#gmap {
    overflow: hidden;
    border-radius: 20px;
    margin-bottom: 0px;
    transition: padding .2s ease-in-out, margin .2s ease-in-out;
    &.open {
        margin-bottom: 20px;
        padding-bottom: 90%;
        @media(min-width: $sm) {
            padding-bottom: 70%;
        }
        @media(min-width: $md) {
            padding-bottom: 56.25%;
        }
    }
    .cluster-markers {
        width: 50px;
        height: 50px;
        color: $color-white;
        border-radius: 50%;
        background-color: $color-red;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-main;
        padding-top: 5px;
    }
}
