.hp-info-doctor {
    background-color: $color-blue-dark;
    color: $color-white;
    position: relative;
    margin-bottom: 80px;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 0 60px;
    @media(min-width: $lg) {
        padding: 90px 0;
    }
    &:after {
        content: "";
        background-color: $color-red-light;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: calc(100% - 70px);
        @media(min-width: $sm) {
            bottom: calc(100% - 100px);
        }
        @media(min-width: $md) {
            right: 0;
            top: 0;
            bottom: 0;
            left: calc(100% - 135px);
        }
        @media(min-width: $lg) {
            left: calc(100% - 160px);
        }
        @media(min-width: $xl) {
            left: calc(100% - 210px);
        }
        @media(min-width: 1440px) {
            left: calc((100vw - ((100vw - 1410px) / 2)) - 200px);
        }
    }
    &__text {
        max-width: 100%;
        order: 2;
        @media(min-width: $md) {
            order: 1;
            max-width: calc(100% - 230px);
        }
        @media(min-width: $lg) {
            max-width: calc(100% - 300px);
        }
    }
    &__title {
        font-weight: 700;
        margin-bottom: 35px;
        max-width: 800px;
        font-size: 26px;
        line-height: 35px;
        @media(min-width: $lg) {
            font-size: 45px;
            line-height: 55px;
        }
    }
    &__subtitle {
        margin-bottom: 45px;
        max-width: 550px;
        font-weight: 700;
        font-size: 18px;
        @media(min-width: $lg) {
            font-size: 22px;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 55px;
        li {
            color: rgba(255,255,255,0.7);
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            font-size: 17px;
            @media(min-width: $lg) {
                font-size: 20px;
            }
            span {
                display: inline-block;
                width: calc(100% - 12px);
            }
            &:before {
                content: "";
                width: 5px;
                height: 5px;
                background-color: $color-red-light;
                border-radius: 50%;
                display: inline-block;
                margin: 12px 7px 0 0;
            }
        }
    }
    &__btn {
        max-width: 520px;
        letter-spacing: 0.05em;
        @media(max-width: $md-max) {
            font-size: 13px;
            height: 60px;
        }
        @media(max-width: $sm-max) {
            margin: 0 auto;
        }
        .btn-border__content {
            background-color: $color-blue-dark;
            &-text {
                span {
                    @media(max-width: $xs-max) {
                        display: none;
                    }
                }
            }
        }
        img {
            margin-left: 20px;
        }
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        z-index: 3;
        justify-content: space-between;
    }
    &__image {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        background-color: $color-white;
        border-radius: 20px;
        margin-right: 25px;
        width: 75px;
        height: 75px;
        @media(min-width: $sm) {
            width: 140px;
            height: 140px;
        }
        @media(min-width: $md) {
            margin-right: 0;
            margin-bottom: 15px;
        }
        @media(min-width: $lg) {
            width: 200px;
            height: 200px;
        }
        img {
            @media(max-width: $md-max) {
                max-width: 50%;
            }
        }
        &:last-child {
            margin-right: 0;
            margin-bottom: 0;
        }
    }
    &__images {
        order: 1;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 30px;
        @media(min-width: $md) {
            margin-bottom: 0;
            padding-right: 50px;
            order: 2;
            display: block;
            width: auto;
        }
        @media(min-width: $xl) {
            padding-right: 100px;
        }
    }

}
