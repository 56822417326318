.calendary {
    &__top {
        padding-bottom: 171px;
    }
    &__list {
        margin-top: -150px;
        &-item {
            display: flex;
            flex-wrap: wrap;
            background-color: #fff;
            border-radius: 20px;
            padding: 28px 30px;
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            position: relative;
            margin-bottom: 40px;
            &-month {
                font-size: 22px;
                line-height: 21px;
                font-weight: 700;
                color: #fff;
                border-radius: 12px;
                margin-bottom: 27px;
                background: linear-gradient(to right, rgba(39,69,101,1) 0%,rgba(53,183,193,1) 100%);
                display: inline-block;
                padding: 10px 26px;
                text-align: center;
                position: relative;
                z-index: 1;
            }
            &-date {
                display: flex;
                position: absolute;
                left: 30px;
                top: 28px;
                &-day {
                    font-size: 42px;
                    line-height: 39px;
                    font-weight: 700;
                    margin-right: 8px;
                }
                &-other {
                    &-month {
                        font-size: 15px;
                        line-height: 18px;
                        font-weight: 700;
                    }
                    &-year {
                        font-size: 11px;
                        font-weight: 400;
                    }
                }
            }
            &-holiday {

            }
            &-articles {
                max-width: 100%;
                width: 100%;
            }
        }
        &-article {
            display: flex;
            align-items: flex-start;
            @media(max-width: 750px) {
                flex-wrap: wrap;
            }
            &-image {
                border-radius: 13px;
                margin-right: 38px;
                margin-top: 60px;
                max-width: 200px;
                width: 100%;
                @media(max-width: 750px) {
                    max-width: 100%;
                    margin-right: 0px;
                    margin-bottom: 15px;
                }
                img {
                    display: block;
                    border-radius: 13px;
                }
            }
            &-text {
                @media(max-width: 750px) {
                    max-width: 100%;
                    width: 100%;
                }
                &-title {
                    font-size: 21px;
                    line-height: 25px;
                    font-weight: 700;
                    margin-bottom: 15px;
                }
                &-introtext {
                    font-size: 15px;
                    line-height: 23px;
                }
            }
        }
    }
    &__category-title {
        font-size: 15px;
        line-height: 29px;
        font-weight: 700;
        color: #fff;
        border-radius: 5px;
        margin-bottom: 27px;
        background: linear-gradient(to right, rgba(39,69,101,1) 0%,rgba(53,183,193,1) 100%);
        display: inline-block;
        text-transform: uppercase;
        padding: 5px 10px 2px 10px;
    }
}


.kalendarium {
    background: #fff;
    padding: 20px 0;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    z-index: 1;
    position: relative;
    .calendar-container {
        z-index: 0;
        .gldp-kalendarium {
            @media(max-width: 470px) {
                max-width: 100% !important;
                width: 100% !important;
            }
            .glCore {
                box-sizing: border-box;
                position: relative;
                float: left;
                padding: 0;
                margin: 0;
                font-size: 24px;
                line-height: 32px;
                text-align: center;
                color: #000;
                &:nth-of-type(1) {
                    background-color: #274565;
                    border-top-left-radius: 14px;
                    border-bottom-left-radius: 14px;
                    margin-bottom: 13px;
                    height: 60px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media(max-width: 470px) {
                        max-width: 20% !important;
                        width: 100% !important;
                    }
                }
                &:nth-of-type(2) {
                    margin-bottom: 13px;
                    font-size: 25px;
                    line-height: 33px;
                    font-weight: 700;
                    height: 60px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media(max-width: 470px) {
                        max-width: 60% !important;
                        width: 100% !important;
                    }
                }
                &:nth-of-type(3) {
                    background-color: #274565;
                    border-top-right-radius: 14px;
                    border-bottom-right-radius: 14px;
                    margin-bottom: 13px;
                    height: 60px !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @media(max-width: 470px) {
                        max-width: 20% !important;
                        width: 100% !important;
                    }
                }
                &.dow {
                    font-weight: 700;
                    margin-bottom: 10px;
                }
            }
            .title {
                font-size: 25px;
                color: #000;
                background-color: #274565;
                color: #fff;
            }
            .dow {
                color: #000;
                font-size: 23px;
                line-height: 31px;
            }
            .glCore.day.inday.event {
                cursor: pointer;
                font-weight: bold;
                color: #35B7C1;
                border: 2px solid #35B7C1;
                border-radius: 15px;
                transition: .5s all;
                &:hover {
                    background-color: #35B7C1;
                    color: #fff;
                }
            }
            .glCore.day, .glCore.glBorder.dow {
                width: 54px !important;
                height: 54px !important;
                margin: 3px !important;
                line-height: 54px !important;
            }
            .glCore.day.inday.selected {
                cursor: pointer;
                color: white;
                font-weight: bold;
                background-color: #35B7C1;
                border-radius: 15px;
            }
            .next-arrow, .prev-arrow {
                cursor: pointer;
                font-weight: 300;
                font-size: 20px;
                color: #fff;
                background-color: #274565;
                img {
                    max-height: 20px;
                }
            }
        }
    }
}