.expert-single {
    padding-bottom: 73px;
    &__top {
        border-bottom: 3px solid $color-red;
        margin-bottom: 30px;
    }
    &__expert {
        display: flex;
        flex-wrap: wrap;
        color: var(--colorFont);
        margin-bottom: 36px;
        &-email {
            font-size: 17px;
            line-height: 21px;
            color: #FB3636;
            margin-bottom: 37px;
            display: block;
            text-align: center;
            @media(min-width: $md) {
                text-align: left;
            }
        }
        &-image {
            width: 100%;
            max-width: 210px;
            display: none;
            @media(min-width: $md) {
                display: block;
            }
            img {
                border-radius: 20px;
                margin-bottom: 19px;
            }
            &--mobile {
                display: block;
                margin: 0 auto 30px;
                @media(min-width: $md) {
                    display: none;
                }
            }
        }
        &-text {
            width: 100%;
            @media(min-width: $md) {
                max-width: calc(100% - 210px);
                padding-left: 30px;
            }
            &.open {
                .open {
                    display: inline;
                }
                .close {
                    display: none;
                }
                .expert-single__expert-description {
                    max-height: 100%;
                }
                .expert-single__read-more {
                    img {
                        transform: rotate(180deg);
                    }
                }
                .expert-single__read-more:after {
                    opacity: 0;
                }
            }
        }
        &-title {
            font-size: 40px;
            line-height: 45px;
            font-weight: 700;
            margin-bottom: 7px;
            text-align: center;
            @media(min-width: $md) {
                text-align: left;
            }
        }
        &-category {
            font-size: 17px;
            font-weight: 400;
            letter-spacing: 0.12em;
            margin-bottom: 29px;
            text-transform: uppercase;
        }
        &-description {
            font-size: 17px;
            font-weight: 400;
            line-height: 30px;
            margin-bottom: 17px;
            max-height: 180px;
            overflow: hidden;
            text-align: center;
            @media(min-width: $md) {
                text-align: left;
            }
            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
        &-counter {
            font-size: 17px;
            font-weight: 700;
            text-align: center;
            @media(min-width: $md) {
                text-align: left;
            }
            span {
                opacity: 0.5;
                font-weight: 400;
            }
        }
    }
    &__read-more {
        position: relative;
        padding-top: 28px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.05em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        cursor: pointer;
        text-align: right;
        justify-content: flex-end;
        display: none;
        @media(min-width: $md) {
            text-align: left;
            justify-content: flex-start;
        }
        img {
            width: 13px;
            margin-right: 10px;
            margin-bottom: 4px;
        }
        &:after {
            content: "";
            height: 130px;
            background: linear-gradient(0deg, rgba(0,43,68,1) 15%, rgba(0,43,68,0) 100%); /* w3c */
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
            pointer-events: none;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: rgba(255,255,255,0.2);
            pointer-events: none;
        }
        .open {
            display: none;
        }
    }
    &__list {
        margin-top: -73px;
    }
}
