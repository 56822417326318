.opinions {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    &__title {
        color: $color-white;
        background-color: $color-blue-bg;
        text-align: center;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 20px;
        font-weight: 700;
    }
    &__list {
        padding: 20px;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 15px;
        border-bottom: 1px solid $color-gray-border;
        margin-bottom: 20px;
        &-images {
            max-width: 47px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            align-content: flex-start;
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
        &-photo {
            margin-bottom: 15px;
            img {
                border-radius: 9px;
            }
        }
        &-text {
            max-width: calc(100% - 47px);
            width: 100%;
            padding-left: 15px;
        }
        &-name {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &-description {
            font-size: 13px;
            margin-bottom: 15px;
            line-height: 20px;
        }
        &-author {
            color: #B7B7B7;
            font-size: 13px;
            span {
                color: $color-font-main;
                font-weight: 700;
            }
        }
    }
}
