.article-img-top {
    &:hover {
        @media(min-width: $lg) {
            .article-img-top__image {
                &:after {
                    opacity: 0.4;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &__image {
        border-radius: 15px;
        overflow: hidden;
        margin-bottom: 25px;
        position: relative;
        &:after {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(0,12,45,1) 0%, rgba(0,12,45,0) 100%);
            opacity: 0;
            position: absolute;
            display: block;
            pointer-events: none;
            transition: opacity .5s ease-in-out;
            border-radius: 20px;
        }
        img {
            transform-origin: 50% 50%;
            transition: transform .5s ease-in-out;
            backface-visibility: hidden;
            outline: 1px solid transparent;
        }
    }
    &__text {

    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 15px;
    }
    &__introtext {
        font-size: 15px;
        font-weight: 400;
    }
}
