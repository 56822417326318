.article-tags {
    margin-top: 10px;
    margin-bottom: 36px;
    &__title {
        font-size: 13px;
        line-height: 17px;
        font-weight: 700;
        border-bottom: 1.3px solid #FB3636;
        padding-bottom: 7px;
        margin-bottom: 9px;
        text-transform: uppercase;
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        li {
            a {
                color: #1F1F1F !important;
                text-decoration: none;
            }
            &.red {
                a {
                    color: #fff !important;
                }
            }
        }
        &-el {
            margin-right: 10px;
            margin-bottom: 10px;
            color:#1F1F1F;
            border-radius: 5px;
            font-weight: 700;
            max-height: 29px;
            height: 100%;
            font-size: 13px;
            line-height: 17px;
            border: 1.3px solid #DDDDDD;
            a {
                display: block;
                padding: 5px 10px;
            }
            &.red {
                background: linear-gradient(135deg, #f16655 0%,#fb3636 100%);
                font-weight: 700;
                text-transform: uppercase;
                color: #fff;
                border: 1.3px solid #fff;
            }
            &:hover {
                background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
                color: #fff !important;
                a {
                    color: #fff !important;
                }
            }
        }
    }
}