.article-list__article-select {
    width: 100%;
    max-width: 330px;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    z-index: 10;
    &.loaded {
        opacity: 1;
    }
    #article-cs-select {
        .cs-list-wrap {
            max-height: 400px;
        }
    }
    .simplebar-track {
        right: 20px;
        bottom: 20px;
        &:before {
            content: "";
            left: 0;
            right: 0;
            bottom: 2px;
            top: 2px;
            margin: auto;
            background-color: $color-white;
            position: absolute;
            width: 1px;
            opacity: 0.3;
        }
        .simplebar-scrollbar {
            &:before {
                opacity: 1;
                background-color: $color-white;
            }
        }
    }
    .cs-select {
        position: relative;
        width: 100%;
        line-height: normal;
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 8px;
            transition: height .2s ease-in-out, box-shadow .2s ease-in-out, border-color .2s ease-in-out;
            height: 50px;
            z-index: 9;
            pointer-events: none;
        }
        &.open {
            &:after {
                height: 400px;
                box-shadow: 0 0 99px rgba(0,0,0,0.41);
            }
        }
    }
    .cs-title {
        height: 50px;
        background-color: $color-white;
        width: 100%;
        border: none;
        outline: none;
        color: $color-black;
        font-size: 13px;
        font-weight: 700;
        padding-left: 20px;
        user-select: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-radius: 8px;
        position: relative;
        z-index: 9;
        span {
            position: relative;
            z-index: 9;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            width: 100%;
            a:nth-child(2) {
                position: relative;
                z-index: 20;
                display: inline-block;
                width: 20px;
                height: 20px;
            }
        }
    }
    .cs-list-wrap {
        height: 0;
        transition: height .2s ease-in-out, background-color .2s ease-in-out;
        overflow: hidden;
        top: 0px;
        width: 100%;
        border-radius: 8px;
        background-color: $color-white;
        z-index: 5;
        position: absolute;
        padding-top: 50px;
        // transition: background-color .2s ease-in-out;
        &:after {
            position: absolute;
        }
    }
    .cs-list {
        height: 100%;
        overflow: hidden;
        overflow-y: scroll;
        color: $color-black;
        list-style: none;
        margin: 0;
        padding: 0 15px 15px;
        width: calc(100% + 20px);
        font-size: 15px;
        line-height: 23px;
        font-weight: 400;
        li {
            padding-right: 15px;
            a {
                display: block;
                &:first-letter {
                    text-transform: uppercase;
                }
            }
        }
    }
    .cs-btn {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        z-index: 12;
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            background-image: url("/templates/main/images/svg/chevron-down-black.svg");
            margin: auto;
            width: 15px;
            height: 8px;
            background-repeat: no-repeat;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            transition: transform .2s ease-in-out;
        }
    }
    .cs-select.open {
        &:after {
            border-color: $color-blue;
        }
        .cs-btn {
            &:after {
                transform: rotate(180deg);
            }
        }
        .cs-list-wrap {
            height: 380px;
            background-color: $color-white;
            &:after {
                border-color: $color-white;
            }
        }
    }
    .article-select--problems {
        li {
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-bottom: 16px;
            & > a {
                margin-bottom: 8px;
                opacity: 0.3;
                display: block;
            }
            & > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    width: 100%;
                    text-transform: none;
                    letter-spacing: normal;
                    font-size: 16px;
                    opacity: 1;
                    font-weight: 400;
                    margin-bottom: 5px;
                    & > a {
                        margin-bottom: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
}
