.recommended-articles {
    padding: 30px 20px 20px 20px;
    background: linear-gradient(135deg, #284666 0%,#284666 100%);
    color: #fff;
    border-radius: 20px;
    margin-bottom: 30px;
    position: relative;
    z-index: 3;
    &-title {
        font-size: 20px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 15px;
        margin-bottom: 16px;
        border-bottom: 3px solid #FB3636;
        &-text {
            font-size: 13px;
            display: block;
        }
    }
    &-el {
        display: flex;
        justify-content: space-between;
        padding-bottom: 12px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.05);
        margin-bottom: 20px;
        &-title {
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            margin-right: 34px;
        }
        &-image {
            width: 70px;
            max-width: 70px;
            flex: 0 0 70px;
            img {
                border-radius: 5px;
            }
        }
    }
}


.recommended-articles-right {
    margin-bottom: 30px;
    box-shadow: 0 25px 50px rgba(0,0,0,.22);
    border-radius: 15px;
    position: relative;
    z-index: 3;
    background-color: $color-white;
    &__title {
        padding: 28px 22px 10px 22px;
        margin-bottom: 36px;
        border-bottom: 3px solid #F16655;
        font-size: 20px;
        line-height: 44px;
        font-weight: 700;
        text-transform: uppercase;
    }
    &__el {
        &-image {
            margin-bottom: 37px;
        }
        &-title {
            font-size: 20px;
            line-height: 25px;
            font-weight: 700;
            text-align: center;
            padding: 0 20px;
            margin-bottom: 13px;
        }
        &-description {
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            padding: 0 20px 40px 20px;
        }
    }
}
