.city-list {
    display: flex;
    flex-wrap: wrap;
    color: $color-black;
    padding: 0;
    &.open {

        li {
            &:nth-child(n) {
                display: block;
            }
        }
        .open {
            display: inline;
        }
        .close {
            display: none;
        }
        li.city-list__more {
            display: none;
        }
    }
    .open {
        display: none;
    }
    .city-list__title {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        opacity: 1;
        padding-left: 0;
        &:before {
            display: none;
        }
    }
    li {
        list-style: none;
        opacity: 0.62;
        margin-right: 10px;
        position: relative;
        padding-left: 11px;
        &:nth-child(2) {
            padding-left: 0;
            &:before {
                display: none;
            }
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            width: 4px;
            height: 4px;
            background-color: $color-white;
            display: block;
            border-radius: 50%;
        }
        &.current, &.active {
            opacity: 1;
            font-weight: 700;
            &:before {
                opacity: 0.62;
            }
        }
        &:nth-child(n + 20) {
            display: none;
        }
        &:last-child {
            display: block;
        }
    }
    .city-list__more {
        opacity: 1;
        text-transform: uppercase;
        cursor: pointer;
        font-weight: 700;
    }
}
