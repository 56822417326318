.error-main {
    .error-code {
        text-align: center;
        padding-bottom: 25px;
        margin-bottom: 35px;
        font-size: 80px;
        font-weight: 700;
        letter-spacing: -0.04em;
        position: relative;
        &:after {
            content: "";
            display: block;
            height: 7px;
            width: 65px;
            border-radius: 5px;
            background-color: $color-red;
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    .error-message {
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        letter-spacing: -0.03em;
        margin-bottom: 30px;
        color: $color-gray;
    }
    .error-image {
        margin: 0 auto 100px;
        display: block;
    }
}
