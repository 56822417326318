.calculator {
    border-radius: 20px;
    background: linear-gradient(135deg, rgba(63,182,147,1) 0%,rgba(53,183,193,1) 100%);
    color: #fff;
    padding: 15px;
    &.calculator-theme {
        max-width: 505px;
        margin: 30px auto;
    }
    @media(min-width: $md) {
        padding: 26px 32px 33px 26px;
    }
    &.mw-510 {
        max-width: 510px;
        margin: 50px auto;
    }
    @media(max-width:1200px) {
        margin-bottom: 30px;
    }
    &-mb {
        margin-bottom: 62px;
    }
    &__top {
        display: flex;
        justify-content: space-between;
        &-image {
            @media(max-width: $sm-max) {
                display: none;
            }
            img {
                @media(max-width: 900px) {
                    max-width: 50px;
                }
            }
        }
        &-text {
            &-subtitle {
                font-size: 13px;
                line-height: 21px;
                font-weight: 700;
                letter-spacing: 1px;
                text-transform: uppercase;
                margin-bottom: 20px;
            }
            &-title {
                font-size: 28px;
                font-weight: 700;
            }
            &-description {
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 27px;
                max-width: 270px;
            }
        }
    }
    &__bottom {
        form {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            @media(max-width:550px) {
                flex-wrap: wrap;
            }
            @media(min-width: $md) {
                justify-content: flex-start;
            }
            @media(min-width: $lg) {
                justify-content: space-between;
            }
            @media(min-width: 1200px) {
                justify-content: flex-start;
            }
            @media(min-width: 1440px) {
                justify-content: space-between;
            }
        }
        &-weight {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 110px;
            margin-bottom: 10px;
            @media(max-width:550px) {
                max-width: 45%;
                flex: 0 0 45%;
            }
            @media(min-width: $md) {
                margin-right: 20px;
            }
            @media(min-width: $lg) {
                margin-right: 0;
            }
            @media(min-width: 1200px) {
                margin-right: 20px;
            }
            @media(min-width: 1440px) {
                margin-right: 0;
            }
            input {
                width: 100%;
                max-height: 40px;
                border: 1px solid #fff;
                background-color: #fff;
                font-size: 16px;
                line-height: 40px;
                font-weight: 700;
                margin-bottom: 6px;
                border-radius: 10px;
                text-align: center;
            }
            &-text {
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &-select {
            text-align: center;
            @media(max-width:550px) {
                max-width: 45%;
                flex: 0 0 45%;
            }
            &-man, &-woman {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 10px;
                border: 1px solid #fff;
                background-color: transparent;
                padding: 0;
                margin: 0;
                font-size: 16px;
                line-height: 40px;
                font-weight: 700;
                color: #fff;
                margin-bottom: 6px;
                cursor: pointer;
                &.active {
                    background-color: #fff;
                    color: #000;
                    box-shadow: 0 3px 6px rgba(0,0,0,.16);
                }
            }
            &-text {
                font-size: 16px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        &-submit {
            width: 100%;
            margin-top: 10px;
            height: 58px;
            @media(max-width:550px) {
                max-width: 100%;
                flex: 0 0 100%;
                display: flex;
                justify-content: center;
                margin-top: 10px;
            }
            @media(min-width: $lg) {
                width: auto;
                height: auto;
                margin-top: 0;
            }
            @media(min-width: 1200px) {
                width: 100%;
                margin-top: 10px;
                height: 58px;
            }
            @media(min-width: 1440px) {
                width: auto;
                height: auto;
                margin-top: 0;
            }
            input {
                background: linear-gradient(135deg, rgba(241,102,85,1) 0%,rgba(251,54,54,1) 100%);
                height: 100%;
                padding: 0 55px;
                font-size: 13px;
                line-height: 21px;
                font-weight: 700;
                letter-spacing: 2px;
                color: #fff;
                border: none;
                border-radius: 10px;
                text-transform: uppercase;
                cursor: pointer;
                @media(max-width:550px) {
                    padding: 15px 55px;
                }
                width: 100%;
                @media(min-width: 1440px) {
                    width: auto;
                }
            }
        }
    }
}
