.therapist-region {
    &__title {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 15px;
        border-bottom: 3px solid $color-red;
    }
    .swiper-container {
        padding: 40px 0;
    }
    .swiper-slide {
        @media(max-width: 499px) {
            width: 250px !important;
        }
        .last-added__item {
            border-radius: 20px;
            background-color: $color-white;
            box-shadow: 0 0 40px rgba(0,0,0,0.1);
            padding: 15px 12px 18px;
            &-image {
                width: 100%;
                max-width: 100%;
                height: auto;
                max-height: none;
                border-radius: 0;
                margin-bottom: 10px;
                @media(min-width: 500px) {
                    max-width: 115px;
                    max-height: 115px;
                    margin-bottom: 0;
                }
                img {
                    border-radius: 10px;
                    display: block;
                    margin: 0 auto;
                }
            }
            &-text {
                max-width: 100%;
                @media(min-width: 500px) {
                    padding-right: 0;
                    padding-left: 20px;
                    max-width: calc(100% - 115px);
                }
            }
        }
    }
    .swiper-button-prev {
        background-position: -1000px -1000px;
        background-repeat: no-repeat;
        background-color: $color-red;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        left: -36px;
        right: auto;
        margin: auto;
        transition: opacity .2s ease-in-out;
        &:after {
            content: "";
            position: absolute;
            background-image: url("/templates/main/images/svg/chevron-down.svg");
            width: 18px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 15px;
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
    }
    .swiper-button-next {
        background-position: -1000px -1000px;
        background-repeat: no-repeat;
        background-color: $color-red;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        top: 0;
        bottom: 0;
        right: -36px;
        left: auto;
        margin: auto;
        transition: opacity .2s ease-in-out;
        &:after {
            content: "";
            position: absolute;
            background-image: url("/templates/main/images/svg/chevron-down.svg");
            width: 18px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 15px;
            transform-origin: 50% 50%;
            transform: rotate(-90deg);
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
    }
}
