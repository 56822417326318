.often-read {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 17px;
}
.hp-slider-problems {
    margin-bottom: 50px;
    padding-left: 15px;
    @media(min-width: 1440px) {
        padding-left: calc((100% - 1410px) / 2);
    }
    @media(max-width: 399px) {
        padding-right: 15px;
    }
    &__title {
        font-size: 18px;
        font-weight: 700;
    }
    .swiper-wrapper {
        padding-top: 23px;
        height: auto;
    }
}
.slide-problem {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    transition: transform .2s ease-in-out;
    &:hover {
        @media(min-width: $lg) {
            transform: translateY(-15px);
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        bottom: 0;
        left: 0;
        right: 0;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
        opacity: 0.65;
    }
    &__image {
        img {
            transform-origin: 50% 50%;
            transition: transform .2s ease-in-out;
            backface-visibility: hidden;
            outline: 1px solid transparent;
        }
    }
    &__text {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 17px;
        color: $color-white;
        letter-spacing: 0.2em;
        font-size: 14px;
        font-weight: 700;
        z-index: 3;
        text-transform: uppercase;
        text-align: center;
    }
}
