.btn-border {
    background: $teal-gradient;
    height: 80px;
    width: 100%;
    padding: 3px;
    border-radius: 20px;
    overflow: hidden;
    display: block;
    font-size: 15px;
    font-weight: 700;
    &__content {
        border-radius: 17px;
        overflow: hidden;
        background-color: transparent;
        width: 100%;
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        position: relative;
        &-text {
            text-transform: uppercase;
            position: relative;
            z-index: 3;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            justify-content: center;
            align-items: center;
            img,svg {
                margin-left: 15px;
            }
        }
        &:after {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 100%;
            background: $teal-gradient;
            position: absolute;
            display: block;
            pointer-events: none;
            transition: all .2s ease-in-out;
        }
        &-text {
            position: relative;
            z-index: 3;
        }
    }
    &:hover {
        .btn-border__content {
            &:after {
                right: 0;
            }
        }
    }
}
