[data-user-browser="firefox"] {
    .finder-modal:after {
        filter: brightness(10%);
    }
}
.finder-modal {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
    display: flex;
    transition: opacity .2s ease-in-out;
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(26,27,28, 0.9);
        backdrop-filter: blur(16px);
    }
    &.open {
        pointer-events: all;
        opacity: 1;
    }
    &__wrap {
        width: 100%;
        max-width: 860px;
        padding: 80px 15px;
        margin: 0 auto;
        position: relative;
        z-index: 3;
    }
    &__logo {
        margin-bottom: 80px;
        text-align: center;
    }
    &__form {
    }
    &__close {
        width: 70px;
        height: 70px;
        border-radius: 20px;
        position: absolute;
        background-color: #3A3A3A;
        z-index: 5;
        cursor: pointer;
        top: 15px;
        right: 15px;
        @media(min-width: $md) {
            top: 45px;
            right: 45px;
        }
        @media(min-width: $lg) {
            top: 80px;
            right: 75px;
        }
        span {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 45%;
            height: 2px;
            border-radius: 1px;
            background-color: $color-white;
            margin: auto;
            transform-origin: 50% 50%;
            &:first-child {
                transform: rotate(45deg);
            }
            &:last-child {
                transform: rotate(-45deg);
            }
        }
    }
    &__input {
        color: $color-white;
        &--search {
            border-bottom: 2px solid $color-red;
            margin-bottom: 32px;
            padding-bottom: 15px;
            input[type='text'] {
                width: 100%;
                border: none;
                background-color: transparent;
                outline: none;
                color: $color-white;
                font-size: 35px;
                font-weight: 400;
                text-align: center;
                &::placeholder {
                    opacity: 1;
                    transition: opacity .2s ease-in-out;
                }
                &:active, &:focus {
                    &::placeholder {
                        opacity: 0;
                    }
                }
            }
        }
        &--submit {
            text-align: center;
            input[type='submit'] {
                width: 100%;
                max-width: 290px;
                height: 80px;
                border: none;
                outline: none;
                color: $color-white;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
                border-radius: 17px;
            }
        }
    }
}
