// Paginacja - START

.pagination {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 35px 0 10px 0;
    margin-bottom: 40px;
    width: 100%;
    .fa-chevron-right {
        padding: 19px 22px;
        border: 1px solid #CACACA;
        border-radius: 5px;
        background-color: #fff;
    }
    .fa-chevron-right:before {
        content: "";
        background-image: url('/templates/main/images/svg/right-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        width: 16px;
        height: 16px;
    }
    .fa-chevron-left {
        padding: 19px 22px;
        border: 1px solid #CACACA;
        border-radius: 5px;
        background-color: #fff;
    }
    .fa-chevron-left:before {
        content: "";
        background-image: url('/templates/main/images/svg/left-arrow.svg');
        background-position: center;
        background-repeat: no-repeat;
        display: block;
        width: 16px;
        height: 16px;
    }
    p {
        margin: 0;
    }
    p, span {
        font-size: 13px;
    }
    .pagination-left {
        position: absolute;
        left: 16px;
        &.inactive {
            display: none;
        }
        img {
            border: none;
        }
        span {
            font-size: 13px;
            @media(max-width: 625px) {
                display: none;
            }
        }
    }
    .pagination-right {
        position: absolute;
        right: 16px;
        &.inactive {
            display: none;
        }
        img {
            border: none;
        }
        span {
            font-size: 13px;
            @media(max-width: 625px) {
                display: none;
            }
        }
    }
    img {
        width: 13px;
        border: none;
    }
    .fa-chevron-left {
        display: block;
        margin-top: 2px;
    }
    .fa-chevron-right {
        display: block;
        margin-top: 2px;
    }
    .items {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        .hidden-xs {
            display: flex;
            @media(max-width: $sm-max) {
                display: none;
            }
        }
        a {
            padding: 0 19px;
            font-size: 20px;
            @media(max-width:$sm) {
                padding: 0 10px;
            }
        }
        p {
            font-size: 20px;
            width: 50px;
            height: 50px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .inactive {
        background-color: #F16655;
        border-radius: 5px;
        color: #fff;
        font-size: 20px;
        @media(max-width:$sm) {
            margin: 0 10px;
        }
    }
    > .inactive {
        background-color: #f8f8fa;
        color: #5A5A5A;
        border-radius: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        margin: 0;
        transition: all .35s ease-in-out;
        opacity: .5;
        display: flex;
        align-items: center;
        @media(max-width: 680px) {
            padding: 10px;
        }
        span {
            padding: 0 20px;
            @media(max-width: 1080px) {
                display: none;
            }
        }
    }
    > .active {
        color: #000;
        border-radius: 0;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        transition: all .35s ease-in-out;
        display: flex;
        align-items: center;
        span {
            padding: 0 20px;
            @media(max-width: 1080px) {
                display: none;
            }
        }
    }
}

// Paginacja - END
