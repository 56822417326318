.page-header {
    font-size: 55px;
    line-height: 63px;
    font-weight: 700;
}
.p-sticky {
    top: 85px;
    position: sticky;
    transition: top .2s ease-in-out;
}
.avt-mb-15 {
    margin-bottom: 15px;
}
picture {
    &.lazy {
        display: block;
    }
}
