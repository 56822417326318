.article-white {
    background-color: $color-white;
    box-shadow: 0 0 99px rgba(0,0,0,0.16);
    border-radius: 20px;
    margin-bottom: 30px;
    overflow: hidden;
    padding: 90px 30px 80px;
    text-align: center;
    &__image {
        img {
            margin: 0 auto 45px;
        }
    }
    &__title {
        font-size: 21px;
        font-weight: 700;
        line-height: 25px;
        margin-bottom: 25px;
    }
    &__introtext {
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
    }
}
