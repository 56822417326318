#return-top {
    position: fixed;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 55px;
    height: 55px;
    bottom: 20px;
    right: 20px;
    z-index: 99;
    transition: .5s all;
    width: 74px;
    height: 74px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 30px 50px rgba(0,0,0,0.16);
    @media(min-width: $sm) {
        bottom: 45px;
        right: 45px;
        width: 74px;
        height: 74px;
    }
    &.show {
        opacity: 1;
    }
    svg {
        path {
            fill: #fb3636;
        }
    }
}
