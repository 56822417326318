.e-commerce {
    &--wide {
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        padding: 50px 0;
        background-color: $color-white;
        line-height: normal;
        .e-commerce {
            &__title {
                font-size: 22px;
                letter-spacing: 0.04em;
                font-weight: 400;
                text-transform: uppercase;
                position: relative;
                margin-bottom: 30px;
                span {
                    display: inline-block;
                    background-color: $color-white;
                    padding-right: 50px;
                    position: relative;
                    z-index: 3;
                    padding-top: 2px;
                }
                &:after {
                    content: "";
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background-color: $color-red;
                    position: absolute;
                    margin: auto;
                }
            }
            &__container {
                padding: 0 calc((100% - 1410px) / 2);
                .swiper-button-next,
                .swiper-button-prev {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 135px;
                    height: 135px;
                    background-color: $color-white;
                    box-shadow: 0 0 99px rgba(0,0,0,0.16);
                    background-image: none;
                    border-radius: 50%;
                    margin: auto;
                    transition: opacity .2s ease-in-out;
                    img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        display: block;
                        width: 27px;
                        height: 27px;
                    }
                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                }
                .swiper-button-next {
                    right: -70px;
                    img {
                        left: 25px;
                        right: auto;
                    }
                }
                .swiper-button-prev {
                    left: -70px;
                    img {
                        right: 25px;
                        left: auto;
                    }
                }
            }
            &__item {
                &-image {
                    margin-bottom: 20px;
                }
                &-category {
                    font-size: 11px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin-bottom: 18px;
                    color: $color-red;
                    padding: 0 15px;
                }
                &-name {
                    font-size: 17px;
                    font-weight: 700;
                    padding: 0 15px;
                }
            }
            &__item--slider {
                width: 360px;
                padding: 0 15px;
                text-align: center;
            }
        }
    }
    &--article-transparent {
        padding: 50px 0;
        overflow: hidden;
        line-height: normal;
        .e-commerce {
            &__title {
                font-size: 13px;
                letter-spacing: 0.04em;
                font-weight: 400;
                text-transform: uppercase;
                position: relative;
                margin: 0 0 18px;
                span {
                    display: inline-block;
                    background-color: $color-white;
                    padding-right: 35px;
                    position: relative;
                    z-index: 3;
                    padding-top: 2px;
                }
                &:after {
                    content: "";
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background-color: $color-red;
                    position: absolute;
                    margin: auto;
                }
            }
            &__container {
                .swiper-button-next,
                .swiper-button-prev {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 80px;
                    height: 80px;
                    background-color: $color-white;
                    box-shadow: 0 0 99px rgba(0,0,0,0.16);
                    background-image: none;
                    border-radius: 50%;
                    margin: auto;
                    transition: opacity .2s ease-in-out;
                    img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        display: block;
                        width: 20px;
                        height: 20px;
                    }
                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                }
                .swiper-button-next {
                    right: -40px;
                    img {
                        left: 15px;
                        right: auto;
                    }
                }
                .swiper-button-prev {
                    left: -40px;
                    img {
                        right: 15px;
                        left: auto;
                    }
                }
            }
            &__item {
                &-link {
                    text-decoration: none;
                    color: $color-font-main !important;
                }
                &-image {
                    margin-bottom: 20px;
                }
                &-category {
                    font-size: 11px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    color: $color-red;
                    padding: 0 15px;
                }
                &-name {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 700;
                    padding: 0 15px;
                }
            }
            &__item--slider {
                width: 242px;
                text-align: center;
                padding: 0 15px;
            }
        }
    }
    &--article {
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        padding: 30px 0;
        background-color: $color-white;
        margin-bottom: 50px;
        border-radius: 20px;
        overflow: hidden;
        line-height: normal;
        .e-commerce {
            &__title {
                font-size: 13px;
                letter-spacing: 0.04em;
                font-weight: 400;
                text-transform: uppercase;
                position: relative;
                margin: 0 20px 18px;
                span {
                    display: inline-block;
                    background-color: $color-white;
                    padding-right: 35px;
                    position: relative;
                    z-index: 3;
                    padding-top: 2px;
                }
                &:after {
                    content: "";
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background-color: $color-red;
                    position: absolute;
                    margin: auto;
                }
            }
            &__container {
                padding: 0 20px;
                .swiper-button-next,
                .swiper-button-prev {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 80px;
                    height: 80px;
                    background-color: $color-white;
                    box-shadow: 0 0 99px rgba(0,0,0,0.16);
                    background-image: none;
                    border-radius: 50%;
                    margin: auto;
                    transition: opacity .2s ease-in-out;
                    img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        display: block;
                        width: 20px;
                        height: 20px;
                    }
                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                }
                .swiper-button-next {
                    right: -40px;
                    img {
                        left: 15px;
                        right: auto;
                    }
                }
                .swiper-button-prev {
                    left: -40px;
                    img {
                        right: 15px;
                        left: auto;
                    }
                }
            }
            &__item {
                &-link {
                    text-decoration: none;
                    color: $color-font-main !important;
                }
                &-image {
                    margin-bottom: 20px;
                }
                &-category {
                    font-size: 11px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin-bottom: 12px;
                    color: $color-red;
                    padding: 0 15px;
                }
                &-name {
                    font-size: 15px;
                    line-height: 20px;
                    font-weight: 700;
                    padding: 0 15px;
                }
            }
            &__item--slider {
                width: 242px;
                text-align: center;
                padding: 0 15px;
            }
        }
    }
    &--column {
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        padding: 30px 0;
        background-color: $color-white;
        margin-bottom: 50px;
        border-radius: 20px;
        overflow: hidden;
        line-height: normal;
        position: relative;
        z-index: 3;
        .e-commerce {
            &__title {
                letter-spacing: 0.04em;
                font-weight: 400;
                text-transform: uppercase;
                position: relative;
                margin: 0 20px 18px;
                font-size: 15px;
                @media(min-width: $sm) {
                    font-size: 22px;
                }
                span {
                    display: inline-block;
                    background-color: $color-white;
                    padding-right: 35px;
                    position: relative;
                    z-index: 3;
                    padding-top: 2px;
                }
                &:after {
                    content: "";
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    height: 2px;
                    background-color: $color-red;
                    position: absolute;
                    margin: auto;
                }
            }
            &__container {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                .swiper-button-next,
                .swiper-button-prev {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 80px;
                    height: 80px;
                    background-color: $color-white;
                    box-shadow: 0 0 99px rgba(0,0,0,0.16);
                    background-image: none;
                    border-radius: 50%;
                    margin: auto;
                    transition: opacity .2s ease-in-out;
                    img {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        display: block;
                        width: 20px;
                        height: 20px;
                    }
                    &.swiper-button-disabled {
                        opacity: 0;
                    }
                }
                .swiper-button-next {
                    right: -40px;
                    img {
                        left: 15px;
                        right: auto;
                    }
                }
                .swiper-button-prev {
                    left: -40px;
                    img {
                        right: 15px;
                        left: auto;
                    }
                }
            }
            &__item {
                width: 100%;
                padding: 0 10px;
                text-align: center;
                margin-bottom: 20px;
                @media(min-width: 360px) {
                    max-width: 50%;
                }
                @media(min-width: $sm) {
                    max-width: calc(100% / 3);
                }
                @media(min-width: $lg) {
                    max-width: 50%;
                }
                &-link {
                    text-decoration: none;
                    color: $color-font-main !important;
                }
                &-image {
                    margin-bottom: 20px;
                }
                &-category {
                    font-size: 11px;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    margin-bottom: 9px;
                    color: $color-red;
                    padding: 0 15px;
                }
                &-name {
                    font-size: 13px;
                    line-height: 17px;
                    font-weight: 700;
                    padding: 0 15px;
                }
            }
            &__item--slider {
                width: 242px;
                text-align: center;
                padding: 0 15px;
            }
        }
    }
    &--column-slider {
        margin: 0 auto 50px;
        max-width: 370px;
        .swiper-container {
            max-height: 850px;
            height: 850px;
        }
        .swiper-slide {
            .e-commerce__item {
                width: 100%;
                max-width: none;
                padding-bottom: 20px;
                img {
                    border-radius: 15px;
                }
            }
        }
        .e-commerce__container.swiper-container {
            .swiper-button-prev {
                top: -40px;
                left: 0;
                right: 0;
                bottom: auto;
                margin: auto;
                transform: rotate(90deg);
            }
            .swiper-button-next {
                top: auto;
                left: 0;
                right: 0;
                bottom: -40px;
                margin: auto;
                transform: rotate(90deg);
            }
        }
    }
}
