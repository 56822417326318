.calculator-single {
    padding-top: 173px;
    margin-top: -173px;
    color: #fff;
    max-width: 900px;
    &__top {
        background-color: #002B44;
        margin-bottom: 41px;
        @media(min-width: 1024px) {
            background-image: url('/templates/main/images/bmi-image.png');
            background-repeat: no-repeat;
            background-position: right;
            background-size: contain;
            &.nmc {
                background-image: linear-gradient(to right,#002B44 50%,rgba(255,255,255,0) 100%), url('/templates/main/images/nmc-background.jpg');
            }
        }
        @media(max-width:1250px) {
            &.nmc {
                background-size: cover;
            }
        }
    }
    &__title {
        font-size: 40px;
        line-height: 49px;
        font-weight: 700;
        margin-bottom: 20px;
        @media(max-width:550px) {
            font-size: 28px;
            line-height: 37px;
        }
    }
    &__description {
        font-size: 16px;
        line-height: 25px;
        opacity: 0.7;
        margin-bottom: 42px;
    }
    &__count {
        display: flex;
        margin-bottom: 41px;
        &-growth {
            text-align: center;
            &-title {
                border-bottom: 1px solid rgba(255,255,255,.15);
                padding-bottom: 15px;
            }
            &-bottom {
                padding-top: 20px;
                padding-bottom: 14px;
                margin-left: 30px;
                border-right: 1px solid rgba(255,255,255,.15);
                &.nmc {
                    display: flex;
                }
                &-btn {
                    font-size: 19px;
                    font-weight: 700;
                    padding: 16px;
                    background-color: rgba(255,255,255,.1);
                    border-radius: 15px;
                    width: 47px;
                    height: 47px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    &.active {
                        background-color: rgba(255,255,255,1);
                        color: #000;
                    }
                    &.error {
                        border: 1px solid red;
                    }
                }
                &-woman {
                    display: flex;
                    margin-right: 21px;
                }
                &-man {
                    display: flex;
                    margin-right: 34px;
                }
            }
            img {
                max-width: 35px;
                margin-right: 21px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            input[type=number] {
                -moz-appearance:textfield; /* Firefox */
            }
            input {
                width: 141px;
                text-align: center;
                background-color: rgba(255,255,255,.1);
                border: none;
                border-radius: 15px;
                padding: 16px 0 12px 0;
                color: #fff;
                margin-right: 30px;
                &.error {
                    border: 1px solid red;
                }
            }
        }
        &-weight {
            text-align: center;
            &-title {
                border-bottom: 1px solid rgba(255,255,255,.15);
                padding-bottom: 15px;
            }
            &-bottom {
                padding-top: 20px;
                padding-bottom: 14px;
                margin-left: 30px;
                border-right: 1px solid rgba(255,255,255,.15);
            }
            img {
                max-width: 35px;
                margin-right: 15px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }

            input[type=number] {
                -moz-appearance:textfield; /* Firefox */
            }
            input {
                width: 141px;
                text-align: center;
                background-color: rgba(255,255,255,.1);
                border: none;
                border-radius: 15px;
                padding: 16px 0 12px 0;
                color: #fff;
                margin-right: 30px;
                &.error {
                    border: 1px solid red;
                }
            }
        }
        &-score {
            text-align: center;
            &-title {
                border-bottom: 1px solid rgba(255,255,255,.15);
                padding-bottom: 15px;
                margin-bottom: 20px;
            }
            &-bottom {
                display: flex;
                align-items: center;
                padding-left: 28px;
                padding-right: 10px;
                &-btn {
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    background: linear-gradient(135deg, rgba(63,182,147,1) 0%,rgba(53,183,193,1) 100%);
                    padding: 12px;
                    display: block;
                    width: 125px;
                    border-radius: 15px;
                    max-height: 47px;
                    text-transform: uppercase;
                    margin-right: 20px;
                    cursor: pointer;
                    border: none;
                    color: #fff;
                    // display: none;
                    &.nmc {
                        &.hide {
                            display: none;
                        }
                    }
                }
                &-result {
                    white-space: nowrap;
                    img {
                        margin-right: 10px;
                    }
                }
                &-results {
                    display: none;
                    &.show {
                        display: flex;
                    }
                    &-broc {
                        width: 95px;
                        padding-right: 13.5px;
                        margin-right: 19px;
                        border-right: 1px solid rgba(255,255,255,.15);
                        white-space: nowrap;
                    }
                    &-top {
                        font-size: 25px;
                        font-weight: 700;
                    }
                    &-bottom {

                    }
                }
            }
        }
    }
    &__btns {
        &-clean {
            padding: 10px 25px 10px 14px;
            font-size: 12px;
            line-height: 21px;
            font-weight: 700;
            text-transform: uppercase;
            border: 1px solid rgba(255,255,255,.15);
            display: inline-block;
            border-radius: 11px;
            margin-bottom: 28px;
            cursor: pointer;
            margin-right: 5px;
            transition: .5s all;
            img {
                transition: .5s all;
                max-width: 22px;
                margin-right: 12px;
            }
        }
        &-share {
            padding: 10px 25px 10px 14px;
            font-size: 12px;
            line-height: 21px;
            font-weight: 700;
            text-transform: uppercase;
            border: 1px solid rgba(255,255,255,.15);
            display: inline-block;
            border-radius: 11px;
            margin-bottom: 28px;
            cursor: pointer;
            display: none;
            img {
                max-width: 22px;
                margin-right: 12px;
            }
        }
    }
    &__bottom {
        &-wrapper {
            max-height: 0px;
            overflow: hidden;
            transition: .3s all;
            &.show {
                max-height: 1000px;
                overflow: initial;
            }
        }
        &-bar {
            height: 48px;
            display: flex;
            border-bottom: 3px solid #1A1B1C;
            margin-bottom: 35px;
            padding: 0 2px;
            position: relative;
            @media(max-width:550px) {
                display: none;
            }
            &-dott {
                position: absolute;
                left: 0;
                top: -47px;
                z-index: 2;
                transition: 1s all;
            }
            &-blue {
                background: linear-gradient(135deg, rgba(63,81,181,1) 0%,rgba(33,150,243,1) 100%);
                width: 52px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                border-top-left-radius: 10px;
                position: relative;
                &::after {
                    content: '16';
                    position: absolute;
                    bottom: -35px;
                    right: -9px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-blue-light {
                background: linear-gradient(to right, rgba(33,150,243,1) 0%,rgba(0,188,212,1) 100%);
                width: 57px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                position: relative;
                &::after {
                    content: '17';
                    position: absolute;
                    bottom: -35px;
                    right: -9px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-blue-green {
                background: linear-gradient(to right, rgba(0,188,212,1) 0%,rgba(76,175,80,1) 100%);
                width: 62px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                position: relative;
                &::after {
                    content: '18.5';
                    position: absolute;
                    bottom: -35px;
                    right: -16px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-green-yellow {
                background: linear-gradient(to right, rgba(102,187,106,1) 0%,rgba(205,220,57,1) 100%);
                width: 203px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                position: relative;
                &::after {
                    content: '25';
                    position: absolute;
                    bottom: -35px;
                    right: -9px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-yellow-orange {
                background: linear-gradient(to right, rgba(205,220,57,1) 0%,rgba(255,193,7,1) 100%);
                width: 170px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                position: relative;
                &::after {
                    content: '30';
                    position: absolute;
                    bottom: -35px;
                    right: -9px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-orange-light {
                background: linear-gradient(to right, rgba(255,193,7,1) 0%,rgba(255,152,0,1) 100%);
                width: 170px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                position: relative;
                &::after {
                    content: '35';
                    position: absolute;
                    bottom: -35px;
                    right: -9px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-orange-red {
                background: linear-gradient(to right, rgba(255,152,0,1) 0%,rgba(244,67,54,1) 100%);
                width: 170px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                position: relative;
                &::after {
                    content: '40';
                    position: absolute;
                    bottom: -35px;
                    right: -9px;
                    font-size: 17px;
                    font-weight: 700;
                }
            }
            &-red {
                background: linear-gradient(to right, rgba(244,67,54,1) 0%,rgba(243,17,0,1) 100%);
                width: 43px;
                height: 100%;
                border-right: 1px solid #FFFFFF;
                border-top-right-radius: 10px;
            }
        }
        &-fulltext {
            font-size: 19px;
            line-height: 33px;
            iframe[name="embedded-content"] {
                border: none;
                width: 100%;
            }
            p {
                & > iframe[name="embedded-content"] {
                    margin-bottom: 0;
                }
            }
            & > h2:first-child {
                margin-top: 0;
            }
            .bibliografia, .bibliography {
                border-radius: 10px;
                margin-bottom: 30px;
                & > p, .bibliography__title {
                    font-size: 18px;
                    line-height: 22px;
                    font-weight: 700;
                    padding: 18px 25px 12px 25px;
                    background-color: #fff;
                    border-radius: 20px;
                }
                & > ol, & > ul {
                    counter-reset: myOrderedListItemsCounter;
                    & > li {
                        overflow-wrap: break-word;
                        position: relative;
                        &::before {
                            counter-increment: myOrderedListItemsCounter;
                            content: counter(myOrderedListItemsCounter)".";
                            margin-right: 15px;
                            position: absolute;
                            top: 6px;
                            left: 25px;
                        }
                        &::marker {
                            margin-left: 15px;
                        }
                    }
                }
                & > ol, & > ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li {
                        padding: 6px 25px 6px 50px;
                        font-size: 15px;
                        line-height: 18px;
                    }
                    li:nth-child(odd) {
                        background-color: rgba(40, 70, 102, 0.05);
                    }
                }
            }
            h2 {
                font-size: 33px;
                line-height: 47px;
                font-weight: 700;
                margin-bottom: 30px;
                margin-top: 65px;
                &.big {
                    font-size: 33px;
                    line-height: 47px;
                    font-weight: 700;
                    margin-bottom: 30px;
                    margin-top: 65px;
                }
                &.small {
                    font-size: 25px;
                    line-height: 31px;
                    font-weight: 700;
                    margin: 50px 0;
                }
            }
            .frac {
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                text-align: center;
                font-size: 45px;
                line-height: 55px;
                margin-top: 62px;
                margin-bottom: 58px;
                font-weight: 700;
                @media(max-width:575px) {
                    font-size: 25px;
                    line-height: 35px;
                }
                &__left {
                    margin-right: 10px;
                }
                &__right {
                    .top {
                        padding-bottom: 4px;
                        border-bottom: 3px solid black;
                    }
                    .bottom {
                        display: block;
                    }
                }
            }
            .pattern {
                box-shadow: 0 30px 99px rgba(0,0,0,.16);
                border-radius: 10px;
                padding: 39px 71px 50px 71px;
                font-size: 15px;
                line-height: 25px;
                text-align: center;
                max-width: 670px;
                margin: 0 auto 30px auto;
                background-color: #fff;
                @media(max-width:575px) {
                    padding: 15px;
                }
                img {
                    margin-right: 14px;
                    @media(max-width:500px) {
                        display: none;
                    }
                }
                &__title {
                    font-size: 23px;
                    line-height: 45px;
                    font-weight: 700;
                    color: #FB3636;
                    text-transform: uppercase;
                }
                &__description {
                    max-width: 380px;
                    margin: 0 auto 22px auto;
                }
                &__value {
                    font-size: 17px;
                    line-height: 33px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    @media(max-width:500px) {
                        font-size: 15px;
                        line-height: 25px;
                    }
                }
                &__alert {
                    display: flex;
                    border: 1px solid #DDDDDD;
                    padding: 26px 16px 16px 16px;
                    border-radius: 20px;
                    max-width: 540px;
                    margin: 0 auto 33px auto;
                    text-align: left;
                }
            }
        }
        &-result {
            display: flex;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 53px;
            @media(max-width:540px) {
                flex-wrap: wrap;
                &-middle {
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-bottom: 30px;
                }
                &-right {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
            &.show {
                max-height: 400px;
            }
            &-score {
                margin-right: 16px;
                text-align: center;
                position: relative;
                &-title {
                    font-size: 23px;
                    font-weight: 700;
                }
                &-number {
                    font-size: 50px;
                    font-weight: 700;
                }
                &-type {
                    font-size: 23px;
                    line-height: 23px;
                    font-weight: 700;
                    padding: 13px 17px;
                    background-color: #BAD641;
                    border: 3px solid #1A1B1C;
                    border-radius: 15px;
                }
            }
            &-list {
                list-style: none;
                padding: 0;
                margin: 0;
                font-size: 13px;
                line-height: 17px;
                &-el {
                    margin-bottom: 5px;
                    &.bold {
                        font-weight: 700;
                    }
                }
                &.hide {
                    display: none;
                }
                &.active {
                    font-weight: 700;
                }
            }
        }
    }
    &__grey {
        background-color: #DDDDDD;
        padding: 36px 23px;
        margin-bottom: 62px;
        border-radius: 10px;
        @media(max-width:575px) {
            padding: 15px;
        }
        &-list {
            padding: 0;
            margin: 0;
            margin-left: 39px;
            list-style: none;
            @media(max-width:575px) {
                margin-left: 0;
            }
            .bold {
                font-weight: 700;
            }
            &-el {
                position: relative;
                padding-left: 25px;
                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 10px;
                    background-color: #F16655;
                    border-radius: 50%;
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
    &.bmi {
        .calculator-single__count {
            flex-wrap: wrap;
            &-growth {
                @media(max-width:768px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                    margin-bottom: 20px;
                    &-bottom {
                        border: 1px solid rgba(255, 255, 255, 0.15);
                        margin-left: 0;
                    }
                }
                @media(max-width:550px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
            &-weight {
                @media(max-width:768px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                    margin-bottom: 20px;
                    &-bottom {
                        border: 1px solid rgba(255, 255, 255, 0.15);
                        margin-left: 0;
                    }
                }
                @media(max-width:550px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
            }
            &-score {
                @media(max-width:768px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                    &-title {
                        margin-bottom: 0;
                    }
                    &-bottom {
                        border: 1px solid rgba(255, 255, 255, 0.15);
                        margin-left: 0;
                        padding: 15px;
                        input {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }
    }
    &.nmc {
        .calculator-single__count {
            &-weight-bottom {
                display: flex;
            }
            &-score {
                max-width: 390px;
                width: 100%;
                &-title {
                    margin: 0;
                }
                &-bottom {
                    justify-content: space-between;
                    padding: 0;
                    &-results {
                        width: 100%;
                        height: 86px;
                        &-broc {
                            line-height: 25px;
                            padding-bottom: 14px;
                            padding: 0;
                            margin: 0;
                            max-width: 33.333%;
                            flex: 0 0 33.333%;
                            padding-top: 24px;
                        }
                        &-lorentz {
                            line-height: 25px;
                            border-right: 1px solid rgba(255, 255, 255, 0.15);
                            white-space: nowrap;
                            padding: 0;
                            margin: 0;
                            max-width: 33.333%;
                            flex: 0 0 33.333%;
                            padding-top: 24px;
                        }
                        &-patton {
                            line-height: 25px;
                            border-right: 1px solid rgba(255, 255, 255, 0.15);
                            white-space: nowrap;
                            padding: 0;
                            margin: 0;
                            max-width: 33.333%;
                            flex: 0 0 33.333%;
                            padding-top: 24px;
                        }
                        &-bottom {
                            font-size: 12px;
                            opacity: .5;
                        }
                    }
                    input {
                        margin: 20px auto 0 auto;
                    }
                }
            }
            @media(max-width:850px) {
                flex-wrap: wrap;
                &-growth {
                    max-width: 50%;
                    flex: 0 0 50%;
                    &-bottom {
                        display: flex;
                        justify-content: center;
                    }
                }
                &-weight {
                    max-width: 50%;
                    flex: 0 0 50%;
                    &-bottom {
                        display: flex;
                        justify-content: center;
                        border: none;
                    }
                }
                &-score {
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-top: 20px;
                }
            }
            @media(max-width:550px) {
                &-growth {
                    max-width: 100%;
                    flex: 0 0 100%;
                    &-title {
                        border: none;
                        text-align: left;
                    }
                    &-bottom {
                        border: none;
                        margin: 0;
                        justify-content: flex-start;
                    }
                }
                &-weight {
                    max-width: 100%;
                    flex: 0 0 100%;
                    &-title {
                        border: none;
                        text-align: left;
                    }
                    &-bottom {
                        margin: 0;
                        justify-content: flex-start;
                    }
                }
                &-score {
                    &-title {
                        border: none;
                        text-align: left;
                    }
                    &-bottom {
                        margin: 0;
                        justify-content: flex-start;
                        input {
                            margin: 20px 0 0 0;
                        }
                    }
                }
            }
        }
    }
    &__important {
        border-radius: 10px;
        box-shadow: 0 50px 99px rgba(0,0,0,.16);
        padding: 25px;
        margin-top: -82px;
        background-color: #fff;
        &-top {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: 700;
            color: #1A1B1C;
            margin-bottom: 18px;
            img {
                margin-right: 15px;
            }
        }
        &-bottom {
            font-size: 14px;
            line-height: 22px;
        }
    }
}

.bibliography {
    border-radius: 10px;
    margin-bottom: 50px;
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    background-color: var(--colorBg);
    &__title {
        font-size: 22px;
        line-height: 26px;
        font-weight: 700;
        text-transform: uppercase;
        padding: 0 28px;
        min-height: 90px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        color: var(--colorFont);
        position: relative;
        cursor: pointer;
        &:before {
            content: "";
            width: 22px;
            height: 17px;
            display: block;
            background-image: url("/templates/main/images/svg/book-open.svg");
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 32px;
        }
        &:after {
            content: "";
            width: 21px;
            height: 13px;
            background-image: url("/templates/main/images/svg/chevron-down-red.svg");
            background-position: center;
            background-repeat: no-repeat;
            margin-left: auto;
            margin-right: 0;
        }
    }
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 15px;
        line-height: 18px;
        display: none;
        li:nth-child(odd) {
            background-color: rgba(40, 70, 102, .05);
        }
        &-el {
            padding: 8px 15px 8px 25px;
        }
    }
}
