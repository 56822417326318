.more-in-section {
    margin-bottom: 52px;
    .hidden {
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        max-height: 0px;
        transition: .5s all;
        &.show {
            max-height: 3000px;
        }
    }
    a.more-in-section__link {
        color: #1A1B1C;
        text-decoration: none;
    }
    &__image {
        margin-bottom: 28px;
        img {
            border-radius: 15px;
        }
    }
    &__title {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        margin-bottom: 18px;
    }
    &__description {
        font-size: 15px;
        line-height: 23px;
        margin-bottom: 30px;
    }
    &__btn {
        font-size: 15px;
        line-height: 21px;
        font-weight: 700;
        width: 100%;
        padding: 30px;
        text-align: center;
        border: 2px solid #DDDDDD;
        margin-top: 30px;
        border-radius: 10px;
        text-transform: uppercase;
        cursor: pointer;
    }
    &-tags {
        margin-bottom: 20px;
    }
}