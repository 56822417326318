.header-module {
    &__title {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 6px;
        border-bottom: 1px solid $color-red;
        margin-bottom: 10px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        height: 30px;
        border: 1px solid $color-gray-border;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px 10px 0;
        padding: 2px 10px 0;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 500;
        color: $color-font-main;
        &.header-module__item--all {
            border: none;
            background-color: $color-red;
            color: $color-white;
            text-transform: uppercase;
        }
    }
}
.header {
    &, &.header-subpages {
        .header__menu {
            .header-module {
                .header-module__list {
                    .header-module__item {
                        &, &.current, &.parent {
                            height: 30px;
                            border: 1px solid $color-gray-border;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 10px 10px 0;
                            padding: 2px 10px 0;
                            border-radius: 5px;
                            font-size: 13px;
                            font-weight: 500;
                            color: $color-font-main;
                            &:after {
                                display: none;
                            }
                        }
                        &.header-module__item--all {
                            border: none;
                            background-color: $color-red;
                            color: $color-white;
                            text-transform: uppercase;
                        }
                    }
                }
            }
        }
    }
}
