.magazine-page {
    position: relative;
    z-index: 3;
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: $color-white;
        align-items: center;
        align-content: center;
        padding-top: 20px;
        border-top: 3px solid $color-red;
        margin-bottom: 60px;
        h1 {
            font-size: 48px;
            font-weight: 700;
            margin: 0;
            padding: 0;
            line-height: 56px;
            color: $color-black;
        }
    }
    &__bottom-nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: 50px auto;
        @media(min-width: $lg) {
            margin: 111px auto;
        }
    }
    .next-year,
    .prev-year {
        position: relative;
        width: calc(50% - 15px);
        border: 3px solid #c9c9c9;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-weight: 400;
        background-image: none;
        transition: opacity .2s ease-in-out;
        user-select: none;
        border-radius: 16px;
        font-size: 24px;
        height: 60px;
        @media(min-width: $lg) {
            font-size: 47px;
            height: 134px;
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
        span {
            padding-top: 2px;
            @media(max-width: 479px) {
                display: none;
            }
        }
    }
    .next-year {
        img {
            @media(min-width: 480px) {
                margin-left: 22px;
            }
        }
    }
    .prev-year {
        img {
            @media(min-width: 480px) {
                margin-right: 22px;
            }
        }
    }
    &__top {
        background-color: transparent;
        padding-bottom: 115px;
    }
    &__bottom {
        margin-top: -55px;
    }
    &__breadcrumb {
        color: $color-black;
    }
    &__archive-btn {
        width: 100%;
        max-width: 225px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 700;
        border: 2px solid rgba(255,255,255,0.2);
        border-radius: 5px;
        background-color: transparent;
        transition: background-color .2s ease-in-out;
        &:hover {
            background-color: rgba(255,255,255,0.2);
        }
    }
}
.swiper-container.swiper-magazine {
    .swiper-wrapper {
        align-items: center;
        align-content: center;
    }
    .swiper-slide {
        width: 175px;
        text-align: center;
        padding: 0;
    }
    .swiper-slide-active {
        .magazine-list__year-item {
            font-size: 60px;
            opacity: 1;
        }
    }
    .swiper-pagination {
        position: relative;
        width: 100%;
        margin: 0;
        padding: 0;
        height: 2px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        max-width: calc(100% - 74px);
        border-bottom: 2px dotted $color-black;
        @media(min-width: $sm) {
            max-width: calc(100% - 400px);
        }
        .swiper-pagination-bullet {
            margin: 0;
            padding: 0;
            height: 2px;
            margin-top: 1px;
            background: none;
            border-radius: 0;
            opacity: 1;
            position: relative;
            padding-left: 1px;
            &-active {
                border: 3px solid $color-black;
            }
        }
    }
    .swiper-button-next, .swiper-button-prev {
        transition: opacity .2s ease-in-out;
        position: relative;
        display: flex;
        align-items: center;
        align-content: center;
        margin: 0;
        padding: 0;
        left: auto;
        right: auto;
        bottom: auto;
        top: auto;
        background: none;
        font-weight: 400;
        font-size: 0;
        width: 37px;
        height: 27px;
        width: 50%;
        color: $color-black;
        &:focus {
            outline: none;
            border: none;
        }
        @media(min-width: $sm) {
            font-size: 15px;
        }
    }
    .swiper-button-prev {
        justify-content: flex-start;
        img,svg {
            margin-right: 10px;
            @media(min-width: $sm) {
                margin-right: 20px;
            }
        }
        svg {
            path {
                stroke: $color-black;
            }
        }
        &:after {
            content: "";
            left: 200px;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 0px;
            border-bottom: 1px dashed $color-black;
            display: block;
            position: absolute;
        }
    }
    .swiper-button-next {
        justify-content: flex-end;
        img,svg {
            margin-left: 10px;
            @media(min-width: $sm) {
                margin-left: 20px;
            }
        }
        svg {
            path {
                stroke: $color-black;
            }
        }
        &:after {
            content: "";
            right: 200px;
            top: 0;
            bottom: 0;
            left: 0;
            margin: auto;
            height: 0px;
            border-bottom: 1px dashed $color-black;
            display: block;
            position: absolute;
        }
    }
    .swiper-button-disabled {
        opacity: 0;
    }
    .swiper-nav-wrap {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin-bottom: 30px;
        padding: 0 7.5px;
        &:after {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            height: 6px;
            width: 124px;
            background-color: $color-black;
            position: absolute;
        }
        @media(min-width: $sm) {
            padding: 0 15px;
        }
    }
}
.magazine-list {
    &__year {
        display: none;
        &.open {
            display: block;
        }
    }
    &__year-item {
        font-size: 45px;
        letter-spacing: -0.035em;
        font-weight: 400;
        opacity: 0.2;
        color: $color-black;
        height: 100px;
        line-height: 100px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        &:hover {
            color: #fff;
        }
    }
    &__item {
        margin-bottom: 20px;
        &-image {
            margin-bottom: 15px;
            img {
                display: block;
                box-shadow: 0 3px 99px rgba(0,0,0,0.16);
            }
        }
        &-title {
            font-size: 17px;
            letter-spacing: -0.015em;
            line-height: 20px;
            font-weight: 700;
            text-align: center;
        }
    }
}
