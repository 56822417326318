[data-dark-mode="false"] {
    .shops__banner {
        box-shadow: 0 33px 99px rgba(0,0,0,0.16);
    }
    .shops__item {
        background-color: $color-white;
        box-shadow: 0 33px 99px rgba(0,0,0,0.16);
        .light {
            display: block;
        }
        .dark {
            display: none;
        }
    }
}
[data-dark-mode="true"] {
    .shops__banner {
        background-color: var(--colorBgDarker);
    }
    .shops__item {
        .light {
            display: none;
        }
        .dark {
            display: block;
        }
    }
}
.shops {
    &__banner {
        background-color: $color-white;
        color: var(--colorFont);
        display: flex;
        flex-wrap: wrap;
        // justify-content: flex-end;
        align-items: center;
        align-content: center;
        margin-bottom: 45px;
        background-image: url("/templates/main/images/shops/papers.png");
        background-repeat: no-repeat;
        border-radius: 20px;
        background-position: bottom -160px center;
        background-size: 400px;
        padding: 30px 15px 150px;
        @media(min-width: 1025px) {
            padding: 60px 15px;
            background-position: bottom -255px right -50px;
            background-size: auto;
        }
        @media(min-width: 1280px) {
            background-position: bottom -240px right 0px;
        }
        @media(min-width: 1440px) {
            background-position: bottom -200px right 0;
        }
        &-text {
            font-size: 17px;
            line-height: 25px;
            font-weight: 500;
            text-align: center;
            @media(min-width: 1025px) {
                text-align: left;
                max-width: 750px;
                margin-left: 15px;
            }
            @media(min-width: 1280px) {
                margin-left: 45px;
            }
            @media(min-width: 1440px) {
                margin-left: 75px;
            }
            a {
                color: #FB3636;
            }
            .bold {
                font-weight: 700;
            }
            .contact {
                display: block;
                margin: 0;
            }
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: space-between;
        @extend .row;
        margin-bottom: 45px;
        padding-bottom: 3px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 15px;
            right: 15px;
            border-bottom: 3px solid #FB3636;
            bottom: -3px;
        }
    }
    &__select {
        margin-bottom: 10px;
        padding: 0 15px;
        ul {
            margin: 0;
            padding: 0;
        }
    }
    &__header {
        margin: 0;
        padding: 0;
        font-size: 35px;
        line-height: 43px;
        font-weight: 700;
        letter-spacing: 0.025em;
        text-transform: uppercase;
        padding: 0 15px;
        margin-bottom: 10px;
    }

    &__item {
        padding: 30px;
        background-color: var(--colorBgDarker);
        border-radius: 20px;
        height: 100%;
        & > * {
            display: block;
            font-size: 15px;
            line-height: 25px;
        }
        &-col {
            margin-bottom: 30px;
        }
        &-name {
            font-size: 18px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 16px;
        }
        &-address,
        &-phone,
        &-mail {
            margin-bottom: 3px;
        }
        &-address,
        &-phone,
        &-mail,
        &-www {
            display: flex;
            flex-wrap: wrap;
            font-weight: 500;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            img {
                margin-right: 8px;
                margin-top: 4px;

            }
            span {
                max-width: calc(100% - 22px);
            }
        }
        &-mail,
        &-www {
            color: #FB3636;
        }
        &-description {
            margin-top: 12px;
            border-top: 1px solid #EBEBEB;
            padding-top: 14px;
            display: flex;
            flex-wrap: wrap;
            img {
                margin-right: 8px;
                margin-top: 6px;
            }
            span {
                max-width: calc(100% - 22px);
            }
        }
    }
}
