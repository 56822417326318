[data-user-browser="firefox"] {
    .waitbox:after {
        filter: brightness(10%);
    }
}

.waitbox {
    display: none;
    position: fixed;
    padding: 15px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    &[data-open="true"] {
        display: flex;
        justify-content: center;
        align-items: center;
        @media(max-width:$md) {
            display: none;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(26,27,28, 0.6);
        backdrop-filter: blur(16px);
    }
    &__close {
        position: absolute;
        right: 25px;
        top: 25px;
        z-index: 3;
        cursor: pointer;
        img {
            pointer-events: none;
        }
    }
    &__wrapper {
        max-width: 1164px;
        background-color:#fff;
        z-index: 1001;
        position: relative;
        padding: 34px 55px 55px 55px;
        border-radius: 25px;
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            width: 100%;
            height: 187px;
            background-color: #002B44;
        }
    }
    &__title {
        font-size: 27px;
        line-height: 33px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 37px;
        z-index: 1;
        position: relative;
        color: #fff;
    }
    &__el {
        height: 100%;
        &-title {
            font-size: 21px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
}