.read-more-slider {
    &__title {
        font-size: 30px;
        font-weight: 700;
        padding-bottom: 15px;
        border-bottom: 3px solid $color-red;
        margin-bottom: 32px;
    }

    .swiper-button-prev {
        background-position: -1000px -1000px;
        background-repeat: no-repeat;
        background-color: $color-red;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        top: 80px;
        bottom: auto;
        left: -36px;
        right: auto;
        margin: auto;
        transition: opacity .2s ease-in-out;
        &:after {
            content: "";
            position: absolute;
            background-image: url("/templates/main/images/svg/chevron-down.svg");
            width: 18px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: 0;
            bottom: 0;
            margin: auto;
            right: 15px;
            transform-origin: 50% 50%;
            transform: rotate(90deg);
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
    }
    .swiper-button-next {
        background-position: -1000px -1000px;
        background-repeat: no-repeat;
        background-color: $color-red;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        top: 80px;
        bottom: auto;
        right: -36px;
        left: auto;
        margin: auto;
        transition: opacity .2s ease-in-out;
        &:after {
            content: "";
            position: absolute;
            background-image: url("/templates/main/images/svg/chevron-down.svg");
            width: 18px;
            height: 10px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            top: 0;
            bottom: 0;
            margin: auto;
            left: 15px;
            transform-origin: 50% 50%;
            transform: rotate(-90deg);
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
    }
}
