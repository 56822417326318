.category-menu {
    color: $color-black;
    max-width: 1440px;
    padding: 0 15px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    &__list {
        max-width: 1050px;
        margin: 0;
        padding: 0;
        list-style: none;
        margin-bottom: 30px;
        @media(min-width: $sm) {
            column-count: 2;
        }
        @media(min-width: $lg) {
            column-count: 3;
        }
        li {
            margin-bottom: 16px;
            font-weight: 700;
            position: relative;
            padding-left: 21px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 13px;
                height: 12px;
                background-image: url("/templates/main/images/svg/list-triangle.svg");
                background-position: center;
                background-repeat: no-repeat;
                top: 3px;
                left: 0;
            }
            a {
                transition: color .2s ease-in-out;
                &:hover {
                    @media(min-width: $lg) {
                        color: $color-red;
                    }
                }
            }
        }
    }
}
