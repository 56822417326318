.calendar-slider {
    margin: 70px 0 0 0;
    .swiper-wrapper {
        div:first-of-type {
            .calendar-slider__item {
                border-left: 1px solid rgba(112,112,112,.1);
            }
        }
    }
    .swiper-button-next,
    .swiper-button-prev {
        position: absolute;
        top: 180px;
        bottom: 0;
        width: 135px;
        height: 135px;
        background-color: $color-white;
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        background-image: none;
        border-radius: 50%;
        transition: opacity .2s ease-in-out;
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            display: block;
            width: 27px;
            height: 27px;
        }
        &.swiper-button-disabled {
            opacity: 0;
        }
    }
    .swiper-slide {
        height: auto;
    }
    .swiper-button-next {
        right: -70px;
        img {
            left: 25px;
            right: auto;
        }
    }
    .swiper-button-prev {
        left: -70px;
        img {
            right: 25px;
            left: auto;
        }
    }
    &__item {
        display: block;
        border: 1px solid rgba(112,112,112,.1);
        height: 100%;
        padding-bottom: 62px;
        border-left: none;
        position: relative;
        background-color: rgba(255,255,255,.1);
        .today {
            position: absolute;
            right: 0;
            top: 0;
        }
        &:first-child {
            // border-left: 1px solid rgba(112,112,112,.1);
        }
        &-date {
            padding-top: 30px;
            padding-left: 28px;
            margin-bottom: 37px;
            .day {
                font-size: 47px;
                line-height: 47px;
                font-weight: 700;
            }
            .month {
                font-size: 15px;
                line-height: 29px;
                font-weight: 700;
            }
            .day-bottom {
                color: #1A1B1C;
                font-size: 15px;
                line-height: 29px;
                text-transform: uppercase;
            }
        }
        &-image {
            margin: 0 -1px 47px -1px;
        }
        &-title {
            font-size: 15px;
            line-height: 29px;
            font-weight: 700;
            padding: 0 15px;
            text-align: center;
        }
    }
}