.breadcrumb {
    padding-left: 0;
    list-style: none;
    opacity: 0.7;
    font-size: 13px;
    line-height: 17px;
    position: relative;
    z-index: 3;
    li {
        display: inline-block;
        .divider {
            margin: 0 7px;
        }
    }
}
