.our-guide {
    &__title {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        // padding-bottom: 10px;
        border-bottom: 3px solid $color-red;
        .our-guide__breadcrumb {
            @media(max-width: $sm-max) {
                width: 100%;
            }
            .breadcrumb {
                font-weight: 400;
                margin: 0;
            }
        }
        .page-header__header {
            font-size: 35px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            @media(max-width: $sm-max) {
                width: 100%;
                text-align: center;
            }
        }
        .article-list__article-select {
            @media(max-width: $sm-max) {
                margin: 0 auto 15px;
            }
        }
        .cs-select.open::after {
            height: 382px;
        }
    }
    &__header-left {
        width: 100%;
        margin-bottom: 15px;
        @media(min-width: $lg) {
            width: auto;
            margin-bottom: 0;
        }
    }
    &__list {
        margin-top: -50px;
        .article-img-top--guide {
            &:hover {
                .article-img-top__image {
                    img {
                        transform: scale(1.2);
                    }
                }
            }
            .article-img-top__image {
                position: relative;
                img {
                    transform-origin: 50% 50%;
                    filter: blur(6px);
                    transform: scale(1.1);
                }
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: $color-blue-navy;
                    opacity: 0.3;
                    z-index: 3;
                }
            }
            .article-img-top__category {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                padding: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                text-transform: uppercase;
                color: $color-white;
                z-index: 6;
                font-weight: 700;
                letter-spacing: 0.075em;
                font-size: 18px;
                text-align: center;
            }
        }
    }
    &__menu {
        width: 100%;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: $color-black;
            justify-content: center;
            text-align: center;
            @media(min-width: $md) {
                text-align: left;
                justify-content: flex-start;
            }
            li {
                font-weight: 700;
                opacity: 1;
                transition: opacity .2s ease-in-out;
                margin: 0 15px 10px;
                font-size: 16px;
                &:before {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 13px;
                    margin-right: 12px;
                    background-image: url("/templates/main/images/svg/triangle-menu.svg");
                    margin-top: -2px;
                }
                @media(min-width: $md) {
                    font-size: 18px;
                    margin: 0 40px 0 0;
                }
                a {
                    transition: color .2s ease-in-out;
                }
                &:first-child {
                    order: 2;
                }
                &:nth-child(2) {
                    order: 1;
                }
                &:hover {
                    @media(min-width: $lg) {
                        a {
                            color: $color-red;
                        }
                    }
                }
                &.current {
                    text-decoration: underline;
                }
            }
        }
    }
}
