.author-more {
    margin-bottom: 60px;
    &__title {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin-bottom: 16px;
    }
    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        background-color: $color-white;
        border-radius: 20px;
        box-shadow: 0 3px 25px rgba(0,0,0,0.16);
        padding: 15px;
        @media(min-width: $sm) {
            padding: 30px;
        }
    }
    &__text {
        width: 100%;
        @media(min-width: $sm) {
            padding-left: 30px;
            max-width: calc(100% - 90px);
        }
    }
    &__image {
        border-radius: 10px;
        overflow: hidden;
        margin-bottom: 30px;
        @media(min-width: $sm) {
            margin-bottom: 0;
        }
    }
    .author-more__mail {
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        margin-bottom: 28px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        align-content: center;
        text-decoration: none;
        color: #FB3636 !important;
        img {
            margin-right: 10px;
        }
    }
    &__name {
        font-size: 20px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 7px;
    }
    &__description {
        font-size: 15px;
        line-height: 25px;
        margin-bottom: 20px;
    }
    .author-more__btn {
        width: 100%;
        max-width: 330px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        border: 2px solid #DDDDDD;
        border-radius: 10px;
        color: $color-font-main !important;
        font-weight: 700;
        text-decoration: none;
        font-size: 14px;
        min-height: 48px;
        text-align: center;
        padding: 5px;
    }
}
