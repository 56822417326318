.burger-open {
    overflow: hidden;
    @media(min-width: $xl) {
        padding-right: 17px;
    }
}
.burger-menu {
    position: fixed;
    left: auto;
    right: 0;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    width: 100%;
    z-index: 9999;
    pointer-events: none;
    height: 100%;
    overflow: hidden;
    .main__select-wrap {
        margin: 0 auto 30px;
        color: $color-white;
    }
    &.main {
        .burger-menu__logo {
            @media(max-width: $sm-max) {
                margin-top: 60px;
            }
        }
    }
    .item-1244 {
        a {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            color: $color-white;
            height: 65px;
            border-radius: 16px;
            text-transform: uppercase;
            font-size: 15px;
            width: 100%;
            max-width: 330px;
            margin: 0 auto;
            background: linear-gradient(45deg,#3fb693 0,#35b7c1 100%);
            font-weight: 700;
            letter-spacing: 0.075em;
            margin-bottom: 30px;
        }
    }
    .item-1155 {
        display: none;
    }
    &__wrapper {
        position: relative;
        background-color: $color-white;
        box-shadow: 0 0 99px rgba(0,0,0,0.1);
        z-index: 3;
        height: 100%;
        transform: translateX(100%);
        opacity: 0;
        transition: transform .2s ease-in-out, opacity .2s ease-in-out;
        right: 0;
        left: auto;
        margin-left: auto;
        width: 100%;
        max-width: 360px;
        padding: 55px 15px 50px;
        @media(min-width: $md) {
            max-width: 465px;
            padding: 25px 30px 50px;
        }
    }
    &__wrap {
        overflow: auto;
        height: 100%;
        // max-height: calc(100% - 240px);
    }
    &__logo {
        a {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding-bottom: 20px;
            margin-bottom: 25px;
            border-bottom: 2px solid $color-red;
        }
        img {
            display: block;
        }
        &-text {
            margin-left: 30px;
            margin-left: 30px;
            position: relative;
            &:after {
                content: "";
                height: 22px;
                left: -15px;
                width: 2px;
                background-color: $color-font-main;
                opacity: 0.5;
                position: absolute;
            }
        }
    }
    &.open {
        opacity: 1;
        top: 0;
        pointer-events: all;
        .burger-menu__container {
            height: auto;
            padding-bottom: 30px;
        }
        .burger-menu__burger {
            span {
                background-color: $color-white;
                &:first-child {
                    opacity: 0;
                }
                &:nth-child(2) {
                    transform: rotate(45deg);
                }
                &:nth-child(3) {
                    transform: rotate(-45deg);
                }
                &:last-child {
                    opacity: 0;
                }
            }
        }
        .burger-menu__background {
            opacity: 1;
            pointer-events: all;
        }
        .burger-menu__wrapper {
            transform: translateX(0);
            opacity: 1;
        }
    }
    &.sticky {
        opacity: 1;
        top: 0;
        pointer-events: all;
        .burger-menu__wrap {
            padding: 0;
            height: 80px;
        }
    }
    &__background {
        position: fixed;
        z-index: 0;
        opacity: 0;
        pointer-events: none;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(26,27,28,0.45);
        transition: opacity .2s ease-in-out;
    }
    &__menu {
        margin-left: auto;
        justify-self: flex-end;
        // padding-bottom: 100px;
        ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            list-style: none;
            margin: 0;
            padding: 0;
            transition: color .2s ease-in-out;
            li {
                font-size: 15px;
                font-weight: 400;
                // display: none;
                transition: all .2s ease-in-out;
                width: 100%;
                @media(min-width: $xl) {
                    display: block;
                }
                & > a {
                    position: relative;
                    transition: color .2s ease-in-out;
                    z-index: 3
                }
                &:last-child {
                    margin-right: 0;
                }
                &.current, &.active {
                    & > a {
                        color: $color-red;
                    }
                    & > a:after {
                        right: 0;
                    }
                }
                & > ul {
                    .current, .active {
                        font-weight: 500;
                    }
                }
            }

            .item-1020, .item-807 {
                display: none;
            }
            .item-101, .item-1055 {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                font-size: 25px;
                margin-bottom: 22px;

                &:before {
                    background-size: 50%;
                    content: "";
                    width: 95px;
                    height: 95px;
                    margin-right: 23px;
                    box-shadow: 0 30px 99px rgba(0,0,0,0.18);
                    background-color: $color-white;
                    border-radius: 10px;
                    background-position: center;
                    background-repeat: no-repeat;
                }
                ul {
                    display: none;
                }
            }
            .item-1055 {
                &:before {
                    background-image: url("/templates/main/images/svg/knowledge-black.svg");
                }
            }
            .item-101 {
                &:before {
                    background-image: url("/templates/main/images/svg/therapist-black.svg");
                }
            }
            .item-1160 {
                margin-bottom: 15px;
            }
            .item-1160, .item-1166, .item-1317 {
                & > a {
                    font-size: 15px;
                    font-weight: 700;
                    text-transform: uppercase;
                    padding-bottom: 20px;
                    border-bottom: 2px solid $color-gray-light;
                    display: block;
                    margin-bottom: 25px;
                    letter-spacing: 0.075em;
                }
                ul {
                    li {
                        margin-bottom: 5px;
                        font-size: 17px;
                    }
                }
            }
            .item-1167, .item-1168 {
                & > a {
                    font-size: 13px;
                    font-weight: 700;
                    letter-spacing: 0.15em;
                    text-transform: uppercase;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                    &:before {
                        content: "";
                        display: inline-block;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
            .item-1167 {
                margin-bottom: 25px;
                a {
                    &:before {
                        width: 20px;
                        height: 20px;
                        background-image: url("/templates/main/images/svg/newsletter-red.svg");
                        margin-right: 10px;
                    }
                }
            }
            .item-1168 {
                a {
                    &:before {
                        width: 18px;
                        height: 14px;
                        background-image: url("/templates/main/images/svg/contact-red.svg");
                        margin-bottom: 1px;
                        margin-right: 12px;
                    }
                }
            }
        }
        &--subpages {
            ul {
                margin: -25px -30px 27px;
                position: relative;
                z-index: 3;
                list-style: none;
                padding: 0;
                display: flex;
                flex-wrap: wrap;
                @media(max-width: $sm-max) {
                    opacity: 0;
                    pointer-events: none;
                }
                li {
                    width: 50%;
                    text-align: center;
                    border-bottom: 1px solid $color-gray-border;
                    min-height: 60px;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    a {
                        min-height: 34px;
                        border-radius: 10px;
                        padding: 4px 15px 0;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        font-size: 15px;
                        text-transform: uppercase;
                        font-weight: 700;
                        letter-spacing: 0.075em;
                        opacity: 0.5;
                    }
                    &.current, &.active, &.alias-parent-active {
                        a {
                            background-color: rgba(26,26,28,0.1);
                            opacity: 1;
                        }
                    }
                    &.item-1151 {
                        border-left: 1px solid $color-gray-border;
                    }
                    &.item-1149,
                    &.item-1152,
                    &.item-1153,
                    &.item-1154 {
                        display: none;
                    }
                }
            }
        }
    }
    &__burger {
        cursor: pointer;
        position: absolute;
        top: 0;
        background-color: $color-red;
        border-bottom-left-radius: 5px;
        right: 0;
        width: 50px;
        height: 50px;
        @media(min-width: $sm) {
        }
        @media(min-width: $md) {
            width: 60px;
            height: 60px;
            border-top-left-radius: 5px;
            right: 100%;
        }
        span {
            display: block;
            width: 40%;
            height: 2px;
            border-radius: 4px;
            position: absolute;
            background-color: $color-white;
            left: 0;
            right: 0;
            margin: auto;
            transform-origin: 50% 50%;
            transition: all .2s ease-in-out;
            &:first-child {
                top: 0;
                bottom: auto;
            }
            &:nth-child(2) {
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:nth-child(3) {
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:last-child {
                bottom: 0;
                top: auto;
            }
        }
    }
    &__search {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        border-bottom: 2px solid $color-gray-light;
        display: block;
        margin-bottom: 45px;
        letter-spacing: 0.075em;
        cursor: pointer;
        @media(min-width: $sm) {
            display: none;
        }
    }
    &.subpages {
        .burger-menu__wrap {
            padding-right: 30px;
        }
        .burger-menu__menu {
            height: calc(100% - 70px);
            &--main {
                & > li {
                    margin-bottom: 25px;
                    & > a {
                        font-size: 15px;
                        font-weight: 700;
                        text-transform: uppercase;
                        padding-bottom: 20px;
                        border-bottom: 2px solid $color-gray-light;
                        display: block;
                        margin-bottom: 20px;
                        letter-spacing: 0.075em;
                    }
                    &.deeper,
                    &.parent {
                        position: relative;
                        &:after {
                            content: "";
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin: auto;
                            width: 25px;
                            height: 25px;
                            display: block;
                            z-index: 3;
                            background-image: url("/templates/main/images/svg/triangle.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 14px;
                            transition: transform .2s ease-in-out;
                        }
                        &.open {
                            &:after {
                                transform: rotate(180deg);
                            }
                        }
                    }
                    & > ul {
                        display: none;
                        & > li {
                            padding-left: 35px;
                            font-size: 16px;
                            margin-bottom: 10px;
                            &.deeper,
                            &.parent {
                                position: relative;
                                &:after {
                                    content: "";
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    right: auto;
                                    margin: auto;
                                    width: 25px;
                                    height: 25px;
                                    display: block;
                                    z-index: 3;
                                    background-image: url("/templates/main/images/svg/triangle.svg");
                                    background-position: center;
                                    background-repeat: no-repeat;
                                    background-size: 13px;
                                    transition: transform .2s ease-in-out;
                                }
                                &.open {
                                    &:after {
                                        transform: rotate(180deg);
                                    }
                                }
                            }
                            & > a {
                                margin-bottom: 10px;
                                display: block;
                            }
                            & > ul {
                                padding-left: 20px;
                                li {
                                    font-size: 16px;
                                    margin-bottom: 5px;
                                    &:last-child {
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .burger-menu__logo {
            img {
                max-width: 275px;
                width: 100%;
            }
        }
    }
    .item-disabled {
        &:before {
            content: "";
            z-index: 3;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    #burger-top {
        .disabled-link {
            pointer-events: none;
            cursor: normal;
        }
        .main__select-wrap {
            margin: 0 auto 30px;
            .cs-list-wrap {
                position: relative;
                top: 0;
            }
            .cs-list {
                display: block;
            }
            .cs-select:after {
                box-shadow: none;
            }
            .cs-select--selected-list.open .cs-list-wrap {
                height: 380px;
            }
        }
    }
    #burger-list-problems {
        &.cs-list {
            li[class*="item-"] {
                font-size: 13px;
                line-height: 18px;
                text-transform: uppercase;
                font-weight: 700;
                letter-spacing: 0.1em;
                margin-bottom: 16px;
                & > a {
                    margin-bottom: 8px;
                    opacity: 0.3;
                    pointer-events: none;
                }
                &.cs-result-list {
                    & > a {
                        pointer-events: all;
                        opacity: 1;
                        margin-bottom: 0;
                    }
                }
                & > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    li {
                        text-transform: none;
                        letter-spacing: normal;
                        font-size: 16px;
                        opacity: 1;
                        font-weight: 400;
                        margin-bottom: 0;
                        & > a {
                            margin-bottom: 0;
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
            }
        }
    }
}
#burger-menu {
    &.main {
        .burger-menu__wrap {
            &,
            .simplebar-wrapper,
            .simplebar-mask,
            .simplebar-offset,
            .simplebar-content-wrapper,
            .simplebar-content {
                overflow: visible !important;
            }
        }
    }
}
