.slider-spec {
    margin-bottom: 125px;
    padding-left: 15px;
    @media(max-width: 399px) {
        padding-right: 15px;
    }
    @media(min-width: 1440px) {
        padding-left: calc((100% - 1410px) / 2);
    }
    &__title {
        font-size: 18px;
        font-weight: 700;
    }
    .swiper-wrapper {
        padding-top: 23px;
        height: auto;
    }
    .swiper-slide {
        height: auto;
    }
}
.slide-spec {
    min-height: 300px;
    border-radius: 10px;
    color: $color-white;
    padding: 35px;
    position: relative;
    overflow: hidden;
    transition: transform .2s ease-in-out;
    &:hover {
        @media(min-width: $lg) {
            transform: translateY(-15px);
        }
    }
    &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        background-repeat: no-repeat;
    }
    &.item-881 {
        background-color: #80C1DB;
        &:after {
            top: auto;
            bottom: -30px;
            right: -30px;
            background-image: url("/templates/main/images/static/medycyna.png");
        }
    }
    &.item-883 {
        background-color: #A497C9;
    }
    &.item-884 {
        background-color: #91D2AF;
        &:after {
            background-position: right;
            background-image: url("/templates/main/images/static/dieta.png");
        }
    }
    &.item-885 {
        background-color: #F69383;
        &:after {
            background-size: 53%;
            background-position: right;
            background-image: url("/templates/main/images/static/energy.png");
            top: -50px;
            @media(max-width: $md-max) {
                top: -60px;
                right: -40px;
            }
        }
    }
    &.item-886 {
        background-color: #FCBB7E;
        &:after {
            background-position: 85% 0;
            background-image: url("/templates/main/images/static/psycho.png");
        }
    }
    &__title {
        font-weight: 700;
        font-size: 24px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 40px;
        position: relative;
        z-index: 3;
        span {
            font-size: 17px;
            display: block;
            letter-spacing: 0.2em;
            font-weight: 400;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: 700;
        position: relative;
        z-index: 3;
    }
}
