.treatment {
    .bg-blue {
        padding-bottom: 80px;
    }
    &__title {
        font-size: 35px;
        line-height: 48px;
        margin-bottom: 40px;
        border-bottom: 3px solid #FB3636;
        text-transform: uppercase;
        padding-top: 3px;
        color: $color-black;
        font-weight: 400;
        @media(max-width: 500px) {
            font-size: 28px;
            line-height: 38px;
        }
    }
    &__content {
        margin-top: -50px;
    }
    &__menu {
        opacity: 0;
        transition: opacity .2s ease-in-out;
        &.loaded {
            opacity: 1;
        }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            color: $color-black;
            li {
                margin-right: 0;
                display: inline-block;
                width: auto;
                a {
                    transition: color .2s ease-in-out;
                    &:hover {
                        @media(min-width: $lg) {
                            color: $color-red;
                        }
                    }
                }
            }
        }
        &--column {
            opacity: 1;
            width: 100%;
            ul {
                max-width: 1200px;
                display: flex;
                flex-wrap: wrap;
                li {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    font-size: 16px;
                    font-weight: 700;
                    margin-bottom: 30px;
                    line-height: normal;
                    @media(min-width: $sm) {
                        max-width: 50%;
                    }
                    @media(min-width: 800px) {
                        max-width: calc(100% / 3);
                    }
                    @media(min-width: $lg) {
                        max-width: 25%;
                    }
                    &:before {
                        content: "";
                        display: inline-block;
                        width: 10px;
                        height: 13px;
                        margin-right: 12px;
                        background-image: url("/templates/main/images/svg/triangle-menu.svg");
                        margin-top: -2px;
                    }
                }
            }
        }
    }
    .swiper-treatment-next, .swiper-treatment-prev {
        background-image: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        background-color: $color-blue;
        top: 0;
        bottom: 0;
        margin: auto;
        transition: opacity .2s ease-in-out;
        img {
            width: 8px;
        }
        &.swiper-button-disabled {
            opacity: 0;
            pointer-events: none;
        }
    }
    .swiper-treatment-next {
        left: auto;
        right: -26px;
        justify-content: flex-start;
        padding-left: 12px;
        box-shadow: -15px 0 15px 15px rgba(0, 43, 68, 0.4);

    }
    .swiper-treatment-prev {
        left: -26px;
        right: auto;
        justify-content: flex-end;
        padding-right: 12px;
        box-shadow: 15px 0 15px 15px rgba(0, 43, 68, 0.4);
    }
}
