.job-offer {
    a {
        display: block;
        @media (min-width: 486px) {
            display: flex;
        }
    }
    color: var(--colorFont);
    background-color: var(--colorBg);
    border: 2px solid var(--colorFont);
    border-radius: 24px;
    padding: 15px;
    margin-bottom: 30px;
    font-weight: 700;
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.16);
    &__text {
        margin: 0;
        @media (min-width: 486px) {
           margin-left: 30px;
        }
        max-width: 625px;
    }
    &__title {
        font-size: 18px;
        background: $main-gradient;
        color: #ffffff;
        padding: 16px 22px 11px 22px;
        text-transform: uppercase;
        max-width: 253px;
        border-radius: 5px;
        text-align: center;
        margin: 20px auto;
        @media (min-width: 486px) {
            margin: 0;
            margin-bottom: 20px;
        }
        @media (min-width: 768px) {
            font-size: 22px;
        }
    }
    &__description {
        font-size: 15px;
        text-align: center;
        @media (min-width: 486px) {
            text-align: left;
        }
    }
    &__image {
        text-align: center;
        img {
            border-radius: 15px;
            min-width: 150px;
            max-width: 600px;
            max-height: 150px;
            width: 100%;
            object-fit: cover;
        }
    }
}

.vertical {
    a {
        display: block;
    }
    .job-offer__description {
        text-align: center;
    }
    .job-offer__text {
        margin: 0 auto;
    }
    .job-offer__title {
        text-align: center;
        font-size: 18px;
        margin: 20px auto;
    }
}
