.subscription-neboa {
    max-width: 1010px;
    margin: 0 auto 30px;
    a {
        display: block;
    }
    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}