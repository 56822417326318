.burger-open {
    #sticky-menu {
        @media(min-width: 1025px) {
            padding-right: 17px;
        }
    }
}
#sticky-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
    z-index: 999;
    transition: transform .2s ease-in-out;
    transform: translateY(calc(-100% - 10px));
    box-shadow: 0 0 99px rgba(0,0,0,0.16);
    &.sticked {
        transform: translateY(0);
        pointer-events: all;
    }
    .top-menu {
        position: relative;
        top: 0;
        height: 50px;
        display: none;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: $color-blue-navy;
        }
    }
    .sticky {
        &__bottom {
            padding: 13px 0;
            background-color: $color-white;
        }
        &__search {
            margin-right: 30px;
            position: relative;
            z-index: 5;
            cursor: pointer;
            margin-left: auto;
            display: none;
            @media(min-width: 360px) {
                display: block;
            }
            @media(min-width: 1366px) {
                margin-left: 0;
            }
        }
        &__logo {
            & > a {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                align-content: center;
                color: $color-font-main;
                font-size: 0;
            }
            &-text {
                color: $color-font-main;
                margin-left: 30px;
                font-size: 16px;
                position: relative;
                padding-top: 2px;
                font-weight: 500;
                display: none;
                @media(min-width: $md) {
                    display: block;
                }
                &:after {
                    content: "";
                    height: 22px;
                    left: -16px;
                    width: 2px;
                    background-color: $color-font-main;
                    opacity: 0.5;
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
        }
        &__wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
        }
        &__burger {
            cursor: pointer;
            width: 20px;
            height: 14px;
            position: relative;
            order: 2;
            z-index: 5;
            margin-left: auto;
            margin-right: 0;
            @media(min-width: 360px) {
                margin-left: 0;
            }
            @media(min-width: $xl) {
                margin-left: 0;
            }
            span {
                display: block;
                width: 100%;
                height: 2px;
                border-radius: 4px;
                position: absolute;
                background-color: $color-font-main;
                left: 0;
                transform-origin: 50% 50%;
                transition: all .2s ease-in-out;
                &:first-child {
                    top: 0;
                    bottom: auto;
                }
                &:nth-child(2) {
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:nth-child(3) {
                    top: 0;
                    bottom: 0;
                    margin: auto;
                }
                &:last-child {
                    bottom: 0;
                    top: auto;
                }
            }
        }
        &__menu {
            justify-self: flex-end;
            margin-left: auto;
            display: none;
            @media(min-width: 1366px) {
                display: block;
            }
            .item-1317 {
                display: none;
            }
            .hp-menu {
                & > li {
                    position: relative;
                    & > a {
                        position: relative;
                        z-index: 3;
                    }
                    // &:hover {
                    //     @media(min-width: $lg) {
                    //         &:before {
                    //             content: "";
                    //             position: absolute;
                    //             left: calc((100% - 275px) / 2);
                    //             top: -28px;
                    //             bottom: -100px;
                    //             width: 275px;
                    //         }
                    //         & > a {
                    //             color: $color-red;
                    //             &:before {
                    //                 content: "";
                    //                 position: absolute;
                    //                 left: calc((100% - 275px) / 2);
                    //                 top: auto;
                    //                 bottom: -88px;
                    //                 width: 275px;
                    //                 height: 60px;
                    //             }
                    //         }
                    //         & > .sub-menu {
                    //             opacity: 1;
                    //             ul {
                    //                 pointer-events: all;
                    //             }
                    //         }
                    //         &:after {
                    //             opacity: 1;
                    //         }
                    //     }
                    // }
                    & > .sub-menu {
                        position: absolute;
                        top: calc(100% + 24px);
                        opacity: 0;
                        pointer-events: none;
                        background-color: $color-white;
                        width: 275px;
                        border-radius: 15px;
                        border-top-left-radius: 0;
                        border-top-right-radius: 0;
                        background-color: $color-blue-bg;
                        transition: opacity .2s ease-in-out;
                        box-shadow: 0 60px 99px rgba(0,0,0,0.16);
                        z-index: 99;
                        left: calc((100% - 275px) / 2);
                        display: block;
                        &:after {
                            content: "";
                            position: absolute;
                            top: -1px;
                            left: 0;
                            right: 0;
                            background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
                            height: 3px;
                            margin: auto;
                        }
                        .sub-menu__header {
                            min-height: 60px;
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            align-content: center;
                            pointer-events: none;
                            &:before {
                                content: "";
                                background-position: center;
                                background-repeat: no-repeat;
                                margin-right: 12px;
                            }
                            &:after {
                                content: "";
                                font-size: 18px;
                                font-weight: 400;
                                color: $color-white;
                            }
                        }
                        ul {
                            margin: 0;
                            padding: 0;
                            list-style: none;
                        }
                        ul > li {
                            margin: 0;
                            padding: 0;
                            text-align: center;
                            width: 100%;
                            color: $color-font-main;
                            background-color: $color-white;
                            &:first-child {
                                padding-top: 20px;
                            }
                            &:last-child {
                                padding-bottom: 20px;
                                border-bottom-left-radius: 15px;
                                border-bottom-right-radius: 15px;
                            }
                            & > a {
                                display: block;
                                min-height: 40px;
                                line-height: 40px;
                                font-size: 16px;
                                font-weight: 500;
                            }
                        }
                    }
                }
                .item-1055 {
                    .sub-menu {
                        & > ul {
                            & > li {
                                &:nth-last-child(2) {
                                    padding-bottom: 20px;
                                    border-bottom-left-radius: 15px;
                                    border-bottom-right-radius: 15px;
                                }
                            }
                        }
                        &:after {
                            width: 115px;
                        }
                        .sub-menu__header {
                            &:before {
                                background-image: url("/templates/main/images/svg/knowledge.svg");
                                width: 28px;
                                height: 24px;
                            }
                            &:after {
                                content: "Wiedza";
                            }
                        }
                    }
                }
                .item-101 {
                    .sub-menu {
                        &:after {
                            width: 175px;
                        }
                        .sub-menu__header {
                            &:before {
                                background-image: url("/templates/main/images/svg/therapist.svg");
                                width: 34px;
                                height: 35px;
                            }
                            &:after {
                                content: "Terapeuci";
                            }
                        }
                    }
                }

            }
            .therapist-menu {
                color: $color-font-main;
                &__link {
                    border-color: $color-font-main;
                    color: $color-font-main;
                    font-weight: 500;
                }
                &__select-wrap {
                    display: block;
                }
            }
            .cs-title {
                font-weight: 500;
            }
            .cs-btn {
                &:after {
                    background-image: url("/templates/main/images/svg/chevron-down-black.svg");
                }
            }
            .cs-list-wrap {
                top: calc(100% + 24px);
                background-color: $color-white;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                box-shadow: 0 0 99px rgba(0,0,0,0.16);
                ul {
                    li {
                        a {
                            color: $color-font-main;
                        }
                    }
                }
                &:after {
                    display: none;
                }
            }
            .item-1020 {
                display: none;
            }
            .item-1155 {
                display: none;
            }
            & > ul {
                display: flex;
                flex-wrap: wrap;
                margin: 0;
                padding: 0;
                list-style: none;
                & > li {
                    margin-right: 30px;
                    & > a {
                        transition: color .2s ease-in-out;
                        display: inline;
                        color: $color-font-main;
                        position: relative;
                        z-index: 3;
                        font-weight: 500;
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 100%;
                            height: 1px;
                            background-color: #fff;
                            margin: 0;
                            padding: 0;
                            background-image: none;
                            width: auto;
                        }
                    }
                    .sub-menu {
                        display: none;
                    }
                }
            }
            & > #burger-menu__menu--main {
                & > li {
                    position: relative;
                    font-size: 16px;
                    &:hover {
                        @media(min-width: $lg) {
                            text-decoration: underline;
                        }
                    }
                    &.current, &.active {
                        &:hover {
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left: -5px;
                        right: -5px;
                        top: calc(100% + 22px);
                        height: 4px;
                        background-color: $color-red;
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                        z-index: 9;
                        pointer-events: none;
                    }
                    & > a {
                        position: relative;
                        font-weight: 700;
                        z-index: 5;
                    }
                    // &:hover {
                    //     @media(min-width: $lg) {
                    //         &:before {
                    //             opacity: 1;
                    //         }
                    //         &:after {
                    //             content: "";
                    //             display: block;
                    //             width: 440px;
                    //             top: -22px;
                    //             bottom: -42px;
                    //             left: calc((100% - 440px) / 2);
                    //             height: -22px;
                    //             position: absolute;
                    //             z-index: 3;
                    //         }
                    //         & > a {
                    //             color: $color-font-main;
                    //         }
                    //         & > .header-module {
                    //             opacity: 1;
                    //             pointer-events: all;
                    //         }
                    //     }
                    // }
                    &.item-814 {
                        // &:hover {
                        //     @media(min-width: $lg) {
                        //         &:before {
                        //             display: none;
                        //         }
                        //         & > .header-module {
                        //             opacity: 0;
                        //             pointer-events: none;
                        //             display: none;
                        //         }
                        //         &:after {
                        //             opacity: 0;
                        //         }
                        //     }
                        // }
                    }
                    &.item-1169 {
                        &:before {
                            display: none;
                        }
                    }
                    & > .header-module {
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        width: 440px;
                        margin: auto;
                        top: calc(100% + 24px);
                        left: calc((100% - 440px) / 2);
                        background-color: $color-white;
                        transition: opacity .2s ease-in-out;
                        color: $color-font-main;
                        font-size: 16px;
                        margin: 0;
                        padding: 35px 42px 30px;
                        box-shadow: 0 60px 99px rgba(0,0,0,0.3);
                        border-bottom-left-radius: 7px;
                        border-bottom-right-radius: 7px;
                        display: flex;
                        flex-wrap: wrap;
                        & > ul {
                            list-style: none;
                            order: 1;
                            margin: 0 0 15px;
                            padding: 0;
                            & > li {
                                color: $color-font-main;
                                margin-bottom: 5px;
                                font-weight: 500;
                                a {
                                    color: $color-font-main;
                                }
                                ul {
                                    display: none;
                                    a {
                                        color: $color-font-main;
                                    }
                                }
                                &.current, &.active {
                                    &, & > a {
                                        color: $color-red;
                                    }
                                }
                            }
                        }
                        .header-module__wrap {
                            order: 2;
                        }
                    }
                }
            }
        }
    }
}
