.important-topics {
    background: linear-gradient(135deg, rgba(0,43,68,1) 0%,rgba(38,130,138,1) 100%);
    color: #fff;
    padding: 42px 15px;
    margin-top: 60px;
    margin-bottom: 54px;
    // box-shadow: 0 25px 60px rgba(100, 190, 243, .4);
    &__wrapper {
        display: flex;
        align-items: center;
        @media(max-width: 1024px) {
            flex-wrap: wrap;
        }
    }
    &__title {
        font-size: 35px;
        line-height: 43px;
        white-space: nowrap;
        margin-right: 48px;
        text-transform: uppercase;
        @media(max-width: 1024px) {
            max-width: 100%;
            width: 100%;
            margin-bottom: 15px;
        }
    }
    &__list {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        @media(max-width: 1024px) {
            max-width: 100%;
            width: 100%;
        }
        &-el {
            margin-right: 10px;
            margin-bottom: 10px;
            background-color: #F7F7F7;
            color: #1F1F1F;
            border-radius: 5px;
            font-weight: 700;
            max-height: 29px;
            height: 100%;
            a {
                padding: 5px 10px;
                display: block;
            }
            &.red {
                background: linear-gradient(135deg, rgba(241,102,85,1) 0%,rgba(251,54,54,1) 100%);
                color: #fff;
                text-transform: uppercase;
            }
        }
    }
}