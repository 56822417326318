html {
	scroll-behavior: smooth;
}

.magazine-single {
	.disable-link {
		opacity: 0.2;
		pointer-events: none;
		cursor: normal;
	}
	&__archive-btn {
		font-size: 15px;
		font-weight: 700;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		margin: 0 auto 36px;
		height: 60px;
		border-radius: 5px;
		border: 2px solid rgba(255,255,255,0.2);
		max-width: 320px;
		width: 100%;
		img {
			margin-right: 15px;
		}
	}
	&__background {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		height: 610px;
		background-color: $color-white;
		z-index: -1;
	}
	&__top {
		background-color: $color-white;
		color: $color-black;
		padding-bottom: 40px;
		margin-bottom: 55px;
		.bg-blue__return {
			margin-bottom: 10px;
		}
	}
	&__toc-main {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		max-width: 560px;
		margin: 0 auto 40px;
		position: relative;
		@media(min-width: $lg) {
			display: none;
		}
		&-title {
			height: 60px;
			border: 2px solid #E1E1E1;
			border-bottom: none;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			align-content: center;
			padding: 0 22px;
			text-transform: uppercase;
			font-size: 15px;
			font-weight: 700;
			cursor: pointer;
			position: relative;
			user-select: none;
			background-color: $color-white;
			z-index: 3;
			img {
				margin-right: 20px;
			}
			.triangle {
				position: absolute;
				width: 13px;
				height: 10px;
				top: 0;
				bottom: 0;
				right: 30px;
				margin: auto;
			}
		}
		&-list {
			height: 0;
			overflow: hidden;
			padding-left: 30px;
			position: absolute;
			left: 0;
			top: 60px;
			background-color: $color-white;
			border: 2px solid #E1E1E1;
			border-top: none;
			width: 100%;
			padding: 0 30px;
			font-size: 17px;
			font-weight: 500;
			box-shadow: 3px 6px 50px rgba(0,0,0,0.2);
		}
		&.open {
			.magazine-single__toc-main-list {
				height: auto;
				padding: 10px 30px;
			}
		}
	}
	.breadcrumb {
		justify-content: center;
		@media(min-width: $lg) {
			justify-content: flex-start;
		}
	}
	&__newspaper {
		margin-top: 15px;
		margin-bottom: 15px;
		@media(min-width: $lg) {
			display: none;
		}
		img {
			box-shadow: 0 30px 99px rgba(0,0,0,0.32);
			margin: 0 auto;
			display: block;
		}
	}
	&__ad {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		justify-content: center;
		margin: 0 7.5px;
		&:after {
			content: "";
			top: 0;
			left: 0;
			width: 1px;
		}
		&-item {
			max-width: 50%;
			width: 100%;
		}
	}
	&__project {
		margin-bottom: 20px;
		&-image {
			border: 1px solid $color-gray-border;
			margin-bottom: 20px;
			img {
				display: block;
			}
		}
		&-title {
			font-size: 15px;
			font-weight: 700;
			letter-spacing: -0.015em;
			margin-bottom: 10px;
		}
	}
	&__article {
		width: 100%;
		padding: 0 15px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		align-content: flex-start;
		margin-bottom: 30px;
		@media(min-width: $sm) {
			max-width: 50%;
		}
		@media(min-width: $md) {
			max-width: calc(100% / 3);
		}
		&-image {
			margin-bottom: 20px;
			border-radius: 15px;
			overflow: hidden;
			img {
				display: block;
			}
		}
		&-title {
			font-size: 17px;
			font-weight: 700;
			letter-spacing: -0.015em;
			margin-bottom: 10px;
		}
		&-introtext {
			font-size: 15px;
			line-height: 22px;
			font-weight: 400;
			margin-bottom: 0;
			color: $color-font-main;
		}
		&:hover {
			.magazine-single__article-introtext {
				color: $color-font-main;
			}
		}
		&.single {
			width: 100%;
			max-width: none;
			align-items: center;
			align-content: center;
			margin-bottom: 0;
			.magazine-single__article-image {
				margin-bottom: 0;
				width: 100%;
				margin-bottom: 20px;
				@media(min-width: $sm) {
					width: 32%;
					margin-bottom: 0;
				}
			}
			.magazine-single__article-text {
				width: 100%;
				@media(min-width: $sm) {
					padding-left: 40px;
					width: 68%;
				}
			}
			.magazine-single__article-title {
				font-weight: 700;
				margin-bottom: 15px;
				@media(min-width: $sm) {
					font-size: 22px;
					line-height: 25px;
				}
			}
			.magazine-single__article-introtext {
				font-size: 15px;
				line-height: 23px;
			}
		}
	}
    &__container {
        position: relative;
        z-index: 3;
        padding: 15px 15px;
        @media(min-width: $sm) {
            padding: 15px 25px;
        }
    }
    &__title {
        font-size: 48px;
        line-height: 52px;
        font-weight: 700;
		text-align: center;
		margin-bottom: 15px;
		span {
			display: inline-block;
			padding-top: 18px;
			border-top: 3px solid $color-red;
		}
		@media(min-width: $lg) {
			text-align: left;
		}
    }
    &__number {
        font-size: 24px;
		line-height: 30px;
        font-weight: 500;
        margin-bottom: 28px;
		text-align: center;
		text-transform: capitalize;
		@media(min-width: $lg) {
			text-align: left;
		}
    }
    &__links {
        display: flex;
        flex-wrap: wrap;
		justify-content: center;
		@media(min-width: $lg) {
			justify-content: flex-start;
		}
		&-left {
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			max-width: 400px;
			margin: 0 5px;
			@media(min-width: $lg) {
				margin: 0;
				margin-right: 22px;
			}
		}
		&-right {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			margin: 0 5px;
			@media(min-width: $sm) {
				height: 70px;
				justify-content: flex-start;
			}
			@media(min-width: $lg) {
				margin: 0;
			}
			span {
				@media(max-width: $xs-max) {
					display: block;
					width: 100%;
					margin-bottom: 5px;
					text-align: center;
					br {
						display: none;
					}
				}
				@media(min-width: $sm) {
					margin-right: 22px;
				}
			}
			.google {
				@media(max-width: $xs-max) {
					margin-left: 5px;
				}
			}
			.appstore {
				@media(max-width: $xs-max) {
					margin-right: 5px;
				}
				@media(min-width: $sm) {
					margin-right: 22px;
				}
			}
		}
		&-wrap {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			@media(max-width: $md-max) {
				justify-content: center;
			}
		}
    }
	&__apps {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		align-content: center;
		justify-content: center;
		width: 100%;
		padding-top: 20px;
		@media(min-width: $lg) {
			justify-content: flex-start;
		}
		span {
			width: 100%;
			text-align: center;
			margin-bottom: 5px;
			@media(min-width: $sm) {
				width: auto;
				margin-bottom: 0;
				text-align: left;
				margin: 0 20px;
			}
			@media(min-width: $xl) {
				margin: 0 40px;
			}
		}
		.appstore, .google {
			font-size: 0;
		}
		.appstore {
			margin-right: 10px;
		}
	}
	&__link {
		height: 70px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		width: 100%;
		max-width: 400px;
		background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
		border-radius: 20px;
		margin: 0 0 20px;
		font-weight: 700;
		position: relative;
		cursor: pointer;
		color: $color-white;
		z-index: 11;
		@media(min-width: $sm) {
			margin: 0 10px 20px;
		}
		@media(min-width: $lg) {
			margin: 0 0 20px;
		}
		&:first-child {
			@media(min-width: $lg) {
				margin-right: 22px;
			}
		}
		& > span {
			position: relative;
			pointer-events: none;
		}
		&--small {
			height: 70px;
			background-color: $color-blue-navy;
			border: 2px solid rgba(255,255,255,0.1);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			width: 100%;
			border-radius: 20px;
			margin-bottom: 20px;
			@media(min-width: $sm) {
				max-width: 190px;
			}
			&:first-child {
				@media(min-width: $sm) {
					margin-right: 20px;
				}
			}
		}
		&.open {
			.magazine-single__link-wrap {
				opacity: 1;
				pointer-events: all;
			}
		}
		&-wrap {
			position: absolute;
			background-color: $color-white;
			padding: 30px;
			width: auto;
			z-index: 5;
			border-radius: 20px;
			pointer-events: none;
			opacity: 0;
			transition: opacity .2s ease-in-out;
			display: block;
			left: -50%;
			right: -50%;
			margin: auto;
			top: calc(100% + 35px);
			text-align: center;
			box-shadow: 0 25px 50px rgba(0,0,0,0.16);
			@media(min-width: $lg) {
				top: -62px;
				right: auto;
				left: calc(100% + 40px);
				text-align: left;
			}
			&:after {
				content: "";
				width: 24px;
				height: 24px;
				background-image: url("/templates/main/images/svg/triangle-archive.svg");
				background-position: center;
				position: absolute;
				background-position: center;
				background-repeat: no-repeat;
				margin: auto;
				transform-origin: 50% 50%;
				left: 0;
				right: 0;
				bottom: auto;
				top: -22px;
				transform: rotate(90deg);
				@media(min-width: $lg) {
					transform: none;
					top: 0;
					bottom: 0;
					right: 100%;
					left: -22px;
				}
			}
			& > a,
			& > span {
				display: block;
				color: $color-font-main;
				white-space: nowrap;
				&:first-child {
					padding-bottom: 20px;
					border-bottom: 1px solid $color-gray-border;
				}
				&:last-child {
					padding-top: 20px;
				}
			}
			span {
				opacity: 0.2;
			}
		}
	}
    &__introtext {
        font-size: 19px;
        line-height: 33px;
        &-title {
            font-size: 22px;
            line-height: 30px;
            font-weight: 700;
            padding-bottom: 10px;
            border-bottom: 3px solid $color-red;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
		&.open {
			p {
				display: block;
			}
		}
    }
	.read-more {
		text-align: right;
		font-weight: 700;
		font-size: 16px;
		cursor: pointer;
	}
    .row--385 {
        .right {
			@media(min-width: $lg) {
				top: -15px;
				right: -25px;
				position: relative;
				margin-bottom: -30px;
			}
        }
    }
    &__magazine {
        color: $color-white;
        max-width: 385px;
        margin: 0 auto 30px;
		display: none;
		z-index: 11;
        @media(min-width: $lg) {
            position: sticky;
            top: 2vh;
            max-width: none;
			display: block;
        }
        &-wrap {
            background-color: $color-blue;
            padding: 30px 15px 20px;
            position: relative;
			border-radius: 20px;
			&.toc-off {
				padding: 30px 15px;
				@media(min-width: $lg) and (max-width: 1920px) {
					min-height: auto;
					height: auto;
				}
			}
			@media(min-width: $lg) and (max-width: 1920px) {
				min-height: 95vh;
				max-height: 95vh;
				height: 95vh;
			}
			@media(min-width: 1920px) {
				max-height: 905px;
				height: 905px;
			}
        }
		&-images {
			position: relative;
			margin-bottom: 30px;
		}
        &-image {
            margin: 0 auto;
            display: block;
			box-shadow: 0 75px 99px rgba(0,0,0,0.32);
			max-width: 200px;
			height: auto;
			position: relative;
			z-index: 5;
			&--prev, &--next {
				position: absolute;
				opacity: 0.3;
				max-width: 130px;
				z-index: 3;
				top: 0;
				bottom: 0;
				margin: auto;
			}
			&--prev {
				left: 0;
				right: auto;
			}
			&--next {
				right: 0;
				left: auto;
			}
        }
		&-buy.magazine-single__link {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			height: 0;
			transition: height .2s ease-in-out;
			margin: 0 -4px 0;
			.magazine-single__link {
				height: 45px;
				margin: 0 4px;
				padding: 0;
				font-size: 14px;
				padding: 0 30px;
				box-shadow: 0 10px 30px rgba(0,0,0,0.25);
				width: 170px;
				background-color: $color-red;
				border: none;
				color: $color-white;
			}
			.magazine-single__links-wrap {
				&.disabled {
					opacity: 0.2;
				}
			}
			.magazine-single__link-wrap {
				top: calc(100% + 4px);
				left: 4px;
				right: 4px;
				bottom: auto;
				width: auto;
				padding: 0;
				color: $color-font-main;
				text-align: center;
				&.disabled {
					opacity: 0.2;
				}
				&:after {
					width: 17px;
					height: 17px;
					left: 0;
					right: 0;
					top: -8px;
					bottom: auto;
					z-index: 1;
				}
			}
		}
		&-buy.magazine-single__link {
			height: auto;
			font-size: 15px;
			text-transform: uppercase;
			font-weight: 700;
			color: $color-white;
			height: 41px;
			width: 0;
			border-radius: 14px;
			box-shadow: 0 25px 99px rgba(0,0,0,0.16);
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-content: center;
			align-items: center;
			background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
			transition: width .2s ease-in-out, margin-left .2s ease-in-out;
			margin-bottom: 3px;
			padding-top: 2px;
			cursor: pointer;
			position: relative;
			z-index: 3;
			b {
				width: 0;
				overflow: hidden;
				font-weight: 700;
				display: inline-block;
				transition: width .2s ease-in-out;
				pointer-events: none;
			}
			.magazine-single__link-wrap {
				width: 255px;
				height: 115px;
				top: calc(100% + 35px);
				bottom: auto;
				right: 0;
				left: auto;
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				align-items: center;
				text-align: center;
				align-content: center;
				box-shadow: 0 25px 50px rgba(0,0,0,0.16);
				&:after {
					content: "";
					width: 24px;
					height: 24px;
					top: -22px;
					bottom: auto;
					right: 30px;
					left: auto;
					margin: auto;
					transform-origin: 50% 50%;
					transform: rotate(90deg);
				}
				& > a,
				& > span {
					display: block;
					color: $color-font-main;
					white-space: nowrap;
					&:first-child {
						padding-bottom: 15px;
						border-bottom: 1px solid $color-gray-border;
					}
					&:last-child {
						padding-top: 15px;
					}
				}
			}
		}
		&-number {
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			text-transform: capitalize;
			margin-bottom: 20px;
			position: relative;
			z-index: 7;
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			align-content: center;
			.magazine-single__link.magazine-single__magazine-buy {
				.magazine-single__link-wrap {
					opacity: 0;
					pointer-events: none;
				}
			}
			&.sticky {
				.magazine-single__link.magazine-single__magazine-buy {
					width: 83px;
					margin-left: 30px;
					b {
						width: 83px;
					}
					.magazine-single__link-wrap {
						opacity: 0;
						pointer-events: none;
					}
					&.open {
						.magazine-single__link-wrap {
							opacity: 1;
							pointer-events: all;
						}
					}
				}
			}
		}
    }
    &__toc {
		height: calc(100% - 530px);
		.simplebar-vertical {
			right: 35px;
			.simplebar-scrollbar {
				opacity: 1;
				width: 9px;
				&:before {
					background-color: $color-white;
					opacity: 1;
				}
			}
			&:after {
				content: "";
				left: 0;
				right: 1px;
				bottom: 2px;
				top: 2px;
				width: 1px;
				background-color: $color-white;
				opacity: 0.2;
				display: block;
				position: absolute;
				margin: auto;
			}
		}
        &-title {
            text-align: center;
            position: relative;
            font-size: 18px;
            font-weight: 500;
            line-height: 60px;
            &:after {
                content: "";
                position: absolute;
                display: block;
                left: 15px;
                right: 15px;
                height: 1px;
                top: 100%;
                background-color: rgba(255,255,255,0.2);
            }
        }
        &-items {
			margin-top: 20px;
            padding: 0 70px;
			overflow: auto;
			height: calc(100% - 80px);
        }
        &-item {
            font-size: 15px;
            letter-spacing: -0.02em;
            position: relative;
            margin-bottom: 4px;
			text-transform: uppercase;
            &:hover {
                color: $color-red;
                transition: color .2s ease-in-out;
            }
			&:last-child {
				margin-bottom: 0;
			}
            &.current {
                color: $color-red;
                &:before {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 7px;
                    left: -25px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-image: url("/templates/main/images/svg/triangle.svg");
                    top: 6px;
					transform-origin: 50% 50%;
					transform: rotate(90deg);
                }
            }
        }
    }
	&__next {
		img {
			margin-left: 15px;
		}
	}
	&__prev {
		img {
			margin-right: 15px;
			transform-origin: 50% 50%;
			transform: rotate(180deg);
		}
	}
    &__next, &__prev {
        width: 50%;
        a {
            padding: 19px 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            justify-content: center;
        }
        img {
            max-width: 15px;
            height: auto;
        }
        span {
            display: block;
            text-align: center;
            font-size: 15px;
			font-weight: 500;
        }
    }
    &__next-prev {
        position: relative;
        left: 15px;
        right: 15px;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid rgba(255,255,255,0.2);
        border-left: none;
        border-right: none;
        width: calc(100% - 30px);
        &:before {
            content: "";
            position: absolute;
            width: 1px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background-color: rgba(255,255,255,0.2);
        }
    }
    &__content {
        &-item {
			padding-top: 20px;
            padding-bottom: 20px;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
        }
        &-category {
            font-size: 23px;
            letter-spacing: -0.015em;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-red;
            margin-bottom: 30px;
			width: 100%;
			position: relative;
			padding: 0 15px;
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				height: 3px;
				background-color: $color-gray-border;
				left: 15px;
				right: 15px;
			}
        }
        &-title {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: 20px;
            &:last-child {
                margin-bottom: 0;
            }
            .text {
                font-size: 17px;
                font-weight: 700;
                letter-spacing: -0.015em;
                padding-right: 10px;
                width: 100%;
                margin-bottom: 10px;
                @media(min-width: $md) {
                    margin-bottom: 0;
                    width: calc(100% - 220px);
                }
            }
            .files {
                display: flex;
                flex-wrap: wrap;
                font-size: 11px;
                font-weight: 400;
                align-items: flex-start;
                width: 100%;
                @media(min-width: $md) {
                    width: 220px;
                }
                &__item {
                    display: flex;
                    flex-wrap: wrap;
                    img {
                        margin-right: 5px;
                    }
                    &:first-child {
                        margin-right: 25px;
                    }
					.disabled {
						opacity: 0.2;
						cursor: normal;
						* {
							pointer-events: none;
							cursor: normal;

						}
						&:hover {
							color: $color-font-main;
						}
					}
                }
            }
        }
    }
}
.magazine-single__magazine.sticked {
	.magazine-single__magazine-buy {
		height: 45px;
		margin-bottom: 22px;
		overflow: visible;
	}
}
