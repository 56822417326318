.find-therapist {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding-top: 30px;
    padding-bottom: 43px;
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    background-color: #fff;
    &.box-wait {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10001;
        background-color: rgba(0,43,68,.97);
        transition: .3s all;
        border-radius: 0px;
        box-shadow: none;
        padding: 0;
        padding-top: 130px;
        @media(max-width: 767px) {
                padding-top: 75px;
            }
        &[data-open="false"] {
            left: -100%;
        }
        .find-therapist__bottom {
            max-width: calc(100% - 244px);
            width: 100%;
            @media(max-width: 767px) {
                max-width: calc(100% - 194px);
            }
            @media(max-width: 670px) {
                max-width: 100%;
            }
            &::before {
                content: none;
            }
            &::after {
                content: none;
            }
        }
        .box-wait-wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 1190px;
            width: 100%;
            padding: 0 15px;
            margin: 0 auto;
        }
        .box-wait__image {
            margin-right: 70px;
            max-width: 124px;
            width: 100%;
            @media(max-width: 670px) {
                max-width: 100%;
                text-align: center;
                margin-bottom: 20px;
            }
            img {
                margin-top: 30px;
            }
        }
        .box-wait__title {
            font-size: 49px;
            line-height: 65px;
            font-weight: 700;
            margin-bottom: 11px;
            letter-spacing: -2px;
            @media(max-width: 767px) {
                font-size: 29px;
                line-height: 45px;
            }
        }
        .box-wait__description {
            margin-bottom: 22px;
            font-size: 25px;
            line-height: 35px;
            font-weight: 300;
            @media(max-width: 767px) {
                font-size: 18px;
                line-height: 21px;
            }
        }
        .box-wait__close {
            max-width: 50px;
            width: 100%;
            text-align: right;
            cursor: pointer;
            @media(max-width: 767px) {
                position: absolute;
                top: 15px;
                right: 15px;
            }
            img {
                margin-top: 13px;
                pointer-events: none;
            }
        }
        .box-wait__select-wrapper {
            display: flex;
            margin-bottom: 72px;
            @media(max-width: 670px) {
                flex-wrap: wrap;
                #hp-select-problems {
                    margin-bottom: 20px;
                }
            }
            .main__select-wrap {
                margin: 0;
                margin-right: 19px;
            }
        }
        .box-wait__link {
            font-weight: 300;
            font-size: 19px;
            line-height: 36px;
            a {
                text-decoration: underline;
            }
        }
    }
    @media(max-width: 379.98px) {
        padding: 30px 15px 43px;
    }
    &__top {
        display: block;
        margin: 0 auto 24px;
        width: 100%;
        max-width: 310px;
    }
    &__bottom {
        position: relative;
        color: $color-white;
        &:before {
            content: "";
            opacity: 1;
            filter: drop-shadow(0 15px 30px rgba(0,0,0,.16));
            position: absolute;
            z-index: 0;
            border-radius: 20px;
            top: 0;
            height: 80px;
            width: 310px;
            left: 0;
            right: 0;
            margin: auto;
            max-width: calc(100% - 60px);
        }
        &:after {
            content: "";
            opacity: 1;
            filter: drop-shadow(0 15px 30px rgba(0,0,0,.16));
            position: absolute;
            z-index: 0;
            border-radius: 20px;
            top: 95px;
            height: 80px;
            width: 310px;
            left: 0;
            right: 0;
            margin: auto;
            max-width: calc(100% - 60px);
        }
    }
    &__title {
        font-size: 20px;
        line-height: normal;
        font-weight: 700;
        margin-bottom: 10px;
        padding-bottom: 8px;
        border-bottom: 2px solid $color-red;
        margin-bottom: 15px;
    }
    &__subtitle {
        font-size: 14px;
        line-height: 18px;
        line-height: normal;
    }
    .main__select-wrap {
        margin: 0 auto 15px;
        max-width: 310px;
        .cs-select {
        }
        .cs-title {
            font-size: 15px;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
}
