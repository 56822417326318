.expert-form {
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 430px;
        background-color: $color-blue-navy;
        pointer-events: none;
    }
    &__wrapper {
        position: relative;
        z-index: 3;
        @media(max-width: $sm-max) {
            margin: 0 -15px;
        }
        &.single-article__center {
            margin-top: 64px;
            padding: 76px 15px 121px;
            box-shadow: 0 0 99px rgba(0,0,0,0.16);
        }
    }
    &__form {
        &-wrapper {
            max-width: 830px;
            margin: 0 auto;
        }
        &-description {
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0.05em;
            margin-bottom: 36px;
            text-transform: uppercase;
        }
        .label {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            height: 54px;
            position: relative;
            margin-right: 8px;
            margin-bottom: 8px;
            overflow: hidden;
            input {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                display: block;
                width: 100%;
                height: 100%;
                opacity: 0;
                cursor: pointer;
                &:checked ~ .label-wrap {
                    background-color: $color-teal;
                    color: $color-white;
                    padding: 3px 35px 0 15px;
                    &:after {
                        opacity: 1;
                    }
                }
            }
            &-wrap {
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 3px 25px 0;
                font-size: 18px;
                font-weight: 700;
                transition: color .2s ease-in-out, background-color .2s ease-in-out, padding .2s ease-in-out;
                border: 2px solid $color-teal;
                border-radius: 57px;
                &:after {
                    content: "";
                    width: 18px;
                    height: 14px;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url("/templates/main/images/svg/check-white.svg");
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 15px;
                    left: auto;
                    margin: auto;
                    opacity: 0;
                    pointer-events: none;
                    transition: opacity .3s ease-in-out;
                }
            }
        }
        &-labels {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 36px;
        }
    }
    &__article {
        text-align: center;
        max-width: 830px;
        margin: 0 auto;
        h1 {
            color: $color-blue-header;
            font-weight: 700;
            letter-spacing: -0.04em;
            margin-bottom: 52px;
            font-size: 40px;
            @media(min-width: $sm) {
                font-size: 60px;
            }
        }
        &-images {
            font-size: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-bottom: 60px;
            img {
                background-color: $color-white;
                display: block;
                max-width: 100%;
                height: auto;
                position: relative;
                z-index: 3;
            }
        }
        &-image {
            margin: 0 14px;
            border-radius: 10px;
            overflow: hidden;
            background-color: $color-white;
            box-shadow: 0 30px 99px rgba(0,0,0,0.16);
            @media(max-width: $sm-max) {
                max-width: calc((100% / 3) - 28px);
            }
        }
        p {
            text-align: left;
            font-size: 19px;
            line-height: 33px;
            margin-bottom: 61px;
        }
        h2 {
            color: $color-blue-header;
            font-size: 25px;
            font-weight: 700;
            margin-bottom: 67px;
        }
    }
    p.expert-form__description {
        color: $color-blue-header;
        font-size: 21px;
        font-weight: 700;
        padding-bottom: 37px;
        margin-bottom: 33px;
        border-bottom: 2px solid #707070;
    }
    .row.captcha {
        .captcha-wrap {
            @media(max-width: $sm-max) {
                margin: 0 -15px;
            }
        }
        .captcha {
            @media(max-width: $sm-max) {
                margin: 0 auto 30px;
            }
        }
    }
    .questionnaire__form-submit--red {
        background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
        border-radius: 10px;
        box-shadow: 0 24px 55px rgba(0,0,0,0.16);
        margin: 0 auto;
        @media(min-width: $md) {
            margin-left: auto;
            margin-right: 0;
        }
    }
}
