#anim-logo-white {
    .UfhKpJPt_0 {
        stroke-dasharray: 4 6;
        stroke-dashoffset: 5;
    }
    .UfhKpJPt_1 {
        stroke-dasharray:173 175;
        stroke-dashoffset:174;
    }
    .UfhKpJPt_2{
        stroke-dasharray:109 111;
        stroke-dashoffset:110;
    }
    .UfhKpJPt_3{
        stroke-dasharray:957 959;
        stroke-dashoffset:958;
    }

    &.start .UfhKpJPt_0 {
        animation: UfhKpJPt_draw 12ms ease-in-out 0ms forwards;
    }
    &.start .UfhKpJPt_1 {
        animation: UfhKpJPt_draw 418ms ease-in-out 12ms forwards;
    }
    &.start .UfhKpJPt_2{
        animation: UfhKpJPt_draw 264ms ease-in-out 430ms forwards;
    }
    &.start .UfhKpJPt_3{
        animation: UfhKpJPt_draw 2304ms ease-in-out 695ms forwards;
    }

    &.loaded .UfhKpJPt_0 {
        animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards;
    }
    &.loaded .UfhKpJPt_1 {
        animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards;
    }
    &.loaded .UfhKpJPt_2{
        animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards;
    }
    &.loaded .UfhKpJPt_3{
        animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards;
    }
    @keyframes UfhKpJPt_draw{ 100%{stroke-dashoffset:0;} }
    @keyframes UfhKpJPt_fade{ 0%{stroke-opacity:1;} 94.44444444444444%{stroke-opacity:1;} 100%{stroke-opacity:0;} }

}
