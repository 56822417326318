.our-guide {
    &__articles {
        display: flex;
        margin-bottom: 60px;
        border-radius: 20px;
        overflow: hidden;
        @media(max-width:1200px) {
            flex-wrap: wrap;
        }
        &-left {
            padding: 27px 40px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            display: flex;
            align-items: flex-end;
            max-width: 609px;
            @media(max-width: 500px) {
                padding: 15px;
            }
            @media(max-width:1200px) {
                max-width: 100%;
                flex: 0 0 100%;
                padding-top: 200px;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 43, 68, .5);
            }
            &-category {
                background: linear-gradient(135deg, rgba(63,182,147,1) 0%,rgba(53,183,193,1) 100%);
                display: inline-block;
                font-size: 13px;
                line-height: 13px;
                font-weight: 700;
                text-transform: uppercase;
                padding: 9px 14px 7px 14px;
                border-radius: 5px;
                margin-bottom: 20px;
            }
            &-title {
                font-size: 32px;
                line-height: 42px;
                font-weight: 700;
                margin-bottom: 20px;
                @media(max-width: 600px) {
                    font-size: 28px;
                    line-height: 38px;
                }
            }
            &-description {
                font-size: 15px;
                line-height: 23px;
            }
            &-text {
                color: #fff;
                position: relative;
                z-index: 1;
            }
        }
        &-right {
            padding: 30px;
            background: linear-gradient(135deg, rgba(40,70,102,1) 0%,rgba(0,43,68,1) 100%);
            color: #fff;
            @media(max-width: 500px) {
                padding: 15px;
            }
            @media(max-width:1200px) {
                max-width: 100%;
                flex: 0 0 100%;
                display: flex;
                flex-wrap: wrap;
            }
            &-el:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
                @media(max-width:1200px) {
                    margin-bottom: 10px;
                    padding-bottom: 14px;
                }
            }
            &-el {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                padding-bottom: 14px;
                border-bottom: 1px solid rgba(255,255,255,.1);
                @media(max-width:1200px) {
                    max-width: 50%;
                    flex: 0 0 50%;
                    border-bottom: none;
                }
                @media(max-width:768px) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                &-image {
                    max-width: 120px;
                    flex: 0 0 120px;
                    width: 100%;
                    border-radius: 10px;
                    overflow: hidden;
                    margin-right: 23px;
                }
                &-title {
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 700;
                }
            }
        }
    }
}