.expert-list {
    position: relative;
    padding-bottom: 93px;
    &:after {
        content: "";
        top: 100%;
        left: 0;
        width: 100%;
        height: 305px;
        background: linear-gradient(180deg, rgba(0,12,45,1) 0%, rgba(0,12,45,0) 100%); /* w3c */
        opacity: 0.07;
        position: absolute;
    }
    &__description {
        color: $color-white;
        margin-top: 37px;
        font-size: 19px;
        line-height: 24px;
        font-weight: 400;
        opacity: 0.7;
        span {
            font-weight: 700;
        }
    }
    &__list {
        margin-top: -50px;
        position: relative;
        z-index: 3;
        .row--370 {
            .col-left {
                background-color: $color-white;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
                padding-top: 46px;
                order: 2;
                @media(max-width: $xs-max) {
                    padding: 46px 0 0;
                }
                @media(min-width: $lg) {
                    order: 1;
                    max-width: calc(100% - 370px);
                    border-top-right-radius: 0;
                    padding: 46px 55px 30px;
                }
                [class^="col-"] {
                //     padding: 0;
                //     border-bottom: 1px solid $color-gray-light;
                //     &:last-child {
                //         border-bottom: none;
                //     }
                //     @media(min-width: $sm) {
                //         border: 1px solid $color-gray-light;
                //         border-bottom: none;
                //         border-left: none;
                //         &:nth-child(1),
                //         &:nth-child(2) {
                //             border-top: none;
                //             .expert-list__item {
                //                 padding-top: 0;
                //             }
                //         }
                //         &:nth-child(3n) {
                //             border-right: 1px solid $color-gray-light;
                //             border-top: 1px solid $color-gray-light;
                //             .expert-list__item {
                //                 padding-top: 46px;
                //             }
                //         }
                //         &:nth-child(2n) {
                //             border-right: none;
                //         }
                //     }
                //     @media(min-width: $md) {
                //         &:nth-child(1),
                //         &:nth-child(2),
                //         &:nth-child(3) {
                //             border-top: none;
                //             .expert-list__item {
                //                 padding-top: 0;
                //             }
                //         }
                //         &:nth-child(2n) {
                //             border-right: 1px solid $color-gray-light;
                //         }
                //         &:nth-child(3n) {
                //             border-right: none;
                //         }
                //     }
                //     @media(min-width: $lg) {
                //         &:nth-child(1),
                //         &:nth-child(2) {
                //             border-top: none;
                //             .expert-list__item {
                //                 padding-top: 0;
                //             }
                //         }
                //         &:nth-child(3n) {
                //             border-right: 1px solid $color-gray-light;
                //             border-top: 1px solid $color-gray-light;
                //             .expert-list__item {
                //                 padding-top: 46px;
                //             }
                //         }
                //         &:nth-child(2n) {
                //             border-right: none;
                //         }
                //     }
                //     @media(min-width: $xl) {
                //         &:nth-child(1),
                //         &:nth-child(2),
                //         &:nth-child(3) {
                //             border-top: none;
                //             .expert-list__item {
                //                 padding-top: 0;
                //             }
                //         }
                //         &:nth-child(2n) {
                //             border-right: 1px solid $color-gray-light;
                //         }
                //         &:nth-child(3n) {
                //             border-right: none;
                //         }
                //     }
                }
            }
            .col-right {
                order: 1;
                @media(min-width: $lg) {
                    max-width: 370px;
                    order: 2;
                }
            }
        }
        .avt-container-1440 {
            display: flex;
            flex-wrap: wrap;
        }
    }
    &__item {
        text-align: center;
        padding: 0 0 25px;
        margin: 0 0 25px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            height: 1px;
            background-color: #DDDDDD;
            left: 0;
            @media(min-width: $sm) {
                left: 190px;
            }
        }
        &-link {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            width: 100%;
        }
        &-image {
            margin-bottom: 23px;
            max-width: 160px;
            @media(max-width: $xs-max) {
                margin: 0 auto 23px;
            }
            img {
                margin: 0 auto;
                border-radius: 20px;
                box-shadow: 0 10px 25px rgba(0,0,0,0.16);
            }
        }
        &-text {
            width: 100%;
            text-align: center;
            @media(min-width: $sm) {
                text-align: left;
                padding-left: 30px;
                max-width: calc(100% - 160px);
            }
        }
        &-title {
            font-size: 22px;
            font-weight: 700;
            margin-bottom: 14px;
        }
        &-category {
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0.05em;
            color: $color-red;
            margin-bottom: 13px;
            text-transform: uppercase;
        }
        &-description {
            font-size: 15px;
            line-height: 25px;
            margin-bottom: 15px;
        }
        &-counter {
            font-size: 16px;
            margin-bottom: 30px;
            span {
                font-weight: 700;
            }
        }
        &-btn {
            text-transform: uppercase;
            font-weight: 700;
            font-size: 13px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            width: 100%;
            letter-spacing: 0.05em;
            justify-content: center;
            @media(min-width: $sm) {
                justify-content: flex-start;
            }
            &:after {
                content: "";
                display: inline-block;
                width: 15px;
                height: 16px;
                background-image: url("/templates/main/images/svg/triangle-menu.svg");
                margin-left: 20px;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
            }
        }
    }

}
