.page-header--select {
    .page-header__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @media(min-width: $md) {
            justify-content: space-between;
        }
    }
    .page-header__header {
        margin: 0;
        padding: 0;
        width: auto;
    }
}
