.rating {
    &__about {
        @media(max-width:$md-max) {
            padding: 25px 45px 45px 45px;
        }
        @media(max-width:$sm-max) {
            padding: 15px;
        }
        &-title {
            font-size: 20px;
            line-height: 25px;
            font-weight: 700;
            padding-top: 38px;
            margin-bottom: 30px;
            @media(max-width:$md-max) {
                padding-top: 0;
            }
        }
        &-subtitle {
            font-size: 16px;
        }
        &-elements {
            padding-left: 0;
            list-style: none;
            &-el {
                position: relative;
                padding-left: 22px;
                &::after {
                    content: '';
                    position: absolute;
                    top: 8px;
                    left: 0;
                    background-color: #F16655;
                    width: 7px;
                    height: 7px;
                    border-radius: 100%;
                }
            }
        }
        &-more {
            font-size: 16px;
            line-height: 23px;
            .red {
                color: #FB3636;
                cursor: pointer;
            }
        }
    }
    &__background {
        background-color: #002A43;
        height: 247px;
        position: relative;
        &::after {
            content: '';
            background: linear-gradient(to bottom, rgba(103, 111, 134, 0.07) 0%, rgba(255, 255, 255, 0) 100%);
            position: absolute;
            width: 100%;
            height: 305px;
            bottom: -305px;
        }
    }
    &__left {
        border-radius: 20px;
        background-color: #fff;
        margin-top: -215px;
        padding: 25px 45px 45px 45px;
        @media(max-width:$sm-max) {
            padding: 15px;
        }
    }
    &__person {
        display: flex;
        align-items: center;
        margin-bottom: 45px;
        @media(max-width:$sm-max) {
            flex-wrap: wrap;
        }
        &-image {
            margin-right: 28px;
            @media(max-width:$sm-max) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 20px;
            }
            &.image-padding {
                img {
                    padding: 10px;
                }
            }
            img {
                border-radius: 8px;
                border: 1px solid rgba(0,0,0,.1);
            }
        }
        &-name {
            font-size: 20px;
            font-weight: 700;
            @media(max-width:$sm-max) {
                flex:0 0 100%;
                max-width: 100%;
            }
        }
    }
    &__info {
        font-size: 16px;
        line-height: 23px;
        padding: 18px 15px;
        background-color: rgba(53, 183, 193, .17);
        margin-bottom: 38px;
        border-radius: 20px;
        text-align: center;
    }
    .control-label {
        display: none;
    }
    &__form {
        &-ratio {
            display: flex;
            align-items: center;
            @media(max-width:630px) {
                flex-wrap: wrap;  
            }
        }
        &-title {
            font-size: 15px;
            font-weight: 700;
            margin-right: 35px;
            @media(max-width:630px) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 15px;
            }
        }
        &-wrapper {
            display: flex;
            justify-content: space-between;
            @media(max-width:$sm-max) {
                flex-wrap: wrap;
            }
        }
        &-recaptcha {
            @media(max-width:$sm-max) {
                flex: 0 0 100%;
                max-width: 100%;
                margin-bottom: 15px;
            }
            @media(max-width:360px) {
                display: flex;
                justify-content: center;
                .control-group {
                    transform: scale(0.8);
                }
            }
        }
        &-submit {
            max-width: 252px;
            width: 100%;
            background: linear-gradient(to right, rgba(241,102,85,1) 0%,rgba(251,54,54,1) 100%);
            border-radius: 10px;
            box-shadow: 0 24px 55px rgba(26, 27, 28, .1);
            margin-left: 33px;
            @media(max-width:$sm-max) {
                margin-left: 0;
                padding: 15px 0;
            }
            @media(max-width:360px) {
                max-width: 100%;
            }
            label {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                cursor: pointer;
                input {
                    background-color: transparent;
                    border: none;
                    color: #fff;
                    font-size: 15px;
                    line-height: 21px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
        &-name {
            position: relative;
            z-index: 1;
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            transition: .5s all;
            input {
                width: 100%;
                padding: 38px 25px 17px 25px;
                border: none;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                box-shadow: 0 0 99px rgba(0,0,0,.1);
                font-size: 15px;
                font-weight: 700;
                font-family: $font-main;
                &:focus {
                    outline: none;
                }
            }
            &.active {
                &::after {
                    max-width: 2000px;
                }
            }
            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                max-width: 0px;
            }
            label {
                position: absolute;
                left: 25px;
                height: 20px;
            }
        }
        &-opinion {
            position: relative;
            flex: 1;
            margin-bottom: 25px;
            transition: .5s all;
            .ratio-elements-wrapper {
                @media(min-width:460px) {
                    display: flex;
                }
            }
            textarea {
                width: 100%;
                padding: 38px 25px 17px 25px;
                border: none;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                box-shadow: 0 0 99px rgba(0,0,0,.1);
                resize: none;
                min-height: 200px;
                display: block;
                font-size: 15px;
                font-weight: 700;
                font-family: $font-main;
                &:focus {
                    outline: none;
                }
            }
        }
        .ratio-wrapper {
            display: flex;
            align-items: flex-end;
            position: relative;
            @media(max-width:460px) {
                display: inline-block;
                padding-left: 35px;
                margin-bottom: 15px;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 24px;
                height: 24px;
                background-color: #fff;
                border-radius: 50%;
                z-index: 1;
                box-shadow: 0 5px 35px rgba(0,0,0,.16);
            }
            input {
                z-index: 10;
                opacity: 0;
                transform: scale(1.5);
                &:checked {
                    outline: none;
                    & + label {
                        &::after {
                            content: '';
                            position: absolute;
                            left: 6px;
                            bottom: 6px;
                            background-color: #F43434;
                            z-index: 2;
                            width: 12px;
                            height: 12px;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
        #type {
            display: flex;
            align-items: first baseline;
            font-size: 13px;
            position: relative;
            color: #1A1B1C;
        }
        .form-input-border {
            &::after {
                content: '';
                max-width: 0px;
                width: 100%;
                background-color: #FB3636;
                height: 3px;
                position: absolute;
                left: 0;
                bottom: 0;
                transition: .5s all;
            }
        }
        #opinion {
            & + label {
                position: absolute;
                left: 25px;
                top: 32px;
                height: 20px;
            }
        }
        #nick, #opinion {
            & + label {
                transition: .5s all ease-in-out;
            }
            &.active {
                & + label {
                    transform: translate(-13%, -80%) scale(0.75);
                    & + .form-input-border {
                        &::after {
                            max-width: 2000px;
                        }
                    }
                } 
            }
            &:focus {
                & + label {
                    transform: translate(-13%, -80%) scale(0.75);
                    & + .form-input-border {
                        &::after {
                            max-width: 2000px;
                        }
                    }
                }
            }
        }
        #type-lbl {
            display: none;
        }
        #type0 {
            position: relative;
            padding-top: 33px;
            display: block;
            margin-right: 10px;
            background-color: #fff;
            margin-bottom: 6px;
            margin-left: 6px;
            & + label {
                padding-top: 33px;
                display: flex;
                justify-content: center;
                margin-right: 35px;
                margin-bottom: 0;
                @media(max-width:460px) {
                    width: 90px;
                    padding-top: 10px;
                }
                &::before {
                    content: '';
                    background-image: url('/templates/main/images/svg/ok-green.svg');
                    background-size: cover;
                    background-position: center;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    margin-left: -13px;
                }
            }
        }
        #type1 {
            position: relative;
            padding-top: 33px;
            margin-right: 10px;
            margin-bottom: 6px;
            margin-left: 6px;
            & + label {
                padding-top: 33px;
                display: flex;
                justify-content: center;
                margin-right: 35px;
                margin-bottom: 0;
                @media(max-width:460px) {
                    width: 90px;
                    padding-top: 10px;
                }
                &::before {
                    content: '';
                    background-image: url('/templates/main/images/svg/smile.svg');
                    background-size: cover;
                    background-position: center;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    margin-left: -13px;
                }
            }
        }
        #type2 {
            position: relative;
            padding-top: 33px;
            margin-right: 10px;
            margin-bottom: 6px;
            margin-left: 6px;
            & + label {
                padding-top: 33px;
                display: flex;
                justify-content: center;
                margin-right: 35px;
                margin-bottom: 0;
                @media(max-width:460px) {
                    width: 90px;
                    padding-top: 10px;
                }
                &::before {
                    content: '';
                    background-image: url('/templates/main/images/svg/negative-red.svg');
                    background-size: cover;
                    background-position: center;
                    width: 20px;
                    height: 20px;
                    position: absolute;
                    top: 0;
                    margin-left: -13px;
                }
            }
        }
    }
    &__rules {
        display: flex;
        align-items: flex-start;
        position: relative;
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 40px;
        .star {
            display: none;
        }
        input {
            margin-right: 12px;
            opacity: 0;
            &:checked {
                & + label {
                    &::after {
                        opacity: 1;
                    }
                }
            }
            & + label {
                padding-left: 12px;
                &::after {
                    content: '';
                    opacity: 0;
                    position: absolute;
                    left: 6px;
                    top: 8px;
                    width: 14px;
                    height: 10px;
                    background-image: url('/templates/main/images/accept-rules.png');
                    background-position: center;
                    background-repeat: no-repeat;
                    transition: .5s all ease-in-out;
                }
                &::before {
                    content: '';
                    width: 25px;
                    height: 25px;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border-radius: 3px;
                    box-shadow: 0 0 10px rgba(0,0,0,.16);
                    z-index: 1;
                }
            }
        }
    }
}