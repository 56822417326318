.hp-info {
    margin-bottom: 40px;
    &__container {
        padding-top: 60px;
        padding-bottom: 54px;
        position: relative;
        &:after {
            content: "";
            height: 1px;
            display: block;
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 0;
            display: block;
            background-color: $color-gray;
            opacity: 0.1;
        }
    }
    &__item {
        text-align: center;
        margin-bottom: 30px;
        &-image {
            min-height: 134px;
            margin-bottom: 40px;
            img {
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                display: block;
            }
        }
        &-title {
            font-size: 22px;
            font-weight: 700;
            max-width: 330px;
            margin: 0 auto 40px;
        }
        &-introtext {
            font-size: 15px;
            font-weight: 400;
        }
    }
}
