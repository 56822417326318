.main {
    &__select-wrap {
        width: 100%;
        max-width: 330px;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        position: relative;
        margin: 0 0 20px;
        z-index: 15;
        .simplebar-track.simplebar-vertical {
            right: 30px;
            bottom: 30px;
            .simplebar-scrollbar {
                background-color: $color-white;
                border-radius: 4px;
                &:before {
                    display: none;
                }
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                width: 1px;
                left: 0;
                right: 0;
                margin: auto;
                background-color: rgba(255,255,255,0.2);
            }
        }

        @media(min-width: $sm) {
            margin: 0 10px 20px;
        }
        @media(min-width: $xl) {
            margin: 0 20px 20px 0;
        }
        &:last-child {
            z-index: 10;
            @media(min-width: $xl) {
                margin: 0 0 20px;
            }
        }
        &.loaded {
            opacity: 1;
        }
        .cs-title {
            width: 100%;
            background: $main-gradient;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            @media(min-width: 400px) {
                font-size: 16px;
            }
            border-radius: 20px;
            height: 80px;
            padding: 0 45px 0 30px;
            span {
                position: relative;
                z-index: 3;
                pointer-events: none;
            }
        }
        .cs-list-wrap {
            overflow: hidden;
            position: absolute;
            height: 0;
            transition: height .2s ease-in-out, opacity .2s ease-in-out;
            background-color: $color-white;
            color: $color-black;
            z-index: 3;
            opacity: 0;
            width: 100%;
            pointer-events: none;
            top: 80px;
            border-radius: 20px;
        }
        .cs-list {
            width: 100%;
            height: 100%;
            position: relative;
            -webkit-overflow-scrolling: touch;
            list-style: none;
            margin: 0;
            padding: 0;
            width: calc(100% + 20px);
            height: 100%;
            overflow: hidden;
            overflow-y: scroll;
            padding: 0 50px 30px 30px;
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            max-width: calc(100% + 20px);
            li {
                a {
                    display: block;
                    width: 100%;
                    padding-bottom: 10px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }

        }
        .cs-btn {
            display: block;
            width: 100%;
            top: 0;
            left: 0;
            position: absolute;
            z-index: 3;
            cursor: pointer;
            height: 80px;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                background-image: url("/templates/main/images/svg/chevron-down.svg");
                margin: auto;
                width: 15px;
                height: 8px;
                background-repeat: no-repeat;
                transform-origin: 50% 50%;
                transition: transform .2s ease-in-out;
                right: 25px;
            }
            &.open {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        .cs-select {
            &:after {
                content: "";
                top: 0;
                left: 0;
                width: 100%;
                height: 80px;
                display: block;
                background-color: $color-white;
                position: absolute;
                opacity: 0;
                box-shadow: 0 0 99px rgba(0,0,0,0.41);
                transition: height .2s ease-in-out, opacity .2s ease-in-out;
                pointer-events: none;
                z-index: 0;
                border-radius: 20px;
            }
        }
        .cs-select.open {
            .cs-list-wrap {
                height: 380px;
                opacity: 1;
                pointer-events: all;
            }
            &:after {
                opacity: 1;
                min-height: 175px;
                height: 460px;
            }
        }
    }
    &__top {
        padding-bottom: 55px;
        position: relative;
        background-color: rgba(#EBEBEB,.5);
        @media(min-width: 1600px) {
            min-height: 700px;
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 100%;
            height: 30px;
            background-color: rgba(#EBEBEB,.5);
            width: 100%;
            pointer-events: none;
        }
        &--therapist {
            justify-content: center;
            padding-bottom: 280px;
            .main__top-title,
            .main__top-subtitle {
                text-align: center;
            }
            .main__top-wrap {
                width: 100%;
                max-width: 1440px;
                padding: 0 15px;
                margin: 0 auto;
            }
            .main__top-left {
                padding-top: 20px;
                max-width: none;
                justify-content: center;
                min-height: auto;
                padding-bottom: 0;
            }
            .main__select-wrap {
                z-index: 14;
            }
        }
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            padding-left: 15px;
            padding-right: 15px;
            @media(min-width: 1440px) {
                padding-right: 0;
                padding-left:  calc((100% - 1410px) / 2);
            }
        }
        &-left {
            min-height: 28.5vw;
            padding-top: 0px;
            padding-bottom: 65px;
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            @media(min-width: 1600px) {
                justify-content: flex-start;
                max-width: 50%;
            }
            @media(min-width: 1800px) {
                padding-bottom: 115px;
            }
        }
        &-right {
            height: 100%;
            right: 0;
            position: relative;
            min-height: 650px;
            top: -35px;
            height: calc(100% + 35px);
            width: calc(100% + 30px);
            margin: 0 -15px;
            padding: 0 15px;
            @media(min-width: 1600px) {
                overflow: hidden;
                width: 100%;
                min-height: auto;
                position: absolute;
                max-width: 47%;
                margin: 0;
                padding: 0;
            }
        }
        &-center {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            ul {
                display: flex;
                flex-wrap: wrap;
                padding: 0;
                width: 100%;
                margin: 0 auto;
                font-size: 14px;
                @media(min-width: $md) {
                    max-width: 80%;
                }
                @media(min-width: 1280px) {
                    margin: 0;
                    max-width: 57%;
                }
                li {
                    list-style: none;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    margin-right: 7px;
                    opacity: 0.7;
                    transition: opacity .2s ease-in-out;
                    &:hover {
                        opacity: 1;
                    }
                    &:after {
                        content: "";
                        width: 3px;
                        height: 3px;
                        background-color: $color-white;
                        border-radius: 50%;
                        display: inline-block;
                        margin: -2px 0 0 7px;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                    &.all {
                        opacity: 1;
                        text-transform: uppercase;
                        font-weight: 700;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        &-text {
            width: 100%;
            margin-bottom: 15px;
            @media(min-width: $md) and (max-width: $md-max) {
                margin-top: 40px;
            }
        }
        &-title {
            font-weight: 700;
            width: 100%;
            text-align: center;
            font-size: 26px;
            line-height: 32px;
            margin: 45px 0;
            @media(min-width: $md) {
                font-size: 30px;
                margin: 0;
            }
            @media(min-width: $lg) {
                font-size: 45px;
                line-height: 56px;
            }
            @media(min-width: 1600px) {
                text-align: left;
                font-size: 2.5vw;
            }
            @media(min-width: 1800px) {
                font-size: 43px;
            }
        }
        &-subtitle {
            font-size: 24px;
            width: 100%;
            text-align: center;
            display: none;
            @media(min-width: $md) {
                display: block;
            }
            @media(min-width: 1600px) {
                text-align: left;
                font-size: 1.25vw;
            }
            @media(min-width: 1800px) {
                font-size: 24px;
            }
        }
    }
}
.select-placeholder {
    height: 80px;
    width: 100%;
    max-width: calc(50% - 10px);
    background: $main-gradient;
    border-radius: 20px;
    &:last-child {
        margin-right: 0;
    }
}
.cs-select--selected-list {
    #hp-list-problems, #box-list-problems {
        .cs-selected-list {
            & > a {
                pointer-events: all;
                opacity: 1;
                margin-bottom: 0;
            }
        }
    }
}
#hp-list-problems, #box-list-problems {

    &.cs-list {
        li {
            font-size: 13px;
            line-height: 18px;
            text-transform: uppercase;
            font-weight: 700;
            letter-spacing: 0.1em;
            margin-bottom: 16px;
            & > a {
                margin-bottom: 8px;
                opacity: 0.3;
                pointer-events: none;
            }
            &.cs-result-list {
                & > a {
                    pointer-events: all;
                    opacity: 1;
                    margin-bottom: 0;
                }
            }
            & > ul {
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    text-transform: none;
                    letter-spacing: normal;
                    font-size: 16px;
                    opacity: 1;
                    font-weight: 400;
                    margin-bottom: 0;
                    & > a {
                        margin-bottom: 0;
                        opacity: 1;
                        pointer-events: all;
                    }
                }
            }
        }
    }
}
