.header {
    &.header-article {
        background: transparent;
        z-index: 10;
        position: relative;
        @media(max-width:$md) {
            padding-bottom: 30px;
        }
    }
}

body[data-user-browser="firefox"] {
    .single-article__top-raport {
        background-color: rgba(40, 70, 102, .9);
    }
}


.single-article {
    &__h1-wrapper {
        margin-bottom: 30px;
        h1 {
            display: inline;
        }
    }
    &.short {
        &.active-holiday {
            &::after {
                max-height: 826px;
            }
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color:transparent;
            max-height: 1061px;
            pointer-events: none;
            @media(min-width: 1366px) {
                height: 1020px;
            }
        }
        .col-left {
            position: relative;
        }
        .col-right {
            z-index: 2;
        }
        .single-article__center {
            border-radius: 0;
            margin-top: 0;
            background-color: transparent;
            padding-top: 34px;
            padding-left: 0;
            padding-right: 0;
        }
        .single-article__top {
            background-color: transparent !important;
            &::after {
                max-height: 956px;
                @media(max-width:$sm-max) {
                    max-height: 975px;
                }
            }
            &::before {
                top: 500px;
            }
        }
        .single-article__top-raport {
            margin-bottom: 30px;
        }
        .single-article__top-text-title {
            z-index: 2;
            position: relative;
        }
        .single-article__top-text-description {
            z-index: 2;
            position: relative;
        }
        .single-article__top-text-time {
            z-index: 2;
            position: relative;
        }
        .single-article__top-text {
            padding-bottom: 211px;
            position: inherit;
            @media(max-width:1025px) {
                padding: 0;
            }
            @media(max-width: 600px) {
                background-color: transparent;
                padding: 0 15px 50px 15px;
                margin: 0 -15px;
            }
        }
        .single-article__center {
            margin-top: -230px;
            margin-bottom: 70px;
            @media(max-width:1025px) {
                margin-top: 0;
            }
        }
        .single-article__cent-image {
            margin-bottom: 50px;
            margin-top: -211px;
            position: relative;
            z-index: 2;
            img {
                border-radius: 20px;
                box-shadow: 0 0 99px rgba(0,0,0,.16);
            }
            @media(max-width:1025px) {
                margin-top: 0;
            }
            @media(max-width: 600px) {
                margin-top: -50px;
                z-index: 5;
            }
        }
        .single-article__right-magazine {
            margin-bottom: 30px;
            img {
                max-width: 100%;
            }
        }
        .single-article__socials-wrapper {
            top: 575px;
            z-index: 1;
            padding-bottom: 600px;
            &.sticky-menu-open {
                padding-bottom: 650px;
            }
            .single-article__socials {
                margin-bottom: 180px;
            }
        }
        &.wymowne-grafiki {
            .single-article__cent-image {
                margin-bottom: 42px;
            }
        }
        @media(max-width:1025px) {
            .single-article__top-wrapper {
                &::before {
                    content: none;
                }
            }
        }
    }
    &.wymowne-grafiki {
        .single-article__center {
            border-radius: 0;
            margin-top: 0;
            background-color: transparent;
            padding-top: 34px;
            padding-left: 0;
            padding-right: 0;
        }
        .single-article__cent-image {
            margin-bottom: 50px;
            position: relative;
            img {
                border-radius: 20px;
                box-shadow: 0 0 99px rgba(0,0,0,.16);
            }
        }
        .single-article__right-magazine-top-title {
            font-size: 11px;
            line-height: 23px;
            margin-bottom: 7px;
            font-weight: 700;
        }
        .single-article__right-magazine-description {
            font-size: 12px;
            line-height: 18px;
        }
        .single-article__right-magazine-btn {
            font-size: 13px;
            line-height: 10px;
            padding: 11px 0;
            line-height: 9px;
        }
        .single-article__top-text {
            padding-bottom: 0;
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
        }
        .single-article__center {
            padding-top: 0;
            margin-top: -85px;
        }
        .single-article__right-magazine-image img {
            display: block;
            margin: 0 auto;
        }
        .single-article__top-text-description {
            margin-bottom: 30px;
        }
        .single-article__top {
            margin-bottom: 38px;
            padding-bottom: 85px;
        }
        .single-article__top-text-time {
            margin-bottom: 2px;
            width: 100%;
        }
        .single-article__right-magazine {
            padding: 17px 27px;
            max-width: 217px;
            margin: 0 0 0 auto;
        }
    }
    &.long {
        .single-article__cent-image {
            margin-bottom: 53px;
            img {
                border-radius: 20px;
                box-shadow: 0 0 99pxrgba(0, 0, 0, 0.16);
            }
        }
        .single-article__top-text {
            @media(max-width: 600px) {
                background-color: transparent;
                padding: 0 15px 1px 15px;
                margin: 0 -15px;
            }
        }
        &.raport {
            .single-article__center {
                border-radius: 0;
                margin-top: 0;
                background-color:transparent;
                padding-top: 34px;
                padding-left: 0;
                padding-right: 0;
                margin-top: -230px;
                margin-bottom: 70px;
                @media(max-width:1025px) {
                    margin-top: 0;
                }
            }
            .single-article__top-text {
                padding-bottom: 230px;
                @media(max-width:1025px) {
                    padding: 0;
                }
                @media(max-width: 600px) {
                    background-color: transparent;
                    padding: 0 15px 1px 15px;
                    margin: 0 -15px;
                }
            }
        }
        .single-article__socials-wrapper {
            .single-article__socials {
                margin-bottom: 90px;
            }
        }
    }
    &__holiday {
        display: inline-flex;
        align-items: center;
        padding: 8px 13px 2px 10px;
        background: linear-gradient(135deg, rgba(39,69,101,1) 0%,rgba(53,183,193,1) 100%);
        max-width: 100%;
        width: auto;
        border-radius: 5px;
        margin-bottom: 30px;
        &-day {
            font-size: 31px;
            line-height: 31px;
            font-weight: 700;
            margin-right: 8px;
        }
        &-month {
            font-size: 15px;
            line-height: 19px;
            font-weight: 700;
            opacity: 0.5;
            margin-right: 14px;
        }
        &-title {
            font-size: 15px;
            line-height: 29px;
            font-weight: 700;
            text-transform: uppercase;
            margin-right: 17px;
        }
        &-icon {
            margin-top: -2px;
        }
    }
    &__holistic {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 36px;
        @media(max-width: 570px) {
            flex-wrap: wrap;
            background-color: #fff;
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            padding-top: 20px;
            border-radius: 20px;
        }
        &-image {
            @media(max-width:570px) {
                display: flex;
                justify-content: center;
                width: 100%;
            }
        }
        &-right {
            padding: 20px 31px 20px 40px;
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            @media(max-width:570px) {
                box-shadow: none;
                padding: 15px;
            }
            &-top {
                font-size: 13px;
                font-weight: 700;
                text-transform: uppercase;
            }
            &-title {
                font-size: 33px;
                line-height: 34px;
                font-weight: 700;
                margin-bottom: 10px;
                @media(max-width:1250px) {
                    font-size: 23px;
                    line-height: 24px;
                }
            }
            &-btns {
                font-size: 13px;
                line-height: 21px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                display: flex;
                letter-spacing: 0.3px;
                white-space:nowrap;
                @media(max-width:1250px) {
                    flex-wrap: wrap;
                }
                a.single-article__holistic-right-btns-number {
                    color: #fff !important;
                    text-decoration: none;
                }
                a.single-article__holistic-right-btns-enumber {
                    color: #fff !important;
                    text-decoration: none;
                }
                &-number {
                    background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
                    margin-right: 15px;
                    padding: 20px 38px;
                    border-radius: 10px;
                    max-width: 302px;
                    width: 100%;
                    margin-bottom: 5px;
                    text-align: center;
                    @media(max-width:1400px) {
                        padding: 15px;
                    }
                    @media(max-width:1250px) {
                        max-width: 100%;
                        margin-right: 0;
                    }
                }
                &-enumber {
                    background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
                    padding: 20px 38px;
                    border-radius: 10px;
                    max-width: 302px;
                    width: 100%;
                    margin-bottom: 5px;
                    text-align: center;
                    @media(max-width:1400px) {
                        padding: 15px;
                    }
                    @media(max-width:1250px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    &__socials {
        max-width: 80px;
        width: 100%;
        position: sticky;
        margin-top: 32px;
        top: 100px;
        margin-bottom: 30px;
        right: 30px;
        max-height: 80px;
        overflow: hidden;
        box-shadow: 0 15px 99px rgba(0,0,0,.22);
        transition: .2s all;
        z-index: 3;
        background-color: transparent;
        border-radius: 7px;
        @media(max-width:1670px) {
            max-width: 50px;
            max-height: 50px;
        }
        &.show {
            max-height: 1000px;

        }
        &-wrapper {
            position: absolute;
            left: -110px;
            top: 65px;
            height: 100%;
            width: 80px;
            transition: .2s all;
            transform: translateY((0));
            @media(max-width:1670px) {
                width: 50px;
                left: -60px;
            }
            @media(max-width:1550px) {
                display: none;
            }
            &.sticky-menu-open {
                .single-article__socials {
                    transform: translateY(60px);
                }
            }
        }
        &-btn {
            background-color: #F16655;
            height: 80px;
            border-radius: 7px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media(max-width:1670px) {
                height: 50px;
            }
            &.show {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-top-right-radius: 7px;
                border-top-left-radius: 7px;
                background-color: #fff;
                svg {
                    path {
                        stroke: #FB3636;
                    }
                }
            }
        }
        &-fb {
            background-color: #3B5999;
            height: 80px;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media(max-width:1670px) {
                height: 50px;
            }
        }
        &-messanger {
            background-color: #0084FF;
            height: 80px;
            border-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media(max-width:1670px) {
                height: 50px;
            }
        }
        &-twitter {
            background-color: #55ACEE;
            height: 80px;
            border-radius: 7px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            @media(max-width:1670px) {
                height: 50px;
            }
        }
    }
    &__bottom {
        & > .row:first-child {
            &:empty {
                & + .row {
                    display: none;
                }
            }
        }
        &-btn {
            max-width: 691px;
            width: 100%;
            color: #fff;
            background: linear-gradient(to right, rgba(53,183,193,1) 0%,rgba(63,182,147,1) 100%);
            padding: 44px 0;
            font-size: 15px;
            line-height: 21px;
            font-weight: 700;
            text-transform: uppercase;
            text-align: center;
            border-radius: 20px;
            margin: 0 auto 96px auto;
            position: relative;
            z-index: 3;
            img {
                margin-left: 34px;
            }
        }
    }
    &__top {
        background-color: $color-white;

        position: relative;
        &-raport {
            border-radius: 20px;
            padding: 22px 37px;
            z-index: 5;
            position: relative;
            text-align: center;
            backdrop-filter: blur(20px);
            background-color: rgba(40,70,102,0.9);
            color: #fff;
            @media(max-width: 1025px) {
                max-width: 450px;
                margin: 0px auto 30px auto;
            }
            &::after {
                content: '';
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255,255,255,.15);
                border-radius: 20px;
            }
            &-top-title {
                font-size: 15px;
                line-height: 21px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                background: linear-gradient(135deg, #3fb693 0%,#35b7c1 100%);
                padding: 6px 12px;
                display: inline-block;
                border-radius: 5px;
                margin: 22px 0;
                position: relative;
                z-index: 1;
            }
            &-title {
                font-size: 25px;
                line-height: 31px;
                font-weight: 700;
                margin-bottom: 22px;
                position: relative;
                z-index: 1;
            }
            &-btn {
                font-size: 13px;
                line-height: 21px;
                font-weight: 700;
                padding: 12px 20px;
                border: 1px solid rgba(255,255,255,.2);
                display: inline-block;
                border-radius: 10px;
                position: relative;
                z-index: 1;
            }
        }
        &-wrapper {
            position: relative;
            // @media(max-width:$md-max) {
            //     &::before {
            //         content: '';
            //         width: 100%;
            //         height: 100%;
            //         position: absolute;
            //         top: 0;
            //         left: 0;
            //         z-index: 1;
            //         background-color: rgba(0, 43, 68, .4);
            //     }
            // }
        }
        &::after {
            content: '';
            // background: linear-gradient(to right, rgba(0,43,68,1) 0%,rgba(255,255,255,0) 100%) ,url("/templates/main/images/dev/testartykul.png");
            background-size: cover !important;
            background-repeat: no-repeat !important;
            background-position: center !important;
            position: absolute;
            right: 0;
            top: 0;
            max-width: 800px;
            width: 100%;
            height: 80%;
            z-index: -1;
        }
        &::before {
            content: '';
            background: linear-gradient(to bottom, rgba(103, 111, 134, 0.07) 0%,rgba(255,255,255,0) 100%);
            position: absolute;
            width: 100%;
            height: 305px;
            bottom: -305px;
            opacity: 0;
            pointer-events: none;
        }
        &-text {
            z-index: 5;
            position: relative;
            padding-bottom: 90px;
            color: $color-black;
            @media(max-width:$md-max) {
                padding-left: 0;
            }
            @media(max-width: 600px) {
                background-color: #002a43;
                padding: 0 15px 15px 15px;
                margin: 0 -15px;
            }
            &-category {
                font-size: 15px;
                line-height: 21px;
                font-weight: 700;
                text-transform: uppercase;
                position: relative;
                display: flex;
                align-items: center;
                padding-left: 59px;
                margin-bottom: 11px;
                &::before {
                    content: '';
                    width: 44px;
                    height: 2px;
                    background-color: rgba(255,255,255,.2);
                    position: absolute;
                    left: 0;
                }
            }
            &-title {
                font-size: 45px;
                line-height: 55px;
                font-weight: 700;
                margin-bottom: 30px;
                @media(max-width:$sm-max) {
                    font-size: 25px;
                    line-height: 35px;
                }
            }
            &-description {
                font-size: 21px;
                line-height: 33px;
                width: 100%;
                margin-bottom: 25px;
                font-weight: 500;
                @media(max-width:$sm-max) {
                    font-size: 18px;
                    line-height: 30px;
                }
                p {
                    margin: 0;
                }
            }
            &-time {
                font-size: 13px;
                line-height: 21px;
                margin-bottom: 38px;
                align-items: center;
                align-content: center;
                &.space-between {
                    display: flex;
                    justify-content: space-between;
                    @media(max-width:900px) {
                        flex-wrap: wrap;
                    }
                }
                &-left {
                    @media(max-width:900px){
                        margin-bottom: 10px;
                        max-width: 100%;
                    }
                    .date {
                        opacity: .7;
                        display: inline-block;
                        margin-right: 60px;
                        @media(max-width: 500px) {
                            display: block;
                        }
                    }
                }
                &-right {
                    @media(max-width:900px){
                        max-width: 100%;
                        &-tag {
                            margin-left: 0 !important;
                            margin-right: 5px;
                        }
                    }
                    &-tag {
                        font-size: 13px;
                        line-height: 26px;
                        padding: 0 8px;
                        border-radius: 5px;
                        border: 1px solid #CACACA;
                        color: $color-black;
                        transition: .5s all;
                        margin-left: 5px;
                        display: inline-block;
                        max-height: 29px;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    &__author {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        &-image {
            margin-right: 14px;
            border-radius: 9px;
            overflow: hidden;
        }
        &-name {
            font-size: 15px;
            font-weight: 700;
            display: block;
            width: 100%;
        }
    }
    &__center {
        padding-top: 50px;
        border-radius: 20px;
        margin-top: -65px;
        position: relative;
        @media(max-width:1025px) {
            margin-top: 0;
        }
        @media(max-width:$md-max) {
            padding: 0;
        }
        @media(max-width: $md) {
            margin-top: 0;
            border-radius: 0;

        }
        &-wrapper {
            position: relative;
            z-index: 6;
        }
    }
    &__introtext {
        color: #1A1B1C;
        font-size: 19px;
        line-height: 33px;
        margin-bottom: 30px;
        padding-right: 50px;
        font-weight: 700;
        @media(max-width:$md-max) {
            padding-right: 0;
        }
    }
    &__fulltext {
        color: #1A1B1C;
        font-size: 19px;
        line-height: 33px;
        & > div:first-child.e-commerce--article-transparent {
            padding-top: 0;
        }
        @media(max-width:$md-max) {
            padding: 0;
        }
        @media(max-width: $md) {
            font-size: 17px;
            line-height: 29px;
            padding: 0;
            padding-top: 15px;
        }
        @media(max-width: $sm) {
            font-size: 17px;
            line-height: 29px;
        }
        p , span {
            color: $color-font-main !important;
        }
        p {
            & > iframe[name="embedded-content"] {
                margin-bottom: 0;
            }
        }
        iframe[name="embedded-content"] {
            border: none;
            width: 100%;
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            max-width: calc(100% - 80px);
            margin: 0 auto;
            display: block;
            border-radius: 15px;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            &.loaded {
                opacity: 1;
            }
        }
        p.film {
            position: relative;
            padding-top: 56.25%;
            margin-bottom: 30px;
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: none;
            }
        }
        .image-right-side {
            display: flex;
            flex-wrap: wrap;
            padding: 25px;
            border: 1px solid rgba(0,0,0,.1);
            margin-bottom: 30px;
            border-radius: 15px;
            &-left {
                max-width: 60%;
                flex: 0 0 60%;
                padding-right: 30px;
                padding-left: 20px;
                &-title {
                    font-size: 20px;
                    font-weight: 700;
                    margin-bottom: 20px;
                    padding-top: 20px;
                    color:#000;
                }
                &-description {
                    font-size: 15px;
                    line-height: 22px;
                    color:#000;
                }
            }
            &-right {
                max-width: 40%;
                flex: 0 0 40%;
                padding-left: 30px;
            }
        }
        .bibliografia, .bibliography {
            border-radius: 10px;
            margin-bottom: 30px;
            overflow: hidden;
            & > ol, & > ul {
                counter-reset: myOrderedListItemsCounter;
                & > li {
                    overflow-wrap: break-word;
                    position: relative;
                    &::before {
                        counter-increment: myOrderedListItemsCounter;
                        content: counter(myOrderedListItemsCounter)".";
                        margin-right: 15px;
                        position: absolute;
                        top: 6px;
                        left: 25px;
                    }
                    &::marker {
                        margin-left: 15px;
                    }
                }
            }
            & > ol, & > ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    padding: 6px 25px 6px 50px;
                    font-size: 15px;
                    line-height: 18px;
                }
                li:nth-child(odd) {
                    background-color: var(--colorBgDarker);
                }
            }
        }
        .static.bibliografia.p {
            background-color: #fff;
        }
        .spis-tresci {
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            transition: .3s all ease-in-out;
            width: 100%;
            z-index: 3;
            background-color: #fff;
            border-radius: 10px;
            padding: 28px;
            position: relative;
            margin-bottom: 50px;
            z-index: 1;
            &__top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;
                &-left {
                    display: flex;
                    align-items: center;
                }
            }
            &__bottom {
                color: #000C2D !important;
                font-size: 16px;
                line-height: 30px;
                @media(min-width: 576px) {
                    font-size: 19px;
                    line-height: 40px;
                }
                &-list {
                    list-style: none;
                    margin: 0;
                    margin-top: 21px;
                    padding-top: 20px;
                    border-top: 2px solid #DDDDDD;
                    @media(max-width: 575px) {
                        padding-left: 10px;
                    }
                    &-el {
                        position: relative;
                        padding-left: 26px;
                        &::before {
                            content: '';
                            width: 10px;
                            height: 10px;
                            background-color: #FB3636;
                            border-radius: 3px;
                            position: absolute;
                            left: 0;
                            transform: translate(-50%,-50%);
                            top: 14px;
                            @media(min-width: 576px) {
                                top: 18px;
                            }
                        }
                        a {
                            color: #1a1b1c !important;
                            text-transform: none;
                            display: block;
                            text-decoration: none;
                        }
                    }
                }
            }

            &__icon {
                margin-right: 32px;
            }
            &__title {
                font-size: 22px;
                line-height: 26px;
                font-weight: 700;
                text-transform: uppercase;
            }
        }
        ul {
            padding-bottom: 20px;
        }
        & > ul {
            list-style: none;
            margin-inline-start: -23px;
            @media (min-width: $xs-max) {
                margin-inline-start: 1em;
            }
            li {
                margin-bottom: 15px;
                position: relative;
                padding-left: 5px;
                &::before {
                    content: '';
                    width: 10px;
                    height: 10px;
                    border-radius: 100%;
                    background-color: #F16655;
                    position: absolute;
                    left: -16px;
                    top: 10px;

                }
            }
        }
        & > ol {
            margin-inline-start: -23px;
            @media (min-width: $xs-max) {
                margin-inline-start: 1em;
            }
        }
        h2 {
            font-size: 25px;
            line-height: 31px;
            font-weight: 700;
            margin: 30px 0;
            &:first-of-type {
                margin-top: 0;
            }
            @media(max-width: 450px) {
                font-size: 20px;
                line-height: 26px;
            }
        }
        // .bg.bg1, .bg.bg2, .bg.bg3, .bg.bg4, .bg.bg5, .bg.bg6 {
        //     background-color: #F16655;
        //     color: #fff !important;
        //     padding: 4px 8px 3px 8px;
        //     border-radius: 3px;
        // }
        .custom-bg {
             background-color: #F16655;
             color: #fff !important;
             padding: 4px 8px 3px 8px;
             border-radius: 3px;
        }
        .msfloatright {
            float: right;
        }
        .mscenter {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .msfloatleft {
            float: left;
        }
        .msmaxwidth {
            max-width: 100%;
        }
        p {
            margin-bottom: 20px;
        }
        a {
            color: #FB3636 !important;
            text-decoration: underline;
        }
        .quote {
            font-size: 22px;
            line-height: 35px;
            font-weight: 700;
            color: #294868;
            display: flex;
            margin: 70px 0;
            @media(max-width:600px) {
                display: block;
            }
            @media(max-width: 450px) {
                font-size: 20px;
                line-height: 33px;
            }
            &__image {
                margin-left: 50px;
                margin-right: 20px;
                @media(max-width:$md) {
                    margin-left: 0;
                }
                @media(max-width: 600px) {
                    float: left;
                }
                img {
                    @media(max-width: 600px) {
                        max-width: 30px;
                    }
                }
            }
        }
        .dictionary {
            background-color: #737E9F;
            color: #fff;
            padding: 25px;
            border-radius: 10px;
            margin-bottom: 54px;
            @media(max-width:$xs-max) {
                padding:15px;
            }
            &__title {
                font-size: 25px;
                font-weight: 700;
                padding-left: 16px;
                margin-bottom: 33px;
                @media(max-width:$xs-max) {
                    padding-left: 0;
                }
            }
            &__el {
                margin-bottom: 16px;
                font-size: 15px;
                line-height: 22px;
                .bold {
                    font-weight: 700;
                }
            }
        }
        .image-with-description {
            border: 1px solid #DDDDDD;
            padding: 55px 90px 15px 90px;
            margin-bottom: 30px;
            border-radius: 10px;
            &__image {
                display: flex;
                justify-content: center;
                margin-bottom: 25px;
            }
            &__text {
                font-size: 15px;
                line-height: 23px;
                text-align: center;
            }
        }
        .list {
            padding: 25px;
            background-color: #DDDDDD;
            border-radius: 10px;
            margin-bottom: 30px;
            &__title {
                font-size: 25px;
                font-weight: 700;
                margin-bottom: 20px;
            }
            &__description {
                font-size: 19px;
                line-height: 30px;
                margin-bottom: 15px;
            }
            &__bold-title {
                font-size: 19px;
                line-height: 30px;
                font-weight: 700;
                margin-bottom: 18px;
            }
            &__elements {
                list-style: none;
                &-el {
                    margin-bottom: 15px;
                    position: relative;
                    padding-left: 5px;
                    &::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 100%;
                        background-color: #F16655;
                        position: absolute;
                        left: -16px;
                        top: 10px;

                    }
                }
            }
        }
        .two-pictures {
            margin-bottom: 50px;
            &__image {
                img {
                    margin-bottom: 16px;
                    border-radius: 10px;
                    max-width: 100%;
                }
            }
            &__text {
                font-size: 13px;
                line-height: 16px;
            }
        }
        .twitter-tweet {
            margin: 30px auto !important;
        }
        // Stare style - START
        blockquote:not(.twitter-tweet) {
            font-size: 22px;
            line-height: 35px;
            font-weight: 700;
            text-align: left;
            margin: 70px 0 70px 50px;
            color: #294868 !important;
            min-height: 54px;
            @media(min-width:600px) {
                background-image: url('/templates/main/images/svg/quote.svg');
                background-repeat: no-repeat,no-repeat;
                background-position: 0 10px;
                padding-left: 75px;
            }
            @media(max-width: 600px) {
                &::before {
                    content: '';
                    width: 30px;
                    margin-right: 14.4px;
                    height: 24px;
                    float: left;
                    background-image: url("/templates/main/images/svg/quote.svg");
                    background-repeat: no-repeat,no-repeat;
                    background-size: contain;
                    margin-top: 5px;
                }
            }
            @media(max-width: 600px) {
                margin-left: 0;
            }
            @media(max-width: 450px) {
                font-size: 20px;
                line-height: 33px;
            }
            span {
                color: #294868 !important;
            }
            & > p {
                text-align: left;
                color: #294868 !important;
            }
        }
        iframe {
            border: none;
        }
        iframe[name="embedded-content"] {
            width: 100%;
            margin-bottom: 30px;
        }
        img.p {
            float: right;
            margin-top: 15px;
            margin-left: 15px;
            margin-bottom: 15px;
        }
        img.l {
            float: left;
            margin-top: 15px;
            margin-right: 15px;
            margin-bottom: 15px;
        }
        .static {
            // &.p {
            //     margin-left: 20px;
            //     font-size: 14px;
            //     line-height: 20px;
            //     .title {
            //         font-size: 18px;
            //         line-height: 25px;
            //     }
            //     img {
            //         margin-bottom: 20px;
            //     }
            // }
            // &.l {
            //     margin-right: 20px;
            //     font-size: 14px;
            //     line-height: 20px;
            //     .title {
            //         font-size: 18px;
            //         line-height: 25px;
            //     }
            //     img {
            //         margin-bottom: 20px;
            //     }
            // }
            &.bibliografia {
                width: 100%;
                margin: 0 0 30px 0;
                &.l, &.r {
                    float: none;
                }
            }
        }
        .ramka4 {
            &.mit, &.fakt {
                background-color: #fff;
                border: 1px solid #DDDDDD;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                & > img {
                    max-width: 125px;
                    flex: 0 0 125px;
                    margin-right: 21px;
                }
                & > .title {
                    max-width: calc(100% - 146px);
                    flex: 0 0 calc(100% - 146px);
                }
                & > .content {
                    max-width: 100%;
                    flex: 0 0 100%;
                    margin-top: 23px;
                }
            }
        }
        .imagesInContent {
            img {
                display: block;
                margin: 0 auto 20px auto;
            }
        }
        .ramka1, .ramka2, .ramka3, .ramka4, .ramka5 {
            // padding: 25px;
            border-radius: 15px;
            background-color: #fff;
            // border: 1px solid #DDDDDD;
            // margin-bottom: 30px;

            box-shadow: 0 0 50px #00000036;
            border: none;
            font-size: 17px;
            line-height: 30px;
            margin: 40px;
            padding: 40px;
            @media(max-width:$md) {
                margin: 40px 5px;
                padding: 15px;
            }
            & > p.title {
                font-size: 20px;
                line-height: 26px;
                font-weight: 700;
                // padding-top: 24px;
                // padding-bottom: 25px;
            }
            ul {
                list-style: none;
                padding: 0;
                li {
                    padding-left: 37px;
                    position: relative;
                    @media(max-width: $md) {
                        padding-left: 20px;
                    }
                    &::before {
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background-color: #F16655;
                        position: absolute;
                        left: 13px;
                        top: 10px;
                        @media(max-width: $md) {
                            left: 0;
                        }
                    }
                }
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
    &__search {
        max-width: 300px;
        display: block;
        width: 100%;
        padding: 51px 0;
        color: #fff;
        background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
        border-radius: 14px;
        text-align: center;
        text-transform: uppercase;
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        margin: 31px auto 46px auto;
        &:hover {
            color: #fff;
        }
    }
    &__red-title {
        font-size: 30px;
        line-height: 44px;
        font-weight: 700;
        border-bottom: 3px solid #F16655;
        margin-bottom: 46px;
        &.add-height {
            height: 91px;
            display: flex;
            align-items: flex-end;
        }
        &.add-more-height {
            height: 131px;
            display: flex;
            align-items: flex-end;
        }
    }
    &__institution {
        &.therapists {
            margin-top: -200px;
            .therapists-hp__title {
                color: #fff;
            }
        }
        &-left {
            margin-right: 20px;
        }
        &-right {
            min-width: 100px;
        }
        &-el {
            padding: 30px 17px 30px 30px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 25px;
            position: relative;
            z-index: 3;
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            background-color: #fff;
            border-radius: 20px;
        }
        &-title {
            font-size: 16px;
            font-weight: 700;
            margin-bottom: 5px;
        }
        &-city {
            font-size: 13px;
            line-height: 21px;
            opacity: 0.5;
            margin-bottom: 13px;
        }
        &-votes {
            .vote-percent {
                font-size: 15px;
                line-height: 23px;
                font-weight: 700;
                margin-right: 14px;
                img {
                    max-width: 20px;
                }
            }
            .vote-numbers {
                font-size: 13px;
                line-height: 23px;
                margin-right: 27px;
            }
            .see-profile {
                font-size: 11px;
                font-weight: 700;
                color: #35B7C1;
                text-transform: uppercase;
            }
        }
        &-image {
            padding: 16px;
            border: 1px solid rgba(0,0,0,.16);
            border-radius: 10px;
            max-width: 100px;
        }
    }
    &__person {
        border-radius: 20px;
        padding: 30px;
        position: relative;
        z-index: 2;
        margin-bottom: 26px;
        box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
        background-color: #fff;
        &-votes {
            @media(max-width:$sm-max) {
                margin-bottom: 20px;
                display: flex;
                justify-content: center;
            }
            .vote-percent {
                font-size: 15px;
                line-height: 23px;
                font-weight: 700;
                img {
                    margin-right: 10px;
                    max-width: 19px;
                    width: 100%;
                }
            }
            .vote-numbers {
                font-size: 13px;
            }
        }
        &-image {
            @media(max-width:$sm-max) {
                display: flex;
                justify-content: center;
            }
            img {
                max-width: 100%;
                border-radius: 10px;
                margin-bottom: 15px;
            }
        }
        &-name {
            font-size: 22px;
            font-weight: 700;
        }
        &-city {
            font-size: 15px;
            line-height: 21px;
            opacity: .5;
            padding-bottom: 10px;
            border-bottom: 1px solid rgba(112, 112, 112, .1);
            margin-bottom: 10px;
        }
        &-address {
            padding-left: 35px;
            position: relative;
            font-size: 15px;
            line-height: 28px;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                background-image: url('/templates/main/images/svg/address-pin.svg');
                background-size: cover;
                width: 18px;
                height: 22px;
            }
            .see-profile {
                font-size: 11px;
                font-weight: 700;
                color: #35B7C1;
                text-transform: uppercase;
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }
        &-www {
            padding-left: 35px;
            position: relative;
            font-size: 15px;
            line-height: 28px;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                background-image: url('/templates/main/images/svg/external-link2.svg');
                background-size: cover;
                width: 22px;
                height: 22px;
            }
        }
        &-category {
            .person-category-el {
                font-weight: 700;
            }
            &.green {
                .person-category-el {
                    background-color: #3FB693;
                }
            }
        }
        .person {
            &-category {
                &-el {
                    display: inline-block;
                    padding: 3px 6px;
                    background-color: #35B7C1;
                    color: #fff;
                    border-radius: 5px;
                    font-size: 13px;
                    margin-bottom: 6px;
                }
                &-see-all {
                    display: inline-block;
                    font-size: 11px;
                    line-height: 23px;
                    margin-left: 17px;
                    .bold {
                        font-weight: 700;
                    }
                }
            }
        }
        &-contact {
            display: flex;
            flex-wrap: wrap;
            padding-top: 15px;
            border-top: 1px solid rgba(112, 112, 112, .1);
            margin-top: 7px;
            font-size: 15px;
            &-el {
                max-width: 50%;
                flex: 0 0 50%;
                margin-bottom: 15px;
                display: flex;
                @media(max-width:$sm) {
                    max-width: 100%;
                    flex: 0 0 100%;
                }
                a {
                    display: flex;
                }
                .image {
                    max-width: 20px;
                    width: 20px;
                    display: flex;
                    justify-content: center;
                    margin-right: 14px;
                }
            }
        }
        &-title {
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 2px;
            padding-bottom: 11px;
            margin-bottom: 14px;
            border-bottom: 1px solid rgba(112, 112, 112, .1);
            margin-top: 36px;
        }
        .vote {
            &-el {
                display: flex;
                &__left {
                    margin-right: 16px;
                    &-image {
                        padding: 13px;
                        border: 1px solid #DDDDDD;
                        border-radius: 9px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                &__right {
                    .name {
                        font-size: 16px;
                        font-weight: 700;
                        margin-right: 29px;
                    }
                    .date {
                        font-size: 13px;
                        color: #B7B7B7;
                        @media(max-width:600px) {
                            display: block;
                        }
                    }
                    .text {
                        margin-top: 14px;
                        font-size: 15px;
                        line-height: 23px;
                    }
                }
            }
        }
    }
    &__right {
        height: 100%;
        &-magazine {
            border-radius: 20px;
            box-shadow: 0 30px 99px rgba(0,0,0,.16);
            padding: 29px 44px;
            z-index: 2;
            position: relative;
            background-color: #284666;
            color: #fff;
            max-width: 370px;
            margin: 0 auto 30px;
            &-top-title {
                font-size: 15px;
                font-weight: 700;
                text-align: center;
                margin-bottom: 11px;
            }
            &-description {
                font-size: 13px;
                opacity: 0.7;
                margin-bottom: 11px;
                text-align: center;
            }
            &-image {
                margin-bottom: 17px;
                img {
                    max-width: 100%;
                }
            }
            &-btn {
                font-size: 16px;
                line-height: 21px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 700;
                background: linear-gradient(135deg, #f16655 0%,#fb3636 100%);
                width: 100%;
                padding: 20px;
                text-align: center;
                border-radius: 10px;
            }
        }
    }
}


.search-btn__therapist {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
    font-size: 15px;
    line-height: 25px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: linear-gradient(90deg,#f16655 0%,#fb3636 100%);
    border-radius: 14px;
    align-items: center;
    align-content: center;
    position: absolute;
    top: 9px;
    left: 0;
    color: #fff;
    height: 48px;
    white-space: nowrap;
    &-wrapper {
        display: inline-block;
        position: relative;
        width: 243.3px;
        height: 48px;
    }
    &:hover {
        color: #fff;
    }
    span {
        display: block;
        padding-top: 4px;
        position: relative;
        z-index: 3;
    }
    img {
        margin-left: 20px;
        position: relative;
        z-index: 3;
    }
}

.single-article {
    @media(min-width: 1440px) {
        .row--370 > .col-left {
            max-width: calc(100% - 430px);
            padding-right: 45px;
        }
    }
}
