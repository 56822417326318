.problem-list-hp {
    padding: 24px 30px 40px;
    background-color: $color-white;
    position: relative;
    z-index: 3;
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    display: none;
    @media(min-width: $sm) {
        display: block;
    }
    &__title {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 700;
        padding-bottom: 8px;
        border-bottom: 2px solid $color-red;
        margin-bottom: 20px;
    }
    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
        column-count: 2;
        font-size: 14px;
        li {
            margin-bottom: 5px;
        }
    }
    &__link {
        margin: 25px auto 0;
        font-size: 15px;
        font-weight: 700;
        height: 48px;
        border-radius: 16px;
        width: 100%;
        max-width: 310px;
        border: 2px solid $color-font-main;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        transition: color .2s ease-in-out, background-color .2s ease-in-out;
        &:hover {
            @media(min-width: $lg) {
                background-color: $color-font-main;
                color: $color-white;
            }
        }
    }
}
