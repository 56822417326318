.therapist-list-articles {
    border-radius: 15px;
    overflow: hidden;
    margin-top: 30px;
    margin-bottom: 60px;
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    &__top {
        color: $color-white;
        background: linear-gradient(90deg, rgba(60,98,138,1) 0%, rgba(0,43,68,1) 100%);
        min-height: 80px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding: 0 15px 0 30px;
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        margin-right: 10px;
    }
    &__separator {
        width: 2px;
        height: 22px;
        background-color: rgba(255,255,255,0.5);
        margin-right: 10px;
    }
    &__subtitle {
        font-size: 20px;
        font-weight: 300;
    }
    &__list {
        padding: 30px 5px 10px;
        display: flex;
        flex-wrap: wrap;
        .last-added__item {
            width: 100%;
            padding: 0 15px 10px;
            border-bottom: none;
            position: relative;
            margin-bottom: 20px;
            @media(min-width: $md) {
                max-width: 50%;
            }
            &-image {
                width: 100%;
                max-width: 280px;
                max-height: 280px;
                order: 1;
                margin: 0 auto 20px;
                @media(min-width: 420px) {
                    order: 2;
                    max-width: 115px;
                    max-height: 115px;
                    margin: 0;
                }
                a {
                    display: block;
                    width: 100%;
                    height: 100%;
                }
                .img-avatar {
                    width: 100%;
                }
            }
            &-text {
                width: 100%;
                order: 2;
                @media(min-width: 420px) {
                    order: 1;
                    max-width: calc(100% - 115px);
                }
            }
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 15px;
                right: 15px;
                height: 1px;
                background-color: $color-gray-border;
            }
        }
    }
    &__link {
        align-self: flex-end;
        margin-left: auto;
        min-height: 46px;
        border-radius: 10px;
        font-size: 14px;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        font-weight: 700;
        padding: 3px 25px 0;
        background: linear-gradient(90deg, rgba(241,102,85,1) 0%, rgba(251,54,54,1) 100%);
        &--bottom {
            text-align: center;
            justify-content: center;
            color: $color-white;
            width: 100%;
            max-width: 250px;
            margin: 0 auto;
            &:hover {
                color: $color-white;
            }
        }
    }
}
