.specialist-sticky {
    background: linear-gradient(0deg,#3fb693 0,#35b7c1 100%);
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 50px 15px 47px;
    color: $color-white;
    text-align: center;
    @media(min-width: $lg) {
        top: 72px;
        position: sticky;
        border-top-left-radius: 0;
        border-bottom-right-radius: 20px;
    }
    &--small {
        padding: 25px 15px 30px;
        border-radius: 20px;
        top: 87px;
        margin-bottom: 36px;
    }
    &__title {
        font-size: 21px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 25px;
        padding: 0 5px;
    }
    &__images {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 44px;
        &:after, &:before {
            content: "";
            width: 96px;
            height: 96px;
            display: inline-block;
            background-color: $color-white;
            box-shadow: 0 25px 30px rgba(0,0,0,0.16);
            border-radius: 50%;
            margin: 0 12px;
        }
        &:after {
            content: "";
            background-image: url("/templates/main/images/svg/doctor.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
        }
        &:before {
            content: "";
            background-image: url("/templates/main/images/svg/case.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 50%;
        }
    }
    &__subtitle {
        font-size: 19px;
        font-weight: 700;
        margin-bottom: 30px;
        padding: 0 5px;
    }
    &__description {
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        margin-bottom: 60px;
        padding: 0 15px;
    }
    &__link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
        height: 62px;
        max-width: 310px;
        margin: 0 auto;
        font-size: 15px;
        font-weight: 700;
        border-radius: 20px;
    }
}
