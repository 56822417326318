.last-added {
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    border-radius: 15px;
    overflow: hidden;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    &__top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 15px;
        &-left {
            padding-left: 20px;
            .last-added__title-value {
                color: #fff !important;
            }
            &-title {
                font-weight: 700;
                font-size: 23px;
            }
            .last-added__title-value {
                font-size: 23px;
                font-weight: 300;
            }
        }
    }
    &.in-fulltext {
        max-width: 830px;
        margin: 0 auto 30px auto;
        .last-added__title {
            padding: 15px 0;
            background: linear-gradient(135deg, #3c628a 0%,#002b44 100%);
        }
        .last-added__item-title {
            line-height: 15px;
        }
        .last-added__item-practice {
            line-height: 23px;
        }
        a.last-added__btn {
            color: #fff !important;
            max-width: 163px;
            width: 100%;
            padding: 0 20px;
            white-space: nowrap;
        }
        .last-added__top-left {
            display: flex;
            align-items: center;
        }
        .last-added__list {
            display: flex;
            flex-wrap: wrap;
            padding: 20px 0;
        }
        .last-added__btn {
            @media(max-width: 700px) {
                display: none;
            }
        }
        .last-added__btn.bottom {
            display: none;
            @media(max-width: 700px) {
                display: flex;
                margin-bottom: 20px;
            }
        }
        .last-added__item {
            margin-bottom: 0px;
            @media(max-width: 700px) {
                margin-bottom: 15px;
            }
            &-wrapper {
                max-width: 50%;
                flex: 0 0 50%;
                padding: 0 25px;
                @media(max-width: 700px) {
                    max-width: 100%;
                    flex:  0 0 100%;
                }
            }
        }
        .last-added__item-practice {
            color: #000C2D;
        }
        .last-added__item-link {
            padding-bottom: 8px;
            line-height: 16px;
            margin-top: 12px;
            a {
                color: #35B7C1 !important;
            }
        }
        .last-added__item:last-child {
            border-bottom: 1px solid rgba(112,112,112,0.1);
        }
        .last-added__item-title {
            a {
                color: #000C2D !important;
            }
        }
        a {
            text-decoration: none;
        }
    }
    &--hp {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        .last-added__item {
            @media(max-width: $xs-max) {
                display: none;
            }
            &:first-child,
            &:nth-child(2),
            &:nth-child(3) {
                display: flex;
            }
        }
    }
    &--short {

    }
    &__title {
        color: $color-white;
        background-color: $color-blue-bg;
        text-align: center;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        &-text {
            font-size: 20px;
            font-weight: 700;
            margin-top: 5px;
        }
        &-separator {
            width: 2px;
            height: 22px;
            background-color: $color-white;
            opacity: 0.5;
            display: block;
            margin: 0 12px;
        }
        &-value {
            font-size: 20px;
            font-weight: 300;
            margin-top: 5px;
        }
    }
    &__list {
        padding: 20px;
        background-color: $color-white;
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-gray-border;
        &:last-child {
            margin-bottom: 0;
            padding-bottom: 0;
            border-bottom: none;
        }
        &-image {
            border-radius: 10px;
            overflow: hidden;
            max-width: 70px;
            max-height: 70px;
        }
        &-text {
            width: 100%;
            padding-right: 10px;
            max-width: calc(100% - 70px);
        }
        &-spec, &-problems {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            align-items: center;
            a:last-child {
                .firm-item__tag {
                    margin-right: 0;
                }
            }
            .firm-item__tag {
                font-size: 12px;
                line-height: 24px;
            }
        }
        &-spec {
            margin-bottom: 5px;
        }
        &-title {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 0;
        }
        &-practice {
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 5px;
        }
        &-location {
            font-size: 13px;
            opacity: 0.5;
        }
        &-link {
            font-size: 12px;
            font-weight: 700;
            color: $color-teal;
            letter-spacing: 0.1em;
            text-transform: uppercase;
        }
    }
    &__wrap {
        padding: 0 20px 20px;
    }
    &__btn {
        width: 100%;
        max-width: 330px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        margin: 0 auto;
        height: 54px;
        background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
        color: $color-white;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.05em;
        border-radius: 10px;
        text-transform: uppercase;
        padding-top: 2px;
        &:hover {
            @media(min-width: $lg) {
                color: $color-white;
            }
        }
    }
    &__find {
        padding: 0 20px 27px;
        &-title {
            font-size: 20px;
            font-weight: 700;
            padding-bottom: 12px;
            border-bottom: 2px solid $color-red;
            margin-bottom: 18px;
        }
        &-items {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
        &-item {
            height: 21px;
            background-color: $color-teal;
            padding: 0 5px;
            border-radius: 5px;
            color: $color-white;
            font-weight: 700;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            font-size: 13px;
            margin-right: 4px;
            margin-bottom: 4px;
            }
        &-logo {
            img {
                margin: 0 auto;
                display: block;
            }
        }
    }
}
