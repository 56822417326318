.alphabet-list {
    &__top {
        background-color: $color-white;
        color: $color-black;
        padding-bottom: 24px;
        @media(max-width: 1439.98px) {
            padding-bottom: 84px;
        }
        @media(max-width: $sm-max) {
            padding-top: 30px;
        }
    }
    .breadcrumb {
        margin-bottom: 0;
    }
    &__list-wrap {
        @media(max-width: 1439.98px) {
            transition: padding .2s ease-in-out, height .2s ease-in-out;
            padding: 0 11px;
            overflow: hidden;
            height: 0;
        }
        &.open {
            @media(max-width: 1439.98px) {
                height: 185px;
                padding: 15px 11px;
            }
        }
    }
    &__title {
        border-bottom: 3px solid $color-red;
        margin-bottom: 45px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        &-text {
            color: #1A1B1C;
            font-size: 35px;
            font-weight: 700;
            letter-spacing: .05em;
            text-transform: uppercase;
            margin-bottom: 0;
        }
        &-link {
            background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
            width: 225px;
            height: 48px;
            color: $color-white;
            border-radius: 14px;
            font-size: 15px;
            font-weight: 700;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-top: 2px;
            margin-bottom: 17px;
        }
    }
    &__container {
        &--bottom {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
    &__bottom {
        padding-top: 80px;
    }
    &__alphabet {
        position: relative;
        &-wrapper {
            @media(max-width: 1439.98px) {
                position: absolute;
                width: 100%;
                max-width: 290px;
                border: 2px solid rgba(255,255,255,0.4);
                border-radius: 16px;
                z-index: 9;
                right: 0;
                left: auto;
                background-color: $color-blue-navy;
            }
            @media(max-width: $xs-max) {
                left: 0;
                right: 0;
                margin: auto;
            }
        }
        &-list {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            height: 0;
            height: 100%;
            width: calc(100% + 16px);
            @media(min-width: 1440px) {
                height: auto;
                width: calc(100% + 16px);
                margin: 0 -8px;
                padding: 0;
            }
            .simplebar-content {
                display: flex;
                flex-wrap: wrap;
            }
        }
        &-title {
            padding: 0 15px;
            height: 50px;
            line-height: 50px;
            cursor: pointer;
            font-size: 15px;
            font-weight: 700;
            user-select: none;
            @media(min-width: 1440px) {
                display: none;
            }
        }
    }
    &__letter {
        width: 43px;
        height: 43px;
        border-radius: 7px;
        color: $color-font-main;
        background-color: $color-white;
        font-size: 17px;
        font-weight: 700;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-top: 2px;
        position: relative;
        overflow: hidden;
        margin: 0 4px 8px;
        border: 2px solid #ededed;
        @media(min-width: 1440px) {
            margin: 0 8px 16px;
        }
        span {
            position: relative;
            z-index: 3;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
            transition: opacity .2s ease-in-out;
        }
        &:hover {
            color: $color-white;
            &:after {
                opacity: 1;
            }
        }
    }
    &__list {
        max-width: 570px;
        margin: 0 auto;
        margin-bottom: 30px;
        &--one-column {
            .alphabet-list__item-list {
                column-count: 1;
            }
            .alphabet-list__item-link {
                padding: 0;
                &:before {
                    display: none;
                }
            }
        }
        &--two-column {
            max-width: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .alphabet-list__item-title {
                padding-bottom: 10px;
                margin-bottom: 20px;
                border-bottom: 2px solid rgba(112,112,112,0.1);
                &:after {
                    display: none;
                }
            }
            .alphabet-list__item-list {
                column-count: 1;
            }
            .alphabet-list__item {
                @media(min-width: $md) {
                    max-width: calc(50% - 15px);
                }
            }
            .alphabet-list__item-link {
                padding: 0;
                &:before {
                    display: none;
                }
            }
        }
        &--one-column.alphabet-list__list--blue {
            .alphabet-list__item-list {
                font-size: 0;
            }
            .alphabet-list__item-link {
                display: inline-block;
                height: 40px;
                background-color: $color-teal;
                border-radius: 8px;
                color: $color-white;
                width: auto;
                margin-right: 10px;
                padding: 2px 10px 0;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                align-content: center;
            }
        }
    }
    &__item {
        width: 100%;
        margin-bottom: 40px;
        &-title {
            font-size: 40px;
            line-height: 40px;
            font-weight: 700;
            text-transform: uppercase;
            color: $color-red;
            margin-bottom: 30px;
            position: relative;
            span {
                display: inline-block;
                background-color: $color-white;
                padding-right: 17px;
                position: relative;
                z-index: 3;
            }
            &:after {
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 11px;
                height: 1px;
                background-color: $color-gray-border;
                position: absolute;
                margin: auto;
            }
        }
        &-link {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            font-size: 16px;
            margin-bottom: 10px;
            align-items: center;
            align-content: center;
            font-weight: 700;
            padding-left: 22px;
            position: relative;
            &:before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 13px;
                margin-right: 12px;
                background-image: url("/templates/main/images/svg/triangle-menu.svg");
                position: absolute;
                top: 3px;
                left: 0;
                z-index: 3;
            }
        }
        &-list {
            margin-bottom: 50px;
            @media(min-width: $sm) {
                column-count: 2;
            }
        }
    }
}
