.article-list {
    &__top {
        // padding-top: 30px;
        background-color: transparent;
        padding-bottom: 170px;
        .breadcrumb {
            color: $color-black;
        }
        &--problems {
            padding-bottom: 60px;
        }
        &-container {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            justify-content: center;
            @media(min-width: $md) {
                justify-content: space-between;
            }
        }
        &--guide {
            padding-bottom: 80px;
            @media(max-width: $sm-max) {
                padding-top: 15px;
            }
        }
    }
    &__header {
        color: $color-black;
        font-weight: 700;
        margin: 0;
        padding: 0;
        width: 100%;
        margin-bottom: 15px;
        text-align: center;
        font-size: 36px;
        @media(min-width: $sm) {
            font-size: 40px;
        }
        @media(min-width: $md) {
            text-align: left;
        }
        @media(min-width: $lg) {
            width: auto;
            margin-bottom: 0;
        }
        @media(min-width: 1280px) {
            font-size: 58px;
        }
    }
    &__category {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        padding-bottom: 16px;
        margin-top: 30px;
        margin-bottom: 20px;
        width: 100%;
        color: $color-blue-navy;
        position: relative;
        z-index: 3;
        font-size: 16px;
        padding: 0 30px 16px;
        @media(min-width: 460px) {
            padding: 0 15px 16px;
            font-size: 18px;
        }
        &:after {
            content: "";
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: 0;
            background-color: $color-red;
            height: 1px;
        }
    }
    &__articles {
        margin: -85px -15px 100px;
        position: relative;
        &:after {
            content: "";
            position: absolute;
            left: 15px;
            right: 15px;
            bottom: -30px;
            top: -30px;
            border-radius: 20px;
            background-color: $color-white;
            box-shadow: 0 33px 99px rgba(0,0,0,0.16);
            @media(min-width: 460px) {
                display: none;
            }
        }
        &--problems {
            margin-top: 30px;
            &:after {
                top: 0;
            }
        }
        &--spec {
            margin-top: 60px;
        }
    }
    &__item-wrap {
        padding: 0 15px;
        width: 100%;
        max-width: 100%;
        position: relative;
        z-index: 3;
        margin-bottom: 10px;
        @media(min-width: 460px) {
            margin-bottom: 30px;
            max-width: 50%;
        }
        @media(min-width: $sm) {
            max-width: calc(100% / 3);
        }
        @media(min-width: $lg) {
            max-width: 25%;
        }
        @media(min-width: $xl) {
            max-width: 20%;
        }
        @media(min-width: 1440px) {
            max-width: calc(100% / 6);
        }
    }
    &__item {
        display: block;
        width: 100%;
        background-color: $color-white;
        position: relative;
        border-radius: 20px;
        overflow: hidden;
        padding: 0 30px;
        @media(min-width: 460px) {
            padding: 0;
            padding-top: 100%;
            box-shadow: 0 33px 99px rgba(0,0,0,0.16);
        }
        &-title {
            top: 5px;
            left: 5px;
            bottom: 5px;
            right: 5px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
            // text-transform: uppercase;
            color: $color-blue;
            font-size: 17px;
            font-weight: 700;
            // letter-spacing: 0.05em;
            pointer-events: none;
            transition: color .2s ease-in-out;
            text-align: left;
            @media(min-width: 460px) {
                text-align: center;
                justify-content: center;
                position: absolute;
            }
            span {
                display: block;
                text-align: center;
                &::first-letter {
                    text-transform: uppercase;
                }
            }
        }
        &-image {
            top: 0;
            left: 0;
            opacity: 0;
            transition: opacity .7s ease-in-out;
            display: none;
            @media(min-width: 460px) {
                position: absolute;
                display: block;
            }
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: rgba(26,27,28,0.4);
            }
        }
        &:hover {
            @media(min-width: $lg) {
                .article-list__item-title {
                    color: $color-white;
                }
                .article-list__item-image {
                    opacity: 1;
                }
            }
        }
    }
}
