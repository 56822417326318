.articles-large-top.module, .articles-large-top-slider.module {
    @media(max-width: 379.98px) {
        padding: 0 15px;
    }
    .small-article {
        @media(max-width: $xs-max) {
            .small-article__text {
                padding: 15px 15px 68px;
            }
        }
    }
}
.articles-list-large-top {
    .small-article {
        @media(max-width: $xs-max) {
            .small-article__text {
                padding: 15px;
            }
        }
    }
}
.swiper-hp-top {
    box-shadow: 0 25px 40px rgba(0,0,0,0.18);
    border-radius: 20px;
    margin-bottom: 30px;
    .small-article {
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 0;
        &:after {
            border-radius: 0;
        }
        .small-article__text-title {
            @media(max-width: 575px) {
                padding-bottom: 35px;
            }
            @media(max-width: 379.98px) {
                padding-bottom: 40px;
            }
        }
        .small-article__text-description {
            padding-right: 172px;
        }
    }
    &__counter {
        font-size: 16px;
        letter-spacing: 0.08em;
        font-weight: 400;
        color: $color-white;
        position: absolute;
        bottom: 21px;
        right: 140px;
        position: absolute;
        z-index: 9;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
    }
    &__next,
    &__prev {
        position: absolute;
        bottom: 10px;
        right: 10px;
        z-index: 9;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid rgba(255,255,255,0.4);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        cursor: pointer;
    }
    &__prev {
        right: 70px;
        img {
            transform: rotate(180deg);
        }
    }
    .loading-bar {
        display: inline-block;
        width: 74px;
        height: 2px;
        border-radius: 20px;
        margin: 0 6px;
        position: relative;
        background-color: $color-white;
        &:after {
            content: "";
            width: 0%;
            background-color: $color-red;
            position: absolute;
        }
    }
    .active {
        .loading-bar {
            &:after {
                left: 0;
                top: 0;
                height: 100%;
                width: 100%;
                transition: width 3s linear;
            }
        }
    }
}
.articles-large-top {
    .swiper-hp-top__nav {
        position: absolute;
        z-index: 99;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        left: 15px;
        bottom: 15px;
        @media(min-width: 576px) {
            bottom: 26px;
            right: 30px;
            left: auto;
            top: auto;
        }
    }
    .swiper-hp-top__prev,
    .swiper-hp-top__next {
        border-radius: 5px;
        width: 34px;
        height: 34px;
        position: static;
    }
    .swiper-hp-top__prev {
        margin-right: 5px;
    }
    .swiper-hp-top__counter {
        margin: auto;
        top: auto;
        justify-content: center;
        max-width: 150px;
        pointer-events: none;
        bottom: 17px;
        left: 120px;
        right: auto;
        @media(min-width: 576px) {
            bottom: 31px;
            left: auto;
            right: 120px;
        }
        @media(min-width: 768px) {
            left: 0;
            right: 0;
        }
        @media(min-width: 1025px) and (max-width: 1200px) {
            display: none;
        }
    }
    .swiper-hp-top__link {
        width: 170px;
        height: 34px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: $color-white;
        font-size: 13px;
        font-weight: 700;
        border-radius: 8px;
        text-transform: uppercase;
        margin-left: 12px;
    }
    .small-article .small-article__text-title {
        padding-bottom: 0;
    }
    .small-article__text {
        @media(max-width: 575px) {
            padding: 15px 15px 68px;
        }
    }
}
.recommended-col {
    .articles-large-top-slider {
        display: block !important;
        .left {
            width: 100%;
            max-width: 100% !important;
        }
        .right {
            display: none;
        }
        .articles-large-top {
            & > .row {
                & > .col-md-6  {
                    display: none !important;
                }
            }
        }
        .swiper-hp-top {
            display: block;
            visibility: visible;
            position: static;
            border-radius: 0;
            box-shadow: none;
            .swiper-hp-top__prev,
            .swiper-hp-top__next,
            .swiper-hp-top__counter {
                display: none;
            }
            .swiper-wrapper {
                @extend .row;
                display: flex;
                flex-wrap: wrap;
                visibility: visible;
                position: static;
                width: calc(100% + 30px);
                .swiper-slide {
                    @extend .col-12;
                    @extend .col-sm-6;
                    @extend .col-xl-4;
                    margin-bottom: 30px;
                    .small-article {
                        &:before,
                        &:after {
                            display: none;
                        }
                        & > a {
                            &:before,
                            &:after {
                                display: none;
                            }
                            &:hover {
                                &:before,
                                &:after {
                                    display: none;
                                }
                            }
                        }
                        picture {
                            display: block;
                            overflow: hidden;
                            border-radius: 15px;
                        }
                        &__text {
                            position: relative;
                            color: $color-font-main;
                            padding: 0;
                            top: -24px;
                            &-title {
                                font-size: 18px;
                                line-height: 25px;
                            }
                            &-description {
                                display: block !important;
                                padding: 0 !important;
                            }
                            &-category {
                                margin-bottom: 20px;
                                border-bottom-left-radius: 15px;
                                border-top-left-radius: 0;
                                border-top-right-radius: 15px;
                                border-bottom-right-radius: 0;
                                padding-top: 7px;
                                color: $color-white;
                            }
                            &-tags {
                                &-el {
                                    border-color: $color-font-main;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
.articles-large-top.articles-large-top--new {
    .row {
        .left {
            padding: 0 15px;
            max-width: 100%;
            @media(min-width: 1366px) {
                max-width: calc((100% / 3) * 2);
            }
        }
        .right {
            padding: 0 15px;
            max-width: 100%;
            @media(min-width: 1366px) {
                max-width: calc(100% / 3);
            }
        }
    }
    .swiper-container {
        .small-article {
            .small-article__text {
                padding: 0 25px 65px;
                @media(min-width: 576px) {
                    padding: 0 30px 90px;
                }
                &-title {
                    margin: 0;
                    font-size: 16px;
                    line-height: 22px;
                    @media(min-width: 576px) {
                        font-size: 25px;
                        line-height: 31px;
                    }
                }
            }
        }
        .swiper-hp-top__counter {
            left: 30px;
            right: auto;
        }
        .swiper-hp-top__link {
            margin-right: 12px;
        }
    }
    .small-article__col {
        padding: 0 15px;
        max-width: 100%;
        @media(min-width: 768px) and (max-width: 1365px) {
            max-width: 50%;
        }
        .small-article__text {
            padding: 20px;
            &-title {
                font-size: 16px;
                line-height: 22px;
                margin: 0;
            }
        }
    }
}
