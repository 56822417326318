.exercises {
    background-color: #DDDDDD;
    padding-top: 46px;
    padding-bottom: 54px;
    margin-bottom: 30px;
    &__el {
        height: 100%;
        border-radius: 20px;
        background-color: #fff;
        padding: 35px 45px 28px 45px;
        text-align: center;
        box-shadow: 0 0 99px rgba(0,0,0,.16);
        @media(max-width:1200px) {
            padding: 15px;
        }
        &-image {
            margin-bottom: 20px;
        }
        &-title {
            font-size: 21px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 37px;
        }
        &-description {
            font-size: 15px;
            line-height: 23px;
        }
    }
    &__col {
        @media(max-width: 1024px) {
            margin-bottom: 30px;
        }
    }
    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-title {
            font-size: 35px;
            line-height: 31px;
            font-weight: 500;
            letter-spacing: 1px;
            text-transform: uppercase;
            margin-bottom: 30px;
        }
        &-more {
            font-size: 14px;
            line-height: 21px;
            padding: 14px 33px 10px 33px;
            font-weight: 700;
            border: 2px solid #1A1B1C;
            margin-bottom: 30px;
            border-radius: 10px;
        }
    }
}