.health-menu {
    background-color: #002B44;
    color: #fff;
    padding: 39px 0 93px 0;
    &__title {
        font-size: 35px;
        line-height: 48px;
        margin-bottom: 40px;
        border-bottom: 3px solid #FB3636;
        text-transform: uppercase;
        padding-top: 3px;
        @media(max-width: 500px) {
            font-size: 28px;
            line-height: 38px;
        }
    }
    &__bottom {
        display: flex;
        @media(max-width: 950px) {
            flex-wrap: wrap;
        }
    }
    &__image {
        max-width: 262px;
        max-height: 262px;
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(221, 221, 221, 0.12);
        border-radius: 10px;
        margin-right: 30px;
        @media(max-width: 1250px) {
            margin-right: 50px;
        }
        @media(max-width: 950px) {
            display: none;
        }
    }
    &__list {
        padding: 0;
        margin: 0;
        columns: 3;
        @media(max-width: 1250px) {
            columns: 2;
        }
        @media(max-width: 950px) {
            max-width: 100%;
        }
        @media(max-width:700px) {
            columns: 1;
        }
        &-el {
            display: block;
            line-height: 36px;
            padding-right: 10px;
            a {
                transition: .3s all;
            }
            &:hover {
                a {
                    color: #FB3636;
                }
            }
            a {
                display: block;
            }
        }
    }
    &--column {
        color: #1A1B1C;
        .health-menu__title {
            font-size: 35px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
        }
        .health-menu__list {
            display: flex;
            flex-wrap: wrap;
            max-width: 1200px;
            .health-menu__list-el {
                width: 100%;
                padding: 0;
                margin-bottom: 30px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-weight: 700;
                padding-right: 10px;
                @media(min-width: $md) {
                    max-width: 50%;
                }
                @media(min-width: $xl) {
                    max-width: calc(100% / 3);
                }
                a {
                    line-height: normal;
                    max-width: calc(100% - 22px);
                }
                &:before {
                    content: "";
                    display: inline-block;
                    width: 10px;
                    height: 13px;
                    margin-right: 12px;
                    background-image: url("/templates/main/images/svg/triangle-menu.svg");
                    margin-top: -2px;
                }
            }
        }
    }
}
