.blue-slider--knowledge {
    &.blue-slider {
        margin: 0;
        padding: 0;
    }
    .blue-slider {
        margin-bottom: 0;
    }
}
.blue-slider {
    background-color: $color-blue-dark;
    color: $color-white;
    padding: 70px 0;
    margin-bottom: 60px;
    &__firm {
        position: absolute;
        top: 27px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: $color-white;
        border-radius: 20px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        padding: 12px 20px;
        justify-content: space-between;
        color: $color-font-main;
        font-size: 12px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        max-width: 300px;
        @media(min-width: $sm) {
            max-width: 310px;
        }
        &-text {
            padding-top: 3px;
        }
    }
    &__title {
        font-size: 22px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        margin-bottom: 50px;
        padding: 0 15px;
        @media(min-width: 1440px) {
            padding: 0 calc((100% - 1410px) / 2);
        }
    }
    &__container {
        padding: 0 15px;
        @media(min-width: 1440px) {
            padding: 0 calc((100% - 1410px) / 2);
        }
    }
    &__nav {
        display: flex;
        flex-wrap: wrap;
    }
    &__controls {
        position: static;
        padding: 30px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        bottom: 150px;
        max-width: 1410px;
        width: 100%;
        .swiper-pagination {
            width: 100%;
            position: relative;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            margin-bottom: 30px;
            &:after {
                content: "";
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: 4px;
                margin: auto;
                background-color: $color-white;
                display: block;
                opacity: 0.5;
                position: absolute;
            }
            .swiper-pagination-bullet {
                width: auto;
                height: 10px;
                background-color: $color-red;
                display: block;
                flex-grow: 1;
                border-radius: 10px;
                opacity: 0;
                position: relative;
                z-index: 3;
                &-active {
                    opacity: 1;
                }
            }
        }
        .swiper-button-next,
        .swiper-button-prev {
            position: relative;
            background-image: none;
            width: 50px;
            height: 50px;
            top: auto;
            left: auto;
            right: auto;
            bottom: auto;
            margin: 0;
            padding: 0;
            transform: none;
            border-radius: 50%;
            border: 2px solid $color-gray;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: url("/templates/main/images/svg/arrow-left.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: 50%;
                transform-origin: 50% 50%;
            }
        }
        .swiper-button-next {
        }
        .swiper-button-prev {
            margin-right: 10px;
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    &__slide {
        &-wrap {
            opacity: 0.5;
            position: relative;
            transition: opacity .2s ease-in-out;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(0deg, rgba(26,27,28,1) 0%, rgba(26,27,28,0) 100%);
                border-radius: 20px;
                opacity: 0.8;
            }
        }
        &-image {
            border-radius: 20px;
            overflow: hidden;
            box-shadow: 0 60px 99px rgba(0,0,0,0.3);
            img {
                outline: 1px solid transparent;
                backface-visibility: hidden;
            }
        }
        &.swiper-slide-active {
            .blue-slider__slide-wrap {
                opacity: 1;
            }
            .blue-slider__slide-text {
                opacity: 1;
            }
        }
        &-text {
            transition: opacity .2s ease-in-out;
            opacity: 0;
            display: block;
            position: absolute;
            bottom: 53px;
            left: 0;
            width: 100%;
            right: 0;
            z-index: 3;
            text-align: center;
            margin: auto;
            max-width: 650px;
            padding: 0 10px;
        }
        &-title {
            font-weight: 700;
            margin-bottom: 20px;
            font-size: 35px;
            line-height: 45px;
            @media(min-width: 440px) {
                font-size: 45px;
                line-height: 55px;
            }
        }
        &-introtext {
            font-size: 16px;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 20px;
            display: none;
            @media(min-width: 440px) {
                display: block;
            }
        }
        &-count {
            font-size: 15px;
            font-weight: 700;
        }
        &-category {
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            margin-bottom: 20px;
            min-height: 29px;
            background: linear-gradient(45deg, rgba(63,182,147,1) 0%, rgba(53,183,193,1) 100%);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding: 0 13px;
            border-radius: 5px;
            padding-top: 2px;
        }
    }
    &__controls {

    }
}
