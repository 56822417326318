.recommended-right {
    background-color: $color-blue;
    color: $color-white;
    border-radius: 20px;
    padding: 30px 20px;
    position: relative;
    z-index: 3;
    margin-bottom: 30px;
    &__top {
        border-bottom: 3px solid $color-red;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
    &__title {
        padding-left: 10px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
    }
    &__subtitle {
        padding-left: 10px;
        font-size: 13px;
        font-weight: 400;
    }
    &__item {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }
        &-link {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        &-title {
            font-size: 16px;
            font-weight: 700;
            padding-right: 15px;
            max-width: calc(100% - 70px);
            width: 100%;
        }
        &-image {
            width: 100%;
            max-width: 70px;
            img {
                border-radius: 5px;
            }
        }
    }
}
