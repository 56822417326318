.reports-module {
    background: linear-gradient(135deg, #284666 0%,#284666 100%);
    border-radius: 20px;
    padding: 30px 20px 36px 20px;
    max-width: 370px;
    margin: 0 auto 35px auto;
    position: relative;
    &__title {
        font-size: 23px;
        line-height: 23px;
        padding-bottom: 13px;
        font-weight: 700;
        color: #fff;
        border-bottom: 3px solid #35B7C1;
        margin-bottom: 21px;
    }
    &__elements {
        &-el {
            margin-bottom: 20px;
            position: relative;
            border-radius: 20px;
            display: block;
            img {
                border-radius: 20px;
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to bottom, rgba(26,27,28,0.25) 0%,rgba(26,27,28,.85) 100%);
                border-radius: 20px;
            }
            &-text {
                position: absolute;
                bottom: 0;
                left: 0;
                padding-bottom: 15px;
                color: #fff;
                text-align: center;
                width: 100%;
                z-index: 1;
                &-title {
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: 700;
                }
                &-firm {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 10px;
                    line-height: 12px;
                    text-transform: uppercase;
                    &-title {
                        margin-top: 3px;
                    }
                    &-logo {
                        padding: 4px 12px 6px 11px;
                        background-color: #fff;
                        border-radius: 4px;
                        margin-left: 7px;
                        img {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    &__btn {
        border-radius: 10px;
        border: 2px solid rgba(255,255,255,.33);
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        color: #fff;
        max-width: 276px;
        width: 100%;
        text-align: center;
        padding: 20px 15px 19px 15px;
        margin: 0 auto;
        margin-top: 34px;
        display: block;
        &:hover {
            color: #fff;
        }
    }
}