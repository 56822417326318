.ocl-module {
    box-shadow: 0 25px 50px rgba(0,0,0,.22);
    &__title {
        font-size: 20px;
        font-weight: 700;
        padding-bottom: 11px;
        border-bottom: 3px solid #F16655;
        text-transform: uppercase;
        margin: 0 22px 36px 22px;
        padding-top: 27px;
    }
    &__wrapper {
        background-color: #fff;
        border-radius: 15px;
        margin-bottom: 30px;
    }
    &__el {
        text-align: center;
        &-title {
            font-size: 20px;
            line-height: 25px;
            font-weight: 700;
            text-align: center;
            max-width: 324px;
            margin: 21px auto 14px auto;
        }
        &-description {
            font-size: 14px;
            line-height: 20px;
            padding: 0 30px 20px 30px;
        }
        &-image {
            margin-bottom: -13px;
        }
        &-category {
            font-size: 10px;
            color: #fff;
            background-color: #FB3636;
            padding: 3px 10px; 
            text-transform: uppercase;
            display: inline-block;
            margin-bottom: -10px auto 0 auto;
        }
        &.only-title {
            padding: 37.5px 74px;
            border-bottom: 1px solid rgba(112, 112, 112, .1);
            @media(max-width:$sm) {
                padding: 15px;
            }
        }
    }
    &__see-more {
        background: linear-gradient(to right, rgba(53,183,193,1) 0%,rgba(63,182,147,1) 100%);
        color: #fff;
        padding: 44px 0 56px 0;
        text-align: center;
        margin-bottom: 50px;
        border-bottom-right-radius: 7px;
        border-bottom-left-radius: 7px;
        .small {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .big {

        }
    }
}