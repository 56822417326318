.main-magazines {
    background-color: transparent;
}
.magazines-list {
    background-color: transparent;
    color: $color-black;
    &__title {
        font-size: 55px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 40px;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        align-content: flex-start;
    }
    &__item {
        text-align: center;
        padding: 0 15px;
        max-width: 400px;
        cursor: pointer;
        margin-bottom: 45px;
        &-image {
            max-width: 330px;
            margin: 0 auto 36px;
            position: relative;
            &:hover {
                .magazines-list__item-links {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
        &-title {
            font-size: 21px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &-subtitle {
            max-width: 300px;
            margin: 0 auto;
            font-size: 17px;
            line-height: 24px;
            opacity: 0.5;
        }
        &-links {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0,43,68,0.5);
            opacity: 0;
            transition: opacity .2s ease-in-out;
            z-index: 3;
            pointer-events: none;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: $color-white;
        }
        &-about, &-archive {
            height: 46px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            cursor: pointer;
            width: 100%;
            max-width: 235px;
            margin-bottom: 10px;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
            border-radius: 10px;
            padding-top: 2px;
        }
    }
    &__popups {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99;
        background-color: $color-blue;
        width: 100%;
        height: 100%;
        transform: translateY(100%);
        transition: transform .2s ease-in-out;
        color: $color-white;
        &.open {
            transform: translateY(0);
        }
        &-btn {
            text-align: center;
            top: -76px;
            position: absolute;
            left: 0;
            width: 100%;
            height: 76px;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.05em;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            background-color: $color-blue;
            text-transform: uppercase;
            cursor: pointer;
            img {
                margin-right: 20px;
            }
        }
        &-close {
            width: 70px;
            height: 70px;
            border-radius: 20px;
            background-color: rgba(255,255,255,0.1);
            position: fixed;
            top: 36px;
            right: 31px;
            opacity: 0;
            z-index: 101;
            cursor: pointer;
            pointer-events: none;
            transition: opacity .2s ease-in-out;
            &.open {
                opacity: 1;
                pointer-events: all;
            }
            span {
                width: 20px;
                height: 3px;
                background-color: $color-white;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                border-radius: 3px;
                &:first-child {
                    transform: rotate(45deg);
                }
                &:last-child {
                    transform: rotate(-45deg);
                }
            }
        }
        &[data-paper="ocl"] {
            .magazines-list__popup[data-paper="ocl"] {
                display: flex;
            }
        }
        &[data-paper="hh"] {
            .magazines-list__popup[data-paper="hh"] {
                display: flex;
            }
        }
        &[data-paper="ter"] {
            .magazines-list__popup[data-paper="ter"] {
                display: flex;
            }
        }
    }
    &__popup {
        display: none;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        overflow: auto;
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            max-width: 1470px;
            padding: 45px 15px;
        }
        &-image {
            width: 31%;
            display: none;
            @media(min-width: $lg) {
                display: block;
            }
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
            &--mobile {
                margin-bottom: 32px;
                @media(min-width: $lg) {
                    display: none;
                }
                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                }
            }
        }
        &-text {
            width: 100%;
            @media(min-width: $lg) {
                padding-left: 60px;
                width: 69%;
            }
        }
        &-title {
            font-size: 48px;
            line-height: 56px;
            font-weight: 700;
            margin-bottom: 36px;
            span {
                display: inline-block;
                padding-bottom: 15px;
                border-bottom: 3px solid $color-red;
            }
        }
        &-introtext {
            &, * {
                font-size: 19px !important;
                line-height: 33px !important;
            }
        }
        &-subtitle {
            font-size: 25px;
            font-weight: 400;
            text-transform: uppercase;
            margin-bottom: 32px;
        }
        &-btns {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
        }
        &-btn {
            width: 100%;
            max-width: 345px;
            height: 70px;
            box-shadow: 0 25px 99px rgba(0,0,0,0.5);
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            font-size: 15px;
            font-weight: 700;
            border-radius: 20px;
            margin-bottom: 30px;
            background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
            &--buy {
                margin-right: 20px;
            }
        }
        p {
            &:nth-last-child(2) {
                margin-bottom: 0;
            }
            &:last-child {
                display: none;
            }
        }
    }
}
