.category-description {
    color: $color-black;
    &__title {
        position: relative;
        z-index: 3;
        font-size: 45px;
        font-weight: 700;
        margin-bottom: 20px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-top: 3px solid $color-red;
        max-width: 1050px;
        padding-top: 16px;
        @media(min-width: $sm) {
            padding-top: 24px;
        }
        &--link.category-description__title {
            align-items: center;
            padding-bottom: 20px;
            @media(min-width: $lg) {
                padding-bottom: 10px;
            }
            .category-description__title-text {
                padding-right: 15px;
                margin-bottom: 10px;
                @media(min-width: $lg) {
                    margin-bottom: 0;
                    max-width: calc(100% - 300px);
                }
                @media(max-width: $sm-max) {
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    -ms-word-break: break-all;
                    /* This is the dangerous one in WebKit, as it breaks things wherever */
                    word-break: break-all;
                    /* Instead use this non-standard one: */
                    word-break: break-word;
                    hyphens: auto;
                }
            }
        }
        &-text {
            margin-right: 25px;
            margin: 0;
            padding: 0;
            font-size: 28px;
            line-height: 36px;
            @media(min-width: $sm) {
                font-size: 38px;
                line-height: 46px;
            }
            @media(min-width: $md) {
                font-size: 45px;
                line-height: 50px;
            }
        }
        &-link {
            display: inline-flex;
            max-width: 243.3px;
            height: 48px;
            justify-content: center;
            align-items: center;
            width: 100%;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            background: linear-gradient(90deg, rgba(241,102,85,1) 0%, rgba(251,54,54,1) 100%);
            border-radius: 14px;
            align-items: center;
            align-content: center;
            position: relative;
            margin-right: 0;
            color: $color-white;
            @media(min-width: $lg) {
                margin-left: auto;
            }
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: $color-red;
                opacity: 0;
                transition: opacity .4s ease-in-out;
                border-radius: 14px;
            }
            &:hover {
                &:after {
                    @media(min-width: $lg) {
                        opacity: 1;
                    }
                }
            }
            span {
                display: block;
                padding-top: 4px;
                position: relative;
                z-index: 3;
            }
            img {
                margin-left: 20px;
                position: relative;
                z-index: 3;
            }
        }
    }
    &__text {
        position: relative;
        z-index: 3;
        max-width: 900px;
        font-size: 17px;
        line-height: 25px;
        margin-bottom: 30px;
        color: $color-black;
    }
    &__image {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 55%;
        overflow: hidden;
        img {
            width: 100%;
            @media(max-width: 1650px) {
                width: inherit;
                max-width: inherit;
            }
            @media(max-width: 1199px) {
                display: none;
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(to right, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            display: block;
        }
    }
    &--short {
        .category-description__text {
            border-bottom: none;
            padding-bottom: 0;
            max-width: 1050px;
        }
    }
}
