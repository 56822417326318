.auraherbals {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 999999;
    font-size: 0.8vw;
    transform: translateY(150%);
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    @media(min-width: 1366px) {
        font-size: 0.9vw;
    }
    @media(min-width: 1530px) {
        font-size: 1vw;
    }
    &.closed {
        transform: translateY(150%);
        opacity: 0;
        pointer-events: none;
    }
    &.sticked {
        transform: translateY(0);
    }
    &__wrap {
        width: 100%;
        max-width: 320px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        position: relative;
        height: 110px;
        justify-content: space-between;
        background-color: #F2F1F0;
        font-size: inherit;
        @media(min-width: 768px) {
            max-width: 768px;
        }
        @media(min-width: $xl) {
            max-width: 1920px;
        }
        &--main {
            position: relative;
            max-width: 320px;
            margin: 0 auto;
            display: block;
            @media(min-width: 768px) {
                max-width: 768px;
            }
            @media(min-width: $xl) {
                max-width: 1920px;
            }
        }
    }
    &__image {
        &--left {
            position: absolute;
            bottom: 0;
            left: 0;
        }
        &--logo {
            margin-left: 68px;
        }
        &--right {
            position: absolute;
            top: 0;
            right: 0;
            img {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
    &__text {
        color: $color-font-main;
        &--title {
            font-size: 2.6em;
            font-weight: 700;
            line-height: 50px;
            margin-top: 5px;
            margin-left: 30px;
            @media(min-width: 1920px) {
                font-size: 50px;
                line-height: 52px;
            }
        }
        &--contact {
            margin-left: 30px;
            font-size: 1.55em;
            line-height: 1.235em;
            font-weight: 700;
            text-align: center;
            margin-right: 120px;
            @media(min-width: 1920px) {
                font-size: 30px;
                line-height: 37px;
            }
            span {
                display: block;
            }
        }
    }
    &__close {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 3;
        cursor: pointer;
        &:after, &:before {
            content: "";
            pointer-events: none;
            display: block;
            height: 2px;
            width: 50%;
            transform-origin: 50% 50%;
            background-color: $color-font-main;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            pointer-events: none;
        }
        &:after {
            transform: rotate(45deg);
        }
        &:before {
            transform: rotate(-45deg);
        }
    }
}
