.firm-item {
    display: flex;
    flex-wrap: wrap;
    background-color: $color-white;
    box-shadow: 0 30px 99px rgba(0,0,0,0.16);
    border-radius: 20px;
    margin-bottom: 30px;
    padding: 20px;
    @media(min-width: $sm) {
        padding: 30px;
    }
    &__firm-type {
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.15em;
        color: $color-blue-light;
    }
    &__image {
        width: 100%;
        margin-bottom: 30px;
        max-width: 165px;
        margin: 0 auto;
        @media(min-width: $sm) {
            max-width: 130px;
            margin-bottom: 0;
        }
        @media(min-width: $lg) {
            max-width: 165px;
        }
        img {
            display: block;
            margin: 0 auto 20px;
            border-radius: 10px;
        }
        &-count {
            color: $color-blue-dark;
            opacity: 0.5;
            font-size: 13px;
            text-align: center;
        }
    }
    &__text {
        width: 100%;
        position: relative;
        @media(min-width: $sm) {
            padding-left: 30px;
            max-width: calc(100% - 130px);
        }
        @media(min-width: $lg) {
            max-width: calc(100% - 165px);
        }
    }
    &__name {
        font-size: 22px;
        font-weight: 700;
    }
    &__time, &__location {
        font-size: 13px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba(112,112,112,0.1);
        margin-bottom: 10px;
    }
    &__location {
        opacity: 0.5;
    }
    &__tags {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        &--blue {
            .firm-item__tag {
                background-color: $color-blue-light;
            }
        }
        &--green {
            margin-bottom: 14px;
            .firm-item__tag {
                background-color: $color-green-light;
            }
        }
    }
    &__tag {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 21px;
        font-size: 13px;
        border-radius: 5px;
        color: $color-white;
        padding: 0 5px;
        margin-right: 4px;
        margin-bottom: 6px;
        font-weight: 700;
        &-link {
            margin-left: 4px;
            font-size: 11px;
            margin-bottom: 6px;
            width: 100%;
            display: block;
            @media(min-width: $md) {
                width: auto;
            }
        }
        &-count {
            font-weight: 700;
        }
    }
    &__type {
        font-size: 15px;
        font-weight: 700;
        margin-bottom: 10px;
    }
    &__info {
        margin-bottom: 15px;
    }
    &__info, &__www {
        font-size: 15px;
    }
    &__tel, &__mail {
        padding-left: 35px;
    }
    &__address {
        width: 100%;
        &-text {
            max-width: calc(100% - 35px);
        }
    }
    &__www, &__address {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        img {
            margin-right: 15px;
        }
    }
    &__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    &__firm-profile {
        position: absolute;
        bottom: 0;
        right: 0;
    }
    &__profile, &__firm-profile {
        text-transform: uppercase;
        color: $color-blue-light;
        font-weight: 700;
        letter-spacing: 0.075em;
        font-size: 11px;
        @media(max-width: $xs-max) {
            margin-top: 20px;
            width: 100%;
            text-align: right;
            position: static;
        }
    }

}
