.articles-list-large-top {
    &.mt-20 {
        margin-top: -30px;
    }
}
.articles-list__articles {
    &.mt-60 {
        margin-top: 60px;
    }
}

.small-article {
    position: relative;
    margin-bottom: 30px;
    box-shadow: 0 25px 40px rgba(0,0,0,.18);
    border-radius: 20px;
    color: #fff;
    border-radius: 20px;
    overflow: hidden;
    & > a {
        display: block;
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
            opacity: 0;
            transition: opacity .5s ease-in-out;
            pointer-events: none;
            z-index: 3;
        }
        &:hover {
            @media(min-width: $lg) {
                &:before {
                    opacity: 0.4;
                }
                img {
                    transform: scale(1.1);
                }
            }
        }
    }
    &.mtm-30 {
        margin-top: -30px;
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background: linear-gradient(to top, rgba(26,27,28,0.65) 0%,rgba(26,27,28,0) 100%);
        background: linear-gradient(to top, rgba(26, 27, 28, 0.85) 0%, rgba(26, 27, 28, 0.25) 100%);
        border-radius: 20px;
        pointer-events: none;
    }
    img {
        transform-origin: 50% 50%;
        transition: transform .5s ease-in-out;
    }
    &__text {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 22px 28px;
        z-index: 3;
        &-category {
            font-size: 11px;
            line-height: 13px;
            font-weight: 700;
            padding: 4px 9px;
            background: linear-gradient(135deg, #f16655 0%,#fb3636 100%);
            border-radius: 5px;
            display: inline-block;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        &-title {
            font-size: 22px;
            line-height: 30px;
            font-weight: 700;
            margin-bottom: 10px;
            &.fs-20 {
                font-size: 20px;
                line-height: 24px;
            }
            &.fs-33 {
                font-size: 33px;
                line-height: 41px;
            }
            &.fs-35 {
                font-size: 28px;
                line-height: 35px;
                @media(max-width:768px) {
                    font-size: 22px;
                    line-height: 30px;
                }
            }
        }
        &-description {
            font-size: 15px;
            line-height: 23px;
            font-weight: 400;
            margin-bottom: 15px;
            max-width: 750px;
        }
        &-tags {
            display: flex;
            flex-wrap: wrap;
            &-el {
                display: inline-block;
                line-height: 29px;
                padding: 0 8px;
                min-height: 29px;
                margin-right: 5px;
                margin-bottom: 5px;
                border-radius: 5px;
                border: 1px solid rgba(255,255,255,.2);
                font-size: 13px;
                font-weight: 500;
                text-align: center;
                &::first-letter {
                    initial-letter: 1;
                    text-transform: uppercase;
                }
            }
        }
    }
}

.three-articles {
    margin-bottom: 30px;
    &__title {
        font-size: 17px;
        line-height: 21px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 21px;
        padding-left: 25px;
        position: relative;
        &::before {
            content: '';
            width: 15px;
            height: 15px;
            background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
            position: absolute;
            left: 0;
            border-radius: 5px;
            margin-top: 1px;
        }
    }
    &__big {
        a {
            display: block;
            &:hover {
                @media(min-width: $lg) {
                    .three-articles__big-image {
                        &:before {
                            opacity: 0.4;
                        }
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }
        }
        &-image {
            border-radius: 15px;
            position: relative;
            margin-bottom: 30px;
            border-radius: 15px;
            overflow: hidden;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
                opacity: 0;
                transition: opacity .5s ease-in-out;
                pointer-events: none;
                z-index: 3;
            }

            &.mb-20 {
                margin-bottom: 20px;
            }
            img {
                transition: transform .5s ease-in-out;
                transform-origin: 50% 50%;
            }
            &-category {
                position: absolute;
                bottom: 0;
                left: 0;
                background: linear-gradient(135deg, #f16655 0%,#fb3636 100%);
                border-radius: 0 15px 0 15px;
                padding: 6px 15px 2px 15px;
                font-size: 11px;
                line-height: 11px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                z-index: 5;
            }
        }
        &-title {
            font-size: 21px;
            line-height: 25px;
            font-weight: 700;
            margin-bottom: 20px;
        }
        &-description {
            font-size: 15px;
            line-height: 23px;
            margin-bottom: 20px;
        }
    }
    &__small {
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        &-image {
            max-width: 90px;
            width: 100%;
            margin-right: 30px;
            border-radius: 10px;
            img {
                border-radius: 10px;
            }
        }
        &-title {
            font-style: 17px;
            line-height: 22px;
            font-weight: 700;
        }
    }
    &__more {
        padding: 15px;
        font-size: 14px;
        line-height: 21px;
        font-weight: 700;
        width: 100%;
        border: 2px solid #1A1B1C;
        text-align: center;
        border-radius: 10px;
        display: block;
        &-title {
            text-transform: uppercase;
        }
    }
    &__tags {
        display: flex;
        flex-wrap: wrap;
    }
    &__tag {
        font-size: 13px;
        font-weight: 400;
        min-height: 29px;
        border: 1px solid #CACACA;
        border-radius: 5px;
        padding: 0 8px;
        display: inline-block;
        line-height: 29px;
        text-align: center;
        margin: 0 5px 5px 0;
        &::first-letter {
            initial-letter: 1;
            text-transform: uppercase;
        }
    }
}
