.magazines-top {
    color: $color-white;
    position: relative;
    z-index: 20;
    height: 80px;
    @media(min-width: 576px) {
        height: 150px;
    }
    &.open {
        .row--370 {
            .col-right {
                @media(min-width: 640px) and (max-width: 767px) {
                    width: 0;
                    padding: 0;
                    max-width: 0;
                    opacity: 0;
                }
            }
            .col-left {
                @media(max-width: 767px) {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }
        .magazines-top {
            &__wrap {
                box-shadow: 0 14px 54px rgba(0,0,0,0.56);
                @media(max-width: 767px) {
                    height: auto !important;
                }
                @media(min-width: 1280px) {
                    max-height: 530px !important;
                    height: 530px !important;
                }
            }
            &__magazines {
                max-width: 100%;
                @media(min-width: 640px) {
                    max-width: calc(100% - 80px);
                }
                @media(min-width: 768px) {
                    max-width: calc(100% - 110px);
                }
                &-wrap {
                    top: 120px;
                    @media(max-width: 767px) {
                        width: 100% !important;
                    }
                    @media(min-width: 768px) {
                        max-width: calc(100vw - 45px);
                    }
                }
            }
            &__btn {
                padding: 0 19px;
                span {
                    width: 0;
                    opacity: 0;
                }
                img {
                    margin-left: 0;
                    transform: rotate(180deg);
                }
            }
        }
    }
    &__logo {
        display: none;
        margin-top: 104px;
        @media(min-width: 1440px) {
            display: block;
        }
    }
    &__wrap {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        overflow: hidden;
        transition: max-height .2s ease-in-out, height .2s ease-in-out, box-shadow .2s ease-in-out;
        background:-moz-linear-gradient(135deg, rgba(0, 43, 68, 1) 0%, rgba(38, 130, 138, 1) 100%);
        background:-webkit-linear-gradient(135deg, rgba(0, 43, 68, 1) 0%, rgba(38, 130, 138, 1) 100%);
        background:-o-linear-gradient(135deg, rgba(0, 43, 68, 1) 0%, rgba(38, 130, 138, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26828A', endColorstr='#002B44', GradientType=1 );
        background:-ms-linear-gradient(135deg, rgba(0, 43, 68, 1) 0%, rgba(38, 130, 138, 1) 100%);
        background:linear-gradient(135deg, rgba(0, 43, 68, 1) 0%, rgba(38, 130, 138, 1) 100%);
        z-index: 9;
        height: 80px;
        max-height: 80px;
        padding-bottom: 30px;
        box-shadow: 0 0 0 0 rgba(0,0,0,0);
        padding-top: 13px;
        @media(min-width: 576px) {
            height: 150px;
            max-height: 150px;
        }
        @media(min-width: 640px) {
            padding-top: 17px;
        }
        @media(max-width: 767px) {
            height: auto !important;
        }
    }
    &__magazines {
        flex-grow: 2;
        transition: max-width .2s ease-in-out;
        max-width: 100%;
        @media(min-width: 640px) {
            max-width: calc(100% - 65px);
        }
        @media(min-width: 768px) {
            max-width: calc(100% - 80px);
        }
        @media(min-width: 1025px) {
            max-width: calc(100% - 236px);
        }
        @media(min-width: 1440px) {
            max-width: 688px;
        }
        &-wrap {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            align-content: flex-start;
            justify-content: space-between;
            transition: top .2s ease-in-out, left .2s ease-in-out, right .2s ease-in-out, width .2s ease-in-out;
            top: 0;
            left: 0;
            right: 0;
            position: static;
            @media(min-width: 768px) {
                position: relative;
            }
            @media(min-width: 1280px) {
                position: static;
                width: 100% !important;
            }
        }
    }
    &__btn {
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        border-radius: 15px;
        height: 50px;
        user-select: none;
        transition: padding .2s ease-in-out;
        padding: 0 19px;
        position: absolute;
        top: 0;
        left: calc(50% + 105px);
        @media(min-width: 640px) {
            background-color: rgba(255,255,255,0.1);
            position: static;
        }
        @media(min-width: 1025px) {
            padding: 0 30px;
        }
        @media(min-width: 1440px) {
            padding: 0 50px;
        }
        span {
            overflow: hidden;
            transition: width .2s ease-in-out, opacity .2s ease-in-out;
            text-align: center;
            white-space: nowrap;
            width: 0;
            @media(min-width: 1025px) {
                width: 122px;
            }
        }
        img {
            transition: margin-left .2s ease-in-out, transform .2s ease-in-out;
            @media(min-width: 1025px) {
                margin-left: 12px;
            }
        }
        &-wrap {
            flex-grow: 1;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            padding-top: 36px;
        }
    }
    &__magazine {
        width: 100%;
        max-width: calc((100% / 4) - 10px);
        @media(min-width: 768px) {
            max-width: calc((100% / 4) - 15px);
        }
        @media(min-width: 1025px) {
            max-width: calc((100% / 4) - 20px);
        }
        &-info {
            display: none;
            @media(min-width: 640px) {
                display: block;
            }
        }
        &-link {
            display: block;
        }
        &-image {
            display: block;
            max-width: 100%;
            height: auto;
            box-shadow: 0 0 30px rgba(0,0,0,0.35);
            margin: 0 auto;
            @media(min-width: 640px) {
                margin: 0 auto 25px;
            }
            @media(min-width: 1025px) {
                margin: 0 auto 30px;
            }
            @media(min-width: 1280px) {
                margin: 0 auto 36px;
            }
        }
        &-title {
            font-weight: 700;
            text-align: center;
            margin-bottom: 15px;
            font-size: 16px;
            @media(min-width: 1025px) {
                margin-bottom: 23px;
            }
            @media(min-width: 900px) {
                white-space: nowrap;
            }
            &--wrap {
                white-space: normal;
            }
        }
        &-description {
            font-size: 13px;
            font-weight: 400;
            line-height: 20px;
            opacity: 0.7;
            text-align: center;
            max-width: 254px;
            margin: 0 auto;
        }
    }
    &__title {
        text-align: center;
        font-weight: 700;
        white-space: nowrap;
        font-size: 15px;
        padding-bottom: 14px;
        @media(min-width: 640px) {
            padding-top: 53px;
            padding-bottom: 0;
        }
        @media(min-width: 768px) {
            font-size: 16px;
        }
    }
    .row--370 {
        .col-left {
            display: flex;
            flex-wrap: wrap;
            transition: width .2s ease-in-out, max-width .2s ease-in-out;
            @media(max-width: 1439px) {
                flex-grow: 2;
                width: auto;
                max-width: calc(100% - 240px);
            }
            @media(max-width: 767px) {
                max-width: calc(100% - 210px);
            }
            @media(max-width: 639px) {
                max-width: 100%;
                width: 100%;
            }
        }
        .col-right {
            overflow: hidden;
            transition: width .2s ease-in-out, padding .2s ease-in-out, opacity .2s ease-in-out, max-width .2s ease-in-out;
            @media(max-width: 1439px) {
                max-width: 240px;
                width: 100%;
            }
            @media(max-width: 767px) {
                max-width: 210px;
            }
            @media(max-width: 639px) {
                max-width: 100%;
            }
        }
    }
}
