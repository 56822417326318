.header {
    &.p-absolute {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        background-color:
        transparent;
    }
}


.knowledge {
    color: #fff;
    margin-top: -173px;
    &-menu {
        background-color: #002B44;
        color: #fff;
        padding: 58px 0;
        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media(max-width: 1280px) {
                flex-wrap: wrap;
                & > div {
                    order: 1;
                }
                & > a {
                    order: 2;
                }
                & > ul {
                    order: 3;
                }
            }
        }
        &__title {
            font-size: 35px;
            letter-spacing: 1px;
            text-transform: uppercase;
        }
        &__list {
            list-style: none;
            margin: 0;
            padding: 0;
            &-el {
                display: inline-block;
                margin-right: 25px;
                font-size: 16px;
                line-height: 27px;
            }
        }
        &__more {
            padding: 10px 15px;
            font-size: 15px;
            line-height: 21px;
            border-radius: 7px;
            border: 1px solid #FFFFFF;
        }
    }
    &__slider {
        &-text {
            max-width: 100%;
            width: 100%;
        }
        .avt-container-1440 {
            height: 100%;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
        }
        .background-size {
            height: 100%;
            max-width: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
        }
        .swiper-slide {
            padding-top: 180px;
            padding-bottom: 178px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            z-index: 1;
            height: auto;
            background-color: #002B44;
            @media(max-width:$md) {
                padding-bottom: 200px;
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, rgba(0,43,68,0.65) 0%,rgba(0,43,68,0.55) 15%,rgba(0,12,45,0) 100%);
            }
            &::before {
                content: '';
                background-size: cover !important;
                background-repeat: no-repeat !important;
                background-position: center !important;
                position: absolute;
                right: 0;
                top: 0;
                max-width: 960px;
                width: 100%;
                height: 100%;
            }
        }
        &-category {
            font-size: 13px;
            line-height: 13px;
            font-weight: 700;
            display: inline-block;
            z-index: 2;
            position: relative;
            margin-bottom: 27px;
            text-transform: uppercase;
        }
        &-title {
            font-size: 45px;
            line-height: 53px;
            font-weight: 700;
            max-width: 809px;
            width: 100%;
            z-index: 2;
            position: relative;
            margin-bottom: 29px;
            @media(max-width:575px) {
                font-size: 35px;
                line-height: 43px;
            }
        }
        &-description {
            font-size: 15px;
            line-height: 23px;
            z-index: 2;
            position: relative;
            margin-bottom: 57px;
            max-width: 810px;
            width: 100%;
        }
        &-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            z-index: 2;
            position: relative;
            max-width: 100%;
            width: 100%;
            &-tags {
                display: flex;
                flex-wrap: wrap;
                &-el {
                    font-size: 13px;
                    padding: 9px 11px;
                    border-radius: 5px;
                    border: 1px solid rgba(255,255,255,.2);
                    margin-right: 10px;
                }
            }
            &-container {
                position: relative;
            }
            &-nav {
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 26px;
                letter-spacing: 1px;
                position: absolute;
                z-index: 5;
                bottom: 163px;
                right: 0;
                padding: 0 15px;
                @media(max-width:$md) {
                    bottom: 130px;
                }
                &-counter {
                    margin-right: 14px;
                }
                &-arrows {
                    display: flex;
                    &-el {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        position: relative;
                        background-color: rgba(255,255,255,.5);
                        margin-left: 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    &__articles {
        margin-top: -112px;
        z-index: 1;
        position: relative;
        margin-bottom: 30px;
        .custom-fs {
            .small-article__text-title {
                font-size: 35px;
                line-height: 40px;
            }
        }
        .row {
            z-index: 1;
            position: relative;
        }
        &::after {
            content: '';
            position: absolute;
            top: 112px;
            left: 0;
            width: 100%;
            max-height: 332px;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0,12,45,1) 0%,rgba(255,255,255,1) 100%);
            opacity: 0.07;
        }
        .small-article::after {
            background: linear-gradient(to top, rgba(26,27,28,0.85) 0%,rgba(26,27,28,0.25) 100%);
        }
    }
}