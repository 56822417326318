.newsletter-main {
    padding: 26px 49px 169px 49px;
    background: #002B44;
    color: rgba(255,255,255,.5);
    @media(max-width:575px) {
        padding: 26px 0 169px 0;
    }
    .newsletter-title {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-bottom: 15px;
        @media(max-width:900px) {
            justify-content: space-between;
        }
        @media(max-width:600px) {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
    .h1-article {
        margin: 0;
        font-size: 40px;
        font-weight: bold;
        line-height: 1;
        text-align: center;
        color: #fff;
        @media(max-width:900px) {
            text-align: left;
        }
        @media(max-width:600px) {
            max-width: 100%;
            flex: 0 0 100%;
            text-align: center;
        }
    }
    .newsletter-example {
        text-align: right;
        @media(min-width:900px) {
            position: absolute;
            right: 0;
        }
        a {
            color: #FCA800;
            font-size: 16px;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }
    .newsletter-description {
        margin: 20px 0 0;
        font-size: 20px;
        line-height: 30px;

        p {
            margin: 0;
        }
    }


    .category__title {
        margin: 40px 0 30px;
        font-size: 26px;
        line-height: 35px;
        p {
            margin: 0;
        }
    }

    .newsletter-gratis {
        font-size: 21px;
        line-height: 35px;
        text-align: center;
        margin-bottom: 15px;
        p {
            margin: 0;
        }
    }
    input.news-input {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 576px;
        box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.16);
        border: none;
        outline: none;
        color: #082134;
        text-align: center;
        font-family: $font-main;
        font-size: 20px;
        line-height: 30px;
        font-weight: bold;
        color: #fff;
        padding: 25px;
        margin-bottom: 33px;
        background-color: #335669;
        border: 3px solid #3FB693;
        border-radius: 28px;
        &:focus, &:active {
            &::placeholder {
                opacity: 0;
            }
        }
    }
    .newspaper-image {
        position: relative;
        display: flex;
        justify-content: flex-end;
        @media(max-width:768px) {
            justify-content: center;
            margin-bottom: 30px;
        }
        img {
            display: block;
            margin-right: 25px;
            box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.16);
        }
    }
    .select-box {
        position: relative;
        margin: 20px 0 0;
        select {
            width: 100%;
            height: 35px;
            padding: 0 9px 0 22px;
            font-size: 17px;
            line-height: 35px;
            box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
            border: none;
            color: #000;
            font-weight: 600;
            font-family: $font-main;
            appearance:none;
        }
        .fa {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translate(-50%,-50%);
            z-index: 2;
            font-size: 16px;
            color: red;
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                font-size: 24px;
            }
        }
        img {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translate(-50%,-50%);
            width: 15px;
            z-index: 2;
            transition: .2s transform;
        }
        .rotate-newsletter {
            transform: translate(-50%,-50%) rotate(180deg);
            transition: .2s transform;
        }
    }
    .avt-info {
        margin-left: 55px;
        font-size: 11px;
        line-height: 17px;
        margin-bottom: 20px;
        #button-text {
            font-weight: 700;
            cursor: pointer;
            text-transform: uppercase;
        }
        #full-text {
            display: none;
        }
    }
    .gift {
        margin-bottom: 35px;
        position: relative;
    }
    #gift-check {
        position: absolute;
        left: -9999px;
    }
    #gift-check:checked + #gift-label:after {
        opacity: 1;
    }
    #gift-label {
        display: block;
        margin: 0 0 0 55px;
        font-size: 11px;
        line-height: 17px;
    }
    #gift-label::before {
        content: '';
        width: 36px;
        height: 36px;
        position: absolute;
        top: 6px;
        left: 0;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
        background: transparent;
        border: 2px solid #DDDDDD;
        border-radius: 8px;

    }
    #gift-label {
        &:after {
            content: "";
            position: absolute;
            top: 6px;
            left: 0;
            background-image: url("/templates/main/images/svg/check-teal.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60%;
            opacity: 0;
            width: 36px;
            height: 36px;
            transition: opacity .2s ease-in-out;
        }
    }
    #additional-info {
        font-size: 11px;
        opacity: 0.5;
        margin-left: 55px;
    }
    .btn-newsletter {
        width: 100%;
        max-width: 576px;
        height: 85px;
        margin: 41px auto 53px auto;
        background: linear-gradient(135deg, rgba(53,183,193,1) 0%,rgba(63,182,147,1) 100%);
        font-size: 17px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #fff;
        line-height: 21px;
        font-weight: 700;
        border: none;
        cursor: pointer;
        box-shadow: 0 25px 99px rgba(0,0,0,.5);
        border-radius: 28px;
        &:focus {
            border: none;
            outline: none;
        }
        .fa {
            font-size: 11px;
            margin-right: 10px;
            color: red;
            vertical-align: 2px;
        }

        &:hover {
            .fa {
                animation: arrow-sliding 1s infinite ease;
            }
        }
    }

    .unsubscribe {
        max-width: 475px;
        .unsubscribe-text {
            font-size: 15px;
        }
        .unsubscribe-form {
            box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.16);
            display: flex;
            margin: 16px 0 0;

            .submit {
                flex: 0 0 138px;
                max-width: 138px;
                height: 40px;
                background: linear-gradient(135deg, rgba(53,183,193,1) 0%,rgba(63,182,147,1) 100%);
                font-size: 15px;
                font-weight: 700;
                cursor: pointer;
                border: none;
                padding: 0;
                text-transform: uppercase;
                color: #fff;
            }
            .mail {
                flex: 1 1 auto;
                height: 40px;
                width: 1%;
                background: #335669;
                border: none;
                box-shadow: none;
                outline: none;
                padding: 0 10px;
                color: #fff;
                border: 1px solid #3FB693;
                &:focus, &:active {
                    &::placeholder {
                        opacity: 0;
                    }
                }
                @media(min-width: $md) {
                    padding: 0 22px;
                }

                // &:focus {
                //     box-shadow: inset 0 0 0 1px #A8A8A8;
                // }
            }
        }
    }

    .open {
        display: inline !important;
    }
    input[type="text"],
    input[type="email"] {
        transition: box-shadow 0.35s ease 0s;

        &:focus {
            box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.16), inset 0 0 0 1px #A8A8A8;
        }
    }
}

.newsletter-gift {
    .gift-text {
        text-align: left;
        @media (max-width: $sm-max) {
            padding-top: 10px;
        }
    }
    .gift-link-row {
        text-align: left;
        @media (max-width: $sm-max) {
            text-align: center;
        }
        .gift-btn {
            padding: 5px 20px;
            background: #92cf35;
            color: $color-white;
            display: inline-block;
            margin-top: 30px;
            font-weight: bold;
        }
    }
 }

 @keyframes arrow-sliding {
  50%  {transform: translateX(-8px)}
  100% {transform: translateX(0)}
}

.newsletter-main.newsletter-main--new {
    padding: 26px 0 169px;
    @media(min-width: $lg) {
        padding: 26px 49px 169px 49px;
    }
    .star {
        display: none;
    }
    .avt-info {
        margin: 0;
        padding: 0;
    }
    .newsletter-title {
        margin-bottom: 164px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .h1-article {
            text-transform: uppercase;
            font-size: 35px;
            letter-spacing: 0.05em;
            width: 100%;
            margin-bottom: 16px;
            text-align: center;
            @media(min-width: $lg) {
                text-align: left;
                width: auto;
                margin: 0 35px;
            }
        }
        .newsletter-title__subtitle {
            font-size: 24px;
            font-weight: 400;
            color: $color-white;
            width: 100%;
            text-align: center;
            @media(min-width: $lg) {
                text-align: left;
                width: auto;
                margin: 0 35px;
            }
        }
    }
    #js-nform {
        position: relative;
        max-width: 960px;
        padding: 0 15px;
        margin: 0 auto 35px;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            border: 2px solid rgba(255,255,255,0.2);
            border-radius: 20px;
            pointer-events: none;
            left: -10px;
            right: -10px;
            @media(min-width: $lg) {
                left: -50px;
                right: -50px;
            }
        }
    }
    .newsletter-main {
        &__message {
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: 700;
            color: $color-white;
            opacity: 1;
        }
        &__banner {
            display: flex;
            flex-wrap: wrap;
            background-image: url("/templates/main/images/static/newsletter.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            overflow: hidden;
            border-radius: 20px;
            position: relative;
            z-index: 3;
            align-items: center;
            font-weight: 400;
            color: $color-font-main;
            font-size: 15px;
            line-height: 20px;
            text-align: center;
            justify-content: center;
            padding: 30px 15px 20px;
            top: -143px;
            @media(min-width: $lg) {
                top: -124px;
                padding: 0 55px;
                height: 250px;
                justify-content: space-between;
                font-size: 24px;
                text-align: left;
                line-height: 35px;
            }
            &-text {
                width: 100%;
                @media(min-width: $lg) {
                    width: auto;
                }
            }
            &-images {
                max-width: 230px;
                @media(min-width: $lg) {
                    max-width: 100%;
                }
                img {
                    display: block;
                    max-width: 100%;
                    height: auto;
                }
            }
        }
        &__wrap {
            max-width: 870px;
            margin: -90px auto 50px;
        }
        &__bottom {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            margin-top: -42px;
            bottom: -42px;
            margin-bottom: 78px;
            z-index: 3;
        }
    }
    .additional-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        &__item {
            margin-bottom: 15px;
            position: relative;
            @media(min-width: $md) {
                width: calc(50% - 24px);
            }
            &-counter {
                width: 50px;
                height: 50px;
                border-radius: 11px;
                background-color: rgba(255,255,255,0.2);
                color: $color-blue-navy;
                display: inline-flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                font-size: 28px;
                padding-top: 6px;
                @media(min-width: $sm) {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            &-title {
                font-size: 16px;
                font-weight: 700;
                line-height: 22px;
                color: $color-white;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                align-content: flex-start;
                margin-bottom: 16px;
                @media(min-width: $sm) {
                    margin-bottom: 0;
                    align-items: center;
                    align-content: center;
                }
            }
            &-text {
                max-width: calc(100% - 50px);
                padding-left: 15px;
                @media(min-width: $sm) {
                    padding-left: 25px;
                    max-width: none;
                    padding-left: 75px;
                }
            }
            &-option {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                position: relative;
                margin-bottom: 8px;
                color: $color-white;
                padding-left: 25px;
                cursor: pointer;
                @media(min-width: $sm) {
                    padding-left: 75px;
                }
                label {
                    margin: 0;
                    color: $color-white;
                    max-width: calc(100% - 25px);
                    padding-left: 15px;
                    margin-top: auto;
                    font-size: 15px;
                    cursor: pointer;

                }
                input {
                    top: 0;
                    left: 75px;
                    width: 25px;
                    height: 25px;
                    opacity: 0;
                    position: absolute;
                    cursor: pointer;
                }
                input:checked ~ .cs-checkbox:after {
                    opacity: 1;
                }
                .cs-checkbox {
                    width: 25px;
                    height: 25px;
                    pointer-events: none;
                    display: inline-flex;
                    border-radius: 3px;
                    border: 1px solid #c4c4c4;
                    position: relative;
                    cursor: pointer;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-image: url("/templates/main/images/svg/check-teal.svg");
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 60%;
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                    }
                }
            }
        }
    }
    .avt-info {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 24px;
        padding-left: 55px;
        #gift-label {
            margin: 0;
            padding: 0;
            display: inline;
            &:before {
                top: 0;
            }
        }
        #dots {
            display: none !important;
        }
        .intro-text {
            display: inline;
        }
    }
    #gift-label {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("/templates/main/images/svg/check-teal.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60%;
            opacity: 0;
            width: 36px;
            height: 36px;
            transition: opacity .2s ease-in-out;
        }
    }
    .form-description {
        font-size: 11px;
        line-height: 17px;
        font-weight: 400;
        max-width: 930px;
        margin: 0 auto 45px;
    }
    .news-input {
        margin: 0 0 24px;
        max-width: none;
        border: none;
        border-radius: 18px;
        width: 100%;
        @media(min-width: $md) {
            margin-right: 15px;
            width: calc(100% - 345px);
        }
        &::placeholder {
            font-weight: 400;
            opacity: 1;
            font-size: 20px;
        }
    }
    .btn-newsletter {
        margin: 0;
        border-radius: 18px;
        width: 100%;
        max-width: none;
        @media(min-width: $md) {
            max-width: 330px;
        }
    }
    #unsubscribe {
        max-width: 930px;
        margin: 0 auto;
    }
    #gift-check {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        opacity: 0;
        visibility: hidden;
    }
}
.download-paper {
    margin-bottom: 20px;
    img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        @media(min-width: $md) {
            margin: 0;
        }
    }
}

.form-com_avtnewsletter {
    scroll-behavior: smooth;
    overflow: hidden;
    height: 100vh;
    background: rgba(0,43,68,1);
    .gift__check {
        position: relative;
    }
    #system-message {
        .gift-btn {
            color: $color-white;
        }
    }
    .gift__info {
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 20px;
        color: $color-white;
    }
    .simplebar-content-wrapper {
        overflow: hidden scroll !important;
        scroll-behavior: smooth;

    }
    &.burger-open {
        @media(min-width: 1025px) {
            padding-right: 17px;
        }
        .simplebar-content-wrapper {
            overflow: hidden !important;
        }
        .body-wrap {
            & > .simplebar-track.simplebar-vertical {
                &, &:hover, &:focus &:active {
                    opacity: 0;
                }
            }
        }
    }
    .newsletter-main,
    .top-menu--new,
    .header {
        background-color: transparent;
    }
    .body-wrap {
        height: 100%;
        & > .simplebar-track.simplebar-vertical {

        }
    }
    .newsletter-main {
        &:after {
            content: "";
            position: absolute;
            top: -168px;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(45deg, rgba(40,70,102,1) 0%, rgba(0,43,68,1) 100%);
            background: rgba(0,43,68,1);
            @media(min-width: 1025px) and (max-width: 1366px) {
                top: -184px;
            }
        }
    }
    .header {
        // position: relative;
        // z-index: 3;
    }
    .top-menu {
        &:before {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 200px;
            background: linear-gradient(0deg, rgba(0,43,68,0) 0%, rgba(0,43,68,0.52) 20%, rgba(0,43,68,0.7) 38%, rgba(0,43,68,1) 66%, rgba(0,43,68,1) 100%); /* w3c */
            z-index: 0;
            pointer-events: none;
        }
    }
    .newsletter {
        padding: 50px 0 90px;
        position: relative;
        &__background {
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: -1;
            pointer-events: none;
        }
        &__container {
            z-index: 3;
            .main-bs-bottom {
                transition: opacity .35s ease-in-out;
                @media(max-width: $md-max) {
                    max-width: 610px;
                    margin: 0 auto;
                }
            }
            &.gifts-open {
                z-index: 12;
                opacity: 0;
                pointer-events: none;
                .main-bs-bottom {
                    opacity: 0;
                    // transform: scale(0);
                    transform-origin: 50% 50%;
                    max-width: 50%;
                    margin-left: 50%;
                    padding-right: 15px;
                    .newsletter__submit-form {
                        height: 75vh;
                        margin: 0 auto;
                    }
                }
                #unsubscribe,
                .avt-info,
                .btn-gift.btn-newsletter,
                .newsletter-gratis,
                .newsletter-title {
                    display: none;
                }
                .gift {
                    margin-bottom: 30px;
                    display: block;
                }
            }
            &.gift-selected {
                position: relative;
                max-width: 1200px;
                padding: 33px 0 0;
                .main-bs-bottom {
                    pointer-events: all;
                    // transform: scale(1);
                    opacity: 1;
                }
                & ~ .our-magazines {
                    display: none;
                }
            }
            &.gifts-open.gift-selected {
                opacity: 1;
            }
        }
        &__gifts {
            margin: 0;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            top: -195px;
            bottom: -203px;
            position: absolute;
            width: 100%;
            display: none;
            height: calc(100% + 250px);
            pointer-events: none;
            @media(min-width: 1025px) {
                display: block;
            }
            @media(min-width: 1025px) and (max-width: 1366px) {
                top: -184px;
                height: calc(100% + 184px);
            }
            &--left {
                left: 0;
                right: auto;
                .swiper-slide {
                    padding: 0 15px 0 35px;
                }
            }
            &--right {
                right: 0;
                left: auto;
                .swiper-wrapper {
                    align-items: flex-end;
                }
                .swiper-slide {
                    padding: 0 35px 0 15px;
                }

            }
            &-close {
                position: absolute;
                width: 194px;
                height: 64px;
                top: 120px;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding-right: 3px;
                cursor: pointer;
                z-index: 999;
                right: 0;
                @media(min-width: 1200px) {
                    right: calc((100% - 1200px) / 2);
                }
            }
            .swiper-slide {
                height: auto;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                padding: 0 15px;
                max-width: calc(100% - 610px);
                img {
                    box-shadow: 0 30px 99px rgba(0,0,0,1);
                    max-width: 100%;
                    height: auto;
                }
            }
            .swiper-wrapper {
                transition-timing-function: linear;
            }
            &.loaded {
                opacity: 1;
            }
        }
        &__scroll-wrap {
            height: 100%;
            overflow-y: auto;
            scroll-behavior: smooth;
            padding-top: 225px;
            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                height: 225px;
                 background: linear-gradient(0deg, rgba(0,43,68,0) 0%, rgba(0,43,68,0.52) 20%, rgba(0,43,68,0.7) 38%, rgba(0,43,68,1) 66%, rgba(0,43,68,1) 100%); /* w3c */
                z-index: 99;
            }
        }
        &__gift {
            transform-origin: 50% 50%;
            opacity: 0.33;
            &-text {
                transition: transform .3s ease-in-out;
            }
            &-wrap {
                display: inline-block;
            }
            &-papers {
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background: linear-gradient(45deg, rgba(40,70,102,1) 0%, rgba(0,43,68,1) 100%);
                background: rgba(0,43,68,1);

                z-index: 9;
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: center;
                // align-items: center;
                // align-content: center;
                padding-top: 0;
                opacity: 0;
                transition: opacity .2s ease-in-out;
                display: block;
                &, * {
                    pointer-events: none;
                }
                &.active {
                    opacity: 1;
                    &, * {
                        pointer-events: all;
                    }
                    .newsletter__gift-paper {
                        * {
                            pointer-events: none;
                        }
                    }
                }
                .simplebar-content-wrapper {
                    overflow: auto !important;
                    scroll-behavior: smooth;
                }
                &.hide-other {
                    .newsletter__gift-paper {
                        opacity: 0;
                    }
                }
                &.select-active {
                    position: absolute;
                    .newsletter__scroll-wrap {
                        overflow: hidden;
                        .simplebar-content-wrapper {
                            overflow: hidden !important;
                        }
                    }
                    .newsletter__gift-paper {
                        opacity: 0;
                        pointer-events: none;
                        // transform: scale(0);
                    }
                    .selected {
                        max-width: none;
                        max-height: none;
                        overflow: visible;
                        pointer-events: all;
                        transform-origin: 0 0;

                        * {
                            pointer-events: none;
                        }
                        // .newsletter__gift-name {
                        //     font-size: 22px;
                        // }
                        // .newsletter__gift-price {
                        //     font-size: 11px;
                        //     .old::after {
                        //         width: 60px;
                        //     }
                        // }
                    }
                    .newsletter__scroll-wrap {
                        overflow: hidden;
                    }
                    &.column-5 {
                        .selected {
                            .newsletter__gift-price {

                            }
                        }
                    }
                }
                &.column-3 {
                    .newsletter__gift-wrap {
                        max-width: 100%;
                        @media(min-width: $sm) {
                            max-width: 50%;
                        }
                        @media(min-width: $lg) {
                            max-width: calc(100% / 3);
                        }
                        .newsletter__gift-price {
                            .old {
                                margin-right: 20px;
                                &:after {
                                    left: -5px;
                                    width: auto;
                                    right: -5px;
                                }
                            }
                        }
                    }
                }
                &.column-4 {
                    .newsletter__gift-wrap {
                        max-width: 100%;
                        @media(min-width: 370px) {
                            max-width: 50%;
                        }
                        @media(min-width: $md) {
                            max-width: calc(100% / 3);
                        }
                        @media(min-width: $lg) {
                            max-width: 25%;
                        }
                        .newsletter__gift-name {
                            font-size: 15px;
                        }
                    }
                }
                &.column-5 {
                    .newsletter__gift-wrap {
                        max-width: 100%;
                        @media(min-width: 370px) {
                            max-width: 50%;
                        }
                        @media(min-width: $sm) {
                            max-width: calc(100% / 3);

                        }
                        @media(min-width: $md) {
                            max-width: 25%;
                        }
                        @media(min-width: $lg) {
                            max-width: 20%;
                        }
                        img {
                            margin-bottom: 10px;
                        }
                        .newsletter__gift-name {
                            font-size: 13px;
                            margin-bottom: 5px;
                        }
                        .newsletter__gift-price {
                            font-size: 13px;
                            .old {
                                margin-right: 25px;
                                &:after {
                                    width: 70px;
                                }
                            }
                        }
                    }
                }
            }
            &-container {
                max-width: 1200px;
                display: flex;
                flex-wrap: wrap;
                position: relative;
                justify-content: center;
                height: 100%;
                margin: 0 auto;
                // display: block;
                font-size: 0;
            }
            &-price {
                font-size: 15px;
                color: $color-white;
                transition: all .3s ease-in-out;
                .old {
                    margin-right: 45px;
                    color: rgba(255,255,255,0.5);
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -10px;
                        right: 0;
                        margin: auto;
                        height: 1px;
                        width: 100px;
                        background-color: $color-red;
                        transition: all .3s ease-in-out;

                    }
                }
                .new {
                    font-weight: 700;
                }
            }
            &-name {
                font-size: 17px;
                font-weight: 500;
                color: $color-white;
                margin-bottom: 13px;
                transition: all .3s ease-in-out;
            }
            &-paper {
                cursor: pointer;
                user-select: none;
                transition: all .3s ease-in-out;
                transform-origin: 0 0;
                margin-bottom: 30px;
                max-height: 1000px;
                display: inline-block;
                padding: 0 10%;
                outline: 1px solid transparent;
                backface-visibility: hidden;
                * {
                    pointer-events: none;
                }
                &.selected {
                    opacity: 1 !important;
                    pointer-events: all;
                    &:hover {
                        img,
                        .newsletter__gift-text {
                            transform: none;
                        }
                    }
                }
                // &.clone {
                //     position: absolute;
                //     top: 0;
                //     opacity: 0;
                //     img {
                //         box-shadow: none;
                //     }
                // }
                // &.left {
                //     left: 0 !important;
                //     max-width: calc(42% - 35px) !important;
                //     opacity: 1 !important;
                //     padding: 0;
                //     transition: all .3s ease-in-out, opacity 0s linear;
                // }
                // &.clone-active {
                //     right: auto;
                //     top: 0;
                //     bottom: 0;
                //     display: flex;
                //     flex-wrap: wrap;
                //     align-items: center;
                //     align-content: center;
                //     cursor: default;
                //     opacity: 0;
                //     transition: all .3s ease-in-out, opacity 0s linear;
                // }
                img {
                    box-shadow: 0 30px 50px rgba(0,0,0,0.48);
                    margin-bottom: 20px;
                    max-width: 100%;
                    height: auto;
                    transition: all .3s ease-in-out;
                    outline: 1px solid transparent;
                    backface-visibility: hidden;
                }
                &-text {
                    outline: 1px solid transparent;
                    backface-visibility: hidden;
                }
                &:hover {
                    img,
                    .newsletter__gift-text {
                        @media(min-width: $lg) {
                            transform: translate3d(0,-10px, 0);
                        }
                    }
                }
            }

        }
        &__container {
            max-width: 1280px;
            margin: 0 auto;
            position: relative;
            padding: 0 15px;
            .newsletter-title {
                margin-bottom: 0;
                justify-content: center;
                max-width: 610px;
            }
            .h1-article {
                font-size: 50px;
                margin-bottom: 20px;
            }
            .news-input {
                max-width: 510px;
                margin-bottom: 20px;
                border: none;
                height: 80px;
                &::placeholder {
                    opacity: 1;
                    font-weight: 400;
                    font-size: 20px;
                    color: $color-white;
                }
            }
            .newsletter-gratis {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 40px;
                color: $color-white;
            }
            .btn-newsletter {
                max-width: 510px;
                height: 80px;
                &[type='submit'] {
                    margin: 0 auto;
                }
            }
            .btn-newsletter.btn-gift {
                background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                margin: 0 auto 50px;
                height: 80px;
            }
            .avt-info {
                margin: 0 auto 40px;
                max-width: 510px;
            }
            .gift {
                max-width: 510px;
                margin: 0 auto;
                display: none;
            }
        }
        &__submit-form {
            margin-bottom: 200px;
            max-width: 610px;
        }
        &__unsubscribe-form {
            text-align: center;
            max-width: 610px;
            .unsubscribe {
                max-width: 510px;
                margin: 0 auto;
            }
            .unsubscribe-text {
                font-size: 20px;
                opacity: 0.7;
                margin-bottom: 20px;
            }

            .mail,
            .submit {
                height: 50px;
            }
            .mail {
                border: 1px solid rgba(255,255,255,0.2);
                text-align: center;
                border-top-left-radius: 17px;
                border-bottom-left-radius: 17px;
                background: transparent;
            }
            .submit {
                background: rgba(255,255,255,0.2);
                border-top-right-radius: 17px;
                border-bottom-right-radius: 17px;
                font-size: 13px;
                font-weight: 400;
                letter-spacing: 0.05em;
            }
        }
    }
    .header.header-subpages.header--new {
        .header__burger {
            @media(min-width: $md) and (max-width: $md-max) {
                margin-right: 0;
            }
        }
    }
    &.burger-open {
        .header.header-subpages.header--new {
            .header__burger {
                @media(min-width: $md) and (max-width: $md-max) {
                    margin-right: 15px;
                }
                @media(max-width: $sm-max) {
                    margin-right: 30px;
                }
            }
        }
    }
    &.gift-selected {

        .our-magazines {
            display: none;
        }
        .newsletter__gifts.loaded {
            opacity: 0;
        }
        .newsletter__gift-wrap {
            @media(max-width: $md-max) {
                display: none;
            }
            &.children-selected {
                @media(max-width: $md-max) {
                    display: block;
                }
                .newsletter__gift-paper {
                    @media(max-width: $md-max) {
                        transform: translate3d(0,0,0) !important;
                    }
                }
            }
        }
        .mobile-wrap {
            @media(max-width: $md-max) {
                display: flex;
                flex-wrap: wrap;
            }

        }
        .newsletter__gift-papers {
            &, &.select-active {
                @media(max-width: $md-max) {
                    order: 1;
                    width: 100%;
                    position: static;
                }
                .newsletter__scroll-wrap,
                .simplebar-mask,
                .simplebar-offset,
                .simplebar-content-wrapper,
                .simplebar-content {
                    @media(max-width: $md-max) {
                        width: 100% !important;
                        position: static !important;
                        height: auto !important;
                        overflow: visible !important;
                        margin: 0 !important;
                        padding: 0 !important;
                    }
                }
            }
            .newsletter__gifts-close {
                @media(max-width: $md-max) {
                    position: static;
                    width: 100%;
                    justify-content: flex-end;
                    padding: 0 15px;
                }
            }
            .newsletter__scroll-wrap {
                &:after {
                    @media(max-width: $md-max) {
                        display: none;
                    }
                }
                .simplebar-placeholder {
                    @media(max-width: $md-max) {
                        display: none;
                    }
                }
            }
            .newsletter__gift-wrap {
                @media(max-width: $md-max) {
                    width: 100%;
                    max-width: 100%;
                    text-align: center;
                }
                .newsletter__gift-paper {
                    @media(max-width: $md-max) {
                        margin: 0 auto 30px;
                        padding: 0 15px;
                        & > img {
                            margin: 0 auto 30px;
                        }
                    }
                }
            }

        }
        .newsletter-main {
            @media(max-width: $md-max) {
                order: 2;
                width: 100%;
                padding-top: 0;
            }
            .main-bs-bottom {
                @media(max-width: $md-max) {
                    max-width: 100%;
                    width: 100%;
                    margin: 0;
                    padding: 0 15px;
                }
                .newsletter__submit-form {
                    @media(max-width: $md-max) {
                        height: auto;
                    }
                }
            }

        }
    }
    .top-menu--new {
        position: relative;
        z-index: 10;
    }
    #system-message-container {
        padding: 0 15px;
    }
    #system-message {
        .alert.alert-message, .alert.alert-error {
            color: $color-white;
            background-color: #284666;
            position: relative;
            z-index: 9;
            box-shadow: 0 25px 99px rgba(0,0,0,0.16);
            padding: 30px;
            border-radius: 26px;
        }
        .alert {
            & > div {
                padding-right: 15px;
                .go-back-link {
                    a {
                        color: $color-white;
                    }
                    &:before {
                        background-image: url("/templates/main/images/svg/arrow-btn.svg");
                        transform-origin: 50% 50%;
                        transform: rotate(180deg);
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}
