.form-com_avtnewsletter {
    .top-menu__link {
        color: $color-white;
    }
    header {
        z-index: 10;
        &.header.header-subpages .header__logo {
            svg {
                max-width: 266px;
                #Path_1479 {
                    fill: $color-white;
                }
                #Path_4 {
                    stroke: $color-white;
                }
                #Path_3 {
                    stroke: $color-white;
                }
            }
        }
        .header__burger span {
            background-color: $color-white;
        }
        .header__search svg {
            circle,path {
                stroke: $color-white;
            }
        }
    }
    .header__new.header.header-subpages .header__menu > ul > li > a {
        color: $color-white;
    }
}

.header {
    background-color: $color-blue-dark;
    transition: background-color .1s ease-in-out;
    padding-top: 45px;
    @media(min-width: $md) {
        padding-bottom: 30px;
        padding-top: 45px;
    }
    &__new {
        background-color: #fff;
        margin-bottom: 30px;
        position: relative;
        z-index: 1;
        padding: 0px;
        &.no-margin {
            margin-bottom: 0px;
        }
        @media(min-width: 768px) {
            padding: 15px 0;
        }
        @media(min-width: 1025px) {
            padding: 0 0 24px 0;
        }
        &.header.header-article {
            background-color: #fff;
        }
        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 305px;
            background: linear-gradient(to bottom, #000c2d 0%,transparent 80%);
            opacity: 0.07;
            pointer-events: none;
        }
        &.header {
            &.header-subpages {
                .header__menu {
                    & > ul {
                        & > li {
                            & > a {
                                color: $color-black;
                            }
                        }
                        li {
                            &.active,&.current,&.alias-parent-active {
                                a {
                                    color: $color-black;
                                }
                                &.parent {
                                    a {
                                        color: $color-black;
                                    }
                                }
                                &::after {
                                    background-color: $color-black;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.header.header-subpages .header__logo {
            svg {
                max-width: 266px;
                #Path_1479 {
                    fill: $color-black;
                }
                #Path_4 {
                    stroke: $color-black;
                }
                #Path_3 {
                    stroke: $color-black;
                }
            }
        }
        .header__burger span {
            background-color: $color-black;
        }
        .header__search svg {
            circle,path {
                stroke: $color-black;
            }
        }
    }
    &.header-subpages.header--new {
        padding-top: 30px;
        @media(min-width: $lg) {
            padding-top: 0;
        }
        .header__wrap {
            height: auto;
            &:after, &:before {
                display: none;
            }
        }
        .header__search {
            @media(max-width: $md-max) {
                width: 80px;
                height: 80px;
                background-color: $color-blue;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                max-width: none;
                margin-right: 15px;
                border-radius: 18px;
            }
            @media(max-width: $sm-max) {
                width: 60px;
                height: 60px;
            }
            @media(max-width: $xs-max) {
                display: none;
            }
        }
        .header__burger {
            @media(max-width: $md-max) {
                width: 80px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                align-content: center;
                background-color: $color-blue;
                border-radius: 18px;
                margin-right: 15px;
                &-wrap {
                    cursor: pointer;
                    width: 20px;
                    height: 14px;
                    position: relative;
                }
            }
            @media(max-width: $sm-max) {
                width: 60px;
                height: 60px;
            }
        }
        .header__logo {
            max-width: none;
            @media(min-width: $lg) {
                display: none;
            }
            @media(min-width: 1410px) {
                display: block;
            }
            @media(max-width: 459.98px) {
                max-width: 210px;
            }
        }
        .header__menu {
            display: none;
            margin-left: 0;
            flex-grow: 1;
            margin-right: 30px;
            @media(min-width: $lg) {
                display: block;
            }
            @media(min-width: 1410px) {
                margin-left: auto;
                flex-grow: unset;
                margin-right: 0;
            }
            #burger-menu__menu--main {
                @media(max-width: 1365.98px) {
                    justify-content: space-between;
                }
                li {
                    display: block;
                    @media(max-width: 1365.98px) {
                        margin: 0;
                    }
                }
                .item-1155 {
                    display: none;
                }
            }
        }
    }
    &, &.header-subpages {
        .header__menu {
            .hp-menu {
                & > li {
                    &.item-1055, &.item-101 {
                        & > a {
                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                z-index: 5;
                                top: calc(100% + 20px);
                                left: calc((100% - 275px) / 2);
                                height: 82px;
                                width: 275px;
                                pointer-events: none;
                                background-color: transparent;
                            }
                        }
                        &:hover {
                            & > a:after {
                                pointer-events: all;
                            }
                        }
                    }
                }
            }
        }
    }
    &__container {
        @media(max-width: $sm-max) {
            padding: 0;
        }
    }
    &__list {
        display: block;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        background-color: $color-white;
        left: 0;
        width: 100%;
        z-index: 20;
        padding-bottom: 45px;
        top: 80px;
        box-shadow: 0 130px 99px rgba(0,0,0,0.2);
        position: absolute;
        pointer-events: none;
        @media(min-width: $md) {
            top: 153px;
        }
        &-container {
            position: relative;
        }
        ul {
            display: flex;
            flex-wrap: wrap;
            list-style: none;
            margin: 0;
            padding: 0;
            position: static;
            width: 100%;
            justify-content: space-around;
            .item-809 {
                display: block;
                border: 1px solid $color-white;
                padding: 0 10px;
                border-radius: 7px;
            }
            .item-817, .item-809 {
                @media(min-width: $lg) {
                    display: none;
                }
            }
            & > li {
                font-weight: 700;
                margin-bottom: 28px;
                width: 100%;
                text-align: center;
                font-size: 16px;
                @media(min-width: $md) {
                    font-size: 20px;
                }
                @media(min-width: $lg) {
                    text-align: left;
                }
                @media(min-width: 1280px) {
                    position: absolute;
                }
                &:hover {
                    @media(min-width: $lg) {
                        & > a {
                            color: $color-white;
                            &:after {
                                right: 0;
                            }
                        }
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
                & > a {
                    transition: color .2s ease-in-out;
                }
                & > ul {
                    display: none;
                }
            }
            .item-804 {
                position: static;
                top: 0;
                bottom: 0;
                justify-content: center;
                @media(min-width: $lg) {
                    width: 50%;
                }
                @media(min-width: 1280px) {
                    width: calc(50% - 130px);
                    margin-left: 260px;
                }
            }
            .item-805 {
                position: static;
                top: 0;
                bottom: 0;
                justify-content: center;
                @media(min-width: $lg) {
                    width: 50%;
                }
                @media(min-width: 1280px) {
                    width: calc(50% - 130px);
                    justify-content: flex-end;
                }
            }
            .item-804, .item-805 {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-start;
                align-content: flex-start;
                & > a {
                    @media(min-width: $lg) {
                        display: block;
                        width: 100%;
                        max-width: 450px;
                        font-size: 13px;
                        font-weight: 700;
                        text-transform: uppercase;
                        letter-spacing: 0.15em;
                        padding-bottom: 13px;
                        border-bottom: 2px solid $color-teal;
                        margin-bottom: 20px;
                    }
                }
                ul {
                    flex-wrap: wrap;
                    width: 100%;
                    max-width: 450px;
                    columns: 2;
                    @media(min-width: $lg) {
                        display: block;
                    }
                    li {
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 5px;
                        position: static;
                    }
                }
            }
            .item-807,
            .item-803,
            .item-808,
            .item-802,
            .item-814 {
                height: 31px;
                left: 0;
                text-align: center;
                @media(min-width: $lg) {
                    width: auto;
                    text-align: left;
                    max-width: none;
                }
                @media(min-width: 1280px) {
                    width: 100%;
                    max-width: 260px;
                }
            }
            .item-807 {
                top: 0;
            }
            .item-808 {
                top: 61px;
            }
            .item-802 {
                top: 122px;
            }
            .item-814 {
                @media(min-width: $lg) {
                    display: none;
                }
            }
        }
    }
    &__logo {
        max-width: 185px;
        @media(min-width: $md) {
            max-width: none;
        }
        svg {
            max-width: 100%;
        }
        &-text {
            @media(max-width: $xs-max) {
                display: none;
            }
        }
    }
    &__wrap {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 15;
        @media(max-width: $sm-max) {
            height: 80px;
        }
        &:before {
            content: "";
            height: 80px;
            width: 1px;
            top: 0;
            right: 80px;
            background-color: rgba(255,255,255,0.1);
            display: block;
            position: absolute;
            z-index: 3;
            @media(min-width: $md) {
                display: none;
            }
        }
        &:after {
            content: "";
            height: 1px;
            top: 80px;
            left: 0;
            right: 0;
            background-color: rgba(255,255,255,0.1);
            display: block;
            position: absolute;
            z-index: 3;
            @media(min-width: $md) {
                display: none;
            }
        }
    }
    &__burger {
        cursor: pointer;
        width: 20px;
        height: 14px;
        position: relative;
        order: 2;
        margin-right: 30px;
        @media(min-width: $md) {
            margin-right: 0;
        }
        span {
            display: block;
            width: 100%;
            height: 2px;
            border-radius: 4px;
            position: absolute;
            background-color: $color-white;
            left: 0;
            transform-origin: 50% 50%;
            transition: all .2s ease-in-out;
            &:first-child {
                top: 0;
                bottom: auto;
            }
            &:nth-child(2) {
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:nth-child(3) {
                top: 0;
                bottom: 0;
                margin: auto;
            }
            &:last-child {
                bottom: 0;
                top: auto;
            }
        }
    }
    &__menu {
        margin-left: auto;
        justify-self: flex-end;
        display: none;
        @media(min-width: 1410px) {
            display: block;
        }
        & > ul {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            color: $color-white;
            list-style: none;
            margin: 0;
            padding: 0;
            transition: color .2s ease-in-out;
            position: relative;
            .item-1020 {
                display: none;
            }
            .item-1317 {
                display: none;
            }
            & > li {
                margin-right: 30px;
                font-size: 16px;
                font-weight: 700;
                transition: all .2s ease-in-out;
                display: none;
                padding: 20px 0;
                position: relative;
                z-index: 3;
                @media(min-width: $xl) {
                    display: block;
                }
                & > .header-module {
                    z-index: 3;
                }
                &.parent {
                    & > a {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        align-content: center;
                        &:after {
                            content: "";
                            display: inline-block;
                            background-image: url("/templates/main/images/svg/triangle.svg");
                            width: 9px;
                            height: 7px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-color: transparent;
                            margin-left: 8px;
                        }
                    }
                    &:after {
                        content: "";
                        position: fixed;
                        top: 0;
                        left: 0;
                        bottom: 0;
                        right: 0;
                        background-color: rgba(26,27,28,0.45);
                        opacity: 0;
                        pointer-events: none;
                        z-index: 0;
                        transition: opacity .4s;
                    }
                }
                &.current, &.active {
                    color: $color-red;
                    & > a:before {
                        right: 0;
                    }
                }
                & > .sub-menu {
                    position: absolute;
                    top: 100%;
                    opacity: 0;
                    pointer-events: none;
                    background-color: $color-white;
                    width: 275px;
                    border-radius: 15px;
                    background-color: $color-blue-bg;
                    transition: opacity .2s ease-in-out;
                    box-shadow: 0 60px 99px rgba(0,0,0,0.16);
                    z-index: 99;
                    left: calc((100% - 275px) / 2);
                    &:after {
                        content: "";
                        position: absolute;
                        top: -1px;
                        left: 0;
                        right: 0;
                        background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
                        height: 3px;
                        margin: auto;
                    }
                    .sub-menu__header {
                        min-height: 60px;
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        align-content: center;
                        pointer-events: none;
                        &:before {
                            content: "";
                            background-position: center;
                            background-repeat: no-repeat;
                            margin-right: 12px;
                        }
                        &:after {
                            content: "";
                            font-size: 18px;
                            font-weight: 400;
                        }
                    }
                    ul {
                        margin: 0;
                        padding: 0;
                        list-style: none;
                    }
                    ul > li {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        width: 100%;
                        color: $color-font-main;
                        background-color: $color-white;
                        &:first-child {
                            padding-top: 20px;
                        }
                        &:last-child {
                            padding-bottom: 20px;
                            border-bottom-left-radius: 15px;
                            border-bottom-right-radius: 15px;
                        }
                        & > a {
                            display: block;
                            min-height: 40px;
                            line-height: 40px;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }
                }
                &.current {
                    & > a {
                        color: $color-white;
                        &:after {
                            right: 0;
                        }
                    }
                }
            }
        }
    }
    .item-1055 {
        .sub-menu {
            & > ul {
                & > li {
                    &:nth-last-child(2) {
                        padding-bottom: 20px;
                        border-bottom-left-radius: 15px;
                        border-bottom-right-radius: 15px;
                    }
                }
            }
            &:after {
                width: 115px;
            }
            .sub-menu__header {
                &:before {
                    background-image: url("/templates/main/images/svg/knowledge.svg");
                    width: 28px;
                    height: 24px;
                }
                &:after {
                    content: "Wiedza";
                }
            }
        }
    }
    .item-101 {
        .sub-menu {
            &:after {
                width: 175px;
            }
            .sub-menu__header {
                &:before {
                    background-image: url("/templates/main/images/svg/therapist.svg");
                    width: 34px;
                    height: 35px;
                }
                &:after {
                    content: "Terapeuci";
                }
            }
        }
    }
    .logo-sticky {
        display: none;
    }
    &.header-subpages {
        .header__logo {
            &, &--subpages {
                img, svg {
                    max-width: 295px;
                    @media(max-width: $sm-max) {
                        margin-left: 15px;
                    }
                }
                & > a {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    align-content: center;
                }
                .header__logo-text {
                    color: $color-white;
                    margin-left: 30px;
                    position: relative;
                    padding-top: 2px;
                    &:after {
                        content: "";
                        height: 22px;
                        left: -15px;
                        width: 2px;
                        background-color: $color-white;
                        opacity: 0.5;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                    }
                }
            }
        }
        .header__menu {
            & > ul {
                & > li {
                    & > a {
                        transition: color .2s ease-in-out;
                        display: inline;
                        color: $color-white;
                        position: relative;
                        z-index: 3;
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 100%;
                            height: 1px;
                            background-color: #1A1B1C;
                            margin: 0;
                            padding: 0;
                            background-image: none;
                            width: auto;
                            transition: background-color .2s ease-in-out;
                        }
                    }
                }
                li.parent {
                    a {
                        &:after {
                            display: none;
                        }
                    }
                }
                li.alias-parent-active,
                li.current,
                li.active,
                li.active.parent,
                li.current.parent {
                    a {
                        display: inline;
                        color: $color-white;
                        position: relative;
                        &:after {
                            content: "";
                            display: block;
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            right: 0;
                            height: 1px;
                            background-color: #1A1B1C;
                            margin: 0;
                            padding: 0;
                            background-image: none;
                            width: auto;
                        }
                    }
                }

            }
        }
    }
}
.header {
    .item-1155 {
        display: none;
    }
    &.header-subpages {
        .header__search {
            margin-right: 30px;
            max-width: 18px;
            cursor: pointer;
            margin-left: auto;
            @media(max-width: $md-max) {
                margin-right: 60px;
            }
            @media(max-width: 450px) {
                margin-right: 15px;
            }
            @media(max-width: 370px) {
                display: none;
            }
            @media(min-width: 1410px) {
                margin-left: 0;
            }
        }
        .header__menu {
            & > #burger-menu__menu--main {
                & > li {
                    position: relative;
                    &:hover {
                        a {
                            color: $color-red;
                            &:after {
                                background-color: $color-red;
                            }
                        }
                    }
                    &.current, &.active {
                        &:hover {
                            a {
                                text-decoration: none;
                            }
                        }
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        left: -5px;
                        right: -5px;
                        top: calc(100% - 2px);
                        height: 4px;
                        background-color: $color-red;
                        opacity: 0;
                        transition: opacity .2s ease-in-out;
                        z-index: 9;
                        pointer-events: none;
                    }
                    &.item-1169 {
                        &:before {
                            display: none;
                        }
                    }
                    & > .header-module {
                        opacity: 0;
                        pointer-events: none;
                        position: absolute;
                        width: 440px;
                        margin: auto;
                        top: 100%;
                        left: calc((100% - 440px) / 2);
                        background-color: $color-white;
                        transition: opacity .2s ease-in-out;
                        color: $color-font-main;
                        font-size: 16px;
                        margin: 0;
                        padding: 35px 42px 30px;
                        box-shadow: 0 60px 99px rgba(0,0,0,0.3);
                        border-radius: 7px;
                        display: flex;
                        flex-wrap: wrap;
                        & > ul {
                            list-style: none;
                            order: 1;
                            margin: 0 0 15px;
                            padding: 0;
                            & > li {
                                color: $color-font-main;
                                margin-bottom: 5px;
                                font-weight: 500;
                                a {
                                    color: $color-font-main;
                                }
                                ul {
                                    display: none;
                                    a {
                                        color: $color-font-main;
                                    }
                                }
                                &.current, &.active {
                                    &, & > a {
                                        color: $color-red;
                                    }
                                }
                            }
                        }
                        .header-module__wrap {
                            order: 2;
                        }
                    }
                }
            }
        }
    }
}
