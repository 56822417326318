.report-single {
    .bg-blue {
        padding-bottom: 150px;
        &__container {
            position: relative;
            z-index: 3;
        }
        &__image {
            position: absolute;
            top: -175px;
            right: 0;
            bottom: 0;
            width: 100%;
            max-width: 1200px;
            overflow: hidden;
            display: none;
            @media(min-width: 1025px) {
                display: block;
            }
            img {
                margin-left: auto;
                margin-right: 0;
                display: block;
                width: 100%;
            }
            &:after {
                content: "";
                right: 0;
                top: 0;
                bottom: 0;
                left: 0;
                background: linear-gradient(to right, rgb(255, 255, 255) 0%,rgba(255,255,255,0) 100%);
                display: block;
                position: absolute;
            }
        }
        &__return {
            margin-bottom: 0;
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 17px;
        &.border-bottom-single {
            border-bottom: 3px solid #35b7c1;
            .report-single__firm {
                margin-bottom: 17px;
            }
            .bg-blue__return {
                line-height: 23px;
            }
        }
        &-left {
            margin-right: 10px;
        }
    }
    &__firm {
        color: var(--colorFont);
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 5px;
        width: 100%;
        @media(min-width: $sm) {
            width: auto;
        }
        span {
            padding-top: 4px;
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
            text-align: center;
            @media(min-width: $sm) {
                width: auto;
                text-align: left;
                margin-bottom: 0;
            }
        }
        img {
            padding: 8px;
            border-radius: 5px;
            background-color: $color-white;
            @media(min-width: $sm) {
                margin-left: 25px;
            }
        }
    }
    &__title {
        color: $color-black;
        font-size: 55px;
        line-height: 60px;
        font-weight: 700;
        margin-bottom: 0px;
        @media(max-width:500px) {
            font-size: 35px;
            line-height: 40px;
        }
        &-wrapper {
            padding-top: 21px;
            margin-bottom: 30px;
            border-top: 3px solid $color-teal;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            .special-raport-btn {
                color: #fff;
                padding: 8px 10px;
                background: linear-gradient(to right, rgba(63,182,147,1) 0%,rgba(53,183,193,1) 100%);
                border-radius: 5px;
                text-transform: uppercase;
                font-weight: 700;
                white-space: nowrap;
            }
        }
        &--tag {
            &::first-letter {
                initial-letter: 1;
                text-transform: uppercase;
            }
        }
    }
    &__introtext {
        color: $color-black;
        font-size: 21px;
        line-height: 33px;
    }
    &__content {
        .col-left {
            margin-top: -75px;
        }
        .col-right {
            margin-top: -75px;
        }
    }
    &--tag {
        .report-single__title {
            padding-bottom: 0;
            border-bottom: none;
        }
    }
}
.single-article.raport {
    .single-article__top {
        &:after {
            display: none;
            @media(min-width: 1025px) {
                display: block;
            }
        }
    }
}
