body {
    &:after {
        @media(max-width: 1439.98px) {
            content: "";
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            background-color: rgba(0,0,0,0.2);
            position: fixed;
            opacity: 0;
            z-index: 2;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
        }
    }
}
.mobile-select-open {
    &:after {
        @media(max-width: 1439.98px) {
            opacity: 1;
            pointer-events: all;
        }
    }
}
.firm-container {
    margin: -160px auto 100px;
    @media(min-width: $md) {
        margin: -140px auto 100px
    }
    @media(min-width: $lg) {
        margin: -85px auto 100px
    }
    &--sort {
        margin: -360px auto 100px;
        @media(min-width: $sm) {
            margin: -280px auto 100px;
        }
        @media(min-width: 600px) {
            margin: -280px auto 100px;
        }
        @media(min-width: $md) {
            margin: -280px auto 100px
        }
        @media(min-width: $lg) {
            margin: -175px auto 100px
        }
    }
}
.article-list__top {
    &.article-list__top--firm-list {
        @media(max-width: $md-max) {
            padding-bottom: 290px;
        }
        @media(max-width: 599.98px) {
            padding-bottom: 280px;
        }
        @media(max-width: $xs-max) {
            padding-bottom: 360px;
        }
        @media(min-width: $md) {
            padding-top: 0;
            padding-bottom: 285px;
        }
        @media(min-width: $lg) {
            padding-bottom: 205px;
        }
        .breadcrumb {
            margin-bottom: 20px;
        }
    }
}

.therapist-list__header {
    padding-top: 10px;
    border-top: 3px solid $color-red;
}
.therapist-description {
    font-size: 18px;
    font-weight: 400;
    line-height: 33px;
    color: $color-black;
    margin-bottom: 40px;
}
.firm {
    min-width: 330px;
    font-family: $font-main;
    // padding: 10px;
    .top {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 17px;
        .logo {
            max-width: 75px;
            border-radius: 10px;
            overflow: hidden;
            max-height: 75px;
            height: 75px;
            width: 100%;
            position: relative;
            margin-right: 13px;
            img {
                display: block;
                width: 100%;
                max-width: 100%;
                height: auto;
                position: absolute;
                left: -9999px;
                right: -9999px;
                top: -9999px;
                bottom: -9999px;
                margin: auto;
            }
        }
        .name {
            font-size: 16px;
            font-weight: 700;
            margin: 0;
            padding: 0;
            margin-bottom: 6.5px;
        }
    }
    .address, .tel, .contact, .www {
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
        color: $color-font-main;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        &:before {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-right: 5px;
            position: static;
            background: none;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    .address {
        &:before {
            background-image: url("/templates/main/images/svg/address-pin.svg")
        }
    }
    .tel {
        &:before {
            background-image: url("/templates/main/images/svg/phone.svg")
        }
    }
    .contact {
        &:before {
            background-image: url("/templates/main/images/svg/mail.svg")
        }
    }
    .www {
        margin-bottom: 0;
        &:before {
            background-image: url("/templates/main/images/svg/external-link.svg")
        }
    }
    .rating {
        font-size: 13px;
        font-weight: 400;
        color: $color-blue-dark;
        opacity: 0.5;
    }
}

.firm-list {
    &__empty {
        text-align: center;
        font-weight: 400;
        font-size: 18px;
        margin-bottom: 45px;
        margin-top: 160px;
        @media(min-width: $lg) {
            margin-top: 120px;
        }
        &-text {
            margin-bottom: 45px;
        }
        a {
            color: $color-red;
        }
    }
    .lower {
        text-transform: lowercase;
    }
    .img-avatar {
        border-radius: 10px;
        // border: 1px solid #ddd;
        width: 100%;
        max-width: 100%;
        max-height: 165px;
        &.p-sm {
            // padding: 10px;
        }
    }
    &__mobile-btns {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        @media(min-width: $lg) {
            display: none;
        }
    }
    &__mobile-btn {
        margin-top: 10px;
        height: 40px;
        width: 100%;
        border: 2px solid rgba(255,255,255,0.4);
        max-width: calc(50% - 3px);
        border-radius: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: rgba(255,255,255,0.4);
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        &:hover {
            &, a {
                color: rgba(255,255,255,0.4);
            }
        }
        &--white {
            background-color: rgba(255,255,255,0.4);
            color: $color-white;
            border: none;
            &:hover {
                &, a {
                    color: $color-white;
                }
            }
        }
    }
    &__select {
        opacity: 0;
        transition: opacity .2s ease-in-out;
        width: 100%;
        border-radius: 10px;
        margin-top: 10px;
        border: 2px solid rgba(255,255,255,0.4);
        position: relative;
        @media(min-width: $lg) {
            border: none;
            border-radius: 0;
            margin-top: 0;
            max-width: calc(100% / 3 - 7px);
        }
        #select-type {
            z-index: 15;
        }
        #select-location {
            z-index: 12;
            .simplebar-track.simplebar-vertical {
                right: 37px;
                bottom: 15px;
                .simplebar-scrollbar {
                    background-color: $color-white;
                    border-radius: 4px;
                    &:before {
                        display: none;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 1px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    background-color: rgba(255,255,255,0.2);
                }
            }
        }
        #select-sort {
            z-index: 9
        }
        &.loaded {
            opacity: 1;
        }
        &.selected {
            .reset-link {
                display: block;
                position: absolute;
                width: 22px;
                height: 22px;
                right: 30px;
                top: 0;
                bottom: 0;
                margin: auto;
                border-radius: 50%;
                z-index: 18;
                opacity: 0.6;
                cursor: pointer;
                span {
                    position: absolute;
                    width: 75%;
                    height: 2px;
                    transform-origin: 50% 50%;
                    background-color: $color-white;
                    display: block;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    &:first-child {
                        transform: rotate(45deg);
                    }
                    &:last-child {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        .cs-select {
            position: relative;
            width: 100%;
        }
        .cs-title {
            height: 40px;
            background-color: $color-white;
            width: 100%;
            border: none;
            outline: none;
            color: $color-black;
            font-size: 13px;
            font-weight: 700;
            padding-left: 20px;
            border-radius: 10px;
            user-select: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            span {
                position: relative;
                z-index: 9;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                width: 100%;
                a:nth-child(2) {
                    position: relative;
                    z-index: 20;
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .cs-list-wrap {
            height: 0;
            transition: height .2s ease-in-out;
            overflow: hidden;
            top: 0px;
            width: 100%;
            border-radius: 10px;
            background-color: $color-white;
            z-index: 3;
            filter: drop-shadow(0 15px 30px rgba(0,0,0,.16));
            @media(min-width: $lg) {
                position: absolute;
                padding-top: 40px;
            }
            &:after {
                position: absolute;
            }
        }
        .cs-list {
            height: 100%;
            overflow: hidden;
            overflow-y: scroll;
            color: $color-black;
            list-style: none;
            margin: 0;
            padding: 0 15px 15px;
            width: calc(100% + 20px);
            font-size: 15px;
        }
        .cs-btn {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40px;
            z-index: 12;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 15px;
                background-image: url("/templates/main/images/svg/chevron-down-black.svg");
                margin: auto;
                width: 15px;
                height: 8px;
                background-repeat: no-repeat;
                -webkit-transform-origin: 50% 50%;
                transform-origin: 50% 50%;
                transition: transform .2s ease-in-out;
            }
        }
        .cs-select.open {
            .cs-btn {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        #select-location.open {
            .cs-list-wrap {
                height: 310px;
            }
        }
        #select-type.open {
            .cs-list-wrap {
                height: 85px;
                @media(min-width: $lg) {
                    height: 128px;
                }
            }
        }
        #select-sort.open {
            .cs-list-wrap {
                height: 105px;
                @media(min-width: $lg) {
                    height: 170px;
                }
            }
        }
    }
    &__tools {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @media(min-width: $lg) {
            justify-content: space-between;
        }
        &--select {
            .firm-list__map-btn {
                height: 60px;
                border-radius: 16px;
                background-color: $color-white;
                width: 140px;
                margin: 0;
                @media(min-width: $sm) {
                    margin: 0 5px;
                }
                @media(min-width: $lg) {
                    margin: 0 0 0 10px;
                }
                @media(min-width: $xl) {
                    display: none;
                }
                img,svg {
                    margin-right: 12px;
                }
            }
            #filter-sort {
                display: none;
            }
            #select-type {
                .simplebar-track.simplebar-vertical {
                    right: 15px;
                }
            }
            #select-type.open {
                .cs-list-wrap {
                    height: 80px;
                    @media(min-width: 1366px) {
                        height: 153px;
                    }
                }
            }
            #select-location {
                .simplebar-track.simplebar-vertical {
                    right: 15px;
                }
            }
            .firm-list__select {
                order: 4;
                border-radius: 16px;
                width: 100%;
                max-width: none;
                border: 2px solid rgba(0,0,0,0.4);
                margin-top: 0;
                @media(min-width: 1440px) {
                    border: none;
                }
                .cs-btn {
                    height: 50px;
                    @media(min-width: 1440px) {
                        height: 60px;
                    }
                    &:after {
                        right: 10px;
                    }
                }
                .cs-select {
                    border-radius: 16px;
                    &:after {
                        border-radius: 16px;
                    }
                    .simplebar-track.simplebar-vertical {
                        right: 15px;
                    }
                }
                .cs-title {
                    border-radius: 16px;
                    padding-left: 15px;
                    height: 50px;
                    @media(min-width: 1440px) {
                        height: 60px;
                    }
                }
                .cs-list {
                    padding-left: 15px;
                }
                .cs-list-wrap {
                    border-radius: 16px;
                    position: relative;
                    padding-top: 0;
                    @media(min-width: 1440px) {
                        padding-top: 60px;
                        position: absolute;
                    }
                }
                &:first-child {
                    margin-right: 0;
                    margin-top: 0;
                    margin-bottom: 20px;
                    @media(min-width: 1440px) {
                        margin-right: 10px;
                        margin-bottom: 0;
                    }
                }
            }
            .main__select-wrap {
                padding: 0;
                color: $color-black;
                width: 100%;
                max-width: 290px;
                margin: 0 0 20px;
                @media(min-width: 600px) {
                    margin: 0 5px 20px;
                    max-width: 275px;
                }
                @media(min-width: $lg) {
                    margin: 0 0 20px;
                }
                @media(min-width: 1440px) {
                    margin: 0;
                    width: auto;
                    width: 100%;
                }
                .cs-btn {
                    height: 60px;
                    &:after {
                        right: 10px;
                    }
                }
                &:first-child {
                    // order: 1;
                    @media(min-width: $lg) {
                        margin-right: 20px;
                    }
                    @media(min-width: 1366px) {
                        margin-right: 10px;
                    }
                }
                &:nth-child(2) {
                    // order: 2;
                    @media(min-width: $lg) {
                        margin-right: 0;
                    }
                    @media(min-width: 1366px) {
                        margin-right: 10px;
                    }
                }
                .cs-select {
                    border-radius: 16px;
                    &:after {
                        border-radius: 16px;
                        height: 60px;
                    }
                    &.open {
                        &::after {
                            height: 440px;
                        }
                    }
                    .simplebar-track.simplebar-vertical {
                        right: 15px;
                    }
                }
                .cs-select .cs-title--finder span {
                    color: $color-black;
                }
                .cs-select.cs-select--finder.open {
                    .cs-title {
                        filter: none;
                    }
                }
                .cs-title {
                    height: 60px;
                    border-radius: 16px;
                    padding-left: 15px;
                    background: $color-white;
                    font-size: 13px;
                    z-index: 1;
                    filter: drop-shadow(0 15px 30px rgba(0,0,0,.16));
                }
                &.selected {
                    .cs-title {
                        background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
                        span {
                            color: $color-white;
                        }
                    }
                    .cs-btn {
                        &::after {
                            background-image: url("/templates/main/images/svg/chevron-down.svg");
                        }
                    }
                    .cs-form {
                        input {
                            border-bottom: 1px solid rgba(255,255,255,.2);
                            color: $color-white;
                        }
                        &::after {
                            background-image: url("/templates/main/images/svg/search-icon.svg");
                        }
                    }
                }
                .cs-list {
                    padding: 0 30px 0 15px;
                    font-size: 15px;
                    li {
                        font-size: 15px;

                        a {
                            font-size: 15px;
                        }
                    }
                    .disable-click {
                        text-transform: uppercase;
                        font-weight: 700;
                        letter-spacing: .1em;
                        margin-bottom: 16px;
                        .disable-link {
                            font-size: 13px;
                            line-height: 18px;
                            margin-bottom: 8px;
                            opacity: 0.3;
                            pointer-events: none;
                        }
                        ul {
                            margin: 0;
                            padding: 0;
                        }
                    }
                    .subitem {
                        text-transform: none;
                        letter-spacing: normal;
                        font-size: 16px;
                        opacity: 1;
                        font-weight: 400;
                        margin-bottom: 0;
                        &-link {
                            margin-bottom: 0;
                            opacity: 1;
                            pointer-events: all;
                        }
                    }
                }
                .cs-see-all {
                    left: 15px;
                    right: 15px;
                }
                .cs-select--selected-list.open:after {
                    height: 460px;
                }
                .cs-select--selected-list.open .cs-list-wrap {
                    height: 380px;
                }
            }
            #filter-type {
                order: 3;
                width: 100%;
                border: 2px solid rgba(0,0,0,0.4);
                margin-bottom: 15px;
                @media(min-width: 1440px) {
                    max-width: 140px;
                    border: none;
                    margin-right: 10px;
                    margin-bottom: 0;
                }
                .cs-list-wrap {
                    position: relative;
                    padding-top: 0;
                    @media(min-width: 1440px) {
                        position: absolute;
                        padding-top: 60px;
                    }
                }
                &.selected {
                    .reset-link {
                        display: block !important;
                    }
                }
            }
            .firm-list__wrap-select {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 100%;
                position: relative;
                transition: height .2s ease-in-out;
                margin: 0 0 15px;
                max-width: 100%;
                @media(min-width: 380px) {
                    margin: 0 10px 15px;
                }
                @media(min-width: 440px) {
                    margin: 0 0 15px;
                }
                @media(min-width: $lg) {
                    margin-bottom: 0;
                }
                @media(min-width: $xl) {
                    height: auto;
                    overflow: visible;
                    margin-right: 0;
                }
                @media(max-width: 1365.98px) {
                    &.open {
                        &[data-element="select-type"],
                        &[data-element="select-location"],
                        &[data-element="select-sort"],
                        &[data-element="firm-select-problems"],
                        &[data-element="firm-select-spec"],
                        &[data-element=""] {
                            .firm-list__mobile-wrap {
                                height: 60px;
                            }
                        }
                    }
                }
            }
            .firm-list__main-wrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
                @media(min-width: $lg) {
                    justify-content: flex-start;
                    width: calc(100% - 310px);
                }
                @media(min-width: $xl) {
                    width: calc(100% - 140px);
                }
                @media(min-width: 1440px) {
                    width: 570px;
                }
            }
            .firm-list__wrap-right {
                display: flex;
                flex-wrap: wrap;
                width: auto;
                align-items: flex-start;
                align-content: flex-start;
                width: 100%;
                bottom: 0;
                left: 0;
                justify-content: center;
                padding-bottom: 20px;
                position: static;
                @media(min-width: $lg) {
                    background: transparent;
                    width: auto;
                    position: static;
                    justify-content: flex-start;
                }
                @media(min-width: 1440px) {
                    width: calc(100% - 570px);
                }
                &.selected {
                    .firm-list__mobile-select {
                        border: none;
                        background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
                        img {
                            opacity: 1;
                        }
                    }
                }
            }
            .open {
                .firm-list__mobile-wrap {
                    z-index: 20;
                }
            }
            .firm-list__mobile-wrap {
                display: flex;
                flex-wrap: wrap;
                width: auto;
                align-items: flex-start;
                align-content: flex-start;
                justify-content: space-between;
                border-radius: 10px;
                transition: height .2s ease-in-out;
                z-index: 7;
                position: relative;
                overflow: visible;
                height: 60px;
                padding: 0;
                border-radius: 16px;
                background-color: $color-blue-dark;
                width: 140px;
                margin-right: 10px;
                @media(min-width: $sm) {
                    margin: 0 5px;
                }
                @media(min-width: $lg) {
                    margin: 0;
                    background-color: transparent;
                }
                @media(min-width: 1440px) {
                    flex-grow: 1;
                    flex-wrap: nowrap;
                    width: auto;
                }
                &.open {
                    z-index: 20;
                }
            }
            .firm-list__mobile-wrapper {
                border-radius: 16px;
                transition: height .2s ease-in-out;
                z-index: 7;
                display: block;
                position: absolute;
                display: flex;
                width: 290px;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                background-color: $color-white;
                opacity: 0;
                pointer-events: none;
                transition: opacity .2s ease-in-out;
                padding: 30px 24px;
                left: 0;
                bottom: calc(100% + 20px);
                z-index: 99;
                @media(min-width: $sm) {
                    bottom: calc(100% + 10px);
                }
                @media(min-width: $lg) {
                    left: -75px;
                    top: 70px;
                    bottom: auto;
                }
                @media(min-width: 1440px) {
                    position: static;
                    background-color: transparent;
                    align-items: flex-start;
                    align-content: flex-start;
                    justify-content: space-between;
                    flex-grow: 1;
                    flex-wrap: nowrap;
                    width: auto;
                    height: auto;
                    opacity: 1;
                    pointer-events: all;
                    padding: 0;
                }
                &.open {
                    opacity: 1;
                    pointer-events: all;
                }
            }
            .firm-list__mobile-btn {
                @media(max-width: 1365.98px) {
                    display: flex;
                }
            }
            .firm-list__mobile-select {
                height: 60px;
                width: 100%;
                border: none;
                outline: none;
                color: $color-black;
                font-size: 13px;
                font-weight: 700;
                padding-left: 20px;
                border-radius: 16px;
                user-select: none;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                cursor: pointer;
                position: relative;
                text-align: center;
                justify-content: center;
                z-index: 9;
                padding: 0;
                background-color: transparent;
                filter: drop-shadow(0 15px 30px rgba(0,0,0,.16));
                background-color: $color-white;
                &:after {
                    display: none;
                }
                img,svg {
                    margin-right: 12px;
                    transition: opacity .2s ease-in-out, background .2s ease-in-out;
                    opacity: 0.2;
                }
                svg {
                    path {
                        stroke: #1a1b1c;
                    }
                }
                span {
                    position: relative;
                    z-index: 9;
                }
                @media(min-width: 1440px) {
                    display: none;
                }
                &.open {
                    border: none;
                    background: linear-gradient(to bottom right,#f16655 0,#fb3636 100%);
                    color: $color-white;
                    img {
                        opacity: 1;
                    }
                    svg {
                        path {
                            stroke: $color-white;
                        }
                    }
                }
            }
        }
    }
    &__wrap-select {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        max-width: 300px;
        position: relative;
        transition: height .2s ease-in-out;
        margin: 0 0 15px;
        @media(min-width: 380px) {
            margin: 0 10px 15px;
        }
        @media(min-width: 440px) {
            margin: 0 10px 15px 0;
        }
        @media(min-width: $lg) {
            height: auto;
            overflow: visible;
            margin-right: 0;
            max-width: calc(100% - 100px);
        }
        @media(max-width: $md-max) {
            &.open {
                .firm-list__mobile-wrap {
                    height: 276px;
                }
                &[data-element="select-type"] {
                    .firm-list__mobile-wrap {
                        height: 361px;
                    }
                }
                &[data-element="select-location"] {
                    .firm-list__mobile-wrap {
                        height: 586px;
                    }
                }
                &[data-element="select-sort"] {
                    .firm-list__mobile-wrap {
                        height: 381px;
                    }
                }
            }
        }
    }
    &__mobile-wrap {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-content: flex-start;
        justify-content: space-between;
        border-radius: 10px;
        transition: height .2s ease-in-out;
        z-index: 7;
        @media(max-width: $md-max) {
            padding: 40px 30px 0px;
            background-color: $color-blue;
            position: absolute;
            height: 0;
            overflow: hidden;
        }
    }
    &__map-btn {
        width: 90px;
        height: 40px;
        color: $color-black;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 10px;
        filter: drop-shadow(0 15px 30px rgba(0,0,0,.16));
        cursor: pointer;
        user-select: none;
        margin: 0 0 15px;
        background-color: $color-white;
        @media(min-width: 380px) {
            margin: 0 10px 15px;
        }
        @media(min-width: 440px) {
            margin: 0 0 15px;
        }
        &.open {
            background: $main-gradient;
            color: $color-white;
            border: none;
            img,svg {
                opacity: 1;
            }
            svg {
                path,circle {
                    stroke: $color-white;
                }
            }
        }
        img,svg {
            opacity: 0.2;
            transition: opacity .2s ease-in-out;
            margin-right: 5px;
        }
        svg {
            path,circle {
                stroke: #1a1b1c;
            }
        }
        &--right {
            width: 100%;
            margin: 0 0 30px;
            padding: 0;
            background: transparent;
            border: none;
            color: $color-font-main;
            height: auto;
            position: relative;
            border-radius: 24px;
            overflow: hidden;
            cursor: pointer;
            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(255,255,255,0.5);
            }
            img {
                margin: 0;
                padding: 0;
                opacity: 1;
                width: 100%;
            }
            .firm-list__map-btn-text {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 3;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                img {
                    margin-bottom: 16px;
                    width: 50px;
                }
                span {
                    width: 100%;
                    display: block;
                    text-align: center;
                }
            }
        }
    }
    &__mobile-select {
        height: 40px;
        background-color: $color-blue;
        width: 100%;
        border: none;
        outline: none;
        color: $color-white;
        font-size: 13px;
        font-weight: 700;
        padding-left: 20px;
        border-radius: 10px;
        user-select: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 9;
        span {
            position: relative;
            z-index: 9;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 15px;
            background-image: url("/templates/main/images/svg/chevron-down-black.svg");
            margin: auto;
            width: 15px;
            height: 8px;
            background-repeat: no-repeat;
            -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
            transition: transform .2s ease-in-out;
            z-index: 9;
        }
        @media(min-width: $lg) {
            display: none;
        }
    }
}
