#search-form {
    color: $color-black;
    padding-bottom: 92px;
    .searched-expression {
        margin-bottom: 30px;
        font-size: 15px;
        line-height: 21px;
        text-transform: uppercase;
        color: $color-black;
    }
    .tm-search-form__counter-value {
        font-size: 15px;
        line-height: 21px;
        margin-top: 30px;
    }
    .tm-search-form__form {
        display: flex;
        align-items: center;
        @media(max-width: 768px) {
            flex-wrap: wrap;
        }
        position: relative;
        max-width: 1008px;
        width: 100%;
        .search-input {
            width: 100%;
            input {
                background-color: transparent;
                font-size: 40px;
                line-height: 49px;
                font-weight: 700;
                color: $color-black;
                padding: 43px 243px 37px 43px;
                border-radius: 49px;
                border: 2px solid rgba(0,0,0,.2);
                width: 100%;
                outline: none;
                @media(max-width: 768px) {
                    padding: 15px 30px;
                    font-size: 30px;
                    line-height: 39px;
                    margin-bottom: 20px;
                }
            }
        }
        .tm-search-form__submit {
            position: absolute;
            right: 15px;
            font-size: 16px;
            line-height: 21px;
            font-weight: 700;
            color: #fff;
            border-radius: 26px;
            background: linear-gradient(135deg, rgba(241,102,85,1) 0%,rgba(251,54,54,1) 100%);
            border: none;
            padding: 28px 67px;
            text-transform: uppercase;
            @media(max-width: 768px) {
                padding: 15px 47px;
                position: relative;
                margin: 0 auto;
            }
        }
    }
}

#search-results {
    margin-top: 45px;
    .search-result-empty {
        margin-bottom: 50px;
        text-align: center;
        &__image {
            img {
                margin: 0 auto;
                display: block;
                max-width: 600px;
                width: 100%;
            }
        }
        &__title {
            font-size: 35px;
            font-weight: 700;
            margin-bottom: 20px;
        }
    }
    .search-item {
        margin-bottom: 30px;
        .image {
            @media(max-width:575px) {
                margin-bottom: 20px;
            }
            img {
                border-radius: 15px;
            }
            &--category {
                display: flex;
                flex-wrap: wrap;
                font-size: 20px;
                font-weight: 700;
                padding-bottom: 65%;
                position: relative;
                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    align-content: center;
                    position: absolute;
                    background-color: $color-white;
                    box-shadow: 0 0 90px rgba(0,0,0,0.16);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border-radius: 15px;
                }
            }
        }
        .category {
            font-size: 11px;
            line-height: 13px;
            font-weight: 700;
            padding: 4px 9px;
            background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
            border-radius: 5px;
            display: block;
            margin-bottom: 10px;
            text-transform: uppercase;
            color: #fff;
            &--guide {
                background: $color-teal;
            }
        }
        .link {
            display: flex;
            align-items: center;
            align-content: center;
            flex-wrap: wrap;
            height: 100%;
            .title {
                font-size: 22px;
                line-height: 25px;
                font-weight: 700;
                margin-bottom: 15px;
                width: 100%;
            }
            .introtext {
                font-size: 15px;
                line-height: 23px;
            }
            &__text {
                font-size: 15px;
                font-weight: 700;
                text-transform: uppercase;
                text-decoration: underline;
                color: $color-red;
                display: block;
                width: 100%;
                margin-top: 10px;
            }
        }
    }
}

.results {
    &__wrapper {
        background-color: #284666;
        border-radius: 20px;
        color: #fff;
        padding: 38px 28px;
        margin-bottom: 30px;
    }
    &__title {
        font-size: 15px;
        line-height: 13px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        border-bottom: 3px solid #FB3636;
        margin-bottom: 25px;
    }
    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 17px;
        line-height: 21px;
        &-item {
            transition: .3s all ease-in-out;
            padding: 11px 0;
            &.active {
                text-decoration: underline;
                &::before {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
            &:hover {
                text-decoration: underline;
                &::before {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
            &::before {
                content: '';
                background-image: url('/templates/main/images/svg/search-arrow.svg');
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                width: 13px;
                height: 10px;
                display: inline-block;
                margin-right: 12px;
                opacity: 0;
                transform: translateX(-5px);
                transition: .3s all ease-in-out;
            }
        }
    }
}
