.therapists-hp {
    margin-bottom: 60px;
    &__title {
        font-size: 25px;
        font-weight: 700;
        padding-bottom: 25px;
        margin-bottom: 30px;
        border-bottom: 1px solid rgba(112,112,112,0.1);
    }
}
.white {
    .therapists-hp {
        margin-top: -200px;
        &__title {
            color: $color-white;
        }
    }
}
