.our-guide {
    margin-bottom: 85px;
    &__module-title {
        font-size: 35px;
        font-weight: 700;
        padding-bottom: 25px;
        border-bottom: 1px solid rgba(112,112,112,0.1);
        margin-bottom: 50px;
    }
    &__btn {
        margin: 0 auto;
        max-width: 300px;
        .btn-border__content {
            background-color: $color-white;
        }
    }
    &__item {
        position: relative;
        text-align: center;
        border-radius: 20px;
        overflow: hidden;
        margin-bottom: 30px;
        color: $color-white;
        &:hover {
            @media(min-width: $lg) {
                .our-guide__item-image {
                    img {
                        // filter: blur(5px);
                        transform: scale(1.1);
                    }
                }
                &:after {
                    opacity: 0.8;
                }
            }
        }
        &-image {
            overflow: hidden;
            border-radius: 20px;
            // background-color: $color-blue-dark;
            img {
                transform-origin: 50% 50%;
                transition: all .5s ease-in-out;
                backface-visibility: hidden;
                outline: 1px solid transparent;
                width: 100%;
            }
        }
        &:after {
            content: "";
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(0,12,45,1) 0%, rgba(0,12,45,0) 100%);
            opacity: 0.3;
            position: absolute;
            display: block;
            pointer-events: none;
            transition: opacity .5s ease-in-out;
            border-radius: 20px;
        }
        &-category {
            height: 29px;
            border-radius: 5px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            align-content: center;
            padding-top: 2px;
            text-transform: uppercase;
            font-size: 13px;
            letter-spacing: 0.05em;
            font-weight: 700;
            background: linear-gradient(45deg, #3fb693 0%, #35b7c1 100%);
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            margin: auto;
            max-width: 155px;
            width: 100%;
        }
        &-text {
            position: absolute;
            z-index: 3;
            left: 15px;
            right: 15px;
            bottom: 35px;
            @media(min-width: $lg) {
                left: 25px;
                right: 25px;
            }
        }
        &-title {
            font-weight: 700;
            font-size: 23px;
            line-height: 30px;
            @media(min-width: $lg) {
                margin-bottom: 35px;
                font-size: 30px;
                line-height: 37px;
            }
        }
        &-introtext {
            font-size: 15px;
            font-weight: 400;
            margin-bottom: 25px;
            display: none;
            @media(min-width: 430px) {
                display: block;
            }
            @media(min-width: $md) and (max-width: $md-max) {
                display: none;
            }
        }
        &-more {
            text-transform: uppercase;
            font-size: 15px;
            font-weight: 700;
            display: none;
            @media(min-width: 430px) {
                display: block;
            }
            @media(min-width: $md) and (max-width: $md-max) {
                display: none;
            }
            img {
                margin-left: 15px;
            }
        }
    }
}
