.single-therapist {
    &__breadcrumb {
        position: relative;
        color: $color-black;
        padding-left: 0;
    }
    .person {
        &-category {
            &-el {
                &.green {
                    background-color: #3FB693;
                }
                display: inline-block;
                padding: 3px 6px;
                background-color: #35B7C1;
                color: #fff;
                border-radius: 5px;
                font-size: 13px;
                margin-bottom: 6px;
            }
            &-see-all {
                display: inline-block;
                font-size: 11px;
                line-height: 23px;
                margin-left: 17px;
                cursor: pointer;
                @media(max-width:$sm) {
                    // display: none;
                    margin-bottom: 10px;
                    margin-left: 0;
                }
                .bold {
                    font-weight: 700;
                }
            }
        }
    }
    &__person {
        display: flex;
        flex-wrap: wrap;
        border-radius: 20px;
        padding: 45px 45px 20px 45px;
        background-color: #fff;
        position: relative;
        z-index: 3;
        margin-bottom: 30px;
        z-index: 10;
        @media(max-width:$sm-max) {
            padding: 15px;
        }
        &-btns {
            display: flex;
            max-width: 100%;
            flex: 0 0 100%;
            margin-top: 24px;
            border-top: 1px solid rgba(112, 112, 112, .1);
            @media(max-width:$sm-max) {
                overflow-y: hidden;
                overflow-x: scroll;
                padding-top: 3px;
                padding-bottom: 15px;
            }
            &-el {
                font-size: 13px;
                letter-spacing: 1px;
                font-weight: 700;
                text-transform: uppercase;
                margin-right: 60px;
                position: relative;
                padding-top: 20px;
                color: rgba(33, 37, 41, .4);
                transition: .5s all;
                cursor: pointer;
                h2 {
                    font-size: 13px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    max-width: 0px;
                    top: -3px;
                    height: 3px;
                    background-color: #1A1B1C;
                    transition: .2s all;
                }
                &:hover {
                    &::before {
                        max-width: 1000px;
                    }
                }
                &.active {
                    color: #212529;
                    &::before {
                        max-width: 1000px;
                    }
                }
            }
        }
        &-image {
            max-width: 19.999%;
            flex: 0 0 19.999%;
            margin-right: 30px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            @media(max-width:$sm-max) {
                max-width:100%;
                flex: 0 0 100%;
                img {
                    margin: 0 auto 30px auto;
                    display: block;
                    @media(max-width:$sm) {
                        margin: 0 auto 16px auto;
                    }
                }
            }
            img {
                border-radius: 10px;
            }
        }
        &-info {
            max-width: 74.999%;
            flex: 0 0 74.999%;
            @media(max-width:$sm-max) {
                max-width:100%;
                flex: 0 0 100%;
                display: flex;
                flex-wrap: wrap;
            }
            &-name {
                font-size: 35px;
                font-weight: 700;
                @media(max-width:$sm-max) {
                    width: 100%;
                    max-width: 100%;
                }
                @media(max-width:$sm) {
                    font-size: 15px;
                    text-align: center;
                    margin-bottom: 12px;
                    order: 1;
                }
            }
            &-years {
                padding-bottom: 12px;
                border-bottom: 1px solid rgba(112, 112, 112, .1);
                margin-bottom: 20px;
                @media(max-width:$sm-max) {
                    width: 100%;
                    max-width: 100%;
                }
                @media(max-width:$sm) {
                    order: 2;
                    margin: 0;
                    padding: 0;
                    border: none;
                    text-align: center;
                    width: 100%;
                }
                .practice {
                    font-size: 15px;
                    line-height: 23px;
                    font-weight: 700;
                }
            }
        }
        &-categories {
            @media(max-width:$sm) {
                order: 1;
                margin-bottom: 10px;
                width: 100%;
            }
            .single-article__person-category {
                @media(max-width:$sm) {
                    a:nth-of-type(1) {
                        display: inline-block;
                    }
                    a:nth-of-type(2) {
                        display: inline-block;
                    }
                    a:nth-of-type(3) {
                        display: none;
                    }
                    a:nth-of-type(4) {
                        display: none;
                    }
                }
            }
        }
    }
    &__place {
        background-color: #fff;
        border-radius: 20px;
        padding: 35px 32px 43px 32px;
        font-size: 15px;
        line-height: 22px;
        box-shadow: 0 0 99px rgba(0,0,0,.1);
        position: relative;
        z-index: 1;
        margin-bottom: 20px;
        @media(max-width:$sm-max) {
            padding: 15px;
        }
        &-el {
            margin-bottom: 51px;
        }
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 7px;
            border-bottom: 1px solid #E1E1E1;
            margin-bottom: 16px;
            &-title {
                font-size: 18px;
                font-weight: 700;
                .blue {
                    font-size: 11px;
                    line-height: 14px;
                    color: #35B7C1;
                    text-transform: uppercase;
                }
            }
            &-map {
                display: flex;
                &-image {
                    max-width: 30px;
                    flex: 0 0 30px;
                    margin-right: 12px;
                }
                img {
                    cursor: pointer;
                }
            }
        }
        &-address {
            display: flex;
            margin-bottom: 8px;
            &-image {
                max-width: 30px;
                flex: 0 0 30px;
                margin-right: 12px;
            }
        }
        &-phone {
            display: flex;
            margin-bottom: 8px;
            &-image {
                max-width: 30px;
                flex: 0 0 30px;
                margin-right: 12px;
            }
        }
        &-email {
            display: flex;
            margin-bottom: 8px;
            &-image {
                max-width: 30px;
                flex: 0 0 30px;
                margin-right: 12px;
            }
        }
        &-www {
            display: flex;
            margin-bottom: 8px;
            &-image {
                max-width: 30px;
                flex: 0 0 30px;
                margin-right: 12px;
            }
        }
    }
    &__reviews {
        background-color: #fff;
        position: relative;
        z-index: 1;
        border-radius: 20px;
        padding: 45px 45px 20px 45px;
        box-shadow: 0 0 99px rgba(0,0,0,.1);
        margin-bottom: 30px;
        @media(max-width:$sm-max) {
            padding: 15px;
        }
        &-top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 11px;
            border-bottom: 1px solid #E1E1E1;
            margin-bottom: 31px;
            &-title {
                font-size: 18px;
                font-weight: 700;
            }
            &-btn {
                background-color: #F16655;
                color: #fff;
                font-size: 13px;
                line-height: 21px;
                font-weight: 700;
                padding: 9px 10px;
                border-radius: 6px;
                cursor: pointer;
                img {
                    margin-right: 5px;
                }
            }
        }
        &-counter {
            display: flex;
            flex-wrap: wrap;
            font-size: 13px;
            font-weight: 700;
            padding-bottom: 20px;
            margin-bottom: 20px;
            border-bottom: 1px solid rgba(112, 112, 112, .1);
            &-all {
                margin-right: 50px;
                @media(max-width:$sm) {
                    width: 100%;
                }
            }
            &-positiv {
                margin-right: 50px;
                @media(max-width:$sm) {
                    width: 100%;
                }
            }
            &-neutral {
                margin-right: 50px;
                @media(max-width:$sm) {
                    width: 100%;
                }
            }
        }
        &-elements {
            &-see-more {
                font-size: 15px;
                font-weight: 700;
                background-color: rgba(221, 221, 221, .34);
                border-radius: 10px;
                padding: 28px 0;
                max-width: 334px;
                width: 100%;
                margin: 0 auto;
                text-align: center;
                text-transform: uppercase;
                cursor: pointer;
                &.hide {
                    display: none;
                }
            }
            .vote {
                &-hidden-wrapper {
                    max-height: 0px;
                    overflow: hidden;
                    transition: .5s all;
                    &.show {
                        max-height: 10000px;
                    }
                }
                &-el {
                    margin-bottom: 30px;
                    display: flex;
                    &__left {
                        margin-right: 16px;
                        &-image {
                            padding: 13px;
                            border: 1px solid #DDDDDD;
                            border-radius: 9px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                    &__right {
                        .name {
                            font-size: 16px;
                            font-weight: 700;
                            margin-right: 29px;
                        }
                        .date {
                            font-size: 13px;
                            color: #B7B7B7;
                        }
                        .text {
                            margin-top: 14px;
                            font-size: 15px;
                            line-height: 23px;
                        }
                    }
                }
            }
        }
        &.no-reviews {
            padding: 45px 45px 20px 45px;
            text-align: center;
            @media(max-width:$sm-max) {
                padding: 15px;
            }
            .single-therapist__reviews-top-title {
                border-bottom: 1px solid #E1E1E1;
                padding-bottom: 10px;
                margin-bottom: 28px;
                text-align: left;
            }
            .no-reviews__image {
                margin-bottom: 32px;
            }
            .no-reviews__title {
                margin-bottom: 29px;
                font-size: 19px;
                font-weight: 700;
            }
            .no-reviews__description {
                margin-bottom: 37px;
                font-size: 17px;
            }
            .no-reviews__btn {
                display: block;
                background: linear-gradient(135deg, rgba(241,102,85,1) 0%,rgba(251,54,54,1) 100%);
                max-width: 218px;
                padding: 18px 15px;
                color: #fff;
                font-size: 15px;
                line-height: 21px;
                font-weight: 700;
                border-radius: 10px;
                text-align: left;
                margin: 0 auto 15px auto;
                text-transform: uppercase;
                img {
                    margin-right: 15px;
                }
            }
        }
    }
    &__info {
        background-color: #fff;
        position: relative;
        z-index: 1;
        border-radius: 20px;
        padding: 45px 45px 20px 45px;
        box-shadow: 0 0 99px rgba(0,0,0,.1);
        margin-bottom: 30px;
        @media(max-width:$sm-max) {
            padding: 15px;
        }
        &-title {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 11px;
            margin-bottom: 20px;
            border-bottom: 1px solid #E1E1E1;
        }
    }
    &__experience {
        background-color: #fff;
        position: relative;
        z-index: 1;
        border-radius: 20px;
        padding: 45px 45px 20px 45px;
        box-shadow: 0 0 99px rgba(0,0,0,.1);
        margin-bottom: 30px;
        @media(max-width:$sm-max) {
            padding: 15px;
        }
        &-title {
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 11px;
            margin-bottom: 20px;
            border-bottom: 1px solid #E1E1E1;
        }
        &-el {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 20px;
            &-title {
                margin-bottom: 11px;
                text-transform: uppercase;
                &.another {
                    margin-top: 20px;
                }
            }
            &-description {
                font-weight: 400;
                font-size: 16px;
            }
        }
    }
    &__therapists {
        background-color: #fff;
        position: relative;
        z-index: 1;
        border-radius: 20px;
        padding: 45px 45px 20px 45px;
        box-shadow: 0 0 99px rgba(0,0,0,.1);
        margin-bottom: 30px;
        &-person {
            display: flex;
            margin-bottom: 42px;
            &-image {
                margin-right: 11px;
                max-width: 165px;
                width: 100%;
                img {
                    border-radius: 10px;
                }
            }
            &-text {
                &-name {
                    font-size: 18px;
                    font-weight: 700;
                    margin-bottom: 5px;
                }
                &-votes-link {
                    display: flex;
                    align-items: center;
                }
                &-votes {
                    font-size: 13px;
                    line-height: 23px;
                    opacity: 0.5;
                    margin-right: 11px;
                }
                &-link {
                    font-size: 11px;
                    line-height: 14px;
                    font-weight: 700;
                    letter-spacing: 1.2px;
                    color: #35B7C1;
                    text-transform: uppercase;
                    cursor: pointer;
                }
                &-category {
                    margin-bottom: 9px;
                }
            }
        }
    }
    &__box {
        transition: .5s all ease-in-out;
        max-height: 10000px;
        opacity: 1;
        &.hide {
            opacity: 0;
            max-height: 0px;
            overflow: hidden;
            &.show {
                opacity: 1;
                max-height: 10000px;
                overflow: inherit;
            }
        }
    }
    &__hidden-map {
        position: relative;
        z-index: 10000;
        &-container {
            position: fixed;
            background-color: rgba(26, 27, 28, .4);
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            transition: .5s opacity ease-in-out;
            opacity: 0;
            pointer-events: none;
            display: none;
            &.show {
                opacity: 1;
                pointer-events: auto;
                display: flex;
            }
            #gmap {
                width: 100%;
                height: 100%;
                border-radius: 20px;
            }
        }
        &-map {
            max-width: 80%;
            max-height: 80%;
            width: 100%;
            height: 100%;
            background-color: #fff;
            border-radius: 20px;
            position: relative;
        }
        &-close {
            position: absolute;
            right: 18px;
            top: 15px;
            cursor: pointer;
            z-index: 1;
            img {
                border-radius: 50%;
                box-shadow: 0 3px 20px rgba(0,0,0,.41);
            }
        }
    }
}
