.bg-blue {
    background-color: transparent;
    color: #1A1B1C;
    position: relative;
    padding-top: 15px;
    @media(min-width: $md) {
        padding-top: 0;
    }
    &--slider {
        padding: 60px 0 55px;
        color: $color-white;
        background-color: $color-blue-navy;
        .bg-blue {
            &__title {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                align-content: center;
                margin-bottom: 30px;
                &-text {
                    color: $color-white;
                    font-size: 35px;
                    font-weight: 400;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    height: 48px;
                    padding-top: 1px;
                }
                &-link {
                    font-size: 14px;
                    font-weight: 700;
                    height: 48px;
                    border-radius: 10px;
                    padding: 0 34px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid $color-white;
                    transition: background-color .2s ease-in-out, color .2s ease-in-out;
                    &:hover {
                        @media(min-width: $lg) {
                            background-color: $color-white;
                            color: $color-blue-navy;
                        }
                    }
                }
            }
        }
        .swiper-treatment-next, .swiper-treatment-prev {
            background-image: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: $color-blue;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: opacity .2s ease-in-out;
            img {
                width: 8px;
            }
            &.swiper-button-disabled {
                opacity: 0;
                pointer-events: none;
            }
        }
        .swiper-treatment-next {
            left: auto;
            right: -26px;
            justify-content: flex-start;
            padding-left: 12px;
            box-shadow: -15px 0 15px 15px rgba(0, 43, 68, 0.4);

        }
        .swiper-treatment-prev {
            left: -26px;
            right: auto;
            justify-content: flex-end;
            padding-right: 12px;
            box-shadow: 15px 0 15px 15px rgba(0, 43, 68, 0.4);
        }
    }
    &--problems-lv1 {
        padding-bottom: 140px;
    }
    &--problems {
        padding-bottom: 40px;
        @media(min-width: $lg) {
            padding-bottom: 80px;
        }
    }
    .breadcrumb {
        margin-bottom: 0px;
        color: #1A1B1C;
    }
    .health-menu {
        padding-top: 0;
        padding-bottom: 165px;
    }
    &__bottom {
        margin-top: -50px;
    }
    &__return {
        display: block;
        font-weight: 700;
        color: $color-black;
        position: relative;
        z-index: 3;
        text-transform: uppercase;
        font-size: 13px;
        margin-bottom: 8px;
        @media(min-width: $sm) {
            margin-bottom: 17px;
            font-size: 19px;
        }
        &:hover {
            color: $color-black;
            @media(min-width: $lg) {
                color: $color-black;
            }
            img {
                @media(min-width: $lg) {
                    margin-left: 15px;
                    margin-right: 10px;
                }

            }
        }
        img,svg {
            transform-origin: 50% 50%;
            transform: rotate(180deg);
            margin-right: 25px;
            transition: margin .2s ease-in-out;
            @media(max-width: $xs-max) {
                max-width: 30px;
                margin-right: 10px;
            }
        }
        svg {
            path {
                stroke: $color-black;
            }
        }
    }
}
