* {
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
}

html, body {
    font-size: 16px;
    font-family: $font-main;
    line-height: 1.533333;
}

a {
    color: inherit;
    &:hover {
        text-decoration: none;
        color: inherit;
    }
}
