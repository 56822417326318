.footer {
    background-color: $color-black;
    color: $color-white;
    padding: 70px 0 50px;
    &__menu {
        width: 100%;
        @media(min-width: $xl) {
            max-width: calc(100% - 465px);
        }
        & > ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 750px;
            & > li {
                margin-bottom: 30px;
                padding-right: 15px;
                & > span, & > a {
                    display: block;
                    font-size: 14px;
                    font-weight: 700;
                    text-transform: uppercase;
                    letter-spacing: 0.05em;
                    margin-bottom: 15px;
                }
                & > ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    & > li {
                        font-size: 15px;
                        opacity: 0.5;
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }
    &__info {
        img {
            max-width: 190px;
            margin-bottom: 15px;
        }
        &-text {
            font-size: 15px;
            opacity: 0.5;
        }
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid rgba(255,255,255,0.2);
        margin-bottom: 38px;
        align-items: flex-start;
        align-content: flex-start;
    }
    &__logo {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 30px;
        @media(min-width: $lg) {
            margin-bottom: 0;
        }
    }
    &__social {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        position: relative;
        width: 100%;
        margin-top: 20px;
        @media(min-width: $sm) {
            padding-left: 25px;
            margin-left: 25px;
            width: auto;
            margin-top: 0;
        }
        &:after {
            @media(min-width: $sm) {
                content: "";
                position: absolute;
                width: 1px;
                height: 31px;
                background-color: $color-white;
                opacity: 0.2;
                display: block;
                top: 0;
                left: 0;
                right: auto;
                bottom: 0;
                margin: auto;
            }
        }
        a {
            opacity: 0.5;
        }
    }
    &__newsletter {
        width: 100%;
        max-width: 465px;
        margin-bottom: 30px;
        &-title {
            font-size: 14px;
            font-weight: 400;
            color: rgba(255,255,255,0.5);
            margin-bottom: 30px;
            @media(min-width: $sm) {
                margin-bottom: 10px;
            }
            span {
                color: $color-white;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                margin-bottom: 10px;
                margin-right: 25px;
                display: block;
                @media(min-width: $sm) {
                    display: inline-block;
                }
            }
        }
        &-input {
            height: 64px;
            position: relative;
            left: -2px;
            top: -2px;
            color: $color-white;
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 15px;
            padding-left: 35px;
            width: 100%;
            @media(min-width: $sm) {
                width: calc(100% - 141px);
            }
            &::placeholder {
                color: $color-white;
            }
        }
        &-submit {
            height: 60px;
            background-color: transparent;
            top: 0;
            right: 0;
            border: none;
            outline: none;
            box-shadow: none;
            color: $color-white;
            text-transform: uppercase;
            font-size: 13px;
            font-weight: 700;
            letter-spacing: 0.1em;
            cursor: pointer;
            width: 100%;
            border: 1px solid rgba(255,255,255,0.2);
            border-radius: 23px;
            @media(min-width: $sm) {
                border-radius: 0;
                border: none;
                border-left: 1px solid rgba(255,255,255,0.2);
                position: absolute;
                width: 145px;
            }
        }
    }
    &__input-wrap {
        @media(max-width: $xs-max) {
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 23px;
            border: 1px solid rgba(255,255,255,0.2);
            height: 60px;
            margin-bottom: 20px;
        }
    }
    &__form-wrap {
        @media(min-width: $sm) {
            width: 100%;
            position: relative;
            overflow: hidden;
            border-radius: 23px;
            border: 1px solid rgba(255,255,255,0.2);
            height: 60px;
        }
    }
    &__bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
    }
    &__publisher {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        @media(min-width: $lg) {
            max-width: 465px;
        }
        &-title {
            display: block;
            font-size: 14px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            margin-bottom: 15px;
            width: 100%;
        }
        &-left {
            margin-right: 100px;
        }
        &-left, &-right {
            font-size: 15px;
            opacity: 0.5;
            margin-bottom: 5px;
        }
    }
}
