.sticky-products {
    position: fixed;
    bottom: -210px;
    left: 0;
    width: 100%;
    background-color: #fff;
    display: flex;
    box-shadow: 0 -16px 55px rgba(0,0,0,.16);
    z-index: 10;
    transition: .5s all;
    opacity: 1;
    &.show {
        bottom: 0;
    }
    &.closed {
        opacity: 0;
    }
    @media(max-width: 460px) {
        flex-wrap: wrap;
    }
    &__close {
        position: absolute;
        right: 0;
        bottom: 100%;
        padding: 15px;
        background-color: #fff;
        cursor: pointer;
        border-top-left-radius: 7px;
    }
    &__recommend {
        max-width: 255px;
        width: 100%;
        background: linear-gradient(to bottom, #3fb693 0%,#35b7c1 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        line-height: 30px;
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        @media(max-width: 460px) {
            max-width: 100%;
            padding: 15px 0;
        }
    }
    &__slider {
        padding: 10px 15px;
        position: relative;
        @media(max-width: 460px) {
            max-width: 100%;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            max-width: 255px;
            width: 100%;
            height: 100%;
            background: linear-gradient(to left, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
            z-index: 1;
            pointer-events: none;
        }
        .swiper-button-next, .swiper-button-prev {
            position: absolute;
            top: 0;
            bottom: 0;
            width: 80px;
            height: 80px;
            background-color: #fff;
            box-shadow: 0 3px 33px rgba(0,0,0,0.16);
            background-image: none;
            border-radius: 50%;
            margin: auto;
            transition: opacity .2s ease-in-out;
            z-index: 2;
            display: flex;
            align-items: center;
            &.swiper-button-disabled {
                opacity: 0;
            }
            img {
                position: absolute;
                display: block;
                width: 20px;
                height: 20px;
            }
        }
        .swiper-button-next {
            right: -40px;
            justify-content: flex-start;
            img {
                margin-left: 12px;
            }
        }
        .swiper-button-prev {
            left: -40px;
            justify-content: flex-end;
            img {
                margin-right: 12px;
            }
        }
        &-item {
            display: flex;
            align-items: center;
            padding: 0 15px;
            height: 100%;
            &-image {
                max-width: 90px;
                width: 100%;
            }
            &-text {
                &-category {
                    font-size: 11px;
                    line-height: 15px;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: #FB3636;
                }
                &-title {
                    font-size: 15px;
                    line-height: 21px;
                    font-weight: 700;
                }
            }
        }
    }
}

#pushpushgo-container {
    .ppg__fab {
        transition: .5s all;
    }
}



// Company product - custom template

.sticky-product {
    background-color: #E3A61A;
    position: fixed;
    width: 100%;
    bottom: -300px;
    z-index: 300;
    transition: .3s all;
    &.show {
        bottom: 0;
    }
    &__close {
        width: 45px;
        height: 45px;
        right: 0;
        top: -45px;
        position: absolute;
        background-color: #fff;
        border-top-left-radius: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        padding: 0 52px;
        @media(max-width: 1550px) {
            padding: 0 15px;
        }
        @media(max-width: 1250px) {
            flex-wrap: wrap;
        }
    }
    &__logo {
        margin-right: 43px;
        @media(max-width: 1250px) {
            max-width: 50%;
            flex: 0 0 50%;
            margin: 0 0 10px 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        @media(max-width: 575px) {
            flex-wrap: wrap;
            margin-bottom: 0px;
            max-width: calc(100% - 90px);
            flex: 0 0 calc(100% - 90px);
            img {
                max-width: 110px;
                margin-bottom: 10px;
            }
        }
        &-text {
            font-size: 12px;
            line-height: 16px;
            font-weight: 700;
            text-align: center;
            display: none;
            width: 100%;
            @media(max-width: 575px) {
                display: block;
            }
        }
    }
    &__images {
        padding: 8px 36px 8px 43px;
        background-color: #FAEED4;
        margin-right: 37px;
        display: flex;
        @media(max-width: 1250px) {
            max-width: 50%;
            flex: 0 0 50%;
            margin: 0 -15px 10px 0;
            display: flex;
            justify-content: center;
            margin-left: 15px;
        }
        @media(max-width: 575px) {
            margin-bottom: 0px;
            padding: 11px;
            max-width: 90px;
            flex: 0 0 90px;
        }
        &-second {
            @media(max-width: 575px) {
                display: none;
            }
        }
    }
    &__title {
        font-size: 28px;
        line-height: 53px;
        font-weight: 700;
        margin-right: 33px;
        @media(max-width: 1460px) {
            font-size: 22px;
            line-height: 32px;
        }
        @media(max-width: 1250px) {
            margin-bottom: 10px;
        }
        @media(max-width: 1024px) {
            max-width: 100%;
            flex: 0 0 100%;
        }
        @media(max-width: 575px) {
            display: none;
        }
    }
    &__order {
        font-size: 20px;
        line-height: 20px;
        border-left: 1px solid rgba(0,0,0,.26);
        padding-left: 33px;
        margin-right: 25px;
        height: 68px;
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        align-items: center;
        @media(max-width: 1460px) {
            font-size: 18px;
            line-height: 18px;
        }
        @media(max-width: 1360px) {
            padding-left: 15px;
        }
        @media(max-width: 1250px) {
            margin-bottom: 10px;
        }
        @media(max-width: 1024px) {
            padding-left: 0px;
            border-left: none;
            height: auto;
        }
        @media(max-width: 575px) {
            display: none;
        }
        &-title {
            width: 100%;
        }
        &-www {
            font-weight: 700;
        }
    }
    &__phone {
        padding-left: 16px;
        border-left: 1px solid rgba(0,0,0,.26);
        height: 68px;
        display: flex;
        align-items: center;
        align-content: center;
        white-space: nowrap;
        font-size: 20px;
        line-height: 20px;
        font-weight: 700;
        @media(max-width: 1460px) {
            font-size: 18px;
            line-height: 18px;
        }
        @media(max-width: 1360px) {
            padding-left: 15px;
        }
        @media(max-width: 1250px) {
            margin-bottom: 10px;
        }
        @media(max-width: 1024px) {
            height: auto;
        }
        @media(max-width: 575px) {
            display: none;
        }
        img {
            @media(max-width: 500px) {
                max-width: 20px;
            }
        }
    }
}