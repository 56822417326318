.archive-papers {
    background-color: $color-blue-navy;
    color: $color-white;
    padding: 36px 0 80px;
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 16px;
        border-bottom: 3px solid $color-red;
        margin-bottom: 30px;
        &-text {
            font-size: 45px;
            font-weight: 700;
        }
        &-btn {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            border-radius: 15px;
            height: 60px;
            background-color: $color-blue;
            box-shadow: 0 25px 50px rgba(0,0,0,0.45);
            width: 100%;
            max-width: 220px;
            font-size: 15px;
            font-weight: 700;
        }
    }
    &__item {
        padding: 0 15px;
        text-align: center;
        margin-bottom: 30px;
        width: 100%;
        @media(min-width: 450px) {
            width: 50%;
        }
        @media(min-width: $md) {
            width: calc(100% / 3);
        }
        @media(min-width: $xl) {
            width: calc(100% / 6);
        }
        &-link {
            display: block;
        }
        &-image {
            margin-bottom: 39px;
        }
        &-text {
            font-size: 18px;
            font-weight: 700;
        }
    }
}
