.newsletter-new {
    background-color: var(--ColorBg);
    color: var(--colorFont);
    .newsletter {
        &__form {
            &.disabled {
                .newsletter__error {
                    display: block;
                }
            }
        }
        &__error {
            font-size: 18px;
            color: #ff0000;
            font-weight: 600;
            text-align: center;
            display: none;
            margin-bottom: 30px;
            margin-top: -10px;
        }
        &__container {
            max-width: 1200px;
            padding: 0 15px;
            margin: 0 auto;
            width: 100%;
        }
        &__left {
            width: 100%;
            padding: 0 15px;
            order: 2;
            max-width: 600px;
            margin: 0 auto;
            @media(min-width: 1025px) {
                order: 1;
                margin: 0;
            }
        }
        &__right {
            width: 100%;
            padding: 0 15px;
            order: 1;
            margin-bottom: 30px;
            @media(min-width: 1025px) {
                max-width: calc(100% - 600px);
                order: 2;
                margin-bottom: 0;
            }
            img {
                margin: 0 auto;
                display: block;
            }
        }
        &__gratis {
            font-size: 18px;
            line-height: 25px;
            margin-bottom: 40px;
            text-align: center;
            @media(min-width: 1025px) {
                text-align: left;
            }
        }
        &__title {
            font-size: 50px;
            margin: 0 0 20px;
            text-align: center;
            @media(min-width: 1025px) {
                text-align: left;
            }
        }
        &__input {
            display: block;
            margin: 0 0 40px;
            width: 100%;
            -webkit-box-shadow: 0 0 99px 0 rgba(0,0,0,0.16);
            box-shadow: 0 0 99px 0 rgba(0,0,0,0.16);
            border: 0;
            outline: 0;
            color: #082134;
            text-align: center;
            font-family: "Objectivity",sans-serif;
            font-size: 20px;
            line-height: 30px;
            font-weight: bold;
            color: #fff;
            padding: 25px;
            background-color: #335669;
            border-radius: 28px;
            &::placeholder {
                font-weight: 400;
                opacity: 1;
                font-size: 20px;
                color: #ffffff;
            }
            &:focus, &:active {
                &::placeholder {
                    opacity: 0;
                }
            }
        }
        &__submit {
            height: 80px;
            width: 100%;
            height: 80px;
            margin: 0 0 30px;
            background: -moz- oldlinear-gradient(315deg,#35b7c1 0,#3fb693 100%);
            background: linear-gradient(135deg,#35b7c1 0,#3fb693 100%);
            font-size: 17px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #fff;
            line-height: 21px;
            font-weight: 700;
            border: 0;
            cursor: pointer;
            border-radius: 28px;
        }
        &__gift {
            position: relative;
            padding-left: 50px;
            margin-bottom: 40px;
            font-size: 11px;
            line-height: 17px;
            opacity: 0.9;
            &-checkbox {
                position: absolute;
                top: 6px;
                left: 0;
                width: 36px;
                height: 36px;
                opacity: 0;
                &:checked ~ .newsletter__gift-label:after {
                    opacity: 1;
                }
            }
            &-label {
                margin: 0;
                &:after {
                    content: "";
                    position: absolute;
                    top: 6px;
                    left: 0;
                    background-image: url("/templates/main/images/svg/check-teal.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 60%;
                    opacity: 0;
                    width: 36px;
                    height: 36px;
                    transition: opacity .2s ease-in-out;
                }
                &:before {
                    content: '';
                    width: 36px;
                    height: 36px;
                    position: absolute;
                    top: 6px;
                    left: 0;
                    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
                    background: transparent;
                    border: 2px solid #DDDDDD;
                    border-radius: 8px;
                }
            }
        }
    }
    .avt-info {
        position: relative;
        top: 0;
        left: 0;
        margin-bottom: 24px;
        font-size: 11px;
        line-height: 17px;
        opacity: 0.9;
        #button-text {
            font-weight: 700;
            cursor: pointer;
            text-transform: uppercase;
        }
        #full-text {
            display: none;
            &.open {
                display: inline;
            }
        }
        .intro-text {
            display: inline;
        }
    }
}
