.our-guide--slider {
    .our-guide__module-title {
        display: flex;
        flex-wrap: wrap;
        border-bottom: none;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        .our-guide__module-title-link {
            font-size: 35px;
            letter-spacing: 0.04em;
            padding-top: 2px;
            font-weight: 500;
            text-transform: uppercase;
            padding-top: 5px;
        }
        .our-guide__module-title-btn {
            width: 100%;
            max-width: 175px;
            height: 50px;
            border: 2px solid $color-font-main;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            line-height: normal;
            padding-top: 2px;
            transition: color .2s ease-in-out, background-color .2s ease-in-out;
            &:hover {
                @media(min-width: $lg) {
                    background-color: $color-font-main;
                    color: $color-white;
                }
            }
        }
    }
}

.swiper-our-guide {
    padding-left: calc(((100% - 1440px) / 2));
    .swiper-slide {
        padding: 0 15px;
    }
}
