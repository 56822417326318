.vaccine {
    background-color: $color-blue-dark;
    color: $color-white;
    padding: 45px 15px 270px;
    [data-step] {
        display: none;
    }
    &[data-step="1"] {
        [data-step="1"] {
            display: block;
        }
    }
    &[data-step="2"] {
        [data-step="2"] {
            display: block;
        }
    }
    &[data-magazine] {
        [data-magazine] {
            display: none;
            &.vaccine__select-option {
                display: block;
            }
        }
    }
    &[data-magazine="paper"] {
        [data-magazine="paper"] {
            display: block;
            &:before {
                opacity: 1;
            }
        }
    }
    &[data-magazine="electronic"] {
        [data-magazine="electronic"] {
            display: block;
            &:before {
                opacity: 1;
            }
        }
    }
    &[data-magazine="electronic"], &[data-magazine="paper"] {
        .vaccine__select-btn {
            opacity: 1;
            cursor: pointer;
            pointer-events: all;
        }
    }
    &__step {
        &-header {
            line-height: normal;
            color: $color-white;
            font-weight: 700;
            margin-bottom: 40px;
            text-align: center;
            font-size: 34px;
            @media(min-width: $sm) {
                font-size: 54px;
            }
            span {
                font-size: 20px;
                font-weight: 400;
                color: rgba(255,255,255,0.7);
            }
        }
    }
    &__header {
        line-height: normal;
        color: $color-white;
        font-weight: 700;
        margin-bottom: 40px;
        font-size: 34px;
        @media(min-width: $sm) {
            font-size: 54px;
        }
    }
    &__introtext {
        max-width: 930px;
        margin: 0 auto;
        color: rgba(255,255,255,0.7);
        position: relative;
        font-size: 16px;
        line-height: 26px;
        @media(min-width: $sm) {
            font-size: 17px;
            line-height: 30px;
        }
        &.open {
            &:after {
                opacity: 0;
            }
            .vaccine__introtext-hidden {
                display: block;
            }
            .close {
                display: none;
            }
            .open {
                display: block;
            }
        }
        &:after {
            content: "";
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            height: 55%;
            opacity: 1;
            transition: opacity .4s ease-in-out;
            background: linear-gradient(0deg, rgba(0,43,68,1) 0%, rgba(0,43,68,0) 100%); /* w3c */
        }
        strong {
            font-weight: 700;
            display: block;
            margin-bottom: 40px;
            color: $color-white;
            font-size: 18px;
            line-height: 28px;
            @media(min-width: $sm) {
                font-size: 21px;
                line-height: 33px;
            }
            span {
                color: $color-red;
                text-decoration: underline;
            }
        }
        &-visible {
            position: relative;

        }
        &-hidden {
            display: none;
        }
        &-btn {
            width: 240px;
            max-width: 100%;
            min-height: 54px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            border-radius: 10px;
            background-color: #0D364E;
            color: $color-white;
            margin: 0 auto;
            padding: 0 15px;
            box-shadow: 0 25px 99px rgba(0,0,0,0.5);
            position: relative;
            z-index: 3;
            user-select: none;
            cursor: pointer;
            font-size: 17px;
            font-weight: 700;
            margin-top: 26px;
            margin-bottom: 80px;
            .close {
                display: block;
            }
            .open {
                display: none;
            }
            * {
                pointer-events: none;
                user-select: none;
            }
        }
    }
    &__select {
        margin: 0 auto;
        display: flex;
        align-items: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        max-width: 720px;
        @media(min-width: 1145px) {
            max-width: 1115px;
            flex-wrap: nowrap;
        }
        &-text {
            font-weight: 700;
            margin-bottom: 40px;
            position: relative;
            font-size: 18px;
            line-height: 28px;
            @media(min-width: $sm) {
                font-size: 26px;
                line-height: 34px;
            }
            &:before {
                content: "";
                position: absolute;
                width: 39px;
                height: 39px;
                border: 2px solid rgba(255,255,255,0.5);
                display: block;
                left: -63px;
                border-radius: 3px;
                background-image: url("/templates/main/images/svg/check-teal.svg");
                background-size: 25px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        &-option {
            font-size: 19px;
            font-weight: 700;
            position: relative;
            cursor: pointer;
            &:before {
                content: "";
                position: absolute;
                width: 14px;
                height: 14px;
                border-radius: 50%;
                top: 8px;
                left: -42px;
                background: #3FB693;
                opacity: 0;
                transition: opacity .2s ease-in-out;
            }
            &:after {
                content: "";
                position: absolute;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid rgba(255,255,255,0.5);
                top: 0;
                left: -50px;
            }
            span {
                display: block;
                font-size: 12px;
                color: rgba(255,255,255,0.5);
                font-weight: 400;
                margin-bottom: 20px;
                pointer-events: none;
            }
        }
        &-left {
            flex-grow: 2;
            border-radius: 15px;
            border: 2px solid rgba(255,255,255,0.2);
            order: 2;
            padding: 15px;
            @media(min-width: $sm) {
                padding: 35px 45px 35px;
            }
            @media(min-width: 1145px) {
                order: 1;
            }
        }
        &-right {
            flex-grow: 1;
            width: 100%;
            order: 1;
            margin-bottom: 45px;
            @media(min-width: 1145px) {
                padding-left: 65px;
                max-width: 395px;
                order: 2;
                margin-bottom: 0;
            }
            img {
                max-width: 100%;
                height: auto;
                margin: 0 auto;
                display: block;
            }
        }
        &-top {
            padding-left: 65px;
        }
        &-btn {
            width: 100%;
            max-width: 310px;
            min-height: 80px;
            padding: 15px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            color: $color-white;
            font-size: 20px;
            font-weight: 700;
            letter-spacing: 0.05em;
            border-radius: 20px;
            margin: 40px auto 0;
            position: relative;
            overflow: hidden;
            background: linear-gradient(135deg,#f16655 0,#fb3636 100%);
            opacity: 0.22;
            cursor: normal;
            pointer-events: none;
            transition: opacity .2s ease-in-out;
            span {
                position: relative;
                z-index: 3;
                pointer-events: none;
                user-select: none;
            }
        }
    }
    &__form {
        &-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            @media(min-width: $md) {
                flex-wrap: nowrap;
                justify-content: flex-start;
            }
        }
        &-left {
            display: flex;
            flex-wrap: wrap;
            flex-grow: 1;
            margin: 0 -10px;
            width: calc(100% + 20px);
            max-width: 395px;
            order: 2;
            @media(min-width: $md) {
                order: 1;
            }
        }
        &-agreement {
            width: 100%;
            font-size: 11px;
            line-height: 17px;
            font-weight: 400;
            color: rgba(255,255,255,0.5);
            position: relative;
            margin-top: 34px;
            margin-bottom: 54px;

            .vaccine__checkbox {
                position: absolute;
                top: 0;
                left: 0;
                width: 30px;
                height: 30px;
                opacity: 0;
                z-index: 9;
                &:checked ~ .vaccine__custom-checkbox {
                    background-image: url("/templates/main/images/svg/check-teal.svg");
                }
                &-wrap {
                    &.validate-error {
                        .vaccine__custom-checkbox {
                            border-color: $color-red;
                        }
                    }
                }
            }
            .vaccine__checkbox-label {
                padding-left: 50px;
                user-select: none;
            }
            .vaccine__custom-checkbox {
                width: 30px;
                height: 30px;
                border-radius: 8px;
                border: 2px solid #DDDDDD;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                background-size: 20px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }
        &-image {
            width: 100%;
            order: 1;
            margin-bottom: 45px;
            @media(min-width: $md) {
                padding-left: 70px;
                flex-grow: 2;
                order: 2;
                width: auto;
                margin-bottom: 0;
            }
            img {
                height: auto;
                display: block;
                margin: 0 auto;
                max-width: 60%;
                @media(min-width: $md) {
                    max-width: 100%;
                }
            }
        }
    }
    &__input-wrap {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 70px;
        margin: 0 10px 20px;
        background-color: rgba(41,72,104,0.3);
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        border-radius: 10px;
        .star {
            display: none;
        }
        &.focus,
        &.filled {
            .control-label {
                label {
                    transform: translate3d(0,-8px,0) scale(0.66);
                }
            }
        }
        &.validate-error {
            background-color: rgba(255,72,104,0.3);
        }
        .control-group {
            width: 100%;
            height: 100%;
            .control-label {
                position: absolute;
                top: 0;
                left: 25px;
                bottom: 0;
                right: 0;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                font-family: "Objectivity", sans-serif;
                label {
                    transition: transform .2s ease-in-out;
                    transform-origin: 0 0;
                    margin: 0;
                    font-size: 18px;
                    font-weight: 700;
                    display: flex;
                    flex-wrap: wrap;
                    align-content: center;
                    align-items: center;
                    &, &:focus &:active {
                        font-family: "Objectivity", sans-serif;
                    }
                }
            }
            .controls {
                width: 100%;
                height: 100%;
            }
        }
        &.lg {
            max-width: calc(100% - 20px);
            @media(min-width: 425px) {

            }
        }
        &.md {
            max-width: calc(100% - 20px);
            @media(min-width: 425px) {
                max-width: calc(65% - 20px);
            }
        }
        &.sm {
            max-width: calc(100% - 20px);
            @media(min-width: 425px) {
                max-width: calc(35% - 20px);
            }
        }
        input {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 70px;
            border: none;
            background: transparent;
            color: $color-white;
            font-size: 18px;
            font-weight: 700;
            padding-left: 25px;
            padding-top: 20px;
            font-family: "Objectivity",sans-serif;
            border: none;
            outline: none;
            box-shadow: none;
            &::placeholder {
                font-size: 0;
                opacity: 0;
            }
        }
    }

    &__submit {
        width: 100%;
        max-width: 510px;
        margin: 0 auto;
        background: linear-gradient(135deg,#f16655 0,#fb3636 100%);
        height: 104px;
        border-radius: 20px;
        color: $color-white;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.05em;
        border: none;
        display: block;
        cursor: pointer;
        &--return {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            margin-top: 20px;
            text-transform: uppercase;
            background: none;
        }
    }
    &[data-magazine="paper"] {
        .vaccine__step-header {
            margin-bottom: 60px;
            span {
                width: 100%;
                display: block;
                margin: 43px auto 60px;
                text-align: center;
            }
        }
        .vaccine__form-wrap,
        .vaccine__form-agreement {
            max-width: 930px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &[data-magazine="electronic"] {
        .vaccine__step-header {
            margin-bottom: 107px;
        }
        .vaccine__form-wrap {
            display: flex;
            align-items: center;
            align-content: center;
        }
        .vaccine__form-wrap,
        .vaccine__form-agreement {
            max-width: 820px;
            margin-left: auto;
            margin-right: auto;
        }
        .vaccine__form-image {
            max-width: 424px;
            padding-left: 41px;
        }
        .vaccine__input-wrap {
            display: none;
            &[data-name="form[imie]"],
            &[data-name="form[nazwisko]"],
            &[data-name="form[email]"] {
                display: block;
            }
        }
    }
}
