.fb-popup {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 999999;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
    overflow: auto;
    background-color: $color-blue-navy;
    @media(min-width: $sm) {
        background-color: rgba(0,0,0,0.4);
        padding: 15px;
    }
    &.open {
        pointer-events: all;
        opacity: 1;
    }
    &__top {
        font-size: 28px;
        font-weight: 700;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        align-content: center;
        justify-content: center;
        margin-bottom: 32px;
        img {
            @media(min-width: $sm) {
                margin-left: 22px;
            }
        }
        &-text {
            width: 100%;
            @media(min-width: $sm) {
                width: auto;
            }
        }
    }
    &__wrapper {
        box-shadow: 0 0 99px rgba(0,0,0,0.3);
        text-align: center;
        color: $color-white;
        max-width: 1024px;
        width: 100%;
        background-color: $color-blue-navy;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 30px 15px;
        height: 100%;
        @media(min-width: $sm) {
            height: auto;
            border-radius: 25px;
            padding: 50px 15px 116px;
        }
        &-content {
            position: relative;
            z-index: 3;
            width: 100%;
        }
    }
    &__banner {
        width: 100%;
        max-width: 500px;
        box-shadow: 0 10px 99px rgba(0,0,0,0.44);
        margin: 0 auto 32px;
    }
    &__text {
        width: 100%;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 37px;
        a {
            font-weight: 700;
        }
    }
    &__close {
        position: absolute;
        top: 32px;
        right: 32px;
        width: 31px;
        height: 31px;
        cursor: pointer;
        span {
            transform-origin: 50% 50%;
            width: 100%;
            height: 2px;
            background-color: $color-white;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            transform: rotate(45deg);
            &:first-child {
                transform: rotate(-45deg);
            }
        }
    }
    &__like {
        width: 192px;
        position: absolute;
        z-index: 0;
        left: 50px;
        top: 32px;
        display: none;
        @media(min-width: $md) {
            display: block;
        }
        @media(min-width: $lg) {
            left: 100px;
            top: 64px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__plane {
        width: 192px;
        position: absolute;
        z-index: 0;
        bottom: 150px;
        right: 20px;
        display: none;
        @media(min-width: $md) {
            display: block;
        }
        @media(min-width: $lg) {
            right: 37px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__form {
        width: 100%;
        &-input {
            color: $color-white;
            input {
                width: 100%;
                max-width: 430px;
                border: 2px solid $color-blue;
                border-radius: 18px;
                outline: none;
                background-color: $color-blue-navy;
                height: 85px;
                display: block;
                margin: 0 auto;
                text-align: center;
                color: $color-white;
                &[type="text"] {
                    font-size: 20px;
                    margin-bottom: 12px;
                    &::placeholder {
                        color: $color-white;
                    }
                }
                &[type="submit"] {
                    font-size: 17px;
                    font-weight: 700;
                    letter-spacing: 0.1em;
                    text-transform: uppercase;
                    background: linear-gradient(to left,#3fb693 0,#35b7c1 100%);
                    margin-bottom: 30px;
                    @media(min-width: $sm) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    &--red {
        .fb-popup {
            &__wrapper {
                background: linear-gradient(315deg, rgba(251,54,54,1) 0%, rgba(26,27,28,1) 100%);
                max-width: 740px;
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-image: url("/templates/main/images/newsletter/newsletter-bg.png");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                }
            }
            &__logo {
                margin-bottom: 56px;
            }
            &__btn {
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0.05em;
                text-transform: uppercase;
                color: $color-font-main;
                display: flex;
                flex-wrap: wrap;
                min-height: 88px;
                background-color: $color-white;
                justify-content: center;
                align-items: center;
                align-content: center;
                box-shadow: 0 20px 30px rgba(0,0,0,0.16);
                border-radius: 21px;
                max-width: 390px;
                margin: 0 auto;
                padding: 0 10px;
            }
            &__close {
                top: 20px;
                right: 25px;
            }
            &__text {
                font-size: 18px;
                font-weight: 500;
                line-height: 30px;
                margin-bottom: 30px;
                span {
                    text-decoration: underline;
                    vertical-align: middle;
                }
                strong {
                    font-size: 23px;
                    font-weight: 700;
                    text-transform: uppercase;
                    vertical-align: middle;
                    display: inline-block;
                    padding-top: 4px;
                }
            }
        }
    }
}
.c-banner__img {
    position: absolute;
    width: 627px;
    margin: 0;
    bottom: 16px;
    z-index: 0;
    transform: scale(0.5);
    transform-origin: 0% 100%;
    left: 25px;
    display: none;
    @media(min-width: $md) {
        display: block;
    }
    @media(min-width: $lg) {
        left: 54px;
    }
}
.perspective-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .perspective {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        img {
            position: absolute;
            top: -17%;
            left: 13.8%;
            transform-origin: 50% 50%;
            width: auto;
            height: 100%;
            transform: perspective(726px) rotateX(40.5deg) rotateY(14.5deg) rotateZ(-24.4deg);
        }
    }
}

.perspective-back {
    width: 100%;
    position: relative;
}
