.avt-container-1170 {
    width: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
}

.avt-container-1440 {
    width: 100%;
    max-width: 1440px;
    padding: 0 15px;
    margin: 0 auto;
}
.main {
    .row--370 {
        .hp-left-column.p-col.col-right {
            @media(max-width: 379.98px) {
                padding: 0;
            }
        }
    }
}
.avt-mb-3 {
    margin-bottom: 30px;
}
.row--300 {
    .p-col {
        padding: 0 15px;
    }
    & > .col-left {
        width: 100%;
        @media(min-width: $lg) {
            max-width: calc(100% - 300px);
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: calc(100% - 330px);
            }
        }
    }
    & > .col-right {
        width: 100%;
        @media(min-width: $lg) {
            max-width: 300px;
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: 330px;
            }
        }
    }
}

.row--370 {
    .p-col {
        padding: 0 15px;
    }
    & > .col-left {
        width: 100%;
        @media(min-width: $lg) {
            max-width: calc(100% - 370px);
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: calc(100% - 400px);
            }
        }
    }
    & > .col-right {
        width: 100%;
        @media(min-width: $lg) {
            max-width: 370px;
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: 400px;
            }
        }
    }
}

.row--480 {
    .p-col {
        padding: 0 15px;
    }
    & > .col-left {
        width: 100%;
        @media(min-width: $lg) {
            max-width: calc(100% - 450px);
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: calc(100% - 480px);
            }
        }
    }
    & > .col-right {
        width: 100%;
        @media(min-width: $lg) {
            max-width: 450px;
        }
        &.p-col {
            padding: 0 15px;
            @media(min-width: $lg) {
                max-width: 480px;
            }
        }
    }
}

.top-blue {
    position: relative;
    padding-top: 20px;
    @media(min-width: $md) {
        padding-top: 0;
    }
    &:after {
        content: "";
        position: absolute;
        z-index: -1;
        background-color: $color-blue-navy;
        top:0;
        left: 0;
        width: 100%;
        height: 60px;
        @media(min-width: $md) {
            height: 40px;
        }
    }
}
