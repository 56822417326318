@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-Bold.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-Bold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Bold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-Bold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-Bold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Bold.svg#Objectivity-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-Medium.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-Medium.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Medium.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-Medium.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-Medium.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Medium.svg#Objectivity-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-Thin.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-Thin.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Thin.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-Thin.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-Thin.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Thin.svg#Objectivity-Thin') format('svg');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-Regular.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-Regular.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Regular.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-Regular.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-Regular.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Regular.svg#Objectivity-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-Black.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-Black.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Black.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-Black.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-Black.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Black.svg#Objectivity-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-Light.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-Light.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Light.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-Light.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-Light.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-Light.svg#Objectivity-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Objectivity';
    src: url('https://cdn.avt.pl/fonts/Objectivity-ExtraBold.eot');
    src: url('https://cdn.avt.pl/fonts/Objectivity-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('https://cdn.avt.pl/fonts/Objectivity-ExtraBold.woff2') format('woff2'),
        url('https://cdn.avt.pl/fonts/Objectivity-ExtraBold.woff') format('woff'),
        url('https://cdn.avt.pl/fonts/Objectivity-ExtraBold.ttf') format('truetype'),
        url('https://cdn.avt.pl/fonts/Objectivity-ExtraBold.svg#Objectivity-ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
