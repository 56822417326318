[data-dark-mode="false"] {
    .select-menu {

    }
}
[data-dark-mode="true"] {
    .select-menu {
        &.open {
            .select-menu__selected,
            .select-menu__list,
            .select-menu__list-wrap {
                background-color: #284666;
                color: #ffffff;
            }
            .close {
                display: block;
            }
            .open {
                display: none;
            }
        }
    }
}

.select-menu {
    position: relative;
    width: 100%;
    &.open {
        .open {
            display: block;
        }
        .close {
            display: none;
        }
        &:after {
            bottom: -400px;
        }
        .select-menu__selected {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            background: #ffffff;
            color: $color-font-main;
            &:after {
                content: "";
                position: fixed;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                z-index: 3;
            }
            img {
                transform: rotate(180deg);
            }
        }
        .select-menu__list-wrap {
            display: block;
        }
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        // box-shadow: 0 10px 50px rgba(0,0,0,.16);
    }
    .open {
        display: none;
    }
    &__selected {
        color: $color-white;
        height: 57px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        font-size: 15px;
        font-weight: 700;
        letter-spacing: 0.02em;
        user-select: none;
        position: relative;
        z-index: 6;
        width: 330px;
        height: 48px;
        padding: 5px 20px 5px 30px;
        cursor: pointer;
        justify-content: space-between;
        border-radius: 14px;
        background:-moz-linear-gradient(45deg, rgba(241, 102, 85, 1) 0%, rgba(251, 54, 54, 1) 100%);
        background:-webkit-linear-gradient(45deg, rgba(241, 102, 85, 1) 0%, rgba(251, 54, 54, 1) 100%);
        background:-o-linear-gradient(45deg, rgba(241, 102, 85, 1) 0%, rgba(251, 54, 54, 1) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FB3636', endColorstr='#F16655', GradientType=1 );
        background:-ms-linear-gradient(45deg, rgba(241, 102, 85, 1) 0%, rgba(251, 54, 54, 1) 100%);
        background:linear-gradient(45deg, rgba(241, 102, 85, 1) 0%, rgba(251, 54, 54, 1) 100%);
        img {
            display: block;
            max-width: 100%;
            height: auto;
        }
        * {
            pointer-events: none;
        }
    }
    &__list {
        height: 100%;
        &-wrap {
            padding: 15px 30px 25px;
            width: 100%;
            height: 400px;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            background-color: $color-white;
            z-index: 6;
            overflow: hidden;
            border-bottom-right-radius: 14px;
            border-bottom-left-radius: 14px;
            color: $color-font-main;
        }
    }
    &__item {
        display: block;
        padding: 6px 0;
        font-size: 16px;
        font-weight: 400;
        transition: color .2s ease-in-out;
        &:hover,
        &.active {
            color: #CD1719;
        }
        &:first-child {
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }
    .simplebar-vertical {

        &.simplebar-track {
            width: 5px;
            right: -6px;
            &:after {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                width: 1px;
                background-color: #DBDBDB;
            }
        }
        .simplebar-scrollbar {
            &:before {
                background-color: $color-font-main;
                left: 0;
                right: 0;
                border-radius: 0;
            }
        }
    }
}
