.rules {
    margin-bottom: 143px;
    padding-top: 40px;
    position: relative;
    &__top-title {
        font-size: 80px;
        line-height: 90px;
        font-weight: 700;
        text-align: center;
        padding-bottom: 10px;
        margin-bottom: 44px;
        display: flex;
        justify-content: center;
        position: relative;
        color: #1A1B1C;
        @media(max-width:$md) {
            font-size: 60px;
            line-height: 70px;
        }
        &::after {
            content: '';
            position: absolute;
            bottom: 0;
            height: 7px;
            width: 64px;
            background-color: #FB3636;
            border-radius: 10px;
        }
    }
    &__text {
        color: #000C2D;
        font-size: 20px;
        line-height: 35px;
        padding: 55px 120px 210px 120px;
        box-shadow: 0 0 99px rgba(0,0,0,.16);
        border-radius: 20px;
        background-color: $color-white;
        @media(max-width:$md-max) {
            padding: 55px 15px;
        }
        .title {
            font-size: 15px;
            font-weight: 700;
            margin-bottom: 35px;
            color: #1A1B1C;
        }
    }
}
