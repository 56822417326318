.therapist-menu {
    color: $color-white;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    margin-right: 30px;
    a {
        color: $color-white;
    }
    &__select-wrap {
        opacity: 0;
        transition: opacity .2s ease-in-out;
        margin-right: 30px;
        &.loaded {
            opacity: 1;
        }
    }
    &.main {
        .therapist-menu__select-wrap {
            display: none;
        }
    }
    &__link {
        display: inline-flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        border: 1px solid $color-white;
        border-radius: 7px;
        min-height: 35px;
        padding: 0 10px;
    }
    .cs-select {
        position: relative;
    }
    .cs-title {
        padding-right: 25px;
    }
    .cs-list {
        height: 100%;
        margin: 0;
        padding: 0;
        padding-right: 24px;
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 400;
            text-transform: none;
            margin: 0;
        }
    }
    .cs-list-wrap {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: 9;
        background-color: $color-blue;
        width: 330px;
        height: 460px;
        top: calc(100% + 15px);
        left: calc((100% - 330px) / 2);
        transition: opacity .2s ease-in-out;
        padding: 24px 0 24px 24px;
        border-radius: 20px;
        &:after {
            content: "";
            position: absolute;
            top: -12px;
            left: 0;
            right: 0;
            background-image: url("/templates/main/images/svg/triangle-list.svg");
            width: 14px;
            height: 14px;
            margin: auto;
        }
    }
    .cs-select {

    }
    .cs-btn {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        cursor: pointer;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 14px;
            height: 9px;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url("/templates/main/images/svg/chevron-down.svg");
            transition: transform .2s ease-in-out;
            transform-origin: 50% 50%;
            z-index: 1;
        }
        &.open {
            &:after {
                transform: rotate(180deg);
            }
        }
    }
    .open {
        .cs-list-wrap {
            opacity: 1;
            pointer-events: all;
        }
    }
}
.therapist-list-problems {
    height: 100%;
    margin: 0;
    padding: 0;
    padding-right: 24px;
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {
        font-size: 13px;
        line-height: 18px;
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: 0.1em;
        margin-bottom: 16px;
        & > a {
            opacity: 0.5;
            padding-bottom: 10px;
            margin-bottom: 8px;
            display: block;
        }
        & > ul {
            & > li {
                font-size: 16px;
                font-weight: 400;
                text-transform: none;
                margin: 0;
                & > a {
                    opacity: 1;
                    margin: 0;
                    display: block;
                    letter-spacing: normal;
                }
            }
        }
    }
    .disable-link {
        pointer-events: none;
    }
}
