.questionnaire {
    .form-validate {
        input:invalid {
            background-color: rgba(251, 54, 54, 0.5);
        }
        select:invalid {
            background-color: rgba(251, 54, 54, 0.5);
        }
    }
    &__category {
        font-size: 17px;
        font-weight: 700;
        color: $color-font-main;
        margin-bottom: 10px;
        &-wrap {
            margin-bottom: 20px;
            display: inline-block;
        }
    }
    .phone-number-first {
        .questionnaire__input-placeholder {
            display: none;
        }
        #jform_phone {
            z-index: -1;
            opacity: 0;
        }
        &.questionnaire__input-wrap--large.open input {
            padding-top: 0;
        }
    }
    // #jform_address__address0__contact_telephone {
    //     opacity: 0;
    // }
    .inputs {
        &-inside {
            &.mobile {
                .inputs-inside__bracket {
                    display: none;
                }
                .inputs-inside__break-mobile {
                    width: 12px;
                }
            }
            &.stacionary {
                .inputs-inside__break-stacionary {
                    width: 12px;
                }
            }
            display: flex;
            align-items: flex-end;
            padding: 25px;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type=number] {
                -moz-appearance:textfield;
            }
            input {
                max-width: 12px;
                border: none;
                border-bottom: 1px solid #000;
                margin: 0 2.5px;
                text-align: center;
                color: #707070;
            }
            &.show {
                display: flex;
            }
            &__break {
                margin: 0 7px;
            }
        }
    }
    #jform_degree {
        height: 70px;
        width: 100%;
        background-color:#fff;
        -webkit-box-shadow: 0 0 99px rgba(0,0,0,0.1);
        box-shadow: 0 0 99px rgba(0,0,0,0.1);
        margin-bottom: 30px;
        border: none;
        position: relative;
        border-radius: 12px;
        padding: 0 25px;
        color: #707070;
        &::after {
            content: '';
        }
    }
    &__custom-select {
        border-radius: 10px;
    }
    &__close-btn {
        position: absolute;
        top: 100%;
        margin-top: 10px;
        font-size: 10px;
        letter-spacing: 0.1em;
        font-weight: 400;
        text-transform: uppercase;
        color: $color-gray;
        width: 100%;
        left: 0;
        text-align: center;
        cursor: pointer;
        user-select: none;
        a {
            display: block;
            padding: 5px 0;
        }
    }
    &__rodo {
        font-size: 13px;
        line-height: 20px;
        margin-bottom: 30px;
        font-weight: 400;
    }
    &__submit-wrap {
        display: flex;
        flex-wrap: wrap;
        .recaptcha {
            margin: 0 30px 20px 0;
        }
        .questionnaire__form-submit {
            margin: 0 0 20px;
            border-radius: 10px;
        }
    }
    &__radio {
        &-wrap {
            margin-bottom: 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            align-content: center;
        }
        &-item {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            position: relative;
            margin-left: 30px;
            &:first-child {
                margin-left: 0;
            }
            input {
                width: 24px;
                height: 24px;
                opacity: 0;
                position: relative;
                z-index: 3;
            }
            .label {
                position: relative;
                margin: 0;
                padding: 0;
                font-size: 13px;
                font-weight: 400;
                color: $color-gray;
                padding-left: 10px;
                height: 100%;
                align-items: center;
                display: flex;

            }
            label {
                position: relative;
                margin: 0;
                padding: 0;
                &:before {
                    content: "";
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    display: block;
                    position: absolute;
                    right: 100%;
                    background-color: $color-white;
                    box-shadow: 0 5px 35px rgba(0,0,0,0.16);
                    pointer-events: none;
                }
                &:after {
                    content: "";
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background-color: $color-red;
                    opacity: 0;
                    right: 100%;
                    pointer-events: none;
                    display: block;
                    right: calc(100% + 4px);
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    position: absolute;
                }
            }
            input:checked ~ label:after {
                opacity: 1;
            }
        }
    }
    &__article {
        max-width: 970px;
        margin: 0 auto;
        font-size: 20px;
        line-height: 35px;
        font-weight: 400;
        &-papers {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            @media(min-width: $sm) {
                justify-content: space-between;
            }
            a {
                display: block;
                margin-bottom: 30px;
                max-width: 225px;
                width: 100%;
                padding: 0 15px;
                @media(min-width: $sm) {
                    padding: 0;
                    width: calc((100% / 3) - 20px);
                }
            }
        }
        p, ol, ul {
            margin-bottom: 30px;
        }
        ul {
            font-size: 15px;
            letter-spacing: 0.05em;
            list-style: none;
            text-transform: uppercase;
            color: $color-red;
            font-weight: 700;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin: 0;
            padding: 0;
            li {
                margin-bottom: 30px;
                &:first-child {
                    margin-right: 30px;
                }
            }
        }
        ol {
            font-size: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            font-weight: 700;
        }
        a {
            color: $color-red;
            &:hover {
                color: $color-red;
                text-decoration: underline;
            }
        }
        &-link {
            height: 90px;
            width: 100%;
            max-width: 345px;
            background-color: $color-teal;
            color: $color-white;
            border: none;
            outline: none;
            margin: 0 auto;
            cursor: pointer;
            display: block;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            align-items: center;
            box-shadow: 0 24px 55px rgba(53,183,193,0.32);
            &:hover {
                color: $color-white;
            }
        }
    }
    &__message-image {
        text-align: center;
        margin-bottom: 45px;
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }
    &__message-link {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            border: 3px solid $color-teal;
            height: 80px;
            padding: 0 35px;
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            img {
                margin-left: 25px;
            }
        }
    }
    .subform-repeatable {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    &__container {
        @media(max-width: $md-max) {
            padding: 0;
        }
    }
    &__logo {
        @media(max-width: $xs-max) {
            width: 100%;
            margin-bottom: 25px;
            text-align: center;
        }
    }
    &__top {
        padding-top: 120px;
        padding-bottom: 100px;
        color: $color-black;
        position: relative;
        padding: 50px 15px 30px;
        @media(min-width: $sm) {
            padding: 50px 15px 30px;
        }
        @media(min-width: $lg) {
            padding: 50px 0 30px;
        }
        &:after {
            content: "";
            height: 200px;
            left: 0;
            right: 0;
            top: 100%;
            width: 100%;
            background-color: inherit;
            position: absolute;
        }
    }
    &__info {
        color: $color-white;
        @media(max-width: $xs-max) {
            width: 100%;
        }
        &-link {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            @media(max-width: $xs-max) {
                justify-content: center;
            }
        }
        img {
            margin-left: 15px;
        }
    }
    &__header {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        &-right {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            @media(max-width: $xs-max) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 100%;
            }
            @media(min-width: $lg) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
            }
            .questionnaire__pdf {
                margin-bottom: 20px;
                @media(min-width: $lg) {
                    margin-bottom: 0;
                    margin-right: 70px;
                }
            }
        }
    }
    &__wrap {
        background-color: $color-white;
        position: relative;
        z-index: 3;
        box-shadow: 0 0 99px rgba(0,0,0,0.16);
        margin-bottom: 200px;
        padding: 70px 15px 60px;
        border-radius: 20px;
        @media(min-width: $lg) {
            padding: 70px 0 60px;
        }
        &-container {
            max-width: 770px;
            margin: 0 auto;
        }
    }
    &__title {
        line-height: 82px;
        font-weight: 700;
        text-align: center;
        padding-bottom: 25px;
        letter-spacing: -0.04em;
        position: relative;
        margin-bottom: 35px;
        font-size: 70px;
        color: $color-font-second;
        @media(min-width: $sm) {
            font-size: 80px;
        }
        &:after {
            content: "";
            display: block;
            height: 7px;
            width: 65px;
            border-radius: 5px;
            background-color: $color-red;
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
    &__form {
        &-left {
            border-radius: 10px;
            .questionnaire__radio-wrap {
                margin-bottom: 0;
            }
        }
        &-fieldset {
            display: flex;
            -webkit-display: flex;
            -moz-display: flex;
            -ms--display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            &--personal {
                justify-content: space-between;
                .questionnaire__form-left {
                    width: 100%;
                    margin-bottom: 30px;
                    @media(min-width: $sm) {
                        width: 170px;
                        margin-bottom: 0;
                    }

                }
                .questionnaire__form-right {
                    width: 100%;
                    @media(min-width: $sm) {
                        width: calc(100% - 200px);
                    }
                }
            }
            &--location {
                justify-content: space-between;
                .questionnaire__form-left {
                    box-shadow: 0 0 99px rgba(0,0,0,0.1);
                    background-color: $color-white;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 45px;
                    padding: 10px;
                    position: relative;
                    @media(min-width: $sm) {
                        padding: 30px;
                    }
                    @media(min-width: $md) {
                        width: calc(50% - 15px);
                    }
                    &:first-child {
                        .questionnaire__close-btn {
                            display: none;
                        }
                    }
                    & > .questionnaire__input-wrap--col {
                        display: flex;
                        flex-wrap: wrap;
                        width: 100%;
                        justify-content: space-between;
                        & > div {
                            display: flex;
                            flex-wrap: wrap;
                            width: calc(50% - 5px);
                        }
                        .questionnaire__input-wrap--col {
                            width: calc(50% - 5px);
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: space-between;
                            align-items: center;
                            .questionnaire__input-wrap {
                                width: calc(50% - 10px);
                            }
                            .questionnaire__input-separator {
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
                .questionnaire__form-right {
                    width: 100%;
                    min-height: 200px;
                    margin-bottom: 45px;
                    @media(min-width: $md) {
                        min-height: 382px;
                        width: calc(50% - 15px);
                    }
                }
            }
            &--specialization {
                .questionnaire__form-center {
                    width: 100%;
                }
            }
        }
        &-legend {
            width: 100%;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            padding-bottom: 12px;
            border-bottom: 2px solid $color-gray-border;
            margin-bottom: 25px;
        }
        &-input {
            &--file {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 0;
                opacity: 0;
                cursor: pointer;
            }
        }
        &-select {
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            padding: 0 10px;
            font-size: 13px;
            font-weight: 700;
            i {
                color: red;
                font-weight: 400;
                font-style: normal;
            }
        }
        &-textarea {
            position: absolute;
            left: -1px;
            top: -1px;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            resize: none;
            padding: 10px;
            @media(min-width: $sm) {
                padding: 20px 25px;
            }
        }
        &-submit {
            height: 90px;
            width: 100%;
            max-width: 345px;
            background-color: $color-teal;
            color: $color-white;
            border: none;
            outline: none;
            margin: 0 auto;
            cursor: pointer;
            display: block;
            font-size: 15px;
            font-weight: 700;
            text-transform: uppercase;
            img {
                margin-right: 10px;
            }
        }
    }
    &__textarea-wrap {
        width: 100%;
        min-height: 235px;
        box-shadow: 0 0 99px rgba(0,0,0,0.1);
        overflow: hidden;
        position: relative;
        border-radius: 10px;
        margin-bottom: 30px;
    }
    &__description {
        color: $color-gray;
        margin-bottom: 70px;
        text-align: center;
        font-size: 20px;
        letter-spacing: -0.03em;
        &-item {
            display: block;
            span {
                color: $color-red;
            }
        }
    }
    &__input {
        &-wrap {
            border-radius: 10px;
            &--large {
                height: 70px;
                width: 100%;
                background-color: $color-white;
                box-shadow: 0 0 99px rgba(0,0,0,0.1);
                position: relative;
                overflow: hidden;
                margin-bottom: 30px;
                .questionnaire__input-placeholder {
                    font-size: 18px;
                    font-weight: 400;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    z-index: 3;
                    color: $color-gray;
                    pointer-events: none;
                    padding-top: 0;
                    transition: all .2s ease-in-out;
                    padding: 0 10px;
                    @media(min-width: $sm) {
                        padding: 0 25px;
                    }
                    &:after {
                        content: "";
                        left: 0;
                        right: 100%;
                        height: 2px;
                        background-color: $color-red;
                        position: absolute;
                        transition: all .2s ease-in-out;
                        bottom: 0;
                        z-index: 3;
                    }
                    i {
                        color: red;
                        font-style: normal;
                    }
                }
                .questionnaire__form-input--text {
                    position: absolute;
                    z-index: 3;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    top: -1px;
                    left: -1px;
                    font-size: 18px;
                    font-weight: 700;
                    padding: 0 10px;
                    @media(min-width: $sm) {
                        padding: 0 25px;
                    }
                    &::placeholder {
                        opacity: 0;
                        font-size: 0;
                    }
                    &:focus {
                        padding-top: 15px;
                    }
                    &:focus ~ .questionnaire__input-placeholder {
                        font-size: 12px;
                        padding-bottom: 25px;
                        &:after {
                            right: 0;
                        }
                    }

                }
            }
            &--small {
                height: 36px;
                width: 100%;
                background-color: $color-white;
                position: relative;
                overflow: hidden;
                margin-bottom: 10px;
                border: 1px solid $color-gray-border;
                .questionnaire__form-input--text {
                    position: absolute;
                    z-index: 3;
                    width: calc(100% + 2px);
                    height: calc(100% + 2px);
                    top: -1px;
                    left: -1px;
                    padding: 0 12px;
                    &::placeholder {
                        opacity: 0;
                    }
                    &:focus ~ .questionnaire__input-placeholder {
                        opacity: 0;
                    }
                }
                .questionnaire__input-placeholder {
                    font-size: 13px;
                    font-weight: 700;
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    top: 0;
                    padding: 0 12px;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    z-index: 3;
                    pointer-events: none;
                    padding-top: 0;
                    transition: all .2s ease-in-out;
                    // &.phone-number {
                    //     display: none;
                    //     opacity: 0;
                    // }
                    &:after {
                        content: "";
                        left: 0;
                        right: 100%;
                        height: 2px;
                        background-color: $color-red;
                        position: absolute;
                        transition: all .2s ease-in-out;
                        bottom: 0;
                        z-index: 3;
                    }
                    i {
                        color: red;
                        font-style: normal;
                    }
                }
            }
            &--file {
                width: 170px;
                height: 170px;
                background-color: $color-gray-dark;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-content: center;
                align-items: center;
                position: relative;
                margin-bottom: 10px;
            }
            &--select {
                &.big-select {
                    select {
                        font-size: 18px;
                        font-weight: 400;
                    }
                    &::after {
                        height: 69px;
                    }
                }
                &.default {
                    &:before {
                        content: "*";
                        position: absolute;
                        line-height: 15px;
                        font-size: 13px;
                        height: 15px;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        font-weight: 700;
                        color: $color-red;
                        z-index: 3;
                    }
                }
                &:after {
                    content: "";
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    height: 36px;
                    width: 36px;
                    background-image: url('/templates/main/images/svg/down-black.svg');
                    background-repeat: no-repeat;
                    background-position: center;
                    background-color: $color-white;
                    pointer-events: none;
                    z-index: 3;
                }
                &.region {
                    &:before {
                        left: 110px;
                    }
                }
                &.type {
                    &:before {
                        left: 38px;
                    }
                }
            }
        }
    }
    &__file-btn {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.08em;
        pointer-events: none;
        img {
            margin: 0 auto;
            display: block;
            margin-bottom: 12px;
        }
        &--remove {
            display: none;
            font-size: 10px;
            letter-spacing: 0.1em;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
            &.file-uploaded {
                display: block;
            }
        }
        &--info {
            display: none;
            font-size: 10px;
            letter-spacing: 0.1em;
            font-weight: 400;
            text-transform: uppercase;
            text-align: center;
            cursor: pointer;
            color: $color-red;
            &.open {
                display: block;
            }
        }
    }
    &__location-btn {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 0.08em;
        text-align: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        align-items: center;
        background-color: $color-gray-dark;
        height: 100%;
        cursor: pointer;
        border-radius: 10px;
        img {
            margin: 0 auto;
            display: block;
            margin-bottom: 12px;
        }
        span {
            display: block;
            width: 100%;
        }
    }

}

.file-uploaded {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    .questionnaire__file-btn {
        opacity: 0;
    }
}
.custom-select {
    margin-bottom: 30px;
    box-shadow: 0 0 99px rgba(0,0,0,0.1);
    padding-bottom: 30px;
    &__top {
        // background-color: $color-blue-dark;
        // color: $color-white;
        margin-bottom: 22px;
        &.open {
            .custom-select__title-description {
                visibility: visible;
                opacity: 0.5;
            }
        }
    }
    &__title {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        height: 70px;
        align-items: center;
        user-select: none;
        padding: 0 10px;
        @media(min-width: $sm) {
            padding: 0 25px;
        }
        i {
            color: red;
            font-style: normal;
            font-weight: 400;
        }
        span {
            text-transform: uppercase;
        }
        &-text {
            font-size: 15px;
            font-weight: 700;
            width: calc(100% - 30px);
            @media(min-width: $sm) {
                width: 100%;
            }
        }
        &-icon {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            flex-wrap: wrap;
            align-items: center;
            right: 10px;
            @media(min-width: $sm) {
                right: 25px;
            }
        }
        &-description {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            top: 45px;
            opacity: 0.5;
            letter-spacing: -0.03em;
            padding-left: 10px;
            font-size: 15px;
            border-bottom: 1px solid #fff;
            padding-bottom: 12px;
            transition: opacity .2s ease-in-out;
            left: 10px;
            right: 10px;
            @media(min-width: $sm) {
                left: 25px;
                right: 25px;
            }
        }
    }
    &__content {
        font-size: 0;
        padding: 35px 10px;
        @media(min-width: $sm) {
            padding: 35px 25px;
        }
        &-wrap {
            @media(min-width: $sm) {
                columns: 2;
                break-inside: avoid-column;
                page-break-inside: avoid;
            }
        }
        &-item {
            display: inline-flex;
            flex-wrap: wrap;
            margin-bottom: 5px;
            align-items: center;
            align-content: center;
            font-size: 18px;
            width: 100%;
        }
    }
    &__btn-bottom {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        margin-top: 30px;
        background-color: $color-teal;
        height: 60px;
        cursor: pointer;
    }
    &__selected {
        padding: 0 25px;
        & > [data-checkbox] {
            display: inline-flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            height: 55px;
            text-align: center;
            border-radius: 57px;
            border: 2px solid $color-teal;
            font-size: 18px;
            font-weight: 700;
            margin-right: 10px;
            margin-bottom: 10px;
            padding: 0 25px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    &__others {
        padding: 0 25px;
        &-title {
            font-size: 15px;
            font-weight: 700;
            letter-spacing: 0.05em;
            margin-bottom: 10px;
            text-transform: uppercase;
        }
        &-description {
            font-size: 15px;
            line-height: 20px;
            letter-spacing: -0.03em;
            opacity: 0.5;
            margin-bottom: 23px;
        }
    }
    &__input-wrap {
        width: 100%;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        box-shadow: 0 0 99px rgba(0,0,0,0.1);
        margin-bottom: 20px;
    }
    &__add-input {
        width: 100%;
        border: none;
        outline: none;
        height: 70px;
        background-color: $color-white;
        font-size: 18px;
        padding: 0 10px;
        @media(min-width: $sm) {
            padding: 0 25px;
            max-width: calc(100% - 200px);
        }
    }
    &__add-btn {
        height: 70px;
        width: 100%;
        background-color: $color-teal;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        color: $color-white;
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;
        user-select: none;
        @media(min-width: $sm) {
            max-width: 200px;
        }
        img {
            display: block;
            margin: 0 10px 0 0;
        }
    }
}
.custom-tag__close {
    position: relative;
    height: 25px;
    width: 25px;
    margin-left: 5px;
    margin-top: -3px;
    &:after, &:before {
        content: "";
        height: 2px;
        width: 20px;
        transform-origin: 50% 50%;
        display: block;
        background-color: $color-gray;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
}
.questionnaire__input-wrap--large.open {
    input {
        padding-top: 15px;
    }
    .questionnaire__input-placeholder {
        font-size: 12px;
        padding-bottom: 25px;
        &:after {
            right: 0;
        }
    }
}
.questionnaire__input-wrap--small.open {
    .questionnaire__input-placeholder {
        opacity: 0;
    }
}
.custom-checkbox {
    display: flex;
    flex-wrap: wrap;
    padding-left: 35px;
    position: relative;
    width: 100%;
    input {
        width: 25px;
        height: 25px;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
    }
    &__name {
        transition: color .2s ease-in-out;
        &:before {
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            border: 1px solid $color-gray;
            pointer-events: none;
        }
        &:after {
            content: "";
            width: 25px;
            height: 25px;
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            bottom: 0;
            opacity: 0;
            margin: auto;
            background-image: url('/templates/main/images/svg/check.svg');
            background-position: center;
            background-repeat: no-repeat;
            pointer-events: none;
            transition: opacity .2s ease-in-out;
        }
    }
    input:checked + &__name {
        color: $color-green;
    }
    input:checked + &__name:after {
        opacity: 1;
    }
    .questionnaire__checkbox-input {
        position: absolute;
        width: 100%;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        max-width: calc(100% - 35px);
        right: 0;
        top: 1px;
        padding-left: 10px;
        left: auto;
        height: 25px;
        z-index: 3;
        background-color: $color-blue-dark;
        color: $color-white;
        outline: none;
        border: 1px solid $color-gray;
    }
    &.other {
        .questionnaire__checkbox-input {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
        }
        .custom-checkbox__name {
            pointer-events: none;
        }
    }
}
.js-input-plus, .js-input-minus {
    position: absolute;
    background-color: $color-white;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: 1px solid #CACACA;
    border-radius: 5px;
    z-index: 5;
    font-weight: 400;
    line-height: 50px;
    font-size: 36px;
    top: 0;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    user-select: none;
}
.js-input-minus {
    right: 70px;
}
.js-input-plus {
    right: 10px;
}
.message-sent {
    background-color: lightgreen;
    border: green 1px dashed;
    padding: 15px 30px;
    display: none;
    width: 100%;
    margin-bottom: 20px;
    &.open {
        display: block;
    }
}
.message-alreadySent {
    background-color: lightgoldenrodyellow;
    border: orange 1px dashed;
    padding: 15px 30px;
    display: none;
    width: 100%;
    margin-bottom: 20px;
    &.open {
        display: block;
    }
}
.message-error {
    background-color: lightpink;
    border: red 1px dashed;
    padding: 15px 30px;
    display: none;
    width: 100%;
    margin-bottom: 20px;
    &.open {
        display: block;
    }
}
#place-name {
    &.disabled {
        opacity: 0.5;
    }
}
.questionnaire__radio-small {
    .questionnaire__radio-item {
        width: 100%;
        margin-left: 0;
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
}
#img {
    opacity: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    &.loaded {
        opacity: 1;
    }
}
#image-edit {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none;
    &.horizontal {
        pointer-events: all;
        z-index: 12;
        img {
            max-height: 100%;
            width: auto;

        }
    }
    &.vertical {
        z-index: 12;
        pointer-events: all;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &:after {
        content: "";
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        display: block;
        z-index: 9;
        position: absolute;
    }
}
