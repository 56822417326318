.reports {
    .bg-blue {
        padding-bottom: 180px;
    }
    &__top {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 15px;
        border-bottom: 3px solid $color-teal;
    }
    &__title {
        color: $color-black;
        font-size: 35px;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        margin: 0;
    }
    &__firm {
        position: absolute;
        min-height: 56px;
        padding: 5px 0;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        justify-content: center;
        text-align: center;
        color: $color-font-main;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0.05em;
        z-index: 3;
        background-color: $color-white;
        padding: 0 5px;
        span {
            padding-top: 4px;
            display: inline-block;
            width: 100%;
            margin-bottom: 5px;
            @media(min-width: $md) {
                width: auto;
                margin-bottom: 0;
            }
        }
        img {
            @media(min-width: $md) {
                margin-left: 25px;
            }
        }
    }
    &__content {
        margin-top: -150px;
    }
    &__item {
        position: relative;
        border-radius: 20px;
        box-shadow: 0 60px 99px rgba(0,0,0,0.18);
        overflow: hidden;
        background-color: $color-blue;
        &-link {
            display: block;
            position: relative;
        }
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(0deg, rgba(26,27,28,1) 0%, rgba(26,27,28,0) 100%);
            opacity: 0.7;
            pointer-events: none;
        }
        &-text {
            position: absolute;
            text-align: center;
            bottom: 0;
            left: 0;
            width: 100%;
            z-index: 3;
            color: $color-white;
            padding: 0 25px 25px;
        }
        &-title {
            font-size: 23px;
            font-weight: 700;
            line-height: 30px;
            margin-bottom: 14px;
        }
        &-introtext {
            font-size: 15px;
            line-height: 23px;
            font-weight: 400;
            margin-bottom: 10px;
        }
        &-count {
            font-size: 13px;
            font-weight: 700;
        }
    }
}
