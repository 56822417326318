.header {
    &__switcher {
        margin-left: 34px;
        @media(max-width: 450px) {
            margin-left: 15px;
        }
        &-wrapper {
            width: 40px;
            height: 24px;
            background-color: #EBEBEB;
            border-radius: 294px;
            position: relative;
            cursor: pointer;
            transition: .3s all ease-in-out;
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
                right: 20px;
                left: 4px;
                border-radius: 50%;
                background-color: #1A1B1C;
                width: 16px;
                height: 16px;
                transition: .3s all ease-in-out;
            }
        }
    }
}
// TODO: Do poprawy caly dark mode
body[data-dark-mode="true"] {
    background-color: #0e222e;
    color: #fff;
    // Home find terapist left module
    .header__wrap::before {
        display: none;
    }
    .find-therapist {
        background-color: #284666;
    }
    .problem-list-hp {
        background-color: #284666;
    }
    .problem-list-hp__link {
        border: 2px solid $color-white;
    }
    .last-added {
        background-color: #284666;
    }
    .last-added__list {
        background-color: #284666;
    }
    // Slider - first section on hp
    .swiper-hp-top__link {
        background-color: #002B44;
        color: $color-white;
    }
    // Calendar module
    .day-bottom {
        color: $color-white;
    }
    // Blue slider - hp
    .blue-slider {
        background-color: #284666;
    }
    // Opinions module
    .opinions__item-author {
        span {
            color: $color-white;
        }
    }
    // Newsletter module
    .newsletter-module__btn {
        color: #212529;
    }
    .three-articles__big-description {
        color: rgba(255,255,255,.7);
    }
    // Exercises module
    .exercises {
        background-color: #284666;
        color: #fff;
    }
    .exercises__top-more {
        border: 2px solid $color-white;
    }
    .exercises__el {
        background-color: rgba(255,255,255,.1);
    }
    .our-guide--slider .our-guide__module-title .our-guide__module-title-btn {
        border: 2px solid $color-white;
    }
    .our-magazines {
        background-color: #284666;
    }
    #sticky-menu {
        .sticky__bottom {
            background-color: #0e222e;
        }
        .sticky__burger {
            span {
                background-color: $color-white;
            }
        }
    }
    .sticky__search {
        svg {
            circle,path {
                stroke: $color-white;
            }
        }
    }
    #sticky-menu .sticky__menu > ul > li > a {
        color: $color-white;
    }
    .sticky__logo, .burger-menu__logo {
        #Group_135 {
            path {
                fill: #fff;
            }
        }
        #cross {
            #Path_4, #Path_3 {
                path {
                    fill: #fff;
                }
            }
        }
    }
    .reports__title {
        color: $color-white;
    }
    .magazines-list {
        color: $color-white;
    }
    .magazine-page__header h1 {
        color: $color-white;
    }
    .magazine-list__year-item {
        color: $color-white;
    }
    .swiper-container.swiper-magazine {
        .swiper-button-prev,.swiper-button-next {
            color: $color-white;
        }
    }
    .swiper-container.swiper-magazine {
        .swiper-button-prev,.swiper-button-next {
            svg {
                path {
                    stroke: $color-white;
                }
            }
            &::after {
                border-bottom: 1px dashed $color-white;
            }
        }
    }
    .magazine-single__background {
        background-color: #0e222e;
    }
    .magazine-single__top {
        background-color: #0e222e;
        color: $color-white;
    }
    .magazine-single__top {
        color: $color-white;
    }
    .header__new.header.header-article {
        background-color: #0e222e;
    }
    .swiper-container.swiper-magazine .swiper-nav-wrap::after {
        background-color: $color-white;
    }
    .article-list__header {
        color: $color-white;
    }
    .questionnaire__top {
        color: $color-white;
    }
    .single-article__top-text {
        color: $color-white;
    }
    .single-article__top-text-time-right-tag {
        color: $color-white;
    }
    .contact__top-title {
        color: $color-white;
    }
    .article-list__top .breadcrumb {
        color: $color-white;
    }
    .our-guide__menu ul {
        color: $color-white;
    }
    .pagination {
        color: $color-white;
    }
    .pagination > .active {
        color: $color-white;
    }
    .pagination > .inactive {
        background-color: transparent;
    }
    .hp-info-doctor {
        background-color: #143855;
    }
    .hp-info-doctor__image {
        background-color: #284666;
        svg {
            line, rect,path {
                stroke: #fff;
            }
        }
    }
    .therapists-hp__title {
        border-color: rgba(255,255,255,.1);
    }
    .firm-item {
        background-color: #284666;
    }
    .single-article__institution-el {
        background-color: #284666;
    }
    .hp-info__item-introtext {
        color: rgba(255,255,255,.7);
    }
    .btn-border__content {
        background-color: #002B44;
        svg {
            path {
                stroke: #fff;
            }
        }
    }
    .main__top {
        background-color: #284666;
    }
    .magazine-page__bottom-nav {
        .swiper-magazine-button-prev {
            border: 3px solid rgba(#C9C9C9,.2);
        }
        .swiper-magazine-button-next {
            border: 3px solid rgba(#C9C9C9,.2);
        }
    }
    .category-description {
        color: $color-white;
    }
    .category-description__text {
        color: $color-white;
    }
    .category-description__image {
        &::after {
            background: linear-gradient(to right,#0e222e 0,rgba(255,255,255,0) 100%);
        }
    }
    .category-menu {
        color: $color-white;
    }
    .calculator-single__top.nmc {
        background-image: linear-gradient(to right,#0e222e 50%,rgba(255,255,255,0) 100%), url('/templates/main/images/nmc-background.jpg');
    }
    .treatment__title {
        color: $color-white;
    }
    .treatment__menu ul {
        color: $color-white;
    }
    .bg-blue .breadcrumb {
        color: $color-white;
    }
    .bg-blue__return {
        color: $color-white;
        svg {
            path {
                stroke: $color-white;
            }
        }
    }
    .alphabet-list__title-text {
        color: $color-white;
    }
    .health-menu--column {
        color: $color-white;
    }
    .header__new.header.header-subpages .header__menu > ul li.active.parent a, .header__new.header.header-subpages .header__menu > ul li.current.parent a, .header__new.header.header-subpages .header__menu > ul li.alias-parent-active.parent a {
        color: $color-white;
    }
    .magazine-single__article-introtext {
        color: rgba(255,255,255,.7);
        &:hover {
            color: rgba(255,255,255,.7);
        }
    }
    .burger-menu__wrapper {
        background-color: #284666;
    }
    .calendar {
        background-color: #284666;
    }
    .calendary__list-item {
        background-color: #284666;
    }
    .kalendarium .calendar-container .gldp-kalendarium {
        .dow, .glCore {
            color: $color-white;
            &.day {
                &.inday {
                    &.event {
                        color: $color-white;
                    }
                }
            }
        }
    }
    .magazine-single__introtext {
        color: rgba(255,255,255,.7);
        &-title {
            color: $color-white;
        }
    }
    .magazine-single__article {
        &:hover {
            .magazine-single__article-introtext {
                color: rgba(255,255,255,.7);
            }
        }
    }
    // Single therapist:
    .single-therapist__person {
        background-color: #284666;
    }
    .single-therapist__place {
        background-color: #284666;
    }
    .single-therapist__info {
        background-color: #284666;
    }
    .single-therapist__experience {
        background-color: #284666;
    }
    .single-therapist__reviews {
        background-color: #284666;
    }
    .therapist-region .swiper-slide .last-added__item {
        background-color: #284666;
    }
    .single-therapist__person-btns-el {
        color: rgba(255,255,255,.4);
    }
    .single-therapist__person-btns-el.active {
        color: $color-white;
    }
    .single-therapist__info-text {
        color: rgba(255,255,255,.7);
    }
    .single-therapist__place-top-map {
        svg {
            #Rectangle_1953 {
                stroke: $color-white;
            }
            #Path_1474 {
                fill: $color-white;
            }
            #map-pin {
                path,circle {
                    stroke: $color-white;
                }
            }
        }
    }
    .calculator-single__bottom-fulltext {
        .pattern {
            background-color: #284666;
        }
        p {
            color: rgba(255,255,255,.7);
        }
    }
    .bibliography {
        padding-top: 0px;
    }
    .bibliography .bibliography__title {
        background-color: #284666;
    }
    .single-article__fulltext .static.bibliografia.p {
        background-color: #284666;
    }
    .single-article__fulltext .bibliografia > p {
        background-color: #284666;
    }
    .calculator-single__important {
        background-color: #284666;
    }
    .calculator-single__grey {
        background-color: #284666;
    }
    .calculator-single__important-top {
        color: $color-white;
    }
    .article-list__item {
        background-color: #284666;
    }
    .article-list__item-title {
        color: $color-white;
    }
    .single-therapist__person-btns-el::before {
        background-color: $color-white;
    }
    .e-commerce--article-transparent {
        .e-commerce__title {
            span {
                background-color: #0e222e;
                color: $color-white !important;
            }
        }
    }
    .report-single .bg-blue__image::after {
        background: linear-gradient(to right,#0e222e 0,rgba(255,255,255,0) 100%);
    }
    .ramka1, .ramka2, .ramka3, .ramka4, .ramka5 {
        background-color: #284666;
        color: $color-white;
    }
    .rules__text {
        background-color: #284666;
        color: $color-white;
    }
    .firm-list__map-btn--right {
        color: $color-white;
    }
    .firm-list__map-btn--right::after {
        display: none;
    }
    .firm-list__map-btn--right > img {
        opacity: 0.4;
    }
    .single-therapist::before {
        background-color: #0e222e;
    }
    .single-therapist::after {
        display: none;
    }
    .rules__top-title {
        color: $color-white;
    }
    .article-list__category {
        color: $color-white;
    }
    .city-list {
        color: $color-white;
    }
    .report-single__title {
        color: $color-white;
    }
    .single-article__top {
        background-color: #0e222e;
    }
    .single-therapist__breadcrumb {
        color: $color-white;
    }
    .report-single__introtext {
        color: $color-white;
    }
    .e-commerce__item-link {
        color: $color-white !important;
    }
    .single-article__fulltext {
        color: rgba(255,255,255,.7);
        h2,h3 {
            color: $color-white;
        }
        p, span {
            color: rgba(255,255,255,.7) !important;
        }
    }
    .more-in-section__link {
        .more-in-section__title {
            color: $color-white;
        }
        .more-in-section__description {
            color: rgba(255,255,255,.7);
        }
    }
    .article-tags__list-el {
        color: $color-white;
    }
    .spis-tresci {
        background-color: #284666;
        .spis-tresci__bottom {
            color: $color-white !important;
        }
        .spis-tresci__title {
            color: $color-white;
        }
    }
    .single-article__fulltext .spis-tresci__bottom-list-el a {
        color: $color-white !important;
    }
    .article-tags__list li a {
        color: $color-white !important;
    }
    .author-more__wrap {
        background-color: #284666;
    }
    .author-more__btn {
        color: $color-white !important;
    }
    .single-article__holistic {
        background-color: #284666;
    }
    .e-commerce--column {
        background-color: #284666;
        .e-commerce__title {
            span {
                background-color: #284666;
            }
        }
    }
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text {
        color: $color-white;
    }
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-description {
        color: rgba(255,255,255,.7);
    }
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-tags-el {
        border-color: rgba(255,255,255,0.2);
    }
    .header__switcher-wrapper {
        background-color: #284666;
        &::after {
            left: 20px;
            background-color: $color-white;
        }
    }
    .header__new {
        background-color: #0e222e;
    }
    .header__new::after {
        background: linear-gradient(to bottom,#000000 0,transparent 80%);
        opacity: .09;
    }
    .header__new.header.header-subpages .header__menu > ul > li > a {
        color: $color-white;
    }
    .header__new .header__search svg path, .header__new .header__search svg circle {
        stroke: $color-white;
    }
    .header__new .header__burger span {
        background-color: $color-white;
    }
    .alphabet-list__top {
        background-color: #0e222e;
        color: $color-white;
    }
    #search-form .searched-expression {
        color: $color-white;
    }
    #search-form {
        color: $color-white;
    }
    #search-form .tm-search-form__form .search-input input {
        color: $color-white;
        border: 2px solid rgba(255,255,255,0.2);
    }
    .calculator-single__top {
        background-color: #0e222e;
    }
    .single-therapist__therapists {
        background-color: #284666;
    }
    .articles-list__top {
        background-color: #0e222e;
    }
    .alphabet-list__letter {
        background-color: #0d202b;
        color: $color-white;
        border: 2px solid rgba(255,255,255,.2)
    }
    .e-commerce--article {
        background-color: #284666;
    }
    .e-commerce--article .e-commerce__title span {
        background-color: #284666;
    }
    #return-top {
        background-color: #284666;
        svg {
            path {
                fill: $color-white;
            }
        }
    }
    .alphabet-list__item-title span {
        background-color: #0e222e;
    }
    .waitbox__wrapper {
        background-color: #0e222e;
    }
    .header__new.header.header-subpages .header__logo svg #Path_1479 {
        fill: $color-white;
    }
    .header__new.header.header-subpages .header__logo svg #Path_3 {
        stroke: $color-white;
    }
    .header__new.header.header-subpages .header__logo svg #Path_4 {
        stroke: $color-white;
    }
    .contact__form {
        background-color: #143855;
    }
    .contact__info {
        background-color: #143855;
    }
    .contact__small-title {
        color: $color-white;
    }
    .top-menu--new {
        background-color: #0e222e;
    }
    .top-menu__link {
        color: $color-white;
    }
    .contact__name input, .contact__email input, .contact__form textarea {
        background-color: #284666;
        color: $color-white;
        border: none;
    }
    .questionnaire__wrap {
        background-color: #143855;
    }
    .questionnaire__title {
        color: $color-white;
    }
    .questionnaire__description {
        color: rgba(255,255,255,.7);
    }
    .questionnaire__input-wrap--file {
        background-color: #284666;
    }
    .questionnaire__input-wrap--large .questionnaire__form-input--text {
        background-color: #284666;
        color: $color-white;
        border: none;
    }
    .questionnaire__input-wrap--large .questionnaire__input-placeholder {
        color: $color-white;
    }
    .questionnaire__input-wrap--large {
        background-color: #284666;
    }
    .questionnaire #jform_degree {
        background-color: #284666;
        color: $color-white;
    }
    .questionnaire__input-wrap--select::after {
        background-color: #284666;
        background-image: url("/templates/main/images/svg/down-white.svg");
    }
    .questionnaire__radio-item {
        color: $color-white;
    }
    .questionnaire__radio-item label::before {
        background-color: #284666;
    }
    .questionnaire__radio-item label::after {
        width: 12px;
        height: 12px;
        right: calc(100% + 5px);
    }
    .questionnaire .inputs-inside input {
        background-color: #284666;
        border-color: $color-white;
    }
    .questionnaire__radio-item .label {
        color: $color-white;
    }
    .questionnaire__form-textarea {
        background-color: #284666;
        color: $color-white;
        border: none;
    }
    .questionnaire__form-fieldset--location .questionnaire__form-left {
        background-color: #284666;
    }
    .questionnaire__input-wrap--small .questionnaire__form-input--text {
        background-color: #284666;
        color: $color-white;
        border: none;
    }
    .questionnaire__input-wrap--small {
        background-color: #284666;
        border: 1px solid rgba(#DBDBDB,.3);
        border-radius: 0;
    }
    .questionnaire__location-btn {
        background-color: #284666;
        svg {
            circle,path {
                stroke: $color-white;
            }
        }
    }
    .questionnaire__file-btn {
        svg {
            circle,path {
                stroke: $color-white;
            }
        }
    }
    .questionnaire__category {
        color: $color-white;
    }
    .questionnaire__form-select {
        background-color: #284666;
        color: $color-white;
        border: none;
    }
    .firm-list__tools--select .main__select-wrap .cs-title {
        background-color: #284666;
        color: $color-white;
    }
    .main__select-wrap .cs-select::after {
        background-color: #284666;
    }
    .main__select-wrap .cs-btn::after {
        background-image: url("/templates/main/images/svg/chevron-down.svg");
    }
    .firm-list__tools--select .main__select-wrap .cs-select .cs-title--finder span {
        color: $color-white;
    }
    .main__select-wrap .cs-list-wrap {
        background-color: #284666;
        color: $color-white;
    }
    .cs-select .cs-list-wrap .cs-selected-list {
        color: $color-white;
    }
    .cs-select .cs-list-wrap .cs-selected-list a {
        color: $color-white;
    }
    .cs-select .cs-title--finder .cs-form::after {
        background-image: url("/templates/main/images/svg/search-icon.svg");
    }
    .cs-select .cs-title--finder .cs-form input {
        color: $color-white;
        border-bottom: 1px solid rgba(255,255,255,.2);
    }
    .cs-select .cs-list-wrap .cs-result-list {
        color: $color-white;
    }
    .firm-list__select .cs-title {
        background-color: #284666;
        color: $color-white;
    }
    .firm-list__select .cs-btn::after {
        background-image: url("/templates/main/images/svg/chevron-down.svg");
    }
    .firm-list__select .cs-list-wrap {
        background-color: #284666;
    }
    .firm-list__select .cs-list {
        color: $color-white;
    }
    .firm-list__tools--select .main__select-wrap .cs-select.cs-select--finder.open .cs-title {
        background: transparent;
    }
    .therapist-description {
        color: $color-white;
    }
    .firm-list__mobile-select {
        background-color: #284666;
        color: $color-white;
        svg {
            path {
                stroke: $color-white;
            }
        }
    }
    .firm-list__tools--select .firm-list__mobile-wrapper {
        background-color: #284666;
        @media(min-width: 1440px) {
            background-color: transparent;
        }
    }
    .firm-list__tools--select .firm-list__select {
        border: 2px solid rgba(255,255,255,0.4);
        @media(min-width: 1440px) {
            border: none;
        }
    }
    .firm-list__tools--select #filter-type {
        border: 2px solid rgba(255,255,255,0.4);
        @media(min-width: 1440px) {
            border: none;
        }
    }
    .firm-list__map-btn {
        background-color: #284666;
        color: $color-white;
        svg {
            path,circle {
                stroke: $color-white;
            }
        }
    }
    .article-list__article-select .cs-title {
        background-color: #284666;
        color: $color-white;
    }
    .article-list__article-select .cs-btn::after {
        background-image: url("/templates/main/images/svg/chevron-down.svg");
    }
    .article-list__article-select .cs-select .cs-list-wrap {
        background-color: #284666;
    }
    .article-list__article-select .cs-list {
        color: $color-white;
    }
    &.form-com_avtnewsletter {
        .header__new {
            background-color:#0E222E;
        }
        .top-menu--new {
            background-color:#0E222E;
        }
        .header__new::after {
            background: linear-gradient(to bottom, rgba(14,34,46,0.65) 0%,rgba(14,34,46,0) 100%);
        }
        .top-menu::before {
            background: linear-gradient(to bottom, rgba(14,34,46,0.65) 0%,rgba(14,34,46,0) 100%);
        }
        .newsletter-main {
            background-color: #0E222E;
        }
        .newsletter-main::after {
            background: #0E222E;
        }
        .newsletter__gift-papers {
            background-color: #0E222E;
        }
    }
    .main__top::before {
        background-color: #284666;
    }
    .header.header-subpages .header__menu>ul li.current a::after {
        background-color: #FFFFFF;
    }
    .recommended-articles-right {
        background-color: #284666;
        color: $color-white;
    }
    .expert-list__list .row--370 .col-left {
        background-color: #284666;
        color: $color-white;
    }

}
.questionnaire__file-btn {
    svg {
        margin: 0 auto;
        display: block;
        margin-bottom: 12px;
    }
}
.questionnaire__location-btn {
    svg {
        margin: 0 auto;
        display: block;
        margin-bottom: 12px;
    }
}
