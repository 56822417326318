.grid-article {
    border-radius: 20px;
    overflow: hidden;
    color: $color-white;
    position: absolute;
    transform-origin: 50% 50%;
    transition: transform .2s ease-in-out;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    transform: scale3d(1,1,1);
    overflow: hidden;
    &:hover {
        @media(min-width: $lg) {
            transform: scale3d(1.07, 1.07, 1);
        }
    }
    &__image {
        left: 0;
        top: 0;
        @media(min-width: $md) {
            position: absolute;
        }
        img {
            transform-origin: 50% 50%;
            transition: transform .2s ease-in-out;
            backface-visibility: hidden;
            outline: 1px solid transparent;
        }
    }
    &__text {
        position: absolute;
        bottom: 18px;
        left: 25px;
        right: 25px;
        font-size: 19px;
        font-weight: 700;
        z-index: 5;
    }
    &__content {
        position: relative;
        width: 100%;
        @media(min-width: $md) {
            padding-bottom: 100%;
        }
    }
    &__link {
        left: 0;
        top: 0;
        display: block;
        z-index: 3;
        width: 100%;
        height: 100%;
        @media(min-width: $md) {
            position: absolute;
        }
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            opacity: 0.5;
            z-index: 3;
        }
    }
    &--el-0 {
        z-index: 7;
        position: relative;
        width: 80%;
        @media(min-width: 380px) {
            width: 60%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            z-index: 2;
            min-width: 200px;
            margin: auto;
            width: 29%;
            top: 0;
            left: 0;
            right: 0;
        }
        @media(min-width: 1600px) {
            width: 22%;
            top: 5.7%;
            left: 23%;
            right: auto;
        }
    }
    &--el-1 {
        position: relative;
        z-index: 6;
        margin-left: auto;
        margin-right: 0;
        width: 80%;
        margin-top: -20%;
        margin-bottom: 10%;
        @media(min-width: 380px) {
            width: 60%;
            margin-top: -20%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            top: 5%;
            left: 11.5%;
            width: 20%;
            min-width: 180px;
            z-index: 2;
        }
        @media(min-width: 1600px) {
            z-index: 1;
            min-width: 300px;
            width: 36.4%;
            top: 13.4%;
            left: 40.1%;
        }
    }
    &--el-2 {
        position: relative;
        z-index: 5;
        width: 80%;
        margin-top: -20%;
        margin-bottom: 10%;
        opacity: 1;
        @media(min-width: 380px) {
            width: 60%;
            margin-top: -20%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            z-index: 3;
            width: 17%;
            min-width: 190px;
            top: 6%;
            right: 14.5%;
        }
        @media(min-width: 1600px) {
            width: 22%;
            min-width: 200px;
            right: 2.9%;
            top: 2.6%;
        }
    }
    &--el-3 {
        position: relative;
        width: 80%;
        z-index: 4;
        margin-left: auto;
        margin-right: 0;
        margin-top: -20%;
        margin-bottom: 10%;
        @media(min-width: 380px) {
            width: 60%;
            margin-top: -20%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            z-index: 1;
            width: 18%;
            min-width: 160px;
            top: 36%;
            left: 2%;
        }
        @media(min-width: 1600px) {
            min-width: 150px;
            width: 22.4%;
            right: -6.7%;
            top: 40.7%;
            left: auto;
            bottom: auto;
        }
    }
    &--el-4 {
        position: relative;
        z-index: 3;
        width: 80%;
        margin-top: -20%;
        margin-bottom: 10%;
        @media(min-width: 380px) {
            width: 60%;
            margin-top: -20%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            z-index: 0;
            width: 24%;
            min-width: 230px;
            bottom: 25%;
            left: 22%;
        }
        @media(min-width: 1600px) {
            min-width: 250px;
            width: 28%;
            right: 9.5%;
            top: 51.8%;
            bottom: auto;
            left: auto;
        }
    }
    &--el-5 {
        position: relative;
        width: 80%;
        z-index: 2;
        margin-left: auto;
        margin-right: 0;
        margin-top: -20%;
        margin-bottom: 10%;
        @media(min-width: 380px) {
            width: 60%;
            margin-top: -20%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            z-index: 0;
            width: 20%;
            min-width: 190px;
            right: 24%;
            bottom: 28%;
        }
        @media(min-width: 1600px) {
            width: 36.4%;
            min-width: 165px;
            left: 18.2%;
            bottom: 5.9%;
        }
    }
    &--el-6 {
        position: relative;
        z-index: 1;
        width: 80%;
        margin-top: -20%;
        margin-bottom: 10%;
        @media(min-width: 380px) {
            width: 60%;
            margin-top: -20%;
            margin-bottom: 10%;
        }
        @media(min-width: $md) {
            position: absolute;
            margin: 0;
            z-index: 0;
            width: 19%;
            min-width: 195px;
            right: 2%;
            bottom: 38%;
        }
        @media(min-width: 1600px) {
            width: 22.5%;
            min-width: 100px;
            left: 6.2%;
            bottom: 29.7%;
        }
    }
}
