:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 1025px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 1025px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row, .shops__top, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1025px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1025px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-3by4::before {
  padding-top: 133.33333%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1025px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1025px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1025px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1025px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1; }

.swiper-container-no-flexbox .swiper-slide {
  float: left; }

.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto; }

.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform,-webkit-transform; }

.swiper-invisible-blank-slide {
  visibility: hidden; }

.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto; }

.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height,-webkit-transform;
  transition-property: height,-webkit-transform;
  -o-transition-property: transform,height;
  transition-property: transform,height;
  transition-property: transform,height,-webkit-transform; }

.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px; }

.swiper-container-3d .swiper-cube-shadow,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-wrapper {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10; }

.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }

.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y; }

.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x; }

.swiper-button-next,
.swiper-button-prev {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat; }

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: .35;
  cursor: auto; }

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  left: 10px;
  right: auto; }

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
  right: 10px;
  left: auto; }

.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E"); }

.swiper-button-lock {
  display: none; }

.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: .3s opacity;
  -o-transition: .3s opacity;
  transition: .3s opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10; }

.swiper-pagination.swiper-pagination-hidden {
  opacity: 0; }

.swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%; }

.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0; }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative; }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66); }

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33); }

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: .2; }

button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer; }

.swiper-pagination-bullet-active {
  opacity: 1;
  background: #007aff; }

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0, -50%, 0);
  transform: translate3d(0, -50%, 0); }

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block; }

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px; }

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: .2s top,.2s -webkit-transform;
  transition: .2s top,.2s -webkit-transform;
  -o-transition: .2s transform,.2s top;
  transition: .2s transform,.2s top;
  transition: .2s transform,.2s top,.2s -webkit-transform; }

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap; }

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s left,.2s -webkit-transform;
  transition: .2s left,.2s -webkit-transform;
  -o-transition: .2s transform,.2s left;
  transition: .2s transform,.2s left;
  transition: .2s transform,.2s left,.2s -webkit-transform; }

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: .2s right,.2s -webkit-transform;
  transition: .2s right,.2s -webkit-transform;
  -o-transition: .2s transform,.2s right;
  transition: .2s transform,.2s right;
  transition: .2s transform,.2s right,.2s -webkit-transform; }

.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute; }

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #007aff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top; }

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top; }

.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0; }

.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-container-vertical > .swiper-pagination-progressbar {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0; }

.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #fff; }

.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25); }

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #fff; }

.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000; }

.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25); }

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000; }

.swiper-pagination-lock {
  display: none; }

.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1); }

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%; }

.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%; }

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0; }

.swiper-scrollbar-cursor-drag {
  cursor: move; }

.swiper-scrollbar-lock {
  display: none; }

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center; }

.swiper-zoom-container > canvas,
.swiper-zoom-container > img,
.swiper-zoom-container > svg {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain; }

.swiper-slide-zoomed {
  cursor: move; }

.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite; }

.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat; }

.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E"); }

@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000; }

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out; }

.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity; }

.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube {
  overflow: visible; }

.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%; }

.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-next + .swiper-slide,
.swiper-container-cube .swiper-slide-prev {
  pointer-events: auto;
  visibility: visible; }

.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right,
.swiper-container-cube .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: .6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0; }

.swiper-container-flip {
  overflow: visible; }

.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1; }

.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none; }

.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto; }

.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right,
.swiper-container-flip .swiper-slide-shadow-top {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden; }

.swiper-container-coverflow .swiper-wrapper {
  -ms-perspective: 1200px; }

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start; }

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit; }

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0; }

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch; }

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  visibility: visible;
  overflow: auto;
  /* Scroll on this element otherwise element can't have a padding applied properly */
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */ }

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table; }

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none; }

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0; }

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1; }

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden; }

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none; }

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all; }

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 7px;
  min-height: 10px; }

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.2s linear; }

.simplebar-track .simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear; }

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px; }

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px; }

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px; }

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto; }

/* Rtl support */
[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0; }

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll; }

:root,
:root[data-theme="light"] {
  --colorFont: #1a1b1c;
  --colorBg: #ffffff;
  --colorBgDarker: #f4f5f7; }

:root[data-theme="dark"] {
  --colorFont: #FFFFFF;
  --colorBg: #284666;
  --colorBgDarker: #193654; }

* {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent; }

html, body {
  font-size: 16px;
  font-family: "Objectivity", sans-serif;
  line-height: 1.533333; }

a {
  color: inherit; }
  a:hover {
    text-decoration: none;
    color: inherit; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-Bold.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-Bold.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-Bold.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-Bold.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-Bold.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-Bold.svg#Objectivity-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-Medium.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-Medium.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-Medium.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-Medium.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-Medium.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-Medium.svg#Objectivity-Medium") format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-Thin.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-Thin.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-Thin.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-Thin.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-Thin.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-Thin.svg#Objectivity-Thin") format("svg");
  font-weight: 100;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-Regular.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-Regular.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-Regular.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-Regular.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-Regular.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-Regular.svg#Objectivity-Regular") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-Black.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-Black.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-Black.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-Black.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-Black.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-Black.svg#Objectivity-Black") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-Light.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-Light.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-Light.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-Light.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-Light.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-Light.svg#Objectivity-Light") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: 'Objectivity';
  src: url("https://cdn.avt.pl/fonts/Objectivity-ExtraBold.eot");
  src: url("https://cdn.avt.pl/fonts/Objectivity-ExtraBold.eot?#iefix") format("embedded-opentype"), url("https://cdn.avt.pl/fonts/Objectivity-ExtraBold.woff2") format("woff2"), url("https://cdn.avt.pl/fonts/Objectivity-ExtraBold.woff") format("woff"), url("https://cdn.avt.pl/fonts/Objectivity-ExtraBold.ttf") format("truetype"), url("https://cdn.avt.pl/fonts/Objectivity-ExtraBold.svg#Objectivity-ExtraBold") format("svg");
  font-weight: 800;
  font-style: normal;
  font-display: swap; }

.page-header {
  font-size: 55px;
  line-height: 63px;
  font-weight: 700; }

.p-sticky {
  top: 85px;
  position: sticky;
  transition: top .2s ease-in-out; }

.avt-mb-15 {
  margin-bottom: 15px; }

picture.lazy {
  display: block; }

.avt-container-1170 {
  width: 100%;
  max-width: 1200px;
  padding: 0 15px;
  margin: 0 auto; }

.avt-container-1440 {
  width: 100%;
  max-width: 1440px;
  padding: 0 15px;
  margin: 0 auto; }

@media (max-width: 379.98px) {
  .main .row--370 .hp-left-column.p-col.col-right {
    padding: 0; } }

.avt-mb-3 {
  margin-bottom: 30px; }

.row--300 .p-col {
  padding: 0 15px; }

.row--300 > .col-left {
  width: 100%; }
  @media (min-width: 1025px) {
    .row--300 > .col-left {
      max-width: calc(100% - 300px); } }
  .row--300 > .col-left.p-col {
    padding: 0 15px; }
    @media (min-width: 1025px) {
      .row--300 > .col-left.p-col {
        max-width: calc(100% - 330px); } }

.row--300 > .col-right {
  width: 100%; }
  @media (min-width: 1025px) {
    .row--300 > .col-right {
      max-width: 300px; } }
  .row--300 > .col-right.p-col {
    padding: 0 15px; }
    @media (min-width: 1025px) {
      .row--300 > .col-right.p-col {
        max-width: 330px; } }

.row--370 .p-col {
  padding: 0 15px; }

.row--370 > .col-left {
  width: 100%; }
  @media (min-width: 1025px) {
    .row--370 > .col-left {
      max-width: calc(100% - 370px); } }
  .row--370 > .col-left.p-col {
    padding: 0 15px; }
    @media (min-width: 1025px) {
      .row--370 > .col-left.p-col {
        max-width: calc(100% - 400px); } }

.row--370 > .col-right {
  width: 100%; }
  @media (min-width: 1025px) {
    .row--370 > .col-right {
      max-width: 370px; } }
  .row--370 > .col-right.p-col {
    padding: 0 15px; }
    @media (min-width: 1025px) {
      .row--370 > .col-right.p-col {
        max-width: 400px; } }

.row--480 .p-col {
  padding: 0 15px; }

.row--480 > .col-left {
  width: 100%; }
  @media (min-width: 1025px) {
    .row--480 > .col-left {
      max-width: calc(100% - 450px); } }
  .row--480 > .col-left.p-col {
    padding: 0 15px; }
    @media (min-width: 1025px) {
      .row--480 > .col-left.p-col {
        max-width: calc(100% - 480px); } }

.row--480 > .col-right {
  width: 100%; }
  @media (min-width: 1025px) {
    .row--480 > .col-right {
      max-width: 450px; } }
  .row--480 > .col-right.p-col {
    padding: 0 15px; }
    @media (min-width: 1025px) {
      .row--480 > .col-right.p-col {
        max-width: 480px; } }

.top-blue {
  position: relative;
  padding-top: 20px; }
  @media (min-width: 768px) {
    .top-blue {
      padding-top: 0; } }
  .top-blue:after {
    content: "";
    position: absolute;
    z-index: -1;
    background-color: #002A43;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px; }
    @media (min-width: 768px) {
      .top-blue:after {
        height: 40px; } }

.questionnaire .form-validate input:invalid {
  background-color: rgba(251, 54, 54, 0.5); }

.questionnaire .form-validate select:invalid {
  background-color: rgba(251, 54, 54, 0.5); }

.questionnaire__category {
  font-size: 17px;
  font-weight: 700;
  color: #1A1B1C;
  margin-bottom: 10px; }
  .questionnaire__category-wrap {
    margin-bottom: 20px;
    display: inline-block; }

.questionnaire .phone-number-first .questionnaire__input-placeholder {
  display: none; }

.questionnaire .phone-number-first #jform_phone {
  z-index: -1;
  opacity: 0; }

.questionnaire .phone-number-first.questionnaire__input-wrap--large.open input {
  padding-top: 0; }

.questionnaire .inputs-inside {
  display: flex;
  align-items: flex-end;
  padding: 25px; }
  .questionnaire .inputs-inside.mobile .inputs-inside__bracket {
    display: none; }
  .questionnaire .inputs-inside.mobile .inputs-inside__break-mobile {
    width: 12px; }
  .questionnaire .inputs-inside.stacionary .inputs-inside__break-stacionary {
    width: 12px; }
  .questionnaire .inputs-inside input::-webkit-outer-spin-button,
  .questionnaire .inputs-inside input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }
  .questionnaire .inputs-inside input[type=number] {
    -moz-appearance: textfield; }
  .questionnaire .inputs-inside input {
    max-width: 12px;
    border: none;
    border-bottom: 1px solid #000;
    margin: 0 2.5px;
    text-align: center;
    color: #707070; }
  .questionnaire .inputs-inside.show {
    display: flex; }
  .questionnaire .inputs-inside__break {
    margin: 0 7px; }

.questionnaire #jform_degree {
  height: 70px;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  border: none;
  position: relative;
  border-radius: 12px;
  padding: 0 25px;
  color: #707070; }
  .questionnaire #jform_degree::after {
    content: ''; }

.questionnaire__custom-select {
  border-radius: 10px; }

.questionnaire__close-btn {
  position: absolute;
  top: 100%;
  margin-top: 10px;
  font-size: 10px;
  letter-spacing: 0.1em;
  font-weight: 400;
  text-transform: uppercase;
  color: #707070;
  width: 100%;
  left: 0;
  text-align: center;
  cursor: pointer;
  user-select: none; }
  .questionnaire__close-btn a {
    display: block;
    padding: 5px 0; }

.questionnaire__rodo {
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 30px;
  font-weight: 400; }

.questionnaire__submit-wrap {
  display: flex;
  flex-wrap: wrap; }
  .questionnaire__submit-wrap .recaptcha {
    margin: 0 30px 20px 0; }
  .questionnaire__submit-wrap .questionnaire__form-submit {
    margin: 0 0 20px;
    border-radius: 10px; }

.questionnaire__radio-wrap {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center; }

.questionnaire__radio-item {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  position: relative;
  margin-left: 30px; }
  .questionnaire__radio-item:first-child {
    margin-left: 0; }
  .questionnaire__radio-item input {
    width: 24px;
    height: 24px;
    opacity: 0;
    position: relative;
    z-index: 3; }
  .questionnaire__radio-item .label {
    position: relative;
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
    color: #707070;
    padding-left: 10px;
    height: 100%;
    align-items: center;
    display: flex; }
  .questionnaire__radio-item label {
    position: relative;
    margin: 0;
    padding: 0; }
    .questionnaire__radio-item label:before {
      content: "";
      width: 24px;
      height: 24px;
      border-radius: 50%;
      display: block;
      position: absolute;
      right: 100%;
      background-color: #fff;
      box-shadow: 0 5px 35px rgba(0, 0, 0, 0.16);
      pointer-events: none; }
    .questionnaire__radio-item label:after {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #FB3636;
      opacity: 0;
      right: 100%;
      pointer-events: none;
      display: block;
      right: calc(100% + 4px);
      top: 0;
      bottom: 0;
      margin: auto;
      position: absolute; }
  .questionnaire__radio-item input:checked ~ label:after {
    opacity: 1; }

.questionnaire__article {
  max-width: 970px;
  margin: 0 auto;
  font-size: 20px;
  line-height: 35px;
  font-weight: 400; }
  .questionnaire__article-papers {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; }
    @media (min-width: 576px) {
      .questionnaire__article-papers {
        justify-content: space-between; } }
    .questionnaire__article-papers a {
      display: block;
      margin-bottom: 30px;
      max-width: 225px;
      width: 100%;
      padding: 0 15px; }
      @media (min-width: 576px) {
        .questionnaire__article-papers a {
          padding: 0;
          width: calc((100% / 3) - 20px); } }
  .questionnaire__article p, .questionnaire__article ol, .questionnaire__article ul {
    margin-bottom: 30px; }
  .questionnaire__article ul {
    font-size: 15px;
    letter-spacing: 0.05em;
    list-style: none;
    text-transform: uppercase;
    color: #FB3636;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0;
    padding: 0; }
    .questionnaire__article ul li {
      margin-bottom: 30px; }
      .questionnaire__article ul li:first-child {
        margin-right: 30px; }
  .questionnaire__article ol {
    font-size: 15px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: 700; }
  .questionnaire__article a {
    color: #FB3636; }
    .questionnaire__article a:hover {
      color: #FB3636;
      text-decoration: underline; }
  .questionnaire__article-link {
    height: 90px;
    width: 100%;
    max-width: 345px;
    background-color: #35B7C1;
    color: #fff;
    border: none;
    outline: none;
    margin: 0 auto;
    cursor: pointer;
    display: block;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    box-shadow: 0 24px 55px rgba(53, 183, 193, 0.32); }
    .questionnaire__article-link:hover {
      color: #fff; }

.questionnaire__message-image {
  text-align: center;
  margin-bottom: 45px; }
  .questionnaire__message-image img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto; }

.questionnaire__message-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .questionnaire__message-link a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: 3px solid #35B7C1;
    height: 80px;
    padding: 0 35px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase; }
    .questionnaire__message-link a img {
      margin-left: 25px; }

.questionnaire .subform-repeatable {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

@media (max-width: 1024.98px) {
  .questionnaire__container {
    padding: 0; } }

@media (max-width: 575.98px) {
  .questionnaire__logo {
    width: 100%;
    margin-bottom: 25px;
    text-align: center; } }

.questionnaire__top {
  padding-top: 120px;
  padding-bottom: 100px;
  color: #1A1B1C;
  position: relative;
  padding: 50px 15px 30px; }
  @media (min-width: 576px) {
    .questionnaire__top {
      padding: 50px 15px 30px; } }
  @media (min-width: 1025px) {
    .questionnaire__top {
      padding: 50px 0 30px; } }
  .questionnaire__top:after {
    content: "";
    height: 200px;
    left: 0;
    right: 0;
    top: 100%;
    width: 100%;
    background-color: inherit;
    position: absolute; }

.questionnaire__info {
  color: #fff; }
  @media (max-width: 575.98px) {
    .questionnaire__info {
      width: 100%; } }
  .questionnaire__info-link {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    @media (max-width: 575.98px) {
      .questionnaire__info-link {
        justify-content: center; } }
  .questionnaire__info img {
    margin-left: 15px; }

.questionnaire__header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%; }
  .questionnaire__header-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%; }
    @media (max-width: 575.98px) {
      .questionnaire__header-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: 100%; } }
    @media (min-width: 1025px) {
      .questionnaire__header-right {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; } }
    .questionnaire__header-right .questionnaire__pdf {
      margin-bottom: 20px; }
      @media (min-width: 1025px) {
        .questionnaire__header-right .questionnaire__pdf {
          margin-bottom: 0;
          margin-right: 70px; } }

.questionnaire__wrap {
  background-color: #fff;
  position: relative;
  z-index: 3;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
  margin-bottom: 200px;
  padding: 70px 15px 60px;
  border-radius: 20px; }
  @media (min-width: 1025px) {
    .questionnaire__wrap {
      padding: 70px 0 60px; } }
  .questionnaire__wrap-container {
    max-width: 770px;
    margin: 0 auto; }

.questionnaire__title {
  line-height: 82px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 25px;
  letter-spacing: -0.04em;
  position: relative;
  margin-bottom: 35px;
  font-size: 70px;
  color: #002B44; }
  @media (min-width: 576px) {
    .questionnaire__title {
      font-size: 80px; } }
  .questionnaire__title:after {
    content: "";
    display: block;
    height: 7px;
    width: 65px;
    border-radius: 5px;
    background-color: #FB3636;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

.questionnaire__form-left {
  border-radius: 10px; }
  .questionnaire__form-left .questionnaire__radio-wrap {
    margin-bottom: 0; }

.questionnaire__form-fieldset {
  display: flex;
  -webkit-display: flex;
  -moz-display: flex;
  -ms--display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px; }
  .questionnaire__form-fieldset--personal {
    justify-content: space-between; }
    .questionnaire__form-fieldset--personal .questionnaire__form-left {
      width: 100%;
      margin-bottom: 30px; }
      @media (min-width: 576px) {
        .questionnaire__form-fieldset--personal .questionnaire__form-left {
          width: 170px;
          margin-bottom: 0; } }
    .questionnaire__form-fieldset--personal .questionnaire__form-right {
      width: 100%; }
      @media (min-width: 576px) {
        .questionnaire__form-fieldset--personal .questionnaire__form-right {
          width: calc(100% - 200px); } }
  .questionnaire__form-fieldset--location {
    justify-content: space-between; }
    .questionnaire__form-fieldset--location .questionnaire__form-left {
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 45px;
      padding: 10px;
      position: relative; }
      @media (min-width: 576px) {
        .questionnaire__form-fieldset--location .questionnaire__form-left {
          padding: 30px; } }
      @media (min-width: 768px) {
        .questionnaire__form-fieldset--location .questionnaire__form-left {
          width: calc(50% - 15px); } }
      .questionnaire__form-fieldset--location .questionnaire__form-left:first-child .questionnaire__close-btn {
        display: none; }
      .questionnaire__form-fieldset--location .questionnaire__form-left > .questionnaire__input-wrap--col {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between; }
        .questionnaire__form-fieldset--location .questionnaire__form-left > .questionnaire__input-wrap--col > div {
          display: flex;
          flex-wrap: wrap;
          width: calc(50% - 5px); }
        .questionnaire__form-fieldset--location .questionnaire__form-left > .questionnaire__input-wrap--col .questionnaire__input-wrap--col {
          width: calc(50% - 5px);
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center; }
          .questionnaire__form-fieldset--location .questionnaire__form-left > .questionnaire__input-wrap--col .questionnaire__input-wrap--col .questionnaire__input-wrap {
            width: calc(50% - 10px); }
          .questionnaire__form-fieldset--location .questionnaire__form-left > .questionnaire__input-wrap--col .questionnaire__input-wrap--col .questionnaire__input-separator {
            margin-bottom: 10px; }
    .questionnaire__form-fieldset--location .questionnaire__form-right {
      width: 100%;
      min-height: 200px;
      margin-bottom: 45px; }
      @media (min-width: 768px) {
        .questionnaire__form-fieldset--location .questionnaire__form-right {
          min-height: 382px;
          width: calc(50% - 15px); } }
  .questionnaire__form-fieldset--specialization .questionnaire__form-center {
    width: 100%; }

.questionnaire__form-legend {
  width: 100%;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 12px;
  border-bottom: 2px solid rgba(112, 112, 112, 0.1);
  margin-bottom: 25px; }

.questionnaire__form-input--file {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 0;
  opacity: 0;
  cursor: pointer; }

.questionnaire__form-select {
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  padding: 0 10px;
  font-size: 13px;
  font-weight: 700; }
  .questionnaire__form-select i {
    color: red;
    font-weight: 400;
    font-style: normal; }

.questionnaire__form-textarea {
  position: absolute;
  left: -1px;
  top: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  resize: none;
  padding: 10px; }
  @media (min-width: 576px) {
    .questionnaire__form-textarea {
      padding: 20px 25px; } }

.questionnaire__form-submit {
  height: 90px;
  width: 100%;
  max-width: 345px;
  background-color: #35B7C1;
  color: #fff;
  border: none;
  outline: none;
  margin: 0 auto;
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: 700;
  text-transform: uppercase; }
  .questionnaire__form-submit img {
    margin-right: 10px; }

.questionnaire__textarea-wrap {
  width: 100%;
  min-height: 235px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  border-radius: 10px;
  margin-bottom: 30px; }

.questionnaire__description {
  color: #707070;
  margin-bottom: 70px;
  text-align: center;
  font-size: 20px;
  letter-spacing: -0.03em; }
  .questionnaire__description-item {
    display: block; }
    .questionnaire__description-item span {
      color: #FB3636; }

.questionnaire__input-wrap {
  border-radius: 10px; }
  .questionnaire__input-wrap--large {
    height: 70px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow: hidden;
    margin-bottom: 30px; }
    .questionnaire__input-wrap--large .questionnaire__input-placeholder {
      font-size: 18px;
      font-weight: 400;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      z-index: 3;
      color: #707070;
      pointer-events: none;
      padding-top: 0;
      transition: all .2s ease-in-out;
      padding: 0 10px; }
      @media (min-width: 576px) {
        .questionnaire__input-wrap--large .questionnaire__input-placeholder {
          padding: 0 25px; } }
      .questionnaire__input-wrap--large .questionnaire__input-placeholder:after {
        content: "";
        left: 0;
        right: 100%;
        height: 2px;
        background-color: #FB3636;
        position: absolute;
        transition: all .2s ease-in-out;
        bottom: 0;
        z-index: 3; }
      .questionnaire__input-wrap--large .questionnaire__input-placeholder i {
        color: red;
        font-style: normal; }
    .questionnaire__input-wrap--large .questionnaire__form-input--text {
      position: absolute;
      z-index: 3;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      font-size: 18px;
      font-weight: 700;
      padding: 0 10px; }
      @media (min-width: 576px) {
        .questionnaire__input-wrap--large .questionnaire__form-input--text {
          padding: 0 25px; } }
      .questionnaire__input-wrap--large .questionnaire__form-input--text::placeholder {
        opacity: 0;
        font-size: 0; }
      .questionnaire__input-wrap--large .questionnaire__form-input--text:focus {
        padding-top: 15px; }
      .questionnaire__input-wrap--large .questionnaire__form-input--text:focus ~ .questionnaire__input-placeholder {
        font-size: 12px;
        padding-bottom: 25px; }
        .questionnaire__input-wrap--large .questionnaire__form-input--text:focus ~ .questionnaire__input-placeholder:after {
          right: 0; }
  .questionnaire__input-wrap--small {
    height: 36px;
    width: 100%;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid rgba(112, 112, 112, 0.1); }
    .questionnaire__input-wrap--small .questionnaire__form-input--text {
      position: absolute;
      z-index: 3;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      top: -1px;
      left: -1px;
      padding: 0 12px; }
      .questionnaire__input-wrap--small .questionnaire__form-input--text::placeholder {
        opacity: 0; }
      .questionnaire__input-wrap--small .questionnaire__form-input--text:focus ~ .questionnaire__input-placeholder {
        opacity: 0; }
    .questionnaire__input-wrap--small .questionnaire__input-placeholder {
      font-size: 13px;
      font-weight: 700;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      padding: 0 12px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      z-index: 3;
      pointer-events: none;
      padding-top: 0;
      transition: all .2s ease-in-out; }
      .questionnaire__input-wrap--small .questionnaire__input-placeholder:after {
        content: "";
        left: 0;
        right: 100%;
        height: 2px;
        background-color: #FB3636;
        position: absolute;
        transition: all .2s ease-in-out;
        bottom: 0;
        z-index: 3; }
      .questionnaire__input-wrap--small .questionnaire__input-placeholder i {
        color: red;
        font-style: normal; }
  .questionnaire__input-wrap--file {
    width: 170px;
    height: 170px;
    background-color: #E9E9EC;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10px; }
  .questionnaire__input-wrap--select.big-select select {
    font-size: 18px;
    font-weight: 400; }
  .questionnaire__input-wrap--select.big-select::after {
    height: 69px; }
  .questionnaire__input-wrap--select.default:before {
    content: "*";
    position: absolute;
    line-height: 15px;
    font-size: 13px;
    height: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-weight: 700;
    color: #FB3636;
    z-index: 3; }
  .questionnaire__input-wrap--select:after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    height: 36px;
    width: 36px;
    background-image: url("/templates/main/images/svg/down-black.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-color: #fff;
    pointer-events: none;
    z-index: 3; }
  .questionnaire__input-wrap--select.region:before {
    left: 110px; }
  .questionnaire__input-wrap--select.type:before {
    left: 38px; }

.questionnaire__file-btn {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  pointer-events: none; }
  .questionnaire__file-btn img {
    margin: 0 auto;
    display: block;
    margin-bottom: 12px; }
  .questionnaire__file-btn--remove {
    display: none;
    font-size: 10px;
    letter-spacing: 0.1em;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer; }
    .questionnaire__file-btn--remove.file-uploaded {
      display: block; }
  .questionnaire__file-btn--info {
    display: none;
    font-size: 10px;
    letter-spacing: 0.1em;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;
    color: #FB3636; }
    .questionnaire__file-btn--info.open {
      display: block; }

.questionnaire__location-btn {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.08em;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
  background-color: #E9E9EC;
  height: 100%;
  cursor: pointer;
  border-radius: 10px; }
  .questionnaire__location-btn img {
    margin: 0 auto;
    display: block;
    margin-bottom: 12px; }
  .questionnaire__location-btn span {
    display: block;
    width: 100%; }

.file-uploaded {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain; }
  .file-uploaded .questionnaire__file-btn {
    opacity: 0; }

.custom-select {
  margin-bottom: 30px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  padding-bottom: 30px; }
  .custom-select__top {
    margin-bottom: 22px; }
    .custom-select__top.open .custom-select__title-description {
      visibility: visible;
      opacity: 0.5; }
  .custom-select__title {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    height: 70px;
    align-items: center;
    user-select: none;
    padding: 0 10px; }
    @media (min-width: 576px) {
      .custom-select__title {
        padding: 0 25px; } }
    .custom-select__title i {
      color: red;
      font-style: normal;
      font-weight: 400; }
    .custom-select__title span {
      text-transform: uppercase; }
    .custom-select__title-text {
      font-size: 15px;
      font-weight: 700;
      width: calc(100% - 30px); }
      @media (min-width: 576px) {
        .custom-select__title-text {
          width: 100%; } }
    .custom-select__title-icon {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      flex-wrap: wrap;
      align-items: center;
      right: 10px; }
      @media (min-width: 576px) {
        .custom-select__title-icon {
          right: 25px; } }
    .custom-select__title-description {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      top: 45px;
      opacity: 0.5;
      letter-spacing: -0.03em;
      padding-left: 10px;
      font-size: 15px;
      border-bottom: 1px solid #fff;
      padding-bottom: 12px;
      transition: opacity .2s ease-in-out;
      left: 10px;
      right: 10px; }
      @media (min-width: 576px) {
        .custom-select__title-description {
          left: 25px;
          right: 25px; } }
  .custom-select__content {
    font-size: 0;
    padding: 35px 10px; }
    @media (min-width: 576px) {
      .custom-select__content {
        padding: 35px 25px; } }
    @media (min-width: 576px) {
      .custom-select__content-wrap {
        columns: 2;
        break-inside: avoid-column;
        page-break-inside: avoid; } }
    .custom-select__content-item {
      display: inline-flex;
      flex-wrap: wrap;
      margin-bottom: 5px;
      align-items: center;
      align-content: center;
      font-size: 18px;
      width: 100%; }
  .custom-select__btn-bottom {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 30px;
    background-color: #35B7C1;
    height: 60px;
    cursor: pointer; }
  .custom-select__selected {
    padding: 0 25px; }
    .custom-select__selected > [data-checkbox] {
      display: inline-flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 55px;
      text-align: center;
      border-radius: 57px;
      border: 2px solid #35B7C1;
      font-size: 18px;
      font-weight: 700;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 0 25px; }
      .custom-select__selected > [data-checkbox]:last-child {
        margin-right: 0; }
  .custom-select__others {
    padding: 0 25px; }
    .custom-select__others-title {
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.05em;
      margin-bottom: 10px;
      text-transform: uppercase; }
    .custom-select__others-description {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: -0.03em;
      opacity: 0.5;
      margin-bottom: 23px; }
  .custom-select__input-wrap {
    width: 100%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; }
  .custom-select__add-input {
    width: 100%;
    border: none;
    outline: none;
    height: 70px;
    background-color: #fff;
    font-size: 18px;
    padding: 0 10px; }
    @media (min-width: 576px) {
      .custom-select__add-input {
        padding: 0 25px;
        max-width: calc(100% - 200px); } }
  .custom-select__add-btn {
    height: 70px;
    width: 100%;
    background-color: #35B7C1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    user-select: none; }
    @media (min-width: 576px) {
      .custom-select__add-btn {
        max-width: 200px; } }
    .custom-select__add-btn img {
      display: block;
      margin: 0 10px 0 0; }

.custom-tag__close {
  position: relative;
  height: 25px;
  width: 25px;
  margin-left: 5px;
  margin-top: -3px; }
  .custom-tag__close:after, .custom-tag__close:before {
    content: "";
    height: 2px;
    width: 20px;
    transform-origin: 50% 50%;
    display: block;
    background-color: #707070;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }
  .custom-tag__close:before {
    transform: rotate(45deg); }
  .custom-tag__close:after {
    transform: rotate(-45deg); }

.questionnaire__input-wrap--large.open input {
  padding-top: 15px; }

.questionnaire__input-wrap--large.open .questionnaire__input-placeholder {
  font-size: 12px;
  padding-bottom: 25px; }
  .questionnaire__input-wrap--large.open .questionnaire__input-placeholder:after {
    right: 0; }

.questionnaire__input-wrap--small.open .questionnaire__input-placeholder {
  opacity: 0; }

.custom-checkbox {
  display: flex;
  flex-wrap: wrap;
  padding-left: 35px;
  position: relative;
  width: 100%; }
  .custom-checkbox input {
    width: 25px;
    height: 25px;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  .custom-checkbox__name {
    transition: color .2s ease-in-out; }
    .custom-checkbox__name:before {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      border: 1px solid #707070;
      pointer-events: none; }
    .custom-checkbox__name:after {
      content: "";
      width: 25px;
      height: 25px;
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      opacity: 0;
      margin: auto;
      background-image: url("/templates/main/images/svg/check.svg");
      background-position: center;
      background-repeat: no-repeat;
      pointer-events: none;
      transition: opacity .2s ease-in-out; }
  input:checked + .custom-checkbox__name {
    color: #3ACE58; }
  input:checked + .custom-checkbox__name:after {
    opacity: 1; }
  .custom-checkbox .questionnaire__checkbox-input {
    position: absolute;
    width: 100%;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    max-width: calc(100% - 35px);
    right: 0;
    top: 1px;
    padding-left: 10px;
    left: auto;
    height: 25px;
    z-index: 3;
    background-color: #002B44;
    color: #fff;
    outline: none;
    border: 1px solid #707070; }
  .custom-checkbox.other .questionnaire__checkbox-input {
    opacity: 1;
    visibility: visible;
    pointer-events: all; }
  .custom-checkbox.other .custom-checkbox__name {
    pointer-events: none; }

.js-input-plus, .js-input-minus {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid #CACACA;
  border-radius: 5px;
  z-index: 5;
  font-weight: 400;
  line-height: 50px;
  font-size: 36px;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  user-select: none; }

.js-input-minus {
  right: 70px; }

.js-input-plus {
  right: 10px; }

.message-sent {
  background-color: lightgreen;
  border: green 1px dashed;
  padding: 15px 30px;
  display: none;
  width: 100%;
  margin-bottom: 20px; }
  .message-sent.open {
    display: block; }

.message-alreadySent {
  background-color: lightgoldenrodyellow;
  border: orange 1px dashed;
  padding: 15px 30px;
  display: none;
  width: 100%;
  margin-bottom: 20px; }
  .message-alreadySent.open {
    display: block; }

.message-error {
  background-color: lightpink;
  border: red 1px dashed;
  padding: 15px 30px;
  display: none;
  width: 100%;
  margin-bottom: 20px; }
  .message-error.open {
    display: block; }

#place-name.disabled {
  opacity: 0.5; }

.questionnaire__radio-small .questionnaire__radio-item {
  width: 100%;
  margin-left: 0;
  margin-bottom: 5px; }
  .questionnaire__radio-small .questionnaire__radio-item:last-child {
    margin-bottom: 0; }

#img {
  opacity: 0;
  display: block;
  position: absolute;
  top: 0;
  left: 0; }
  #img.loaded {
    opacity: 1; }

#image-edit {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none; }
  #image-edit.horizontal {
    pointer-events: all;
    z-index: 12; }
    #image-edit.horizontal img {
      max-height: 100%;
      width: auto; }
  #image-edit.vertical {
    z-index: 12;
    pointer-events: all; }
    #image-edit.vertical img {
      max-width: 100%;
      height: auto; }
  #image-edit:after {
    content: "";
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    z-index: 9;
    position: absolute; }

.error-main .error-code {
  text-align: center;
  padding-bottom: 25px;
  margin-bottom: 35px;
  font-size: 80px;
  font-weight: 700;
  letter-spacing: -0.04em;
  position: relative; }
  .error-main .error-code:after {
    content: "";
    display: block;
    height: 7px;
    width: 65px;
    border-radius: 5px;
    background-color: #FB3636;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; }

.error-main .error-message {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.03em;
  margin-bottom: 30px;
  color: #707070; }

.error-main .error-image {
  margin: 0 auto 100px;
  display: block; }

.ocl-module {
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.22); }
  .ocl-module__title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 11px;
    border-bottom: 3px solid #F16655;
    text-transform: uppercase;
    margin: 0 22px 36px 22px;
    padding-top: 27px; }
  .ocl-module__wrapper {
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 30px; }
  .ocl-module__el {
    text-align: center; }
    .ocl-module__el-title {
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
      text-align: center;
      max-width: 324px;
      margin: 21px auto 14px auto; }
    .ocl-module__el-description {
      font-size: 14px;
      line-height: 20px;
      padding: 0 30px 20px 30px; }
    .ocl-module__el-image {
      margin-bottom: -13px; }
    .ocl-module__el-category {
      font-size: 10px;
      color: #fff;
      background-color: #FB3636;
      padding: 3px 10px;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: -10px auto 0 auto; }
    .ocl-module__el.only-title {
      padding: 37.5px 74px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
      @media (max-width: 576px) {
        .ocl-module__el.only-title {
          padding: 15px; } }
  .ocl-module__see-more {
    background: linear-gradient(to right, #35b7c1 0%, #3fb693 100%);
    color: #fff;
    padding: 44px 0 56px 0;
    text-align: center;
    margin-bottom: 50px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px; }
    .ocl-module__see-more .small {
      font-size: 15px;
      margin-bottom: 10px; }

.breadcrumb {
  padding-left: 0;
  list-style: none;
  opacity: 0.7;
  font-size: 13px;
  line-height: 17px;
  position: relative;
  z-index: 3; }
  .breadcrumb li {
    display: inline-block; }
    .breadcrumb li .divider {
      margin: 0 7px; }

.articles-list-large-top.mt-20 {
  margin-top: -30px; }

.articles-list__articles.mt-60 {
  margin-top: 60px; }

.small-article {
  position: relative;
  margin-bottom: 30px;
  box-shadow: 0 25px 40px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  color: #fff;
  border-radius: 20px;
  overflow: hidden; }
  .small-article > a {
    display: block; }
    .small-article > a:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
      opacity: 0;
      transition: opacity .5s ease-in-out;
      pointer-events: none;
      z-index: 3; }
    @media (min-width: 1025px) {
      .small-article > a:hover:before {
        opacity: 0.4; }
      .small-article > a:hover img {
        transform: scale(1.1); } }
  .small-article.mtm-30 {
    margin-top: -30px; }
  .small-article::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, rgba(26, 27, 28, 0.85) 0%, rgba(26, 27, 28, 0.25) 100%);
    border-radius: 20px;
    pointer-events: none; }
  .small-article img {
    transform-origin: 50% 50%;
    transition: transform .5s ease-in-out; }
  .small-article__text {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 22px 28px;
    z-index: 3; }
    .small-article__text-category {
      font-size: 11px;
      line-height: 13px;
      font-weight: 700;
      padding: 4px 9px;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      border-radius: 5px;
      display: inline-block;
      margin-bottom: 10px;
      text-transform: uppercase; }
    .small-article__text-title {
      font-size: 22px;
      line-height: 30px;
      font-weight: 700;
      margin-bottom: 10px; }
      .small-article__text-title.fs-20 {
        font-size: 20px;
        line-height: 24px; }
      .small-article__text-title.fs-33 {
        font-size: 33px;
        line-height: 41px; }
      .small-article__text-title.fs-35 {
        font-size: 28px;
        line-height: 35px; }
        @media (max-width: 768px) {
          .small-article__text-title.fs-35 {
            font-size: 22px;
            line-height: 30px; } }
    .small-article__text-description {
      font-size: 15px;
      line-height: 23px;
      font-weight: 400;
      margin-bottom: 15px;
      max-width: 750px; }
    .small-article__text-tags {
      display: flex;
      flex-wrap: wrap; }
      .small-article__text-tags-el {
        display: inline-block;
        line-height: 29px;
        padding: 0 8px;
        min-height: 29px;
        margin-right: 5px;
        margin-bottom: 5px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        font-size: 13px;
        font-weight: 500;
        text-align: center; }
        .small-article__text-tags-el::first-letter {
          initial-letter: 1;
          text-transform: uppercase; }

.three-articles {
  margin-bottom: 30px; }
  .three-articles__title {
    font-size: 17px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 21px;
    padding-left: 25px;
    position: relative; }
    .three-articles__title::before {
      content: '';
      width: 15px;
      height: 15px;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      position: absolute;
      left: 0;
      border-radius: 5px;
      margin-top: 1px; }
  .three-articles__big a {
    display: block; }
    @media (min-width: 1025px) {
      .three-articles__big a:hover .three-articles__big-image:before {
        opacity: 0.4; }
      .three-articles__big a:hover .three-articles__big-image img {
        transform: scale(1.1); } }
  .three-articles__big-image {
    border-radius: 15px;
    position: relative;
    margin-bottom: 30px;
    border-radius: 15px;
    overflow: hidden; }
    .three-articles__big-image:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
      opacity: 0;
      transition: opacity .5s ease-in-out;
      pointer-events: none;
      z-index: 3; }
    .three-articles__big-image.mb-20 {
      margin-bottom: 20px; }
    .three-articles__big-image img {
      transition: transform .5s ease-in-out;
      transform-origin: 50% 50%; }
    .three-articles__big-image-category {
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      border-radius: 0 15px 0 15px;
      padding: 6px 15px 2px 15px;
      font-size: 11px;
      line-height: 11px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      z-index: 5; }
  .three-articles__big-title {
    font-size: 21px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 20px; }
  .three-articles__big-description {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 20px; }
  .three-articles__small {
    display: flex;
    align-items: center;
    margin-bottom: 30px; }
    .three-articles__small-image {
      max-width: 90px;
      width: 100%;
      margin-right: 30px;
      border-radius: 10px; }
      .three-articles__small-image img {
        border-radius: 10px; }
    .three-articles__small-title {
      font-style: 17px;
      line-height: 22px;
      font-weight: 700; }
  .three-articles__more {
    padding: 15px;
    font-size: 14px;
    line-height: 21px;
    font-weight: 700;
    width: 100%;
    border: 2px solid #1A1B1C;
    text-align: center;
    border-radius: 10px;
    display: block; }
    .three-articles__more-title {
      text-transform: uppercase; }
  .three-articles__tags {
    display: flex;
    flex-wrap: wrap; }
  .three-articles__tag {
    font-size: 13px;
    font-weight: 400;
    min-height: 29px;
    border: 1px solid #CACACA;
    border-radius: 5px;
    padding: 0 8px;
    display: inline-block;
    line-height: 29px;
    text-align: center;
    margin: 0 5px 5px 0; }
    .three-articles__tag::first-letter {
      initial-letter: 1;
      text-transform: uppercase; }

.pagination {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  padding: 35px 0 10px 0;
  margin-bottom: 40px;
  width: 100%; }
  .pagination .fa-chevron-right {
    padding: 19px 22px;
    border: 1px solid #CACACA;
    border-radius: 5px;
    background-color: #fff; }
  .pagination .fa-chevron-right:before {
    content: "";
    background-image: url("/templates/main/images/svg/right-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: 16px;
    height: 16px; }
  .pagination .fa-chevron-left {
    padding: 19px 22px;
    border: 1px solid #CACACA;
    border-radius: 5px;
    background-color: #fff; }
  .pagination .fa-chevron-left:before {
    content: "";
    background-image: url("/templates/main/images/svg/left-arrow.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    width: 16px;
    height: 16px; }
  .pagination p {
    margin: 0; }
  .pagination p, .pagination span {
    font-size: 13px; }
  .pagination .pagination-left {
    position: absolute;
    left: 16px; }
    .pagination .pagination-left.inactive {
      display: none; }
    .pagination .pagination-left img {
      border: none; }
    .pagination .pagination-left span {
      font-size: 13px; }
      @media (max-width: 625px) {
        .pagination .pagination-left span {
          display: none; } }
  .pagination .pagination-right {
    position: absolute;
    right: 16px; }
    .pagination .pagination-right.inactive {
      display: none; }
    .pagination .pagination-right img {
      border: none; }
    .pagination .pagination-right span {
      font-size: 13px; }
      @media (max-width: 625px) {
        .pagination .pagination-right span {
          display: none; } }
  .pagination img {
    width: 13px;
    border: none; }
  .pagination .fa-chevron-left {
    display: block;
    margin-top: 2px; }
  .pagination .fa-chevron-right {
    display: block;
    margin-top: 2px; }
  .pagination .items {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    font-size: 20px;
    font-weight: 700; }
    .pagination .items .hidden-xs {
      display: flex; }
      @media (max-width: 767.98px) {
        .pagination .items .hidden-xs {
          display: none; } }
    .pagination .items a {
      padding: 0 19px;
      font-size: 20px; }
      @media (max-width: 576px) {
        .pagination .items a {
          padding: 0 10px; } }
    .pagination .items p {
      font-size: 20px;
      width: 50px;
      height: 50px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center; }
  .pagination .inactive {
    background-color: #F16655;
    border-radius: 5px;
    color: #fff;
    font-size: 20px; }
    @media (max-width: 576px) {
      .pagination .inactive {
        margin: 0 10px; } }
  .pagination > .inactive {
    background-color: #f8f8fa;
    color: #5A5A5A;
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    transition: all .35s ease-in-out;
    opacity: .5;
    display: flex;
    align-items: center; }
    @media (max-width: 680px) {
      .pagination > .inactive {
        padding: 10px; } }
    .pagination > .inactive span {
      padding: 0 20px; }
      @media (max-width: 1080px) {
        .pagination > .inactive span {
          display: none; } }
  .pagination > .active {
    color: #000;
    border-radius: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    transition: all .35s ease-in-out;
    display: flex;
    align-items: center; }
    .pagination > .active span {
      padding: 0 20px; }
      @media (max-width: 1080px) {
        .pagination > .active span {
          display: none; } }

.contact {
  margin-bottom: 101px; }
  .contact__wrapper {
    display: flex;
    z-index: 1;
    position: relative; }
    @media (max-width: 1199.98px) {
      .contact__wrapper {
        flex-wrap: wrap; } }
  .contact__top-title {
    font-size: 58px;
    font-weight: 700;
    text-align: center;
    color: #1A1B1C;
    z-index: 1;
    position: relative;
    margin-top: 20px; }
  .contact__small-title {
    font-size: 18px;
    margin-bottom: 30px;
    border-bottom: 2px solid #DDDDDD;
    color: #1A1B1C;
    font-weight: 700;
    padding-bottom: 5px; }
  .contact__name input {
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 22px;
    margin-bottom: 30px; }
  .contact__email input {
    width: 100%;
    border: 1px solid #DDDDDD;
    border-radius: 10px;
    padding: 22px;
    margin-bottom: 30px; }
  .contact__form {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    margin-right: 30px;
    max-width: 930px;
    width: 100%;
    padding: 28px 45px 45px 45px; }
    @media (max-width: 575.98px) {
      .contact__form {
        padding: 15px; } }
    @media (max-width: 1199.98px) {
      .contact__form {
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px;
        margin-right: 0; } }
    @media (max-width: 768px) {
      .contact__form .captcha-wrap {
        margin-bottom: 30px; } }
    @media (max-width: 390px) {
      .contact__form .captcha-wrap {
        display: flex;
        justify-content: center; }
        .contact__form .captcha-wrap .captcha {
          transform: scale(0.9); } }
    .contact__form textarea {
      width: 100%;
      border: 1px solid #DDDDDD;
      border-radius: 10px;
      padding: 22px;
      resize: none;
      min-height: 200px;
      margin-bottom: 30px; }
    .contact__form .button-wrap button {
      width: 100%;
      background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
      border: none;
      border-radius: 10px;
      color: #fff;
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      text-transform: uppercase;
      cursor: pointer; }
      .contact__form .button-wrap button img {
        margin-right: 20px; }
    .contact__form .button-wrap .submit-btn-inside {
      padding: 35px 0; }
  .contact__info {
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    max-width: 450px;
    width: 100%;
    padding: 28px 45px 110px 45px; }
    @media (max-width: 1199.98px) {
      .contact__info {
        max-width: 100%;
        width: 100%;
        margin-bottom: 30px; } }
    @media (max-width: 575.98px) {
      .contact__info {
        padding: 15px; } }
  .contact__text {
    text-align: center;
    font-size: 16px;
    line-height: 23px;
    width: 100%; }
    .contact__text-first {
      padding-top: 57px;
      text-align: center;
      margin-bottom: 21px; }
      .contact__text-first .bold {
        font-weight: 700; }
    .contact__text-second {
      text-align: center;
      margin-bottom: 21px; }
    .contact__text-third {
      text-align: center; }

.rules {
  margin-bottom: 143px;
  padding-top: 40px;
  position: relative; }
  .rules__top-title {
    font-size: 80px;
    line-height: 90px;
    font-weight: 700;
    text-align: center;
    padding-bottom: 10px;
    margin-bottom: 44px;
    display: flex;
    justify-content: center;
    position: relative;
    color: #1A1B1C; }
    @media (max-width: 768px) {
      .rules__top-title {
        font-size: 60px;
        line-height: 70px; } }
    .rules__top-title::after {
      content: '';
      position: absolute;
      bottom: 0;
      height: 7px;
      width: 64px;
      background-color: #FB3636;
      border-radius: 10px; }
  .rules__text {
    color: #000C2D;
    font-size: 20px;
    line-height: 35px;
    padding: 55px 120px 210px 120px;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    background-color: #fff; }
    @media (max-width: 1024.98px) {
      .rules__text {
        padding: 55px 15px; } }
    .rules__text .title {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 35px;
      color: #1A1B1C; }

.single-therapist__breadcrumb {
  position: relative;
  color: #1A1B1C;
  padding-left: 0; }

.single-therapist .person-category-el {
  display: inline-block;
  padding: 3px 6px;
  background-color: #35B7C1;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  margin-bottom: 6px; }
  .single-therapist .person-category-el.green {
    background-color: #3FB693; }

.single-therapist .person-category-see-all {
  display: inline-block;
  font-size: 11px;
  line-height: 23px;
  margin-left: 17px;
  cursor: pointer; }
  @media (max-width: 576px) {
    .single-therapist .person-category-see-all {
      margin-bottom: 10px;
      margin-left: 0; } }
  .single-therapist .person-category-see-all .bold {
    font-weight: 700; }

.single-therapist__person {
  display: flex;
  flex-wrap: wrap;
  border-radius: 20px;
  padding: 45px 45px 20px 45px;
  background-color: #fff;
  position: relative;
  z-index: 3;
  margin-bottom: 30px;
  z-index: 10; }
  @media (max-width: 767.98px) {
    .single-therapist__person {
      padding: 15px; } }
  .single-therapist__person-btns {
    display: flex;
    max-width: 100%;
    flex: 0 0 100%;
    margin-top: 24px;
    border-top: 1px solid rgba(112, 112, 112, 0.1); }
    @media (max-width: 767.98px) {
      .single-therapist__person-btns {
        overflow-y: hidden;
        overflow-x: scroll;
        padding-top: 3px;
        padding-bottom: 15px; } }
    .single-therapist__person-btns-el {
      font-size: 13px;
      letter-spacing: 1px;
      font-weight: 700;
      text-transform: uppercase;
      margin-right: 60px;
      position: relative;
      padding-top: 20px;
      color: rgba(33, 37, 41, 0.4);
      transition: .5s all;
      cursor: pointer; }
      .single-therapist__person-btns-el h2 {
        font-size: 13px; }
      .single-therapist__person-btns-el::before {
        content: '';
        position: absolute;
        width: 100%;
        max-width: 0px;
        top: -3px;
        height: 3px;
        background-color: #1A1B1C;
        transition: .2s all; }
      .single-therapist__person-btns-el:hover::before {
        max-width: 1000px; }
      .single-therapist__person-btns-el.active {
        color: #212529; }
        .single-therapist__person-btns-el.active::before {
          max-width: 1000px; }
  .single-therapist__person-image {
    max-width: 19.999%;
    flex: 0 0 19.999%;
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-start; }
    @media (max-width: 767.98px) {
      .single-therapist__person-image {
        max-width: 100%;
        flex: 0 0 100%; }
        .single-therapist__person-image img {
          margin: 0 auto 30px auto;
          display: block; } }
    @media (max-width: 767.98px) and (max-width: 576px) {
      .single-therapist__person-image img {
        margin: 0 auto 16px auto; } }
    .single-therapist__person-image img {
      border-radius: 10px; }
  .single-therapist__person-info {
    max-width: 74.999%;
    flex: 0 0 74.999%; }
    @media (max-width: 767.98px) {
      .single-therapist__person-info {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap; } }
    .single-therapist__person-info-name {
      font-size: 35px;
      font-weight: 700; }
      @media (max-width: 767.98px) {
        .single-therapist__person-info-name {
          width: 100%;
          max-width: 100%; } }
      @media (max-width: 576px) {
        .single-therapist__person-info-name {
          font-size: 15px;
          text-align: center;
          margin-bottom: 12px;
          order: 1; } }
    .single-therapist__person-info-years {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);
      margin-bottom: 20px; }
      @media (max-width: 767.98px) {
        .single-therapist__person-info-years {
          width: 100%;
          max-width: 100%; } }
      @media (max-width: 576px) {
        .single-therapist__person-info-years {
          order: 2;
          margin: 0;
          padding: 0;
          border: none;
          text-align: center;
          width: 100%; } }
      .single-therapist__person-info-years .practice {
        font-size: 15px;
        line-height: 23px;
        font-weight: 700; }
  @media (max-width: 576px) {
    .single-therapist__person-categories {
      order: 1;
      margin-bottom: 10px;
      width: 100%; } }
  @media (max-width: 576px) {
    .single-therapist__person-categories .single-article__person-category a:nth-of-type(1) {
      display: inline-block; }
    .single-therapist__person-categories .single-article__person-category a:nth-of-type(2) {
      display: inline-block; }
    .single-therapist__person-categories .single-article__person-category a:nth-of-type(3) {
      display: none; }
    .single-therapist__person-categories .single-article__person-category a:nth-of-type(4) {
      display: none; } }

.single-therapist__place {
  background-color: #fff;
  border-radius: 20px;
  padding: 35px 32px 43px 32px;
  font-size: 15px;
  line-height: 22px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
  margin-bottom: 20px; }
  @media (max-width: 767.98px) {
    .single-therapist__place {
      padding: 15px; } }
  .single-therapist__place-el {
    margin-bottom: 51px; }
  .single-therapist__place-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 7px;
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 16px; }
    .single-therapist__place-top-title {
      font-size: 18px;
      font-weight: 700; }
      .single-therapist__place-top-title .blue {
        font-size: 11px;
        line-height: 14px;
        color: #35B7C1;
        text-transform: uppercase; }
    .single-therapist__place-top-map {
      display: flex; }
      .single-therapist__place-top-map-image {
        max-width: 30px;
        flex: 0 0 30px;
        margin-right: 12px; }
      .single-therapist__place-top-map img {
        cursor: pointer; }
  .single-therapist__place-address {
    display: flex;
    margin-bottom: 8px; }
    .single-therapist__place-address-image {
      max-width: 30px;
      flex: 0 0 30px;
      margin-right: 12px; }
  .single-therapist__place-phone {
    display: flex;
    margin-bottom: 8px; }
    .single-therapist__place-phone-image {
      max-width: 30px;
      flex: 0 0 30px;
      margin-right: 12px; }
  .single-therapist__place-email {
    display: flex;
    margin-bottom: 8px; }
    .single-therapist__place-email-image {
      max-width: 30px;
      flex: 0 0 30px;
      margin-right: 12px; }
  .single-therapist__place-www {
    display: flex;
    margin-bottom: 8px; }
    .single-therapist__place-www-image {
      max-width: 30px;
      flex: 0 0 30px;
      margin-right: 12px; }

.single-therapist__reviews {
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  padding: 45px 45px 20px 45px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .single-therapist__reviews {
      padding: 15px; } }
  .single-therapist__reviews-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 11px;
    border-bottom: 1px solid #E1E1E1;
    margin-bottom: 31px; }
    .single-therapist__reviews-top-title {
      font-size: 18px;
      font-weight: 700; }
    .single-therapist__reviews-top-btn {
      background-color: #F16655;
      color: #fff;
      font-size: 13px;
      line-height: 21px;
      font-weight: 700;
      padding: 9px 10px;
      border-radius: 6px;
      cursor: pointer; }
      .single-therapist__reviews-top-btn img {
        margin-right: 5px; }
  .single-therapist__reviews-counter {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    font-weight: 700;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
    .single-therapist__reviews-counter-all {
      margin-right: 50px; }
      @media (max-width: 576px) {
        .single-therapist__reviews-counter-all {
          width: 100%; } }
    .single-therapist__reviews-counter-positiv {
      margin-right: 50px; }
      @media (max-width: 576px) {
        .single-therapist__reviews-counter-positiv {
          width: 100%; } }
    .single-therapist__reviews-counter-neutral {
      margin-right: 50px; }
      @media (max-width: 576px) {
        .single-therapist__reviews-counter-neutral {
          width: 100%; } }
  .single-therapist__reviews-elements-see-more {
    font-size: 15px;
    font-weight: 700;
    background-color: rgba(221, 221, 221, 0.34);
    border-radius: 10px;
    padding: 28px 0;
    max-width: 334px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer; }
    .single-therapist__reviews-elements-see-more.hide {
      display: none; }
  .single-therapist__reviews-elements .vote-hidden-wrapper {
    max-height: 0px;
    overflow: hidden;
    transition: .5s all; }
    .single-therapist__reviews-elements .vote-hidden-wrapper.show {
      max-height: 10000px; }
  .single-therapist__reviews-elements .vote-el {
    margin-bottom: 30px;
    display: flex; }
    .single-therapist__reviews-elements .vote-el__left {
      margin-right: 16px; }
      .single-therapist__reviews-elements .vote-el__left-image {
        padding: 13px;
        border: 1px solid #DDDDDD;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center; }
    .single-therapist__reviews-elements .vote-el__right .name {
      font-size: 16px;
      font-weight: 700;
      margin-right: 29px; }
    .single-therapist__reviews-elements .vote-el__right .date {
      font-size: 13px;
      color: #B7B7B7; }
    .single-therapist__reviews-elements .vote-el__right .text {
      margin-top: 14px;
      font-size: 15px;
      line-height: 23px; }
  .single-therapist__reviews.no-reviews {
    padding: 45px 45px 20px 45px;
    text-align: center; }
    @media (max-width: 767.98px) {
      .single-therapist__reviews.no-reviews {
        padding: 15px; } }
    .single-therapist__reviews.no-reviews .single-therapist__reviews-top-title {
      border-bottom: 1px solid #E1E1E1;
      padding-bottom: 10px;
      margin-bottom: 28px;
      text-align: left; }
    .single-therapist__reviews.no-reviews .no-reviews__image {
      margin-bottom: 32px; }
    .single-therapist__reviews.no-reviews .no-reviews__title {
      margin-bottom: 29px;
      font-size: 19px;
      font-weight: 700; }
    .single-therapist__reviews.no-reviews .no-reviews__description {
      margin-bottom: 37px;
      font-size: 17px; }
    .single-therapist__reviews.no-reviews .no-reviews__btn {
      display: block;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      max-width: 218px;
      padding: 18px 15px;
      color: #fff;
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      border-radius: 10px;
      text-align: left;
      margin: 0 auto 15px auto;
      text-transform: uppercase; }
      .single-therapist__reviews.no-reviews .no-reviews__btn img {
        margin-right: 15px; }

.single-therapist__info {
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  padding: 45px 45px 20px 45px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .single-therapist__info {
      padding: 15px; } }
  .single-therapist__info-title {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 11px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E1E1E1; }

.single-therapist__experience {
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  padding: 45px 45px 20px 45px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  @media (max-width: 767.98px) {
    .single-therapist__experience {
      padding: 15px; } }
  .single-therapist__experience-title {
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 11px;
    margin-bottom: 20px;
    border-bottom: 1px solid #E1E1E1; }
  .single-therapist__experience-el {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 20px; }
    .single-therapist__experience-el-title {
      margin-bottom: 11px;
      text-transform: uppercase; }
      .single-therapist__experience-el-title.another {
        margin-top: 20px; }
    .single-therapist__experience-el-description {
      font-weight: 400;
      font-size: 16px; }

.single-therapist__therapists {
  background-color: #fff;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  padding: 45px 45px 20px 45px;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px; }
  .single-therapist__therapists-person {
    display: flex;
    margin-bottom: 42px; }
    .single-therapist__therapists-person-image {
      margin-right: 11px;
      max-width: 165px;
      width: 100%; }
      .single-therapist__therapists-person-image img {
        border-radius: 10px; }
    .single-therapist__therapists-person-text-name {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px; }
    .single-therapist__therapists-person-text-votes-link {
      display: flex;
      align-items: center; }
    .single-therapist__therapists-person-text-votes {
      font-size: 13px;
      line-height: 23px;
      opacity: 0.5;
      margin-right: 11px; }
    .single-therapist__therapists-person-text-link {
      font-size: 11px;
      line-height: 14px;
      font-weight: 700;
      letter-spacing: 1.2px;
      color: #35B7C1;
      text-transform: uppercase;
      cursor: pointer; }
    .single-therapist__therapists-person-text-category {
      margin-bottom: 9px; }

.single-therapist__box {
  transition: .5s all ease-in-out;
  max-height: 10000px;
  opacity: 1; }
  .single-therapist__box.hide {
    opacity: 0;
    max-height: 0px;
    overflow: hidden; }
    .single-therapist__box.hide.show {
      opacity: 1;
      max-height: 10000px;
      overflow: inherit; }

.single-therapist__hidden-map {
  position: relative;
  z-index: 10000; }
  .single-therapist__hidden-map-container {
    position: fixed;
    background-color: rgba(26, 27, 28, 0.4);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transition: .5s opacity ease-in-out;
    opacity: 0;
    pointer-events: none;
    display: none; }
    .single-therapist__hidden-map-container.show {
      opacity: 1;
      pointer-events: auto;
      display: flex; }
    .single-therapist__hidden-map-container #gmap {
      width: 100%;
      height: 100%;
      border-radius: 20px; }
  .single-therapist__hidden-map-map {
    max-width: 80%;
    max-height: 80%;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 20px;
    position: relative; }
  .single-therapist__hidden-map-close {
    position: absolute;
    right: 18px;
    top: 15px;
    cursor: pointer;
    z-index: 1; }
    .single-therapist__hidden-map-close img {
      border-radius: 50%;
      box-shadow: 0 3px 20px rgba(0, 0, 0, 0.41); }

@media (max-width: 1024.98px) {
  .rating__about {
    padding: 25px 45px 45px 45px; } }

@media (max-width: 767.98px) {
  .rating__about {
    padding: 15px; } }

.rating__about-title {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  padding-top: 38px;
  margin-bottom: 30px; }
  @media (max-width: 1024.98px) {
    .rating__about-title {
      padding-top: 0; } }

.rating__about-subtitle {
  font-size: 16px; }

.rating__about-elements {
  padding-left: 0;
  list-style: none; }
  .rating__about-elements-el {
    position: relative;
    padding-left: 22px; }
    .rating__about-elements-el::after {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      background-color: #F16655;
      width: 7px;
      height: 7px;
      border-radius: 100%; }

.rating__about-more {
  font-size: 16px;
  line-height: 23px; }
  .rating__about-more .red {
    color: #FB3636;
    cursor: pointer; }

.rating__background {
  background-color: #002A43;
  height: 247px;
  position: relative; }
  .rating__background::after {
    content: '';
    background: linear-gradient(to bottom, rgba(103, 111, 134, 0.07) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    width: 100%;
    height: 305px;
    bottom: -305px; }

.rating__left {
  border-radius: 20px;
  background-color: #fff;
  margin-top: -215px;
  padding: 25px 45px 45px 45px; }
  @media (max-width: 767.98px) {
    .rating__left {
      padding: 15px; } }

.rating__person {
  display: flex;
  align-items: center;
  margin-bottom: 45px; }
  @media (max-width: 767.98px) {
    .rating__person {
      flex-wrap: wrap; } }
  .rating__person-image {
    margin-right: 28px; }
    @media (max-width: 767.98px) {
      .rating__person-image {
        flex: 0 0 100%;
        max-width: 100%;
        margin-bottom: 20px; } }
    .rating__person-image.image-padding img {
      padding: 10px; }
    .rating__person-image img {
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1); }
  .rating__person-name {
    font-size: 20px;
    font-weight: 700; }
    @media (max-width: 767.98px) {
      .rating__person-name {
        flex: 0 0 100%;
        max-width: 100%; } }

.rating__info {
  font-size: 16px;
  line-height: 23px;
  padding: 18px 15px;
  background-color: rgba(53, 183, 193, 0.17);
  margin-bottom: 38px;
  border-radius: 20px;
  text-align: center; }

.rating .control-label {
  display: none; }

.rating__form-ratio {
  display: flex;
  align-items: center; }
  @media (max-width: 630px) {
    .rating__form-ratio {
      flex-wrap: wrap; } }

.rating__form-title {
  font-size: 15px;
  font-weight: 700;
  margin-right: 35px; }
  @media (max-width: 630px) {
    .rating__form-title {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 15px; } }

.rating__form-wrapper {
  display: flex;
  justify-content: space-between; }
  @media (max-width: 767.98px) {
    .rating__form-wrapper {
      flex-wrap: wrap; } }

@media (max-width: 767.98px) {
  .rating__form-recaptcha {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px; } }

@media (max-width: 360px) {
  .rating__form-recaptcha {
    display: flex;
    justify-content: center; }
    .rating__form-recaptcha .control-group {
      transform: scale(0.8); } }

.rating__form-submit {
  max-width: 252px;
  width: 100%;
  background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
  border-radius: 10px;
  box-shadow: 0 24px 55px rgba(26, 27, 28, 0.1);
  margin-left: 33px; }
  @media (max-width: 767.98px) {
    .rating__form-submit {
      margin-left: 0;
      padding: 15px 0; } }
  @media (max-width: 360px) {
    .rating__form-submit {
      max-width: 100%; } }
  .rating__form-submit label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer; }
    .rating__form-submit label input {
      background-color: transparent;
      border: none;
      color: #fff;
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      text-transform: uppercase; }

.rating__form-name {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  transition: .5s all; }
  .rating__form-name input {
    width: 100%;
    padding: 38px 25px 17px 25px;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
    font-size: 15px;
    font-weight: 700;
    font-family: "Objectivity", sans-serif; }
    .rating__form-name input:focus {
      outline: none; }
  .rating__form-name.active::after {
    max-width: 2000px; }
  .rating__form-name::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 0px; }
  .rating__form-name label {
    position: absolute;
    left: 25px;
    height: 20px; }

.rating__form-opinion {
  position: relative;
  flex: 1;
  margin-bottom: 25px;
  transition: .5s all; }
  @media (min-width: 460px) {
    .rating__form-opinion .ratio-elements-wrapper {
      display: flex; } }
  .rating__form-opinion textarea {
    width: 100%;
    padding: 38px 25px 17px 25px;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
    resize: none;
    min-height: 200px;
    display: block;
    font-size: 15px;
    font-weight: 700;
    font-family: "Objectivity", sans-serif; }
    .rating__form-opinion textarea:focus {
      outline: none; }

.rating__form .ratio-wrapper {
  display: flex;
  align-items: flex-end;
  position: relative; }
  @media (max-width: 460px) {
    .rating__form .ratio-wrapper {
      display: inline-block;
      padding-left: 35px;
      margin-bottom: 15px; } }
  .rating__form .ratio-wrapper::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 1;
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.16); }
  .rating__form .ratio-wrapper input {
    z-index: 10;
    opacity: 0;
    transform: scale(1.5); }
    .rating__form .ratio-wrapper input:checked {
      outline: none; }
      .rating__form .ratio-wrapper input:checked + label::after {
        content: '';
        position: absolute;
        left: 6px;
        bottom: 6px;
        background-color: #F43434;
        z-index: 2;
        width: 12px;
        height: 12px;
        border-radius: 50%; }

.rating__form #type {
  display: flex;
  align-items: first baseline;
  font-size: 13px;
  position: relative;
  color: #1A1B1C; }

.rating__form .form-input-border::after {
  content: '';
  max-width: 0px;
  width: 100%;
  background-color: #FB3636;
  height: 3px;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: .5s all; }

.rating__form #opinion + label {
  position: absolute;
  left: 25px;
  top: 32px;
  height: 20px; }

.rating__form #nick + label, .rating__form #opinion + label {
  transition: .5s all ease-in-out; }

.rating__form #nick.active + label, .rating__form #opinion.active + label {
  transform: translate(-13%, -80%) scale(0.75); }
  .rating__form #nick.active + label + .form-input-border::after, .rating__form #opinion.active + label + .form-input-border::after {
    max-width: 2000px; }

.rating__form #nick:focus + label, .rating__form #opinion:focus + label {
  transform: translate(-13%, -80%) scale(0.75); }
  .rating__form #nick:focus + label + .form-input-border::after, .rating__form #opinion:focus + label + .form-input-border::after {
    max-width: 2000px; }

.rating__form #type-lbl {
  display: none; }

.rating__form #type0 {
  position: relative;
  padding-top: 33px;
  display: block;
  margin-right: 10px;
  background-color: #fff;
  margin-bottom: 6px;
  margin-left: 6px; }
  .rating__form #type0 + label {
    padding-top: 33px;
    display: flex;
    justify-content: center;
    margin-right: 35px;
    margin-bottom: 0; }
    @media (max-width: 460px) {
      .rating__form #type0 + label {
        width: 90px;
        padding-top: 10px; } }
    .rating__form #type0 + label::before {
      content: '';
      background-image: url("/templates/main/images/svg/ok-green.svg");
      background-size: cover;
      background-position: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      margin-left: -13px; }

.rating__form #type1 {
  position: relative;
  padding-top: 33px;
  margin-right: 10px;
  margin-bottom: 6px;
  margin-left: 6px; }
  .rating__form #type1 + label {
    padding-top: 33px;
    display: flex;
    justify-content: center;
    margin-right: 35px;
    margin-bottom: 0; }
    @media (max-width: 460px) {
      .rating__form #type1 + label {
        width: 90px;
        padding-top: 10px; } }
    .rating__form #type1 + label::before {
      content: '';
      background-image: url("/templates/main/images/svg/smile.svg");
      background-size: cover;
      background-position: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      margin-left: -13px; }

.rating__form #type2 {
  position: relative;
  padding-top: 33px;
  margin-right: 10px;
  margin-bottom: 6px;
  margin-left: 6px; }
  .rating__form #type2 + label {
    padding-top: 33px;
    display: flex;
    justify-content: center;
    margin-right: 35px;
    margin-bottom: 0; }
    @media (max-width: 460px) {
      .rating__form #type2 + label {
        width: 90px;
        padding-top: 10px; } }
    .rating__form #type2 + label::before {
      content: '';
      background-image: url("/templates/main/images/svg/negative-red.svg");
      background-size: cover;
      background-position: center;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      margin-left: -13px; }

.rating__rules {
  display: flex;
  align-items: flex-start;
  position: relative;
  font-size: 16px;
  line-height: 23px;
  margin-bottom: 40px; }
  .rating__rules .star {
    display: none; }
  .rating__rules input {
    margin-right: 12px;
    opacity: 0; }
    .rating__rules input:checked + label::after {
      opacity: 1; }
    .rating__rules input + label {
      padding-left: 12px; }
      .rating__rules input + label::after {
        content: '';
        opacity: 0;
        position: absolute;
        left: 6px;
        top: 8px;
        width: 14px;
        height: 10px;
        background-image: url("/templates/main/images/accept-rules.png");
        background-position: center;
        background-repeat: no-repeat;
        transition: .5s all ease-in-out; }
      .rating__rules input + label::before {
        content: '';
        width: 25px;
        height: 25px;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 3px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
        z-index: 1; }

[data-dark-mode="false"] .shops__banner {
  box-shadow: 0 33px 99px rgba(0, 0, 0, 0.16); }

[data-dark-mode="false"] .shops__item {
  background-color: #fff;
  box-shadow: 0 33px 99px rgba(0, 0, 0, 0.16); }
  [data-dark-mode="false"] .shops__item .light {
    display: block; }
  [data-dark-mode="false"] .shops__item .dark {
    display: none; }

[data-dark-mode="true"] .shops__banner {
  background-color: var(--colorBgDarker); }

[data-dark-mode="true"] .shops__item .light {
  display: none; }

[data-dark-mode="true"] .shops__item .dark {
  display: block; }

.shops__banner {
  background-color: #fff;
  color: var(--colorFont);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-bottom: 45px;
  background-image: url("/templates/main/images/shops/papers.png");
  background-repeat: no-repeat;
  border-radius: 20px;
  background-position: bottom -160px center;
  background-size: 400px;
  padding: 30px 15px 150px; }
  @media (min-width: 1025px) {
    .shops__banner {
      padding: 60px 15px;
      background-position: bottom -255px right -50px;
      background-size: auto; } }
  @media (min-width: 1280px) {
    .shops__banner {
      background-position: bottom -240px right 0px; } }
  @media (min-width: 1440px) {
    .shops__banner {
      background-position: bottom -200px right 0; } }
  .shops__banner-text {
    font-size: 17px;
    line-height: 25px;
    font-weight: 500;
    text-align: center; }
    @media (min-width: 1025px) {
      .shops__banner-text {
        text-align: left;
        max-width: 750px;
        margin-left: 15px; } }
    @media (min-width: 1280px) {
      .shops__banner-text {
        margin-left: 45px; } }
    @media (min-width: 1440px) {
      .shops__banner-text {
        margin-left: 75px; } }
    .shops__banner-text a {
      color: #FB3636; }
    .shops__banner-text .bold {
      font-weight: 700; }
    .shops__banner-text .contact {
      display: block;
      margin: 0; }

.shops__top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: 45px;
  padding-bottom: 3px;
  position: relative; }
  .shops__top:after {
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    border-bottom: 3px solid #FB3636;
    bottom: -3px; }

.shops__select {
  margin-bottom: 10px;
  padding: 0 15px; }
  .shops__select ul {
    margin: 0;
    padding: 0; }

.shops__header {
  margin: 0;
  padding: 0;
  font-size: 35px;
  line-height: 43px;
  font-weight: 700;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  padding: 0 15px;
  margin-bottom: 10px; }

.shops__item {
  padding: 30px;
  background-color: var(--colorBgDarker);
  border-radius: 20px;
  height: 100%; }
  .shops__item > * {
    display: block;
    font-size: 15px;
    line-height: 25px; }
  .shops__item-col {
    margin-bottom: 30px; }
  .shops__item-name {
    font-size: 18px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 16px; }
  .shops__item-address, .shops__item-phone, .shops__item-mail {
    margin-bottom: 3px; }
  .shops__item-address, .shops__item-phone, .shops__item-mail, .shops__item-www {
    display: flex;
    flex-wrap: wrap;
    font-weight: 500;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start; }
    .shops__item-address img, .shops__item-phone img, .shops__item-mail img, .shops__item-www img {
      margin-right: 8px;
      margin-top: 4px; }
    .shops__item-address span, .shops__item-phone span, .shops__item-mail span, .shops__item-www span {
      max-width: calc(100% - 22px); }
  .shops__item-mail, .shops__item-www {
    color: #FB3636; }
  .shops__item-description {
    margin-top: 12px;
    border-top: 1px solid #EBEBEB;
    padding-top: 14px;
    display: flex;
    flex-wrap: wrap; }
    .shops__item-description img {
      margin-right: 8px;
      margin-top: 6px; }
    .shops__item-description span {
      max-width: calc(100% - 22px); }

.btn-border {
  background: linear-gradient(to bottom right, #3FB693 0%, #35B7C1 100%);
  height: 80px;
  width: 100%;
  padding: 3px;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  font-size: 15px;
  font-weight: 700; }
  .btn-border__content {
    border-radius: 17px;
    overflow: hidden;
    background-color: transparent;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    position: relative; }
    .btn-border__content-text {
      text-transform: uppercase;
      position: relative;
      z-index: 3;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      align-items: center; }
      .btn-border__content-text img, .btn-border__content-text svg {
        margin-left: 15px; }
    .btn-border__content:after {
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 100%;
      background: linear-gradient(to bottom right, #3FB693 0%, #35B7C1 100%);
      position: absolute;
      display: block;
      pointer-events: none;
      transition: all .2s ease-in-out; }
    .btn-border__content-text {
      position: relative;
      z-index: 3; }
  .btn-border:hover .btn-border__content:after {
    right: 0; }

.form-com_avtnewsletter .top-menu__link {
  color: #fff; }

.form-com_avtnewsletter header {
  z-index: 10; }
  .form-com_avtnewsletter header.header.header-subpages .header__logo svg {
    max-width: 266px; }
    .form-com_avtnewsletter header.header.header-subpages .header__logo svg #Path_1479 {
      fill: #fff; }
    .form-com_avtnewsletter header.header.header-subpages .header__logo svg #Path_4 {
      stroke: #fff; }
    .form-com_avtnewsletter header.header.header-subpages .header__logo svg #Path_3 {
      stroke: #fff; }
  .form-com_avtnewsletter header .header__burger span {
    background-color: #fff; }
  .form-com_avtnewsletter header .header__search svg circle, .form-com_avtnewsletter header .header__search svg path {
    stroke: #fff; }

.form-com_avtnewsletter .header__new.header.header-subpages .header__menu > ul > li > a {
  color: #fff; }

.header {
  background-color: #002B44;
  transition: background-color .1s ease-in-out;
  padding-top: 45px; }
  @media (min-width: 768px) {
    .header {
      padding-bottom: 30px;
      padding-top: 45px; } }
  .header__new {
    background-color: #fff;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    padding: 0px; }
    .header__new.no-margin {
      margin-bottom: 0px; }
    @media (min-width: 768px) {
      .header__new {
        padding: 15px 0; } }
    @media (min-width: 1025px) {
      .header__new {
        padding: 0 0 24px 0; } }
    .header__new.header.header-article {
      background-color: #fff; }
    .header__new::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 305px;
      background: linear-gradient(to bottom, #000c2d 0%, transparent 80%);
      opacity: 0.07;
      pointer-events: none; }
    .header__new.header.header-subpages .header__menu > ul > li > a {
      color: #1A1B1C; }
    .header__new.header.header-subpages .header__menu > ul li.active a, .header__new.header.header-subpages .header__menu > ul li.current a, .header__new.header.header-subpages .header__menu > ul li.alias-parent-active a {
      color: #1A1B1C; }
    .header__new.header.header-subpages .header__menu > ul li.active.parent a, .header__new.header.header-subpages .header__menu > ul li.current.parent a, .header__new.header.header-subpages .header__menu > ul li.alias-parent-active.parent a {
      color: #1A1B1C; }
    .header__new.header.header-subpages .header__menu > ul li.active::after, .header__new.header.header-subpages .header__menu > ul li.current::after, .header__new.header.header-subpages .header__menu > ul li.alias-parent-active::after {
      background-color: #1A1B1C; }
    .header__new.header.header-subpages .header__logo svg {
      max-width: 266px; }
      .header__new.header.header-subpages .header__logo svg #Path_1479 {
        fill: #1A1B1C; }
      .header__new.header.header-subpages .header__logo svg #Path_4 {
        stroke: #1A1B1C; }
      .header__new.header.header-subpages .header__logo svg #Path_3 {
        stroke: #1A1B1C; }
    .header__new .header__burger span {
      background-color: #1A1B1C; }
    .header__new .header__search svg circle, .header__new .header__search svg path {
      stroke: #1A1B1C; }
  .header.header-subpages.header--new {
    padding-top: 30px; }
    @media (min-width: 1025px) {
      .header.header-subpages.header--new {
        padding-top: 0; } }
    .header.header-subpages.header--new .header__wrap {
      height: auto; }
      .header.header-subpages.header--new .header__wrap:after, .header.header-subpages.header--new .header__wrap:before {
        display: none; }
    @media (max-width: 1024.98px) {
      .header.header-subpages.header--new .header__search {
        width: 80px;
        height: 80px;
        background-color: #294868;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        max-width: none;
        margin-right: 15px;
        border-radius: 18px; } }
    @media (max-width: 767.98px) {
      .header.header-subpages.header--new .header__search {
        width: 60px;
        height: 60px; } }
    @media (max-width: 575.98px) {
      .header.header-subpages.header--new .header__search {
        display: none; } }
    @media (max-width: 1024.98px) {
      .header.header-subpages.header--new .header__burger {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        background-color: #294868;
        border-radius: 18px;
        margin-right: 15px; }
        .header.header-subpages.header--new .header__burger-wrap {
          cursor: pointer;
          width: 20px;
          height: 14px;
          position: relative; } }
    @media (max-width: 767.98px) {
      .header.header-subpages.header--new .header__burger {
        width: 60px;
        height: 60px; } }
    .header.header-subpages.header--new .header__logo {
      max-width: none; }
      @media (min-width: 1025px) {
        .header.header-subpages.header--new .header__logo {
          display: none; } }
      @media (min-width: 1410px) {
        .header.header-subpages.header--new .header__logo {
          display: block; } }
      @media (max-width: 459.98px) {
        .header.header-subpages.header--new .header__logo {
          max-width: 210px; } }
    .header.header-subpages.header--new .header__menu {
      display: none;
      margin-left: 0;
      flex-grow: 1;
      margin-right: 30px; }
      @media (min-width: 1025px) {
        .header.header-subpages.header--new .header__menu {
          display: block; } }
      @media (min-width: 1410px) {
        .header.header-subpages.header--new .header__menu {
          margin-left: auto;
          flex-grow: unset;
          margin-right: 0; } }
      @media (max-width: 1365.98px) {
        .header.header-subpages.header--new .header__menu #burger-menu__menu--main {
          justify-content: space-between; } }
      .header.header-subpages.header--new .header__menu #burger-menu__menu--main li {
        display: block; }
        @media (max-width: 1365.98px) {
          .header.header-subpages.header--new .header__menu #burger-menu__menu--main li {
            margin: 0; } }
      .header.header-subpages.header--new .header__menu #burger-menu__menu--main .item-1155 {
        display: none; }
  .header .header__menu .hp-menu > li.item-1055 > a:after, .header .header__menu .hp-menu > li.item-101 > a:after, .header.header-subpages .header__menu .hp-menu > li.item-1055 > a:after, .header.header-subpages .header__menu .hp-menu > li.item-101 > a:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 5;
    top: calc(100% + 20px);
    left: calc((100% - 275px) / 2);
    height: 82px;
    width: 275px;
    pointer-events: none;
    background-color: transparent; }
  .header .header__menu .hp-menu > li.item-1055:hover > a:after, .header .header__menu .hp-menu > li.item-101:hover > a:after, .header.header-subpages .header__menu .hp-menu > li.item-1055:hover > a:after, .header.header-subpages .header__menu .hp-menu > li.item-101:hover > a:after {
    pointer-events: all; }
  @media (max-width: 767.98px) {
    .header__container {
      padding: 0; } }
  .header__list {
    display: block;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    background-color: #fff;
    left: 0;
    width: 100%;
    z-index: 20;
    padding-bottom: 45px;
    top: 80px;
    box-shadow: 0 130px 99px rgba(0, 0, 0, 0.2);
    position: absolute;
    pointer-events: none; }
    @media (min-width: 768px) {
      .header__list {
        top: 153px; } }
    .header__list-container {
      position: relative; }
    .header__list ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin: 0;
      padding: 0;
      position: static;
      width: 100%;
      justify-content: space-around; }
      .header__list ul .item-809 {
        display: block;
        border: 1px solid #fff;
        padding: 0 10px;
        border-radius: 7px; }
      @media (min-width: 1025px) {
        .header__list ul .item-817, .header__list ul .item-809 {
          display: none; } }
      .header__list ul > li {
        font-weight: 700;
        margin-bottom: 28px;
        width: 100%;
        text-align: center;
        font-size: 16px; }
        @media (min-width: 768px) {
          .header__list ul > li {
            font-size: 20px; } }
        @media (min-width: 1025px) {
          .header__list ul > li {
            text-align: left; } }
        @media (min-width: 1280px) {
          .header__list ul > li {
            position: absolute; } }
        @media (min-width: 1025px) {
          .header__list ul > li:hover > a {
            color: #fff; }
            .header__list ul > li:hover > a:after {
              right: 0; } }
        .header__list ul > li:last-child {
          margin-bottom: 0; }
        .header__list ul > li > a {
          transition: color .2s ease-in-out; }
        .header__list ul > li > ul {
          display: none; }
      .header__list ul .item-804 {
        position: static;
        top: 0;
        bottom: 0;
        justify-content: center; }
        @media (min-width: 1025px) {
          .header__list ul .item-804 {
            width: 50%; } }
        @media (min-width: 1280px) {
          .header__list ul .item-804 {
            width: calc(50% - 130px);
            margin-left: 260px; } }
      .header__list ul .item-805 {
        position: static;
        top: 0;
        bottom: 0;
        justify-content: center; }
        @media (min-width: 1025px) {
          .header__list ul .item-805 {
            width: 50%; } }
        @media (min-width: 1280px) {
          .header__list ul .item-805 {
            width: calc(50% - 130px);
            justify-content: flex-end; } }
      .header__list ul .item-804, .header__list ul .item-805 {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start; }
        @media (min-width: 1025px) {
          .header__list ul .item-804 > a, .header__list ul .item-805 > a {
            display: block;
            width: 100%;
            max-width: 450px;
            font-size: 13px;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: 0.15em;
            padding-bottom: 13px;
            border-bottom: 2px solid #35B7C1;
            margin-bottom: 20px; } }
        .header__list ul .item-804 ul, .header__list ul .item-805 ul {
          flex-wrap: wrap;
          width: 100%;
          max-width: 450px;
          columns: 2; }
          @media (min-width: 1025px) {
            .header__list ul .item-804 ul, .header__list ul .item-805 ul {
              display: block; } }
          .header__list ul .item-804 ul li, .header__list ul .item-805 ul li {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 5px;
            position: static; }
      .header__list ul .item-807,
      .header__list ul .item-803,
      .header__list ul .item-808,
      .header__list ul .item-802,
      .header__list ul .item-814 {
        height: 31px;
        left: 0;
        text-align: center; }
        @media (min-width: 1025px) {
          .header__list ul .item-807,
          .header__list ul .item-803,
          .header__list ul .item-808,
          .header__list ul .item-802,
          .header__list ul .item-814 {
            width: auto;
            text-align: left;
            max-width: none; } }
        @media (min-width: 1280px) {
          .header__list ul .item-807,
          .header__list ul .item-803,
          .header__list ul .item-808,
          .header__list ul .item-802,
          .header__list ul .item-814 {
            width: 100%;
            max-width: 260px; } }
      .header__list ul .item-807 {
        top: 0; }
      .header__list ul .item-808 {
        top: 61px; }
      .header__list ul .item-802 {
        top: 122px; }
      @media (min-width: 1025px) {
        .header__list ul .item-814 {
          display: none; } }
  .header__logo {
    max-width: 185px; }
    @media (min-width: 768px) {
      .header__logo {
        max-width: none; } }
    .header__logo svg {
      max-width: 100%; }
    @media (max-width: 575.98px) {
      .header__logo-text {
        display: none; } }
  .header__wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 15; }
    @media (max-width: 767.98px) {
      .header__wrap {
        height: 80px; } }
    .header__wrap:before {
      content: "";
      height: 80px;
      width: 1px;
      top: 0;
      right: 80px;
      background-color: rgba(255, 255, 255, 0.1);
      display: block;
      position: absolute;
      z-index: 3; }
      @media (min-width: 768px) {
        .header__wrap:before {
          display: none; } }
    .header__wrap:after {
      content: "";
      height: 1px;
      top: 80px;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.1);
      display: block;
      position: absolute;
      z-index: 3; }
      @media (min-width: 768px) {
        .header__wrap:after {
          display: none; } }
  .header__burger {
    cursor: pointer;
    width: 20px;
    height: 14px;
    position: relative;
    order: 2;
    margin-right: 30px; }
    @media (min-width: 768px) {
      .header__burger {
        margin-right: 0; } }
    .header__burger span {
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 4px;
      position: absolute;
      background-color: #fff;
      left: 0;
      transform-origin: 50% 50%;
      transition: all .2s ease-in-out; }
      .header__burger span:first-child {
        top: 0;
        bottom: auto; }
      .header__burger span:nth-child(2) {
        top: 0;
        bottom: 0;
        margin: auto; }
      .header__burger span:nth-child(3) {
        top: 0;
        bottom: 0;
        margin: auto; }
      .header__burger span:last-child {
        bottom: 0;
        top: auto; }
  .header__menu {
    margin-left: auto;
    justify-self: flex-end;
    display: none; }
    @media (min-width: 1410px) {
      .header__menu {
        display: block; } }
    .header__menu > ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      color: #fff;
      list-style: none;
      margin: 0;
      padding: 0;
      transition: color .2s ease-in-out;
      position: relative; }
      .header__menu > ul .item-1020 {
        display: none; }
      .header__menu > ul .item-1317 {
        display: none; }
      .header__menu > ul > li {
        margin-right: 30px;
        font-size: 16px;
        font-weight: 700;
        transition: all .2s ease-in-out;
        display: none;
        padding: 20px 0;
        position: relative;
        z-index: 3; }
        @media (min-width: 1200px) {
          .header__menu > ul > li {
            display: block; } }
        .header__menu > ul > li > .header-module {
          z-index: 3; }
        .header__menu > ul > li.parent > a {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          align-content: center; }
          .header__menu > ul > li.parent > a:after {
            content: "";
            display: inline-block;
            background-image: url("/templates/main/images/svg/triangle.svg");
            width: 9px;
            height: 7px;
            background-position: center;
            background-repeat: no-repeat;
            background-color: transparent;
            margin-left: 8px; }
        .header__menu > ul > li.parent:after {
          content: "";
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba(26, 27, 28, 0.45);
          opacity: 0;
          pointer-events: none;
          z-index: 0;
          transition: opacity .4s; }
        .header__menu > ul > li.current, .header__menu > ul > li.active {
          color: #FB3636; }
          .header__menu > ul > li.current > a:before, .header__menu > ul > li.active > a:before {
            right: 0; }
        .header__menu > ul > li > .sub-menu {
          position: absolute;
          top: 100%;
          opacity: 0;
          pointer-events: none;
          background-color: #fff;
          width: 275px;
          border-radius: 15px;
          background-color: #223D59;
          transition: opacity .2s ease-in-out;
          box-shadow: 0 60px 99px rgba(0, 0, 0, 0.16);
          z-index: 99;
          left: calc((100% - 275px) / 2); }
          .header__menu > ul > li > .sub-menu:after {
            content: "";
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
            height: 3px;
            margin: auto; }
          .header__menu > ul > li > .sub-menu .sub-menu__header {
            min-height: 60px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            align-content: center;
            pointer-events: none; }
            .header__menu > ul > li > .sub-menu .sub-menu__header:before {
              content: "";
              background-position: center;
              background-repeat: no-repeat;
              margin-right: 12px; }
            .header__menu > ul > li > .sub-menu .sub-menu__header:after {
              content: "";
              font-size: 18px;
              font-weight: 400; }
          .header__menu > ul > li > .sub-menu ul {
            margin: 0;
            padding: 0;
            list-style: none; }
          .header__menu > ul > li > .sub-menu ul > li {
            margin: 0;
            padding: 0;
            text-align: center;
            width: 100%;
            color: #1A1B1C;
            background-color: #fff; }
            .header__menu > ul > li > .sub-menu ul > li:first-child {
              padding-top: 20px; }
            .header__menu > ul > li > .sub-menu ul > li:last-child {
              padding-bottom: 20px;
              border-bottom-left-radius: 15px;
              border-bottom-right-radius: 15px; }
            .header__menu > ul > li > .sub-menu ul > li > a {
              display: block;
              min-height: 40px;
              line-height: 40px;
              font-size: 16px;
              font-weight: 500; }
        .header__menu > ul > li.current > a {
          color: #fff; }
          .header__menu > ul > li.current > a:after {
            right: 0; }
  .header .item-1055 .sub-menu > ul > li:nth-last-child(2) {
    padding-bottom: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px; }
  .header .item-1055 .sub-menu:after {
    width: 115px; }
  .header .item-1055 .sub-menu .sub-menu__header:before {
    background-image: url("/templates/main/images/svg/knowledge.svg");
    width: 28px;
    height: 24px; }
  .header .item-1055 .sub-menu .sub-menu__header:after {
    content: "Wiedza"; }
  .header .item-101 .sub-menu:after {
    width: 175px; }
  .header .item-101 .sub-menu .sub-menu__header:before {
    background-image: url("/templates/main/images/svg/therapist.svg");
    width: 34px;
    height: 35px; }
  .header .item-101 .sub-menu .sub-menu__header:after {
    content: "Terapeuci"; }
  .header .logo-sticky {
    display: none; }
  .header.header-subpages .header__logo img, .header.header-subpages .header__logo svg, .header.header-subpages .header__logo--subpages img, .header.header-subpages .header__logo--subpages svg {
    max-width: 295px; }
    @media (max-width: 767.98px) {
      .header.header-subpages .header__logo img, .header.header-subpages .header__logo svg, .header.header-subpages .header__logo--subpages img, .header.header-subpages .header__logo--subpages svg {
        margin-left: 15px; } }
  .header.header-subpages .header__logo > a, .header.header-subpages .header__logo--subpages > a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center; }
  .header.header-subpages .header__logo .header__logo-text, .header.header-subpages .header__logo--subpages .header__logo-text {
    color: #fff;
    margin-left: 30px;
    position: relative;
    padding-top: 2px; }
    .header.header-subpages .header__logo .header__logo-text:after, .header.header-subpages .header__logo--subpages .header__logo-text:after {
      content: "";
      height: 22px;
      left: -15px;
      width: 2px;
      background-color: #fff;
      opacity: 0.5;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; }
  .header.header-subpages .header__menu > ul > li > a {
    transition: color .2s ease-in-out;
    display: inline;
    color: #fff;
    position: relative;
    z-index: 3; }
    .header.header-subpages .header__menu > ul > li > a:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 100%;
      height: 1px;
      background-color: #1A1B1C;
      margin: 0;
      padding: 0;
      background-image: none;
      width: auto;
      transition: background-color .2s ease-in-out; }
  .header.header-subpages .header__menu > ul li.parent a:after {
    display: none; }
  .header.header-subpages .header__menu > ul li.alias-parent-active a,
  .header.header-subpages .header__menu > ul li.current a,
  .header.header-subpages .header__menu > ul li.active a,
  .header.header-subpages .header__menu > ul li.active.parent a,
  .header.header-subpages .header__menu > ul li.current.parent a {
    display: inline;
    color: #fff;
    position: relative; }
    .header.header-subpages .header__menu > ul li.alias-parent-active a:after,
    .header.header-subpages .header__menu > ul li.current a:after,
    .header.header-subpages .header__menu > ul li.active a:after,
    .header.header-subpages .header__menu > ul li.active.parent a:after,
    .header.header-subpages .header__menu > ul li.current.parent a:after {
      content: "";
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background-color: #1A1B1C;
      margin: 0;
      padding: 0;
      background-image: none;
      width: auto; }

.header .item-1155 {
  display: none; }

.header.header-subpages .header__search {
  margin-right: 30px;
  max-width: 18px;
  cursor: pointer;
  margin-left: auto; }
  @media (max-width: 1024.98px) {
    .header.header-subpages .header__search {
      margin-right: 60px; } }
  @media (max-width: 450px) {
    .header.header-subpages .header__search {
      margin-right: 15px; } }
  @media (max-width: 370px) {
    .header.header-subpages .header__search {
      display: none; } }
  @media (min-width: 1410px) {
    .header.header-subpages .header__search {
      margin-left: 0; } }

.header.header-subpages .header__menu > #burger-menu__menu--main > li {
  position: relative; }
  .header.header-subpages .header__menu > #burger-menu__menu--main > li:hover a {
    color: #FB3636; }
    .header.header-subpages .header__menu > #burger-menu__menu--main > li:hover a:after {
      background-color: #FB3636; }
  .header.header-subpages .header__menu > #burger-menu__menu--main > li.current:hover a, .header.header-subpages .header__menu > #burger-menu__menu--main > li.active:hover a {
    text-decoration: none; }
  .header.header-subpages .header__menu > #burger-menu__menu--main > li:before {
    content: "";
    position: absolute;
    left: -5px;
    right: -5px;
    top: calc(100% - 2px);
    height: 4px;
    background-color: #FB3636;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    z-index: 9;
    pointer-events: none; }
  .header.header-subpages .header__menu > #burger-menu__menu--main > li.item-1169:before {
    display: none; }
  .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    width: 440px;
    margin: auto;
    top: 100%;
    left: calc((100% - 440px) / 2);
    background-color: #fff;
    transition: opacity .2s ease-in-out;
    color: #1A1B1C;
    font-size: 16px;
    margin: 0;
    padding: 35px 42px 30px;
    box-shadow: 0 60px 99px rgba(0, 0, 0, 0.3);
    border-radius: 7px;
    display: flex;
    flex-wrap: wrap; }
    .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul {
      list-style: none;
      order: 1;
      margin: 0 0 15px;
      padding: 0; }
      .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li {
        color: #1A1B1C;
        margin-bottom: 5px;
        font-weight: 500; }
        .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li a {
          color: #1A1B1C; }
        .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li ul {
          display: none; }
          .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li ul a {
            color: #1A1B1C; }
        .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li.current, .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li.current > a, .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li.active, .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module > ul > li.active > a {
          color: #FB3636; }
    .header.header-subpages .header__menu > #burger-menu__menu--main > li > .header-module .header-module__wrap {
      order: 2; }

.footer {
  background-color: #1A1B1C;
  color: #fff;
  padding: 70px 0 50px; }
  .footer__menu {
    width: 100%; }
    @media (min-width: 1200px) {
      .footer__menu {
        max-width: calc(100% - 465px); } }
    .footer__menu > ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      max-width: 750px; }
      .footer__menu > ul > li {
        margin-bottom: 30px;
        padding-right: 15px; }
        .footer__menu > ul > li > span, .footer__menu > ul > li > a {
          display: block;
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          margin-bottom: 15px; }
        .footer__menu > ul > li > ul {
          margin: 0;
          padding: 0;
          list-style: none; }
          .footer__menu > ul > li > ul > li {
            font-size: 15px;
            opacity: 0.5;
            margin-bottom: 5px; }
  .footer__info img {
    max-width: 190px;
    margin-bottom: 15px; }
  .footer__info-text {
    font-size: 15px;
    opacity: 0.5; }
  .footer__top {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 38px;
    align-items: flex-start;
    align-content: flex-start; }
  .footer__logo {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px; }
    @media (min-width: 1025px) {
      .footer__logo {
        margin-bottom: 0; } }
  .footer__social {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    position: relative;
    width: 100%;
    margin-top: 20px; }
    @media (min-width: 576px) {
      .footer__social {
        padding-left: 25px;
        margin-left: 25px;
        width: auto;
        margin-top: 0; } }
    @media (min-width: 576px) {
      .footer__social:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 31px;
        background-color: #fff;
        opacity: 0.2;
        display: block;
        top: 0;
        left: 0;
        right: auto;
        bottom: 0;
        margin: auto; } }
    .footer__social a {
      opacity: 0.5; }
  .footer__newsletter {
    width: 100%;
    max-width: 465px;
    margin-bottom: 30px; }
    .footer__newsletter-title {
      font-size: 14px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 30px; }
      @media (min-width: 576px) {
        .footer__newsletter-title {
          margin-bottom: 10px; } }
      .footer__newsletter-title span {
        color: #fff;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: 0.05em;
        margin-bottom: 10px;
        margin-right: 25px;
        display: block; }
        @media (min-width: 576px) {
          .footer__newsletter-title span {
            display: inline-block; } }
    .footer__newsletter-input {
      height: 64px;
      position: relative;
      left: -2px;
      top: -2px;
      color: #fff;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 15px;
      padding-left: 35px;
      width: 100%; }
      @media (min-width: 576px) {
        .footer__newsletter-input {
          width: calc(100% - 141px); } }
      .footer__newsletter-input::placeholder {
        color: #fff; }
    .footer__newsletter-submit {
      height: 60px;
      background-color: transparent;
      top: 0;
      right: 0;
      border: none;
      outline: none;
      box-shadow: none;
      color: #fff;
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.1em;
      cursor: pointer;
      width: 100%;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 23px; }
      @media (min-width: 576px) {
        .footer__newsletter-submit {
          border-radius: 0;
          border: none;
          border-left: 1px solid rgba(255, 255, 255, 0.2);
          position: absolute;
          width: 145px; } }
  @media (max-width: 575.98px) {
    .footer__input-wrap {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 23px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      height: 60px;
      margin-bottom: 20px; } }
  @media (min-width: 576px) {
    .footer__form-wrap {
      width: 100%;
      position: relative;
      overflow: hidden;
      border-radius: 23px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      height: 60px; } }
  .footer__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
  .footer__publisher {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    @media (min-width: 1025px) {
      .footer__publisher {
        max-width: 465px; } }
    .footer__publisher-title {
      display: block;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      margin-bottom: 15px;
      width: 100%; }
    .footer__publisher-left {
      margin-right: 100px; }
    .footer__publisher-left, .footer__publisher-right {
      font-size: 15px;
      opacity: 0.5;
      margin-bottom: 5px; }

.burger-open {
  overflow: hidden; }
  @media (min-width: 1200px) {
    .burger-open {
      padding-right: 17px; } }

.burger-menu {
  position: fixed;
  left: auto;
  right: 0;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  width: 100%;
  z-index: 9999;
  pointer-events: none;
  height: 100%;
  overflow: hidden; }
  .burger-menu .main__select-wrap {
    margin: 0 auto 30px;
    color: #fff; }
  @media (max-width: 767.98px) {
    .burger-menu.main .burger-menu__logo {
      margin-top: 60px; } }
  .burger-menu .item-1244 a {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 65px;
    border-radius: 16px;
    text-transform: uppercase;
    font-size: 15px;
    width: 100%;
    max-width: 330px;
    margin: 0 auto;
    background: linear-gradient(45deg, #3fb693 0, #35b7c1 100%);
    font-weight: 700;
    letter-spacing: 0.075em;
    margin-bottom: 30px; }
  .burger-menu .item-1155 {
    display: none; }
  .burger-menu__wrapper {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.1);
    z-index: 3;
    height: 100%;
    transform: translateX(100%);
    opacity: 0;
    transition: transform .2s ease-in-out, opacity .2s ease-in-out;
    right: 0;
    left: auto;
    margin-left: auto;
    width: 100%;
    max-width: 360px;
    padding: 55px 15px 50px; }
    @media (min-width: 768px) {
      .burger-menu__wrapper {
        max-width: 465px;
        padding: 25px 30px 50px; } }
  .burger-menu__wrap {
    overflow: auto;
    height: 100%; }
  .burger-menu__logo a {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 25px;
    border-bottom: 2px solid #FB3636; }
  .burger-menu__logo img {
    display: block; }
  .burger-menu__logo-text {
    margin-left: 30px;
    margin-left: 30px;
    position: relative; }
    .burger-menu__logo-text:after {
      content: "";
      height: 22px;
      left: -15px;
      width: 2px;
      background-color: #1A1B1C;
      opacity: 0.5;
      position: absolute; }
  .burger-menu.open {
    opacity: 1;
    top: 0;
    pointer-events: all; }
    .burger-menu.open .burger-menu__container {
      height: auto;
      padding-bottom: 30px; }
    .burger-menu.open .burger-menu__burger span {
      background-color: #fff; }
      .burger-menu.open .burger-menu__burger span:first-child {
        opacity: 0; }
      .burger-menu.open .burger-menu__burger span:nth-child(2) {
        transform: rotate(45deg); }
      .burger-menu.open .burger-menu__burger span:nth-child(3) {
        transform: rotate(-45deg); }
      .burger-menu.open .burger-menu__burger span:last-child {
        opacity: 0; }
    .burger-menu.open .burger-menu__background {
      opacity: 1;
      pointer-events: all; }
    .burger-menu.open .burger-menu__wrapper {
      transform: translateX(0);
      opacity: 1; }
  .burger-menu.sticky {
    opacity: 1;
    top: 0;
    pointer-events: all; }
    .burger-menu.sticky .burger-menu__wrap {
      padding: 0;
      height: 80px; }
  .burger-menu__background {
    position: fixed;
    z-index: 0;
    opacity: 0;
    pointer-events: none;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(26, 27, 28, 0.45);
    transition: opacity .2s ease-in-out; }
  .burger-menu__menu {
    margin-left: auto;
    justify-self: flex-end; }
    .burger-menu__menu ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      list-style: none;
      margin: 0;
      padding: 0;
      transition: color .2s ease-in-out; }
      .burger-menu__menu ul li {
        font-size: 15px;
        font-weight: 400;
        transition: all .2s ease-in-out;
        width: 100%; }
        @media (min-width: 1200px) {
          .burger-menu__menu ul li {
            display: block; } }
        .burger-menu__menu ul li > a {
          position: relative;
          transition: color .2s ease-in-out;
          z-index: 3; }
        .burger-menu__menu ul li:last-child {
          margin-right: 0; }
        .burger-menu__menu ul li.current > a, .burger-menu__menu ul li.active > a {
          color: #FB3636; }
        .burger-menu__menu ul li.current > a:after, .burger-menu__menu ul li.active > a:after {
          right: 0; }
        .burger-menu__menu ul li > ul .current, .burger-menu__menu ul li > ul .active {
          font-weight: 500; }
      .burger-menu__menu ul .item-1020, .burger-menu__menu ul .item-807 {
        display: none; }
      .burger-menu__menu ul .item-101, .burger-menu__menu ul .item-1055 {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        font-size: 25px;
        margin-bottom: 22px; }
        .burger-menu__menu ul .item-101:before, .burger-menu__menu ul .item-1055:before {
          background-size: 50%;
          content: "";
          width: 95px;
          height: 95px;
          margin-right: 23px;
          box-shadow: 0 30px 99px rgba(0, 0, 0, 0.18);
          background-color: #fff;
          border-radius: 10px;
          background-position: center;
          background-repeat: no-repeat; }
        .burger-menu__menu ul .item-101 ul, .burger-menu__menu ul .item-1055 ul {
          display: none; }
      .burger-menu__menu ul .item-1055:before {
        background-image: url("/templates/main/images/svg/knowledge-black.svg"); }
      .burger-menu__menu ul .item-101:before {
        background-image: url("/templates/main/images/svg/therapist-black.svg"); }
      .burger-menu__menu ul .item-1160 {
        margin-bottom: 15px; }
      .burger-menu__menu ul .item-1160 > a, .burger-menu__menu ul .item-1166 > a, .burger-menu__menu ul .item-1317 > a {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        border-bottom: 2px solid #dddddd;
        display: block;
        margin-bottom: 25px;
        letter-spacing: 0.075em; }
      .burger-menu__menu ul .item-1160 ul li, .burger-menu__menu ul .item-1166 ul li, .burger-menu__menu ul .item-1317 ul li {
        margin-bottom: 5px;
        font-size: 17px; }
      .burger-menu__menu ul .item-1167 > a, .burger-menu__menu ul .item-1168 > a {
        font-size: 13px;
        font-weight: 700;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center; }
        .burger-menu__menu ul .item-1167 > a:before, .burger-menu__menu ul .item-1168 > a:before {
          content: "";
          display: inline-block;
          background-position: center;
          background-repeat: no-repeat; }
      .burger-menu__menu ul .item-1167 {
        margin-bottom: 25px; }
        .burger-menu__menu ul .item-1167 a:before {
          width: 20px;
          height: 20px;
          background-image: url("/templates/main/images/svg/newsletter-red.svg");
          margin-right: 10px; }
      .burger-menu__menu ul .item-1168 a:before {
        width: 18px;
        height: 14px;
        background-image: url("/templates/main/images/svg/contact-red.svg");
        margin-bottom: 1px;
        margin-right: 12px; }
    .burger-menu__menu--subpages ul {
      margin: -25px -30px 27px;
      position: relative;
      z-index: 3;
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap; }
      @media (max-width: 767.98px) {
        .burger-menu__menu--subpages ul {
          opacity: 0;
          pointer-events: none; } }
      .burger-menu__menu--subpages ul li {
        width: 50%;
        text-align: center;
        border-bottom: 1px solid rgba(112, 112, 112, 0.1);
        min-height: 60px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center; }
        .burger-menu__menu--subpages ul li a {
          min-height: 34px;
          border-radius: 10px;
          padding: 4px 15px 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.075em;
          opacity: 0.5; }
        .burger-menu__menu--subpages ul li.current a, .burger-menu__menu--subpages ul li.active a, .burger-menu__menu--subpages ul li.alias-parent-active a {
          background-color: rgba(26, 26, 28, 0.1);
          opacity: 1; }
        .burger-menu__menu--subpages ul li.item-1151 {
          border-left: 1px solid rgba(112, 112, 112, 0.1); }
        .burger-menu__menu--subpages ul li.item-1149, .burger-menu__menu--subpages ul li.item-1152, .burger-menu__menu--subpages ul li.item-1153, .burger-menu__menu--subpages ul li.item-1154 {
          display: none; }
  .burger-menu__burger {
    cursor: pointer;
    position: absolute;
    top: 0;
    background-color: #FB3636;
    border-bottom-left-radius: 5px;
    right: 0;
    width: 50px;
    height: 50px; }
    @media (min-width: 768px) {
      .burger-menu__burger {
        width: 60px;
        height: 60px;
        border-top-left-radius: 5px;
        right: 100%; } }
    .burger-menu__burger span {
      display: block;
      width: 40%;
      height: 2px;
      border-radius: 4px;
      position: absolute;
      background-color: #fff;
      left: 0;
      right: 0;
      margin: auto;
      transform-origin: 50% 50%;
      transition: all .2s ease-in-out; }
      .burger-menu__burger span:first-child {
        top: 0;
        bottom: auto; }
      .burger-menu__burger span:nth-child(2) {
        top: 0;
        bottom: 0;
        margin: auto; }
      .burger-menu__burger span:nth-child(3) {
        top: 0;
        bottom: 0;
        margin: auto; }
      .burger-menu__burger span:last-child {
        bottom: 0;
        top: auto; }
  .burger-menu__search {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 20px;
    border-bottom: 2px solid #dddddd;
    display: block;
    margin-bottom: 45px;
    letter-spacing: 0.075em;
    cursor: pointer; }
    @media (min-width: 576px) {
      .burger-menu__search {
        display: none; } }
  .burger-menu.subpages .burger-menu__wrap {
    padding-right: 30px; }
  .burger-menu.subpages .burger-menu__menu {
    height: calc(100% - 70px); }
    .burger-menu.subpages .burger-menu__menu--main > li {
      margin-bottom: 25px; }
      .burger-menu.subpages .burger-menu__menu--main > li > a {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        padding-bottom: 20px;
        border-bottom: 2px solid #dddddd;
        display: block;
        margin-bottom: 20px;
        letter-spacing: 0.075em; }
      .burger-menu.subpages .burger-menu__menu--main > li.deeper, .burger-menu.subpages .burger-menu__menu--main > li.parent {
        position: relative; }
        .burger-menu.subpages .burger-menu__menu--main > li.deeper:after, .burger-menu.subpages .burger-menu__menu--main > li.parent:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          margin: auto;
          width: 25px;
          height: 25px;
          display: block;
          z-index: 3;
          background-image: url("/templates/main/images/svg/triangle.svg");
          background-position: center;
          background-repeat: no-repeat;
          background-size: 14px;
          transition: transform .2s ease-in-out; }
        .burger-menu.subpages .burger-menu__menu--main > li.deeper.open:after, .burger-menu.subpages .burger-menu__menu--main > li.parent.open:after {
          transform: rotate(180deg); }
      .burger-menu.subpages .burger-menu__menu--main > li > ul {
        display: none; }
        .burger-menu.subpages .burger-menu__menu--main > li > ul > li {
          padding-left: 35px;
          font-size: 16px;
          margin-bottom: 10px; }
          .burger-menu.subpages .burger-menu__menu--main > li > ul > li.deeper, .burger-menu.subpages .burger-menu__menu--main > li > ul > li.parent {
            position: relative; }
            .burger-menu.subpages .burger-menu__menu--main > li > ul > li.deeper:after, .burger-menu.subpages .burger-menu__menu--main > li > ul > li.parent:after {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              right: auto;
              margin: auto;
              width: 25px;
              height: 25px;
              display: block;
              z-index: 3;
              background-image: url("/templates/main/images/svg/triangle.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: 13px;
              transition: transform .2s ease-in-out; }
            .burger-menu.subpages .burger-menu__menu--main > li > ul > li.deeper.open:after, .burger-menu.subpages .burger-menu__menu--main > li > ul > li.parent.open:after {
              transform: rotate(180deg); }
          .burger-menu.subpages .burger-menu__menu--main > li > ul > li > a {
            margin-bottom: 10px;
            display: block; }
          .burger-menu.subpages .burger-menu__menu--main > li > ul > li > ul {
            padding-left: 20px; }
            .burger-menu.subpages .burger-menu__menu--main > li > ul > li > ul li {
              font-size: 16px;
              margin-bottom: 5px; }
              .burger-menu.subpages .burger-menu__menu--main > li > ul > li > ul li:last-child {
                margin-bottom: 0; }
  .burger-menu.subpages .burger-menu__logo img {
    max-width: 275px;
    width: 100%; }
  .burger-menu .item-disabled:before {
    content: "";
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .burger-menu #burger-top .disabled-link {
    pointer-events: none;
    cursor: normal; }
  .burger-menu #burger-top .main__select-wrap {
    margin: 0 auto 30px; }
    .burger-menu #burger-top .main__select-wrap .cs-list-wrap {
      position: relative;
      top: 0; }
    .burger-menu #burger-top .main__select-wrap .cs-list {
      display: block; }
    .burger-menu #burger-top .main__select-wrap .cs-select:after {
      box-shadow: none; }
    .burger-menu #burger-top .main__select-wrap .cs-select--selected-list.open .cs-list-wrap {
      height: 380px; }
  .burger-menu #burger-list-problems.cs-list li[class*="item-"] {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 16px; }
    .burger-menu #burger-list-problems.cs-list li[class*="item-"] > a {
      margin-bottom: 8px;
      opacity: 0.3;
      pointer-events: none; }
    .burger-menu #burger-list-problems.cs-list li[class*="item-"].cs-result-list > a {
      pointer-events: all;
      opacity: 1;
      margin-bottom: 0; }
    .burger-menu #burger-list-problems.cs-list li[class*="item-"] > ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .burger-menu #burger-list-problems.cs-list li[class*="item-"] > ul li {
        text-transform: none;
        letter-spacing: normal;
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
        margin-bottom: 0; }
        .burger-menu #burger-list-problems.cs-list li[class*="item-"] > ul li > a {
          margin-bottom: 0;
          opacity: 1;
          pointer-events: all; }

#burger-menu.main .burger-menu__wrap,
#burger-menu.main .burger-menu__wrap .simplebar-wrapper,
#burger-menu.main .burger-menu__wrap .simplebar-mask,
#burger-menu.main .burger-menu__wrap .simplebar-offset,
#burger-menu.main .burger-menu__wrap .simplebar-content-wrapper,
#burger-menu.main .burger-menu__wrap .simplebar-content {
  overflow: visible !important; }

.fb-popup {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 999999;
  pointer-events: none;
  transition: opacity .2s ease-in-out;
  overflow: auto;
  background-color: #002A43; }
  @media (min-width: 576px) {
    .fb-popup {
      background-color: rgba(0, 0, 0, 0.4);
      padding: 15px; } }
  .fb-popup.open {
    pointer-events: all;
    opacity: 1; }
  .fb-popup__top {
    font-size: 28px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 32px; }
    @media (min-width: 576px) {
      .fb-popup__top img {
        margin-left: 22px; } }
    .fb-popup__top-text {
      width: 100%; }
      @media (min-width: 576px) {
        .fb-popup__top-text {
          width: auto; } }
  .fb-popup__wrapper {
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #fff;
    max-width: 1024px;
    width: 100%;
    background-color: #002A43;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 30px 15px;
    height: 100%; }
    @media (min-width: 576px) {
      .fb-popup__wrapper {
        height: auto;
        border-radius: 25px;
        padding: 50px 15px 116px; } }
    .fb-popup__wrapper-content {
      position: relative;
      z-index: 3;
      width: 100%; }
  .fb-popup__banner {
    width: 100%;
    max-width: 500px;
    box-shadow: 0 10px 99px rgba(0, 0, 0, 0.44);
    margin: 0 auto 32px; }
  .fb-popup__text {
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 37px; }
    .fb-popup__text a {
      font-weight: 700; }
  .fb-popup__close {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 31px;
    height: 31px;
    cursor: pointer; }
    .fb-popup__close span {
      transform-origin: 50% 50%;
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      transform: rotate(45deg); }
      .fb-popup__close span:first-child {
        transform: rotate(-45deg); }
  .fb-popup__like {
    width: 192px;
    position: absolute;
    z-index: 0;
    left: 50px;
    top: 32px;
    display: none; }
    @media (min-width: 768px) {
      .fb-popup__like {
        display: block; } }
    @media (min-width: 1025px) {
      .fb-popup__like {
        left: 100px;
        top: 64px; } }
    .fb-popup__like img {
      max-width: 100%;
      height: auto; }
  .fb-popup__plane {
    width: 192px;
    position: absolute;
    z-index: 0;
    bottom: 150px;
    right: 20px;
    display: none; }
    @media (min-width: 768px) {
      .fb-popup__plane {
        display: block; } }
    @media (min-width: 1025px) {
      .fb-popup__plane {
        right: 37px; } }
    .fb-popup__plane img {
      max-width: 100%;
      height: auto; }
  .fb-popup__form {
    width: 100%; }
    .fb-popup__form-input {
      color: #fff; }
      .fb-popup__form-input input {
        width: 100%;
        max-width: 430px;
        border: 2px solid #294868;
        border-radius: 18px;
        outline: none;
        background-color: #002A43;
        height: 85px;
        display: block;
        margin: 0 auto;
        text-align: center;
        color: #fff; }
        .fb-popup__form-input input[type="text"] {
          font-size: 20px;
          margin-bottom: 12px; }
          .fb-popup__form-input input[type="text"]::placeholder {
            color: #fff; }
        .fb-popup__form-input input[type="submit"] {
          font-size: 17px;
          font-weight: 700;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          background: linear-gradient(to left, #3fb693 0, #35b7c1 100%);
          margin-bottom: 30px; }
          @media (min-width: 576px) {
            .fb-popup__form-input input[type="submit"] {
              margin-bottom: 0; } }
  .fb-popup--red .fb-popup__wrapper {
    background: linear-gradient(315deg, #fb3636 0%, #1a1b1c 100%);
    max-width: 740px;
    position: relative; }
    .fb-popup--red .fb-popup__wrapper:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url("/templates/main/images/newsletter/newsletter-bg.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
  .fb-popup--red .fb-popup__logo {
    margin-bottom: 56px; }
  .fb-popup--red .fb-popup__btn {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1A1B1C;
    display: flex;
    flex-wrap: wrap;
    min-height: 88px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.16);
    border-radius: 21px;
    max-width: 390px;
    margin: 0 auto;
    padding: 0 10px; }
  .fb-popup--red .fb-popup__close {
    top: 20px;
    right: 25px; }
  .fb-popup--red .fb-popup__text {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 30px; }
    .fb-popup--red .fb-popup__text span {
      text-decoration: underline;
      vertical-align: middle; }
    .fb-popup--red .fb-popup__text strong {
      font-size: 23px;
      font-weight: 700;
      text-transform: uppercase;
      vertical-align: middle;
      display: inline-block;
      padding-top: 4px; }

.c-banner__img {
  position: absolute;
  width: 627px;
  margin: 0;
  bottom: 16px;
  z-index: 0;
  transform: scale(0.5);
  transform-origin: 0% 100%;
  left: 25px;
  display: none; }
  @media (min-width: 768px) {
    .c-banner__img {
      display: block; } }
  @media (min-width: 1025px) {
    .c-banner__img {
      left: 54px; } }

.perspective-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .perspective-wrapper .perspective {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
    .perspective-wrapper .perspective img {
      position: absolute;
      top: -17%;
      left: 13.8%;
      transform-origin: 50% 50%;
      width: auto;
      height: 100%;
      transform: perspective(726px) rotateX(40.5deg) rotateY(14.5deg) rotateZ(-24.4deg); }

.perspective-back {
  width: 100%;
  position: relative; }

.main__select-wrap {
  width: 100%;
  max-width: 330px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  position: relative;
  margin: 0 0 20px;
  z-index: 15; }
  .main__select-wrap .simplebar-track.simplebar-vertical {
    right: 30px;
    bottom: 30px; }
    .main__select-wrap .simplebar-track.simplebar-vertical .simplebar-scrollbar {
      background-color: #fff;
      border-radius: 4px; }
      .main__select-wrap .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
        display: none; }
    .main__select-wrap .simplebar-track.simplebar-vertical:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 1px;
      left: 0;
      right: 0;
      margin: auto;
      background-color: rgba(255, 255, 255, 0.2); }
  @media (min-width: 576px) {
    .main__select-wrap {
      margin: 0 10px 20px; } }
  @media (min-width: 1200px) {
    .main__select-wrap {
      margin: 0 20px 20px 0; } }
  .main__select-wrap:last-child {
    z-index: 10; }
    @media (min-width: 1200px) {
      .main__select-wrap:last-child {
        margin: 0 0 20px; } }
  .main__select-wrap.loaded {
    opacity: 1; }
  .main__select-wrap .cs-title {
    width: 100%;
    background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 700;
    font-size: 14px;
    border-radius: 20px;
    height: 80px;
    padding: 0 45px 0 30px; }
    @media (min-width: 400px) {
      .main__select-wrap .cs-title {
        font-size: 16px; } }
    .main__select-wrap .cs-title span {
      position: relative;
      z-index: 3;
      pointer-events: none; }
  .main__select-wrap .cs-list-wrap {
    overflow: hidden;
    position: absolute;
    height: 0;
    transition: height .2s ease-in-out, opacity .2s ease-in-out;
    background-color: #fff;
    color: #1A1B1C;
    z-index: 3;
    opacity: 0;
    width: 100%;
    pointer-events: none;
    top: 80px;
    border-radius: 20px; }
  .main__select-wrap .cs-list {
    width: 100%;
    height: 100%;
    position: relative;
    -webkit-overflow-scrolling: touch;
    list-style: none;
    margin: 0;
    padding: 0;
    width: calc(100% + 20px);
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    padding: 0 50px 30px 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    max-width: calc(100% + 20px); }
    .main__select-wrap .cs-list li a {
      display: block;
      width: 100%;
      padding-bottom: 10px; }
    .main__select-wrap .cs-list li:last-child {
      margin-bottom: 0; }
  .main__select-wrap .cs-btn {
    display: block;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 3;
    cursor: pointer;
    height: 80px; }
    .main__select-wrap .cs-btn:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      background-image: url("/templates/main/images/svg/chevron-down.svg");
      margin: auto;
      width: 15px;
      height: 8px;
      background-repeat: no-repeat;
      transform-origin: 50% 50%;
      transition: transform .2s ease-in-out;
      right: 25px; }
    .main__select-wrap .cs-btn.open:after {
      transform: rotate(180deg); }
  .main__select-wrap .cs-select:after {
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    display: block;
    background-color: #fff;
    position: absolute;
    opacity: 0;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.41);
    transition: height .2s ease-in-out, opacity .2s ease-in-out;
    pointer-events: none;
    z-index: 0;
    border-radius: 20px; }
  .main__select-wrap .cs-select.open .cs-list-wrap {
    height: 380px;
    opacity: 1;
    pointer-events: all; }
  .main__select-wrap .cs-select.open:after {
    opacity: 1;
    min-height: 175px;
    height: 460px; }

.main__top {
  padding-bottom: 55px;
  position: relative;
  background-color: rgba(235, 235, 235, 0.5); }
  @media (min-width: 1600px) {
    .main__top {
      min-height: 700px; } }
  .main__top::before {
    content: '';
    position: absolute;
    bottom: 100%;
    height: 30px;
    background-color: rgba(235, 235, 235, 0.5);
    width: 100%;
    pointer-events: none; }
  .main__top--therapist {
    justify-content: center;
    padding-bottom: 280px; }
    .main__top--therapist .main__top-title,
    .main__top--therapist .main__top-subtitle {
      text-align: center; }
    .main__top--therapist .main__top-wrap {
      width: 100%;
      max-width: 1440px;
      padding: 0 15px;
      margin: 0 auto; }
    .main__top--therapist .main__top-left {
      padding-top: 20px;
      max-width: none;
      justify-content: center;
      min-height: auto;
      padding-bottom: 0; }
    .main__top--therapist .main__select-wrap {
      z-index: 14; }
  .main__top-wrap {
    display: flex;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px; }
    @media (min-width: 1440px) {
      .main__top-wrap {
        padding-right: 0;
        padding-left: calc((100% - 1410px) / 2); } }
  .main__top-left {
    min-height: 28.5vw;
    padding-top: 0px;
    padding-bottom: 65px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center; }
    @media (min-width: 1600px) {
      .main__top-left {
        justify-content: flex-start;
        max-width: 50%; } }
    @media (min-width: 1800px) {
      .main__top-left {
        padding-bottom: 115px; } }
  .main__top-right {
    height: 100%;
    right: 0;
    position: relative;
    min-height: 650px;
    top: -35px;
    height: calc(100% + 35px);
    width: calc(100% + 30px);
    margin: 0 -15px;
    padding: 0 15px; }
    @media (min-width: 1600px) {
      .main__top-right {
        overflow: hidden;
        width: 100%;
        min-height: auto;
        position: absolute;
        max-width: 47%;
        margin: 0;
        padding: 0; } }
  .main__top-center {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    .main__top-center ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      width: 100%;
      margin: 0 auto;
      font-size: 14px; }
      @media (min-width: 768px) {
        .main__top-center ul {
          max-width: 80%; } }
      @media (min-width: 1280px) {
        .main__top-center ul {
          margin: 0;
          max-width: 57%; } }
      .main__top-center ul li {
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-right: 7px;
        opacity: 0.7;
        transition: opacity .2s ease-in-out; }
        .main__top-center ul li:hover {
          opacity: 1; }
        .main__top-center ul li:after {
          content: "";
          width: 3px;
          height: 3px;
          background-color: #fff;
          border-radius: 50%;
          display: inline-block;
          margin: -2px 0 0 7px; }
        .main__top-center ul li:last-child {
          margin-right: 0; }
        .main__top-center ul li.all {
          opacity: 1;
          text-transform: uppercase;
          font-weight: 700; }
          .main__top-center ul li.all:after {
            display: none; }
  .main__top-text {
    width: 100%;
    margin-bottom: 15px; }
    @media (min-width: 768px) and (max-width: 1024.98px) {
      .main__top-text {
        margin-top: 40px; } }
  .main__top-title {
    font-weight: 700;
    width: 100%;
    text-align: center;
    font-size: 26px;
    line-height: 32px;
    margin: 45px 0; }
    @media (min-width: 768px) {
      .main__top-title {
        font-size: 30px;
        margin: 0; } }
    @media (min-width: 1025px) {
      .main__top-title {
        font-size: 45px;
        line-height: 56px; } }
    @media (min-width: 1600px) {
      .main__top-title {
        text-align: left;
        font-size: 2.5vw; } }
    @media (min-width: 1800px) {
      .main__top-title {
        font-size: 43px; } }
  .main__top-subtitle {
    font-size: 24px;
    width: 100%;
    text-align: center;
    display: none; }
    @media (min-width: 768px) {
      .main__top-subtitle {
        display: block; } }
    @media (min-width: 1600px) {
      .main__top-subtitle {
        text-align: left;
        font-size: 1.25vw; } }
    @media (min-width: 1800px) {
      .main__top-subtitle {
        font-size: 24px; } }

.select-placeholder {
  height: 80px;
  width: 100%;
  max-width: calc(50% - 10px);
  background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
  border-radius: 20px; }
  .select-placeholder:last-child {
    margin-right: 0; }

.cs-select--selected-list #hp-list-problems .cs-selected-list > a, .cs-select--selected-list #box-list-problems .cs-selected-list > a {
  pointer-events: all;
  opacity: 1;
  margin-bottom: 0; }

#hp-list-problems.cs-list li, #box-list-problems.cs-list li {
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.1em;
  margin-bottom: 16px; }
  #hp-list-problems.cs-list li > a, #box-list-problems.cs-list li > a {
    margin-bottom: 8px;
    opacity: 0.3;
    pointer-events: none; }
  #hp-list-problems.cs-list li.cs-result-list > a, #box-list-problems.cs-list li.cs-result-list > a {
    pointer-events: all;
    opacity: 1;
    margin-bottom: 0; }
  #hp-list-problems.cs-list li > ul, #box-list-problems.cs-list li > ul {
    margin: 0;
    padding: 0;
    list-style: none; }
    #hp-list-problems.cs-list li > ul li, #box-list-problems.cs-list li > ul li {
      text-transform: none;
      letter-spacing: normal;
      font-size: 16px;
      opacity: 1;
      font-weight: 400;
      margin-bottom: 0; }
      #hp-list-problems.cs-list li > ul li > a, #box-list-problems.cs-list li > ul li > a {
        margin-bottom: 0;
        opacity: 1;
        pointer-events: all; }

.firm-item {
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  margin-bottom: 30px;
  padding: 20px; }
  @media (min-width: 576px) {
    .firm-item {
      padding: 30px; } }
  .firm-item__firm-type {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    color: #35B7C1; }
  .firm-item__image {
    width: 100%;
    margin-bottom: 30px;
    max-width: 165px;
    margin: 0 auto; }
    @media (min-width: 576px) {
      .firm-item__image {
        max-width: 130px;
        margin-bottom: 0; } }
    @media (min-width: 1025px) {
      .firm-item__image {
        max-width: 165px; } }
    .firm-item__image img {
      display: block;
      margin: 0 auto 20px;
      border-radius: 10px; }
    .firm-item__image-count {
      color: #002B44;
      opacity: 0.5;
      font-size: 13px;
      text-align: center; }
  .firm-item__text {
    width: 100%;
    position: relative; }
    @media (min-width: 576px) {
      .firm-item__text {
        padding-left: 30px;
        max-width: calc(100% - 130px); } }
    @media (min-width: 1025px) {
      .firm-item__text {
        max-width: calc(100% - 165px); } }
  .firm-item__name {
    font-size: 22px;
    font-weight: 700; }
  .firm-item__time, .firm-item__location {
    font-size: 13px;
    padding-bottom: 5px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    margin-bottom: 10px; }
  .firm-item__location {
    opacity: 0.5; }
  .firm-item__tags {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .firm-item__tags--blue .firm-item__tag {
      background-color: #35B7C1; }
    .firm-item__tags--green {
      margin-bottom: 14px; }
      .firm-item__tags--green .firm-item__tag {
        background-color: #3FB693; }
  .firm-item__tag {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 21px;
    font-size: 13px;
    border-radius: 5px;
    color: #fff;
    padding: 0 5px;
    margin-right: 4px;
    margin-bottom: 6px;
    font-weight: 700; }
    .firm-item__tag-link {
      margin-left: 4px;
      font-size: 11px;
      margin-bottom: 6px;
      width: 100%;
      display: block; }
      @media (min-width: 768px) {
        .firm-item__tag-link {
          width: auto; } }
    .firm-item__tag-count {
      font-weight: 700; }
  .firm-item__type {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 10px; }
  .firm-item__info {
    margin-bottom: 15px; }
  .firm-item__info, .firm-item__www {
    font-size: 15px; }
  .firm-item__tel, .firm-item__mail {
    padding-left: 35px; }
  .firm-item__address {
    width: 100%; }
    .firm-item__address-text {
      max-width: calc(100% - 35px); }
  .firm-item__www, .firm-item__address {
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .firm-item__www img, .firm-item__address img {
      margin-right: 15px; }
  .firm-item__bottom {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
  .firm-item__firm-profile {
    position: absolute;
    bottom: 0;
    right: 0; }
  .firm-item__profile, .firm-item__firm-profile {
    text-transform: uppercase;
    color: #35B7C1;
    font-weight: 700;
    letter-spacing: 0.075em;
    font-size: 11px; }
    @media (max-width: 575.98px) {
      .firm-item__profile, .firm-item__firm-profile {
        margin-top: 20px;
        width: 100%;
        text-align: right;
        position: static; } }

@media (min-width: 1025px) {
  .article-img-top:hover .article-img-top__image:after {
    opacity: 0.4; }
  .article-img-top:hover .article-img-top__image img {
    transform: scale(1.1); } }

.article-img-top__image {
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 25px;
  position: relative; }
  .article-img-top__image:after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
    opacity: 0;
    position: absolute;
    display: block;
    pointer-events: none;
    transition: opacity .5s ease-in-out;
    border-radius: 20px; }
  .article-img-top__image img {
    transform-origin: 50% 50%;
    transition: transform .5s ease-in-out;
    backface-visibility: hidden;
    outline: 1px solid transparent; }

.article-img-top__title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px; }

.article-img-top__introtext {
  font-size: 15px;
  font-weight: 400; }

.page-header--select .page-header__container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  @media (min-width: 768px) {
    .page-header--select .page-header__container {
      justify-content: space-between; } }

.page-header--select .page-header__header {
  margin: 0;
  padding: 0;
  width: auto; }

.grid-article {
  border-radius: 20px;
  overflow: hidden;
  color: #fff;
  position: absolute;
  transform-origin: 50% 50%;
  transition: transform .2s ease-in-out;
  backface-visibility: hidden;
  outline: 1px solid transparent;
  transform: scale3d(1, 1, 1);
  overflow: hidden; }
  @media (min-width: 1025px) {
    .grid-article:hover {
      transform: scale3d(1.07, 1.07, 1); } }
  .grid-article__image {
    left: 0;
    top: 0; }
    @media (min-width: 768px) {
      .grid-article__image {
        position: absolute; } }
    .grid-article__image img {
      transform-origin: 50% 50%;
      transition: transform .2s ease-in-out;
      backface-visibility: hidden;
      outline: 1px solid transparent; }
  .grid-article__text {
    position: absolute;
    bottom: 18px;
    left: 25px;
    right: 25px;
    font-size: 19px;
    font-weight: 700;
    z-index: 5; }
  .grid-article__content {
    position: relative;
    width: 100%; }
    @media (min-width: 768px) {
      .grid-article__content {
        padding-bottom: 100%; } }
  .grid-article__link {
    left: 0;
    top: 0;
    display: block;
    z-index: 3;
    width: 100%;
    height: 100%; }
    @media (min-width: 768px) {
      .grid-article__link {
        position: absolute; } }
    .grid-article__link:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
      opacity: 0.5;
      z-index: 3; }
  .grid-article--el-0 {
    z-index: 7;
    position: relative;
    width: 80%; }
    @media (min-width: 380px) {
      .grid-article--el-0 {
        width: 60%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-0 {
        position: absolute;
        margin: 0;
        z-index: 2;
        min-width: 200px;
        margin: auto;
        width: 29%;
        top: 0;
        left: 0;
        right: 0; } }
    @media (min-width: 1600px) {
      .grid-article--el-0 {
        width: 22%;
        top: 5.7%;
        left: 23%;
        right: auto; } }
  .grid-article--el-1 {
    position: relative;
    z-index: 6;
    margin-left: auto;
    margin-right: 0;
    width: 80%;
    margin-top: -20%;
    margin-bottom: 10%; }
    @media (min-width: 380px) {
      .grid-article--el-1 {
        width: 60%;
        margin-top: -20%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-1 {
        position: absolute;
        margin: 0;
        top: 5%;
        left: 11.5%;
        width: 20%;
        min-width: 180px;
        z-index: 2; } }
    @media (min-width: 1600px) {
      .grid-article--el-1 {
        z-index: 1;
        min-width: 300px;
        width: 36.4%;
        top: 13.4%;
        left: 40.1%; } }
  .grid-article--el-2 {
    position: relative;
    z-index: 5;
    width: 80%;
    margin-top: -20%;
    margin-bottom: 10%;
    opacity: 1; }
    @media (min-width: 380px) {
      .grid-article--el-2 {
        width: 60%;
        margin-top: -20%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-2 {
        position: absolute;
        margin: 0;
        z-index: 3;
        width: 17%;
        min-width: 190px;
        top: 6%;
        right: 14.5%; } }
    @media (min-width: 1600px) {
      .grid-article--el-2 {
        width: 22%;
        min-width: 200px;
        right: 2.9%;
        top: 2.6%; } }
  .grid-article--el-3 {
    position: relative;
    width: 80%;
    z-index: 4;
    margin-left: auto;
    margin-right: 0;
    margin-top: -20%;
    margin-bottom: 10%; }
    @media (min-width: 380px) {
      .grid-article--el-3 {
        width: 60%;
        margin-top: -20%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-3 {
        position: absolute;
        margin: 0;
        z-index: 1;
        width: 18%;
        min-width: 160px;
        top: 36%;
        left: 2%; } }
    @media (min-width: 1600px) {
      .grid-article--el-3 {
        min-width: 150px;
        width: 22.4%;
        right: -6.7%;
        top: 40.7%;
        left: auto;
        bottom: auto; } }
  .grid-article--el-4 {
    position: relative;
    z-index: 3;
    width: 80%;
    margin-top: -20%;
    margin-bottom: 10%; }
    @media (min-width: 380px) {
      .grid-article--el-4 {
        width: 60%;
        margin-top: -20%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-4 {
        position: absolute;
        margin: 0;
        z-index: 0;
        width: 24%;
        min-width: 230px;
        bottom: 25%;
        left: 22%; } }
    @media (min-width: 1600px) {
      .grid-article--el-4 {
        min-width: 250px;
        width: 28%;
        right: 9.5%;
        top: 51.8%;
        bottom: auto;
        left: auto; } }
  .grid-article--el-5 {
    position: relative;
    width: 80%;
    z-index: 2;
    margin-left: auto;
    margin-right: 0;
    margin-top: -20%;
    margin-bottom: 10%; }
    @media (min-width: 380px) {
      .grid-article--el-5 {
        width: 60%;
        margin-top: -20%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-5 {
        position: absolute;
        margin: 0;
        z-index: 0;
        width: 20%;
        min-width: 190px;
        right: 24%;
        bottom: 28%; } }
    @media (min-width: 1600px) {
      .grid-article--el-5 {
        width: 36.4%;
        min-width: 165px;
        left: 18.2%;
        bottom: 5.9%; } }
  .grid-article--el-6 {
    position: relative;
    z-index: 1;
    width: 80%;
    margin-top: -20%;
    margin-bottom: 10%; }
    @media (min-width: 380px) {
      .grid-article--el-6 {
        width: 60%;
        margin-top: -20%;
        margin-bottom: 10%; } }
    @media (min-width: 768px) {
      .grid-article--el-6 {
        position: absolute;
        margin: 0;
        z-index: 0;
        width: 19%;
        min-width: 195px;
        right: 2%;
        bottom: 38%; } }
    @media (min-width: 1600px) {
      .grid-article--el-6 {
        width: 22.5%;
        min-width: 100px;
        left: 6.2%;
        bottom: 29.7%; } }

#gmap {
  overflow: hidden;
  border-radius: 20px;
  margin-bottom: 0px;
  transition: padding .2s ease-in-out, margin .2s ease-in-out; }
  #gmap.open {
    margin-bottom: 20px;
    padding-bottom: 90%; }
    @media (min-width: 576px) {
      #gmap.open {
        padding-bottom: 70%; } }
    @media (min-width: 768px) {
      #gmap.open {
        padding-bottom: 56.25%; } }
  #gmap .cluster-markers {
    width: 50px;
    height: 50px;
    color: #fff;
    border-radius: 50%;
    background-color: #FB3636;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Objectivity", sans-serif;
    padding-top: 5px; }

#return-top {
  position: fixed;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  width: 55px;
  height: 55px;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  transition: .5s all;
  width: 74px;
  height: 74px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.16); }
  @media (min-width: 576px) {
    #return-top {
      bottom: 45px;
      right: 45px;
      width: 74px;
      height: 74px; } }
  #return-top.show {
    opacity: 1; }
  #return-top svg path {
    fill: #fb3636; }

#anim-logo-white .UfhKpJPt_0 {
  stroke-dasharray: 4 6;
  stroke-dashoffset: 5; }

#anim-logo-white .UfhKpJPt_1 {
  stroke-dasharray: 173 175;
  stroke-dashoffset: 174; }

#anim-logo-white .UfhKpJPt_2 {
  stroke-dasharray: 109 111;
  stroke-dashoffset: 110; }

#anim-logo-white .UfhKpJPt_3 {
  stroke-dasharray: 957 959;
  stroke-dashoffset: 958; }

#anim-logo-white.start .UfhKpJPt_0 {
  animation: UfhKpJPt_draw 12ms ease-in-out 0ms forwards; }

#anim-logo-white.start .UfhKpJPt_1 {
  animation: UfhKpJPt_draw 418ms ease-in-out 12ms forwards; }

#anim-logo-white.start .UfhKpJPt_2 {
  animation: UfhKpJPt_draw 264ms ease-in-out 430ms forwards; }

#anim-logo-white.start .UfhKpJPt_3 {
  animation: UfhKpJPt_draw 2304ms ease-in-out 695ms forwards; }

#anim-logo-white.loaded .UfhKpJPt_0 {
  animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards; }

#anim-logo-white.loaded .UfhKpJPt_1 {
  animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards; }

#anim-logo-white.loaded .UfhKpJPt_2 {
  animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards; }

#anim-logo-white.loaded .UfhKpJPt_3 {
  animation: UfhKpJPt_draw 0s ease-in-out 0ms forwards; }

@keyframes UfhKpJPt_draw {
  100% {
    stroke-dashoffset: 0; } }

@keyframes UfhKpJPt_fade {
  0% {
    stroke-opacity: 1; }
  94.44444444444444% {
    stroke-opacity: 1; }
  100% {
    stroke-opacity: 0; } }

.author-more {
  margin-bottom: 60px; }
  .author-more__title {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 16px; }
  .author-more__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 3px 25px rgba(0, 0, 0, 0.16);
    padding: 15px; }
    @media (min-width: 576px) {
      .author-more__wrap {
        padding: 30px; } }
  .author-more__text {
    width: 100%; }
    @media (min-width: 576px) {
      .author-more__text {
        padding-left: 30px;
        max-width: calc(100% - 90px); } }
  .author-more__image {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      .author-more__image {
        margin-bottom: 0; } }
  .author-more .author-more__mail {
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
    margin-bottom: 28px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    align-content: center;
    text-decoration: none;
    color: #FB3636 !important; }
    .author-more .author-more__mail img {
      margin-right: 10px; }
  .author-more__name {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    margin-bottom: 7px; }
  .author-more__description {
    font-size: 15px;
    line-height: 25px;
    margin-bottom: 20px; }
  .author-more .author-more__btn {
    width: 100%;
    max-width: 330px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 2px solid #DDDDDD;
    border-radius: 10px;
    color: #1A1B1C !important;
    font-weight: 700;
    text-decoration: none;
    font-size: 14px;
    min-height: 48px;
    text-align: center;
    padding: 5px; }

.article-list__article-select {
  width: 100%;
  max-width: 330px;
  opacity: 0;
  transition: opacity .2s ease-in-out;
  z-index: 10; }
  .article-list__article-select.loaded {
    opacity: 1; }
  .article-list__article-select #article-cs-select .cs-list-wrap {
    max-height: 400px; }
  .article-list__article-select .simplebar-track {
    right: 20px;
    bottom: 20px; }
    .article-list__article-select .simplebar-track:before {
      content: "";
      left: 0;
      right: 0;
      bottom: 2px;
      top: 2px;
      margin: auto;
      background-color: #fff;
      position: absolute;
      width: 1px;
      opacity: 0.3; }
    .article-list__article-select .simplebar-track .simplebar-scrollbar:before {
      opacity: 1;
      background-color: #fff; }
  .article-list__article-select .cs-select {
    position: relative;
    width: 100%;
    line-height: normal; }
    .article-list__article-select .cs-select:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      border-radius: 8px;
      transition: height .2s ease-in-out, box-shadow .2s ease-in-out, border-color .2s ease-in-out;
      height: 50px;
      z-index: 9;
      pointer-events: none; }
    .article-list__article-select .cs-select.open:after {
      height: 400px;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.41); }
  .article-list__article-select .cs-title {
    height: 50px;
    background-color: #fff;
    width: 100%;
    border: none;
    outline: none;
    color: #1A1B1C;
    font-size: 13px;
    font-weight: 700;
    padding-left: 20px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 8px;
    position: relative;
    z-index: 9; }
    .article-list__article-select .cs-title span {
      position: relative;
      z-index: 9;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      width: 100%; }
      .article-list__article-select .cs-title span a:nth-child(2) {
        position: relative;
        z-index: 20;
        display: inline-block;
        width: 20px;
        height: 20px; }
  .article-list__article-select .cs-list-wrap {
    height: 0;
    transition: height .2s ease-in-out, background-color .2s ease-in-out;
    overflow: hidden;
    top: 0px;
    width: 100%;
    border-radius: 8px;
    background-color: #fff;
    z-index: 5;
    position: absolute;
    padding-top: 50px; }
    .article-list__article-select .cs-list-wrap:after {
      position: absolute; }
  .article-list__article-select .cs-list {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    color: #1A1B1C;
    list-style: none;
    margin: 0;
    padding: 0 15px 15px;
    width: calc(100% + 20px);
    font-size: 15px;
    line-height: 23px;
    font-weight: 400; }
    .article-list__article-select .cs-list li {
      padding-right: 15px; }
      .article-list__article-select .cs-list li a {
        display: block; }
        .article-list__article-select .cs-list li a:first-letter {
          text-transform: uppercase; }
  .article-list__article-select .cs-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 12;
    cursor: pointer; }
    .article-list__article-select .cs-btn:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      background-image: url("/templates/main/images/svg/chevron-down-black.svg");
      margin: auto;
      width: 15px;
      height: 8px;
      background-repeat: no-repeat;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      transition: transform .2s ease-in-out; }
  .article-list__article-select .cs-select.open:after {
    border-color: #294868; }
  .article-list__article-select .cs-select.open .cs-btn:after {
    transform: rotate(180deg); }
  .article-list__article-select .cs-select.open .cs-list-wrap {
    height: 380px;
    background-color: #fff; }
    .article-list__article-select .cs-select.open .cs-list-wrap:after {
      border-color: #fff; }
  .article-list__article-select .article-select--problems li {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 16px; }
    .article-list__article-select .article-select--problems li > a {
      margin-bottom: 8px;
      opacity: 0.3;
      display: block; }
    .article-list__article-select .article-select--problems li > ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      .article-list__article-select .article-select--problems li > ul li {
        width: 100%;
        text-transform: none;
        letter-spacing: normal;
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
        margin-bottom: 5px; }
        .article-list__article-select .article-select--problems li > ul li > a {
          margin-bottom: 0;
          opacity: 1; }

.hp-info {
  margin-bottom: 40px; }
  .hp-info__container {
    padding-top: 60px;
    padding-bottom: 54px;
    position: relative; }
    .hp-info__container:after {
      content: "";
      height: 1px;
      display: block;
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      display: block;
      background-color: #707070;
      opacity: 0.1; }
  .hp-info__item {
    text-align: center;
    margin-bottom: 30px; }
    .hp-info__item-image {
      min-height: 134px;
      margin-bottom: 40px; }
      .hp-info__item-image img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        display: block; }
    .hp-info__item-title {
      font-size: 22px;
      font-weight: 700;
      max-width: 330px;
      margin: 0 auto 40px; }
    .hp-info__item-introtext {
      font-size: 15px;
      font-weight: 400; }

.often-read {
  font-size: 35px;
  font-weight: 700;
  margin-bottom: 17px; }

.hp-slider-problems {
  margin-bottom: 50px;
  padding-left: 15px; }
  @media (min-width: 1440px) {
    .hp-slider-problems {
      padding-left: calc((100% - 1410px) / 2); } }
  @media (max-width: 399px) {
    .hp-slider-problems {
      padding-right: 15px; } }
  .hp-slider-problems__title {
    font-size: 18px;
    font-weight: 700; }
  .hp-slider-problems .swiper-wrapper {
    padding-top: 23px;
    height: auto; }

.slide-problem {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  transition: transform .2s ease-in-out; }
  @media (min-width: 1025px) {
    .slide-problem:hover {
      transform: translateY(-15px); } }
  .slide-problem:after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, black 0%, rgba(0, 0, 0, 0) 100%);
    opacity: 0.65; }
  .slide-problem__image img {
    transform-origin: 50% 50%;
    transition: transform .2s ease-in-out;
    backface-visibility: hidden;
    outline: 1px solid transparent; }
  .slide-problem__text {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 17px;
    color: #fff;
    letter-spacing: 0.2em;
    font-size: 14px;
    font-weight: 700;
    z-index: 3;
    text-transform: uppercase;
    text-align: center; }

.hp-info-doctor {
  background-color: #002B44;
  color: #fff;
  position: relative;
  margin-bottom: 80px;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 60px; }
  @media (min-width: 1025px) {
    .hp-info-doctor {
      padding: 90px 0; } }
  .hp-info-doctor:after {
    content: "";
    background-color: #F16655;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: calc(100% - 70px); }
    @media (min-width: 576px) {
      .hp-info-doctor:after {
        bottom: calc(100% - 100px); } }
    @media (min-width: 768px) {
      .hp-info-doctor:after {
        right: 0;
        top: 0;
        bottom: 0;
        left: calc(100% - 135px); } }
    @media (min-width: 1025px) {
      .hp-info-doctor:after {
        left: calc(100% - 160px); } }
    @media (min-width: 1200px) {
      .hp-info-doctor:after {
        left: calc(100% - 210px); } }
    @media (min-width: 1440px) {
      .hp-info-doctor:after {
        left: calc((100vw - ((100vw - 1410px) / 2)) - 200px); } }
  .hp-info-doctor__text {
    max-width: 100%;
    order: 2; }
    @media (min-width: 768px) {
      .hp-info-doctor__text {
        order: 1;
        max-width: calc(100% - 230px); } }
    @media (min-width: 1025px) {
      .hp-info-doctor__text {
        max-width: calc(100% - 300px); } }
  .hp-info-doctor__title {
    font-weight: 700;
    margin-bottom: 35px;
    max-width: 800px;
    font-size: 26px;
    line-height: 35px; }
    @media (min-width: 1025px) {
      .hp-info-doctor__title {
        font-size: 45px;
        line-height: 55px; } }
  .hp-info-doctor__subtitle {
    margin-bottom: 45px;
    max-width: 550px;
    font-weight: 700;
    font-size: 18px; }
    @media (min-width: 1025px) {
      .hp-info-doctor__subtitle {
        font-size: 22px; } }
  .hp-info-doctor__list {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 55px; }
    .hp-info-doctor__list li {
      color: rgba(255, 255, 255, 0.7);
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      font-size: 17px; }
      @media (min-width: 1025px) {
        .hp-info-doctor__list li {
          font-size: 20px; } }
      .hp-info-doctor__list li span {
        display: inline-block;
        width: calc(100% - 12px); }
      .hp-info-doctor__list li:before {
        content: "";
        width: 5px;
        height: 5px;
        background-color: #F16655;
        border-radius: 50%;
        display: inline-block;
        margin: 12px 7px 0 0; }
  .hp-info-doctor__btn {
    max-width: 520px;
    letter-spacing: 0.05em; }
    @media (max-width: 1024.98px) {
      .hp-info-doctor__btn {
        font-size: 13px;
        height: 60px; } }
    @media (max-width: 767.98px) {
      .hp-info-doctor__btn {
        margin: 0 auto; } }
    .hp-info-doctor__btn .btn-border__content {
      background-color: #002B44; }
      @media (max-width: 575.98px) {
        .hp-info-doctor__btn .btn-border__content-text span {
          display: none; } }
    .hp-info-doctor__btn img {
      margin-left: 20px; }
  .hp-info-doctor__container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 3;
    justify-content: space-between; }
  .hp-info-doctor__image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    margin-right: 25px;
    width: 75px;
    height: 75px; }
    @media (min-width: 576px) {
      .hp-info-doctor__image {
        width: 140px;
        height: 140px; } }
    @media (min-width: 768px) {
      .hp-info-doctor__image {
        margin-right: 0;
        margin-bottom: 15px; } }
    @media (min-width: 1025px) {
      .hp-info-doctor__image {
        width: 200px;
        height: 200px; } }
    @media (max-width: 1024.98px) {
      .hp-info-doctor__image img {
        max-width: 50%; } }
    .hp-info-doctor__image:last-child {
      margin-right: 0;
      margin-bottom: 0; }
  .hp-info-doctor__images {
    order: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 30px; }
    @media (min-width: 768px) {
      .hp-info-doctor__images {
        margin-bottom: 0;
        padding-right: 50px;
        order: 2;
        display: block;
        width: auto; } }
    @media (min-width: 1200px) {
      .hp-info-doctor__images {
        padding-right: 100px; } }

.our-guide {
  margin-bottom: 85px; }
  .our-guide__module-title {
    font-size: 35px;
    font-weight: 700;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    margin-bottom: 50px; }
  .our-guide__btn {
    margin: 0 auto;
    max-width: 300px; }
    .our-guide__btn .btn-border__content {
      background-color: #fff; }
  .our-guide__item {
    position: relative;
    text-align: center;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 30px;
    color: #fff; }
    @media (min-width: 1025px) {
      .our-guide__item:hover .our-guide__item-image img {
        transform: scale(1.1); }
      .our-guide__item:hover:after {
        opacity: 0.8; } }
    .our-guide__item-image {
      overflow: hidden;
      border-radius: 20px; }
      .our-guide__item-image img {
        transform-origin: 50% 50%;
        transition: all .5s ease-in-out;
        backface-visibility: hidden;
        outline: 1px solid transparent;
        width: 100%; }
    .our-guide__item:after {
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
      opacity: 0.3;
      position: absolute;
      display: block;
      pointer-events: none;
      transition: opacity .5s ease-in-out;
      border-radius: 20px; }
    .our-guide__item-category {
      height: 29px;
      border-radius: 5px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      padding-top: 2px;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 0.05em;
      font-weight: 700;
      background: linear-gradient(45deg, #3fb693 0%, #35b7c1 100%);
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: 155px;
      width: 100%; }
    .our-guide__item-text {
      position: absolute;
      z-index: 3;
      left: 15px;
      right: 15px;
      bottom: 35px; }
      @media (min-width: 1025px) {
        .our-guide__item-text {
          left: 25px;
          right: 25px; } }
    .our-guide__item-title {
      font-weight: 700;
      font-size: 23px;
      line-height: 30px; }
      @media (min-width: 1025px) {
        .our-guide__item-title {
          margin-bottom: 35px;
          font-size: 30px;
          line-height: 37px; } }
    .our-guide__item-introtext {
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 25px;
      display: none; }
      @media (min-width: 430px) {
        .our-guide__item-introtext {
          display: block; } }
      @media (min-width: 768px) and (max-width: 1024.98px) {
        .our-guide__item-introtext {
          display: none; } }
    .our-guide__item-more {
      text-transform: uppercase;
      font-size: 15px;
      font-weight: 700;
      display: none; }
      @media (min-width: 430px) {
        .our-guide__item-more {
          display: block; } }
      @media (min-width: 768px) and (max-width: 1024.98px) {
        .our-guide__item-more {
          display: none; } }
      .our-guide__item-more img {
        margin-left: 15px; }

.slider-spec {
  margin-bottom: 125px;
  padding-left: 15px; }
  @media (max-width: 399px) {
    .slider-spec {
      padding-right: 15px; } }
  @media (min-width: 1440px) {
    .slider-spec {
      padding-left: calc((100% - 1410px) / 2); } }
  .slider-spec__title {
    font-size: 18px;
    font-weight: 700; }
  .slider-spec .swiper-wrapper {
    padding-top: 23px;
    height: auto; }
  .slider-spec .swiper-slide {
    height: auto; }

.slide-spec {
  min-height: 300px;
  border-radius: 10px;
  color: #fff;
  padding: 35px;
  position: relative;
  overflow: hidden;
  transition: transform .2s ease-in-out; }
  @media (min-width: 1025px) {
    .slide-spec:hover {
      transform: translateY(-15px); } }
  .slide-spec:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-repeat: no-repeat; }
  .slide-spec.item-881 {
    background-color: #80C1DB; }
    .slide-spec.item-881:after {
      top: auto;
      bottom: -30px;
      right: -30px;
      background-image: url("/templates/main/images/static/medycyna.png"); }
  .slide-spec.item-883 {
    background-color: #A497C9; }
  .slide-spec.item-884 {
    background-color: #91D2AF; }
    .slide-spec.item-884:after {
      background-position: right;
      background-image: url("/templates/main/images/static/dieta.png"); }
  .slide-spec.item-885 {
    background-color: #F69383; }
    .slide-spec.item-885:after {
      background-size: 53%;
      background-position: right;
      background-image: url("/templates/main/images/static/energy.png");
      top: -50px; }
      @media (max-width: 1024.98px) {
        .slide-spec.item-885:after {
          top: -60px;
          right: -40px; } }
  .slide-spec.item-886 {
    background-color: #FCBB7E; }
    .slide-spec.item-886:after {
      background-position: 85% 0;
      background-image: url("/templates/main/images/static/psycho.png"); }
  .slide-spec__title {
    font-weight: 700;
    font-size: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 40px;
    position: relative;
    z-index: 3; }
    .slide-spec__title span {
      font-size: 17px;
      display: block;
      letter-spacing: 0.2em;
      font-weight: 400; }
  .slide-spec__list {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 700;
    position: relative;
    z-index: 3; }

.therapists-hp {
  margin-bottom: 60px; }
  .therapists-hp__title {
    font-size: 25px;
    font-weight: 700;
    padding-bottom: 25px;
    margin-bottom: 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1); }

.white .therapists-hp {
  margin-top: -200px; }
  .white .therapists-hp__title {
    color: #fff; }

.e-commerce--wide {
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
  padding: 50px 0;
  background-color: #fff;
  line-height: normal; }
  .e-commerce--wide .e-commerce__title {
    font-size: 22px;
    letter-spacing: 0.04em;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    margin-bottom: 30px; }
    .e-commerce--wide .e-commerce__title span {
      display: inline-block;
      background-color: #fff;
      padding-right: 50px;
      position: relative;
      z-index: 3;
      padding-top: 2px; }
    .e-commerce--wide .e-commerce__title:after {
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      background-color: #FB3636;
      position: absolute;
      margin: auto; }
  .e-commerce--wide .e-commerce__container {
    padding: 0 calc((100% - 1410px) / 2); }
    .e-commerce--wide .e-commerce__container .swiper-button-next,
    .e-commerce--wide .e-commerce__container .swiper-button-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 135px;
      height: 135px;
      background-color: #fff;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
      background-image: none;
      border-radius: 50%;
      margin: auto;
      transition: opacity .2s ease-in-out; }
      .e-commerce--wide .e-commerce__container .swiper-button-next img,
      .e-commerce--wide .e-commerce__container .swiper-button-prev img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 27px;
        height: 27px; }
      .e-commerce--wide .e-commerce__container .swiper-button-next.swiper-button-disabled,
      .e-commerce--wide .e-commerce__container .swiper-button-prev.swiper-button-disabled {
        opacity: 0; }
    .e-commerce--wide .e-commerce__container .swiper-button-next {
      right: -70px; }
      .e-commerce--wide .e-commerce__container .swiper-button-next img {
        left: 25px;
        right: auto; }
    .e-commerce--wide .e-commerce__container .swiper-button-prev {
      left: -70px; }
      .e-commerce--wide .e-commerce__container .swiper-button-prev img {
        right: 25px;
        left: auto; }
  .e-commerce--wide .e-commerce__item-image {
    margin-bottom: 20px; }
  .e-commerce--wide .e-commerce__item-category {
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 18px;
    color: #FB3636;
    padding: 0 15px; }
  .e-commerce--wide .e-commerce__item-name {
    font-size: 17px;
    font-weight: 700;
    padding: 0 15px; }
  .e-commerce--wide .e-commerce__item--slider {
    width: 360px;
    padding: 0 15px;
    text-align: center; }

.e-commerce--article-transparent {
  padding: 50px 0;
  overflow: hidden;
  line-height: normal; }
  .e-commerce--article-transparent .e-commerce__title {
    font-size: 13px;
    letter-spacing: 0.04em;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    margin: 0 0 18px; }
    .e-commerce--article-transparent .e-commerce__title span {
      display: inline-block;
      background-color: #fff;
      padding-right: 35px;
      position: relative;
      z-index: 3;
      padding-top: 2px; }
    .e-commerce--article-transparent .e-commerce__title:after {
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      background-color: #FB3636;
      position: absolute;
      margin: auto; }
  .e-commerce--article-transparent .e-commerce__container .swiper-button-next,
  .e-commerce--article-transparent .e-commerce__container .swiper-button-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 80px;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
    background-image: none;
    border-radius: 50%;
    margin: auto;
    transition: opacity .2s ease-in-out; }
    .e-commerce--article-transparent .e-commerce__container .swiper-button-next img,
    .e-commerce--article-transparent .e-commerce__container .swiper-button-prev img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 20px;
      height: 20px; }
    .e-commerce--article-transparent .e-commerce__container .swiper-button-next.swiper-button-disabled,
    .e-commerce--article-transparent .e-commerce__container .swiper-button-prev.swiper-button-disabled {
      opacity: 0; }
  .e-commerce--article-transparent .e-commerce__container .swiper-button-next {
    right: -40px; }
    .e-commerce--article-transparent .e-commerce__container .swiper-button-next img {
      left: 15px;
      right: auto; }
  .e-commerce--article-transparent .e-commerce__container .swiper-button-prev {
    left: -40px; }
    .e-commerce--article-transparent .e-commerce__container .swiper-button-prev img {
      right: 15px;
      left: auto; }
  .e-commerce--article-transparent .e-commerce__item-link {
    text-decoration: none;
    color: #1A1B1C !important; }
  .e-commerce--article-transparent .e-commerce__item-image {
    margin-bottom: 20px; }
  .e-commerce--article-transparent .e-commerce__item-category {
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 12px;
    color: #FB3636;
    padding: 0 15px; }
  .e-commerce--article-transparent .e-commerce__item-name {
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    padding: 0 15px; }
  .e-commerce--article-transparent .e-commerce__item--slider {
    width: 242px;
    text-align: center;
    padding: 0 15px; }

.e-commerce--article {
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
  padding: 30px 0;
  background-color: #fff;
  margin-bottom: 50px;
  border-radius: 20px;
  overflow: hidden;
  line-height: normal; }
  .e-commerce--article .e-commerce__title {
    font-size: 13px;
    letter-spacing: 0.04em;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    margin: 0 20px 18px; }
    .e-commerce--article .e-commerce__title span {
      display: inline-block;
      background-color: #fff;
      padding-right: 35px;
      position: relative;
      z-index: 3;
      padding-top: 2px; }
    .e-commerce--article .e-commerce__title:after {
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      background-color: #FB3636;
      position: absolute;
      margin: auto; }
  .e-commerce--article .e-commerce__container {
    padding: 0 20px; }
    .e-commerce--article .e-commerce__container .swiper-button-next,
    .e-commerce--article .e-commerce__container .swiper-button-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 80px;
      height: 80px;
      background-color: #fff;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
      background-image: none;
      border-radius: 50%;
      margin: auto;
      transition: opacity .2s ease-in-out; }
      .e-commerce--article .e-commerce__container .swiper-button-next img,
      .e-commerce--article .e-commerce__container .swiper-button-prev img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 20px;
        height: 20px; }
      .e-commerce--article .e-commerce__container .swiper-button-next.swiper-button-disabled,
      .e-commerce--article .e-commerce__container .swiper-button-prev.swiper-button-disabled {
        opacity: 0; }
    .e-commerce--article .e-commerce__container .swiper-button-next {
      right: -40px; }
      .e-commerce--article .e-commerce__container .swiper-button-next img {
        left: 15px;
        right: auto; }
    .e-commerce--article .e-commerce__container .swiper-button-prev {
      left: -40px; }
      .e-commerce--article .e-commerce__container .swiper-button-prev img {
        right: 15px;
        left: auto; }
  .e-commerce--article .e-commerce__item-link {
    text-decoration: none;
    color: #1A1B1C !important; }
  .e-commerce--article .e-commerce__item-image {
    margin-bottom: 20px; }
  .e-commerce--article .e-commerce__item-category {
    font-size: 11px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-bottom: 12px;
    color: #FB3636;
    padding: 0 15px; }
  .e-commerce--article .e-commerce__item-name {
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    padding: 0 15px; }
  .e-commerce--article .e-commerce__item--slider {
    width: 242px;
    text-align: center;
    padding: 0 15px; }

.e-commerce--column {
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
  padding: 30px 0;
  background-color: #fff;
  margin-bottom: 50px;
  border-radius: 20px;
  overflow: hidden;
  line-height: normal;
  position: relative;
  z-index: 3; }
  .e-commerce--column .e-commerce__title {
    letter-spacing: 0.04em;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
    margin: 0 20px 18px;
    font-size: 15px; }
    @media (min-width: 576px) {
      .e-commerce--column .e-commerce__title {
        font-size: 22px; } }
    .e-commerce--column .e-commerce__title span {
      display: inline-block;
      background-color: #fff;
      padding-right: 35px;
      position: relative;
      z-index: 3;
      padding-top: 2px; }
    .e-commerce--column .e-commerce__title:after {
      content: "";
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      background-color: #FB3636;
      position: absolute;
      margin: auto; }
  .e-commerce--column .e-commerce__container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start; }
    .e-commerce--column .e-commerce__container .swiper-button-next,
    .e-commerce--column .e-commerce__container .swiper-button-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 80px;
      height: 80px;
      background-color: #fff;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
      background-image: none;
      border-radius: 50%;
      margin: auto;
      transition: opacity .2s ease-in-out; }
      .e-commerce--column .e-commerce__container .swiper-button-next img,
      .e-commerce--column .e-commerce__container .swiper-button-prev img {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;
        width: 20px;
        height: 20px; }
      .e-commerce--column .e-commerce__container .swiper-button-next.swiper-button-disabled,
      .e-commerce--column .e-commerce__container .swiper-button-prev.swiper-button-disabled {
        opacity: 0; }
    .e-commerce--column .e-commerce__container .swiper-button-next {
      right: -40px; }
      .e-commerce--column .e-commerce__container .swiper-button-next img {
        left: 15px;
        right: auto; }
    .e-commerce--column .e-commerce__container .swiper-button-prev {
      left: -40px; }
      .e-commerce--column .e-commerce__container .swiper-button-prev img {
        right: 15px;
        left: auto; }
  .e-commerce--column .e-commerce__item {
    width: 100%;
    padding: 0 10px;
    text-align: center;
    margin-bottom: 20px; }
    @media (min-width: 360px) {
      .e-commerce--column .e-commerce__item {
        max-width: 50%; } }
    @media (min-width: 576px) {
      .e-commerce--column .e-commerce__item {
        max-width: calc(100% / 3); } }
    @media (min-width: 1025px) {
      .e-commerce--column .e-commerce__item {
        max-width: 50%; } }
    .e-commerce--column .e-commerce__item-link {
      text-decoration: none;
      color: #1A1B1C !important; }
    .e-commerce--column .e-commerce__item-image {
      margin-bottom: 20px; }
    .e-commerce--column .e-commerce__item-category {
      font-size: 11px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      margin-bottom: 9px;
      color: #FB3636;
      padding: 0 15px; }
    .e-commerce--column .e-commerce__item-name {
      font-size: 13px;
      line-height: 17px;
      font-weight: 700;
      padding: 0 15px; }
  .e-commerce--column .e-commerce__item--slider {
    width: 242px;
    text-align: center;
    padding: 0 15px; }

.e-commerce--column-slider {
  margin: 0 auto 50px;
  max-width: 370px; }
  .e-commerce--column-slider .swiper-container {
    max-height: 850px;
    height: 850px; }
  .e-commerce--column-slider .swiper-slide .e-commerce__item {
    width: 100%;
    max-width: none;
    padding-bottom: 20px; }
    .e-commerce--column-slider .swiper-slide .e-commerce__item img {
      border-radius: 15px; }
  .e-commerce--column-slider .e-commerce__container.swiper-container .swiper-button-prev {
    top: -40px;
    left: 0;
    right: 0;
    bottom: auto;
    margin: auto;
    transform: rotate(90deg); }
  .e-commerce--column-slider .e-commerce__container.swiper-container .swiper-button-next {
    top: auto;
    left: 0;
    right: 0;
    bottom: -40px;
    margin: auto;
    transform: rotate(90deg); }

.reports-module {
  background: linear-gradient(135deg, #284666 0%, #284666 100%);
  border-radius: 20px;
  padding: 30px 20px 36px 20px;
  max-width: 370px;
  margin: 0 auto 35px auto;
  position: relative; }
  .reports-module__title {
    font-size: 23px;
    line-height: 23px;
    padding-bottom: 13px;
    font-weight: 700;
    color: #fff;
    border-bottom: 3px solid #35B7C1;
    margin-bottom: 21px; }
  .reports-module__elements-el {
    margin-bottom: 20px;
    position: relative;
    border-radius: 20px;
    display: block; }
    .reports-module__elements-el img {
      border-radius: 20px; }
    .reports-module__elements-el::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(26, 27, 28, 0.25) 0%, rgba(26, 27, 28, 0.85) 100%);
      border-radius: 20px; }
    .reports-module__elements-el-text {
      position: absolute;
      bottom: 0;
      left: 0;
      padding-bottom: 15px;
      color: #fff;
      text-align: center;
      width: 100%;
      z-index: 1; }
      .reports-module__elements-el-text-title {
        font-size: 20px;
        line-height: 30px;
        font-weight: 700; }
      .reports-module__elements-el-text-firm {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase; }
        .reports-module__elements-el-text-firm-title {
          margin-top: 3px; }
        .reports-module__elements-el-text-firm-logo {
          padding: 4px 12px 6px 11px;
          background-color: #fff;
          border-radius: 4px;
          margin-left: 7px; }
          .reports-module__elements-el-text-firm-logo img {
            display: block; }
  .reports-module__btn {
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.33);
    font-size: 16px;
    line-height: 21px;
    font-weight: 700;
    color: #fff;
    max-width: 276px;
    width: 100%;
    text-align: center;
    padding: 20px 15px 19px 15px;
    margin: 0 auto;
    margin-top: 34px;
    display: block; }
    .reports-module__btn:hover {
      color: #fff; }

.city-list {
  display: flex;
  flex-wrap: wrap;
  color: #1A1B1C;
  padding: 0; }
  .city-list.open li:nth-child(n) {
    display: block; }
  .city-list.open .open {
    display: inline; }
  .city-list.open .close {
    display: none; }
  .city-list.open li.city-list__more {
    display: none; }
  .city-list .open {
    display: none; }
  .city-list .city-list__title {
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 1;
    padding-left: 0; }
    .city-list .city-list__title:before {
      display: none; }
  .city-list li {
    list-style: none;
    opacity: 0.62;
    margin-right: 10px;
    position: relative;
    padding-left: 11px; }
    .city-list li:nth-child(2) {
      padding-left: 0; }
      .city-list li:nth-child(2):before {
        display: none; }
    .city-list li:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      width: 4px;
      height: 4px;
      background-color: #fff;
      display: block;
      border-radius: 50%; }
    .city-list li.current, .city-list li.active {
      opacity: 1;
      font-weight: 700; }
      .city-list li.current:before, .city-list li.active:before {
        opacity: 0.62; }
    .city-list li:nth-child(n + 20) {
      display: none; }
    .city-list li:last-child {
      display: block; }
  .city-list .city-list__more {
    opacity: 1;
    text-transform: uppercase;
    cursor: pointer;
    font-weight: 700; }

.subscription-neboa {
  max-width: 1010px;
  margin: 0 auto 30px; }
  .subscription-neboa a {
    display: block; }
  .subscription-neboa img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 0 auto; }

.job-offer {
  color: var(--colorFont);
  background-color: var(--colorBg);
  border: 2px solid var(--colorFont);
  border-radius: 24px;
  padding: 15px;
  margin-bottom: 30px;
  font-weight: 700;
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.16); }
  .job-offer a {
    display: block; }
    @media (min-width: 486px) {
      .job-offer a {
        display: flex; } }
  .job-offer__text {
    margin: 0;
    max-width: 625px; }
    @media (min-width: 486px) {
      .job-offer__text {
        margin-left: 30px; } }
  .job-offer__title {
    font-size: 18px;
    background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
    color: #ffffff;
    padding: 16px 22px 11px 22px;
    text-transform: uppercase;
    max-width: 253px;
    border-radius: 5px;
    text-align: center;
    margin: 20px auto; }
    @media (min-width: 486px) {
      .job-offer__title {
        margin: 0;
        margin-bottom: 20px; } }
    @media (min-width: 768px) {
      .job-offer__title {
        font-size: 22px; } }
  .job-offer__description {
    font-size: 15px;
    text-align: center; }
    @media (min-width: 486px) {
      .job-offer__description {
        text-align: left; } }
  .job-offer__image {
    text-align: center; }
    .job-offer__image img {
      border-radius: 15px;
      min-width: 150px;
      max-width: 600px;
      max-height: 150px;
      width: 100%;
      object-fit: cover; }

.vertical a {
  display: block; }

.vertical .job-offer__description {
  text-align: center; }

.vertical .job-offer__text {
  margin: 0 auto; }

.vertical .job-offer__title {
  text-align: center;
  font-size: 18px;
  margin: 20px auto; }

[data-dark-mode="true"] .select-menu.open .select-menu__selected,
[data-dark-mode="true"] .select-menu.open .select-menu__list,
[data-dark-mode="true"] .select-menu.open .select-menu__list-wrap {
  background-color: #284666;
  color: #ffffff; }

[data-dark-mode="true"] .select-menu.open .close {
  display: block; }

[data-dark-mode="true"] .select-menu.open .open {
  display: none; }

.select-menu {
  position: relative;
  width: 100%; }
  .select-menu.open .open {
    display: block; }
  .select-menu.open .close {
    display: none; }
  .select-menu.open:after {
    bottom: -400px; }
  .select-menu.open .select-menu__selected {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: #ffffff;
    color: #1A1B1C; }
    .select-menu.open .select-menu__selected:after {
      content: "";
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 3; }
    .select-menu.open .select-menu__selected img {
      transform: rotate(180deg); }
  .select-menu.open .select-menu__list-wrap {
    display: block; }
  .select-menu:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    pointer-events: none; }
  .select-menu .open {
    display: none; }
  .select-menu__selected {
    color: #fff;
    height: 57px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.02em;
    user-select: none;
    position: relative;
    z-index: 6;
    width: 330px;
    height: 48px;
    padding: 5px 20px 5px 30px;
    cursor: pointer;
    justify-content: space-between;
    border-radius: 14px;
    background: -moz-linear-gradient(45deg, #f16655 0%, #fb3636 100%);
    background: -webkit-linear-gradient(45deg, #f16655 0%, #fb3636 100%);
    background: -o-linear-gradient(45deg, #f16655 0%, #fb3636 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FB3636', endColorstr='#F16655', GradientType=1 );
    background: -ms-linear-gradient(45deg, #f16655 0%, #fb3636 100%);
    background: linear-gradient(45deg, #f16655 0%, #fb3636 100%); }
    .select-menu__selected img {
      display: block;
      max-width: 100%;
      height: auto; }
    .select-menu__selected * {
      pointer-events: none; }
  .select-menu__list {
    height: 100%; }
    .select-menu__list-wrap {
      padding: 15px 30px 25px;
      width: 100%;
      height: 400px;
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #fff;
      z-index: 6;
      overflow: hidden;
      border-bottom-right-radius: 14px;
      border-bottom-left-radius: 14px;
      color: #1A1B1C; }
  .select-menu__item {
    display: block;
    padding: 6px 0;
    font-size: 16px;
    font-weight: 400;
    transition: color .2s ease-in-out; }
    .select-menu__item:hover, .select-menu__item.active {
      color: #CD1719; }
    .select-menu__item:first-child {
      padding-top: 0; }
    .select-menu__item:last-child {
      padding-bottom: 0; }
  .select-menu .simplebar-vertical.simplebar-track {
    width: 5px;
    right: -6px; }
    .select-menu .simplebar-vertical.simplebar-track:after {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 1px;
      background-color: #DBDBDB; }
  .select-menu .simplebar-vertical .simplebar-scrollbar:before {
    background-color: #1A1B1C;
    left: 0;
    right: 0;
    border-radius: 0; }

.article-list__top {
  background-color: transparent;
  padding-bottom: 170px; }
  .article-list__top .breadcrumb {
    color: #1A1B1C; }
  .article-list__top--problems {
    padding-bottom: 60px; }
  .article-list__top-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center; }
    @media (min-width: 768px) {
      .article-list__top-container {
        justify-content: space-between; } }
  .article-list__top--guide {
    padding-bottom: 80px; }
    @media (max-width: 767.98px) {
      .article-list__top--guide {
        padding-top: 15px; } }

.article-list__header {
  color: #1A1B1C;
  font-weight: 700;
  margin: 0;
  padding: 0;
  width: 100%;
  margin-bottom: 15px;
  text-align: center;
  font-size: 36px; }
  @media (min-width: 576px) {
    .article-list__header {
      font-size: 40px; } }
  @media (min-width: 768px) {
    .article-list__header {
      text-align: left; } }
  @media (min-width: 1025px) {
    .article-list__header {
      width: auto;
      margin-bottom: 0; } }
  @media (min-width: 1280px) {
    .article-list__header {
      font-size: 58px; } }

.article-list__category {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  padding-bottom: 16px;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 100%;
  color: #002A43;
  position: relative;
  z-index: 3;
  font-size: 16px;
  padding: 0 30px 16px; }
  @media (min-width: 460px) {
    .article-list__category {
      padding: 0 15px 16px;
      font-size: 18px; } }
  .article-list__category:after {
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 0;
    background-color: #FB3636;
    height: 1px; }

.article-list__articles {
  margin: -85px -15px 100px;
  position: relative; }
  .article-list__articles:after {
    content: "";
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: -30px;
    top: -30px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0 33px 99px rgba(0, 0, 0, 0.16); }
    @media (min-width: 460px) {
      .article-list__articles:after {
        display: none; } }
  .article-list__articles--problems {
    margin-top: 30px; }
    .article-list__articles--problems:after {
      top: 0; }
  .article-list__articles--spec {
    margin-top: 60px; }

.article-list__item-wrap {
  padding: 0 15px;
  width: 100%;
  max-width: 100%;
  position: relative;
  z-index: 3;
  margin-bottom: 10px; }
  @media (min-width: 460px) {
    .article-list__item-wrap {
      margin-bottom: 30px;
      max-width: 50%; } }
  @media (min-width: 576px) {
    .article-list__item-wrap {
      max-width: calc(100% / 3); } }
  @media (min-width: 1025px) {
    .article-list__item-wrap {
      max-width: 25%; } }
  @media (min-width: 1200px) {
    .article-list__item-wrap {
      max-width: 20%; } }
  @media (min-width: 1440px) {
    .article-list__item-wrap {
      max-width: calc(100% / 6); } }

.article-list__item {
  display: block;
  width: 100%;
  background-color: #fff;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  padding: 0 30px; }
  @media (min-width: 460px) {
    .article-list__item {
      padding: 0;
      padding-top: 100%;
      box-shadow: 0 33px 99px rgba(0, 0, 0, 0.16); } }
  .article-list__item-title {
    top: 5px;
    left: 5px;
    bottom: 5px;
    right: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    color: #294868;
    font-size: 17px;
    font-weight: 700;
    pointer-events: none;
    transition: color .2s ease-in-out;
    text-align: left; }
    @media (min-width: 460px) {
      .article-list__item-title {
        text-align: center;
        justify-content: center;
        position: absolute; } }
    .article-list__item-title span {
      display: block;
      text-align: center; }
      .article-list__item-title span::first-letter {
        text-transform: uppercase; }
  .article-list__item-image {
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity .7s ease-in-out;
    display: none; }
    @media (min-width: 460px) {
      .article-list__item-image {
        position: absolute;
        display: block; } }
    .article-list__item-image:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(26, 27, 28, 0.4); }
  @media (min-width: 1025px) {
    .article-list__item:hover .article-list__item-title {
      color: #fff; }
    .article-list__item:hover .article-list__item-image {
      opacity: 1; } }

@media (max-width: 1439.98px) {
  body:after {
    content: "";
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    opacity: 0;
    z-index: 2;
    transition: opacity .2s ease-in-out;
    pointer-events: none; } }

@media (max-width: 1439.98px) {
  .mobile-select-open:after {
    opacity: 1;
    pointer-events: all; } }

.firm-container {
  margin: -160px auto 100px; }
  @media (min-width: 768px) {
    .firm-container {
      margin: -140px auto 100px; } }
  @media (min-width: 1025px) {
    .firm-container {
      margin: -85px auto 100px; } }
  .firm-container--sort {
    margin: -360px auto 100px; }
    @media (min-width: 576px) {
      .firm-container--sort {
        margin: -280px auto 100px; } }
    @media (min-width: 600px) {
      .firm-container--sort {
        margin: -280px auto 100px; } }
    @media (min-width: 768px) {
      .firm-container--sort {
        margin: -280px auto 100px; } }
    @media (min-width: 1025px) {
      .firm-container--sort {
        margin: -175px auto 100px; } }

@media (max-width: 1024.98px) {
  .article-list__top.article-list__top--firm-list {
    padding-bottom: 290px; } }

@media (max-width: 599.98px) {
  .article-list__top.article-list__top--firm-list {
    padding-bottom: 280px; } }

@media (max-width: 575.98px) {
  .article-list__top.article-list__top--firm-list {
    padding-bottom: 360px; } }

@media (min-width: 768px) {
  .article-list__top.article-list__top--firm-list {
    padding-top: 0;
    padding-bottom: 285px; } }

@media (min-width: 1025px) {
  .article-list__top.article-list__top--firm-list {
    padding-bottom: 205px; } }

.article-list__top.article-list__top--firm-list .breadcrumb {
  margin-bottom: 20px; }

.therapist-list__header {
  padding-top: 10px;
  border-top: 3px solid #FB3636; }

.therapist-description {
  font-size: 18px;
  font-weight: 400;
  line-height: 33px;
  color: #1A1B1C;
  margin-bottom: 40px; }

.firm {
  min-width: 330px;
  font-family: "Objectivity", sans-serif; }
  .firm .top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 17px; }
    .firm .top .logo {
      max-width: 75px;
      border-radius: 10px;
      overflow: hidden;
      max-height: 75px;
      height: 75px;
      width: 100%;
      position: relative;
      margin-right: 13px; }
      .firm .top .logo img {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
        position: absolute;
        left: -9999px;
        right: -9999px;
        top: -9999px;
        bottom: -9999px;
        margin: auto; }
    .firm .top .name {
      font-size: 16px;
      font-weight: 700;
      margin: 0;
      padding: 0;
      margin-bottom: 6.5px; }
  .firm .address, .firm .tel, .firm .contact, .firm .www {
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
    color: #1A1B1C;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%; }
    .firm .address:before, .firm .tel:before, .firm .contact:before, .firm .www:before {
      content: "";
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;
      position: static;
      background: none;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain; }
  .firm .address:before {
    background-image: url("/templates/main/images/svg/address-pin.svg"); }
  .firm .tel:before {
    background-image: url("/templates/main/images/svg/phone.svg"); }
  .firm .contact:before {
    background-image: url("/templates/main/images/svg/mail.svg"); }
  .firm .www {
    margin-bottom: 0; }
    .firm .www:before {
      background-image: url("/templates/main/images/svg/external-link.svg"); }
  .firm .rating {
    font-size: 13px;
    font-weight: 400;
    color: #002B44;
    opacity: 0.5; }

.firm-list__empty {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 45px;
  margin-top: 160px; }
  @media (min-width: 1025px) {
    .firm-list__empty {
      margin-top: 120px; } }
  .firm-list__empty-text {
    margin-bottom: 45px; }
  .firm-list__empty a {
    color: #FB3636; }

.firm-list .lower {
  text-transform: lowercase; }

.firm-list .img-avatar {
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  max-height: 165px; }

.firm-list__mobile-btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%; }
  @media (min-width: 1025px) {
    .firm-list__mobile-btns {
      display: none; } }

.firm-list__mobile-btn {
  margin-top: 10px;
  height: 40px;
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.4);
  max-width: calc(50% - 3px);
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.4);
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer; }
  .firm-list__mobile-btn:hover, .firm-list__mobile-btn:hover a {
    color: rgba(255, 255, 255, 0.4); }
  .firm-list__mobile-btn--white {
    background-color: rgba(255, 255, 255, 0.4);
    color: #fff;
    border: none; }
    .firm-list__mobile-btn--white:hover, .firm-list__mobile-btn--white:hover a {
      color: #fff; }

.firm-list__select {
  opacity: 0;
  transition: opacity .2s ease-in-out;
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
  border: 2px solid rgba(255, 255, 255, 0.4);
  position: relative; }
  @media (min-width: 1025px) {
    .firm-list__select {
      border: none;
      border-radius: 0;
      margin-top: 0;
      max-width: calc(100% / 3 - 7px); } }
  .firm-list__select #select-type {
    z-index: 15; }
  .firm-list__select #select-location {
    z-index: 12; }
    .firm-list__select #select-location .simplebar-track.simplebar-vertical {
      right: 37px;
      bottom: 15px; }
      .firm-list__select #select-location .simplebar-track.simplebar-vertical .simplebar-scrollbar {
        background-color: #fff;
        border-radius: 4px; }
        .firm-list__select #select-location .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
          display: none; }
      .firm-list__select #select-location .simplebar-track.simplebar-vertical:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        left: 0;
        right: 0;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.2); }
  .firm-list__select #select-sort {
    z-index: 9; }
  .firm-list__select.loaded {
    opacity: 1; }
  .firm-list__select.selected .reset-link {
    display: block;
    position: absolute;
    width: 22px;
    height: 22px;
    right: 30px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    z-index: 18;
    opacity: 0.6;
    cursor: pointer; }
    .firm-list__select.selected .reset-link span {
      position: absolute;
      width: 75%;
      height: 2px;
      transform-origin: 50% 50%;
      background-color: #fff;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; }
      .firm-list__select.selected .reset-link span:first-child {
        transform: rotate(45deg); }
      .firm-list__select.selected .reset-link span:last-child {
        transform: rotate(-45deg); }
  .firm-list__select .cs-select {
    position: relative;
    width: 100%; }
  .firm-list__select .cs-title {
    height: 40px;
    background-color: #fff;
    width: 100%;
    border: none;
    outline: none;
    color: #1A1B1C;
    font-size: 13px;
    font-weight: 700;
    padding-left: 20px;
    border-radius: 10px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .firm-list__select .cs-title span {
      position: relative;
      z-index: 9;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      width: 100%; }
      .firm-list__select .cs-title span a:nth-child(2) {
        position: relative;
        z-index: 20;
        display: inline-block;
        width: 20px;
        height: 20px; }
  .firm-list__select .cs-list-wrap {
    height: 0;
    transition: height .2s ease-in-out;
    overflow: hidden;
    top: 0px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    z-index: 3;
    filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.16)); }
    @media (min-width: 1025px) {
      .firm-list__select .cs-list-wrap {
        position: absolute;
        padding-top: 40px; } }
    .firm-list__select .cs-list-wrap:after {
      position: absolute; }
  .firm-list__select .cs-list {
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
    color: #1A1B1C;
    list-style: none;
    margin: 0;
    padding: 0 15px 15px;
    width: calc(100% + 20px);
    font-size: 15px; }
  .firm-list__select .cs-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 12;
    cursor: pointer; }
    .firm-list__select .cs-btn:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      background-image: url("/templates/main/images/svg/chevron-down-black.svg");
      margin: auto;
      width: 15px;
      height: 8px;
      background-repeat: no-repeat;
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
      transition: transform .2s ease-in-out; }
  .firm-list__select .cs-select.open .cs-btn:after {
    transform: rotate(180deg); }
  .firm-list__select #select-location.open .cs-list-wrap {
    height: 310px; }
  .firm-list__select #select-type.open .cs-list-wrap {
    height: 85px; }
    @media (min-width: 1025px) {
      .firm-list__select #select-type.open .cs-list-wrap {
        height: 128px; } }
  .firm-list__select #select-sort.open .cs-list-wrap {
    height: 105px; }
    @media (min-width: 1025px) {
      .firm-list__select #select-sort.open .cs-list-wrap {
        height: 170px; } }

.firm-list__tools {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }
  @media (min-width: 1025px) {
    .firm-list__tools {
      justify-content: space-between; } }
  .firm-list__tools--select .firm-list__map-btn {
    height: 60px;
    border-radius: 16px;
    background-color: #fff;
    width: 140px;
    margin: 0; }
    @media (min-width: 576px) {
      .firm-list__tools--select .firm-list__map-btn {
        margin: 0 5px; } }
    @media (min-width: 1025px) {
      .firm-list__tools--select .firm-list__map-btn {
        margin: 0 0 0 10px; } }
    @media (min-width: 1200px) {
      .firm-list__tools--select .firm-list__map-btn {
        display: none; } }
    .firm-list__tools--select .firm-list__map-btn img, .firm-list__tools--select .firm-list__map-btn svg {
      margin-right: 12px; }
  .firm-list__tools--select #filter-sort {
    display: none; }
  .firm-list__tools--select #select-type .simplebar-track.simplebar-vertical {
    right: 15px; }
  .firm-list__tools--select #select-type.open .cs-list-wrap {
    height: 80px; }
    @media (min-width: 1366px) {
      .firm-list__tools--select #select-type.open .cs-list-wrap {
        height: 153px; } }
  .firm-list__tools--select #select-location .simplebar-track.simplebar-vertical {
    right: 15px; }
  .firm-list__tools--select .firm-list__select {
    order: 4;
    border-radius: 16px;
    width: 100%;
    max-width: none;
    border: 2px solid rgba(0, 0, 0, 0.4);
    margin-top: 0; }
    @media (min-width: 1440px) {
      .firm-list__tools--select .firm-list__select {
        border: none; } }
    .firm-list__tools--select .firm-list__select .cs-btn {
      height: 50px; }
      @media (min-width: 1440px) {
        .firm-list__tools--select .firm-list__select .cs-btn {
          height: 60px; } }
      .firm-list__tools--select .firm-list__select .cs-btn:after {
        right: 10px; }
    .firm-list__tools--select .firm-list__select .cs-select {
      border-radius: 16px; }
      .firm-list__tools--select .firm-list__select .cs-select:after {
        border-radius: 16px; }
      .firm-list__tools--select .firm-list__select .cs-select .simplebar-track.simplebar-vertical {
        right: 15px; }
    .firm-list__tools--select .firm-list__select .cs-title {
      border-radius: 16px;
      padding-left: 15px;
      height: 50px; }
      @media (min-width: 1440px) {
        .firm-list__tools--select .firm-list__select .cs-title {
          height: 60px; } }
    .firm-list__tools--select .firm-list__select .cs-list {
      padding-left: 15px; }
    .firm-list__tools--select .firm-list__select .cs-list-wrap {
      border-radius: 16px;
      position: relative;
      padding-top: 0; }
      @media (min-width: 1440px) {
        .firm-list__tools--select .firm-list__select .cs-list-wrap {
          padding-top: 60px;
          position: absolute; } }
    .firm-list__tools--select .firm-list__select:first-child {
      margin-right: 0;
      margin-top: 0;
      margin-bottom: 20px; }
      @media (min-width: 1440px) {
        .firm-list__tools--select .firm-list__select:first-child {
          margin-right: 10px;
          margin-bottom: 0; } }
  .firm-list__tools--select .main__select-wrap {
    padding: 0;
    color: #1A1B1C;
    width: 100%;
    max-width: 290px;
    margin: 0 0 20px; }
    @media (min-width: 600px) {
      .firm-list__tools--select .main__select-wrap {
        margin: 0 5px 20px;
        max-width: 275px; } }
    @media (min-width: 1025px) {
      .firm-list__tools--select .main__select-wrap {
        margin: 0 0 20px; } }
    @media (min-width: 1440px) {
      .firm-list__tools--select .main__select-wrap {
        margin: 0;
        width: auto;
        width: 100%; } }
    .firm-list__tools--select .main__select-wrap .cs-btn {
      height: 60px; }
      .firm-list__tools--select .main__select-wrap .cs-btn:after {
        right: 10px; }
    @media (min-width: 1025px) {
      .firm-list__tools--select .main__select-wrap:first-child {
        margin-right: 20px; } }
    @media (min-width: 1366px) {
      .firm-list__tools--select .main__select-wrap:first-child {
        margin-right: 10px; } }
    @media (min-width: 1025px) {
      .firm-list__tools--select .main__select-wrap:nth-child(2) {
        margin-right: 0; } }
    @media (min-width: 1366px) {
      .firm-list__tools--select .main__select-wrap:nth-child(2) {
        margin-right: 10px; } }
    .firm-list__tools--select .main__select-wrap .cs-select {
      border-radius: 16px; }
      .firm-list__tools--select .main__select-wrap .cs-select:after {
        border-radius: 16px;
        height: 60px; }
      .firm-list__tools--select .main__select-wrap .cs-select.open::after {
        height: 440px; }
      .firm-list__tools--select .main__select-wrap .cs-select .simplebar-track.simplebar-vertical {
        right: 15px; }
    .firm-list__tools--select .main__select-wrap .cs-select .cs-title--finder span {
      color: #1A1B1C; }
    .firm-list__tools--select .main__select-wrap .cs-select.cs-select--finder.open .cs-title {
      filter: none; }
    .firm-list__tools--select .main__select-wrap .cs-title {
      height: 60px;
      border-radius: 16px;
      padding-left: 15px;
      background: #fff;
      font-size: 13px;
      z-index: 1;
      filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.16)); }
    .firm-list__tools--select .main__select-wrap.selected .cs-title {
      background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%); }
      .firm-list__tools--select .main__select-wrap.selected .cs-title span {
        color: #fff; }
    .firm-list__tools--select .main__select-wrap.selected .cs-btn::after {
      background-image: url("/templates/main/images/svg/chevron-down.svg"); }
    .firm-list__tools--select .main__select-wrap.selected .cs-form input {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      color: #fff; }
    .firm-list__tools--select .main__select-wrap.selected .cs-form::after {
      background-image: url("/templates/main/images/svg/search-icon.svg"); }
    .firm-list__tools--select .main__select-wrap .cs-list {
      padding: 0 30px 0 15px;
      font-size: 15px; }
      .firm-list__tools--select .main__select-wrap .cs-list li {
        font-size: 15px; }
        .firm-list__tools--select .main__select-wrap .cs-list li a {
          font-size: 15px; }
      .firm-list__tools--select .main__select-wrap .cs-list .disable-click {
        text-transform: uppercase;
        font-weight: 700;
        letter-spacing: .1em;
        margin-bottom: 16px; }
        .firm-list__tools--select .main__select-wrap .cs-list .disable-click .disable-link {
          font-size: 13px;
          line-height: 18px;
          margin-bottom: 8px;
          opacity: 0.3;
          pointer-events: none; }
        .firm-list__tools--select .main__select-wrap .cs-list .disable-click ul {
          margin: 0;
          padding: 0; }
      .firm-list__tools--select .main__select-wrap .cs-list .subitem {
        text-transform: none;
        letter-spacing: normal;
        font-size: 16px;
        opacity: 1;
        font-weight: 400;
        margin-bottom: 0; }
        .firm-list__tools--select .main__select-wrap .cs-list .subitem-link {
          margin-bottom: 0;
          opacity: 1;
          pointer-events: all; }
    .firm-list__tools--select .main__select-wrap .cs-see-all {
      left: 15px;
      right: 15px; }
    .firm-list__tools--select .main__select-wrap .cs-select--selected-list.open:after {
      height: 460px; }
    .firm-list__tools--select .main__select-wrap .cs-select--selected-list.open .cs-list-wrap {
      height: 380px; }
  .firm-list__tools--select #filter-type {
    order: 3;
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.4);
    margin-bottom: 15px; }
    @media (min-width: 1440px) {
      .firm-list__tools--select #filter-type {
        max-width: 140px;
        border: none;
        margin-right: 10px;
        margin-bottom: 0; } }
    .firm-list__tools--select #filter-type .cs-list-wrap {
      position: relative;
      padding-top: 0; }
      @media (min-width: 1440px) {
        .firm-list__tools--select #filter-type .cs-list-wrap {
          position: absolute;
          padding-top: 60px; } }
    .firm-list__tools--select #filter-type.selected .reset-link {
      display: block !important; }
  .firm-list__tools--select .firm-list__wrap-select {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    position: relative;
    transition: height .2s ease-in-out;
    margin: 0 0 15px;
    max-width: 100%; }
    @media (min-width: 380px) {
      .firm-list__tools--select .firm-list__wrap-select {
        margin: 0 10px 15px; } }
    @media (min-width: 440px) {
      .firm-list__tools--select .firm-list__wrap-select {
        margin: 0 0 15px; } }
    @media (min-width: 1025px) {
      .firm-list__tools--select .firm-list__wrap-select {
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .firm-list__tools--select .firm-list__wrap-select {
        height: auto;
        overflow: visible;
        margin-right: 0; } }
    @media (max-width: 1365.98px) {
      .firm-list__tools--select .firm-list__wrap-select.open[data-element="select-type"] .firm-list__mobile-wrap, .firm-list__tools--select .firm-list__wrap-select.open[data-element="select-location"] .firm-list__mobile-wrap, .firm-list__tools--select .firm-list__wrap-select.open[data-element="select-sort"] .firm-list__mobile-wrap, .firm-list__tools--select .firm-list__wrap-select.open[data-element="firm-select-problems"] .firm-list__mobile-wrap, .firm-list__tools--select .firm-list__wrap-select.open[data-element="firm-select-spec"] .firm-list__mobile-wrap, .firm-list__tools--select .firm-list__wrap-select.open[data-element=""] .firm-list__mobile-wrap {
        height: 60px; } }
  .firm-list__tools--select .firm-list__main-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; }
    @media (min-width: 1025px) {
      .firm-list__tools--select .firm-list__main-wrap {
        justify-content: flex-start;
        width: calc(100% - 310px); } }
    @media (min-width: 1200px) {
      .firm-list__tools--select .firm-list__main-wrap {
        width: calc(100% - 140px); } }
    @media (min-width: 1440px) {
      .firm-list__tools--select .firm-list__main-wrap {
        width: 570px; } }
  .firm-list__tools--select .firm-list__wrap-right {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    align-items: flex-start;
    align-content: flex-start;
    width: 100%;
    bottom: 0;
    left: 0;
    justify-content: center;
    padding-bottom: 20px;
    position: static; }
    @media (min-width: 1025px) {
      .firm-list__tools--select .firm-list__wrap-right {
        background: transparent;
        width: auto;
        position: static;
        justify-content: flex-start; } }
    @media (min-width: 1440px) {
      .firm-list__tools--select .firm-list__wrap-right {
        width: calc(100% - 570px); } }
    .firm-list__tools--select .firm-list__wrap-right.selected .firm-list__mobile-select {
      border: none;
      background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%); }
      .firm-list__tools--select .firm-list__wrap-right.selected .firm-list__mobile-select img {
        opacity: 1; }
  .firm-list__tools--select .open .firm-list__mobile-wrap {
    z-index: 20; }
  .firm-list__tools--select .firm-list__mobile-wrap {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    border-radius: 10px;
    transition: height .2s ease-in-out;
    z-index: 7;
    position: relative;
    overflow: visible;
    height: 60px;
    padding: 0;
    border-radius: 16px;
    background-color: #002B44;
    width: 140px;
    margin-right: 10px; }
    @media (min-width: 576px) {
      .firm-list__tools--select .firm-list__mobile-wrap {
        margin: 0 5px; } }
    @media (min-width: 1025px) {
      .firm-list__tools--select .firm-list__mobile-wrap {
        margin: 0;
        background-color: transparent; } }
    @media (min-width: 1440px) {
      .firm-list__tools--select .firm-list__mobile-wrap {
        flex-grow: 1;
        flex-wrap: nowrap;
        width: auto; } }
    .firm-list__tools--select .firm-list__mobile-wrap.open {
      z-index: 20; }
  .firm-list__tools--select .firm-list__mobile-wrapper {
    border-radius: 16px;
    transition: height .2s ease-in-out;
    z-index: 7;
    display: block;
    position: absolute;
    display: flex;
    width: 290px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out;
    padding: 30px 24px;
    left: 0;
    bottom: calc(100% + 20px);
    z-index: 99; }
    @media (min-width: 576px) {
      .firm-list__tools--select .firm-list__mobile-wrapper {
        bottom: calc(100% + 10px); } }
    @media (min-width: 1025px) {
      .firm-list__tools--select .firm-list__mobile-wrapper {
        left: -75px;
        top: 70px;
        bottom: auto; } }
    @media (min-width: 1440px) {
      .firm-list__tools--select .firm-list__mobile-wrapper {
        position: static;
        background-color: transparent;
        align-items: flex-start;
        align-content: flex-start;
        justify-content: space-between;
        flex-grow: 1;
        flex-wrap: nowrap;
        width: auto;
        height: auto;
        opacity: 1;
        pointer-events: all;
        padding: 0; } }
    .firm-list__tools--select .firm-list__mobile-wrapper.open {
      opacity: 1;
      pointer-events: all; }
  @media (max-width: 1365.98px) {
    .firm-list__tools--select .firm-list__mobile-btn {
      display: flex; } }
  .firm-list__tools--select .firm-list__mobile-select {
    height: 60px;
    width: 100%;
    border: none;
    outline: none;
    color: #1A1B1C;
    font-size: 13px;
    font-weight: 700;
    padding-left: 20px;
    border-radius: 16px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    position: relative;
    text-align: center;
    justify-content: center;
    z-index: 9;
    padding: 0;
    background-color: transparent;
    filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.16));
    background-color: #fff; }
    .firm-list__tools--select .firm-list__mobile-select:after {
      display: none; }
    .firm-list__tools--select .firm-list__mobile-select img, .firm-list__tools--select .firm-list__mobile-select svg {
      margin-right: 12px;
      transition: opacity .2s ease-in-out, background .2s ease-in-out;
      opacity: 0.2; }
    .firm-list__tools--select .firm-list__mobile-select svg path {
      stroke: #1a1b1c; }
    .firm-list__tools--select .firm-list__mobile-select span {
      position: relative;
      z-index: 9; }
    @media (min-width: 1440px) {
      .firm-list__tools--select .firm-list__mobile-select {
        display: none; } }
    .firm-list__tools--select .firm-list__mobile-select.open {
      border: none;
      background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
      color: #fff; }
      .firm-list__tools--select .firm-list__mobile-select.open img {
        opacity: 1; }
      .firm-list__tools--select .firm-list__mobile-select.open svg path {
        stroke: #fff; }

.firm-list__wrap-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  position: relative;
  transition: height .2s ease-in-out;
  margin: 0 0 15px; }
  @media (min-width: 380px) {
    .firm-list__wrap-select {
      margin: 0 10px 15px; } }
  @media (min-width: 440px) {
    .firm-list__wrap-select {
      margin: 0 10px 15px 0; } }
  @media (min-width: 1025px) {
    .firm-list__wrap-select {
      height: auto;
      overflow: visible;
      margin-right: 0;
      max-width: calc(100% - 100px); } }
  @media (max-width: 1024.98px) {
    .firm-list__wrap-select.open .firm-list__mobile-wrap {
      height: 276px; }
    .firm-list__wrap-select.open[data-element="select-type"] .firm-list__mobile-wrap {
      height: 361px; }
    .firm-list__wrap-select.open[data-element="select-location"] .firm-list__mobile-wrap {
      height: 586px; }
    .firm-list__wrap-select.open[data-element="select-sort"] .firm-list__mobile-wrap {
      height: 381px; } }

.firm-list__mobile-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;
  justify-content: space-between;
  border-radius: 10px;
  transition: height .2s ease-in-out;
  z-index: 7; }
  @media (max-width: 1024.98px) {
    .firm-list__mobile-wrap {
      padding: 40px 30px 0px;
      background-color: #294868;
      position: absolute;
      height: 0;
      overflow: hidden; } }

.firm-list__map-btn {
  width: 90px;
  height: 40px;
  color: #1A1B1C;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 10px;
  filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.16));
  cursor: pointer;
  user-select: none;
  margin: 0 0 15px;
  background-color: #fff; }
  @media (min-width: 380px) {
    .firm-list__map-btn {
      margin: 0 10px 15px; } }
  @media (min-width: 440px) {
    .firm-list__map-btn {
      margin: 0 0 15px; } }
  .firm-list__map-btn.open {
    background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
    color: #fff;
    border: none; }
    .firm-list__map-btn.open img, .firm-list__map-btn.open svg {
      opacity: 1; }
    .firm-list__map-btn.open svg path, .firm-list__map-btn.open svg circle {
      stroke: #fff; }
  .firm-list__map-btn img, .firm-list__map-btn svg {
    opacity: 0.2;
    transition: opacity .2s ease-in-out;
    margin-right: 5px; }
  .firm-list__map-btn svg path, .firm-list__map-btn svg circle {
    stroke: #1a1b1c; }
  .firm-list__map-btn--right {
    width: 100%;
    margin: 0 0 30px;
    padding: 0;
    background: transparent;
    border: none;
    color: #1A1B1C;
    height: auto;
    position: relative;
    border-radius: 24px;
    overflow: hidden;
    cursor: pointer; }
    .firm-list__map-btn--right:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0.5); }
    .firm-list__map-btn--right img {
      margin: 0;
      padding: 0;
      opacity: 1;
      width: 100%; }
    .firm-list__map-btn--right .firm-list__map-btn-text {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center; }
      .firm-list__map-btn--right .firm-list__map-btn-text img {
        margin-bottom: 16px;
        width: 50px; }
      .firm-list__map-btn--right .firm-list__map-btn-text span {
        width: 100%;
        display: block;
        text-align: center; }

.firm-list__mobile-select {
  height: 40px;
  background-color: #294868;
  width: 100%;
  border: none;
  outline: none;
  color: #fff;
  font-size: 13px;
  font-weight: 700;
  padding-left: 20px;
  border-radius: 10px;
  user-select: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 9; }
  .firm-list__mobile-select span {
    position: relative;
    z-index: 9; }
  .firm-list__mobile-select:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    background-image: url("/templates/main/images/svg/chevron-down-black.svg");
    margin: auto;
    width: 15px;
    height: 8px;
    background-repeat: no-repeat;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    transition: transform .2s ease-in-out;
    z-index: 9; }
  @media (min-width: 1025px) {
    .firm-list__mobile-select {
      display: none; } }

.our-guide__title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  border-bottom: 3px solid #FB3636; }
  @media (max-width: 767.98px) {
    .our-guide__title .our-guide__breadcrumb {
      width: 100%; } }
  .our-guide__title .our-guide__breadcrumb .breadcrumb {
    font-weight: 400;
    margin: 0; }
  .our-guide__title .page-header__header {
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase; }
    @media (max-width: 767.98px) {
      .our-guide__title .page-header__header {
        width: 100%;
        text-align: center; } }
  @media (max-width: 767.98px) {
    .our-guide__title .article-list__article-select {
      margin: 0 auto 15px; } }
  .our-guide__title .cs-select.open::after {
    height: 382px; }

.our-guide__header-left {
  width: 100%;
  margin-bottom: 15px; }
  @media (min-width: 1025px) {
    .our-guide__header-left {
      width: auto;
      margin-bottom: 0; } }

.our-guide__list {
  margin-top: -50px; }
  .our-guide__list .article-img-top--guide:hover .article-img-top__image img {
    transform: scale(1.2); }
  .our-guide__list .article-img-top--guide .article-img-top__image {
    position: relative; }
    .our-guide__list .article-img-top--guide .article-img-top__image img {
      transform-origin: 50% 50%;
      filter: blur(6px);
      transform: scale(1.1); }
    .our-guide__list .article-img-top--guide .article-img-top__image:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #002A43;
      opacity: 0.3;
      z-index: 3; }
  .our-guide__list .article-img-top--guide .article-img-top__category {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-transform: uppercase;
    color: #fff;
    z-index: 6;
    font-weight: 700;
    letter-spacing: 0.075em;
    font-size: 18px;
    text-align: center; }

.our-guide__menu {
  width: 100%; }
  .our-guide__menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    color: #1A1B1C;
    justify-content: center;
    text-align: center; }
    @media (min-width: 768px) {
      .our-guide__menu ul {
        text-align: left;
        justify-content: flex-start; } }
    .our-guide__menu ul li {
      font-weight: 700;
      opacity: 1;
      transition: opacity .2s ease-in-out;
      margin: 0 15px 10px;
      font-size: 16px; }
      .our-guide__menu ul li:before {
        content: "";
        display: inline-block;
        width: 10px;
        height: 13px;
        margin-right: 12px;
        background-image: url("/templates/main/images/svg/triangle-menu.svg");
        margin-top: -2px; }
      @media (min-width: 768px) {
        .our-guide__menu ul li {
          font-size: 18px;
          margin: 0 40px 0 0; } }
      .our-guide__menu ul li a {
        transition: color .2s ease-in-out; }
      .our-guide__menu ul li:first-child {
        order: 2; }
      .our-guide__menu ul li:nth-child(2) {
        order: 1; }
      @media (min-width: 1025px) {
        .our-guide__menu ul li:hover a {
          color: #FB3636; } }
      .our-guide__menu ul li.current {
        text-decoration: underline; }

.blue-slider--knowledge.blue-slider {
  margin: 0;
  padding: 0; }

.blue-slider--knowledge .blue-slider {
  margin-bottom: 0; }

.blue-slider {
  background-color: #002B44;
  color: #fff;
  padding: 70px 0;
  margin-bottom: 60px; }
  .blue-slider__firm {
    position: absolute;
    top: 27px;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding: 12px 20px;
    justify-content: space-between;
    color: #1A1B1C;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    max-width: 300px; }
    @media (min-width: 576px) {
      .blue-slider__firm {
        max-width: 310px; } }
    .blue-slider__firm-text {
      padding-top: 3px; }
  .blue-slider__title {
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    margin-bottom: 50px;
    padding: 0 15px; }
    @media (min-width: 1440px) {
      .blue-slider__title {
        padding: 0 calc((100% - 1410px) / 2); } }
  .blue-slider__container {
    padding: 0 15px; }
    @media (min-width: 1440px) {
      .blue-slider__container {
        padding: 0 calc((100% - 1410px) / 2); } }
  .blue-slider__nav {
    display: flex;
    flex-wrap: wrap; }
  .blue-slider__controls {
    position: static;
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    bottom: 150px;
    max-width: 1410px;
    width: 100%; }
    .blue-slider__controls .swiper-pagination {
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      margin-bottom: 30px; }
      .blue-slider__controls .swiper-pagination:after {
        content: "";
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: 4px;
        margin: auto;
        background-color: #fff;
        display: block;
        opacity: 0.5;
        position: absolute; }
      .blue-slider__controls .swiper-pagination .swiper-pagination-bullet {
        width: auto;
        height: 10px;
        background-color: #FB3636;
        display: block;
        flex-grow: 1;
        border-radius: 10px;
        opacity: 0;
        position: relative;
        z-index: 3; }
        .blue-slider__controls .swiper-pagination .swiper-pagination-bullet-active {
          opacity: 1; }
    .blue-slider__controls .swiper-button-next,
    .blue-slider__controls .swiper-button-prev {
      position: relative;
      background-image: none;
      width: 50px;
      height: 50px;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      margin: 0;
      padding: 0;
      transform: none;
      border-radius: 50%;
      border: 2px solid #707070; }
      .blue-slider__controls .swiper-button-next:after,
      .blue-slider__controls .swiper-button-prev:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-image: url("/templates/main/images/svg/arrow-left.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 50%;
        transform-origin: 50% 50%; }
    .blue-slider__controls .swiper-button-prev {
      margin-right: 10px; }
      .blue-slider__controls .swiper-button-prev:after {
        transform: rotate(180deg); }
  .blue-slider__slide-wrap {
    opacity: 0.5;
    position: relative;
    transition: opacity .2s ease-in-out; }
    .blue-slider__slide-wrap:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(0deg, #1a1b1c 0%, rgba(26, 27, 28, 0) 100%);
      border-radius: 20px;
      opacity: 0.8; }
  .blue-slider__slide-image {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 60px 99px rgba(0, 0, 0, 0.3); }
    .blue-slider__slide-image img {
      outline: 1px solid transparent;
      backface-visibility: hidden; }
  .blue-slider__slide.swiper-slide-active .blue-slider__slide-wrap {
    opacity: 1; }
  .blue-slider__slide.swiper-slide-active .blue-slider__slide-text {
    opacity: 1; }
  .blue-slider__slide-text {
    transition: opacity .2s ease-in-out;
    opacity: 0;
    display: block;
    position: absolute;
    bottom: 53px;
    left: 0;
    width: 100%;
    right: 0;
    z-index: 3;
    text-align: center;
    margin: auto;
    max-width: 650px;
    padding: 0 10px; }
  .blue-slider__slide-title {
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 35px;
    line-height: 45px; }
    @media (min-width: 440px) {
      .blue-slider__slide-title {
        font-size: 45px;
        line-height: 55px; } }
  .blue-slider__slide-introtext {
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    margin-bottom: 20px;
    display: none; }
    @media (min-width: 440px) {
      .blue-slider__slide-introtext {
        display: block; } }
  .blue-slider__slide-count {
    font-size: 15px;
    font-weight: 700; }
  .blue-slider__slide-category {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-bottom: 20px;
    min-height: 29px;
    background: linear-gradient(45deg, #3fb693 0%, #35b7c1 100%);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 13px;
    border-radius: 5px;
    padding-top: 2px; }

.our-guide--slider .our-guide__module-title {
  display: flex;
  flex-wrap: wrap;
  border-bottom: none;
  justify-content: space-between;
  align-items: center;
  align-content: center; }
  .our-guide--slider .our-guide__module-title .our-guide__module-title-link {
    font-size: 35px;
    letter-spacing: 0.04em;
    padding-top: 2px;
    font-weight: 500;
    text-transform: uppercase;
    padding-top: 5px; }
  .our-guide--slider .our-guide__module-title .our-guide__module-title-btn {
    width: 100%;
    max-width: 175px;
    height: 50px;
    border: 2px solid #1A1B1C;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    line-height: normal;
    padding-top: 2px;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    @media (min-width: 1025px) {
      .our-guide--slider .our-guide__module-title .our-guide__module-title-btn:hover {
        background-color: #1A1B1C;
        color: #fff; } }

.swiper-our-guide {
  padding-left: calc(((100% - 1440px) / 2)); }
  .swiper-our-guide .swiper-slide {
    padding: 0 15px; }

.articles-list__top {
  background-color: #002A43;
  color: #fff;
  padding-bottom: 115px; }
  .articles-list__top .breadcrumb {
    margin-bottom: 20px; }
  .articles-list__top .tags-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center; }
    .articles-list__top .tags-header .page-header {
      margin-bottom: 0;
      width: auto; }

.articles-list__bottom {
  margin-top: -50px; }
  .articles-list__bottom--tag .article-list__item-wrap:hover .article-list__item-image img {
    opacity: 0; }
  .articles-list__bottom--tag .article-list__item-wrap .article-list__item-image:after {
    background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%); }
  .articles-list__bottom--tag .article-list__item-wrap .article-list__item-image img {
    opacity: 0; }

.articles-list__title {
  font-size: 22px;
  line-height: 33px;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  margin-bottom: 25px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  transition: color .5s ease-in-out; }
  .articles-list__title-link {
    display: inline-flex;
    align-items: center;
    align-content: center;
    flex-grow: 0;
    padding-right: 15px; }
  .articles-list__title-line {
    font-size: 0;
    height: 2px;
    flex-grow: 2;
    background-color: #FB3636;
    display: inline-flex;
    align-items: center;
    align-content: center;
    margin-bottom: 5px; }

.last-added {
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
  z-index: 1; }
  .last-added__top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 15px; }
    .last-added__top-left {
      padding-left: 20px; }
      .last-added__top-left .last-added__title-value {
        color: #fff !important; }
      .last-added__top-left-title {
        font-weight: 700;
        font-size: 23px; }
      .last-added__top-left .last-added__title-value {
        font-size: 23px;
        font-weight: 300; }
  .last-added.in-fulltext {
    max-width: 830px;
    margin: 0 auto 30px auto; }
    .last-added.in-fulltext .last-added__title {
      padding: 15px 0;
      background: linear-gradient(135deg, #3c628a 0%, #002b44 100%); }
    .last-added.in-fulltext .last-added__item-title {
      line-height: 15px; }
    .last-added.in-fulltext .last-added__item-practice {
      line-height: 23px; }
    .last-added.in-fulltext a.last-added__btn {
      color: #fff !important;
      max-width: 163px;
      width: 100%;
      padding: 0 20px;
      white-space: nowrap; }
    .last-added.in-fulltext .last-added__top-left {
      display: flex;
      align-items: center; }
    .last-added.in-fulltext .last-added__list {
      display: flex;
      flex-wrap: wrap;
      padding: 20px 0; }
    @media (max-width: 700px) {
      .last-added.in-fulltext .last-added__btn {
        display: none; } }
    .last-added.in-fulltext .last-added__btn.bottom {
      display: none; }
      @media (max-width: 700px) {
        .last-added.in-fulltext .last-added__btn.bottom {
          display: flex;
          margin-bottom: 20px; } }
    .last-added.in-fulltext .last-added__item {
      margin-bottom: 0px; }
      @media (max-width: 700px) {
        .last-added.in-fulltext .last-added__item {
          margin-bottom: 15px; } }
      .last-added.in-fulltext .last-added__item-wrapper {
        max-width: 50%;
        flex: 0 0 50%;
        padding: 0 25px; }
        @media (max-width: 700px) {
          .last-added.in-fulltext .last-added__item-wrapper {
            max-width: 100%;
            flex: 0 0 100%; } }
    .last-added.in-fulltext .last-added__item-practice {
      color: #000C2D; }
    .last-added.in-fulltext .last-added__item-link {
      padding-bottom: 8px;
      line-height: 16px;
      margin-top: 12px; }
      .last-added.in-fulltext .last-added__item-link a {
        color: #35B7C1 !important; }
    .last-added.in-fulltext .last-added__item:last-child {
      border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
    .last-added.in-fulltext .last-added__item-title a {
      color: #000C2D !important; }
    .last-added.in-fulltext a {
      text-decoration: none; }
  .last-added--hp {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
    @media (max-width: 575.98px) {
      .last-added--hp .last-added__item {
        display: none; } }
    .last-added--hp .last-added__item:first-child, .last-added--hp .last-added__item:nth-child(2), .last-added--hp .last-added__item:nth-child(3) {
      display: flex; }
  .last-added__title {
    color: #fff;
    background-color: #223D59;
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .last-added__title-text {
      font-size: 20px;
      font-weight: 700;
      margin-top: 5px; }
    .last-added__title-separator {
      width: 2px;
      height: 22px;
      background-color: #fff;
      opacity: 0.5;
      display: block;
      margin: 0 12px; }
    .last-added__title-value {
      font-size: 20px;
      font-weight: 300;
      margin-top: 5px; }
  .last-added__list {
    padding: 20px;
    background-color: #fff; }
  .last-added__item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
    .last-added__item:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none; }
    .last-added__item-image {
      border-radius: 10px;
      overflow: hidden;
      max-width: 70px;
      max-height: 70px; }
    .last-added__item-text {
      width: 100%;
      padding-right: 10px;
      max-width: calc(100% - 70px); }
    .last-added__item-spec, .last-added__item-problems {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      align-items: center; }
      .last-added__item-spec a:last-child .firm-item__tag, .last-added__item-problems a:last-child .firm-item__tag {
        margin-right: 0; }
      .last-added__item-spec .firm-item__tag, .last-added__item-problems .firm-item__tag {
        font-size: 12px;
        line-height: 24px; }
    .last-added__item-spec {
      margin-bottom: 5px; }
    .last-added__item-title {
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 0; }
    .last-added__item-practice {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 5px; }
    .last-added__item-location {
      font-size: 13px;
      opacity: 0.5; }
    .last-added__item-link {
      font-size: 12px;
      font-weight: 700;
      color: #35B7C1;
      letter-spacing: 0.1em;
      text-transform: uppercase; }
  .last-added__wrap {
    padding: 0 20px 20px; }
  .last-added__btn {
    width: 100%;
    max-width: 330px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    height: 54px;
    background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
    color: #fff;
    justify-content: center;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0.05em;
    border-radius: 10px;
    text-transform: uppercase;
    padding-top: 2px; }
    @media (min-width: 1025px) {
      .last-added__btn:hover {
        color: #fff; } }
  .last-added__find {
    padding: 0 20px 27px; }
    .last-added__find-title {
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 12px;
      border-bottom: 2px solid #FB3636;
      margin-bottom: 18px; }
    .last-added__find-items {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px; }
    .last-added__find-item {
      height: 21px;
      background-color: #35B7C1;
      padding: 0 5px;
      border-radius: 5px;
      color: #fff;
      font-weight: 700;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 13px;
      margin-right: 4px;
      margin-bottom: 4px; }
    .last-added__find-logo img {
      margin: 0 auto;
      display: block; }

.our-magazines {
  background-color: #284666;
  color: #fff;
  padding: 50px 0 0; }
  .our-magazines__title {
    text-align: center;
    padding: 0 15px;
    text-transform: uppercase;
    margin-bottom: 50px;
    font-size: 26px; }
    @media (min-width: 576px) {
      .our-magazines__title {
        font-size: 35px; } }
  .our-magazines .swiper-container {
    padding-bottom: 50px;
    padding-left: 15px; }
    @media (min-width: 1440px) {
      .our-magazines .swiper-container {
        padding-left: calc((100% - 1410px) / 2); } }
  .our-magazines__list {
    display: flex;
    flex-wrap: wrap; }
  .our-magazines__item {
    text-align: center; }
    .our-magazines__item-link {
      display: block; }
    .our-magazines__item-image {
      margin-bottom: 50px; }
      .our-magazines__item-image img {
        box-shadow: 0 140px 95px rgba(0, 0, 0, 0.3); }
    .our-magazines__item-title {
      font-size: 19px;
      font-weight: 700;
      margin-bottom: 18px; }
    .our-magazines__item-description {
      font-size: 15px;
      line-height: 21px;
      opacity: 0.5; }

.opinions {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16); }
  .opinions__title {
    color: #fff;
    background-color: #223D59;
    text-align: center;
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 20px;
    font-weight: 700; }
  .opinions__list {
    padding: 20px; }
  .opinions__item {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    margin-bottom: 20px; }
    .opinions__item-images {
      max-width: 47px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start; }
      .opinions__item-images img {
        max-width: 100%;
        height: auto;
        display: block; }
    .opinions__item-photo {
      margin-bottom: 15px; }
      .opinions__item-photo img {
        border-radius: 9px; }
    .opinions__item-text {
      max-width: calc(100% - 47px);
      width: 100%;
      padding-left: 15px; }
    .opinions__item-name {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 20px; }
    .opinions__item-description {
      font-size: 13px;
      margin-bottom: 15px;
      line-height: 20px; }
    .opinions__item-author {
      color: #B7B7B7;
      font-size: 13px; }
      .opinions__item-author span {
        color: #1A1B1C;
        font-weight: 700; }

.recommended-right {
  background-color: #294868;
  color: #fff;
  border-radius: 20px;
  padding: 30px 20px;
  position: relative;
  z-index: 3;
  margin-bottom: 30px; }
  .recommended-right__top {
    border-bottom: 3px solid #FB3636;
    padding-bottom: 15px;
    margin-bottom: 15px; }
  .recommended-right__title {
    padding-left: 10px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700; }
  .recommended-right__subtitle {
    padding-left: 10px;
    font-size: 13px;
    font-weight: 400; }
  .recommended-right__item {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
    .recommended-right__item:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0; }
    .recommended-right__item-link {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start; }
    .recommended-right__item-title {
      font-size: 16px;
      font-weight: 700;
      padding-right: 15px;
      max-width: calc(100% - 70px);
      width: 100%; }
    .recommended-right__item-image {
      width: 100%;
      max-width: 70px; }
      .recommended-right__item-image img {
        border-radius: 5px; }

.category-description {
  color: #1A1B1C; }
  .category-description__title {
    position: relative;
    z-index: 3;
    font-size: 45px;
    font-weight: 700;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-top: 3px solid #FB3636;
    max-width: 1050px;
    padding-top: 16px; }
    @media (min-width: 576px) {
      .category-description__title {
        padding-top: 24px; } }
    .category-description__title--link.category-description__title {
      align-items: center;
      padding-bottom: 20px; }
      @media (min-width: 1025px) {
        .category-description__title--link.category-description__title {
          padding-bottom: 10px; } }
      .category-description__title--link.category-description__title .category-description__title-text {
        padding-right: 15px;
        margin-bottom: 10px; }
        @media (min-width: 1025px) {
          .category-description__title--link.category-description__title .category-description__title-text {
            margin-bottom: 0;
            max-width: calc(100% - 300px); } }
        @media (max-width: 767.98px) {
          .category-description__title--link.category-description__title .category-description__title-text {
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            /* This is the dangerous one in WebKit, as it breaks things wherever */
            word-break: break-all;
            /* Instead use this non-standard one: */
            word-break: break-word;
            hyphens: auto; } }
    .category-description__title-text {
      margin-right: 25px;
      margin: 0;
      padding: 0;
      font-size: 28px;
      line-height: 36px; }
      @media (min-width: 576px) {
        .category-description__title-text {
          font-size: 38px;
          line-height: 46px; } }
      @media (min-width: 768px) {
        .category-description__title-text {
          font-size: 45px;
          line-height: 50px; } }
    .category-description__title-link {
      display: inline-flex;
      max-width: 243.3px;
      height: 48px;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 15px;
      font-weight: 700;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      background: linear-gradient(90deg, #f16655 0%, #fb3636 100%);
      border-radius: 14px;
      align-items: center;
      align-content: center;
      position: relative;
      margin-right: 0;
      color: #fff; }
      @media (min-width: 1025px) {
        .category-description__title-link {
          margin-left: auto; } }
      .category-description__title-link:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #FB3636;
        opacity: 0;
        transition: opacity .4s ease-in-out;
        border-radius: 14px; }
      @media (min-width: 1025px) {
        .category-description__title-link:hover:after {
          opacity: 1; } }
      .category-description__title-link span {
        display: block;
        padding-top: 4px;
        position: relative;
        z-index: 3; }
      .category-description__title-link img {
        margin-left: 20px;
        position: relative;
        z-index: 3; }
  .category-description__text {
    position: relative;
    z-index: 3;
    max-width: 900px;
    font-size: 17px;
    line-height: 25px;
    margin-bottom: 30px;
    color: #1A1B1C; }
  .category-description__image {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 55%;
    overflow: hidden; }
    .category-description__image img {
      width: 100%; }
      @media (max-width: 1650px) {
        .category-description__image img {
          width: inherit;
          max-width: inherit; } }
      @media (max-width: 1199px) {
        .category-description__image img {
          display: none; } }
    .category-description__image:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
      display: block; }
  .category-description--short .category-description__text {
    border-bottom: none;
    padding-bottom: 0;
    max-width: 1050px; }

.category-menu {
  color: #1A1B1C;
  max-width: 1440px;
  padding: 0 15px;
  margin: 0 auto;
  position: relative;
  z-index: 3; }
  .category-menu__list {
    max-width: 1050px;
    margin: 0;
    padding: 0;
    list-style: none;
    margin-bottom: 30px; }
    @media (min-width: 576px) {
      .category-menu__list {
        column-count: 2; } }
    @media (min-width: 1025px) {
      .category-menu__list {
        column-count: 3; } }
    .category-menu__list li {
      margin-bottom: 16px;
      font-weight: 700;
      position: relative;
      padding-left: 21px; }
      .category-menu__list li:before {
        content: "";
        display: block;
        position: absolute;
        width: 13px;
        height: 12px;
        background-image: url("/templates/main/images/svg/list-triangle.svg");
        background-position: center;
        background-repeat: no-repeat;
        top: 3px;
        left: 0; }
      .category-menu__list li a {
        transition: color .2s ease-in-out; }
        @media (min-width: 1025px) {
          .category-menu__list li a:hover {
            color: #FB3636; } }

.therapist-list-articles {
  border-radius: 15px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 60px;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16); }
  .therapist-list-articles__top {
    color: #fff;
    background: linear-gradient(90deg, #3c628a 0%, #002b44 100%);
    min-height: 80px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding: 0 15px 0 30px; }
  .therapist-list-articles__title {
    font-size: 20px;
    font-weight: 700;
    margin-right: 10px; }
  .therapist-list-articles__separator {
    width: 2px;
    height: 22px;
    background-color: rgba(255, 255, 255, 0.5);
    margin-right: 10px; }
  .therapist-list-articles__subtitle {
    font-size: 20px;
    font-weight: 300; }
  .therapist-list-articles__list {
    padding: 30px 5px 10px;
    display: flex;
    flex-wrap: wrap; }
    .therapist-list-articles__list .last-added__item {
      width: 100%;
      padding: 0 15px 10px;
      border-bottom: none;
      position: relative;
      margin-bottom: 20px; }
      @media (min-width: 768px) {
        .therapist-list-articles__list .last-added__item {
          max-width: 50%; } }
      .therapist-list-articles__list .last-added__item-image {
        width: 100%;
        max-width: 280px;
        max-height: 280px;
        order: 1;
        margin: 0 auto 20px; }
        @media (min-width: 420px) {
          .therapist-list-articles__list .last-added__item-image {
            order: 2;
            max-width: 115px;
            max-height: 115px;
            margin: 0; } }
        .therapist-list-articles__list .last-added__item-image a {
          display: block;
          width: 100%;
          height: 100%; }
        .therapist-list-articles__list .last-added__item-image .img-avatar {
          width: 100%; }
      .therapist-list-articles__list .last-added__item-text {
        width: 100%;
        order: 2; }
        @media (min-width: 420px) {
          .therapist-list-articles__list .last-added__item-text {
            order: 1;
            max-width: calc(100% - 115px); } }
      .therapist-list-articles__list .last-added__item:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 15px;
        right: 15px;
        height: 1px;
        background-color: rgba(112, 112, 112, 0.1); }
  .therapist-list-articles__link {
    align-self: flex-end;
    margin-left: auto;
    min-height: 46px;
    border-radius: 10px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    font-weight: 700;
    padding: 3px 25px 0;
    background: linear-gradient(90deg, #f16655 0%, #fb3636 100%); }
    .therapist-list-articles__link--bottom {
      text-align: center;
      justify-content: center;
      color: #fff;
      width: 100%;
      max-width: 250px;
      margin: 0 auto; }
      .therapist-list-articles__link--bottom:hover {
        color: #fff; }

.archive-papers {
  background-color: #002A43;
  color: #fff;
  padding: 36px 0 80px; }
  .archive-papers__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-bottom: 16px;
    border-bottom: 3px solid #FB3636;
    margin-bottom: 30px; }
    .archive-papers__header-text {
      font-size: 45px;
      font-weight: 700; }
    .archive-papers__header-btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 15px;
      height: 60px;
      background-color: #294868;
      box-shadow: 0 25px 50px rgba(0, 0, 0, 0.45);
      width: 100%;
      max-width: 220px;
      font-size: 15px;
      font-weight: 700; }
  .archive-papers__item {
    padding: 0 15px;
    text-align: center;
    margin-bottom: 30px;
    width: 100%; }
    @media (min-width: 450px) {
      .archive-papers__item {
        width: 50%; } }
    @media (min-width: 768px) {
      .archive-papers__item {
        width: calc(100% / 3); } }
    @media (min-width: 1200px) {
      .archive-papers__item {
        width: calc(100% / 6); } }
    .archive-papers__item-link {
      display: block; }
    .archive-papers__item-image {
      margin-bottom: 39px; }
    .archive-papers__item-text {
      font-size: 18px;
      font-weight: 700; }

.find-therapist {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  padding-top: 30px;
  padding-bottom: 43px;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  background-color: #fff; }
  .find-therapist.box-wait {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10001;
    background-color: rgba(0, 43, 68, 0.97);
    transition: .3s all;
    border-radius: 0px;
    box-shadow: none;
    padding: 0;
    padding-top: 130px; }
    @media (max-width: 767px) {
      .find-therapist.box-wait {
        padding-top: 75px; } }
    .find-therapist.box-wait[data-open="false"] {
      left: -100%; }
    .find-therapist.box-wait .find-therapist__bottom {
      max-width: calc(100% - 244px);
      width: 100%; }
      @media (max-width: 767px) {
        .find-therapist.box-wait .find-therapist__bottom {
          max-width: calc(100% - 194px); } }
      @media (max-width: 670px) {
        .find-therapist.box-wait .find-therapist__bottom {
          max-width: 100%; } }
      .find-therapist.box-wait .find-therapist__bottom::before {
        content: none; }
      .find-therapist.box-wait .find-therapist__bottom::after {
        content: none; }
    .find-therapist.box-wait .box-wait-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      max-width: 1190px;
      width: 100%;
      padding: 0 15px;
      margin: 0 auto; }
    .find-therapist.box-wait .box-wait__image {
      margin-right: 70px;
      max-width: 124px;
      width: 100%; }
      @media (max-width: 670px) {
        .find-therapist.box-wait .box-wait__image {
          max-width: 100%;
          text-align: center;
          margin-bottom: 20px; } }
      .find-therapist.box-wait .box-wait__image img {
        margin-top: 30px; }
    .find-therapist.box-wait .box-wait__title {
      font-size: 49px;
      line-height: 65px;
      font-weight: 700;
      margin-bottom: 11px;
      letter-spacing: -2px; }
      @media (max-width: 767px) {
        .find-therapist.box-wait .box-wait__title {
          font-size: 29px;
          line-height: 45px; } }
    .find-therapist.box-wait .box-wait__description {
      margin-bottom: 22px;
      font-size: 25px;
      line-height: 35px;
      font-weight: 300; }
      @media (max-width: 767px) {
        .find-therapist.box-wait .box-wait__description {
          font-size: 18px;
          line-height: 21px; } }
    .find-therapist.box-wait .box-wait__close {
      max-width: 50px;
      width: 100%;
      text-align: right;
      cursor: pointer; }
      @media (max-width: 767px) {
        .find-therapist.box-wait .box-wait__close {
          position: absolute;
          top: 15px;
          right: 15px; } }
      .find-therapist.box-wait .box-wait__close img {
        margin-top: 13px;
        pointer-events: none; }
    .find-therapist.box-wait .box-wait__select-wrapper {
      display: flex;
      margin-bottom: 72px; }
      @media (max-width: 670px) {
        .find-therapist.box-wait .box-wait__select-wrapper {
          flex-wrap: wrap; }
          .find-therapist.box-wait .box-wait__select-wrapper #hp-select-problems {
            margin-bottom: 20px; } }
      .find-therapist.box-wait .box-wait__select-wrapper .main__select-wrap {
        margin: 0;
        margin-right: 19px; }
    .find-therapist.box-wait .box-wait__link {
      font-weight: 300;
      font-size: 19px;
      line-height: 36px; }
      .find-therapist.box-wait .box-wait__link a {
        text-decoration: underline; }
  @media (max-width: 379.98px) {
    .find-therapist {
      padding: 30px 15px 43px; } }
  .find-therapist__top {
    display: block;
    margin: 0 auto 24px;
    width: 100%;
    max-width: 310px; }
  .find-therapist__bottom {
    position: relative;
    color: #fff; }
    .find-therapist__bottom:before {
      content: "";
      opacity: 1;
      filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.16));
      position: absolute;
      z-index: 0;
      border-radius: 20px;
      top: 0;
      height: 80px;
      width: 310px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: calc(100% - 60px); }
    .find-therapist__bottom:after {
      content: "";
      opacity: 1;
      filter: drop-shadow(0 15px 30px rgba(0, 0, 0, 0.16));
      position: absolute;
      z-index: 0;
      border-radius: 20px;
      top: 95px;
      height: 80px;
      width: 310px;
      left: 0;
      right: 0;
      margin: auto;
      max-width: calc(100% - 60px); }
  .find-therapist__title {
    font-size: 20px;
    line-height: normal;
    font-weight: 700;
    margin-bottom: 10px;
    padding-bottom: 8px;
    border-bottom: 2px solid #FB3636;
    margin-bottom: 15px; }
  .find-therapist__subtitle {
    font-size: 14px;
    line-height: 18px;
    line-height: normal; }
  .find-therapist .main__select-wrap {
    margin: 0 auto 15px;
    max-width: 310px; }
    .find-therapist .main__select-wrap .cs-title {
      font-size: 15px; }
    .find-therapist .main__select-wrap:last-child {
      margin-bottom: 0; }

.problem-list-hp {
  padding: 24px 30px 40px;
  background-color: #fff;
  position: relative;
  z-index: 3;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  display: none; }
  @media (min-width: 576px) {
    .problem-list-hp {
      display: block; } }
  .problem-list-hp__title {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 700;
    padding-bottom: 8px;
    border-bottom: 2px solid #FB3636;
    margin-bottom: 20px; }
  .problem-list-hp__list {
    margin: 0;
    padding: 0;
    list-style: none;
    column-count: 2;
    font-size: 14px; }
    .problem-list-hp__list li {
      margin-bottom: 5px; }
  .problem-list-hp__link {
    margin: 25px auto 0;
    font-size: 15px;
    font-weight: 700;
    height: 48px;
    border-radius: 16px;
    width: 100%;
    max-width: 310px;
    border: 2px solid #1A1B1C;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    transition: color .2s ease-in-out, background-color .2s ease-in-out; }
    @media (min-width: 1025px) {
      .problem-list-hp__link:hover {
        background-color: #1A1B1C;
        color: #fff; } }

.header-module__title {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 6px;
  border-bottom: 1px solid #FB3636;
  margin-bottom: 10px; }

.header-module__list {
  display: flex;
  flex-wrap: wrap; }

.header-module__item {
  height: 30px;
  border: 1px solid rgba(112, 112, 112, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 0;
  padding: 2px 10px 0;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #1A1B1C; }
  .header-module__item.header-module__item--all {
    border: none;
    background-color: #FB3636;
    color: #fff;
    text-transform: uppercase; }

.header .header__menu .header-module .header-module__list .header-module__item, .header .header__menu .header-module .header-module__list .header-module__item.current, .header .header__menu .header-module .header-module__list .header-module__item.parent, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item.current, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item.parent {
  height: 30px;
  border: 1px solid rgba(112, 112, 112, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px 10px 0;
  padding: 2px 10px 0;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 500;
  color: #1A1B1C; }
  .header .header__menu .header-module .header-module__list .header-module__item:after, .header .header__menu .header-module .header-module__list .header-module__item.current:after, .header .header__menu .header-module .header-module__list .header-module__item.parent:after, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item:after, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item.current:after, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item.parent:after {
    display: none; }

.header .header__menu .header-module .header-module__list .header-module__item.header-module__item--all, .header.header-subpages .header__menu .header-module .header-module__list .header-module__item.header-module__item--all {
  border: none;
  background-color: #FB3636;
  color: #fff;
  text-transform: uppercase; }

.therapist-region__title {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 3px solid #FB3636; }

.therapist-region .swiper-container {
  padding: 40px 0; }

@media (max-width: 499px) {
  .therapist-region .swiper-slide {
    width: 250px !important; } }

.therapist-region .swiper-slide .last-added__item {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  padding: 15px 12px 18px; }
  .therapist-region .swiper-slide .last-added__item-image {
    width: 100%;
    max-width: 100%;
    height: auto;
    max-height: none;
    border-radius: 0;
    margin-bottom: 10px; }
    @media (min-width: 500px) {
      .therapist-region .swiper-slide .last-added__item-image {
        max-width: 115px;
        max-height: 115px;
        margin-bottom: 0; } }
    .therapist-region .swiper-slide .last-added__item-image img {
      border-radius: 10px;
      display: block;
      margin: 0 auto; }
  .therapist-region .swiper-slide .last-added__item-text {
    max-width: 100%; }
    @media (min-width: 500px) {
      .therapist-region .swiper-slide .last-added__item-text {
        padding-right: 0;
        padding-left: 20px;
        max-width: calc(100% - 115px); } }

.therapist-region .swiper-button-prev {
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  background-color: #FB3636;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  left: -36px;
  right: auto;
  margin: auto;
  transition: opacity .2s ease-in-out; }
  .therapist-region .swiper-button-prev:after {
    content: "";
    position: absolute;
    background-image: url("/templates/main/images/svg/chevron-down.svg");
    width: 18px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    transform-origin: 50% 50%;
    transform: rotate(90deg); }
  .therapist-region .swiper-button-prev.swiper-button-disabled {
    opacity: 0; }

.therapist-region .swiper-button-next {
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  background-color: #FB3636;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  right: -36px;
  left: auto;
  margin: auto;
  transition: opacity .2s ease-in-out; }
  .therapist-region .swiper-button-next:after {
    content: "";
    position: absolute;
    background-image: url("/templates/main/images/svg/chevron-down.svg");
    width: 18px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 15px;
    transform-origin: 50% 50%;
    transform: rotate(-90deg); }
  .therapist-region .swiper-button-next.swiper-button-disabled {
    opacity: 0; }

.read-more-slider__title {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 15px;
  border-bottom: 3px solid #FB3636;
  margin-bottom: 32px; }

.read-more-slider .swiper-button-prev {
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  background-color: #FB3636;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  top: 80px;
  bottom: auto;
  left: -36px;
  right: auto;
  margin: auto;
  transition: opacity .2s ease-in-out; }
  .read-more-slider .swiper-button-prev:after {
    content: "";
    position: absolute;
    background-image: url("/templates/main/images/svg/chevron-down.svg");
    width: 18px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 15px;
    transform-origin: 50% 50%;
    transform: rotate(90deg); }
  .read-more-slider .swiper-button-prev.swiper-button-disabled {
    opacity: 0; }

.read-more-slider .swiper-button-next {
  background-position: -1000px -1000px;
  background-repeat: no-repeat;
  background-color: #FB3636;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  top: 80px;
  bottom: auto;
  right: -36px;
  left: auto;
  margin: auto;
  transition: opacity .2s ease-in-out; }
  .read-more-slider .swiper-button-next:after {
    content: "";
    position: absolute;
    background-image: url("/templates/main/images/svg/chevron-down.svg");
    width: 18px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 15px;
    transform-origin: 50% 50%;
    transform: rotate(-90deg); }
  .read-more-slider .swiper-button-next.swiper-button-disabled {
    opacity: 0; }

[data-user-browser="firefox"] .waitbox:after {
  filter: brightness(10%); }

.waitbox {
  display: none;
  position: fixed;
  padding: 15px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; }
  .waitbox[data-open="true"] {
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (max-width: 768px) {
      .waitbox[data-open="true"] {
        display: none; } }
  .waitbox:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(26, 27, 28, 0.6);
    backdrop-filter: blur(16px); }
  .waitbox__close {
    position: absolute;
    right: 25px;
    top: 25px;
    z-index: 3;
    cursor: pointer; }
    .waitbox__close img {
      pointer-events: none; }
  .waitbox__wrapper {
    max-width: 1164px;
    background-color: #fff;
    z-index: 1001;
    position: relative;
    padding: 34px 55px 55px 55px;
    border-radius: 25px; }
    .waitbox__wrapper::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      width: 100%;
      height: 187px;
      background-color: #002B44; }
  .waitbox__title {
    font-size: 27px;
    line-height: 33px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 37px;
    z-index: 1;
    position: relative;
    color: #fff; }
  .waitbox__el {
    height: 100%; }
    .waitbox__el-title {
      font-size: 21px;
      line-height: 25px;
      font-weight: 700;
      margin-bottom: 20px; }

@media (max-width: 379.98px) {
  .articles-large-top.module, .articles-large-top-slider.module {
    padding: 0 15px; } }

@media (max-width: 575.98px) {
  .articles-large-top.module .small-article .small-article__text, .articles-large-top-slider.module .small-article .small-article__text {
    padding: 15px 15px 68px; } }

@media (max-width: 575.98px) {
  .articles-list-large-top .small-article .small-article__text {
    padding: 15px; } }

.swiper-hp-top {
  box-shadow: 0 25px 40px rgba(0, 0, 0, 0.18);
  border-radius: 20px;
  margin-bottom: 30px; }
  .swiper-hp-top .small-article {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0; }
    .swiper-hp-top .small-article:after {
      border-radius: 0; }
    @media (max-width: 575px) {
      .swiper-hp-top .small-article .small-article__text-title {
        padding-bottom: 35px; } }
    @media (max-width: 379.98px) {
      .swiper-hp-top .small-article .small-article__text-title {
        padding-bottom: 40px; } }
    .swiper-hp-top .small-article .small-article__text-description {
      padding-right: 172px; }
  .swiper-hp-top__counter {
    font-size: 16px;
    letter-spacing: 0.08em;
    font-weight: 400;
    color: #fff;
    position: absolute;
    bottom: 21px;
    right: 140px;
    position: absolute;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center; }
  .swiper-hp-top__next, .swiper-hp-top__prev {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 9;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid rgba(255, 255, 255, 0.4);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    cursor: pointer; }
  .swiper-hp-top__prev {
    right: 70px; }
    .swiper-hp-top__prev img {
      transform: rotate(180deg); }
  .swiper-hp-top .loading-bar {
    display: inline-block;
    width: 74px;
    height: 2px;
    border-radius: 20px;
    margin: 0 6px;
    position: relative;
    background-color: #fff; }
    .swiper-hp-top .loading-bar:after {
      content: "";
      width: 0%;
      background-color: #FB3636;
      position: absolute; }
  .swiper-hp-top .active .loading-bar:after {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    transition: width 3s linear; }

.articles-large-top .swiper-hp-top__nav {
  position: absolute;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  left: 15px;
  bottom: 15px; }
  @media (min-width: 576px) {
    .articles-large-top .swiper-hp-top__nav {
      bottom: 26px;
      right: 30px;
      left: auto;
      top: auto; } }

.articles-large-top .swiper-hp-top__prev,
.articles-large-top .swiper-hp-top__next {
  border-radius: 5px;
  width: 34px;
  height: 34px;
  position: static; }

.articles-large-top .swiper-hp-top__prev {
  margin-right: 5px; }

.articles-large-top .swiper-hp-top__counter {
  margin: auto;
  top: auto;
  justify-content: center;
  max-width: 150px;
  pointer-events: none;
  bottom: 17px;
  left: 120px;
  right: auto; }
  @media (min-width: 576px) {
    .articles-large-top .swiper-hp-top__counter {
      bottom: 31px;
      left: auto;
      right: 120px; } }
  @media (min-width: 768px) {
    .articles-large-top .swiper-hp-top__counter {
      left: 0;
      right: 0; } }
  @media (min-width: 1025px) and (max-width: 1200px) {
    .articles-large-top .swiper-hp-top__counter {
      display: none; } }

.articles-large-top .swiper-hp-top__link {
  width: 170px;
  height: 34px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  background-color: #fff;
  font-size: 13px;
  font-weight: 700;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 12px; }

.articles-large-top .small-article .small-article__text-title {
  padding-bottom: 0; }

@media (max-width: 575px) {
  .articles-large-top .small-article__text {
    padding: 15px 15px 68px; } }

.recommended-col .articles-large-top-slider {
  display: block !important; }
  .recommended-col .articles-large-top-slider .left {
    width: 100%;
    max-width: 100% !important; }
  .recommended-col .articles-large-top-slider .right {
    display: none; }
  .recommended-col .articles-large-top-slider .articles-large-top > .row > .col-md-6, .recommended-col .articles-large-top-slider .articles-large-top > .shops__top > .col-md-6, .recommended-col .articles-large-top-slider .swiper-hp-top .articles-large-top > .swiper-wrapper > .col-md-6 {
    display: none !important; }
  .recommended-col .articles-large-top-slider .swiper-hp-top {
    display: block;
    visibility: visible;
    position: static;
    border-radius: 0;
    box-shadow: none; }
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-hp-top__prev,
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-hp-top__next,
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-hp-top__counter {
      display: none; }
    .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper {
      display: flex;
      flex-wrap: wrap;
      visibility: visible;
      position: static;
      width: calc(100% + 30px); }
      .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide {
        margin-bottom: 30px; }
        .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article:before, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article:after {
          display: none; }
        .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article > a:before, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article > a:after {
          display: none; }
        .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article > a:hover:before, .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article > a:hover:after {
          display: none; }
        .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article picture {
          display: block;
          overflow: hidden;
          border-radius: 15px; }
        .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text {
          position: relative;
          color: #1A1B1C;
          padding: 0;
          top: -24px; }
          .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-title {
            font-size: 18px;
            line-height: 25px; }
          .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-description {
            display: block !important;
            padding: 0 !important; }
          .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-category {
            margin-bottom: 20px;
            border-bottom-left-radius: 15px;
            border-top-left-radius: 0;
            border-top-right-radius: 15px;
            border-bottom-right-radius: 0;
            padding-top: 7px;
            color: #fff; }
          .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-tags-el {
            border-color: #1A1B1C; }

.articles-large-top.articles-large-top--new .row .left, .articles-large-top.articles-large-top--new .shops__top .left, .articles-large-top.articles-large-top--new .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .left, .recommended-col .articles-large-top-slider .swiper-hp-top .articles-large-top.articles-large-top--new .swiper-wrapper .left {
  padding: 0 15px;
  max-width: 100%; }
  @media (min-width: 1366px) {
    .articles-large-top.articles-large-top--new .row .left, .articles-large-top.articles-large-top--new .shops__top .left, .articles-large-top.articles-large-top--new .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .left, .recommended-col .articles-large-top-slider .swiper-hp-top .articles-large-top.articles-large-top--new .swiper-wrapper .left {
      max-width: calc((100% / 3) * 2); } }

.articles-large-top.articles-large-top--new .row .right, .articles-large-top.articles-large-top--new .shops__top .right, .articles-large-top.articles-large-top--new .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .right, .recommended-col .articles-large-top-slider .swiper-hp-top .articles-large-top.articles-large-top--new .swiper-wrapper .right {
  padding: 0 15px;
  max-width: 100%; }
  @media (min-width: 1366px) {
    .articles-large-top.articles-large-top--new .row .right, .articles-large-top.articles-large-top--new .shops__top .right, .articles-large-top.articles-large-top--new .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .right, .recommended-col .articles-large-top-slider .swiper-hp-top .articles-large-top.articles-large-top--new .swiper-wrapper .right {
      max-width: calc(100% / 3); } }

.articles-large-top.articles-large-top--new .swiper-container .small-article .small-article__text {
  padding: 0 25px 65px; }
  @media (min-width: 576px) {
    .articles-large-top.articles-large-top--new .swiper-container .small-article .small-article__text {
      padding: 0 30px 90px; } }
  .articles-large-top.articles-large-top--new .swiper-container .small-article .small-article__text-title {
    margin: 0;
    font-size: 16px;
    line-height: 22px; }
    @media (min-width: 576px) {
      .articles-large-top.articles-large-top--new .swiper-container .small-article .small-article__text-title {
        font-size: 25px;
        line-height: 31px; } }

.articles-large-top.articles-large-top--new .swiper-container .swiper-hp-top__counter {
  left: 30px;
  right: auto; }

.articles-large-top.articles-large-top--new .swiper-container .swiper-hp-top__link {
  margin-right: 12px; }

.articles-large-top.articles-large-top--new .small-article__col {
  padding: 0 15px;
  max-width: 100%; }
  @media (min-width: 768px) and (max-width: 1365px) {
    .articles-large-top.articles-large-top--new .small-article__col {
      max-width: 50%; } }
  .articles-large-top.articles-large-top--new .small-article__col .small-article__text {
    padding: 20px; }
    .articles-large-top.articles-large-top--new .small-article__col .small-article__text-title {
      font-size: 16px;
      line-height: 22px;
      margin: 0; }

.specialist-sticky {
  background: linear-gradient(0deg, #3fb693 0, #35b7c1 100%);
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  padding: 50px 15px 47px;
  color: #fff;
  text-align: center; }
  @media (min-width: 1025px) {
    .specialist-sticky {
      top: 72px;
      position: sticky;
      border-top-left-radius: 0;
      border-bottom-right-radius: 20px; } }
  .specialist-sticky--small {
    padding: 25px 15px 30px;
    border-radius: 20px;
    top: 87px;
    margin-bottom: 36px; }
  .specialist-sticky__title {
    font-size: 21px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 25px;
    padding: 0 5px; }
  .specialist-sticky__images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 44px; }
    .specialist-sticky__images:after, .specialist-sticky__images:before {
      content: "";
      width: 96px;
      height: 96px;
      display: inline-block;
      background-color: #fff;
      box-shadow: 0 25px 30px rgba(0, 0, 0, 0.16);
      border-radius: 50%;
      margin: 0 12px; }
    .specialist-sticky__images:after {
      content: "";
      background-image: url("/templates/main/images/svg/doctor.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50%; }
    .specialist-sticky__images:before {
      content: "";
      background-image: url("/templates/main/images/svg/case.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 50%; }
  .specialist-sticky__subtitle {
    font-size: 19px;
    font-weight: 700;
    margin-bottom: 30px;
    padding: 0 5px; }
  .specialist-sticky__description {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    margin-bottom: 60px;
    padding: 0 15px; }
  .specialist-sticky__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
    height: 62px;
    max-width: 310px;
    margin: 0 auto;
    font-size: 15px;
    font-weight: 700;
    border-radius: 20px; }

.auraherbals {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  font-size: 0.8vw;
  transform: translateY(150%);
  transition: transform .2s ease-in-out, opacity .2s ease-in-out; }
  @media (min-width: 1366px) {
    .auraherbals {
      font-size: 0.9vw; } }
  @media (min-width: 1530px) {
    .auraherbals {
      font-size: 1vw; } }
  .auraherbals.closed {
    transform: translateY(150%);
    opacity: 0;
    pointer-events: none; }
  .auraherbals.sticked {
    transform: translateY(0); }
  .auraherbals__wrap {
    width: 100%;
    max-width: 320px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    position: relative;
    height: 110px;
    justify-content: space-between;
    background-color: #F2F1F0;
    font-size: inherit; }
    @media (min-width: 768px) {
      .auraherbals__wrap {
        max-width: 768px; } }
    @media (min-width: 1200px) {
      .auraherbals__wrap {
        max-width: 1920px; } }
    .auraherbals__wrap--main {
      position: relative;
      max-width: 320px;
      margin: 0 auto;
      display: block; }
      @media (min-width: 768px) {
        .auraherbals__wrap--main {
          max-width: 768px; } }
      @media (min-width: 1200px) {
        .auraherbals__wrap--main {
          max-width: 1920px; } }
  .auraherbals__image--left {
    position: absolute;
    bottom: 0;
    left: 0; }
  .auraherbals__image--logo {
    margin-left: 68px; }
  .auraherbals__image--right {
    position: absolute;
    top: 0;
    right: 0; }
    .auraherbals__image--right img {
      position: absolute;
      top: 0;
      right: 0; }
  .auraherbals__text {
    color: #1A1B1C; }
    .auraherbals__text--title {
      font-size: 2.6em;
      font-weight: 700;
      line-height: 50px;
      margin-top: 5px;
      margin-left: 30px; }
      @media (min-width: 1920px) {
        .auraherbals__text--title {
          font-size: 50px;
          line-height: 52px; } }
    .auraherbals__text--contact {
      margin-left: 30px;
      font-size: 1.55em;
      line-height: 1.235em;
      font-weight: 700;
      text-align: center;
      margin-right: 120px; }
      @media (min-width: 1920px) {
        .auraherbals__text--contact {
          font-size: 30px;
          line-height: 37px; } }
      .auraherbals__text--contact span {
        display: block; }
  .auraherbals__close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    cursor: pointer; }
    .auraherbals__close:after, .auraherbals__close:before {
      content: "";
      pointer-events: none;
      display: block;
      height: 2px;
      width: 50%;
      transform-origin: 50% 50%;
      background-color: #1A1B1C;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      pointer-events: none; }
    .auraherbals__close:after {
      transform: rotate(45deg); }
    .auraherbals__close:before {
      transform: rotate(-45deg); }

.magazines-top {
  color: #fff;
  position: relative;
  z-index: 20;
  height: 80px; }
  @media (min-width: 576px) {
    .magazines-top {
      height: 150px; } }
  @media (min-width: 640px) and (max-width: 767px) {
    .magazines-top.open .row--370 .col-right {
      width: 0;
      padding: 0;
      max-width: 0;
      opacity: 0; } }
  @media (max-width: 767px) {
    .magazines-top.open .row--370 .col-left {
      max-width: 100%;
      width: 100%; } }
  .magazines-top.open .magazines-top__wrap {
    box-shadow: 0 14px 54px rgba(0, 0, 0, 0.56); }
    @media (max-width: 767px) {
      .magazines-top.open .magazines-top__wrap {
        height: auto !important; } }
    @media (min-width: 1280px) {
      .magazines-top.open .magazines-top__wrap {
        max-height: 530px !important;
        height: 530px !important; } }
  .magazines-top.open .magazines-top__magazines {
    max-width: 100%; }
    @media (min-width: 640px) {
      .magazines-top.open .magazines-top__magazines {
        max-width: calc(100% - 80px); } }
    @media (min-width: 768px) {
      .magazines-top.open .magazines-top__magazines {
        max-width: calc(100% - 110px); } }
    .magazines-top.open .magazines-top__magazines-wrap {
      top: 120px; }
      @media (max-width: 767px) {
        .magazines-top.open .magazines-top__magazines-wrap {
          width: 100% !important; } }
      @media (min-width: 768px) {
        .magazines-top.open .magazines-top__magazines-wrap {
          max-width: calc(100vw - 45px); } }
  .magazines-top.open .magazines-top__btn {
    padding: 0 19px; }
    .magazines-top.open .magazines-top__btn span {
      width: 0;
      opacity: 0; }
    .magazines-top.open .magazines-top__btn img {
      margin-left: 0;
      transform: rotate(180deg); }
  .magazines-top__logo {
    display: none;
    margin-top: 104px; }
    @media (min-width: 1440px) {
      .magazines-top__logo {
        display: block; } }
  .magazines-top__wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    transition: max-height .2s ease-in-out, height .2s ease-in-out, box-shadow .2s ease-in-out;
    background: -moz-linear-gradient(135deg, #002b44 0%, #26828a 100%);
    background: -webkit-linear-gradient(135deg, #002b44 0%, #26828a 100%);
    background: -o-linear-gradient(135deg, #002b44 0%, #26828a 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26828A', endColorstr='#002B44', GradientType=1 );
    background: -ms-linear-gradient(135deg, #002b44 0%, #26828a 100%);
    background: linear-gradient(135deg, #002b44 0%, #26828a 100%);
    z-index: 9;
    height: 80px;
    max-height: 80px;
    padding-bottom: 30px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    padding-top: 13px; }
    @media (min-width: 576px) {
      .magazines-top__wrap {
        height: 150px;
        max-height: 150px; } }
    @media (min-width: 640px) {
      .magazines-top__wrap {
        padding-top: 17px; } }
    @media (max-width: 767px) {
      .magazines-top__wrap {
        height: auto !important; } }
  .magazines-top__magazines {
    flex-grow: 2;
    transition: max-width .2s ease-in-out;
    max-width: 100%; }
    @media (min-width: 640px) {
      .magazines-top__magazines {
        max-width: calc(100% - 65px); } }
    @media (min-width: 768px) {
      .magazines-top__magazines {
        max-width: calc(100% - 80px); } }
    @media (min-width: 1025px) {
      .magazines-top__magazines {
        max-width: calc(100% - 236px); } }
    @media (min-width: 1440px) {
      .magazines-top__magazines {
        max-width: 688px; } }
    .magazines-top__magazines-wrap {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: space-between;
      transition: top .2s ease-in-out, left .2s ease-in-out, right .2s ease-in-out, width .2s ease-in-out;
      top: 0;
      left: 0;
      right: 0;
      position: static; }
      @media (min-width: 768px) {
        .magazines-top__magazines-wrap {
          position: relative; } }
      @media (min-width: 1280px) {
        .magazines-top__magazines-wrap {
          position: static;
          width: 100% !important; } }
  .magazines-top__btn {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    border-radius: 15px;
    height: 50px;
    user-select: none;
    transition: padding .2s ease-in-out;
    padding: 0 19px;
    position: absolute;
    top: 0;
    left: calc(50% + 105px); }
    @media (min-width: 640px) {
      .magazines-top__btn {
        background-color: rgba(255, 255, 255, 0.1);
        position: static; } }
    @media (min-width: 1025px) {
      .magazines-top__btn {
        padding: 0 30px; } }
    @media (min-width: 1440px) {
      .magazines-top__btn {
        padding: 0 50px; } }
    .magazines-top__btn span {
      overflow: hidden;
      transition: width .2s ease-in-out, opacity .2s ease-in-out;
      text-align: center;
      white-space: nowrap;
      width: 0; }
      @media (min-width: 1025px) {
        .magazines-top__btn span {
          width: 122px; } }
    .magazines-top__btn img {
      transition: margin-left .2s ease-in-out, transform .2s ease-in-out; }
      @media (min-width: 1025px) {
        .magazines-top__btn img {
          margin-left: 12px; } }
    .magazines-top__btn-wrap {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding-top: 36px; }
  .magazines-top__magazine {
    width: 100%;
    max-width: calc((100% / 4) - 10px); }
    @media (min-width: 768px) {
      .magazines-top__magazine {
        max-width: calc((100% / 4) - 15px); } }
    @media (min-width: 1025px) {
      .magazines-top__magazine {
        max-width: calc((100% / 4) - 20px); } }
    .magazines-top__magazine-info {
      display: none; }
      @media (min-width: 640px) {
        .magazines-top__magazine-info {
          display: block; } }
    .magazines-top__magazine-link {
      display: block; }
    .magazines-top__magazine-image {
      display: block;
      max-width: 100%;
      height: auto;
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.35);
      margin: 0 auto; }
      @media (min-width: 640px) {
        .magazines-top__magazine-image {
          margin: 0 auto 25px; } }
      @media (min-width: 1025px) {
        .magazines-top__magazine-image {
          margin: 0 auto 30px; } }
      @media (min-width: 1280px) {
        .magazines-top__magazine-image {
          margin: 0 auto 36px; } }
    .magazines-top__magazine-title {
      font-weight: 700;
      text-align: center;
      margin-bottom: 15px;
      font-size: 16px; }
      @media (min-width: 1025px) {
        .magazines-top__magazine-title {
          margin-bottom: 23px; } }
      @media (min-width: 900px) {
        .magazines-top__magazine-title {
          white-space: nowrap; } }
      .magazines-top__magazine-title--wrap {
        white-space: normal; }
    .magazines-top__magazine-description {
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      opacity: 0.7;
      text-align: center;
      max-width: 254px;
      margin: 0 auto; }
  .magazines-top__title {
    text-align: center;
    font-weight: 700;
    white-space: nowrap;
    font-size: 15px;
    padding-bottom: 14px; }
    @media (min-width: 640px) {
      .magazines-top__title {
        padding-top: 53px;
        padding-bottom: 0; } }
    @media (min-width: 768px) {
      .magazines-top__title {
        font-size: 16px; } }
  .magazines-top .row--370 .col-left {
    display: flex;
    flex-wrap: wrap;
    transition: width .2s ease-in-out, max-width .2s ease-in-out; }
    @media (max-width: 1439px) {
      .magazines-top .row--370 .col-left {
        flex-grow: 2;
        width: auto;
        max-width: calc(100% - 240px); } }
    @media (max-width: 767px) {
      .magazines-top .row--370 .col-left {
        max-width: calc(100% - 210px); } }
    @media (max-width: 639px) {
      .magazines-top .row--370 .col-left {
        max-width: 100%;
        width: 100%; } }
  .magazines-top .row--370 .col-right {
    overflow: hidden;
    transition: width .2s ease-in-out, padding .2s ease-in-out, opacity .2s ease-in-out, max-width .2s ease-in-out; }
    @media (max-width: 1439px) {
      .magazines-top .row--370 .col-right {
        max-width: 240px;
        width: 100%; } }
    @media (max-width: 767px) {
      .magazines-top .row--370 .col-right {
        max-width: 210px; } }
    @media (max-width: 639px) {
      .magazines-top .row--370 .col-right {
        max-width: 100%; } }

.important-today {
  background-color: rgba(53, 183, 193, 0.2);
  padding: 40px 0 55px;
  margin-bottom: 65px;
  margin-top: -30px; }
  .important-today__container {
    max-width: 1350px;
    margin: 0 auto;
    padding: 0 15px; }
    @media (min-width: 440px) {
      .important-today__container {
        padding: 0 45px; } }
    @media (min-width: 576px) {
      .important-today__container {
        padding: 0 90px; } }
  .important-today__article--main {
    display: block;
    padding: 0 15px;
    width: 100%;
    margin-bottom: 45px; }
    @media (min-width: 1025px) {
      .important-today__article--main {
        width: 50%;
        margin-bottom: 0; } }
    @media (min-width: 1200px) {
      .important-today__article--main {
        width: 62%; } }
    .important-today__article--main .important-today__article-img {
      border-radius: 20px;
      overflow: hidden;
      margin-bottom: 25px; }
    .important-today__article--main .important-today__article-title {
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 24px;
      line-height: 30px; }
      @media (min-width: 1200px) {
        .important-today__article--main .important-today__article-title {
          font-size: 28px;
          line-height: 35px; } }
    .important-today__article--main .important-today__article-text {
      font-size: 15px;
      line-height: 23px; }
  .important-today__article--other {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px; }
    .important-today__article--other:last-child {
      margin-bottom: 0; }
    .important-today__article--other .important-today__article-img {
      width: 31.5%; }
      @media (min-width: 440px) and (max-width: 1024px) {
        .important-today__article--other .important-today__article-img {
          width: 142px; } }
      .important-today__article--other .important-today__article-img img {
        border-radius: 20px; }
    .important-today__article--other .important-today__article-wrap {
      padding-left: 25px;
      width: 68.5%; }
      @media (min-width: 440px) and (max-width: 1024px) {
        .important-today__article--other .important-today__article-wrap {
          width: calc(100% - 142px); } }
    .important-today__article--other .important-today__article-title {
      font-weight: 700;
      margin-bottom: 15px;
      font-size: 17px;
      line-height: 19px; }
      @media (min-width: 576px) {
        .important-today__article--other .important-today__article-title {
          font-size: 19px;
          line-height: 21px; } }
    .important-today__article--other .important-today__article-text {
      font-size: 15px;
      line-height: 23px; }
  .important-today__articles {
    padding: 0 15px;
    width: 100%; }
    @media (min-width: 1025px) {
      .important-today__articles {
        width: 50%; } }
    @media (min-width: 1200px) {
      .important-today__articles {
        width: 38%; } }
  .important-today__title {
    font-size: 20px;
    line-height: normal;
    font-weight: 400;
    letter-spacing: 0.04em;
    padding-bottom: 14px;
    border-bottom: 2px solid #FB3636;
    text-transform: uppercase;
    margin-bottom: 25px; }

.bg-blue {
  background-color: transparent;
  color: #1A1B1C;
  position: relative;
  padding-top: 15px; }
  @media (min-width: 768px) {
    .bg-blue {
      padding-top: 0; } }
  .bg-blue--slider {
    padding: 60px 0 55px;
    color: #fff;
    background-color: #002A43; }
    .bg-blue--slider .bg-blue__title {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      margin-bottom: 30px; }
      .bg-blue--slider .bg-blue__title-text {
        color: #fff;
        font-size: 35px;
        font-weight: 400;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        height: 48px;
        padding-top: 1px; }
      .bg-blue--slider .bg-blue__title-link {
        font-size: 14px;
        font-weight: 700;
        height: 48px;
        border-radius: 10px;
        padding: 0 34px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #fff;
        transition: background-color .2s ease-in-out, color .2s ease-in-out; }
        @media (min-width: 1025px) {
          .bg-blue--slider .bg-blue__title-link:hover {
            background-color: #fff;
            color: #002A43; } }
    .bg-blue--slider .swiper-treatment-next, .bg-blue--slider .swiper-treatment-prev {
      background-image: none;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 52px;
      height: 52px;
      border-radius: 50%;
      background-color: #294868;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: opacity .2s ease-in-out; }
      .bg-blue--slider .swiper-treatment-next img, .bg-blue--slider .swiper-treatment-prev img {
        width: 8px; }
      .bg-blue--slider .swiper-treatment-next.swiper-button-disabled, .bg-blue--slider .swiper-treatment-prev.swiper-button-disabled {
        opacity: 0;
        pointer-events: none; }
    .bg-blue--slider .swiper-treatment-next {
      left: auto;
      right: -26px;
      justify-content: flex-start;
      padding-left: 12px;
      box-shadow: -15px 0 15px 15px rgba(0, 43, 68, 0.4); }
    .bg-blue--slider .swiper-treatment-prev {
      left: -26px;
      right: auto;
      justify-content: flex-end;
      padding-right: 12px;
      box-shadow: 15px 0 15px 15px rgba(0, 43, 68, 0.4); }
  .bg-blue--problems-lv1 {
    padding-bottom: 140px; }
  .bg-blue--problems {
    padding-bottom: 40px; }
    @media (min-width: 1025px) {
      .bg-blue--problems {
        padding-bottom: 80px; } }
  .bg-blue .breadcrumb {
    margin-bottom: 0px;
    color: #1A1B1C; }
  .bg-blue .health-menu {
    padding-top: 0;
    padding-bottom: 165px; }
  .bg-blue__bottom {
    margin-top: -50px; }
  .bg-blue__return {
    display: block;
    font-weight: 700;
    color: #1A1B1C;
    position: relative;
    z-index: 3;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 8px; }
    @media (min-width: 576px) {
      .bg-blue__return {
        margin-bottom: 17px;
        font-size: 19px; } }
    .bg-blue__return:hover {
      color: #1A1B1C; }
      @media (min-width: 1025px) {
        .bg-blue__return:hover {
          color: #1A1B1C; } }
      @media (min-width: 1025px) {
        .bg-blue__return:hover img {
          margin-left: 15px;
          margin-right: 10px; } }
    .bg-blue__return img, .bg-blue__return svg {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      margin-right: 25px;
      transition: margin .2s ease-in-out; }
      @media (max-width: 575.98px) {
        .bg-blue__return img, .bg-blue__return svg {
          max-width: 30px;
          margin-right: 10px; } }
    .bg-blue__return svg path {
      stroke: #1A1B1C; }

.article-white {
  background-color: #fff;
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 90px 30px 80px;
  text-align: center; }
  .article-white__image img {
    margin: 0 auto 45px; }
  .article-white__title {
    font-size: 21px;
    font-weight: 700;
    line-height: 25px;
    margin-bottom: 25px; }
  .article-white__introtext {
    font-size: 15px;
    font-weight: 400;
    line-height: 23px; }

[data-user-browser="firefox"] .finder-modal:after {
  filter: brightness(10%); }

.finder-modal {
  position: fixed;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 9999;
  display: flex;
  transition: opacity .2s ease-in-out; }
  .finder-modal:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(26, 27, 28, 0.9);
    backdrop-filter: blur(16px); }
  .finder-modal.open {
    pointer-events: all;
    opacity: 1; }
  .finder-modal__wrap {
    width: 100%;
    max-width: 860px;
    padding: 80px 15px;
    margin: 0 auto;
    position: relative;
    z-index: 3; }
  .finder-modal__logo {
    margin-bottom: 80px;
    text-align: center; }
  .finder-modal__close {
    width: 70px;
    height: 70px;
    border-radius: 20px;
    position: absolute;
    background-color: #3A3A3A;
    z-index: 5;
    cursor: pointer;
    top: 15px;
    right: 15px; }
    @media (min-width: 768px) {
      .finder-modal__close {
        top: 45px;
        right: 45px; } }
    @media (min-width: 1025px) {
      .finder-modal__close {
        top: 80px;
        right: 75px; } }
    .finder-modal__close span {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 45%;
      height: 2px;
      border-radius: 1px;
      background-color: #fff;
      margin: auto;
      transform-origin: 50% 50%; }
      .finder-modal__close span:first-child {
        transform: rotate(45deg); }
      .finder-modal__close span:last-child {
        transform: rotate(-45deg); }
  .finder-modal__input {
    color: #fff; }
    .finder-modal__input--search {
      border-bottom: 2px solid #FB3636;
      margin-bottom: 32px;
      padding-bottom: 15px; }
      .finder-modal__input--search input[type='text'] {
        width: 100%;
        border: none;
        background-color: transparent;
        outline: none;
        color: #fff;
        font-size: 35px;
        font-weight: 400;
        text-align: center; }
        .finder-modal__input--search input[type='text']::placeholder {
          opacity: 1;
          transition: opacity .2s ease-in-out; }
        .finder-modal__input--search input[type='text']:active::placeholder, .finder-modal__input--search input[type='text']:focus::placeholder {
          opacity: 0; }
    .finder-modal__input--submit {
      text-align: center; }
      .finder-modal__input--submit input[type='submit'] {
        width: 100%;
        max-width: 290px;
        height: 80px;
        border: none;
        outline: none;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
        border-radius: 17px; }

[id^='hp-list'].cs-select:after, .cs-select:after {
  max-height: 495px; }

[id^='hp-list'].cs-select.open .cs-title--finder .cs-form, .cs-select.open .cs-title--finder .cs-form {
  z-index: 3;
  pointer-events: all;
  opacity: 1; }

[id^='hp-list'].cs-select.open .cs-title--finder span, .cs-select.open .cs-title--finder span {
  opacity: 0;
  pointer-events: none; }

[id^='hp-list'].cs-select .cs-option, .cs-select .cs-option {
  cursor: pointer; }

[id^='hp-list'].cs-select.finder-result .cs-result-list, .cs-select.finder-result .cs-result-list {
  margin-top: 16px; }

[id^='hp-list'].cs-select.finder-result .cs-list-wrap .cs-selected-list, .cs-select.finder-result .cs-list-wrap .cs-selected-list {
  display: none; }

[id^='hp-list'].cs-select.finder-result .cs-see-all, .cs-select.finder-result .cs-see-all {
  display: none; }

[id^='hp-list'].cs-select.finder-result .cs-selected-list, .cs-select.finder-result .cs-selected-list {
  display: none; }

[id^='hp-list'].cs-select.finder-result li, .cs-select.finder-result li {
  display: none; }

[id^='hp-list'].cs-select.finder-result .cs-result-list, .cs-select.finder-result .cs-result-list {
  display: block; }

[id^='hp-list'].cs-select .cs-result-list a, [id^='hp-list'].cs-select .cs-selected-list a, .cs-select .cs-result-list a, .cs-select .cs-selected-list a {
  letter-spacing: normal;
  position: relative;
  z-index: 5; }

[id^='hp-list'].cs-select .cs-title--finder, .cs-select .cs-title--finder {
  position: relative; }
  [id^='hp-list'].cs-select .cs-title--finder span, .cs-select .cs-title--finder span {
    transition: opacity .2s ease-in-out;
    color: #fff; }
  [id^='hp-list'].cs-select .cs-title--finder .cs-form, .cs-select .cs-title--finder .cs-form {
    position: absolute;
    top: 0;
    left: 30px;
    bottom: 0;
    right: 45px;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out; }
    [id^='hp-list'].cs-select .cs-title--finder .cs-form:after, .cs-select .cs-title--finder .cs-form:after {
      content: "";
      position: absolute;
      top: 27px;
      left: 0;
      background-image: url("/templates/main/images/svg/search-icon-black.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 18px;
      height: 18px;
      opacity: 0.2; }
    [id^='hp-list'].cs-select .cs-title--finder .cs-form input, .cs-select .cs-title--finder .cs-form input {
      padding-left: 25px;
      top: 17px;
      left: 0;
      position: absolute;
      width: 100%;
      max-width: 100%;
      display: block;
      border: none;
      outline: none;
      height: 38px;
      background: transparent;
      color: #1A1B1C;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      font-size: 16px; }

[id^='hp-list'].cs-select .cs-list-wrap, .cs-select .cs-list-wrap {
  max-height: 380px; }
  [id^='hp-list'].cs-select .cs-list-wrap .cs-result-list, [id^='hp-list'].cs-select .cs-list-wrap .cs-selected-list, .cs-select .cs-list-wrap .cs-result-list, .cs-select .cs-list-wrap .cs-selected-list {
    display: block;
    width: 100%;
    opacity: 1;
    pointer-events: all;
    color: #1A1B1C; }
    [id^='hp-list'].cs-select .cs-list-wrap .cs-result-list a, [id^='hp-list'].cs-select .cs-list-wrap .cs-selected-list a, .cs-select .cs-list-wrap .cs-result-list a, .cs-select .cs-list-wrap .cs-selected-list a {
      display: block;
      width: 100%;
      padding-bottom: 10px;
      font-size: 16px;
      text-transform: none;
      pointer-events: all;
      opacity: 1;
      color: #1A1B1C;
      font-weight: 400; }
      [id^='hp-list'].cs-select .cs-list-wrap .cs-result-list a span, [id^='hp-list'].cs-select .cs-list-wrap .cs-selected-list a span, .cs-select .cs-list-wrap .cs-result-list a span, .cs-select .cs-list-wrap .cs-selected-list a span {
        color: #35B7C1; }

[id^='hp-list'].cs-select .cs-see-all, .cs-select .cs-see-all {
  position: absolute;
  left: 30px;
  right: 30px;
  bottom: 26px;
  height: 43px;
  background-color: #3F5B77;
  font-size: 13px;
  letter-spacing: 0.1em;
  font-weight: 700;
  padding-top: 2px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-transform: uppercase;
  border-radius: 14px;
  cursor: pointer;
  color: #fff; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list .cs-selected-list, .main__select-wrap [id^='hp-list'].cs-select--selected-list .cs-selected-list a, .main__select-wrap .cs-select--selected-list .cs-selected-list, .main__select-wrap .cs-select--selected-list .cs-selected-list a {
  display: block; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list .cs-result-list, .main__select-wrap [id^='hp-list'].cs-select--selected-list .cs-result-list a, .main__select-wrap .cs-select--selected-list .cs-result-list, .main__select-wrap .cs-select--selected-list .cs-result-list a {
  display: block; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list li, .main__select-wrap [id^='hp-list'].cs-select--selected-list li a, .main__select-wrap .cs-select--selected-list li, .main__select-wrap .cs-select--selected-list li a {
  display: none; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.open .cs-list-wrap, .main__select-wrap .cs-select--selected-list.open .cs-list-wrap {
  height: 360px; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.open:after, .main__select-wrap .cs-select--selected-list.open:after {
  height: 440px; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open .cs-see-all, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open .cs-see-all {
  display: none; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open .cs-list-wrap, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open .cs-list-wrap {
  height: 380px; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open:after, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open:after {
  height: 460px; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open .cs-selected-list, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open .cs-selected-list {
  display: none; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open li, .main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open li a, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open li, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open li a {
  display: block; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open.finder-result .cs-selected-list, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open.finder-result .cs-selected-list {
  display: none; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open.finder-result li, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open.finder-result li {
  display: none; }

.main__select-wrap [id^='hp-list'].cs-select--selected-list.cs-select--see-all.open.finder-result .cs-result-list, .main__select-wrap .cs-select--selected-list.cs-select--see-all.open.finder-result .cs-result-list {
  display: block; }

.problems__container--lv1 {
  margin-top: -50px; }

.treatment .bg-blue {
  padding-bottom: 80px; }

.treatment__title {
  font-size: 35px;
  line-height: 48px;
  margin-bottom: 40px;
  border-bottom: 3px solid #FB3636;
  text-transform: uppercase;
  padding-top: 3px;
  color: #1A1B1C;
  font-weight: 400; }
  @media (max-width: 500px) {
    .treatment__title {
      font-size: 28px;
      line-height: 38px; } }

.treatment__content {
  margin-top: -50px; }

.treatment__menu {
  opacity: 0;
  transition: opacity .2s ease-in-out; }
  .treatment__menu.loaded {
    opacity: 1; }
  .treatment__menu ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #1A1B1C; }
    .treatment__menu ul li {
      margin-right: 0;
      display: inline-block;
      width: auto; }
      .treatment__menu ul li a {
        transition: color .2s ease-in-out; }
        @media (min-width: 1025px) {
          .treatment__menu ul li a:hover {
            color: #FB3636; } }
  .treatment__menu--column {
    opacity: 1;
    width: 100%; }
    .treatment__menu--column ul {
      max-width: 1200px;
      display: flex;
      flex-wrap: wrap; }
      .treatment__menu--column ul li {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 30px;
        line-height: normal; }
        @media (min-width: 576px) {
          .treatment__menu--column ul li {
            max-width: 50%; } }
        @media (min-width: 800px) {
          .treatment__menu--column ul li {
            max-width: calc(100% / 3); } }
        @media (min-width: 1025px) {
          .treatment__menu--column ul li {
            max-width: 25%; } }
        .treatment__menu--column ul li:before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 13px;
          margin-right: 12px;
          background-image: url("/templates/main/images/svg/triangle-menu.svg");
          margin-top: -2px; }

.treatment .swiper-treatment-next, .treatment .swiper-treatment-prev {
  background-image: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: #294868;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: opacity .2s ease-in-out; }
  .treatment .swiper-treatment-next img, .treatment .swiper-treatment-prev img {
    width: 8px; }
  .treatment .swiper-treatment-next.swiper-button-disabled, .treatment .swiper-treatment-prev.swiper-button-disabled {
    opacity: 0;
    pointer-events: none; }

.treatment .swiper-treatment-next {
  left: auto;
  right: -26px;
  justify-content: flex-start;
  padding-left: 12px;
  box-shadow: -15px 0 15px 15px rgba(0, 43, 68, 0.4); }

.treatment .swiper-treatment-prev {
  left: -26px;
  right: auto;
  justify-content: flex-end;
  padding-right: 12px;
  box-shadow: 15px 0 15px 15px rgba(0, 43, 68, 0.4); }

.reports .bg-blue {
  padding-bottom: 180px; }

.reports__top {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 3px solid #35B7C1; }

.reports__title {
  color: #1A1B1C;
  font-size: 35px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0; }

.reports__firm {
  position: absolute;
  min-height: 56px;
  padding: 5px 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: #1A1B1C;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  z-index: 3;
  background-color: #fff;
  padding: 0 5px; }
  .reports__firm span {
    padding-top: 4px;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px; }
    @media (min-width: 768px) {
      .reports__firm span {
        width: auto;
        margin-bottom: 0; } }
  @media (min-width: 768px) {
    .reports__firm img {
      margin-left: 25px; } }

.reports__content {
  margin-top: -150px; }

.reports__item {
  position: relative;
  border-radius: 20px;
  box-shadow: 0 60px 99px rgba(0, 0, 0, 0.18);
  overflow: hidden;
  background-color: #294868; }
  .reports__item-link {
    display: block;
    position: relative; }
  .reports__item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, #1a1b1c 0%, rgba(26, 27, 28, 0) 100%);
    opacity: 0.7;
    pointer-events: none; }
  .reports__item-text {
    position: absolute;
    text-align: center;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 3;
    color: #fff;
    padding: 0 25px 25px; }
  .reports__item-title {
    font-size: 23px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 14px; }
  .reports__item-introtext {
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
    margin-bottom: 10px; }
  .reports__item-count {
    font-size: 13px;
    font-weight: 700; }

.report-single .bg-blue {
  padding-bottom: 150px; }
  .report-single .bg-blue__container {
    position: relative;
    z-index: 3; }
  .report-single .bg-blue__image {
    position: absolute;
    top: -175px;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    display: none; }
    @media (min-width: 1025px) {
      .report-single .bg-blue__image {
        display: block; } }
    .report-single .bg-blue__image img {
      margin-left: auto;
      margin-right: 0;
      display: block;
      width: 100%; }
    .report-single .bg-blue__image:after {
      content: "";
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
      display: block;
      position: absolute; }
  .report-single .bg-blue__return {
    margin-bottom: 0; }

.report-single__top {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 17px; }
  .report-single__top.border-bottom-single {
    border-bottom: 3px solid #35b7c1; }
    .report-single__top.border-bottom-single .report-single__firm {
      margin-bottom: 17px; }
    .report-single__top.border-bottom-single .bg-blue__return {
      line-height: 23px; }
  .report-single__top-left {
    margin-right: 10px; }

.report-single__firm {
  color: var(--colorFont);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.05em;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 5px;
  width: 100%; }
  @media (min-width: 576px) {
    .report-single__firm {
      width: auto; } }
  .report-single__firm span {
    padding-top: 4px;
    display: inline-block;
    width: 100%;
    margin-bottom: 5px;
    text-align: center; }
    @media (min-width: 576px) {
      .report-single__firm span {
        width: auto;
        text-align: left;
        margin-bottom: 0; } }
  .report-single__firm img {
    padding: 8px;
    border-radius: 5px;
    background-color: #fff; }
    @media (min-width: 576px) {
      .report-single__firm img {
        margin-left: 25px; } }

.report-single__title {
  color: #1A1B1C;
  font-size: 55px;
  line-height: 60px;
  font-weight: 700;
  margin-bottom: 0px; }
  @media (max-width: 500px) {
    .report-single__title {
      font-size: 35px;
      line-height: 40px; } }
  .report-single__title-wrapper {
    padding-top: 21px;
    margin-bottom: 30px;
    border-top: 3px solid #35B7C1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; }
    .report-single__title-wrapper .special-raport-btn {
      color: #fff;
      padding: 8px 10px;
      background: linear-gradient(to right, #3fb693 0%, #35b7c1 100%);
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: 700;
      white-space: nowrap; }
  .report-single__title--tag::first-letter {
    initial-letter: 1;
    text-transform: uppercase; }

.report-single__introtext {
  color: #1A1B1C;
  font-size: 21px;
  line-height: 33px; }

.report-single__content .col-left {
  margin-top: -75px; }

.report-single__content .col-right {
  margin-top: -75px; }

.report-single--tag .report-single__title {
  padding-bottom: 0;
  border-bottom: none; }

.single-article.raport .single-article__top:after {
  display: none; }
  @media (min-width: 1025px) {
    .single-article.raport .single-article__top:after {
      display: block; } }

.main-magazines {
  background-color: transparent; }

.magazines-list {
  background-color: transparent;
  color: #1A1B1C; }
  .magazines-list__title {
    font-size: 55px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 40px; }
  .magazines-list__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start; }
  .magazines-list__item {
    text-align: center;
    padding: 0 15px;
    max-width: 400px;
    cursor: pointer;
    margin-bottom: 45px; }
    .magazines-list__item-image {
      max-width: 330px;
      margin: 0 auto 36px;
      position: relative; }
      .magazines-list__item-image:hover .magazines-list__item-links {
        opacity: 1;
        pointer-events: all; }
      .magazines-list__item-image img {
        max-width: 100%;
        height: auto;
        display: block; }
    .magazines-list__item-title {
      font-size: 21px;
      font-weight: 700;
      margin-bottom: 20px; }
    .magazines-list__item-subtitle {
      max-width: 300px;
      margin: 0 auto;
      font-size: 17px;
      line-height: 24px;
      opacity: 0.5; }
    .magazines-list__item-links {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 43, 68, 0.5);
      opacity: 0;
      transition: opacity .2s ease-in-out;
      z-index: 3;
      pointer-events: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: #fff; }
    .magazines-list__item-about, .magazines-list__item-archive {
      height: 46px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      cursor: pointer;
      width: 100%;
      max-width: 235px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      border-radius: 10px;
      padding-top: 2px; }
  .magazines-list__popups {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #294868;
    width: 100%;
    height: 100%;
    transform: translateY(100%);
    transition: transform .2s ease-in-out;
    color: #fff; }
    .magazines-list__popups.open {
      transform: translateY(0); }
    .magazines-list__popups-btn {
      text-align: center;
      top: -76px;
      position: absolute;
      left: 0;
      width: 100%;
      height: 76px;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.05em;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      background-color: #294868;
      text-transform: uppercase;
      cursor: pointer; }
      .magazines-list__popups-btn img {
        margin-right: 20px; }
    .magazines-list__popups-close {
      width: 70px;
      height: 70px;
      border-radius: 20px;
      background-color: rgba(255, 255, 255, 0.1);
      position: fixed;
      top: 36px;
      right: 31px;
      opacity: 0;
      z-index: 101;
      cursor: pointer;
      pointer-events: none;
      transition: opacity .2s ease-in-out; }
      .magazines-list__popups-close.open {
        opacity: 1;
        pointer-events: all; }
      .magazines-list__popups-close span {
        width: 20px;
        height: 3px;
        background-color: #fff;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border-radius: 3px; }
        .magazines-list__popups-close span:first-child {
          transform: rotate(45deg); }
        .magazines-list__popups-close span:last-child {
          transform: rotate(-45deg); }
    .magazines-list__popups[data-paper="ocl"] .magazines-list__popup[data-paper="ocl"] {
      display: flex; }
    .magazines-list__popups[data-paper="hh"] .magazines-list__popup[data-paper="hh"] {
      display: flex; }
    .magazines-list__popups[data-paper="ter"] .magazines-list__popup[data-paper="ter"] {
      display: flex; }
  .magazines-list__popup {
    display: none;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: auto; }
    .magazines-list__popup-wrap {
      display: flex;
      flex-wrap: wrap;
      max-width: 1470px;
      padding: 45px 15px; }
    .magazines-list__popup-image {
      width: 31%;
      display: none; }
      @media (min-width: 1025px) {
        .magazines-list__popup-image {
          display: block; } }
      .magazines-list__popup-image img {
        max-width: 100%;
        height: auto;
        display: block; }
      .magazines-list__popup-image--mobile {
        margin-bottom: 32px; }
        @media (min-width: 1025px) {
          .magazines-list__popup-image--mobile {
            display: none; } }
        .magazines-list__popup-image--mobile img {
          max-width: 100%;
          height: auto;
          display: block; }
    .magazines-list__popup-text {
      width: 100%; }
      @media (min-width: 1025px) {
        .magazines-list__popup-text {
          padding-left: 60px;
          width: 69%; } }
    .magazines-list__popup-title {
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
      margin-bottom: 36px; }
      .magazines-list__popup-title span {
        display: inline-block;
        padding-bottom: 15px;
        border-bottom: 3px solid #FB3636; }
    .magazines-list__popup-introtext, .magazines-list__popup-introtext * {
      font-size: 19px !important;
      line-height: 33px !important; }
    .magazines-list__popup-subtitle {
      font-size: 25px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 32px; }
    .magazines-list__popup-btns {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px; }
    .magazines-list__popup-btn {
      width: 100%;
      max-width: 345px;
      height: 70px;
      box-shadow: 0 25px 99px rgba(0, 0, 0, 0.5);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-weight: 700;
      border-radius: 20px;
      margin-bottom: 30px;
      background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%); }
      .magazines-list__popup-btn--buy {
        margin-right: 20px; }
    .magazines-list__popup p:nth-last-child(2) {
      margin-bottom: 0; }
    .magazines-list__popup p:last-child {
      display: none; }

.magazine-page {
  position: relative;
  z-index: 3; }
  .magazine-page__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: #fff;
    align-items: center;
    align-content: center;
    padding-top: 20px;
    border-top: 3px solid #FB3636;
    margin-bottom: 60px; }
    .magazine-page__header h1 {
      font-size: 48px;
      font-weight: 700;
      margin: 0;
      padding: 0;
      line-height: 56px;
      color: #1A1B1C; }
  .magazine-page__bottom-nav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 50px auto; }
    @media (min-width: 1025px) {
      .magazine-page__bottom-nav {
        margin: 111px auto; } }
  .magazine-page .next-year,
  .magazine-page .prev-year {
    position: relative;
    width: calc(50% - 15px);
    border: 3px solid #c9c9c9;
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-weight: 400;
    background-image: none;
    transition: opacity .2s ease-in-out;
    user-select: none;
    border-radius: 16px;
    font-size: 24px;
    height: 60px; }
    @media (min-width: 1025px) {
      .magazine-page .next-year,
      .magazine-page .prev-year {
        font-size: 47px;
        height: 134px; } }
    .magazine-page .next-year.swiper-button-disabled,
    .magazine-page .prev-year.swiper-button-disabled {
      opacity: 0; }
    .magazine-page .next-year span,
    .magazine-page .prev-year span {
      padding-top: 2px; }
      @media (max-width: 479px) {
        .magazine-page .next-year span,
        .magazine-page .prev-year span {
          display: none; } }
  @media (min-width: 480px) {
    .magazine-page .next-year img {
      margin-left: 22px; } }
  @media (min-width: 480px) {
    .magazine-page .prev-year img {
      margin-right: 22px; } }
  .magazine-page__top {
    background-color: transparent;
    padding-bottom: 115px; }
  .magazine-page__bottom {
    margin-top: -55px; }
  .magazine-page__breadcrumb {
    color: #1A1B1C; }
  .magazine-page__archive-btn {
    width: 100%;
    max-width: 225px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 700;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    background-color: transparent;
    transition: background-color .2s ease-in-out; }
    .magazine-page__archive-btn:hover {
      background-color: rgba(255, 255, 255, 0.2); }

.swiper-container.swiper-magazine .swiper-wrapper {
  align-items: center;
  align-content: center; }

.swiper-container.swiper-magazine .swiper-slide {
  width: 175px;
  text-align: center;
  padding: 0; }

.swiper-container.swiper-magazine .swiper-slide-active .magazine-list__year-item {
  font-size: 60px;
  opacity: 1; }

.swiper-container.swiper-magazine .swiper-pagination {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 2px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  max-width: calc(100% - 74px);
  border-bottom: 2px dotted #1A1B1C; }
  @media (min-width: 576px) {
    .swiper-container.swiper-magazine .swiper-pagination {
      max-width: calc(100% - 400px); } }
  .swiper-container.swiper-magazine .swiper-pagination .swiper-pagination-bullet {
    margin: 0;
    padding: 0;
    height: 2px;
    margin-top: 1px;
    background: none;
    border-radius: 0;
    opacity: 1;
    position: relative;
    padding-left: 1px; }
    .swiper-container.swiper-magazine .swiper-pagination .swiper-pagination-bullet-active {
      border: 3px solid #1A1B1C; }

.swiper-container.swiper-magazine .swiper-button-next, .swiper-container.swiper-magazine .swiper-button-prev {
  transition: opacity .2s ease-in-out;
  position: relative;
  display: flex;
  align-items: center;
  align-content: center;
  margin: 0;
  padding: 0;
  left: auto;
  right: auto;
  bottom: auto;
  top: auto;
  background: none;
  font-weight: 400;
  font-size: 0;
  width: 37px;
  height: 27px;
  width: 50%;
  color: #1A1B1C; }
  .swiper-container.swiper-magazine .swiper-button-next:focus, .swiper-container.swiper-magazine .swiper-button-prev:focus {
    outline: none;
    border: none; }
  @media (min-width: 576px) {
    .swiper-container.swiper-magazine .swiper-button-next, .swiper-container.swiper-magazine .swiper-button-prev {
      font-size: 15px; } }

.swiper-container.swiper-magazine .swiper-button-prev {
  justify-content: flex-start; }
  .swiper-container.swiper-magazine .swiper-button-prev img, .swiper-container.swiper-magazine .swiper-button-prev svg {
    margin-right: 10px; }
    @media (min-width: 576px) {
      .swiper-container.swiper-magazine .swiper-button-prev img, .swiper-container.swiper-magazine .swiper-button-prev svg {
        margin-right: 20px; } }
  .swiper-container.swiper-magazine .swiper-button-prev svg path {
    stroke: #1A1B1C; }
  .swiper-container.swiper-magazine .swiper-button-prev:after {
    content: "";
    left: 200px;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 0px;
    border-bottom: 1px dashed #1A1B1C;
    display: block;
    position: absolute; }

.swiper-container.swiper-magazine .swiper-button-next {
  justify-content: flex-end; }
  .swiper-container.swiper-magazine .swiper-button-next img, .swiper-container.swiper-magazine .swiper-button-next svg {
    margin-left: 10px; }
    @media (min-width: 576px) {
      .swiper-container.swiper-magazine .swiper-button-next img, .swiper-container.swiper-magazine .swiper-button-next svg {
        margin-left: 20px; } }
  .swiper-container.swiper-magazine .swiper-button-next svg path {
    stroke: #1A1B1C; }
  .swiper-container.swiper-magazine .swiper-button-next:after {
    content: "";
    right: 200px;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 0px;
    border-bottom: 1px dashed #1A1B1C;
    display: block;
    position: absolute; }

.swiper-container.swiper-magazine .swiper-button-disabled {
  opacity: 0; }

.swiper-container.swiper-magazine .swiper-nav-wrap {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-bottom: 30px;
  padding: 0 7.5px; }
  .swiper-container.swiper-magazine .swiper-nav-wrap:after {
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 6px;
    width: 124px;
    background-color: #1A1B1C;
    position: absolute; }
  @media (min-width: 576px) {
    .swiper-container.swiper-magazine .swiper-nav-wrap {
      padding: 0 15px; } }

.magazine-list__year {
  display: none; }
  .magazine-list__year.open {
    display: block; }

.magazine-list__year-item {
  font-size: 45px;
  letter-spacing: -0.035em;
  font-weight: 400;
  opacity: 0.2;
  color: #1A1B1C;
  height: 100px;
  line-height: 100px;
  cursor: pointer;
  transition: all .2s ease-in-out; }
  .magazine-list__year-item:hover {
    color: #fff; }

.magazine-list__item {
  margin-bottom: 20px; }
  .magazine-list__item-image {
    margin-bottom: 15px; }
    .magazine-list__item-image img {
      display: block;
      box-shadow: 0 3px 99px rgba(0, 0, 0, 0.16); }
  .magazine-list__item-title {
    font-size: 17px;
    letter-spacing: -0.015em;
    line-height: 20px;
    font-weight: 700;
    text-align: center; }

html {
  scroll-behavior: smooth; }

.magazine-single .disable-link {
  opacity: 0.2;
  pointer-events: none;
  cursor: normal; }

.magazine-single__archive-btn {
  font-size: 15px;
  font-weight: 700;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto 36px;
  height: 60px;
  border-radius: 5px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  max-width: 320px;
  width: 100%; }
  .magazine-single__archive-btn img {
    margin-right: 15px; }

.magazine-single__background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 610px;
  background-color: #fff;
  z-index: -1; }

.magazine-single__top {
  background-color: #fff;
  color: #1A1B1C;
  padding-bottom: 40px;
  margin-bottom: 55px; }
  .magazine-single__top .bg-blue__return {
    margin-bottom: 10px; }

.magazine-single__toc-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  max-width: 560px;
  margin: 0 auto 40px;
  position: relative; }
  @media (min-width: 1025px) {
    .magazine-single__toc-main {
      display: none; } }
  .magazine-single__toc-main-title {
    height: 60px;
    border: 2px solid #E1E1E1;
    border-bottom: none;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    padding: 0 22px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    cursor: pointer;
    position: relative;
    user-select: none;
    background-color: #fff;
    z-index: 3; }
    .magazine-single__toc-main-title img {
      margin-right: 20px; }
    .magazine-single__toc-main-title .triangle {
      position: absolute;
      width: 13px;
      height: 10px;
      top: 0;
      bottom: 0;
      right: 30px;
      margin: auto; }
  .magazine-single__toc-main-list {
    height: 0;
    overflow: hidden;
    padding-left: 30px;
    position: absolute;
    left: 0;
    top: 60px;
    background-color: #fff;
    border: 2px solid #E1E1E1;
    border-top: none;
    width: 100%;
    padding: 0 30px;
    font-size: 17px;
    font-weight: 500;
    box-shadow: 3px 6px 50px rgba(0, 0, 0, 0.2); }
  .magazine-single__toc-main.open .magazine-single__toc-main-list {
    height: auto;
    padding: 10px 30px; }

.magazine-single .breadcrumb {
  justify-content: center; }
  @media (min-width: 1025px) {
    .magazine-single .breadcrumb {
      justify-content: flex-start; } }

.magazine-single__newspaper {
  margin-top: 15px;
  margin-bottom: 15px; }
  @media (min-width: 1025px) {
    .magazine-single__newspaper {
      display: none; } }
  .magazine-single__newspaper img {
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.32);
    margin: 0 auto;
    display: block; }

.magazine-single__ad {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: 0 7.5px; }
  .magazine-single__ad:after {
    content: "";
    top: 0;
    left: 0;
    width: 1px; }
  .magazine-single__ad-item {
    max-width: 50%;
    width: 100%; }

.magazine-single__project {
  margin-bottom: 20px; }
  .magazine-single__project-image {
    border: 1px solid rgba(112, 112, 112, 0.1);
    margin-bottom: 20px; }
    .magazine-single__project-image img {
      display: block; }
  .magazine-single__project-title {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.015em;
    margin-bottom: 10px; }

.magazine-single__article {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  margin-bottom: 30px; }
  @media (min-width: 576px) {
    .magazine-single__article {
      max-width: 50%; } }
  @media (min-width: 768px) {
    .magazine-single__article {
      max-width: calc(100% / 3); } }
  .magazine-single__article-image {
    margin-bottom: 20px;
    border-radius: 15px;
    overflow: hidden; }
    .magazine-single__article-image img {
      display: block; }
  .magazine-single__article-title {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.015em;
    margin-bottom: 10px; }
  .magazine-single__article-introtext {
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    margin-bottom: 0;
    color: #1A1B1C; }
  .magazine-single__article:hover .magazine-single__article-introtext {
    color: #1A1B1C; }
  .magazine-single__article.single {
    width: 100%;
    max-width: none;
    align-items: center;
    align-content: center;
    margin-bottom: 0; }
    .magazine-single__article.single .magazine-single__article-image {
      margin-bottom: 0;
      width: 100%;
      margin-bottom: 20px; }
      @media (min-width: 576px) {
        .magazine-single__article.single .magazine-single__article-image {
          width: 32%;
          margin-bottom: 0; } }
    .magazine-single__article.single .magazine-single__article-text {
      width: 100%; }
      @media (min-width: 576px) {
        .magazine-single__article.single .magazine-single__article-text {
          padding-left: 40px;
          width: 68%; } }
    .magazine-single__article.single .magazine-single__article-title {
      font-weight: 700;
      margin-bottom: 15px; }
      @media (min-width: 576px) {
        .magazine-single__article.single .magazine-single__article-title {
          font-size: 22px;
          line-height: 25px; } }
    .magazine-single__article.single .magazine-single__article-introtext {
      font-size: 15px;
      line-height: 23px; }

.magazine-single__container {
  position: relative;
  z-index: 3;
  padding: 15px 15px; }
  @media (min-width: 576px) {
    .magazine-single__container {
      padding: 15px 25px; } }

.magazine-single__title {
  font-size: 48px;
  line-height: 52px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 15px; }
  .magazine-single__title span {
    display: inline-block;
    padding-top: 18px;
    border-top: 3px solid #FB3636; }
  @media (min-width: 1025px) {
    .magazine-single__title {
      text-align: left; } }

.magazine-single__number {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 28px;
  text-align: center;
  text-transform: capitalize; }
  @media (min-width: 1025px) {
    .magazine-single__number {
      text-align: left; } }

.magazine-single__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 1025px) {
    .magazine-single__links {
      justify-content: flex-start; } }
  .magazine-single__links-left {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 400px;
    margin: 0 5px; }
    @media (min-width: 1025px) {
      .magazine-single__links-left {
        margin: 0;
        margin-right: 22px; } }
  .magazine-single__links-right {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 0 5px; }
    @media (min-width: 576px) {
      .magazine-single__links-right {
        height: 70px;
        justify-content: flex-start; } }
    @media (min-width: 1025px) {
      .magazine-single__links-right {
        margin: 0; } }
    @media (max-width: 575.98px) {
      .magazine-single__links-right span {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        text-align: center; }
        .magazine-single__links-right span br {
          display: none; } }
    @media (min-width: 576px) {
      .magazine-single__links-right span {
        margin-right: 22px; } }
    @media (max-width: 575.98px) {
      .magazine-single__links-right .google {
        margin-left: 5px; } }
    @media (max-width: 575.98px) {
      .magazine-single__links-right .appstore {
        margin-right: 5px; } }
    @media (min-width: 576px) {
      .magazine-single__links-right .appstore {
        margin-right: 22px; } }
  .magazine-single__links-wrap {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1024.98px) {
      .magazine-single__links-wrap {
        justify-content: center; } }

.magazine-single__apps {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100%;
  padding-top: 20px; }
  @media (min-width: 1025px) {
    .magazine-single__apps {
      justify-content: flex-start; } }
  .magazine-single__apps span {
    width: 100%;
    text-align: center;
    margin-bottom: 5px; }
    @media (min-width: 576px) {
      .magazine-single__apps span {
        width: auto;
        margin-bottom: 0;
        text-align: left;
        margin: 0 20px; } }
    @media (min-width: 1200px) {
      .magazine-single__apps span {
        margin: 0 40px; } }
  .magazine-single__apps .appstore, .magazine-single__apps .google {
    font-size: 0; }
  .magazine-single__apps .appstore {
    margin-right: 10px; }

.magazine-single__link {
  height: 70px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
  border-radius: 20px;
  margin: 0 0 20px;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  color: #fff;
  z-index: 11; }
  @media (min-width: 576px) {
    .magazine-single__link {
      margin: 0 10px 20px; } }
  @media (min-width: 1025px) {
    .magazine-single__link {
      margin: 0 0 20px; } }
  @media (min-width: 1025px) {
    .magazine-single__link:first-child {
      margin-right: 22px; } }
  .magazine-single__link > span {
    position: relative;
    pointer-events: none; }
  .magazine-single__link--small {
    height: 70px;
    background-color: #002A43;
    border: 2px solid rgba(255, 255, 255, 0.1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 20px;
    margin-bottom: 20px; }
    @media (min-width: 576px) {
      .magazine-single__link--small {
        max-width: 190px; } }
    @media (min-width: 576px) {
      .magazine-single__link--small:first-child {
        margin-right: 20px; } }
  .magazine-single__link.open .magazine-single__link-wrap {
    opacity: 1;
    pointer-events: all; }
  .magazine-single__link-wrap {
    position: absolute;
    background-color: #fff;
    padding: 30px;
    width: auto;
    z-index: 5;
    border-radius: 20px;
    pointer-events: none;
    opacity: 0;
    transition: opacity .2s ease-in-out;
    display: block;
    left: -50%;
    right: -50%;
    margin: auto;
    top: calc(100% + 35px);
    text-align: center;
    box-shadow: 0 25px 50px rgba(0, 0, 0, 0.16); }
    @media (min-width: 1025px) {
      .magazine-single__link-wrap {
        top: -62px;
        right: auto;
        left: calc(100% + 40px);
        text-align: left; } }
    .magazine-single__link-wrap:after {
      content: "";
      width: 24px;
      height: 24px;
      background-image: url("/templates/main/images/svg/triangle-archive.svg");
      background-position: center;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      margin: auto;
      transform-origin: 50% 50%;
      left: 0;
      right: 0;
      bottom: auto;
      top: -22px;
      transform: rotate(90deg); }
      @media (min-width: 1025px) {
        .magazine-single__link-wrap:after {
          transform: none;
          top: 0;
          bottom: 0;
          right: 100%;
          left: -22px; } }
    .magazine-single__link-wrap > a,
    .magazine-single__link-wrap > span {
      display: block;
      color: #1A1B1C;
      white-space: nowrap; }
      .magazine-single__link-wrap > a:first-child,
      .magazine-single__link-wrap > span:first-child {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
      .magazine-single__link-wrap > a:last-child,
      .magazine-single__link-wrap > span:last-child {
        padding-top: 20px; }
    .magazine-single__link-wrap span {
      opacity: 0.2; }

.magazine-single__introtext {
  font-size: 19px;
  line-height: 33px; }
  .magazine-single__introtext-title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    padding-bottom: 10px;
    border-bottom: 3px solid #FB3636;
    text-transform: uppercase;
    margin-bottom: 30px; }
  .magazine-single__introtext.open p {
    display: block; }

.magazine-single .read-more {
  text-align: right;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer; }

@media (min-width: 1025px) {
  .magazine-single .row--385 .right {
    top: -15px;
    right: -25px;
    position: relative;
    margin-bottom: -30px; } }

.magazine-single__magazine {
  color: #fff;
  max-width: 385px;
  margin: 0 auto 30px;
  display: none;
  z-index: 11; }
  @media (min-width: 1025px) {
    .magazine-single__magazine {
      position: sticky;
      top: 2vh;
      max-width: none;
      display: block; } }
  .magazine-single__magazine-wrap {
    background-color: #294868;
    padding: 30px 15px 20px;
    position: relative;
    border-radius: 20px; }
    .magazine-single__magazine-wrap.toc-off {
      padding: 30px 15px; }
      @media (min-width: 1025px) and (max-width: 1920px) {
        .magazine-single__magazine-wrap.toc-off {
          min-height: auto;
          height: auto; } }
    @media (min-width: 1025px) and (max-width: 1920px) {
      .magazine-single__magazine-wrap {
        min-height: 95vh;
        max-height: 95vh;
        height: 95vh; } }
    @media (min-width: 1920px) {
      .magazine-single__magazine-wrap {
        max-height: 905px;
        height: 905px; } }
  .magazine-single__magazine-images {
    position: relative;
    margin-bottom: 30px; }
  .magazine-single__magazine-image {
    margin: 0 auto;
    display: block;
    box-shadow: 0 75px 99px rgba(0, 0, 0, 0.32);
    max-width: 200px;
    height: auto;
    position: relative;
    z-index: 5; }
    .magazine-single__magazine-image--prev, .magazine-single__magazine-image--next {
      position: absolute;
      opacity: 0.3;
      max-width: 130px;
      z-index: 3;
      top: 0;
      bottom: 0;
      margin: auto; }
    .magazine-single__magazine-image--prev {
      left: 0;
      right: auto; }
    .magazine-single__magazine-image--next {
      right: 0;
      left: auto; }
  .magazine-single__magazine-buy.magazine-single__link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 0;
    transition: height .2s ease-in-out;
    margin: 0 -4px 0; }
    .magazine-single__magazine-buy.magazine-single__link .magazine-single__link {
      height: 45px;
      margin: 0 4px;
      padding: 0;
      font-size: 14px;
      padding: 0 30px;
      box-shadow: 0 10px 30px rgba(0, 0, 0, 0.25);
      width: 170px;
      background-color: #FB3636;
      border: none;
      color: #fff; }
    .magazine-single__magazine-buy.magazine-single__link .magazine-single__links-wrap.disabled {
      opacity: 0.2; }
    .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap {
      top: calc(100% + 4px);
      left: 4px;
      right: 4px;
      bottom: auto;
      width: auto;
      padding: 0;
      color: #1A1B1C;
      text-align: center; }
      .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap.disabled {
        opacity: 0.2; }
      .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap:after {
        width: 17px;
        height: 17px;
        left: 0;
        right: 0;
        top: -8px;
        bottom: auto;
        z-index: 1; }
  .magazine-single__magazine-buy.magazine-single__link {
    height: auto;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    height: 41px;
    width: 0;
    border-radius: 14px;
    box-shadow: 0 25px 99px rgba(0, 0, 0, 0.16);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
    transition: width .2s ease-in-out, margin-left .2s ease-in-out;
    margin-bottom: 3px;
    padding-top: 2px;
    cursor: pointer;
    position: relative;
    z-index: 3; }
    .magazine-single__magazine-buy.magazine-single__link b {
      width: 0;
      overflow: hidden;
      font-weight: 700;
      display: inline-block;
      transition: width .2s ease-in-out;
      pointer-events: none; }
    .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap {
      width: 255px;
      height: 115px;
      top: calc(100% + 35px);
      bottom: auto;
      right: 0;
      left: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-content: center;
      box-shadow: 0 25px 50px rgba(0, 0, 0, 0.16); }
      .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap:after {
        content: "";
        width: 24px;
        height: 24px;
        top: -22px;
        bottom: auto;
        right: 30px;
        left: auto;
        margin: auto;
        transform-origin: 50% 50%;
        transform: rotate(90deg); }
      .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap > a,
      .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap > span {
        display: block;
        color: #1A1B1C;
        white-space: nowrap; }
        .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap > a:first-child,
        .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap > span:first-child {
          padding-bottom: 15px;
          border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
        .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap > a:last-child,
        .magazine-single__magazine-buy.magazine-single__link .magazine-single__link-wrap > span:last-child {
          padding-top: 15px; }
  .magazine-single__magazine-number {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    text-transform: capitalize;
    margin-bottom: 20px;
    position: relative;
    z-index: 7;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center; }
    .magazine-single__magazine-number .magazine-single__link.magazine-single__magazine-buy .magazine-single__link-wrap {
      opacity: 0;
      pointer-events: none; }
    .magazine-single__magazine-number.sticky .magazine-single__link.magazine-single__magazine-buy {
      width: 83px;
      margin-left: 30px; }
      .magazine-single__magazine-number.sticky .magazine-single__link.magazine-single__magazine-buy b {
        width: 83px; }
      .magazine-single__magazine-number.sticky .magazine-single__link.magazine-single__magazine-buy .magazine-single__link-wrap {
        opacity: 0;
        pointer-events: none; }
      .magazine-single__magazine-number.sticky .magazine-single__link.magazine-single__magazine-buy.open .magazine-single__link-wrap {
        opacity: 1;
        pointer-events: all; }

.magazine-single__toc {
  height: calc(100% - 530px); }
  .magazine-single__toc .simplebar-vertical {
    right: 35px; }
    .magazine-single__toc .simplebar-vertical .simplebar-scrollbar {
      opacity: 1;
      width: 9px; }
      .magazine-single__toc .simplebar-vertical .simplebar-scrollbar:before {
        background-color: #fff;
        opacity: 1; }
    .magazine-single__toc .simplebar-vertical:after {
      content: "";
      left: 0;
      right: 1px;
      bottom: 2px;
      top: 2px;
      width: 1px;
      background-color: #fff;
      opacity: 0.2;
      display: block;
      position: absolute;
      margin: auto; }
  .magazine-single__toc-title {
    text-align: center;
    position: relative;
    font-size: 18px;
    font-weight: 500;
    line-height: 60px; }
    .magazine-single__toc-title:after {
      content: "";
      position: absolute;
      display: block;
      left: 15px;
      right: 15px;
      height: 1px;
      top: 100%;
      background-color: rgba(255, 255, 255, 0.2); }
  .magazine-single__toc-items {
    margin-top: 20px;
    padding: 0 70px;
    overflow: auto;
    height: calc(100% - 80px); }
  .magazine-single__toc-item {
    font-size: 15px;
    letter-spacing: -0.02em;
    position: relative;
    margin-bottom: 4px;
    text-transform: uppercase; }
    .magazine-single__toc-item:hover {
      color: #FB3636;
      transition: color .2s ease-in-out; }
    .magazine-single__toc-item:last-child {
      margin-bottom: 0; }
    .magazine-single__toc-item.current {
      color: #FB3636; }
      .magazine-single__toc-item.current:before {
        content: "";
        position: absolute;
        width: 10px;
        height: 7px;
        left: -25px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("/templates/main/images/svg/triangle.svg");
        top: 6px;
        transform-origin: 50% 50%;
        transform: rotate(90deg); }

.magazine-single__next img {
  margin-left: 15px; }

.magazine-single__prev img {
  margin-right: 15px;
  transform-origin: 50% 50%;
  transform: rotate(180deg); }

.magazine-single__next, .magazine-single__prev {
  width: 50%; }
  .magazine-single__next a, .magazine-single__prev a {
    padding: 19px 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center; }
  .magazine-single__next img, .magazine-single__prev img {
    max-width: 15px;
    height: auto; }
  .magazine-single__next span, .magazine-single__prev span {
    display: block;
    text-align: center;
    font-size: 15px;
    font-weight: 500; }

.magazine-single__next-prev {
  position: relative;
  left: 15px;
  right: 15px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-left: none;
  border-right: none;
  width: calc(100% - 30px); }
  .magazine-single__next-prev:before {
    content: "";
    position: absolute;
    width: 1px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.2); }

.magazine-single__content-item {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%; }

.magazine-single__content-category {
  font-size: 23px;
  letter-spacing: -0.015em;
  font-weight: 700;
  text-transform: uppercase;
  color: #FB3636;
  margin-bottom: 30px;
  width: 100%;
  position: relative;
  padding: 0 15px; }
  .magazine-single__content-category:after {
    content: "";
    position: absolute;
    bottom: 0;
    height: 3px;
    background-color: rgba(112, 112, 112, 0.1);
    left: 15px;
    right: 15px; }

.magazine-single__content-title {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px; }
  .magazine-single__content-title:last-child {
    margin-bottom: 0; }
  .magazine-single__content-title .text {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.015em;
    padding-right: 10px;
    width: 100%;
    margin-bottom: 10px; }
    @media (min-width: 768px) {
      .magazine-single__content-title .text {
        margin-bottom: 0;
        width: calc(100% - 220px); } }
  .magazine-single__content-title .files {
    display: flex;
    flex-wrap: wrap;
    font-size: 11px;
    font-weight: 400;
    align-items: flex-start;
    width: 100%; }
    @media (min-width: 768px) {
      .magazine-single__content-title .files {
        width: 220px; } }
    .magazine-single__content-title .files__item {
      display: flex;
      flex-wrap: wrap; }
      .magazine-single__content-title .files__item img {
        margin-right: 5px; }
      .magazine-single__content-title .files__item:first-child {
        margin-right: 25px; }
      .magazine-single__content-title .files__item .disabled {
        opacity: 0.2;
        cursor: normal; }
        .magazine-single__content-title .files__item .disabled * {
          pointer-events: none;
          cursor: normal; }
        .magazine-single__content-title .files__item .disabled:hover {
          color: #1A1B1C; }

.magazine-single__magazine.sticked .magazine-single__magazine-buy {
  height: 45px;
  margin-bottom: 22px;
  overflow: visible; }

.alphabet-list__top {
  background-color: #fff;
  color: #1A1B1C;
  padding-bottom: 24px; }
  @media (max-width: 1439.98px) {
    .alphabet-list__top {
      padding-bottom: 84px; } }
  @media (max-width: 767.98px) {
    .alphabet-list__top {
      padding-top: 30px; } }

.alphabet-list .breadcrumb {
  margin-bottom: 0; }

@media (max-width: 1439.98px) {
  .alphabet-list__list-wrap {
    transition: padding .2s ease-in-out, height .2s ease-in-out;
    padding: 0 11px;
    overflow: hidden;
    height: 0; } }

@media (max-width: 1439.98px) {
  .alphabet-list__list-wrap.open {
    height: 185px;
    padding: 15px 11px; } }

.alphabet-list__title {
  border-bottom: 3px solid #FB3636;
  margin-bottom: 45px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center; }
  .alphabet-list__title-text {
    color: #1A1B1C;
    font-size: 35px;
    font-weight: 700;
    letter-spacing: .05em;
    text-transform: uppercase;
    margin-bottom: 0; }
  .alphabet-list__title-link {
    background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
    width: 225px;
    height: 48px;
    color: #fff;
    border-radius: 14px;
    font-size: 15px;
    font-weight: 700;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding-top: 2px;
    margin-bottom: 17px; }

.alphabet-list__container--bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.alphabet-list__bottom {
  padding-top: 80px; }

.alphabet-list__alphabet {
  position: relative; }
  @media (max-width: 1439.98px) {
    .alphabet-list__alphabet-wrapper {
      position: absolute;
      width: 100%;
      max-width: 290px;
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 16px;
      z-index: 9;
      right: 0;
      left: auto;
      background-color: #002A43; } }
  @media (max-width: 575.98px) {
    .alphabet-list__alphabet-wrapper {
      left: 0;
      right: 0;
      margin: auto; } }
  .alphabet-list__alphabet-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    height: 0;
    height: 100%;
    width: calc(100% + 16px); }
    @media (min-width: 1440px) {
      .alphabet-list__alphabet-list {
        height: auto;
        width: calc(100% + 16px);
        margin: 0 -8px;
        padding: 0; } }
    .alphabet-list__alphabet-list .simplebar-content {
      display: flex;
      flex-wrap: wrap; }
  .alphabet-list__alphabet-title {
    padding: 0 15px;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 700;
    user-select: none; }
    @media (min-width: 1440px) {
      .alphabet-list__alphabet-title {
        display: none; } }

.alphabet-list__letter {
  width: 43px;
  height: 43px;
  border-radius: 7px;
  color: #1A1B1C;
  background-color: #fff;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 2px;
  position: relative;
  overflow: hidden;
  margin: 0 4px 8px;
  border: 2px solid #ededed; }
  @media (min-width: 1440px) {
    .alphabet-list__letter {
      margin: 0 8px 16px; } }
  .alphabet-list__letter span {
    position: relative;
    z-index: 3; }
  .alphabet-list__letter:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
    transition: opacity .2s ease-in-out; }
  .alphabet-list__letter:hover {
    color: #fff; }
    .alphabet-list__letter:hover:after {
      opacity: 1; }

.alphabet-list__list {
  max-width: 570px;
  margin: 0 auto;
  margin-bottom: 30px; }
  .alphabet-list__list--one-column .alphabet-list__item-list {
    column-count: 1; }
  .alphabet-list__list--one-column .alphabet-list__item-link {
    padding: 0; }
    .alphabet-list__list--one-column .alphabet-list__item-link:before {
      display: none; }
  .alphabet-list__list--two-column {
    max-width: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .alphabet-list__list--two-column .alphabet-list__item-title {
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 2px solid rgba(112, 112, 112, 0.1); }
      .alphabet-list__list--two-column .alphabet-list__item-title:after {
        display: none; }
    .alphabet-list__list--two-column .alphabet-list__item-list {
      column-count: 1; }
    @media (min-width: 768px) {
      .alphabet-list__list--two-column .alphabet-list__item {
        max-width: calc(50% - 15px); } }
    .alphabet-list__list--two-column .alphabet-list__item-link {
      padding: 0; }
      .alphabet-list__list--two-column .alphabet-list__item-link:before {
        display: none; }
  .alphabet-list__list--one-column.alphabet-list__list--blue .alphabet-list__item-list {
    font-size: 0; }
  .alphabet-list__list--one-column.alphabet-list__list--blue .alphabet-list__item-link {
    display: inline-block;
    height: 40px;
    background-color: #35B7C1;
    border-radius: 8px;
    color: #fff;
    width: auto;
    margin-right: 10px;
    padding: 2px 10px 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    align-content: center; }

.alphabet-list__item {
  width: 100%;
  margin-bottom: 40px; }
  .alphabet-list__item-title {
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    text-transform: uppercase;
    color: #FB3636;
    margin-bottom: 30px;
    position: relative; }
    .alphabet-list__item-title span {
      display: inline-block;
      background-color: #fff;
      padding-right: 17px;
      position: relative;
      z-index: 3; }
    .alphabet-list__item-title:after {
      content: "";
      top: 0;
      left: 0;
      right: 0;
      bottom: 11px;
      height: 1px;
      background-color: rgba(112, 112, 112, 0.1);
      position: absolute;
      margin: auto; }
  .alphabet-list__item-link {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    font-size: 16px;
    margin-bottom: 10px;
    align-items: center;
    align-content: center;
    font-weight: 700;
    padding-left: 22px;
    position: relative; }
    .alphabet-list__item-link:before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 13px;
      margin-right: 12px;
      background-image: url("/templates/main/images/svg/triangle-menu.svg");
      position: absolute;
      top: 3px;
      left: 0;
      z-index: 3; }
  .alphabet-list__item-list {
    margin-bottom: 50px; }
    @media (min-width: 576px) {
      .alphabet-list__item-list {
        column-count: 2; } }

.expert-form:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 430px;
  background-color: #002A43;
  pointer-events: none; }

.expert-form__wrapper {
  position: relative;
  z-index: 3; }
  @media (max-width: 767.98px) {
    .expert-form__wrapper {
      margin: 0 -15px; } }
  .expert-form__wrapper.single-article__center {
    margin-top: 64px;
    padding: 76px 15px 121px;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }

.expert-form__form-wrapper {
  max-width: 830px;
  margin: 0 auto; }

.expert-form__form-description {
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin-bottom: 36px;
  text-transform: uppercase; }

.expert-form__form .label {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 54px;
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden; }
  .expert-form__form .label input {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
    .expert-form__form .label input:checked ~ .label-wrap {
      background-color: #35B7C1;
      color: #fff;
      padding: 3px 35px 0 15px; }
      .expert-form__form .label input:checked ~ .label-wrap:after {
        opacity: 1; }
  .expert-form__form .label-wrap {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px 25px 0;
    font-size: 18px;
    font-weight: 700;
    transition: color .2s ease-in-out, background-color .2s ease-in-out, padding .2s ease-in-out;
    border: 2px solid #35B7C1;
    border-radius: 57px; }
    .expert-form__form .label-wrap:after {
      content: "";
      width: 18px;
      height: 14px;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("/templates/main/images/svg/check-white.svg");
      position: absolute;
      top: 0;
      bottom: 0;
      right: 15px;
      left: auto;
      margin: auto;
      opacity: 0;
      pointer-events: none;
      transition: opacity .3s ease-in-out; }

.expert-form__form-labels {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 36px; }

.expert-form__article {
  text-align: center;
  max-width: 830px;
  margin: 0 auto; }
  .expert-form__article h1 {
    color: #000C2D;
    font-weight: 700;
    letter-spacing: -0.04em;
    margin-bottom: 52px;
    font-size: 40px; }
    @media (min-width: 576px) {
      .expert-form__article h1 {
        font-size: 60px; } }
  .expert-form__article-images {
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 60px; }
    .expert-form__article-images img {
      background-color: #fff;
      display: block;
      max-width: 100%;
      height: auto;
      position: relative;
      z-index: 3; }
  .expert-form__article-image {
    margin: 0 14px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16); }
    @media (max-width: 767.98px) {
      .expert-form__article-image {
        max-width: calc((100% / 3) - 28px); } }
  .expert-form__article p {
    text-align: left;
    font-size: 19px;
    line-height: 33px;
    margin-bottom: 61px; }
  .expert-form__article h2 {
    color: #000C2D;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 67px; }

.expert-form p.expert-form__description {
  color: #000C2D;
  font-size: 21px;
  font-weight: 700;
  padding-bottom: 37px;
  margin-bottom: 33px;
  border-bottom: 2px solid #707070; }

@media (max-width: 767.98px) {
  .expert-form .row.captcha .captcha-wrap, .expert-form .captcha.shops__top .captcha-wrap, .expert-form .recommended-col .articles-large-top-slider .swiper-hp-top .captcha.swiper-wrapper .captcha-wrap, .recommended-col .articles-large-top-slider .swiper-hp-top .expert-form .captcha.swiper-wrapper .captcha-wrap {
    margin: 0 -15px; } }

@media (max-width: 767.98px) {
  .expert-form .row.captcha .captcha, .expert-form .captcha.shops__top .captcha, .expert-form .recommended-col .articles-large-top-slider .swiper-hp-top .captcha.swiper-wrapper .captcha, .recommended-col .articles-large-top-slider .swiper-hp-top .expert-form .captcha.swiper-wrapper .captcha {
    margin: 0 auto 30px; } }

.expert-form .questionnaire__form-submit--red {
  background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
  border-radius: 10px;
  box-shadow: 0 24px 55px rgba(0, 0, 0, 0.16);
  margin: 0 auto; }
  @media (min-width: 768px) {
    .expert-form .questionnaire__form-submit--red {
      margin-left: auto;
      margin-right: 0; } }

.expert-list {
  position: relative;
  padding-bottom: 93px; }
  .expert-list:after {
    content: "";
    top: 100%;
    left: 0;
    width: 100%;
    height: 305px;
    background: linear-gradient(180deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
    /* w3c */
    opacity: 0.07;
    position: absolute; }
  .expert-list__description {
    color: #fff;
    margin-top: 37px;
    font-size: 19px;
    line-height: 24px;
    font-weight: 400;
    opacity: 0.7; }
    .expert-list__description span {
      font-weight: 700; }
  .expert-list__list {
    margin-top: -50px;
    position: relative;
    z-index: 3; }
    .expert-list__list .row--370 .col-left {
      background-color: #fff;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      padding-top: 46px;
      order: 2; }
      @media (max-width: 575.98px) {
        .expert-list__list .row--370 .col-left {
          padding: 46px 0 0; } }
      @media (min-width: 1025px) {
        .expert-list__list .row--370 .col-left {
          order: 1;
          max-width: calc(100% - 370px);
          border-top-right-radius: 0;
          padding: 46px 55px 30px; } }
    .expert-list__list .row--370 .col-right {
      order: 1; }
      @media (min-width: 1025px) {
        .expert-list__list .row--370 .col-right {
          max-width: 370px;
          order: 2; } }
    .expert-list__list .avt-container-1440 {
      display: flex;
      flex-wrap: wrap; }
  .expert-list__item {
    text-align: center;
    padding: 0 0 25px;
    margin: 0 0 25px;
    position: relative; }
    .expert-list__item:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 1px;
      background-color: #DDDDDD;
      left: 0; }
      @media (min-width: 576px) {
        .expert-list__item:after {
          left: 190px; } }
    .expert-list__item-link {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      align-content: flex-start;
      width: 100%; }
    .expert-list__item-image {
      margin-bottom: 23px;
      max-width: 160px; }
      @media (max-width: 575.98px) {
        .expert-list__item-image {
          margin: 0 auto 23px; } }
      .expert-list__item-image img {
        margin: 0 auto;
        border-radius: 20px;
        box-shadow: 0 10px 25px rgba(0, 0, 0, 0.16); }
    .expert-list__item-text {
      width: 100%;
      text-align: center; }
      @media (min-width: 576px) {
        .expert-list__item-text {
          text-align: left;
          padding-left: 30px;
          max-width: calc(100% - 160px); } }
    .expert-list__item-title {
      font-size: 22px;
      font-weight: 700;
      margin-bottom: 14px; }
    .expert-list__item-category {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: #FB3636;
      margin-bottom: 13px;
      text-transform: uppercase; }
    .expert-list__item-description {
      font-size: 15px;
      line-height: 25px;
      margin-bottom: 15px; }
    .expert-list__item-counter {
      font-size: 16px;
      margin-bottom: 30px; }
      .expert-list__item-counter span {
        font-weight: 700; }
    .expert-list__item-btn {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 13px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      width: 100%;
      letter-spacing: 0.05em;
      justify-content: center; }
      @media (min-width: 576px) {
        .expert-list__item-btn {
          justify-content: flex-start; } }
      .expert-list__item-btn:after {
        content: "";
        display: inline-block;
        width: 15px;
        height: 16px;
        background-image: url("/templates/main/images/svg/triangle-menu.svg");
        margin-left: 20px;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat; }

.expert-single {
  padding-bottom: 73px; }
  .expert-single__top {
    border-bottom: 3px solid #FB3636;
    margin-bottom: 30px; }
  .expert-single__expert {
    display: flex;
    flex-wrap: wrap;
    color: var(--colorFont);
    margin-bottom: 36px; }
    .expert-single__expert-email {
      font-size: 17px;
      line-height: 21px;
      color: #FB3636;
      margin-bottom: 37px;
      display: block;
      text-align: center; }
      @media (min-width: 768px) {
        .expert-single__expert-email {
          text-align: left; } }
    .expert-single__expert-image {
      width: 100%;
      max-width: 210px;
      display: none; }
      @media (min-width: 768px) {
        .expert-single__expert-image {
          display: block; } }
      .expert-single__expert-image img {
        border-radius: 20px;
        margin-bottom: 19px; }
      .expert-single__expert-image--mobile {
        display: block;
        margin: 0 auto 30px; }
        @media (min-width: 768px) {
          .expert-single__expert-image--mobile {
            display: none; } }
    .expert-single__expert-text {
      width: 100%; }
      @media (min-width: 768px) {
        .expert-single__expert-text {
          max-width: calc(100% - 210px);
          padding-left: 30px; } }
      .expert-single__expert-text.open .open {
        display: inline; }
      .expert-single__expert-text.open .close {
        display: none; }
      .expert-single__expert-text.open .expert-single__expert-description {
        max-height: 100%; }
      .expert-single__expert-text.open .expert-single__read-more img {
        transform: rotate(180deg); }
      .expert-single__expert-text.open .expert-single__read-more:after {
        opacity: 0; }
    .expert-single__expert-title {
      font-size: 40px;
      line-height: 45px;
      font-weight: 700;
      margin-bottom: 7px;
      text-align: center; }
      @media (min-width: 768px) {
        .expert-single__expert-title {
          text-align: left; } }
    .expert-single__expert-category {
      font-size: 17px;
      font-weight: 400;
      letter-spacing: 0.12em;
      margin-bottom: 29px;
      text-transform: uppercase; }
    .expert-single__expert-description {
      font-size: 17px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 17px;
      max-height: 180px;
      overflow: hidden;
      text-align: center; }
      @media (min-width: 768px) {
        .expert-single__expert-description {
          text-align: left; } }
      .expert-single__expert-description p:last-child {
        margin-bottom: 0; }
    .expert-single__expert-counter {
      font-size: 17px;
      font-weight: 700;
      text-align: center; }
      @media (min-width: 768px) {
        .expert-single__expert-counter {
          text-align: left; } }
      .expert-single__expert-counter span {
        opacity: 0.5;
        font-weight: 400; }
  .expert-single__read-more {
    position: relative;
    padding-top: 28px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.05em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    cursor: pointer;
    text-align: right;
    justify-content: flex-end;
    display: none; }
    @media (min-width: 768px) {
      .expert-single__read-more {
        text-align: left;
        justify-content: flex-start; } }
    .expert-single__read-more img {
      width: 13px;
      margin-right: 10px;
      margin-bottom: 4px; }
    .expert-single__read-more:after {
      content: "";
      height: 130px;
      background: linear-gradient(0deg, #002b44 15%, rgba(0, 43, 68, 0) 100%);
      /* w3c */
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      pointer-events: none; }
    .expert-single__read-more:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(255, 255, 255, 0.2);
      pointer-events: none; }
    .expert-single__read-more .open {
      display: none; }
  .expert-single__list {
    margin-top: -73px; }

.vaccine {
  background-color: #002B44;
  color: #fff;
  padding: 45px 15px 270px; }
  .vaccine [data-step] {
    display: none; }
  .vaccine[data-step="1"] [data-step="1"] {
    display: block; }
  .vaccine[data-step="2"] [data-step="2"] {
    display: block; }
  .vaccine[data-magazine] [data-magazine] {
    display: none; }
    .vaccine[data-magazine] [data-magazine].vaccine__select-option {
      display: block; }
  .vaccine[data-magazine="paper"] [data-magazine="paper"] {
    display: block; }
    .vaccine[data-magazine="paper"] [data-magazine="paper"]:before {
      opacity: 1; }
  .vaccine[data-magazine="electronic"] [data-magazine="electronic"] {
    display: block; }
    .vaccine[data-magazine="electronic"] [data-magazine="electronic"]:before {
      opacity: 1; }
  .vaccine[data-magazine="electronic"] .vaccine__select-btn, .vaccine[data-magazine="paper"] .vaccine__select-btn {
    opacity: 1;
    cursor: pointer;
    pointer-events: all; }
  .vaccine__step-header {
    line-height: normal;
    color: #fff;
    font-weight: 700;
    margin-bottom: 40px;
    text-align: center;
    font-size: 34px; }
    @media (min-width: 576px) {
      .vaccine__step-header {
        font-size: 54px; } }
    .vaccine__step-header span {
      font-size: 20px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.7); }
  .vaccine__header {
    line-height: normal;
    color: #fff;
    font-weight: 700;
    margin-bottom: 40px;
    font-size: 34px; }
    @media (min-width: 576px) {
      .vaccine__header {
        font-size: 54px; } }
  .vaccine__introtext {
    max-width: 930px;
    margin: 0 auto;
    color: rgba(255, 255, 255, 0.7);
    position: relative;
    font-size: 16px;
    line-height: 26px; }
    @media (min-width: 576px) {
      .vaccine__introtext {
        font-size: 17px;
        line-height: 30px; } }
    .vaccine__introtext.open:after {
      opacity: 0; }
    .vaccine__introtext.open .vaccine__introtext-hidden {
      display: block; }
    .vaccine__introtext.open .close {
      display: none; }
    .vaccine__introtext.open .open {
      display: block; }
    .vaccine__introtext:after {
      content: "";
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 0;
      height: 55%;
      opacity: 1;
      transition: opacity .4s ease-in-out;
      background: linear-gradient(0deg, #002b44 0%, rgba(0, 43, 68, 0) 100%);
      /* w3c */ }
    .vaccine__introtext strong {
      font-weight: 700;
      display: block;
      margin-bottom: 40px;
      color: #fff;
      font-size: 18px;
      line-height: 28px; }
      @media (min-width: 576px) {
        .vaccine__introtext strong {
          font-size: 21px;
          line-height: 33px; } }
      .vaccine__introtext strong span {
        color: #FB3636;
        text-decoration: underline; }
    .vaccine__introtext-visible {
      position: relative; }
    .vaccine__introtext-hidden {
      display: none; }
    .vaccine__introtext-btn {
      width: 240px;
      max-width: 100%;
      min-height: 54px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      border-radius: 10px;
      background-color: #0D364E;
      color: #fff;
      margin: 0 auto;
      padding: 0 15px;
      box-shadow: 0 25px 99px rgba(0, 0, 0, 0.5);
      position: relative;
      z-index: 3;
      user-select: none;
      cursor: pointer;
      font-size: 17px;
      font-weight: 700;
      margin-top: 26px;
      margin-bottom: 80px; }
      .vaccine__introtext-btn .close {
        display: block; }
      .vaccine__introtext-btn .open {
        display: none; }
      .vaccine__introtext-btn * {
        pointer-events: none;
        user-select: none; }
  .vaccine__select {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    max-width: 720px; }
    @media (min-width: 1145px) {
      .vaccine__select {
        max-width: 1115px;
        flex-wrap: nowrap; } }
    .vaccine__select-text {
      font-weight: 700;
      margin-bottom: 40px;
      position: relative;
      font-size: 18px;
      line-height: 28px; }
      @media (min-width: 576px) {
        .vaccine__select-text {
          font-size: 26px;
          line-height: 34px; } }
      .vaccine__select-text:before {
        content: "";
        position: absolute;
        width: 39px;
        height: 39px;
        border: 2px solid rgba(255, 255, 255, 0.5);
        display: block;
        left: -63px;
        border-radius: 3px;
        background-image: url("/templates/main/images/svg/check-teal.svg");
        background-size: 25px;
        background-position: center;
        background-repeat: no-repeat; }
    .vaccine__select-option {
      font-size: 19px;
      font-weight: 700;
      position: relative;
      cursor: pointer; }
      .vaccine__select-option:before {
        content: "";
        position: absolute;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        top: 8px;
        left: -42px;
        background: #3FB693;
        opacity: 0;
        transition: opacity .2s ease-in-out; }
      .vaccine__select-option:after {
        content: "";
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.5);
        top: 0;
        left: -50px; }
      .vaccine__select-option span {
        display: block;
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        font-weight: 400;
        margin-bottom: 20px;
        pointer-events: none; }
    .vaccine__select-left {
      flex-grow: 2;
      border-radius: 15px;
      border: 2px solid rgba(255, 255, 255, 0.2);
      order: 2;
      padding: 15px; }
      @media (min-width: 576px) {
        .vaccine__select-left {
          padding: 35px 45px 35px; } }
      @media (min-width: 1145px) {
        .vaccine__select-left {
          order: 1; } }
    .vaccine__select-right {
      flex-grow: 1;
      width: 100%;
      order: 1;
      margin-bottom: 45px; }
      @media (min-width: 1145px) {
        .vaccine__select-right {
          padding-left: 65px;
          max-width: 395px;
          order: 2;
          margin-bottom: 0; } }
      .vaccine__select-right img {
        max-width: 100%;
        height: auto;
        margin: 0 auto;
        display: block; }
    .vaccine__select-top {
      padding-left: 65px; }
    .vaccine__select-btn {
      width: 100%;
      max-width: 310px;
      min-height: 80px;
      padding: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      color: #fff;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: 0.05em;
      border-radius: 20px;
      margin: 40px auto 0;
      position: relative;
      overflow: hidden;
      background: linear-gradient(135deg, #f16655 0, #fb3636 100%);
      opacity: 0.22;
      cursor: normal;
      pointer-events: none;
      transition: opacity .2s ease-in-out; }
      .vaccine__select-btn span {
        position: relative;
        z-index: 3;
        pointer-events: none;
        user-select: none; }
  .vaccine__form-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media (min-width: 768px) {
      .vaccine__form-wrap {
        flex-wrap: nowrap;
        justify-content: flex-start; } }
  .vaccine__form-left {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin: 0 -10px;
    width: calc(100% + 20px);
    max-width: 395px;
    order: 2; }
    @media (min-width: 768px) {
      .vaccine__form-left {
        order: 1; } }
  .vaccine__form-agreement {
    width: 100%;
    font-size: 11px;
    line-height: 17px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    margin-top: 34px;
    margin-bottom: 54px; }
    .vaccine__form-agreement .vaccine__checkbox {
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      opacity: 0;
      z-index: 9; }
      .vaccine__form-agreement .vaccine__checkbox:checked ~ .vaccine__custom-checkbox {
        background-image: url("/templates/main/images/svg/check-teal.svg"); }
      .vaccine__form-agreement .vaccine__checkbox-wrap.validate-error .vaccine__custom-checkbox {
        border-color: #FB3636; }
    .vaccine__form-agreement .vaccine__checkbox-label {
      padding-left: 50px;
      user-select: none; }
    .vaccine__form-agreement .vaccine__custom-checkbox {
      width: 30px;
      height: 30px;
      border-radius: 8px;
      border: 2px solid #DDDDDD;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      background-size: 20px;
      background-position: center;
      background-repeat: no-repeat; }
  .vaccine__form-image {
    width: 100%;
    order: 1;
    margin-bottom: 45px; }
    @media (min-width: 768px) {
      .vaccine__form-image {
        padding-left: 70px;
        flex-grow: 2;
        order: 2;
        width: auto;
        margin-bottom: 0; } }
    .vaccine__form-image img {
      height: auto;
      display: block;
      margin: 0 auto;
      max-width: 60%; }
      @media (min-width: 768px) {
        .vaccine__form-image img {
          max-width: 100%; } }
  .vaccine__input-wrap {
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 70px;
    margin: 0 10px 20px;
    background-color: rgba(41, 72, 104, 0.3);
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
    border-radius: 10px; }
    .vaccine__input-wrap .star {
      display: none; }
    .vaccine__input-wrap.focus .control-label label, .vaccine__input-wrap.filled .control-label label {
      transform: translate3d(0, -8px, 0) scale(0.66); }
    .vaccine__input-wrap.validate-error {
      background-color: rgba(255, 72, 104, 0.3); }
    .vaccine__input-wrap .control-group {
      width: 100%;
      height: 100%; }
      .vaccine__input-wrap .control-group .control-label {
        position: absolute;
        top: 0;
        left: 25px;
        bottom: 0;
        right: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        font-family: "Objectivity", sans-serif; }
        .vaccine__input-wrap .control-group .control-label label {
          transition: transform .2s ease-in-out;
          transform-origin: 0 0;
          margin: 0;
          font-size: 18px;
          font-weight: 700;
          display: flex;
          flex-wrap: wrap;
          align-content: center;
          align-items: center; }
          .vaccine__input-wrap .control-group .control-label label, .vaccine__input-wrap .control-group .control-label label:focus .vaccine__input-wrap .control-group .control-label label:active {
            font-family: "Objectivity", sans-serif; }
      .vaccine__input-wrap .control-group .controls {
        width: 100%;
        height: 100%; }
    .vaccine__input-wrap.lg {
      max-width: calc(100% - 20px); }
    .vaccine__input-wrap.md {
      max-width: calc(100% - 20px); }
      @media (min-width: 425px) {
        .vaccine__input-wrap.md {
          max-width: calc(65% - 20px); } }
    .vaccine__input-wrap.sm {
      max-width: calc(100% - 20px); }
      @media (min-width: 425px) {
        .vaccine__input-wrap.sm {
          max-width: calc(35% - 20px); } }
    .vaccine__input-wrap input {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 70px;
      border: none;
      background: transparent;
      color: #fff;
      font-size: 18px;
      font-weight: 700;
      padding-left: 25px;
      padding-top: 20px;
      font-family: "Objectivity",sans-serif;
      border: none;
      outline: none;
      box-shadow: none; }
      .vaccine__input-wrap input::placeholder {
        font-size: 0;
        opacity: 0; }
  .vaccine__submit {
    width: 100%;
    max-width: 510px;
    margin: 0 auto;
    background: linear-gradient(135deg, #f16655 0, #fb3636 100%);
    height: 104px;
    border-radius: 20px;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.05em;
    border: none;
    display: block;
    cursor: pointer; }
    .vaccine__submit--return {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      margin-top: 20px;
      text-transform: uppercase;
      background: none; }
  .vaccine[data-magazine="paper"] .vaccine__step-header {
    margin-bottom: 60px; }
    .vaccine[data-magazine="paper"] .vaccine__step-header span {
      width: 100%;
      display: block;
      margin: 43px auto 60px;
      text-align: center; }
  .vaccine[data-magazine="paper"] .vaccine__form-wrap,
  .vaccine[data-magazine="paper"] .vaccine__form-agreement {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto; }
  .vaccine[data-magazine="electronic"] .vaccine__step-header {
    margin-bottom: 107px; }
  .vaccine[data-magazine="electronic"] .vaccine__form-wrap {
    display: flex;
    align-items: center;
    align-content: center; }
  .vaccine[data-magazine="electronic"] .vaccine__form-wrap,
  .vaccine[data-magazine="electronic"] .vaccine__form-agreement {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto; }
  .vaccine[data-magazine="electronic"] .vaccine__form-image {
    max-width: 424px;
    padding-left: 41px; }
  .vaccine[data-magazine="electronic"] .vaccine__input-wrap {
    display: none; }
    .vaccine[data-magazine="electronic"] .vaccine__input-wrap[data-name="form[imie]"], .vaccine[data-magazine="electronic"] .vaccine__input-wrap[data-name="form[nazwisko]"], .vaccine[data-magazine="electronic"] .vaccine__input-wrap[data-name="form[email]"] {
      display: block; }

.newsletter-new {
  background-color: var(--ColorBg);
  color: var(--colorFont); }
  .newsletter-new .newsletter__form.disabled .newsletter__error {
    display: block; }
  .newsletter-new .newsletter__error {
    font-size: 18px;
    color: #ff0000;
    font-weight: 600;
    text-align: center;
    display: none;
    margin-bottom: 30px;
    margin-top: -10px; }
  .newsletter-new .newsletter__container {
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto;
    width: 100%; }
  .newsletter-new .newsletter__left {
    width: 100%;
    padding: 0 15px;
    order: 2;
    max-width: 600px;
    margin: 0 auto; }
    @media (min-width: 1025px) {
      .newsletter-new .newsletter__left {
        order: 1;
        margin: 0; } }
  .newsletter-new .newsletter__right {
    width: 100%;
    padding: 0 15px;
    order: 1;
    margin-bottom: 30px; }
    @media (min-width: 1025px) {
      .newsletter-new .newsletter__right {
        max-width: calc(100% - 600px);
        order: 2;
        margin-bottom: 0; } }
    .newsletter-new .newsletter__right img {
      margin: 0 auto;
      display: block; }
  .newsletter-new .newsletter__gratis {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 40px;
    text-align: center; }
    @media (min-width: 1025px) {
      .newsletter-new .newsletter__gratis {
        text-align: left; } }
  .newsletter-new .newsletter__title {
    font-size: 50px;
    margin: 0 0 20px;
    text-align: center; }
    @media (min-width: 1025px) {
      .newsletter-new .newsletter__title {
        text-align: left; } }
  .newsletter-new .newsletter__input {
    display: block;
    margin: 0 0 40px;
    width: 100%;
    -webkit-box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.16);
    box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.16);
    border: 0;
    outline: 0;
    color: #082134;
    text-align: center;
    font-family: "Objectivity",sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: #fff;
    padding: 25px;
    background-color: #335669;
    border-radius: 28px; }
    .newsletter-new .newsletter__input::placeholder {
      font-weight: 400;
      opacity: 1;
      font-size: 20px;
      color: #ffffff; }
    .newsletter-new .newsletter__input:focus::placeholder, .newsletter-new .newsletter__input:active::placeholder {
      opacity: 0; }
  .newsletter-new .newsletter__submit {
    height: 80px;
    width: 100%;
    height: 80px;
    margin: 0 0 30px;
    background: -moz- oldlinear-gradient(315deg, #35b7c1 0, #3fb693 100%);
    background: linear-gradient(135deg, #35b7c1 0, #3fb693 100%);
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    line-height: 21px;
    font-weight: 700;
    border: 0;
    cursor: pointer;
    border-radius: 28px; }
  .newsletter-new .newsletter__gift {
    position: relative;
    padding-left: 50px;
    margin-bottom: 40px;
    font-size: 11px;
    line-height: 17px;
    opacity: 0.9; }
    .newsletter-new .newsletter__gift-checkbox {
      position: absolute;
      top: 6px;
      left: 0;
      width: 36px;
      height: 36px;
      opacity: 0; }
      .newsletter-new .newsletter__gift-checkbox:checked ~ .newsletter__gift-label:after {
        opacity: 1; }
    .newsletter-new .newsletter__gift-label {
      margin: 0; }
      .newsletter-new .newsletter__gift-label:after {
        content: "";
        position: absolute;
        top: 6px;
        left: 0;
        background-image: url("/templates/main/images/svg/check-teal.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 60%;
        opacity: 0;
        width: 36px;
        height: 36px;
        transition: opacity .2s ease-in-out; }
      .newsletter-new .newsletter__gift-label:before {
        content: '';
        width: 36px;
        height: 36px;
        position: absolute;
        top: 6px;
        left: 0;
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
        background: transparent;
        border: 2px solid #DDDDDD;
        border-radius: 8px; }
  .newsletter-new .avt-info {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 24px;
    font-size: 11px;
    line-height: 17px;
    opacity: 0.9; }
    .newsletter-new .avt-info #button-text {
      font-weight: 700;
      cursor: pointer;
      text-transform: uppercase; }
    .newsletter-new .avt-info #full-text {
      display: none; }
      .newsletter-new .avt-info #full-text.open {
        display: inline; }
    .newsletter-new .avt-info .intro-text {
      display: inline; }

@media (min-width: 1025px) {
  .burger-open .top-menu {
    padding-right: 17px; } }

.top-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  text-align: right;
  background-color: #fff; }
  .top-menu__container {
    padding-top: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    align-content: center; }
  .top-menu--new {
    position: relative;
    background-color: #fff;
    padding: 16px 0 10px;
    display: none; }
    @media (min-width: 1025px) {
      .top-menu--new {
        display: block; } }
    .top-menu--new .top-menu__container {
      display: flex;
      flex-wrap: wrap;
      justify-content: unset;
      padding: 0 15px; }
    .top-menu--new .top-menu__logo {
      justify-self: flex-start; }
      .top-menu--new .top-menu__logo svg {
        position: relative;
        z-index: 3; }
      @media (min-width: 1366px) {
        .top-menu--new .top-menu__logo {
          display: none; } }
    .top-menu--new .top-menu__link {
      justify-self: flex-end;
      margin-left: auto; }
  .top-menu__link {
    color: #1A1B1C;
    border-bottom: 1px dotted #fff;
    padding-bottom: 1px;
    position: relative;
    z-index: 3;
    font-size: 13px;
    transition: color .2s ease-in-out, border-color .2s ease-in-out; }
    .top-menu__link:hover {
      color: #FB3636;
      border-color: #FB3636; }
  .top-menu ul {
    min-height: 50px;
    height: 100%;
    color: #fff;
    max-width: 1440px;
    padding: 0 15px;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 3; }
    .top-menu ul li.current, .top-menu ul li.active, .top-menu ul li.alias-parent-active {
      position: relative; }
      .top-menu ul li.current a, .top-menu ul li.active a, .top-menu ul li.alias-parent-active a {
        color: #fff;
        opacity: 1; }
      .top-menu ul li.current:after, .top-menu ul li.active:after, .top-menu ul li.alias-parent-active:after {
        content: "";
        top: -6px;
        bottom: -6px;
        left: -12px;
        right: -12px;
        border-radius: 10px;
        background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
        position: absolute; }
    .top-menu ul li a {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      opacity: 0.6;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.075em;
      text-transform: uppercase;
      position: relative;
      z-index: 3; }
    .top-menu ul .item-1149.current:after, .top-menu ul .item-1149.active:after {
      display: none; }
    .top-menu ul .item-1149.current a, .top-menu ul .item-1149.active a {
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%); }
    .top-menu ul .item-1149 a {
      background-color: rgba(40, 70, 102, 0.5);
      display: block;
      border-radius: 10px;
      width: 42px;
      height: 34px;
      font-size: 0;
      position: relative;
      opacity: 1; }
      .top-menu ul .item-1149 a:after {
        content: "";
        width: 20px;
        height: 20px;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        position: absolute;
        background-image: url("/templates/main/images/svg/home.svg");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
    .top-menu ul .item-1149, .top-menu ul .item-1150, .top-menu ul .item-1151 {
      margin-right: 35px; }
    @media (max-width: 360px) {
      .top-menu ul .item-1151 {
        margin-right: 0; } }
    .top-menu ul .item-1153, .top-menu ul .item-1152, .top-menu ul .item-1154 {
      justify-self: flex-end;
      margin-left: 35px;
      display: none; }
      @media (min-width: 1025px) {
        .top-menu ul .item-1153, .top-menu ul .item-1152, .top-menu ul .item-1154 {
          display: block; } }
    .top-menu ul .item-1153 {
      margin-left: auto; }
      .top-menu ul .item-1153 a:after {
        content: "";
        height: 16px;
        width: 21px;
        display: inline-block;
        background-image: url("/templates/main/images/svg/magazines.svg");
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 12px; }
    .top-menu ul .item-1152 a:after {
      content: "";
      height: 21px;
      width: 21px;
      display: inline-block;
      background-image: url("/templates/main/images/svg/newsletter.svg");
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 12px; }
    .top-menu ul .item-1154 a:after {
      content: "";
      height: 13px;
      width: 17px;
      display: inline-block;
      background-image: url("/templates/main/images/svg/contact.svg");
      background-position: center;
      background-repeat: no-repeat;
      margin-left: 12px; }

.therapist-menu {
  color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
  margin-right: 30px; }
  .therapist-menu a {
    color: #fff; }
  .therapist-menu__select-wrap {
    opacity: 0;
    transition: opacity .2s ease-in-out;
    margin-right: 30px; }
    .therapist-menu__select-wrap.loaded {
      opacity: 1; }
  .therapist-menu.main .therapist-menu__select-wrap {
    display: none; }
  .therapist-menu__link {
    display: inline-flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 7px;
    min-height: 35px;
    padding: 0 10px; }
  .therapist-menu .cs-select {
    position: relative; }
  .therapist-menu .cs-title {
    padding-right: 25px; }
  .therapist-menu .cs-list {
    height: 100%;
    margin: 0;
    padding: 0;
    padding-right: 24px; }
    .therapist-menu .cs-list ul {
      list-style: none;
      margin: 0;
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      text-transform: none;
      margin: 0; }
  .therapist-menu .cs-list-wrap {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    z-index: 9;
    background-color: #294868;
    width: 330px;
    height: 460px;
    top: calc(100% + 15px);
    left: calc((100% - 330px) / 2);
    transition: opacity .2s ease-in-out;
    padding: 24px 0 24px 24px;
    border-radius: 20px; }
    .therapist-menu .cs-list-wrap:after {
      content: "";
      position: absolute;
      top: -12px;
      left: 0;
      right: 0;
      background-image: url("/templates/main/images/svg/triangle-list.svg");
      width: 14px;
      height: 14px;
      margin: auto; }
  .therapist-menu .cs-btn {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    cursor: pointer; }
    .therapist-menu .cs-btn:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 14px;
      height: 9px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("/templates/main/images/svg/chevron-down.svg");
      transition: transform .2s ease-in-out;
      transform-origin: 50% 50%;
      z-index: 1; }
    .therapist-menu .cs-btn.open:after {
      transform: rotate(180deg); }
  .therapist-menu .open .cs-list-wrap {
    opacity: 1;
    pointer-events: all; }

.therapist-list-problems {
  height: 100%;
  margin: 0;
  padding: 0;
  padding-right: 24px; }
  .therapist-list-problems ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .therapist-list-problems li {
    font-size: 13px;
    line-height: 18px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: 16px; }
    .therapist-list-problems li > a {
      opacity: 0.5;
      padding-bottom: 10px;
      margin-bottom: 8px;
      display: block; }
    .therapist-list-problems li > ul > li {
      font-size: 16px;
      font-weight: 400;
      text-transform: none;
      margin: 0; }
      .therapist-list-problems li > ul > li > a {
        opacity: 1;
        margin: 0;
        display: block;
        letter-spacing: normal; }
  .therapist-list-problems .disable-link {
    pointer-events: none; }

@media (min-width: 1025px) {
  .burger-open #sticky-menu {
    padding-right: 17px; } }

#sticky-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  z-index: 999;
  transition: transform .2s ease-in-out;
  transform: translateY(calc(-100% - 10px));
  box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }
  #sticky-menu.sticked {
    transform: translateY(0);
    pointer-events: all; }
  #sticky-menu .top-menu {
    position: relative;
    top: 0;
    height: 50px;
    display: none; }
    #sticky-menu .top-menu:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #002A43; }
  #sticky-menu .sticky__bottom {
    padding: 13px 0;
    background-color: #fff; }
  #sticky-menu .sticky__search {
    margin-right: 30px;
    position: relative;
    z-index: 5;
    cursor: pointer;
    margin-left: auto;
    display: none; }
    @media (min-width: 360px) {
      #sticky-menu .sticky__search {
        display: block; } }
    @media (min-width: 1366px) {
      #sticky-menu .sticky__search {
        margin-left: 0; } }
  #sticky-menu .sticky__logo > a {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    align-content: center;
    color: #1A1B1C;
    font-size: 0; }
  #sticky-menu .sticky__logo-text {
    color: #1A1B1C;
    margin-left: 30px;
    font-size: 16px;
    position: relative;
    padding-top: 2px;
    font-weight: 500;
    display: none; }
    @media (min-width: 768px) {
      #sticky-menu .sticky__logo-text {
        display: block; } }
    #sticky-menu .sticky__logo-text:after {
      content: "";
      height: 22px;
      left: -16px;
      width: 2px;
      background-color: #1A1B1C;
      opacity: 0.5;
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto; }
  #sticky-menu .sticky__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center; }
  #sticky-menu .sticky__burger {
    cursor: pointer;
    width: 20px;
    height: 14px;
    position: relative;
    order: 2;
    z-index: 5;
    margin-left: auto;
    margin-right: 0; }
    @media (min-width: 360px) {
      #sticky-menu .sticky__burger {
        margin-left: 0; } }
    @media (min-width: 1200px) {
      #sticky-menu .sticky__burger {
        margin-left: 0; } }
    #sticky-menu .sticky__burger span {
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 4px;
      position: absolute;
      background-color: #1A1B1C;
      left: 0;
      transform-origin: 50% 50%;
      transition: all .2s ease-in-out; }
      #sticky-menu .sticky__burger span:first-child {
        top: 0;
        bottom: auto; }
      #sticky-menu .sticky__burger span:nth-child(2) {
        top: 0;
        bottom: 0;
        margin: auto; }
      #sticky-menu .sticky__burger span:nth-child(3) {
        top: 0;
        bottom: 0;
        margin: auto; }
      #sticky-menu .sticky__burger span:last-child {
        bottom: 0;
        top: auto; }
  #sticky-menu .sticky__menu {
    justify-self: flex-end;
    margin-left: auto;
    display: none; }
    @media (min-width: 1366px) {
      #sticky-menu .sticky__menu {
        display: block; } }
    #sticky-menu .sticky__menu .item-1317 {
      display: none; }
    #sticky-menu .sticky__menu .hp-menu > li {
      position: relative; }
      #sticky-menu .sticky__menu .hp-menu > li > a {
        position: relative;
        z-index: 3; }
      #sticky-menu .sticky__menu .hp-menu > li > .sub-menu {
        position: absolute;
        top: calc(100% + 24px);
        opacity: 0;
        pointer-events: none;
        background-color: #fff;
        width: 275px;
        border-radius: 15px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-color: #223D59;
        transition: opacity .2s ease-in-out;
        box-shadow: 0 60px 99px rgba(0, 0, 0, 0.16);
        z-index: 99;
        left: calc((100% - 275px) / 2);
        display: block; }
        #sticky-menu .sticky__menu .hp-menu > li > .sub-menu:after {
          content: "";
          position: absolute;
          top: -1px;
          left: 0;
          right: 0;
          background: linear-gradient(to bottom right, #f16655 0%, #fb3636 100%);
          height: 3px;
          margin: auto; }
        #sticky-menu .sticky__menu .hp-menu > li > .sub-menu .sub-menu__header {
          min-height: 60px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          align-content: center;
          pointer-events: none; }
          #sticky-menu .sticky__menu .hp-menu > li > .sub-menu .sub-menu__header:before {
            content: "";
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 12px; }
          #sticky-menu .sticky__menu .hp-menu > li > .sub-menu .sub-menu__header:after {
            content: "";
            font-size: 18px;
            font-weight: 400;
            color: #fff; }
        #sticky-menu .sticky__menu .hp-menu > li > .sub-menu ul {
          margin: 0;
          padding: 0;
          list-style: none; }
        #sticky-menu .sticky__menu .hp-menu > li > .sub-menu ul > li {
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;
          color: #1A1B1C;
          background-color: #fff; }
          #sticky-menu .sticky__menu .hp-menu > li > .sub-menu ul > li:first-child {
            padding-top: 20px; }
          #sticky-menu .sticky__menu .hp-menu > li > .sub-menu ul > li:last-child {
            padding-bottom: 20px;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px; }
          #sticky-menu .sticky__menu .hp-menu > li > .sub-menu ul > li > a {
            display: block;
            min-height: 40px;
            line-height: 40px;
            font-size: 16px;
            font-weight: 500; }
    #sticky-menu .sticky__menu .hp-menu .item-1055 .sub-menu > ul > li:nth-last-child(2) {
      padding-bottom: 20px;
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px; }
    #sticky-menu .sticky__menu .hp-menu .item-1055 .sub-menu:after {
      width: 115px; }
    #sticky-menu .sticky__menu .hp-menu .item-1055 .sub-menu .sub-menu__header:before {
      background-image: url("/templates/main/images/svg/knowledge.svg");
      width: 28px;
      height: 24px; }
    #sticky-menu .sticky__menu .hp-menu .item-1055 .sub-menu .sub-menu__header:after {
      content: "Wiedza"; }
    #sticky-menu .sticky__menu .hp-menu .item-101 .sub-menu:after {
      width: 175px; }
    #sticky-menu .sticky__menu .hp-menu .item-101 .sub-menu .sub-menu__header:before {
      background-image: url("/templates/main/images/svg/therapist.svg");
      width: 34px;
      height: 35px; }
    #sticky-menu .sticky__menu .hp-menu .item-101 .sub-menu .sub-menu__header:after {
      content: "Terapeuci"; }
    #sticky-menu .sticky__menu .therapist-menu {
      color: #1A1B1C; }
      #sticky-menu .sticky__menu .therapist-menu__link {
        border-color: #1A1B1C;
        color: #1A1B1C;
        font-weight: 500; }
      #sticky-menu .sticky__menu .therapist-menu__select-wrap {
        display: block; }
    #sticky-menu .sticky__menu .cs-title {
      font-weight: 500; }
    #sticky-menu .sticky__menu .cs-btn:after {
      background-image: url("/templates/main/images/svg/chevron-down-black.svg"); }
    #sticky-menu .sticky__menu .cs-list-wrap {
      top: calc(100% + 24px);
      background-color: #fff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }
      #sticky-menu .sticky__menu .cs-list-wrap ul li a {
        color: #1A1B1C; }
      #sticky-menu .sticky__menu .cs-list-wrap:after {
        display: none; }
    #sticky-menu .sticky__menu .item-1020 {
      display: none; }
    #sticky-menu .sticky__menu .item-1155 {
      display: none; }
    #sticky-menu .sticky__menu > ul {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      list-style: none; }
      #sticky-menu .sticky__menu > ul > li {
        margin-right: 30px; }
        #sticky-menu .sticky__menu > ul > li > a {
          transition: color .2s ease-in-out;
          display: inline;
          color: #1A1B1C;
          position: relative;
          z-index: 3;
          font-weight: 500; }
          #sticky-menu .sticky__menu > ul > li > a:after {
            content: "";
            display: block;
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 100%;
            height: 1px;
            background-color: #fff;
            margin: 0;
            padding: 0;
            background-image: none;
            width: auto; }
        #sticky-menu .sticky__menu > ul > li .sub-menu {
          display: none; }
    #sticky-menu .sticky__menu > #burger-menu__menu--main > li {
      position: relative;
      font-size: 16px; }
      @media (min-width: 1025px) {
        #sticky-menu .sticky__menu > #burger-menu__menu--main > li:hover {
          text-decoration: underline; } }
      #sticky-menu .sticky__menu > #burger-menu__menu--main > li.current:hover a, #sticky-menu .sticky__menu > #burger-menu__menu--main > li.active:hover a {
        text-decoration: none; }
      #sticky-menu .sticky__menu > #burger-menu__menu--main > li:before {
        content: "";
        position: absolute;
        left: -5px;
        right: -5px;
        top: calc(100% + 22px);
        height: 4px;
        background-color: #FB3636;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        z-index: 9;
        pointer-events: none; }
      #sticky-menu .sticky__menu > #burger-menu__menu--main > li > a {
        position: relative;
        font-weight: 700;
        z-index: 5; }
      #sticky-menu .sticky__menu > #burger-menu__menu--main > li.item-1169:before {
        display: none; }
      #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        width: 440px;
        margin: auto;
        top: calc(100% + 24px);
        left: calc((100% - 440px) / 2);
        background-color: #fff;
        transition: opacity .2s ease-in-out;
        color: #1A1B1C;
        font-size: 16px;
        margin: 0;
        padding: 35px 42px 30px;
        box-shadow: 0 60px 99px rgba(0, 0, 0, 0.3);
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        display: flex;
        flex-wrap: wrap; }
        #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul {
          list-style: none;
          order: 1;
          margin: 0 0 15px;
          padding: 0; }
          #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li {
            color: #1A1B1C;
            margin-bottom: 5px;
            font-weight: 500; }
            #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li a {
              color: #1A1B1C; }
            #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li ul {
              display: none; }
              #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li ul a {
                color: #1A1B1C; }
            #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li.current, #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li.current > a, #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li.active, #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module > ul > li.active > a {
              color: #FB3636; }
        #sticky-menu .sticky__menu > #burger-menu__menu--main > li > .header-module .header-module__wrap {
          order: 2; }

@media (min-width: 768px) {
  .col-md-4-5 {
    flex: 0 0 34.9999%;
    max-width: 34.9999%; } }

@media (min-width: 1200px) {
  .col-xl-4-5 {
    flex: 0 0 34.9999%;
    max-width: 34.9999%; } }

@media (min-width: 768px) {
  .col-md-3-5 {
    flex: 0 0 29.9999%;
    max-width: 29.9999%; } }

@media (min-width: 1200px) {
  .col-xl-3-5 {
    flex: 0 0 29.9999%;
    max-width: 29.9999%; } }

.last-added.single {
  border-radius: 15px; }

#practice-year input#jform_practice {
  /* Chrome, Safari, Edge, Opera */
  /* Firefox */
  -moz-appearance: textfield; }
  #practice-year input#jform_practice::-webkit-outer-spin-button, #practice-year input#jform_practice::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; }

.header.p-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: transparent; }

.knowledge {
  color: #fff;
  margin-top: -173px; }
  .knowledge-menu {
    background-color: #002B44;
    color: #fff;
    padding: 58px 0; }
    .knowledge-menu-container {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 1280px) {
        .knowledge-menu-container {
          flex-wrap: wrap; }
          .knowledge-menu-container > div {
            order: 1; }
          .knowledge-menu-container > a {
            order: 2; }
          .knowledge-menu-container > ul {
            order: 3; } }
    .knowledge-menu__title {
      font-size: 35px;
      letter-spacing: 1px;
      text-transform: uppercase; }
    .knowledge-menu__list {
      list-style: none;
      margin: 0;
      padding: 0; }
      .knowledge-menu__list-el {
        display: inline-block;
        margin-right: 25px;
        font-size: 16px;
        line-height: 27px; }
    .knowledge-menu__more {
      padding: 10px 15px;
      font-size: 15px;
      line-height: 21px;
      border-radius: 7px;
      border: 1px solid #FFFFFF; }
  .knowledge__slider-text {
    max-width: 100%;
    width: 100%; }
  .knowledge__slider .avt-container-1440 {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between; }
  .knowledge__slider .background-size {
    height: 100%;
    max-width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%; }
  .knowledge__slider .swiper-slide {
    padding-top: 180px;
    padding-bottom: 178px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    height: auto;
    background-color: #002B44; }
    @media (max-width: 768px) {
      .knowledge__slider .swiper-slide {
        padding-bottom: 200px; } }
    .knowledge__slider .swiper-slide::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to right, rgba(0, 43, 68, 0.65) 0%, rgba(0, 43, 68, 0.55) 15%, rgba(0, 12, 45, 0) 100%); }
    .knowledge__slider .swiper-slide::before {
      content: '';
      background-size: cover !important;
      background-repeat: no-repeat !important;
      background-position: center !important;
      position: absolute;
      right: 0;
      top: 0;
      max-width: 960px;
      width: 100%;
      height: 100%; }
  .knowledge__slider-category {
    font-size: 13px;
    line-height: 13px;
    font-weight: 700;
    display: inline-block;
    z-index: 2;
    position: relative;
    margin-bottom: 27px;
    text-transform: uppercase; }
  .knowledge__slider-title {
    font-size: 45px;
    line-height: 53px;
    font-weight: 700;
    max-width: 809px;
    width: 100%;
    z-index: 2;
    position: relative;
    margin-bottom: 29px; }
    @media (max-width: 575px) {
      .knowledge__slider-title {
        font-size: 35px;
        line-height: 43px; } }
  .knowledge__slider-description {
    font-size: 15px;
    line-height: 23px;
    z-index: 2;
    position: relative;
    margin-bottom: 57px;
    max-width: 810px;
    width: 100%; }
  .knowledge__slider-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    position: relative;
    max-width: 100%;
    width: 100%; }
    .knowledge__slider-bottom-tags {
      display: flex;
      flex-wrap: wrap; }
      .knowledge__slider-bottom-tags-el {
        font-size: 13px;
        padding: 9px 11px;
        border-radius: 5px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        margin-right: 10px; }
    .knowledge__slider-bottom-container {
      position: relative; }
    .knowledge__slider-bottom-nav {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 1px;
      position: absolute;
      z-index: 5;
      bottom: 163px;
      right: 0;
      padding: 0 15px; }
      @media (max-width: 768px) {
        .knowledge__slider-bottom-nav {
          bottom: 130px; } }
      .knowledge__slider-bottom-nav-counter {
        margin-right: 14px; }
      .knowledge__slider-bottom-nav-arrows {
        display: flex; }
        .knowledge__slider-bottom-nav-arrows-el {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          position: relative;
          background-color: rgba(255, 255, 255, 0.5);
          margin-left: 10px;
          cursor: pointer; }
  .knowledge__articles {
    margin-top: -112px;
    z-index: 1;
    position: relative;
    margin-bottom: 30px; }
    .knowledge__articles .custom-fs .small-article__text-title {
      font-size: 35px;
      line-height: 40px; }
    .knowledge__articles .row, .knowledge__articles .shops__top, .knowledge__articles .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper, .recommended-col .articles-large-top-slider .swiper-hp-top .knowledge__articles .swiper-wrapper {
      z-index: 1;
      position: relative; }
    .knowledge__articles::after {
      content: '';
      position: absolute;
      top: 112px;
      left: 0;
      width: 100%;
      max-height: 332px;
      height: 100%;
      background: linear-gradient(to bottom, #000c2d 0%, white 100%);
      opacity: 0.07; }
    .knowledge__articles .small-article::after {
      background: linear-gradient(to top, rgba(26, 27, 28, 0.85) 0%, rgba(26, 27, 28, 0.25) 100%); }

.health-menu {
  background-color: #002B44;
  color: #fff;
  padding: 39px 0 93px 0; }
  .health-menu__title {
    font-size: 35px;
    line-height: 48px;
    margin-bottom: 40px;
    border-bottom: 3px solid #FB3636;
    text-transform: uppercase;
    padding-top: 3px; }
    @media (max-width: 500px) {
      .health-menu__title {
        font-size: 28px;
        line-height: 38px; } }
  .health-menu__bottom {
    display: flex; }
    @media (max-width: 950px) {
      .health-menu__bottom {
        flex-wrap: wrap; } }
  .health-menu__image {
    max-width: 262px;
    max-height: 262px;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(221, 221, 221, 0.12);
    border-radius: 10px;
    margin-right: 30px; }
    @media (max-width: 1250px) {
      .health-menu__image {
        margin-right: 50px; } }
    @media (max-width: 950px) {
      .health-menu__image {
        display: none; } }
  .health-menu__list {
    padding: 0;
    margin: 0;
    columns: 3; }
    @media (max-width: 1250px) {
      .health-menu__list {
        columns: 2; } }
    @media (max-width: 950px) {
      .health-menu__list {
        max-width: 100%; } }
    @media (max-width: 700px) {
      .health-menu__list {
        columns: 1; } }
    .health-menu__list-el {
      display: block;
      line-height: 36px;
      padding-right: 10px; }
      .health-menu__list-el a {
        transition: .3s all; }
      .health-menu__list-el:hover a {
        color: #FB3636; }
      .health-menu__list-el a {
        display: block; }
  .health-menu--column {
    color: #1A1B1C; }
    .health-menu--column .health-menu__title {
      font-size: 35px;
      font-weight: 700;
      letter-spacing: 0.05em;
      text-transform: uppercase; }
    .health-menu--column .health-menu__list {
      display: flex;
      flex-wrap: wrap;
      max-width: 1200px; }
      .health-menu--column .health-menu__list .health-menu__list-el {
        width: 100%;
        padding: 0;
        margin-bottom: 30px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-weight: 700;
        padding-right: 10px; }
        @media (min-width: 768px) {
          .health-menu--column .health-menu__list .health-menu__list-el {
            max-width: 50%; } }
        @media (min-width: 1200px) {
          .health-menu--column .health-menu__list .health-menu__list-el {
            max-width: calc(100% / 3); } }
        .health-menu--column .health-menu__list .health-menu__list-el a {
          line-height: normal;
          max-width: calc(100% - 22px); }
        .health-menu--column .health-menu__list .health-menu__list-el:before {
          content: "";
          display: inline-block;
          width: 10px;
          height: 13px;
          margin-right: 12px;
          background-image: url("/templates/main/images/svg/triangle-menu.svg");
          margin-top: -2px; }

.exercises {
  background-color: #DDDDDD;
  padding-top: 46px;
  padding-bottom: 54px;
  margin-bottom: 30px; }
  .exercises__el {
    height: 100%;
    border-radius: 20px;
    background-color: #fff;
    padding: 35px 45px 28px 45px;
    text-align: center;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }
    @media (max-width: 1200px) {
      .exercises__el {
        padding: 15px; } }
    .exercises__el-image {
      margin-bottom: 20px; }
    .exercises__el-title {
      font-size: 21px;
      line-height: 25px;
      font-weight: 700;
      margin-bottom: 37px; }
    .exercises__el-description {
      font-size: 15px;
      line-height: 23px; }
  @media (max-width: 1024px) {
    .exercises__col {
      margin-bottom: 30px; } }
  .exercises__top {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .exercises__top-title {
      font-size: 35px;
      line-height: 31px;
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .exercises__top-more {
      font-size: 14px;
      line-height: 21px;
      padding: 14px 33px 10px 33px;
      font-weight: 700;
      border: 2px solid #1A1B1C;
      margin-bottom: 30px;
      border-radius: 10px; }

.calculator {
  border-radius: 20px;
  background: linear-gradient(135deg, #3fb693 0%, #35b7c1 100%);
  color: #fff;
  padding: 15px; }
  .calculator.calculator-theme {
    max-width: 505px;
    margin: 30px auto; }
  @media (min-width: 768px) {
    .calculator {
      padding: 26px 32px 33px 26px; } }
  .calculator.mw-510 {
    max-width: 510px;
    margin: 50px auto; }
  @media (max-width: 1200px) {
    .calculator {
      margin-bottom: 30px; } }
  .calculator-mb {
    margin-bottom: 62px; }
  .calculator__top {
    display: flex;
    justify-content: space-between; }
    @media (max-width: 767.98px) {
      .calculator__top-image {
        display: none; } }
    @media (max-width: 900px) {
      .calculator__top-image img {
        max-width: 50px; } }
    .calculator__top-text-subtitle {
      font-size: 13px;
      line-height: 21px;
      font-weight: 700;
      letter-spacing: 1px;
      text-transform: uppercase;
      margin-bottom: 20px; }
    .calculator__top-text-title {
      font-size: 28px;
      font-weight: 700; }
    .calculator__top-text-description {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 27px;
      max-width: 270px; }
  .calculator__bottom form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (max-width: 550px) {
      .calculator__bottom form {
        flex-wrap: wrap; } }
    @media (min-width: 768px) {
      .calculator__bottom form {
        justify-content: flex-start; } }
    @media (min-width: 1025px) {
      .calculator__bottom form {
        justify-content: space-between; } }
    @media (min-width: 1200px) {
      .calculator__bottom form {
        justify-content: flex-start; } }
    @media (min-width: 1440px) {
      .calculator__bottom form {
        justify-content: space-between; } }
  .calculator__bottom-weight {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 110px;
    margin-bottom: 10px; }
    @media (max-width: 550px) {
      .calculator__bottom-weight {
        max-width: 45%;
        flex: 0 0 45%; } }
    @media (min-width: 768px) {
      .calculator__bottom-weight {
        margin-right: 20px; } }
    @media (min-width: 1025px) {
      .calculator__bottom-weight {
        margin-right: 0; } }
    @media (min-width: 1200px) {
      .calculator__bottom-weight {
        margin-right: 20px; } }
    @media (min-width: 1440px) {
      .calculator__bottom-weight {
        margin-right: 0; } }
    .calculator__bottom-weight input {
      width: 100%;
      max-height: 40px;
      border: 1px solid #fff;
      background-color: #fff;
      font-size: 16px;
      line-height: 40px;
      font-weight: 700;
      margin-bottom: 6px;
      border-radius: 10px;
      text-align: center; }
    .calculator__bottom-weight-text {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase; }
  .calculator__bottom-select {
    text-align: center; }
    @media (max-width: 550px) {
      .calculator__bottom-select {
        max-width: 45%;
        flex: 0 0 45%; } }
    .calculator__bottom-select-man, .calculator__bottom-select-woman {
      display: inline-block;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      border: 1px solid #fff;
      background-color: transparent;
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 40px;
      font-weight: 700;
      color: #fff;
      margin-bottom: 6px;
      cursor: pointer; }
      .calculator__bottom-select-man.active, .calculator__bottom-select-woman.active {
        background-color: #fff;
        color: #000;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16); }
    .calculator__bottom-select-text {
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase; }
  .calculator__bottom-submit {
    width: 100%;
    margin-top: 10px;
    height: 58px; }
    @media (max-width: 550px) {
      .calculator__bottom-submit {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px; } }
    @media (min-width: 1025px) {
      .calculator__bottom-submit {
        width: auto;
        height: auto;
        margin-top: 0; } }
    @media (min-width: 1200px) {
      .calculator__bottom-submit {
        width: 100%;
        margin-top: 10px;
        height: 58px; } }
    @media (min-width: 1440px) {
      .calculator__bottom-submit {
        width: auto;
        height: auto;
        margin-top: 0; } }
    .calculator__bottom-submit input {
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      height: 100%;
      padding: 0 55px;
      font-size: 13px;
      line-height: 21px;
      font-weight: 700;
      letter-spacing: 2px;
      color: #fff;
      border: none;
      border-radius: 10px;
      text-transform: uppercase;
      cursor: pointer;
      width: 100%; }
      @media (max-width: 550px) {
        .calculator__bottom-submit input {
          padding: 15px 55px; } }
      @media (min-width: 1440px) {
        .calculator__bottom-submit input {
          width: auto; } }

.newsletter-module {
  background-color: #002B44;
  border-radius: 20px;
  padding: 45px 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%; }
  .newsletter-module--red.newsletter-module {
    background: linear-gradient(135deg, #f16655 0, #fb3636 100%);
    max-width: 370px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    padding: 0;
    overflow: hidden;
    z-index: 9;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 30px 30px;
    height: auto; }
    .newsletter-module--red.newsletter-module:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url("/templates/main/images/static/newsletter-papers.png");
      background-position: center right;
      background-repeat: no-repeat;
      z-index: 3;
      pointer-events: none; }
    .newsletter-module--red.newsletter-module:after {
      content: "";
      position: absolute;
      bottom: -6%;
      left: 0%;
      width: 200%;
      height: 200%;
      transform: rotate(45deg);
      background-color: rgba(255, 255, 255, 0.3);
      transform-origin: 0 100%;
      pointer-events: none; }
  .newsletter-module--red .newsletter-module__top {
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
    position: relative;
    z-index: 9; }
  .newsletter-module--red .newsletter-module__text {
    max-width: 160px;
    width: 100%;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    position: relative;
    z-index: 9; }
    .newsletter-module--red .newsletter-module__text span {
      text-decoration: underline; }
    .newsletter-module--red .newsletter-module__text strong {
      display: block;
      width: 100%;
      text-transform: uppercase;
      font-size: 23px;
      font-weight: 700;
      letter-spacing: 0.016em;
      margin-top: 14px;
      margin-bottom: 18px; }
  .newsletter-module--red .newsletter-module__btn {
    position: relative;
    z-index: 9;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    align-self: flex-end;
    justify-self: flex-end;
    margin-top: auto;
    width: 100%;
    min-height: 66px;
    background-color: #fff;
    border-radius: 11px;
    box-shadow: 0 20px 30px rgba(0, 0, 0, 0.16);
    text-transform: uppercase;
    font-size: 13px;
    letter-spacing: 0.05em;
    font-weight: 700; }

.col-right .newsletter-module--red {
  margin-bottom: 30px; }

.important-topics {
  background: linear-gradient(135deg, #002b44 0%, #26828a 100%);
  color: #fff;
  padding: 42px 15px;
  margin-top: 60px;
  margin-bottom: 54px; }
  .important-topics__wrapper {
    display: flex;
    align-items: center; }
    @media (max-width: 1024px) {
      .important-topics__wrapper {
        flex-wrap: wrap; } }
  .important-topics__title {
    font-size: 35px;
    line-height: 43px;
    white-space: nowrap;
    margin-right: 48px;
    text-transform: uppercase; }
    @media (max-width: 1024px) {
      .important-topics__title {
        max-width: 100%;
        width: 100%;
        margin-bottom: 15px; } }
  .important-topics__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap; }
    @media (max-width: 1024px) {
      .important-topics__list {
        max-width: 100%;
        width: 100%; } }
    .important-topics__list-el {
      margin-right: 10px;
      margin-bottom: 10px;
      background-color: #F7F7F7;
      color: #1F1F1F;
      border-radius: 5px;
      font-weight: 700;
      max-height: 29px;
      height: 100%; }
      .important-topics__list-el a {
        padding: 5px 10px;
        display: block; }
      .important-topics__list-el.red {
        background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
        color: #fff;
        text-transform: uppercase; }

.our-guide__articles {
  display: flex;
  margin-bottom: 60px;
  border-radius: 20px;
  overflow: hidden; }
  @media (max-width: 1200px) {
    .our-guide__articles {
      flex-wrap: wrap; } }
  .our-guide__articles-left {
    padding: 27px 40px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    align-items: flex-end;
    max-width: 609px; }
    @media (max-width: 500px) {
      .our-guide__articles-left {
        padding: 15px; } }
    @media (max-width: 1200px) {
      .our-guide__articles-left {
        max-width: 100%;
        flex: 0 0 100%;
        padding-top: 200px; } }
    .our-guide__articles-left::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 43, 68, 0.5); }
    .our-guide__articles-left-category {
      background: linear-gradient(135deg, #3fb693 0%, #35b7c1 100%);
      display: inline-block;
      font-size: 13px;
      line-height: 13px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 9px 14px 7px 14px;
      border-radius: 5px;
      margin-bottom: 20px; }
    .our-guide__articles-left-title {
      font-size: 32px;
      line-height: 42px;
      font-weight: 700;
      margin-bottom: 20px; }
      @media (max-width: 600px) {
        .our-guide__articles-left-title {
          font-size: 28px;
          line-height: 38px; } }
    .our-guide__articles-left-description {
      font-size: 15px;
      line-height: 23px; }
    .our-guide__articles-left-text {
      color: #fff;
      position: relative;
      z-index: 1; }
  .our-guide__articles-right {
    padding: 30px;
    background: linear-gradient(135deg, #284666 0%, #002b44 100%);
    color: #fff; }
    @media (max-width: 500px) {
      .our-guide__articles-right {
        padding: 15px; } }
    @media (max-width: 1200px) {
      .our-guide__articles-right {
        max-width: 100%;
        flex: 0 0 100%;
        display: flex;
        flex-wrap: wrap; } }
    .our-guide__articles-right-el:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border: none; }
      @media (max-width: 1200px) {
        .our-guide__articles-right-el:last-child {
          margin-bottom: 10px;
          padding-bottom: 14px; } }
    .our-guide__articles-right-el {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      padding-bottom: 14px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1); }
      @media (max-width: 1200px) {
        .our-guide__articles-right-el {
          max-width: 50%;
          flex: 0 0 50%;
          border-bottom: none; } }
      @media (max-width: 768px) {
        .our-guide__articles-right-el {
          max-width: 100%;
          flex: 0 0 100%; } }
      .our-guide__articles-right-el-image {
        max-width: 120px;
        flex: 0 0 120px;
        width: 100%;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 23px; }
      .our-guide__articles-right-el-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700; }

.article-tags {
  margin-top: 10px;
  margin-bottom: 36px; }
  .article-tags__title {
    font-size: 13px;
    line-height: 17px;
    font-weight: 700;
    border-bottom: 1.3px solid #FB3636;
    padding-bottom: 7px;
    margin-bottom: 9px;
    text-transform: uppercase; }
  .article-tags__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap; }
    .article-tags__list li a {
      color: #1F1F1F !important;
      text-decoration: none; }
    .article-tags__list li.red a {
      color: #fff !important; }
    .article-tags__list-el {
      margin-right: 10px;
      margin-bottom: 10px;
      color: #1F1F1F;
      border-radius: 5px;
      font-weight: 700;
      max-height: 29px;
      height: 100%;
      font-size: 13px;
      line-height: 17px;
      border: 1.3px solid #DDDDDD; }
      .article-tags__list-el a {
        display: block;
        padding: 5px 10px; }
      .article-tags__list-el.red {
        background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
        font-weight: 700;
        text-transform: uppercase;
        color: #fff;
        border: 1.3px solid #fff; }
      .article-tags__list-el:hover {
        background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
        color: #fff !important; }
        .article-tags__list-el:hover a {
          color: #fff !important; }

.more-in-section {
  margin-bottom: 52px; }
  .more-in-section .hidden {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: 0px;
    transition: .5s all; }
    .more-in-section .hidden.show {
      max-height: 3000px; }
  .more-in-section a.more-in-section__link {
    color: #1A1B1C;
    text-decoration: none; }
  .more-in-section__image {
    margin-bottom: 28px; }
    .more-in-section__image img {
      border-radius: 15px; }
  .more-in-section__title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 18px; }
  .more-in-section__description {
    font-size: 15px;
    line-height: 23px;
    margin-bottom: 30px; }
  .more-in-section__btn {
    font-size: 15px;
    line-height: 21px;
    font-weight: 700;
    width: 100%;
    padding: 30px;
    text-align: center;
    border: 2px solid #DDDDDD;
    margin-top: 30px;
    border-radius: 10px;
    text-transform: uppercase;
    cursor: pointer; }
  .more-in-section-tags {
    margin-bottom: 20px; }

.recommended-articles {
  padding: 30px 20px 20px 20px;
  background: linear-gradient(135deg, #284666 0%, #284666 100%);
  color: #fff;
  border-radius: 20px;
  margin-bottom: 30px;
  position: relative;
  z-index: 3; }
  .recommended-articles-title {
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding-bottom: 15px;
    margin-bottom: 16px;
    border-bottom: 3px solid #FB3636; }
    .recommended-articles-title-text {
      font-size: 13px;
      display: block; }
  .recommended-articles-el {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    margin-bottom: 20px; }
    .recommended-articles-el-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin-right: 34px; }
    .recommended-articles-el-image {
      width: 70px;
      max-width: 70px;
      flex: 0 0 70px; }
      .recommended-articles-el-image img {
        border-radius: 5px; }

.recommended-articles-right {
  margin-bottom: 30px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.22);
  border-radius: 15px;
  position: relative;
  z-index: 3;
  background-color: #fff; }
  .recommended-articles-right__title {
    padding: 28px 22px 10px 22px;
    margin-bottom: 36px;
    border-bottom: 3px solid #F16655;
    font-size: 20px;
    line-height: 44px;
    font-weight: 700;
    text-transform: uppercase; }
  .recommended-articles-right__el-image {
    margin-bottom: 37px; }
  .recommended-articles-right__el-title {
    font-size: 20px;
    line-height: 25px;
    font-weight: 700;
    text-align: center;
    padding: 0 20px;
    margin-bottom: 13px; }
  .recommended-articles-right__el-description {
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 0 20px 40px 20px; }

.avt-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background-color: #000000;
  transition: transform .35s ease-in-out;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 50px 15px;
  max-height: 100vh;
  z-index: 999999; }
  @media (min-width: 768px) {
    .avt-popup {
      padding: 50px 30px; } }
  @media (min-width: 1366px) {
    .avt-popup {
      padding: 50px; } }
  .avt-popup.zoom {
    padding: 0;
    overflow: hidden; }
    .avt-popup.zoom .avt-popup__close {
      right: 30px; }
  .avt-popup.--img .avt-popup__image-wrap {
    width: 100%;
    max-width: 100%; }
    .avt-popup.--img .avt-popup__image-wrap:after {
      display: none; }
  .avt-popup__image-wrap {
    width: 100%;
    position: relative;
    background-repeat: no-repeat;
    background-position: -99999999px -99999999px;
    margin-bottom: 30px;
    height: auto; }
    @media (min-width: 576px) {
      .avt-popup__image-wrap {
        padding: 0 15px; } }
    @media (min-width: 1025px) {
      .avt-popup__image-wrap {
        overflow: hidden;
        height: 100%;
        max-width: 85%;
        margin-bottom: 0; } }
    .avt-popup__image-wrap:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: inherit;
      filter: blur(15px) brightness(80%);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
    .avt-popup__image-wrap:after {
      content: "";
      position: absolute;
      bottom: -15px;
      left: -15px;
      right: -15px;
      height: 30%;
      background: linear-gradient(180deg, rgba(153, 218, 255, 0) 0%, black 100%);
      pointer-events: none; }
    .avt-popup__image-wrap.zoom {
      margin-top: 0;
      overflow: auto;
      max-width: none;
      max-height: none;
      position: relative;
      z-index: 3; }
      .avt-popup__image-wrap.zoom .avt-popup__image {
        cursor: zoom-out;
        max-width: none;
        max-height: none; }
      .avt-popup__image-wrap.zoom .avt-popup__counter,
      .avt-popup__image-wrap.zoom .avt-popup__prev,
      .avt-popup__image-wrap.zoom .avt-popup__next,
      .avt-popup__image-wrap.zoom .avt-popup__description {
        display: none; }
      .avt-popup__image-wrap.zoom:after {
        display: none; }
  .avt-popup__image {
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0 auto;
    display: block;
    cursor: zoom-in;
    box-shadow: 3px 6px 16px rgba(0, 0, 0, 0.16);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: relative;
    margin: 2.5% auto 30px; }
    @media (min-width: 1025px) {
      .avt-popup__image {
        max-width: calc(100% - 30px);
        position: absolute;
        margin: auto; } }
  .avt-popup__description {
    width: auto;
    text-align: center;
    color: #fff;
    bottom: 0;
    text-align: center;
    width: 100%;
    z-index: 3;
    position: relative;
    margin-bottom: 60px; }
    @media (min-width: 576px) {
      .avt-popup__description {
        text-align: left; } }
    @media (min-width: 1025px) {
      .avt-popup__description {
        left: 15px;
        margin-bottom: 0;
        position: absolute;
        max-width: calc(100% - 165px); } }
    @media (min-width: 1366px) {
      .avt-popup__description {
        max-width: 100%;
        text-align: center;
        bottom: 45px; } }
    .avt-popup__description .title {
      margin-bottom: 10px;
      font-weight: 700;
      font-size: 36px;
      line-height: 39px; }
      @media (min-width: 576px) {
        .avt-popup__description .title {
          font-size: 49px;
          line-height: 53px; } }
    .avt-popup__description .introtext {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400; }
  .avt-popup__close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 3; }
    .avt-popup__close:after, .avt-popup__close:before {
      content: "";
      display: block;
      width: 30px;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: -999999px;
      right: -999999px;
      bottom: -999999px;
      left: -999999px;
      margin: auto; }
    .avt-popup__close:before {
      transform: rotate(-45deg); }
    .avt-popup__close:after {
      transform: rotate(45deg); }
  .avt-popup.open {
    transform: translateX(0); }
    @media (max-width: 1025px) {
      .avt-popup.open {
        overflow: scroll; } }
  .avt-popup__gallery {
    text-align: center;
    overflow: auto;
    max-height: 100%;
    width: 100%;
    padding-bottom: 30px;
    white-space: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    @media (min-width: 576px) {
      .avt-popup__gallery {
        display: block;
        white-space: nowrap;
        padding: 0 15px; } }
    @media (min-width: 1025px) {
      .avt-popup__gallery {
        max-width: 15%;
        padding-bottom: 0;
        white-space: normal; } }
    .avt-popup__gallery-item {
      margin-bottom: 15px;
      cursor: pointer;
      background-size: cover;
      background-position: center;
      transition: transform .35s ease-in-out;
      display: inline-block;
      margin: 0 5px;
      width: calc((100% / 3) - 10px);
      padding-bottom: 30%;
      margin-bottom: 10px; }
      @media (min-width: 576px) {
        .avt-popup__gallery-item {
          width: 30vw;
          margin-bottom: 0; } }
      @media (min-width: 768px) {
        .avt-popup__gallery-item {
          width: 20vw;
          padding-bottom: 15%; } }
      @media (min-width: 1025px) {
        .avt-popup__gallery-item {
          display: block;
          padding-bottom: 65%;
          width: 100%;
          margin: 0 0 15px; } }
      @media (min-width: 576px) {
        .avt-popup__gallery-item:first-child {
          margin-left: 0; } }
      @media (min-width: 576px) {
        .avt-popup__gallery-item:last-child {
          margin-right: 0; } }
      .avt-popup__gallery-item.active {
        box-shadow: 0px 0px 15px rgba(255, 255, 255, 0.16); }
  .avt-popup__next, .avt-popup__prev {
    position: absolute;
    width: 35px;
    height: 30px;
    bottom: 0;
    margin: auto;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 3;
    background-size: contain; }
  .avt-popup__next {
    right: 0;
    background-image: url("/templates/main/images/svg/next-white.svg"); }
  .avt-popup__prev {
    background-image: url("/templates/main/images/svg/prev-white.svg");
    left: 0;
    right: auto; }
    @media (min-width: 576px) {
      .avt-popup__prev {
        left: auto;
        right: 115px; } }
  .avt-popup__counter {
    bottom: 0;
    color: #fff;
    z-index: 3;
    user-select: none;
    font-size: 16px;
    line-height: 30px;
    position: relative;
    width: 100%;
    pointer-events: none;
    position: absolute;
    text-align: center; }
    @media (min-width: 576px) {
      .avt-popup__counter {
        right: 55px;
        text-align: right; } }
    .avt-popup__counter-total {
      display: inline; }
    .avt-popup__counter-current {
      display: inline; }
      .avt-popup__counter-current:after {
        content: "/"; }

.ocl-module {
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.22); }
  .ocl-module__title {
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 11px;
    border-bottom: 3px solid #F16655;
    text-transform: uppercase;
    margin: 0 22px 36px 22px;
    padding-top: 27px; }
  .ocl-module__wrapper {
    background-color: #fff;
    border-radius: 15px;
    margin-bottom: 30px; }
  .ocl-module__el {
    text-align: center; }
    .ocl-module__el-title {
      font-size: 20px;
      line-height: 25px;
      font-weight: 700;
      text-align: center;
      max-width: 324px;
      margin: 21px auto 14px auto; }
    .ocl-module__el-description {
      font-size: 14px;
      line-height: 20px;
      padding: 0 30px 20px 30px; }
    .ocl-module__el-image {
      margin-bottom: -13px; }
    .ocl-module__el-category {
      font-size: 10px;
      color: #fff;
      background-color: #FB3636;
      padding: 3px 10px;
      text-transform: uppercase;
      display: inline-block;
      margin-bottom: -10px auto 0 auto; }
    .ocl-module__el.only-title {
      padding: 37.5px 74px;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1); }
      @media (max-width: 576px) {
        .ocl-module__el.only-title {
          padding: 15px; } }
  .ocl-module__see-more {
    background: linear-gradient(to right, #35b7c1 0%, #3fb693 100%);
    color: #fff;
    padding: 44px 0 56px 0;
    text-align: center;
    margin-bottom: 50px;
    border-bottom-right-radius: 7px;
    border-bottom-left-radius: 7px; }
    .ocl-module__see-more .small {
      font-size: 15px;
      margin-bottom: 10px; }

.calendar-slider {
  margin: 70px 0 0 0; }
  .calendar-slider .swiper-wrapper div:first-of-type .calendar-slider__item {
    border-left: 1px solid rgba(112, 112, 112, 0.1); }
  .calendar-slider .swiper-button-next,
  .calendar-slider .swiper-button-prev {
    position: absolute;
    top: 180px;
    bottom: 0;
    width: 135px;
    height: 135px;
    background-color: #fff;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16);
    background-image: none;
    border-radius: 50%;
    transition: opacity .2s ease-in-out; }
    .calendar-slider .swiper-button-next img,
    .calendar-slider .swiper-button-prev img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      display: block;
      width: 27px;
      height: 27px; }
    .calendar-slider .swiper-button-next.swiper-button-disabled,
    .calendar-slider .swiper-button-prev.swiper-button-disabled {
      opacity: 0; }
  .calendar-slider .swiper-slide {
    height: auto; }
  .calendar-slider .swiper-button-next {
    right: -70px; }
    .calendar-slider .swiper-button-next img {
      left: 25px;
      right: auto; }
  .calendar-slider .swiper-button-prev {
    left: -70px; }
    .calendar-slider .swiper-button-prev img {
      right: 25px;
      left: auto; }
  .calendar-slider__item {
    display: block;
    border: 1px solid rgba(112, 112, 112, 0.1);
    height: 100%;
    padding-bottom: 62px;
    border-left: none;
    position: relative;
    background-color: rgba(255, 255, 255, 0.1); }
    .calendar-slider__item .today {
      position: absolute;
      right: 0;
      top: 0; }
    .calendar-slider__item-date {
      padding-top: 30px;
      padding-left: 28px;
      margin-bottom: 37px; }
      .calendar-slider__item-date .day {
        font-size: 47px;
        line-height: 47px;
        font-weight: 700; }
      .calendar-slider__item-date .month {
        font-size: 15px;
        line-height: 29px;
        font-weight: 700; }
      .calendar-slider__item-date .day-bottom {
        color: #1A1B1C;
        font-size: 15px;
        line-height: 29px;
        text-transform: uppercase; }
    .calendar-slider__item-image {
      margin: 0 -1px 47px -1px; }
    .calendar-slider__item-title {
      font-size: 15px;
      line-height: 29px;
      font-weight: 700;
      padding: 0 15px;
      text-align: center; }

.sticky-products {
  position: fixed;
  bottom: -210px;
  left: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  box-shadow: 0 -16px 55px rgba(0, 0, 0, 0.16);
  z-index: 10;
  transition: .5s all;
  opacity: 1; }
  .sticky-products.show {
    bottom: 0; }
  .sticky-products.closed {
    opacity: 0; }
  @media (max-width: 460px) {
    .sticky-products {
      flex-wrap: wrap; } }
  .sticky-products__close {
    position: absolute;
    right: 0;
    bottom: 100%;
    padding: 15px;
    background-color: #fff;
    cursor: pointer;
    border-top-left-radius: 7px; }
  .sticky-products__recommend {
    max-width: 255px;
    width: 100%;
    background: linear-gradient(to bottom, #3fb693 0%, #35b7c1 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    line-height: 30px;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff; }
    @media (max-width: 460px) {
      .sticky-products__recommend {
        max-width: 100%;
        padding: 15px 0; } }
  .sticky-products__slider {
    padding: 10px 15px;
    position: relative; }
    @media (max-width: 460px) {
      .sticky-products__slider {
        max-width: 100%; } }
    .sticky-products__slider::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      max-width: 255px;
      width: 100%;
      height: 100%;
      background: linear-gradient(to left, white 0%, rgba(255, 255, 255, 0) 100%);
      z-index: 1;
      pointer-events: none; }
    .sticky-products__slider .swiper-button-next, .sticky-products__slider .swiper-button-prev {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 80px;
      height: 80px;
      background-color: #fff;
      box-shadow: 0 3px 33px rgba(0, 0, 0, 0.16);
      background-image: none;
      border-radius: 50%;
      margin: auto;
      transition: opacity .2s ease-in-out;
      z-index: 2;
      display: flex;
      align-items: center; }
      .sticky-products__slider .swiper-button-next.swiper-button-disabled, .sticky-products__slider .swiper-button-prev.swiper-button-disabled {
        opacity: 0; }
      .sticky-products__slider .swiper-button-next img, .sticky-products__slider .swiper-button-prev img {
        position: absolute;
        display: block;
        width: 20px;
        height: 20px; }
    .sticky-products__slider .swiper-button-next {
      right: -40px;
      justify-content: flex-start; }
      .sticky-products__slider .swiper-button-next img {
        margin-left: 12px; }
    .sticky-products__slider .swiper-button-prev {
      left: -40px;
      justify-content: flex-end; }
      .sticky-products__slider .swiper-button-prev img {
        margin-right: 12px; }
    .sticky-products__slider-item {
      display: flex;
      align-items: center;
      padding: 0 15px;
      height: 100%; }
      .sticky-products__slider-item-image {
        max-width: 90px;
        width: 100%; }
      .sticky-products__slider-item-text-category {
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #FB3636; }
      .sticky-products__slider-item-text-title {
        font-size: 15px;
        line-height: 21px;
        font-weight: 700; }

#pushpushgo-container .ppg__fab {
  transition: .5s all; }

.sticky-product {
  background-color: #E3A61A;
  position: fixed;
  width: 100%;
  bottom: -300px;
  z-index: 300;
  transition: .3s all; }
  .sticky-product.show {
    bottom: 0; }
  .sticky-product__close {
    width: 45px;
    height: 45px;
    right: 0;
    top: -45px;
    position: absolute;
    background-color: #fff;
    border-top-left-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
  .sticky-product__wrapper {
    display: flex;
    align-items: center;
    padding: 0 52px; }
    @media (max-width: 1550px) {
      .sticky-product__wrapper {
        padding: 0 15px; } }
    @media (max-width: 1250px) {
      .sticky-product__wrapper {
        flex-wrap: wrap; } }
  .sticky-product__logo {
    margin-right: 43px; }
    @media (max-width: 1250px) {
      .sticky-product__logo {
        max-width: 50%;
        flex: 0 0 50%;
        margin: 0 0 10px 0;
        display: flex;
        justify-content: center;
        align-items: center; } }
    @media (max-width: 575px) {
      .sticky-product__logo {
        flex-wrap: wrap;
        margin-bottom: 0px;
        max-width: calc(100% - 90px);
        flex: 0 0 calc(100% - 90px); }
        .sticky-product__logo img {
          max-width: 110px;
          margin-bottom: 10px; } }
    .sticky-product__logo-text {
      font-size: 12px;
      line-height: 16px;
      font-weight: 700;
      text-align: center;
      display: none;
      width: 100%; }
      @media (max-width: 575px) {
        .sticky-product__logo-text {
          display: block; } }
  .sticky-product__images {
    padding: 8px 36px 8px 43px;
    background-color: #FAEED4;
    margin-right: 37px;
    display: flex; }
    @media (max-width: 1250px) {
      .sticky-product__images {
        max-width: 50%;
        flex: 0 0 50%;
        margin: 0 -15px 10px 0;
        display: flex;
        justify-content: center;
        margin-left: 15px; } }
    @media (max-width: 575px) {
      .sticky-product__images {
        margin-bottom: 0px;
        padding: 11px;
        max-width: 90px;
        flex: 0 0 90px; } }
    @media (max-width: 575px) {
      .sticky-product__images-second {
        display: none; } }
  .sticky-product__title {
    font-size: 28px;
    line-height: 53px;
    font-weight: 700;
    margin-right: 33px; }
    @media (max-width: 1460px) {
      .sticky-product__title {
        font-size: 22px;
        line-height: 32px; } }
    @media (max-width: 1250px) {
      .sticky-product__title {
        margin-bottom: 10px; } }
    @media (max-width: 1024px) {
      .sticky-product__title {
        max-width: 100%;
        flex: 0 0 100%; } }
    @media (max-width: 575px) {
      .sticky-product__title {
        display: none; } }
  .sticky-product__order {
    font-size: 20px;
    line-height: 20px;
    border-left: 1px solid rgba(0, 0, 0, 0.26);
    padding-left: 33px;
    margin-right: 25px;
    height: 68px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center; }
    @media (max-width: 1460px) {
      .sticky-product__order {
        font-size: 18px;
        line-height: 18px; } }
    @media (max-width: 1360px) {
      .sticky-product__order {
        padding-left: 15px; } }
    @media (max-width: 1250px) {
      .sticky-product__order {
        margin-bottom: 10px; } }
    @media (max-width: 1024px) {
      .sticky-product__order {
        padding-left: 0px;
        border-left: none;
        height: auto; } }
    @media (max-width: 575px) {
      .sticky-product__order {
        display: none; } }
    .sticky-product__order-title {
      width: 100%; }
    .sticky-product__order-www {
      font-weight: 700; }
  .sticky-product__phone {
    padding-left: 16px;
    border-left: 1px solid rgba(0, 0, 0, 0.26);
    height: 68px;
    display: flex;
    align-items: center;
    align-content: center;
    white-space: nowrap;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700; }
    @media (max-width: 1460px) {
      .sticky-product__phone {
        font-size: 18px;
        line-height: 18px; } }
    @media (max-width: 1360px) {
      .sticky-product__phone {
        padding-left: 15px; } }
    @media (max-width: 1250px) {
      .sticky-product__phone {
        margin-bottom: 10px; } }
    @media (max-width: 1024px) {
      .sticky-product__phone {
        height: auto; } }
    @media (max-width: 575px) {
      .sticky-product__phone {
        display: none; } }
    @media (max-width: 500px) {
      .sticky-product__phone img {
        max-width: 20px; } }

.calculator-single {
  padding-top: 173px;
  margin-top: -173px;
  color: #fff;
  max-width: 900px; }
  .calculator-single__top {
    background-color: #002B44;
    margin-bottom: 41px; }
    @media (min-width: 1024px) {
      .calculator-single__top {
        background-image: url("/templates/main/images/bmi-image.png");
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain; }
        .calculator-single__top.nmc {
          background-image: linear-gradient(to right, #002B44 50%, rgba(255, 255, 255, 0) 100%), url("/templates/main/images/nmc-background.jpg"); } }
    @media (max-width: 1250px) {
      .calculator-single__top.nmc {
        background-size: cover; } }
  .calculator-single__title {
    font-size: 40px;
    line-height: 49px;
    font-weight: 700;
    margin-bottom: 20px; }
    @media (max-width: 550px) {
      .calculator-single__title {
        font-size: 28px;
        line-height: 37px; } }
  .calculator-single__description {
    font-size: 16px;
    line-height: 25px;
    opacity: 0.7;
    margin-bottom: 42px; }
  .calculator-single__count {
    display: flex;
    margin-bottom: 41px; }
    .calculator-single__count-growth {
      text-align: center; }
      .calculator-single__count-growth-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 15px; }
      .calculator-single__count-growth-bottom {
        padding-top: 20px;
        padding-bottom: 14px;
        margin-left: 30px;
        border-right: 1px solid rgba(255, 255, 255, 0.15); }
        .calculator-single__count-growth-bottom.nmc {
          display: flex; }
        .calculator-single__count-growth-bottom-btn {
          font-size: 19px;
          font-weight: 700;
          padding: 16px;
          background-color: rgba(255, 255, 255, 0.1);
          border-radius: 15px;
          width: 47px;
          height: 47px;
          display: flex;
          justify-content: center;
          align-items: center; }
          .calculator-single__count-growth-bottom-btn.active {
            background-color: white;
            color: #000; }
          .calculator-single__count-growth-bottom-btn.error {
            border: 1px solid red; }
        .calculator-single__count-growth-bottom-woman {
          display: flex;
          margin-right: 21px; }
        .calculator-single__count-growth-bottom-man {
          display: flex;
          margin-right: 34px; }
      .calculator-single__count-growth img {
        max-width: 35px;
        margin-right: 21px; }
      .calculator-single__count-growth input::-webkit-outer-spin-button,
      .calculator-single__count-growth input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
        /* <-- Apparently some margin are still there even though it's hidden */ }
      .calculator-single__count-growth input[type=number] {
        -moz-appearance: textfield;
        /* Firefox */ }
      .calculator-single__count-growth input {
        width: 141px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.1);
        border: none;
        border-radius: 15px;
        padding: 16px 0 12px 0;
        color: #fff;
        margin-right: 30px; }
        .calculator-single__count-growth input.error {
          border: 1px solid red; }
    .calculator-single__count-weight {
      text-align: center; }
      .calculator-single__count-weight-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 15px; }
      .calculator-single__count-weight-bottom {
        padding-top: 20px;
        padding-bottom: 14px;
        margin-left: 30px;
        border-right: 1px solid rgba(255, 255, 255, 0.15); }
      .calculator-single__count-weight img {
        max-width: 35px;
        margin-right: 15px; }
      .calculator-single__count-weight input::-webkit-outer-spin-button,
      .calculator-single__count-weight input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
        /* <-- Apparently some margin are still there even though it's hidden */ }
      .calculator-single__count-weight input[type=number] {
        -moz-appearance: textfield;
        /* Firefox */ }
      .calculator-single__count-weight input {
        width: 141px;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.1);
        border: none;
        border-radius: 15px;
        padding: 16px 0 12px 0;
        color: #fff;
        margin-right: 30px; }
        .calculator-single__count-weight input.error {
          border: 1px solid red; }
    .calculator-single__count-score {
      text-align: center; }
      .calculator-single__count-score-title {
        border-bottom: 1px solid rgba(255, 255, 255, 0.15);
        padding-bottom: 15px;
        margin-bottom: 20px; }
      .calculator-single__count-score-bottom {
        display: flex;
        align-items: center;
        padding-left: 28px;
        padding-right: 10px; }
        .calculator-single__count-score-bottom-btn {
          font-size: 25px;
          line-height: 31px;
          font-weight: 700;
          background: linear-gradient(135deg, #3fb693 0%, #35b7c1 100%);
          padding: 12px;
          display: block;
          width: 125px;
          border-radius: 15px;
          max-height: 47px;
          text-transform: uppercase;
          margin-right: 20px;
          cursor: pointer;
          border: none;
          color: #fff; }
          .calculator-single__count-score-bottom-btn.nmc.hide {
            display: none; }
        .calculator-single__count-score-bottom-result {
          white-space: nowrap; }
          .calculator-single__count-score-bottom-result img {
            margin-right: 10px; }
        .calculator-single__count-score-bottom-results {
          display: none; }
          .calculator-single__count-score-bottom-results.show {
            display: flex; }
          .calculator-single__count-score-bottom-results-broc {
            width: 95px;
            padding-right: 13.5px;
            margin-right: 19px;
            border-right: 1px solid rgba(255, 255, 255, 0.15);
            white-space: nowrap; }
          .calculator-single__count-score-bottom-results-top {
            font-size: 25px;
            font-weight: 700; }
  .calculator-single__btns-clean {
    padding: 10px 25px 10px 14px;
    font-size: 12px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, 0.15);
    display: inline-block;
    border-radius: 11px;
    margin-bottom: 28px;
    cursor: pointer;
    margin-right: 5px;
    transition: .5s all; }
    .calculator-single__btns-clean img {
      transition: .5s all;
      max-width: 22px;
      margin-right: 12px; }
  .calculator-single__btns-share {
    padding: 10px 25px 10px 14px;
    font-size: 12px;
    line-height: 21px;
    font-weight: 700;
    text-transform: uppercase;
    border: 1px solid rgba(255, 255, 255, 0.15);
    display: inline-block;
    border-radius: 11px;
    margin-bottom: 28px;
    cursor: pointer;
    display: none; }
    .calculator-single__btns-share img {
      max-width: 22px;
      margin-right: 12px; }
  .calculator-single__bottom-wrapper {
    max-height: 0px;
    overflow: hidden;
    transition: .3s all; }
    .calculator-single__bottom-wrapper.show {
      max-height: 1000px;
      overflow: initial; }
  .calculator-single__bottom-bar {
    height: 48px;
    display: flex;
    border-bottom: 3px solid #1A1B1C;
    margin-bottom: 35px;
    padding: 0 2px;
    position: relative; }
    @media (max-width: 550px) {
      .calculator-single__bottom-bar {
        display: none; } }
    .calculator-single__bottom-bar-dott {
      position: absolute;
      left: 0;
      top: -47px;
      z-index: 2;
      transition: 1s all; }
    .calculator-single__bottom-bar-blue {
      background: linear-gradient(135deg, #3f51b5 0%, #2196f3 100%);
      width: 52px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      border-top-left-radius: 10px;
      position: relative; }
      .calculator-single__bottom-bar-blue::after {
        content: '16';
        position: absolute;
        bottom: -35px;
        right: -9px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-blue-light {
      background: linear-gradient(to right, #2196f3 0%, #00bcd4 100%);
      width: 57px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      position: relative; }
      .calculator-single__bottom-bar-blue-light::after {
        content: '17';
        position: absolute;
        bottom: -35px;
        right: -9px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-blue-green {
      background: linear-gradient(to right, #00bcd4 0%, #4caf50 100%);
      width: 62px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      position: relative; }
      .calculator-single__bottom-bar-blue-green::after {
        content: '18.5';
        position: absolute;
        bottom: -35px;
        right: -16px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-green-yellow {
      background: linear-gradient(to right, #66bb6a 0%, #cddc39 100%);
      width: 203px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      position: relative; }
      .calculator-single__bottom-bar-green-yellow::after {
        content: '25';
        position: absolute;
        bottom: -35px;
        right: -9px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-yellow-orange {
      background: linear-gradient(to right, #cddc39 0%, #ffc107 100%);
      width: 170px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      position: relative; }
      .calculator-single__bottom-bar-yellow-orange::after {
        content: '30';
        position: absolute;
        bottom: -35px;
        right: -9px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-orange-light {
      background: linear-gradient(to right, #ffc107 0%, #ff9800 100%);
      width: 170px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      position: relative; }
      .calculator-single__bottom-bar-orange-light::after {
        content: '35';
        position: absolute;
        bottom: -35px;
        right: -9px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-orange-red {
      background: linear-gradient(to right, #ff9800 0%, #f44336 100%);
      width: 170px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      position: relative; }
      .calculator-single__bottom-bar-orange-red::after {
        content: '40';
        position: absolute;
        bottom: -35px;
        right: -9px;
        font-size: 17px;
        font-weight: 700; }
    .calculator-single__bottom-bar-red {
      background: linear-gradient(to right, #f44336 0%, #f31100 100%);
      width: 43px;
      height: 100%;
      border-right: 1px solid #FFFFFF;
      border-top-right-radius: 10px; }
  .calculator-single__bottom-fulltext {
    font-size: 19px;
    line-height: 33px; }
    .calculator-single__bottom-fulltext iframe[name="embedded-content"] {
      border: none;
      width: 100%; }
    .calculator-single__bottom-fulltext p > iframe[name="embedded-content"] {
      margin-bottom: 0; }
    .calculator-single__bottom-fulltext > h2:first-child {
      margin-top: 0; }
    .calculator-single__bottom-fulltext .bibliografia, .calculator-single__bottom-fulltext .bibliography {
      border-radius: 10px;
      margin-bottom: 30px; }
      .calculator-single__bottom-fulltext .bibliografia > p, .calculator-single__bottom-fulltext .bibliografia .bibliography__title, .calculator-single__bottom-fulltext .bibliography > p, .calculator-single__bottom-fulltext .bibliography .bibliography__title {
        font-size: 18px;
        line-height: 22px;
        font-weight: 700;
        padding: 18px 25px 12px 25px;
        background-color: #fff;
        border-radius: 20px; }
      .calculator-single__bottom-fulltext .bibliografia > ol, .calculator-single__bottom-fulltext .bibliografia > ul, .calculator-single__bottom-fulltext .bibliography > ol, .calculator-single__bottom-fulltext .bibliography > ul {
        counter-reset: myOrderedListItemsCounter; }
        .calculator-single__bottom-fulltext .bibliografia > ol > li, .calculator-single__bottom-fulltext .bibliografia > ul > li, .calculator-single__bottom-fulltext .bibliography > ol > li, .calculator-single__bottom-fulltext .bibliography > ul > li {
          overflow-wrap: break-word;
          position: relative; }
          .calculator-single__bottom-fulltext .bibliografia > ol > li::before, .calculator-single__bottom-fulltext .bibliografia > ul > li::before, .calculator-single__bottom-fulltext .bibliography > ol > li::before, .calculator-single__bottom-fulltext .bibliography > ul > li::before {
            counter-increment: myOrderedListItemsCounter;
            content: counter(myOrderedListItemsCounter) ".";
            margin-right: 15px;
            position: absolute;
            top: 6px;
            left: 25px; }
          .calculator-single__bottom-fulltext .bibliografia > ol > li::marker, .calculator-single__bottom-fulltext .bibliografia > ul > li::marker, .calculator-single__bottom-fulltext .bibliography > ol > li::marker, .calculator-single__bottom-fulltext .bibliography > ul > li::marker {
            margin-left: 15px; }
      .calculator-single__bottom-fulltext .bibliografia > ol, .calculator-single__bottom-fulltext .bibliografia > ul, .calculator-single__bottom-fulltext .bibliography > ol, .calculator-single__bottom-fulltext .bibliography > ul {
        list-style: none;
        padding: 0;
        margin: 0; }
        .calculator-single__bottom-fulltext .bibliografia > ol li, .calculator-single__bottom-fulltext .bibliografia > ul li, .calculator-single__bottom-fulltext .bibliography > ol li, .calculator-single__bottom-fulltext .bibliography > ul li {
          padding: 6px 25px 6px 50px;
          font-size: 15px;
          line-height: 18px; }
        .calculator-single__bottom-fulltext .bibliografia > ol li:nth-child(odd), .calculator-single__bottom-fulltext .bibliografia > ul li:nth-child(odd), .calculator-single__bottom-fulltext .bibliography > ol li:nth-child(odd), .calculator-single__bottom-fulltext .bibliography > ul li:nth-child(odd) {
          background-color: rgba(40, 70, 102, 0.05); }
    .calculator-single__bottom-fulltext h2 {
      font-size: 33px;
      line-height: 47px;
      font-weight: 700;
      margin-bottom: 30px;
      margin-top: 65px; }
      .calculator-single__bottom-fulltext h2.big {
        font-size: 33px;
        line-height: 47px;
        font-weight: 700;
        margin-bottom: 30px;
        margin-top: 65px; }
      .calculator-single__bottom-fulltext h2.small {
        font-size: 25px;
        line-height: 31px;
        font-weight: 700;
        margin: 50px 0; }
    .calculator-single__bottom-fulltext .frac {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      font-size: 45px;
      line-height: 55px;
      margin-top: 62px;
      margin-bottom: 58px;
      font-weight: 700; }
      @media (max-width: 575px) {
        .calculator-single__bottom-fulltext .frac {
          font-size: 25px;
          line-height: 35px; } }
      .calculator-single__bottom-fulltext .frac__left {
        margin-right: 10px; }
      .calculator-single__bottom-fulltext .frac__right .top {
        padding-bottom: 4px;
        border-bottom: 3px solid black; }
      .calculator-single__bottom-fulltext .frac__right .bottom {
        display: block; }
    .calculator-single__bottom-fulltext .pattern {
      box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
      border-radius: 10px;
      padding: 39px 71px 50px 71px;
      font-size: 15px;
      line-height: 25px;
      text-align: center;
      max-width: 670px;
      margin: 0 auto 30px auto;
      background-color: #fff; }
      @media (max-width: 575px) {
        .calculator-single__bottom-fulltext .pattern {
          padding: 15px; } }
      .calculator-single__bottom-fulltext .pattern img {
        margin-right: 14px; }
        @media (max-width: 500px) {
          .calculator-single__bottom-fulltext .pattern img {
            display: none; } }
      .calculator-single__bottom-fulltext .pattern__title {
        font-size: 23px;
        line-height: 45px;
        font-weight: 700;
        color: #FB3636;
        text-transform: uppercase; }
      .calculator-single__bottom-fulltext .pattern__description {
        max-width: 380px;
        margin: 0 auto 22px auto; }
      .calculator-single__bottom-fulltext .pattern__value {
        font-size: 17px;
        line-height: 33px;
        font-weight: 700;
        margin-bottom: 20px; }
        @media (max-width: 500px) {
          .calculator-single__bottom-fulltext .pattern__value {
            font-size: 15px;
            line-height: 25px; } }
      .calculator-single__bottom-fulltext .pattern__alert {
        display: flex;
        border: 1px solid #DDDDDD;
        padding: 26px 16px 16px 16px;
        border-radius: 20px;
        max-width: 540px;
        margin: 0 auto 33px auto;
        text-align: left; }
  .calculator-single__bottom-result {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 53px; }
    @media (max-width: 540px) {
      .calculator-single__bottom-result {
        flex-wrap: wrap; }
        .calculator-single__bottom-result-middle {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 30px; }
        .calculator-single__bottom-result-right {
          max-width: 100%;
          flex: 0 0 100%; } }
    .calculator-single__bottom-result.show {
      max-height: 400px; }
    .calculator-single__bottom-result-score {
      margin-right: 16px;
      text-align: center;
      position: relative; }
      .calculator-single__bottom-result-score-title {
        font-size: 23px;
        font-weight: 700; }
      .calculator-single__bottom-result-score-number {
        font-size: 50px;
        font-weight: 700; }
      .calculator-single__bottom-result-score-type {
        font-size: 23px;
        line-height: 23px;
        font-weight: 700;
        padding: 13px 17px;
        background-color: #BAD641;
        border: 3px solid #1A1B1C;
        border-radius: 15px; }
    .calculator-single__bottom-result-list {
      list-style: none;
      padding: 0;
      margin: 0;
      font-size: 13px;
      line-height: 17px; }
      .calculator-single__bottom-result-list-el {
        margin-bottom: 5px; }
        .calculator-single__bottom-result-list-el.bold {
          font-weight: 700; }
      .calculator-single__bottom-result-list.hide {
        display: none; }
      .calculator-single__bottom-result-list.active {
        font-weight: 700; }
  .calculator-single__grey {
    background-color: #DDDDDD;
    padding: 36px 23px;
    margin-bottom: 62px;
    border-radius: 10px; }
    @media (max-width: 575px) {
      .calculator-single__grey {
        padding: 15px; } }
    .calculator-single__grey-list {
      padding: 0;
      margin: 0;
      margin-left: 39px;
      list-style: none; }
      @media (max-width: 575px) {
        .calculator-single__grey-list {
          margin-left: 0; } }
      .calculator-single__grey-list .bold {
        font-weight: 700; }
      .calculator-single__grey-list-el {
        position: relative;
        padding-left: 25px; }
        .calculator-single__grey-list-el::after {
          content: '';
          position: absolute;
          left: 0;
          top: 10px;
          background-color: #F16655;
          border-radius: 50%;
          width: 10px;
          height: 10px; }
  .calculator-single.bmi .calculator-single__count {
    flex-wrap: wrap; }
    @media (max-width: 768px) {
      .calculator-single.bmi .calculator-single__count-growth {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 20px; }
        .calculator-single.bmi .calculator-single__count-growth-bottom {
          border: 1px solid rgba(255, 255, 255, 0.15);
          margin-left: 0; } }
    @media (max-width: 550px) {
      .calculator-single.bmi .calculator-single__count-growth {
        max-width: 100%;
        flex: 0 0 100%; } }
    @media (max-width: 768px) {
      .calculator-single.bmi .calculator-single__count-weight {
        max-width: 50%;
        flex: 0 0 50%;
        margin-bottom: 20px; }
        .calculator-single.bmi .calculator-single__count-weight-bottom {
          border: 1px solid rgba(255, 255, 255, 0.15);
          margin-left: 0; } }
    @media (max-width: 550px) {
      .calculator-single.bmi .calculator-single__count-weight {
        max-width: 100%;
        flex: 0 0 100%; } }
    @media (max-width: 768px) {
      .calculator-single.bmi .calculator-single__count-score {
        max-width: 100%;
        flex: 0 0 100%; }
        .calculator-single.bmi .calculator-single__count-score-title {
          margin-bottom: 0; }
        .calculator-single.bmi .calculator-single__count-score-bottom {
          border: 1px solid rgba(255, 255, 255, 0.15);
          margin-left: 0;
          padding: 15px; }
          .calculator-single.bmi .calculator-single__count-score-bottom input {
            margin: 0 auto; } }
  .calculator-single.nmc .calculator-single__count-weight-bottom {
    display: flex; }
  .calculator-single.nmc .calculator-single__count-score {
    max-width: 390px;
    width: 100%; }
    .calculator-single.nmc .calculator-single__count-score-title {
      margin: 0; }
    .calculator-single.nmc .calculator-single__count-score-bottom {
      justify-content: space-between;
      padding: 0; }
      .calculator-single.nmc .calculator-single__count-score-bottom-results {
        width: 100%;
        height: 86px; }
        .calculator-single.nmc .calculator-single__count-score-bottom-results-broc {
          line-height: 25px;
          padding-bottom: 14px;
          padding: 0;
          margin: 0;
          max-width: 33.333%;
          flex: 0 0 33.333%;
          padding-top: 24px; }
        .calculator-single.nmc .calculator-single__count-score-bottom-results-lorentz {
          line-height: 25px;
          border-right: 1px solid rgba(255, 255, 255, 0.15);
          white-space: nowrap;
          padding: 0;
          margin: 0;
          max-width: 33.333%;
          flex: 0 0 33.333%;
          padding-top: 24px; }
        .calculator-single.nmc .calculator-single__count-score-bottom-results-patton {
          line-height: 25px;
          border-right: 1px solid rgba(255, 255, 255, 0.15);
          white-space: nowrap;
          padding: 0;
          margin: 0;
          max-width: 33.333%;
          flex: 0 0 33.333%;
          padding-top: 24px; }
        .calculator-single.nmc .calculator-single__count-score-bottom-results-bottom {
          font-size: 12px;
          opacity: .5; }
      .calculator-single.nmc .calculator-single__count-score-bottom input {
        margin: 20px auto 0 auto; }
  @media (max-width: 850px) {
    .calculator-single.nmc .calculator-single__count {
      flex-wrap: wrap; }
      .calculator-single.nmc .calculator-single__count-growth {
        max-width: 50%;
        flex: 0 0 50%; }
        .calculator-single.nmc .calculator-single__count-growth-bottom {
          display: flex;
          justify-content: center; }
      .calculator-single.nmc .calculator-single__count-weight {
        max-width: 50%;
        flex: 0 0 50%; }
        .calculator-single.nmc .calculator-single__count-weight-bottom {
          display: flex;
          justify-content: center;
          border: none; }
      .calculator-single.nmc .calculator-single__count-score {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 20px; } }
  @media (max-width: 550px) {
    .calculator-single.nmc .calculator-single__count-growth {
      max-width: 100%;
      flex: 0 0 100%; }
      .calculator-single.nmc .calculator-single__count-growth-title {
        border: none;
        text-align: left; }
      .calculator-single.nmc .calculator-single__count-growth-bottom {
        border: none;
        margin: 0;
        justify-content: flex-start; }
    .calculator-single.nmc .calculator-single__count-weight {
      max-width: 100%;
      flex: 0 0 100%; }
      .calculator-single.nmc .calculator-single__count-weight-title {
        border: none;
        text-align: left; }
      .calculator-single.nmc .calculator-single__count-weight-bottom {
        margin: 0;
        justify-content: flex-start; }
    .calculator-single.nmc .calculator-single__count-score-title {
      border: none;
      text-align: left; }
    .calculator-single.nmc .calculator-single__count-score-bottom {
      margin: 0;
      justify-content: flex-start; }
      .calculator-single.nmc .calculator-single__count-score-bottom input {
        margin: 20px 0 0 0; } }
  .calculator-single__important {
    border-radius: 10px;
    box-shadow: 0 50px 99px rgba(0, 0, 0, 0.16);
    padding: 25px;
    margin-top: -82px;
    background-color: #fff; }
    .calculator-single__important-top {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: 700;
      color: #1A1B1C;
      margin-bottom: 18px; }
      .calculator-single__important-top img {
        margin-right: 15px; }
    .calculator-single__important-bottom {
      font-size: 14px;
      line-height: 22px; }

.bibliography {
  border-radius: 10px;
  margin-bottom: 50px;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  background-color: var(--colorBg); }
  .bibliography__title {
    font-size: 22px;
    line-height: 26px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0 28px;
    min-height: 90px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    color: var(--colorFont);
    position: relative;
    cursor: pointer; }
    .bibliography__title:before {
      content: "";
      width: 22px;
      height: 17px;
      display: block;
      background-image: url("/templates/main/images/svg/book-open.svg");
      background-position: center;
      background-repeat: no-repeat;
      margin-right: 32px; }
    .bibliography__title:after {
      content: "";
      width: 21px;
      height: 13px;
      background-image: url("/templates/main/images/svg/chevron-down-red.svg");
      background-position: center;
      background-repeat: no-repeat;
      margin-left: auto;
      margin-right: 0; }
  .bibliography__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 18px;
    display: none; }
    .bibliography__list li:nth-child(odd) {
      background-color: rgba(40, 70, 102, 0.05); }
    .bibliography__list-el {
      padding: 8px 15px 8px 25px; }

.newsletter-main {
  padding: 26px 49px 169px 49px;
  background: #002B44;
  color: rgba(255, 255, 255, 0.5); }
  @media (max-width: 575px) {
    .newsletter-main {
      padding: 26px 0 169px 0; } }
  .newsletter-main .newsletter-title {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px; }
    @media (max-width: 900px) {
      .newsletter-main .newsletter-title {
        justify-content: space-between; } }
    @media (max-width: 600px) {
      .newsletter-main .newsletter-title {
        flex-wrap: wrap;
        justify-content: center; } }
  .newsletter-main .h1-article {
    margin: 0;
    font-size: 40px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
    color: #fff; }
    @media (max-width: 900px) {
      .newsletter-main .h1-article {
        text-align: left; } }
    @media (max-width: 600px) {
      .newsletter-main .h1-article {
        max-width: 100%;
        flex: 0 0 100%;
        text-align: center; } }
  .newsletter-main .newsletter-example {
    text-align: right; }
    @media (min-width: 900px) {
      .newsletter-main .newsletter-example {
        position: absolute;
        right: 0; } }
    .newsletter-main .newsletter-example a {
      color: #FCA800;
      font-size: 16px;
      text-decoration: underline; }
      .newsletter-main .newsletter-example a:hover {
        text-decoration: none; }
  .newsletter-main .newsletter-description {
    margin: 20px 0 0;
    font-size: 20px;
    line-height: 30px; }
    .newsletter-main .newsletter-description p {
      margin: 0; }
  .newsletter-main .category__title {
    margin: 40px 0 30px;
    font-size: 26px;
    line-height: 35px; }
    .newsletter-main .category__title p {
      margin: 0; }
  .newsletter-main .newsletter-gratis {
    font-size: 21px;
    line-height: 35px;
    text-align: center;
    margin-bottom: 15px; }
    .newsletter-main .newsletter-gratis p {
      margin: 0; }
  .newsletter-main input.news-input {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 576px;
    box-shadow: 0 0 99px 0 rgba(0, 0, 0, 0.16);
    border: none;
    outline: none;
    color: #082134;
    text-align: center;
    font-family: "Objectivity", sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: bold;
    color: #fff;
    padding: 25px;
    margin-bottom: 33px;
    background-color: #335669;
    border: 3px solid #3FB693;
    border-radius: 28px; }
    .newsletter-main input.news-input:focus::placeholder, .newsletter-main input.news-input:active::placeholder {
      opacity: 0; }
  .newsletter-main .newspaper-image {
    position: relative;
    display: flex;
    justify-content: flex-end; }
    @media (max-width: 768px) {
      .newsletter-main .newspaper-image {
        justify-content: center;
        margin-bottom: 30px; } }
    .newsletter-main .newspaper-image img {
      display: block;
      margin-right: 25px;
      box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.16); }
  .newsletter-main .select-box {
    position: relative;
    margin: 20px 0 0; }
    .newsletter-main .select-box select {
      width: 100%;
      height: 35px;
      padding: 0 9px 0 22px;
      font-size: 17px;
      line-height: 35px;
      box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
      border: none;
      color: #000;
      font-weight: 600;
      font-family: "Objectivity", sans-serif;
      appearance: none; }
    .newsletter-main .select-box .fa {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-size: 16px;
      color: red; }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .newsletter-main .select-box .fa {
          font-size: 24px; } }
    .newsletter-main .select-box img {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translate(-50%, -50%);
      width: 15px;
      z-index: 2;
      transition: .2s transform; }
    .newsletter-main .select-box .rotate-newsletter {
      transform: translate(-50%, -50%) rotate(180deg);
      transition: .2s transform; }
  .newsletter-main .avt-info {
    margin-left: 55px;
    font-size: 11px;
    line-height: 17px;
    margin-bottom: 20px; }
    .newsletter-main .avt-info #button-text {
      font-weight: 700;
      cursor: pointer;
      text-transform: uppercase; }
    .newsletter-main .avt-info #full-text {
      display: none; }
  .newsletter-main .gift {
    margin-bottom: 35px;
    position: relative; }
  .newsletter-main #gift-check {
    position: absolute;
    left: -9999px; }
  .newsletter-main #gift-check:checked + #gift-label:after {
    opacity: 1; }
  .newsletter-main #gift-label {
    display: block;
    margin: 0 0 0 55px;
    font-size: 11px;
    line-height: 17px; }
  .newsletter-main #gift-label::before {
    content: '';
    width: 36px;
    height: 36px;
    position: absolute;
    top: 6px;
    left: 0;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.16);
    background: transparent;
    border: 2px solid #DDDDDD;
    border-radius: 8px; }
  .newsletter-main #gift-label:after {
    content: "";
    position: absolute;
    top: 6px;
    left: 0;
    background-image: url("/templates/main/images/svg/check-teal.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    opacity: 0;
    width: 36px;
    height: 36px;
    transition: opacity .2s ease-in-out; }
  .newsletter-main #additional-info {
    font-size: 11px;
    opacity: 0.5;
    margin-left: 55px; }
  .newsletter-main .btn-newsletter {
    width: 100%;
    max-width: 576px;
    height: 85px;
    margin: 41px auto 53px auto;
    background: linear-gradient(135deg, #35b7c1 0%, #3fb693 100%);
    font-size: 17px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #fff;
    line-height: 21px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    box-shadow: 0 25px 99px rgba(0, 0, 0, 0.5);
    border-radius: 28px; }
    .newsletter-main .btn-newsletter:focus {
      border: none;
      outline: none; }
    .newsletter-main .btn-newsletter .fa {
      font-size: 11px;
      margin-right: 10px;
      color: red;
      vertical-align: 2px; }
    .newsletter-main .btn-newsletter:hover .fa {
      animation: arrow-sliding 1s infinite ease; }
  .newsletter-main .unsubscribe {
    max-width: 475px; }
    .newsletter-main .unsubscribe .unsubscribe-text {
      font-size: 15px; }
    .newsletter-main .unsubscribe .unsubscribe-form {
      box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.16);
      display: flex;
      margin: 16px 0 0; }
      .newsletter-main .unsubscribe .unsubscribe-form .submit {
        flex: 0 0 138px;
        max-width: 138px;
        height: 40px;
        background: linear-gradient(135deg, #35b7c1 0%, #3fb693 100%);
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
        border: none;
        padding: 0;
        text-transform: uppercase;
        color: #fff; }
      .newsletter-main .unsubscribe .unsubscribe-form .mail {
        flex: 1 1 auto;
        height: 40px;
        width: 1%;
        background: #335669;
        border: none;
        box-shadow: none;
        outline: none;
        padding: 0 10px;
        color: #fff;
        border: 1px solid #3FB693; }
        .newsletter-main .unsubscribe .unsubscribe-form .mail:focus::placeholder, .newsletter-main .unsubscribe .unsubscribe-form .mail:active::placeholder {
          opacity: 0; }
        @media (min-width: 768px) {
          .newsletter-main .unsubscribe .unsubscribe-form .mail {
            padding: 0 22px; } }
  .newsletter-main .open {
    display: inline !important; }
  .newsletter-main input[type="text"],
  .newsletter-main input[type="email"] {
    transition: box-shadow 0.35s ease 0s; }
    .newsletter-main input[type="text"]:focus,
    .newsletter-main input[type="email"]:focus {
      box-shadow: 0 0 55px 0 rgba(0, 0, 0, 0.16), inset 0 0 0 1px #A8A8A8; }

.newsletter-gift .gift-text {
  text-align: left; }
  @media (max-width: 767.98px) {
    .newsletter-gift .gift-text {
      padding-top: 10px; } }

.newsletter-gift .gift-link-row {
  text-align: left; }
  @media (max-width: 767.98px) {
    .newsletter-gift .gift-link-row {
      text-align: center; } }
  .newsletter-gift .gift-link-row .gift-btn {
    padding: 5px 20px;
    background: #92cf35;
    color: #fff;
    display: inline-block;
    margin-top: 30px;
    font-weight: bold; }

@keyframes arrow-sliding {
  50% {
    transform: translateX(-8px); }
  100% {
    transform: translateX(0); } }

.newsletter-main.newsletter-main--new {
  padding: 26px 0 169px; }
  @media (min-width: 1025px) {
    .newsletter-main.newsletter-main--new {
      padding: 26px 49px 169px 49px; } }
  .newsletter-main.newsletter-main--new .star {
    display: none; }
  .newsletter-main.newsletter-main--new .avt-info {
    margin: 0;
    padding: 0; }
  .newsletter-main.newsletter-main--new .newsletter-title {
    margin-bottom: 164px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    .newsletter-main.newsletter-main--new .newsletter-title .h1-article {
      text-transform: uppercase;
      font-size: 35px;
      letter-spacing: 0.05em;
      width: 100%;
      margin-bottom: 16px;
      text-align: center; }
      @media (min-width: 1025px) {
        .newsletter-main.newsletter-main--new .newsletter-title .h1-article {
          text-align: left;
          width: auto;
          margin: 0 35px; } }
    .newsletter-main.newsletter-main--new .newsletter-title .newsletter-title__subtitle {
      font-size: 24px;
      font-weight: 400;
      color: #fff;
      width: 100%;
      text-align: center; }
      @media (min-width: 1025px) {
        .newsletter-main.newsletter-main--new .newsletter-title .newsletter-title__subtitle {
          text-align: left;
          width: auto;
          margin: 0 35px; } }
  .newsletter-main.newsletter-main--new #js-nform {
    position: relative;
    max-width: 960px;
    padding: 0 15px;
    margin: 0 auto 35px; }
    .newsletter-main.newsletter-main--new #js-nform:after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      border: 2px solid rgba(255, 255, 255, 0.2);
      border-radius: 20px;
      pointer-events: none;
      left: -10px;
      right: -10px; }
      @media (min-width: 1025px) {
        .newsletter-main.newsletter-main--new #js-nform:after {
          left: -50px;
          right: -50px; } }
  .newsletter-main.newsletter-main--new .newsletter-main__message {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    opacity: 1; }
  .newsletter-main.newsletter-main--new .newsletter-main__banner {
    display: flex;
    flex-wrap: wrap;
    background-image: url("/templates/main/images/static/newsletter.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    border-radius: 20px;
    position: relative;
    z-index: 3;
    align-items: center;
    font-weight: 400;
    color: #1A1B1C;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    justify-content: center;
    padding: 30px 15px 20px;
    top: -143px; }
    @media (min-width: 1025px) {
      .newsletter-main.newsletter-main--new .newsletter-main__banner {
        top: -124px;
        padding: 0 55px;
        height: 250px;
        justify-content: space-between;
        font-size: 24px;
        text-align: left;
        line-height: 35px; } }
    .newsletter-main.newsletter-main--new .newsletter-main__banner-text {
      width: 100%; }
      @media (min-width: 1025px) {
        .newsletter-main.newsletter-main--new .newsletter-main__banner-text {
          width: auto; } }
    .newsletter-main.newsletter-main--new .newsletter-main__banner-images {
      max-width: 230px; }
      @media (min-width: 1025px) {
        .newsletter-main.newsletter-main--new .newsletter-main__banner-images {
          max-width: 100%; } }
      .newsletter-main.newsletter-main--new .newsletter-main__banner-images img {
        display: block;
        max-width: 100%;
        height: auto; }
  .newsletter-main.newsletter-main--new .newsletter-main__wrap {
    max-width: 870px;
    margin: -90px auto 50px; }
  .newsletter-main.newsletter-main--new .newsletter-main__bottom {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: -42px;
    bottom: -42px;
    margin-bottom: 78px;
    z-index: 3; }
  .newsletter-main.newsletter-main--new .additional-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .newsletter-main.newsletter-main--new .additional-form__item {
      margin-bottom: 15px;
      position: relative; }
      @media (min-width: 768px) {
        .newsletter-main.newsletter-main--new .additional-form__item {
          width: calc(50% - 24px); } }
      .newsletter-main.newsletter-main--new .additional-form__item-counter {
        width: 50px;
        height: 50px;
        border-radius: 11px;
        background-color: rgba(255, 255, 255, 0.2);
        color: #002A43;
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        font-size: 28px;
        padding-top: 6px; }
        @media (min-width: 576px) {
          .newsletter-main.newsletter-main--new .additional-form__item-counter {
            position: absolute;
            top: 0;
            left: 0; } }
      .newsletter-main.newsletter-main--new .additional-form__item-title {
        font-size: 16px;
        font-weight: 700;
        line-height: 22px;
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        margin-bottom: 16px; }
        @media (min-width: 576px) {
          .newsletter-main.newsletter-main--new .additional-form__item-title {
            margin-bottom: 0;
            align-items: center;
            align-content: center; } }
      .newsletter-main.newsletter-main--new .additional-form__item-text {
        max-width: calc(100% - 50px);
        padding-left: 15px; }
        @media (min-width: 576px) {
          .newsletter-main.newsletter-main--new .additional-form__item-text {
            padding-left: 25px;
            max-width: none;
            padding-left: 75px; } }
      .newsletter-main.newsletter-main--new .additional-form__item-option {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
        position: relative;
        margin-bottom: 8px;
        color: #fff;
        padding-left: 25px;
        cursor: pointer; }
        @media (min-width: 576px) {
          .newsletter-main.newsletter-main--new .additional-form__item-option {
            padding-left: 75px; } }
        .newsletter-main.newsletter-main--new .additional-form__item-option label {
          margin: 0;
          color: #fff;
          max-width: calc(100% - 25px);
          padding-left: 15px;
          margin-top: auto;
          font-size: 15px;
          cursor: pointer; }
        .newsletter-main.newsletter-main--new .additional-form__item-option input {
          top: 0;
          left: 75px;
          width: 25px;
          height: 25px;
          opacity: 0;
          position: absolute;
          cursor: pointer; }
        .newsletter-main.newsletter-main--new .additional-form__item-option input:checked ~ .cs-checkbox:after {
          opacity: 1; }
        .newsletter-main.newsletter-main--new .additional-form__item-option .cs-checkbox {
          width: 25px;
          height: 25px;
          pointer-events: none;
          display: inline-flex;
          border-radius: 3px;
          border: 1px solid #c4c4c4;
          position: relative;
          cursor: pointer; }
          .newsletter-main.newsletter-main--new .additional-form__item-option .cs-checkbox:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-image: url("/templates/main/images/svg/check-teal.svg");
            background-repeat: no-repeat;
            background-position: center;
            background-size: 60%;
            opacity: 0;
            transition: opacity .2s ease-in-out; }
  .newsletter-main.newsletter-main--new .avt-info {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 24px;
    padding-left: 55px; }
    .newsletter-main.newsletter-main--new .avt-info #gift-label {
      margin: 0;
      padding: 0;
      display: inline; }
      .newsletter-main.newsletter-main--new .avt-info #gift-label:before {
        top: 0; }
    .newsletter-main.newsletter-main--new .avt-info #dots {
      display: none !important; }
    .newsletter-main.newsletter-main--new .avt-info .intro-text {
      display: inline; }
  .newsletter-main.newsletter-main--new #gift-label:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("/templates/main/images/svg/check-teal.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    opacity: 0;
    width: 36px;
    height: 36px;
    transition: opacity .2s ease-in-out; }
  .newsletter-main.newsletter-main--new .form-description {
    font-size: 11px;
    line-height: 17px;
    font-weight: 400;
    max-width: 930px;
    margin: 0 auto 45px; }
  .newsletter-main.newsletter-main--new .news-input {
    margin: 0 0 24px;
    max-width: none;
    border: none;
    border-radius: 18px;
    width: 100%; }
    @media (min-width: 768px) {
      .newsletter-main.newsletter-main--new .news-input {
        margin-right: 15px;
        width: calc(100% - 345px); } }
    .newsletter-main.newsletter-main--new .news-input::placeholder {
      font-weight: 400;
      opacity: 1;
      font-size: 20px; }
  .newsletter-main.newsletter-main--new .btn-newsletter {
    margin: 0;
    border-radius: 18px;
    width: 100%;
    max-width: none; }
    @media (min-width: 768px) {
      .newsletter-main.newsletter-main--new .btn-newsletter {
        max-width: 330px; } }
  .newsletter-main.newsletter-main--new #unsubscribe {
    max-width: 930px;
    margin: 0 auto; }
  .newsletter-main.newsletter-main--new #gift-check {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
    visibility: hidden; }

.download-paper {
  margin-bottom: 20px; }
  .download-paper img {
    display: block;
    max-width: 100%;
    height: auto;
    margin: 0 auto; }
    @media (min-width: 768px) {
      .download-paper img {
        margin: 0; } }

.form-com_avtnewsletter {
  scroll-behavior: smooth;
  overflow: hidden;
  height: 100vh;
  background: #002b44; }
  .form-com_avtnewsletter .gift__check {
    position: relative; }
  .form-com_avtnewsletter #system-message .gift-btn {
    color: #fff; }
  .form-com_avtnewsletter .gift__info {
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 20px;
    color: #fff; }
  .form-com_avtnewsletter .simplebar-content-wrapper {
    overflow: hidden scroll !important;
    scroll-behavior: smooth; }
  @media (min-width: 1025px) {
    .form-com_avtnewsletter.burger-open {
      padding-right: 17px; } }
  .form-com_avtnewsletter.burger-open .simplebar-content-wrapper {
    overflow: hidden !important; }
  .form-com_avtnewsletter.burger-open .body-wrap > .simplebar-track.simplebar-vertical, .form-com_avtnewsletter.burger-open .body-wrap > .simplebar-track.simplebar-vertical:hover, .form-com_avtnewsletter.burger-open .body-wrap > .simplebar-track.simplebar-vertical:focus .form-com_avtnewsletter.burger-open .body-wrap > .simplebar-track.simplebar-vertical:active {
    opacity: 0; }
  .form-com_avtnewsletter .newsletter-main,
  .form-com_avtnewsletter .top-menu--new,
  .form-com_avtnewsletter .header {
    background-color: transparent; }
  .form-com_avtnewsletter .body-wrap {
    height: 100%; }
  .form-com_avtnewsletter .newsletter-main:after {
    content: "";
    position: absolute;
    top: -168px;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(45deg, #284666 0%, #002b44 100%);
    background: #002b44; }
    @media (min-width: 1025px) and (max-width: 1366px) {
      .form-com_avtnewsletter .newsletter-main:after {
        top: -184px; } }
  .form-com_avtnewsletter .top-menu:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 200px;
    background: linear-gradient(0deg, rgba(0, 43, 68, 0) 0%, rgba(0, 43, 68, 0.52) 20%, rgba(0, 43, 68, 0.7) 38%, #002b44 66%, #002b44 100%);
    /* w3c */
    z-index: 0;
    pointer-events: none; }
  .form-com_avtnewsletter .newsletter {
    padding: 50px 0 90px;
    position: relative; }
    .form-com_avtnewsletter .newsletter__background {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: -1;
      pointer-events: none; }
    .form-com_avtnewsletter .newsletter__container {
      z-index: 3; }
      .form-com_avtnewsletter .newsletter__container .main-bs-bottom {
        transition: opacity .35s ease-in-out; }
        @media (max-width: 1024.98px) {
          .form-com_avtnewsletter .newsletter__container .main-bs-bottom {
            max-width: 610px;
            margin: 0 auto; } }
      .form-com_avtnewsletter .newsletter__container.gifts-open {
        z-index: 12;
        opacity: 0;
        pointer-events: none; }
        .form-com_avtnewsletter .newsletter__container.gifts-open .main-bs-bottom {
          opacity: 0;
          transform-origin: 50% 50%;
          max-width: 50%;
          margin-left: 50%;
          padding-right: 15px; }
          .form-com_avtnewsletter .newsletter__container.gifts-open .main-bs-bottom .newsletter__submit-form {
            height: 75vh;
            margin: 0 auto; }
        .form-com_avtnewsletter .newsletter__container.gifts-open #unsubscribe,
        .form-com_avtnewsletter .newsletter__container.gifts-open .avt-info,
        .form-com_avtnewsletter .newsletter__container.gifts-open .btn-gift.btn-newsletter,
        .form-com_avtnewsletter .newsletter__container.gifts-open .newsletter-gratis,
        .form-com_avtnewsletter .newsletter__container.gifts-open .newsletter-title {
          display: none; }
        .form-com_avtnewsletter .newsletter__container.gifts-open .gift {
          margin-bottom: 30px;
          display: block; }
      .form-com_avtnewsletter .newsletter__container.gift-selected {
        position: relative;
        max-width: 1200px;
        padding: 33px 0 0; }
        .form-com_avtnewsletter .newsletter__container.gift-selected .main-bs-bottom {
          pointer-events: all;
          opacity: 1; }
        .form-com_avtnewsletter .newsletter__container.gift-selected ~ .our-magazines {
          display: none; }
      .form-com_avtnewsletter .newsletter__container.gifts-open.gift-selected {
        opacity: 1; }
    .form-com_avtnewsletter .newsletter__gifts {
      margin: 0;
      opacity: 0;
      transition: opacity .2s ease-in-out;
      top: -195px;
      bottom: -203px;
      position: absolute;
      width: 100%;
      display: none;
      height: calc(100% + 250px);
      pointer-events: none; }
      @media (min-width: 1025px) {
        .form-com_avtnewsletter .newsletter__gifts {
          display: block; } }
      @media (min-width: 1025px) and (max-width: 1366px) {
        .form-com_avtnewsletter .newsletter__gifts {
          top: -184px;
          height: calc(100% + 184px); } }
      .form-com_avtnewsletter .newsletter__gifts--left {
        left: 0;
        right: auto; }
        .form-com_avtnewsletter .newsletter__gifts--left .swiper-slide {
          padding: 0 15px 0 35px; }
      .form-com_avtnewsletter .newsletter__gifts--right {
        right: 0;
        left: auto; }
        .form-com_avtnewsletter .newsletter__gifts--right .swiper-wrapper {
          align-items: flex-end; }
        .form-com_avtnewsletter .newsletter__gifts--right .swiper-slide {
          padding: 0 35px 0 15px; }
      .form-com_avtnewsletter .newsletter__gifts-close {
        position: absolute;
        width: 194px;
        height: 64px;
        top: 120px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding-right: 3px;
        cursor: pointer;
        z-index: 999;
        right: 0; }
        @media (min-width: 1200px) {
          .form-com_avtnewsletter .newsletter__gifts-close {
            right: calc((100% - 1200px) / 2); } }
      .form-com_avtnewsletter .newsletter__gifts .swiper-slide {
        height: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        padding: 0 15px;
        max-width: calc(100% - 610px); }
        .form-com_avtnewsletter .newsletter__gifts .swiper-slide img {
          box-shadow: 0 30px 99px black;
          max-width: 100%;
          height: auto; }
      .form-com_avtnewsletter .newsletter__gifts .swiper-wrapper {
        transition-timing-function: linear; }
      .form-com_avtnewsletter .newsletter__gifts.loaded {
        opacity: 1; }
    .form-com_avtnewsletter .newsletter__scroll-wrap {
      height: 100%;
      overflow-y: auto;
      scroll-behavior: smooth;
      padding-top: 225px; }
      .form-com_avtnewsletter .newsletter__scroll-wrap:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 225px;
        background: linear-gradient(0deg, rgba(0, 43, 68, 0) 0%, rgba(0, 43, 68, 0.52) 20%, rgba(0, 43, 68, 0.7) 38%, #002b44 66%, #002b44 100%);
        /* w3c */
        z-index: 99; }
    .form-com_avtnewsletter .newsletter__gift {
      transform-origin: 50% 50%;
      opacity: 0.33; }
      .form-com_avtnewsletter .newsletter__gift-text {
        transition: transform .3s ease-in-out; }
      .form-com_avtnewsletter .newsletter__gift-wrap {
        display: inline-block; }
      .form-com_avtnewsletter .newsletter__gift-papers {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: linear-gradient(45deg, #284666 0%, #002b44 100%);
        background: #002b44;
        z-index: 9;
        padding-top: 0;
        opacity: 0;
        transition: opacity .2s ease-in-out;
        display: block; }
        .form-com_avtnewsletter .newsletter__gift-papers, .form-com_avtnewsletter .newsletter__gift-papers * {
          pointer-events: none; }
        .form-com_avtnewsletter .newsletter__gift-papers.active {
          opacity: 1; }
          .form-com_avtnewsletter .newsletter__gift-papers.active, .form-com_avtnewsletter .newsletter__gift-papers.active * {
            pointer-events: all; }
          .form-com_avtnewsletter .newsletter__gift-papers.active .newsletter__gift-paper * {
            pointer-events: none; }
        .form-com_avtnewsletter .newsletter__gift-papers .simplebar-content-wrapper {
          overflow: auto !important;
          scroll-behavior: smooth; }
        .form-com_avtnewsletter .newsletter__gift-papers.hide-other .newsletter__gift-paper {
          opacity: 0; }
        .form-com_avtnewsletter .newsletter__gift-papers.select-active {
          position: absolute; }
          .form-com_avtnewsletter .newsletter__gift-papers.select-active .newsletter__scroll-wrap {
            overflow: hidden; }
            .form-com_avtnewsletter .newsletter__gift-papers.select-active .newsletter__scroll-wrap .simplebar-content-wrapper {
              overflow: hidden !important; }
          .form-com_avtnewsletter .newsletter__gift-papers.select-active .newsletter__gift-paper {
            opacity: 0;
            pointer-events: none; }
          .form-com_avtnewsletter .newsletter__gift-papers.select-active .selected {
            max-width: none;
            max-height: none;
            overflow: visible;
            pointer-events: all;
            transform-origin: 0 0; }
            .form-com_avtnewsletter .newsletter__gift-papers.select-active .selected * {
              pointer-events: none; }
          .form-com_avtnewsletter .newsletter__gift-papers.select-active .newsletter__scroll-wrap {
            overflow: hidden; }
        .form-com_avtnewsletter .newsletter__gift-papers.column-3 .newsletter__gift-wrap {
          max-width: 100%; }
          @media (min-width: 576px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-3 .newsletter__gift-wrap {
              max-width: 50%; } }
          @media (min-width: 1025px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-3 .newsletter__gift-wrap {
              max-width: calc(100% / 3); } }
          .form-com_avtnewsletter .newsletter__gift-papers.column-3 .newsletter__gift-wrap .newsletter__gift-price .old {
            margin-right: 20px; }
            .form-com_avtnewsletter .newsletter__gift-papers.column-3 .newsletter__gift-wrap .newsletter__gift-price .old:after {
              left: -5px;
              width: auto;
              right: -5px; }
        .form-com_avtnewsletter .newsletter__gift-papers.column-4 .newsletter__gift-wrap {
          max-width: 100%; }
          @media (min-width: 370px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-4 .newsletter__gift-wrap {
              max-width: 50%; } }
          @media (min-width: 768px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-4 .newsletter__gift-wrap {
              max-width: calc(100% / 3); } }
          @media (min-width: 1025px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-4 .newsletter__gift-wrap {
              max-width: 25%; } }
          .form-com_avtnewsletter .newsletter__gift-papers.column-4 .newsletter__gift-wrap .newsletter__gift-name {
            font-size: 15px; }
        .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap {
          max-width: 100%; }
          @media (min-width: 370px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap {
              max-width: 50%; } }
          @media (min-width: 576px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap {
              max-width: calc(100% / 3); } }
          @media (min-width: 768px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap {
              max-width: 25%; } }
          @media (min-width: 1025px) {
            .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap {
              max-width: 20%; } }
          .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap img {
            margin-bottom: 10px; }
          .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap .newsletter__gift-name {
            font-size: 13px;
            margin-bottom: 5px; }
          .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap .newsletter__gift-price {
            font-size: 13px; }
            .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap .newsletter__gift-price .old {
              margin-right: 25px; }
              .form-com_avtnewsletter .newsletter__gift-papers.column-5 .newsletter__gift-wrap .newsletter__gift-price .old:after {
                width: 70px; }
      .form-com_avtnewsletter .newsletter__gift-container {
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
        height: 100%;
        margin: 0 auto;
        font-size: 0; }
      .form-com_avtnewsletter .newsletter__gift-price {
        font-size: 15px;
        color: #fff;
        transition: all .3s ease-in-out; }
        .form-com_avtnewsletter .newsletter__gift-price .old {
          margin-right: 45px;
          color: rgba(255, 255, 255, 0.5);
          position: relative; }
          .form-com_avtnewsletter .newsletter__gift-price .old:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: -10px;
            right: 0;
            margin: auto;
            height: 1px;
            width: 100px;
            background-color: #FB3636;
            transition: all .3s ease-in-out; }
        .form-com_avtnewsletter .newsletter__gift-price .new {
          font-weight: 700; }
      .form-com_avtnewsletter .newsletter__gift-name {
        font-size: 17px;
        font-weight: 500;
        color: #fff;
        margin-bottom: 13px;
        transition: all .3s ease-in-out; }
      .form-com_avtnewsletter .newsletter__gift-paper {
        cursor: pointer;
        user-select: none;
        transition: all .3s ease-in-out;
        transform-origin: 0 0;
        margin-bottom: 30px;
        max-height: 1000px;
        display: inline-block;
        padding: 0 10%;
        outline: 1px solid transparent;
        backface-visibility: hidden; }
        .form-com_avtnewsletter .newsletter__gift-paper * {
          pointer-events: none; }
        .form-com_avtnewsletter .newsletter__gift-paper.selected {
          opacity: 1 !important;
          pointer-events: all; }
          .form-com_avtnewsletter .newsletter__gift-paper.selected:hover img,
          .form-com_avtnewsletter .newsletter__gift-paper.selected:hover .newsletter__gift-text {
            transform: none; }
        .form-com_avtnewsletter .newsletter__gift-paper img {
          box-shadow: 0 30px 50px rgba(0, 0, 0, 0.48);
          margin-bottom: 20px;
          max-width: 100%;
          height: auto;
          transition: all .3s ease-in-out;
          outline: 1px solid transparent;
          backface-visibility: hidden; }
        .form-com_avtnewsletter .newsletter__gift-paper-text {
          outline: 1px solid transparent;
          backface-visibility: hidden; }
        @media (min-width: 1025px) {
          .form-com_avtnewsletter .newsletter__gift-paper:hover img,
          .form-com_avtnewsletter .newsletter__gift-paper:hover .newsletter__gift-text {
            transform: translate3d(0, -10px, 0); } }
    .form-com_avtnewsletter .newsletter__container {
      max-width: 1280px;
      margin: 0 auto;
      position: relative;
      padding: 0 15px; }
      .form-com_avtnewsletter .newsletter__container .newsletter-title {
        margin-bottom: 0;
        justify-content: center;
        max-width: 610px; }
      .form-com_avtnewsletter .newsletter__container .h1-article {
        font-size: 50px;
        margin-bottom: 20px; }
      .form-com_avtnewsletter .newsletter__container .news-input {
        max-width: 510px;
        margin-bottom: 20px;
        border: none;
        height: 80px; }
        .form-com_avtnewsletter .newsletter__container .news-input::placeholder {
          opacity: 1;
          font-weight: 400;
          font-size: 20px;
          color: #fff; }
      .form-com_avtnewsletter .newsletter__container .newsletter-gratis {
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 40px;
        color: #fff; }
      .form-com_avtnewsletter .newsletter__container .btn-newsletter {
        max-width: 510px;
        height: 80px; }
        .form-com_avtnewsletter .newsletter__container .btn-newsletter[type='submit'] {
          margin: 0 auto; }
      .form-com_avtnewsletter .newsletter__container .btn-newsletter.btn-gift {
        background: linear-gradient(to bottom right, #f16655 0, #fb3636 100%);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin: 0 auto 50px;
        height: 80px; }
      .form-com_avtnewsletter .newsletter__container .avt-info {
        margin: 0 auto 40px;
        max-width: 510px; }
      .form-com_avtnewsletter .newsletter__container .gift {
        max-width: 510px;
        margin: 0 auto;
        display: none; }
    .form-com_avtnewsletter .newsletter__submit-form {
      margin-bottom: 200px;
      max-width: 610px; }
    .form-com_avtnewsletter .newsletter__unsubscribe-form {
      text-align: center;
      max-width: 610px; }
      .form-com_avtnewsletter .newsletter__unsubscribe-form .unsubscribe {
        max-width: 510px;
        margin: 0 auto; }
      .form-com_avtnewsletter .newsletter__unsubscribe-form .unsubscribe-text {
        font-size: 20px;
        opacity: 0.7;
        margin-bottom: 20px; }
      .form-com_avtnewsletter .newsletter__unsubscribe-form .mail,
      .form-com_avtnewsletter .newsletter__unsubscribe-form .submit {
        height: 50px; }
      .form-com_avtnewsletter .newsletter__unsubscribe-form .mail {
        border: 1px solid rgba(255, 255, 255, 0.2);
        text-align: center;
        border-top-left-radius: 17px;
        border-bottom-left-radius: 17px;
        background: transparent; }
      .form-com_avtnewsletter .newsletter__unsubscribe-form .submit {
        background: rgba(255, 255, 255, 0.2);
        border-top-right-radius: 17px;
        border-bottom-right-radius: 17px;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.05em; }
  @media (min-width: 768px) and (max-width: 1024.98px) {
    .form-com_avtnewsletter .header.header-subpages.header--new .header__burger {
      margin-right: 0; } }
  @media (min-width: 768px) and (max-width: 1024.98px) {
    .form-com_avtnewsletter.burger-open .header.header-subpages.header--new .header__burger {
      margin-right: 15px; } }
  @media (max-width: 767.98px) {
    .form-com_avtnewsletter.burger-open .header.header-subpages.header--new .header__burger {
      margin-right: 30px; } }
  .form-com_avtnewsletter.gift-selected .our-magazines {
    display: none; }
  .form-com_avtnewsletter.gift-selected .newsletter__gifts.loaded {
    opacity: 0; }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-wrap {
      display: none; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-wrap.children-selected {
      display: block; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-wrap.children-selected .newsletter__gift-paper {
      transform: translate3d(0, 0, 0) !important; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .mobile-wrap {
      display: flex;
      flex-wrap: wrap; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers, .form-com_avtnewsletter.gift-selected .newsletter__gift-papers.select-active {
      order: 1;
      width: 100%;
      position: static; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__scroll-wrap,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .simplebar-mask,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .simplebar-offset,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .simplebar-content-wrapper,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .simplebar-content, .form-com_avtnewsletter.gift-selected .newsletter__gift-papers.select-active .newsletter__scroll-wrap,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers.select-active .simplebar-mask,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers.select-active .simplebar-offset,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers.select-active .simplebar-content-wrapper,
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers.select-active .simplebar-content {
      width: 100% !important;
      position: static !important;
      height: auto !important;
      overflow: visible !important;
      margin: 0 !important;
      padding: 0 !important; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__gifts-close {
      position: static;
      width: 100%;
      justify-content: flex-end;
      padding: 0 15px; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__scroll-wrap:after {
      display: none; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__scroll-wrap .simplebar-placeholder {
      display: none; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__gift-wrap {
      width: 100%;
      max-width: 100%;
      text-align: center; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__gift-wrap .newsletter__gift-paper {
      margin: 0 auto 30px;
      padding: 0 15px; }
      .form-com_avtnewsletter.gift-selected .newsletter__gift-papers .newsletter__gift-wrap .newsletter__gift-paper > img {
        margin: 0 auto 30px; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter-main {
      order: 2;
      width: 100%;
      padding-top: 0; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter-main .main-bs-bottom {
      max-width: 100%;
      width: 100%;
      margin: 0;
      padding: 0 15px; } }
  @media (max-width: 1024.98px) {
    .form-com_avtnewsletter.gift-selected .newsletter-main .main-bs-bottom .newsletter__submit-form {
      height: auto; } }
  .form-com_avtnewsletter .top-menu--new {
    position: relative;
    z-index: 10; }
  .form-com_avtnewsletter #system-message-container {
    padding: 0 15px; }
  .form-com_avtnewsletter #system-message .alert.alert-message, .form-com_avtnewsletter #system-message .alert.alert-error {
    color: #fff;
    background-color: #284666;
    position: relative;
    z-index: 9;
    box-shadow: 0 25px 99px rgba(0, 0, 0, 0.16);
    padding: 30px;
    border-radius: 26px; }
  .form-com_avtnewsletter #system-message .alert > div {
    padding-right: 15px; }
    .form-com_avtnewsletter #system-message .alert > div .go-back-link a {
      color: #fff; }
    .form-com_avtnewsletter #system-message .alert > div .go-back-link:before {
      background-image: url("/templates/main/images/svg/arrow-btn.svg");
      transform-origin: 50% 50%;
      transform: rotate(180deg);
      background-position: center;
      background-repeat: no-repeat; }

.header.header-article {
  background: transparent;
  z-index: 10;
  position: relative; }
  @media (max-width: 768px) {
    .header.header-article {
      padding-bottom: 30px; } }

body[data-user-browser="firefox"] .single-article__top-raport {
  background-color: rgba(40, 70, 102, 0.9); }

.single-article__h1-wrapper {
  margin-bottom: 30px; }
  .single-article__h1-wrapper h1 {
    display: inline; }

.single-article.short.active-holiday::after {
  max-height: 826px; }

.single-article.short::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  max-height: 1061px;
  pointer-events: none; }
  @media (min-width: 1366px) {
    .single-article.short::after {
      height: 1020px; } }

.single-article.short .col-left {
  position: relative; }

.single-article.short .col-right {
  z-index: 2; }

.single-article.short .single-article__center {
  border-radius: 0;
  margin-top: 0;
  background-color: transparent;
  padding-top: 34px;
  padding-left: 0;
  padding-right: 0; }

.single-article.short .single-article__top {
  background-color: transparent !important; }
  .single-article.short .single-article__top::after {
    max-height: 956px; }
    @media (max-width: 767.98px) {
      .single-article.short .single-article__top::after {
        max-height: 975px; } }
  .single-article.short .single-article__top::before {
    top: 500px; }

.single-article.short .single-article__top-raport {
  margin-bottom: 30px; }

.single-article.short .single-article__top-text-title {
  z-index: 2;
  position: relative; }

.single-article.short .single-article__top-text-description {
  z-index: 2;
  position: relative; }

.single-article.short .single-article__top-text-time {
  z-index: 2;
  position: relative; }

.single-article.short .single-article__top-text {
  padding-bottom: 211px;
  position: inherit; }
  @media (max-width: 1025px) {
    .single-article.short .single-article__top-text {
      padding: 0; } }
  @media (max-width: 600px) {
    .single-article.short .single-article__top-text {
      background-color: transparent;
      padding: 0 15px 50px 15px;
      margin: 0 -15px; } }

.single-article.short .single-article__center {
  margin-top: -230px;
  margin-bottom: 70px; }
  @media (max-width: 1025px) {
    .single-article.short .single-article__center {
      margin-top: 0; } }

.single-article.short .single-article__cent-image {
  margin-bottom: 50px;
  margin-top: -211px;
  position: relative;
  z-index: 2; }
  .single-article.short .single-article__cent-image img {
    border-radius: 20px;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }
  @media (max-width: 1025px) {
    .single-article.short .single-article__cent-image {
      margin-top: 0; } }
  @media (max-width: 600px) {
    .single-article.short .single-article__cent-image {
      margin-top: -50px;
      z-index: 5; } }

.single-article.short .single-article__right-magazine {
  margin-bottom: 30px; }
  .single-article.short .single-article__right-magazine img {
    max-width: 100%; }

.single-article.short .single-article__socials-wrapper {
  top: 575px;
  z-index: 1;
  padding-bottom: 600px; }
  .single-article.short .single-article__socials-wrapper.sticky-menu-open {
    padding-bottom: 650px; }
  .single-article.short .single-article__socials-wrapper .single-article__socials {
    margin-bottom: 180px; }

.single-article.short.wymowne-grafiki .single-article__cent-image {
  margin-bottom: 42px; }

@media (max-width: 1025px) {
  .single-article.short .single-article__top-wrapper::before {
    content: none; } }

.single-article.wymowne-grafiki .single-article__center {
  border-radius: 0;
  margin-top: 0;
  background-color: transparent;
  padding-top: 34px;
  padding-left: 0;
  padding-right: 0; }

.single-article.wymowne-grafiki .single-article__cent-image {
  margin-bottom: 50px;
  position: relative; }
  .single-article.wymowne-grafiki .single-article__cent-image img {
    border-radius: 20px;
    box-shadow: 0 0 99px rgba(0, 0, 0, 0.16); }

.single-article.wymowne-grafiki .single-article__right-magazine-top-title {
  font-size: 11px;
  line-height: 23px;
  margin-bottom: 7px;
  font-weight: 700; }

.single-article.wymowne-grafiki .single-article__right-magazine-description {
  font-size: 12px;
  line-height: 18px; }

.single-article.wymowne-grafiki .single-article__right-magazine-btn {
  font-size: 13px;
  line-height: 10px;
  padding: 11px 0;
  line-height: 9px; }

.single-article.wymowne-grafiki .single-article__top-text {
  padding-bottom: 0;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between; }

.single-article.wymowne-grafiki .single-article__center {
  padding-top: 0;
  margin-top: -85px; }

.single-article.wymowne-grafiki .single-article__right-magazine-image img {
  display: block;
  margin: 0 auto; }

.single-article.wymowne-grafiki .single-article__top-text-description {
  margin-bottom: 30px; }

.single-article.wymowne-grafiki .single-article__top {
  margin-bottom: 38px;
  padding-bottom: 85px; }

.single-article.wymowne-grafiki .single-article__top-text-time {
  margin-bottom: 2px;
  width: 100%; }

.single-article.wymowne-grafiki .single-article__right-magazine {
  padding: 17px 27px;
  max-width: 217px;
  margin: 0 0 0 auto; }

.single-article.long .single-article__cent-image {
  margin-bottom: 53px; }
  .single-article.long .single-article__cent-image img {
    border-radius: 20px;
    box-shadow: 0 0 99pxrgba 0, 0, 0, 0.16; }

@media (max-width: 600px) {
  .single-article.long .single-article__top-text {
    background-color: transparent;
    padding: 0 15px 1px 15px;
    margin: 0 -15px; } }

.single-article.long.raport .single-article__center {
  border-radius: 0;
  margin-top: 0;
  background-color: transparent;
  padding-top: 34px;
  padding-left: 0;
  padding-right: 0;
  margin-top: -230px;
  margin-bottom: 70px; }
  @media (max-width: 1025px) {
    .single-article.long.raport .single-article__center {
      margin-top: 0; } }

.single-article.long.raport .single-article__top-text {
  padding-bottom: 230px; }
  @media (max-width: 1025px) {
    .single-article.long.raport .single-article__top-text {
      padding: 0; } }
  @media (max-width: 600px) {
    .single-article.long.raport .single-article__top-text {
      background-color: transparent;
      padding: 0 15px 1px 15px;
      margin: 0 -15px; } }

.single-article.long .single-article__socials-wrapper .single-article__socials {
  margin-bottom: 90px; }

.single-article__holiday {
  display: inline-flex;
  align-items: center;
  padding: 8px 13px 2px 10px;
  background: linear-gradient(135deg, #274565 0%, #35b7c1 100%);
  max-width: 100%;
  width: auto;
  border-radius: 5px;
  margin-bottom: 30px; }
  .single-article__holiday-day {
    font-size: 31px;
    line-height: 31px;
    font-weight: 700;
    margin-right: 8px; }
  .single-article__holiday-month {
    font-size: 15px;
    line-height: 19px;
    font-weight: 700;
    opacity: 0.5;
    margin-right: 14px; }
  .single-article__holiday-title {
    font-size: 15px;
    line-height: 29px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 17px; }
  .single-article__holiday-icon {
    margin-top: -2px; }

.single-article__holistic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px; }
  @media (max-width: 570px) {
    .single-article__holistic {
      flex-wrap: wrap;
      background-color: #fff;
      box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
      padding-top: 20px;
      border-radius: 20px; } }
  @media (max-width: 570px) {
    .single-article__holistic-image {
      display: flex;
      justify-content: center;
      width: 100%; } }
  .single-article__holistic-right {
    padding: 20px 31px 20px 40px;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px; }
    @media (max-width: 570px) {
      .single-article__holistic-right {
        box-shadow: none;
        padding: 15px; } }
    .single-article__holistic-right-top {
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase; }
    .single-article__holistic-right-title {
      font-size: 33px;
      line-height: 34px;
      font-weight: 700;
      margin-bottom: 10px; }
      @media (max-width: 1250px) {
        .single-article__holistic-right-title {
          font-size: 23px;
          line-height: 24px; } }
    .single-article__holistic-right-btns {
      font-size: 13px;
      line-height: 21px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      display: flex;
      letter-spacing: 0.3px;
      white-space: nowrap; }
      @media (max-width: 1250px) {
        .single-article__holistic-right-btns {
          flex-wrap: wrap; } }
      .single-article__holistic-right-btns a.single-article__holistic-right-btns-number {
        color: #fff !important;
        text-decoration: none; }
      .single-article__holistic-right-btns a.single-article__holistic-right-btns-enumber {
        color: #fff !important;
        text-decoration: none; }
      .single-article__holistic-right-btns-number {
        background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
        margin-right: 15px;
        padding: 20px 38px;
        border-radius: 10px;
        max-width: 302px;
        width: 100%;
        margin-bottom: 5px;
        text-align: center; }
        @media (max-width: 1400px) {
          .single-article__holistic-right-btns-number {
            padding: 15px; } }
        @media (max-width: 1250px) {
          .single-article__holistic-right-btns-number {
            max-width: 100%;
            margin-right: 0; } }
      .single-article__holistic-right-btns-enumber {
        background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
        padding: 20px 38px;
        border-radius: 10px;
        max-width: 302px;
        width: 100%;
        margin-bottom: 5px;
        text-align: center; }
        @media (max-width: 1400px) {
          .single-article__holistic-right-btns-enumber {
            padding: 15px; } }
        @media (max-width: 1250px) {
          .single-article__holistic-right-btns-enumber {
            max-width: 100%; } }

.single-article__socials {
  max-width: 80px;
  width: 100%;
  position: sticky;
  margin-top: 32px;
  top: 100px;
  margin-bottom: 30px;
  right: 30px;
  max-height: 80px;
  overflow: hidden;
  box-shadow: 0 15px 99px rgba(0, 0, 0, 0.22);
  transition: .2s all;
  z-index: 3;
  background-color: transparent;
  border-radius: 7px; }
  @media (max-width: 1670px) {
    .single-article__socials {
      max-width: 50px;
      max-height: 50px; } }
  .single-article__socials.show {
    max-height: 1000px; }
  .single-article__socials-wrapper {
    position: absolute;
    left: -110px;
    top: 65px;
    height: 100%;
    width: 80px;
    transition: .2s all;
    transform: translateY(0); }
    @media (max-width: 1670px) {
      .single-article__socials-wrapper {
        width: 50px;
        left: -60px; } }
    @media (max-width: 1550px) {
      .single-article__socials-wrapper {
        display: none; } }
    .single-article__socials-wrapper.sticky-menu-open .single-article__socials {
      transform: translateY(60px); }
  .single-article__socials-btn {
    background-color: #F16655;
    height: 80px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 1670px) {
      .single-article__socials-btn {
        height: 50px; } }
    .single-article__socials-btn.show {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 7px;
      border-top-left-radius: 7px;
      background-color: #fff; }
      .single-article__socials-btn.show svg path {
        stroke: #FB3636; }
  .single-article__socials-fb {
    background-color: #3B5999;
    height: 80px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 1670px) {
      .single-article__socials-fb {
        height: 50px; } }
  .single-article__socials-messanger {
    background-color: #0084FF;
    height: 80px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 1670px) {
      .single-article__socials-messanger {
        height: 50px; } }
  .single-article__socials-twitter {
    background-color: #55ACEE;
    height: 80px;
    border-radius: 7px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer; }
    @media (max-width: 1670px) {
      .single-article__socials-twitter {
        height: 50px; } }

.single-article__bottom > .row:first-child:empty + .row, .single-article__bottom > .shops__top:first-child:empty + .row, .recommended-col .articles-large-top-slider .swiper-hp-top .single-article__bottom > .swiper-wrapper:first-child:empty + .row, .single-article__bottom > .row:first-child:empty + .shops__top, .single-article__bottom > .shops__top:first-child:empty + .shops__top, .recommended-col .articles-large-top-slider .swiper-hp-top .single-article__bottom > .swiper-wrapper:first-child:empty + .shops__top, .recommended-col .articles-large-top-slider .swiper-hp-top .single-article__bottom > .row:first-child:empty + .swiper-wrapper, .recommended-col .articles-large-top-slider .swiper-hp-top .single-article__bottom > .shops__top:first-child:empty + .swiper-wrapper, .recommended-col .articles-large-top-slider .swiper-hp-top .single-article__bottom > .swiper-wrapper:first-child:empty + .swiper-wrapper {
  display: none; }

.single-article__bottom-btn {
  max-width: 691px;
  width: 100%;
  color: #fff;
  background: linear-gradient(to right, #35b7c1 0%, #3fb693 100%);
  padding: 44px 0;
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  border-radius: 20px;
  margin: 0 auto 96px auto;
  position: relative;
  z-index: 3; }
  .single-article__bottom-btn img {
    margin-left: 34px; }

.single-article__top {
  background-color: #fff;
  position: relative; }
  .single-article__top-raport {
    border-radius: 20px;
    padding: 22px 37px;
    z-index: 5;
    position: relative;
    text-align: center;
    backdrop-filter: blur(20px);
    background-color: rgba(40, 70, 102, 0.9);
    color: #fff; }
    @media (max-width: 1025px) {
      .single-article__top-raport {
        max-width: 450px;
        margin: 0px auto 30px auto; } }
    .single-article__top-raport::after {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 20px; }
    .single-article__top-raport-top-title {
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      color: #fff;
      text-transform: uppercase;
      background: linear-gradient(135deg, #3fb693 0%, #35b7c1 100%);
      padding: 6px 12px;
      display: inline-block;
      border-radius: 5px;
      margin: 22px 0;
      position: relative;
      z-index: 1; }
    .single-article__top-raport-title {
      font-size: 25px;
      line-height: 31px;
      font-weight: 700;
      margin-bottom: 22px;
      position: relative;
      z-index: 1; }
    .single-article__top-raport-btn {
      font-size: 13px;
      line-height: 21px;
      font-weight: 700;
      padding: 12px 20px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      display: inline-block;
      border-radius: 10px;
      position: relative;
      z-index: 1; }
  .single-article__top-wrapper {
    position: relative; }
  .single-article__top::after {
    content: '';
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    position: absolute;
    right: 0;
    top: 0;
    max-width: 800px;
    width: 100%;
    height: 80%;
    z-index: -1; }
  .single-article__top::before {
    content: '';
    background: linear-gradient(to bottom, rgba(103, 111, 134, 0.07) 0%, rgba(255, 255, 255, 0) 100%);
    position: absolute;
    width: 100%;
    height: 305px;
    bottom: -305px;
    opacity: 0;
    pointer-events: none; }
  .single-article__top-text {
    z-index: 5;
    position: relative;
    padding-bottom: 90px;
    color: #1A1B1C; }
    @media (max-width: 1024.98px) {
      .single-article__top-text {
        padding-left: 0; } }
    @media (max-width: 600px) {
      .single-article__top-text {
        background-color: #002a43;
        padding: 0 15px 15px 15px;
        margin: 0 -15px; } }
    .single-article__top-text-category {
      font-size: 15px;
      line-height: 21px;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      display: flex;
      align-items: center;
      padding-left: 59px;
      margin-bottom: 11px; }
      .single-article__top-text-category::before {
        content: '';
        width: 44px;
        height: 2px;
        background-color: rgba(255, 255, 255, 0.2);
        position: absolute;
        left: 0; }
    .single-article__top-text-title {
      font-size: 45px;
      line-height: 55px;
      font-weight: 700;
      margin-bottom: 30px; }
      @media (max-width: 767.98px) {
        .single-article__top-text-title {
          font-size: 25px;
          line-height: 35px; } }
    .single-article__top-text-description {
      font-size: 21px;
      line-height: 33px;
      width: 100%;
      margin-bottom: 25px;
      font-weight: 500; }
      @media (max-width: 767.98px) {
        .single-article__top-text-description {
          font-size: 18px;
          line-height: 30px; } }
      .single-article__top-text-description p {
        margin: 0; }
    .single-article__top-text-time {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 38px;
      align-items: center;
      align-content: center; }
      .single-article__top-text-time.space-between {
        display: flex;
        justify-content: space-between; }
        @media (max-width: 900px) {
          .single-article__top-text-time.space-between {
            flex-wrap: wrap; } }
      @media (max-width: 900px) {
        .single-article__top-text-time-left {
          margin-bottom: 10px;
          max-width: 100%; } }
      .single-article__top-text-time-left .date {
        opacity: .7;
        display: inline-block;
        margin-right: 60px; }
        @media (max-width: 500px) {
          .single-article__top-text-time-left .date {
            display: block; } }
      @media (max-width: 900px) {
        .single-article__top-text-time-right {
          max-width: 100%; }
          .single-article__top-text-time-right-tag {
            margin-left: 0 !important;
            margin-right: 5px; } }
      .single-article__top-text-time-right-tag {
        font-size: 13px;
        line-height: 26px;
        padding: 0 8px;
        border-radius: 5px;
        border: 1px solid #CACACA;
        color: #1A1B1C;
        transition: .5s all;
        margin-left: 5px;
        display: inline-block;
        max-height: 29px;
        margin-bottom: 5px; }

.single-article__author {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: center; }
  .single-article__author-image {
    margin-right: 14px;
    border-radius: 9px;
    overflow: hidden; }
  .single-article__author-name {
    font-size: 15px;
    font-weight: 700;
    display: block;
    width: 100%; }

.single-article__center {
  padding-top: 50px;
  border-radius: 20px;
  margin-top: -65px;
  position: relative; }
  @media (max-width: 1025px) {
    .single-article__center {
      margin-top: 0; } }
  @media (max-width: 1024.98px) {
    .single-article__center {
      padding: 0; } }
  @media (max-width: 768px) {
    .single-article__center {
      margin-top: 0;
      border-radius: 0; } }
  .single-article__center-wrapper {
    position: relative;
    z-index: 6; }

.single-article__introtext {
  color: #1A1B1C;
  font-size: 19px;
  line-height: 33px;
  margin-bottom: 30px;
  padding-right: 50px;
  font-weight: 700; }
  @media (max-width: 1024.98px) {
    .single-article__introtext {
      padding-right: 0; } }

.single-article__fulltext {
  color: #1A1B1C;
  font-size: 19px;
  line-height: 33px; }
  .single-article__fulltext > div:first-child.e-commerce--article-transparent {
    padding-top: 0; }
  @media (max-width: 1024.98px) {
    .single-article__fulltext {
      padding: 0; } }
  @media (max-width: 768px) {
    .single-article__fulltext {
      font-size: 17px;
      line-height: 29px;
      padding: 0;
      padding-top: 15px; } }
  @media (max-width: 576px) {
    .single-article__fulltext {
      font-size: 17px;
      line-height: 29px; } }
  .single-article__fulltext p, .single-article__fulltext span {
    color: #1A1B1C !important; }
  .single-article__fulltext p > iframe[name="embedded-content"] {
    margin-bottom: 0; }
  .single-article__fulltext iframe[name="embedded-content"] {
    border: none;
    width: 100%;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    max-width: calc(100% - 80px);
    margin: 0 auto;
    display: block;
    border-radius: 15px;
    opacity: 0;
    transition: opacity .2s ease-in-out; }
    .single-article__fulltext iframe[name="embedded-content"].loaded {
      opacity: 1; }
  .single-article__fulltext p.film {
    position: relative;
    padding-top: 56.25%;
    margin-bottom: 30px; }
    .single-article__fulltext p.film iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none; }
  .single-article__fulltext .image-right-side {
    display: flex;
    flex-wrap: wrap;
    padding: 25px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
    border-radius: 15px; }
    .single-article__fulltext .image-right-side-left {
      max-width: 60%;
      flex: 0 0 60%;
      padding-right: 30px;
      padding-left: 20px; }
      .single-article__fulltext .image-right-side-left-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-top: 20px;
        color: #000; }
      .single-article__fulltext .image-right-side-left-description {
        font-size: 15px;
        line-height: 22px;
        color: #000; }
    .single-article__fulltext .image-right-side-right {
      max-width: 40%;
      flex: 0 0 40%;
      padding-left: 30px; }
  .single-article__fulltext .bibliografia, .single-article__fulltext .bibliography {
    border-radius: 10px;
    margin-bottom: 30px;
    overflow: hidden; }
    .single-article__fulltext .bibliografia > ol, .single-article__fulltext .bibliografia > ul, .single-article__fulltext .bibliography > ol, .single-article__fulltext .bibliography > ul {
      counter-reset: myOrderedListItemsCounter; }
      .single-article__fulltext .bibliografia > ol > li, .single-article__fulltext .bibliografia > ul > li, .single-article__fulltext .bibliography > ol > li, .single-article__fulltext .bibliography > ul > li {
        overflow-wrap: break-word;
        position: relative; }
        .single-article__fulltext .bibliografia > ol > li::before, .single-article__fulltext .bibliografia > ul > li::before, .single-article__fulltext .bibliography > ol > li::before, .single-article__fulltext .bibliography > ul > li::before {
          counter-increment: myOrderedListItemsCounter;
          content: counter(myOrderedListItemsCounter) ".";
          margin-right: 15px;
          position: absolute;
          top: 6px;
          left: 25px; }
        .single-article__fulltext .bibliografia > ol > li::marker, .single-article__fulltext .bibliografia > ul > li::marker, .single-article__fulltext .bibliography > ol > li::marker, .single-article__fulltext .bibliography > ul > li::marker {
          margin-left: 15px; }
    .single-article__fulltext .bibliografia > ol, .single-article__fulltext .bibliografia > ul, .single-article__fulltext .bibliography > ol, .single-article__fulltext .bibliography > ul {
      list-style: none;
      padding: 0;
      margin: 0; }
      .single-article__fulltext .bibliografia > ol li, .single-article__fulltext .bibliografia > ul li, .single-article__fulltext .bibliography > ol li, .single-article__fulltext .bibliography > ul li {
        padding: 6px 25px 6px 50px;
        font-size: 15px;
        line-height: 18px; }
      .single-article__fulltext .bibliografia > ol li:nth-child(odd), .single-article__fulltext .bibliografia > ul li:nth-child(odd), .single-article__fulltext .bibliography > ol li:nth-child(odd), .single-article__fulltext .bibliography > ul li:nth-child(odd) {
        background-color: var(--colorBgDarker); }
  .single-article__fulltext .static.bibliografia.p {
    background-color: #fff; }
  .single-article__fulltext .spis-tresci {
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    transition: .3s all ease-in-out;
    width: 100%;
    z-index: 3;
    background-color: #fff;
    border-radius: 10px;
    padding: 28px;
    position: relative;
    margin-bottom: 50px;
    z-index: 1; }
    .single-article__fulltext .spis-tresci__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer; }
      .single-article__fulltext .spis-tresci__top-left {
        display: flex;
        align-items: center; }
    .single-article__fulltext .spis-tresci__bottom {
      color: #000C2D !important;
      font-size: 16px;
      line-height: 30px; }
      @media (min-width: 576px) {
        .single-article__fulltext .spis-tresci__bottom {
          font-size: 19px;
          line-height: 40px; } }
      .single-article__fulltext .spis-tresci__bottom-list {
        list-style: none;
        margin: 0;
        margin-top: 21px;
        padding-top: 20px;
        border-top: 2px solid #DDDDDD; }
        @media (max-width: 575px) {
          .single-article__fulltext .spis-tresci__bottom-list {
            padding-left: 10px; } }
        .single-article__fulltext .spis-tresci__bottom-list-el {
          position: relative;
          padding-left: 26px; }
          .single-article__fulltext .spis-tresci__bottom-list-el::before {
            content: '';
            width: 10px;
            height: 10px;
            background-color: #FB3636;
            border-radius: 3px;
            position: absolute;
            left: 0;
            transform: translate(-50%, -50%);
            top: 14px; }
            @media (min-width: 576px) {
              .single-article__fulltext .spis-tresci__bottom-list-el::before {
                top: 18px; } }
          .single-article__fulltext .spis-tresci__bottom-list-el a {
            color: #1a1b1c !important;
            text-transform: none;
            display: block;
            text-decoration: none; }
    .single-article__fulltext .spis-tresci__icon {
      margin-right: 32px; }
    .single-article__fulltext .spis-tresci__title {
      font-size: 22px;
      line-height: 26px;
      font-weight: 700;
      text-transform: uppercase; }
  .single-article__fulltext ul {
    padding-bottom: 20px; }
  .single-article__fulltext > ul {
    list-style: none;
    margin-inline-start: -23px; }
    @media (min-width: 575.98px) {
      .single-article__fulltext > ul {
        margin-inline-start: 1em; } }
    .single-article__fulltext > ul li {
      margin-bottom: 15px;
      position: relative;
      padding-left: 5px; }
      .single-article__fulltext > ul li::before {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background-color: #F16655;
        position: absolute;
        left: -16px;
        top: 10px; }
  .single-article__fulltext > ol {
    margin-inline-start: -23px; }
    @media (min-width: 575.98px) {
      .single-article__fulltext > ol {
        margin-inline-start: 1em; } }
  .single-article__fulltext h2 {
    font-size: 25px;
    line-height: 31px;
    font-weight: 700;
    margin: 30px 0; }
    .single-article__fulltext h2:first-of-type {
      margin-top: 0; }
    @media (max-width: 450px) {
      .single-article__fulltext h2 {
        font-size: 20px;
        line-height: 26px; } }
  .single-article__fulltext .custom-bg {
    background-color: #F16655;
    color: #fff !important;
    padding: 4px 8px 3px 8px;
    border-radius: 3px; }
  .single-article__fulltext .msfloatright {
    float: right; }
  .single-article__fulltext .mscenter {
    display: block;
    margin-left: auto;
    margin-right: auto; }
  .single-article__fulltext .msfloatleft {
    float: left; }
  .single-article__fulltext .msmaxwidth {
    max-width: 100%; }
  .single-article__fulltext p {
    margin-bottom: 20px; }
  .single-article__fulltext a {
    color: #FB3636 !important;
    text-decoration: underline; }
  .single-article__fulltext .quote {
    font-size: 22px;
    line-height: 35px;
    font-weight: 700;
    color: #294868;
    display: flex;
    margin: 70px 0; }
    @media (max-width: 600px) {
      .single-article__fulltext .quote {
        display: block; } }
    @media (max-width: 450px) {
      .single-article__fulltext .quote {
        font-size: 20px;
        line-height: 33px; } }
    .single-article__fulltext .quote__image {
      margin-left: 50px;
      margin-right: 20px; }
      @media (max-width: 768px) {
        .single-article__fulltext .quote__image {
          margin-left: 0; } }
      @media (max-width: 600px) {
        .single-article__fulltext .quote__image {
          float: left; } }
      @media (max-width: 600px) {
        .single-article__fulltext .quote__image img {
          max-width: 30px; } }
  .single-article__fulltext .dictionary {
    background-color: #737E9F;
    color: #fff;
    padding: 25px;
    border-radius: 10px;
    margin-bottom: 54px; }
    @media (max-width: 575.98px) {
      .single-article__fulltext .dictionary {
        padding: 15px; } }
    .single-article__fulltext .dictionary__title {
      font-size: 25px;
      font-weight: 700;
      padding-left: 16px;
      margin-bottom: 33px; }
      @media (max-width: 575.98px) {
        .single-article__fulltext .dictionary__title {
          padding-left: 0; } }
    .single-article__fulltext .dictionary__el {
      margin-bottom: 16px;
      font-size: 15px;
      line-height: 22px; }
      .single-article__fulltext .dictionary__el .bold {
        font-weight: 700; }
  .single-article__fulltext .image-with-description {
    border: 1px solid #DDDDDD;
    padding: 55px 90px 15px 90px;
    margin-bottom: 30px;
    border-radius: 10px; }
    .single-article__fulltext .image-with-description__image {
      display: flex;
      justify-content: center;
      margin-bottom: 25px; }
    .single-article__fulltext .image-with-description__text {
      font-size: 15px;
      line-height: 23px;
      text-align: center; }
  .single-article__fulltext .list {
    padding: 25px;
    background-color: #DDDDDD;
    border-radius: 10px;
    margin-bottom: 30px; }
    .single-article__fulltext .list__title {
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 20px; }
    .single-article__fulltext .list__description {
      font-size: 19px;
      line-height: 30px;
      margin-bottom: 15px; }
    .single-article__fulltext .list__bold-title {
      font-size: 19px;
      line-height: 30px;
      font-weight: 700;
      margin-bottom: 18px; }
    .single-article__fulltext .list__elements {
      list-style: none; }
      .single-article__fulltext .list__elements-el {
        margin-bottom: 15px;
        position: relative;
        padding-left: 5px; }
        .single-article__fulltext .list__elements-el::before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 100%;
          background-color: #F16655;
          position: absolute;
          left: -16px;
          top: 10px; }
  .single-article__fulltext .two-pictures {
    margin-bottom: 50px; }
    .single-article__fulltext .two-pictures__image img {
      margin-bottom: 16px;
      border-radius: 10px;
      max-width: 100%; }
    .single-article__fulltext .two-pictures__text {
      font-size: 13px;
      line-height: 16px; }
  .single-article__fulltext .twitter-tweet {
    margin: 30px auto !important; }
  .single-article__fulltext blockquote:not(.twitter-tweet) {
    font-size: 22px;
    line-height: 35px;
    font-weight: 700;
    text-align: left;
    margin: 70px 0 70px 50px;
    color: #294868 !important;
    min-height: 54px; }
    @media (min-width: 600px) {
      .single-article__fulltext blockquote:not(.twitter-tweet) {
        background-image: url("/templates/main/images/svg/quote.svg");
        background-repeat: no-repeat,no-repeat;
        background-position: 0 10px;
        padding-left: 75px; } }
    @media (max-width: 600px) {
      .single-article__fulltext blockquote:not(.twitter-tweet)::before {
        content: '';
        width: 30px;
        margin-right: 14.4px;
        height: 24px;
        float: left;
        background-image: url("/templates/main/images/svg/quote.svg");
        background-repeat: no-repeat,no-repeat;
        background-size: contain;
        margin-top: 5px; } }
    @media (max-width: 600px) {
      .single-article__fulltext blockquote:not(.twitter-tweet) {
        margin-left: 0; } }
    @media (max-width: 450px) {
      .single-article__fulltext blockquote:not(.twitter-tweet) {
        font-size: 20px;
        line-height: 33px; } }
    .single-article__fulltext blockquote:not(.twitter-tweet) span {
      color: #294868 !important; }
    .single-article__fulltext blockquote:not(.twitter-tweet) > p {
      text-align: left;
      color: #294868 !important; }
  .single-article__fulltext iframe {
    border: none; }
  .single-article__fulltext iframe[name="embedded-content"] {
    width: 100%;
    margin-bottom: 30px; }
  .single-article__fulltext img.p {
    float: right;
    margin-top: 15px;
    margin-left: 15px;
    margin-bottom: 15px; }
  .single-article__fulltext img.l {
    float: left;
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px; }
  .single-article__fulltext .static.bibliografia {
    width: 100%;
    margin: 0 0 30px 0; }
    .single-article__fulltext .static.bibliografia.l, .single-article__fulltext .static.bibliografia.r {
      float: none; }
  .single-article__fulltext .ramka4.mit, .single-article__fulltext .ramka4.fakt {
    background-color: #fff;
    border: 1px solid #DDDDDD;
    display: flex;
    flex-wrap: wrap;
    align-items: center; }
    .single-article__fulltext .ramka4.mit > img, .single-article__fulltext .ramka4.fakt > img {
      max-width: 125px;
      flex: 0 0 125px;
      margin-right: 21px; }
    .single-article__fulltext .ramka4.mit > .title, .single-article__fulltext .ramka4.fakt > .title {
      max-width: calc(100% - 146px);
      flex: 0 0 calc(100% - 146px); }
    .single-article__fulltext .ramka4.mit > .content, .single-article__fulltext .ramka4.fakt > .content {
      max-width: 100%;
      flex: 0 0 100%;
      margin-top: 23px; }
  .single-article__fulltext .imagesInContent img {
    display: block;
    margin: 0 auto 20px auto; }
  .single-article__fulltext .ramka1, .single-article__fulltext .ramka2, .single-article__fulltext .ramka3, .single-article__fulltext .ramka4, .single-article__fulltext .ramka5 {
    border-radius: 15px;
    background-color: #fff;
    box-shadow: 0 0 50px #00000036;
    border: none;
    font-size: 17px;
    line-height: 30px;
    margin: 40px;
    padding: 40px; }
    @media (max-width: 768px) {
      .single-article__fulltext .ramka1, .single-article__fulltext .ramka2, .single-article__fulltext .ramka3, .single-article__fulltext .ramka4, .single-article__fulltext .ramka5 {
        margin: 40px 5px;
        padding: 15px; } }
    .single-article__fulltext .ramka1 > p.title, .single-article__fulltext .ramka2 > p.title, .single-article__fulltext .ramka3 > p.title, .single-article__fulltext .ramka4 > p.title, .single-article__fulltext .ramka5 > p.title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 700; }
    .single-article__fulltext .ramka1 ul, .single-article__fulltext .ramka2 ul, .single-article__fulltext .ramka3 ul, .single-article__fulltext .ramka4 ul, .single-article__fulltext .ramka5 ul {
      list-style: none;
      padding: 0; }
      .single-article__fulltext .ramka1 ul li, .single-article__fulltext .ramka2 ul li, .single-article__fulltext .ramka3 ul li, .single-article__fulltext .ramka4 ul li, .single-article__fulltext .ramka5 ul li {
        padding-left: 37px;
        position: relative; }
        @media (max-width: 768px) {
          .single-article__fulltext .ramka1 ul li, .single-article__fulltext .ramka2 ul li, .single-article__fulltext .ramka3 ul li, .single-article__fulltext .ramka4 ul li, .single-article__fulltext .ramka5 ul li {
            padding-left: 20px; } }
        .single-article__fulltext .ramka1 ul li::before, .single-article__fulltext .ramka2 ul li::before, .single-article__fulltext .ramka3 ul li::before, .single-article__fulltext .ramka4 ul li::before, .single-article__fulltext .ramka5 ul li::before {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #F16655;
          position: absolute;
          left: 13px;
          top: 10px; }
          @media (max-width: 768px) {
            .single-article__fulltext .ramka1 ul li::before, .single-article__fulltext .ramka2 ul li::before, .single-article__fulltext .ramka3 ul li::before, .single-article__fulltext .ramka4 ul li::before, .single-article__fulltext .ramka5 ul li::before {
              left: 0; } }
    .single-article__fulltext .ramka1 img, .single-article__fulltext .ramka2 img, .single-article__fulltext .ramka3 img, .single-article__fulltext .ramka4 img, .single-article__fulltext .ramka5 img {
      max-width: 100%;
      height: auto; }

.single-article__search {
  max-width: 300px;
  display: block;
  width: 100%;
  padding: 51px 0;
  color: #fff;
  background: linear-gradient(to right, #f16655 0%, #fb3636 100%);
  border-radius: 14px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 21px;
  font-weight: 700;
  margin: 31px auto 46px auto; }
  .single-article__search:hover {
    color: #fff; }

.single-article__red-title {
  font-size: 30px;
  line-height: 44px;
  font-weight: 700;
  border-bottom: 3px solid #F16655;
  margin-bottom: 46px; }
  .single-article__red-title.add-height {
    height: 91px;
    display: flex;
    align-items: flex-end; }
  .single-article__red-title.add-more-height {
    height: 131px;
    display: flex;
    align-items: flex-end; }

.single-article__institution.therapists {
  margin-top: -200px; }
  .single-article__institution.therapists .therapists-hp__title {
    color: #fff; }

.single-article__institution-left {
  margin-right: 20px; }

.single-article__institution-right {
  min-width: 100px; }

.single-article__institution-el {
  padding: 30px 17px 30px 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  z-index: 3;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  border-radius: 20px; }

.single-article__institution-title {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 5px; }

.single-article__institution-city {
  font-size: 13px;
  line-height: 21px;
  opacity: 0.5;
  margin-bottom: 13px; }

.single-article__institution-votes .vote-percent {
  font-size: 15px;
  line-height: 23px;
  font-weight: 700;
  margin-right: 14px; }
  .single-article__institution-votes .vote-percent img {
    max-width: 20px; }

.single-article__institution-votes .vote-numbers {
  font-size: 13px;
  line-height: 23px;
  margin-right: 27px; }

.single-article__institution-votes .see-profile {
  font-size: 11px;
  font-weight: 700;
  color: #35B7C1;
  text-transform: uppercase; }

.single-article__institution-image {
  padding: 16px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  max-width: 100px; }

.single-article__person {
  border-radius: 20px;
  padding: 30px;
  position: relative;
  z-index: 2;
  margin-bottom: 26px;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  background-color: #fff; }
  @media (max-width: 767.98px) {
    .single-article__person-votes {
      margin-bottom: 20px;
      display: flex;
      justify-content: center; } }
  .single-article__person-votes .vote-percent {
    font-size: 15px;
    line-height: 23px;
    font-weight: 700; }
    .single-article__person-votes .vote-percent img {
      margin-right: 10px;
      max-width: 19px;
      width: 100%; }
  .single-article__person-votes .vote-numbers {
    font-size: 13px; }
  @media (max-width: 767.98px) {
    .single-article__person-image {
      display: flex;
      justify-content: center; } }
  .single-article__person-image img {
    max-width: 100%;
    border-radius: 10px;
    margin-bottom: 15px; }
  .single-article__person-name {
    font-size: 22px;
    font-weight: 700; }
  .single-article__person-city {
    font-size: 15px;
    line-height: 21px;
    opacity: .5;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    margin-bottom: 10px; }
  .single-article__person-address {
    padding-left: 35px;
    position: relative;
    font-size: 15px;
    line-height: 28px; }
    .single-article__person-address::before {
      content: '';
      position: absolute;
      left: 0;
      background-image: url("/templates/main/images/svg/address-pin.svg");
      background-size: cover;
      width: 18px;
      height: 22px; }
    .single-article__person-address .see-profile {
      font-size: 11px;
      font-weight: 700;
      color: #35B7C1;
      text-transform: uppercase;
      position: absolute;
      right: 0;
      bottom: 0; }
  .single-article__person-www {
    padding-left: 35px;
    position: relative;
    font-size: 15px;
    line-height: 28px; }
    .single-article__person-www::before {
      content: '';
      position: absolute;
      left: 0;
      background-image: url("/templates/main/images/svg/external-link2.svg");
      background-size: cover;
      width: 22px;
      height: 22px; }
  .single-article__person-category .person-category-el {
    font-weight: 700; }
  .single-article__person-category.green .person-category-el {
    background-color: #3FB693; }
  .single-article__person .person-category-el {
    display: inline-block;
    padding: 3px 6px;
    background-color: #35B7C1;
    color: #fff;
    border-radius: 5px;
    font-size: 13px;
    margin-bottom: 6px; }
  .single-article__person .person-category-see-all {
    display: inline-block;
    font-size: 11px;
    line-height: 23px;
    margin-left: 17px; }
    .single-article__person .person-category-see-all .bold {
      font-weight: 700; }
  .single-article__person-contact {
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    border-top: 1px solid rgba(112, 112, 112, 0.1);
    margin-top: 7px;
    font-size: 15px; }
    .single-article__person-contact-el {
      max-width: 50%;
      flex: 0 0 50%;
      margin-bottom: 15px;
      display: flex; }
      @media (max-width: 576px) {
        .single-article__person-contact-el {
          max-width: 100%;
          flex: 0 0 100%; } }
      .single-article__person-contact-el a {
        display: flex; }
      .single-article__person-contact-el .image {
        max-width: 20px;
        width: 20px;
        display: flex;
        justify-content: center;
        margin-right: 14px; }
  .single-article__person-title {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 2px;
    padding-bottom: 11px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.1);
    margin-top: 36px; }
  .single-article__person .vote-el {
    display: flex; }
    .single-article__person .vote-el__left {
      margin-right: 16px; }
      .single-article__person .vote-el__left-image {
        padding: 13px;
        border: 1px solid #DDDDDD;
        border-radius: 9px;
        display: flex;
        justify-content: center;
        align-items: center; }
    .single-article__person .vote-el__right .name {
      font-size: 16px;
      font-weight: 700;
      margin-right: 29px; }
    .single-article__person .vote-el__right .date {
      font-size: 13px;
      color: #B7B7B7; }
      @media (max-width: 600px) {
        .single-article__person .vote-el__right .date {
          display: block; } }
    .single-article__person .vote-el__right .text {
      margin-top: 14px;
      font-size: 15px;
      line-height: 23px; }

.single-article__right {
  height: 100%; }
  .single-article__right-magazine {
    border-radius: 20px;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    padding: 29px 44px;
    z-index: 2;
    position: relative;
    background-color: #284666;
    color: #fff;
    max-width: 370px;
    margin: 0 auto 30px; }
    .single-article__right-magazine-top-title {
      font-size: 15px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 11px; }
    .single-article__right-magazine-description {
      font-size: 13px;
      opacity: 0.7;
      margin-bottom: 11px;
      text-align: center; }
    .single-article__right-magazine-image {
      margin-bottom: 17px; }
      .single-article__right-magazine-image img {
        max-width: 100%; }
    .single-article__right-magazine-btn {
      font-size: 16px;
      line-height: 21px;
      color: #fff;
      text-transform: uppercase;
      font-weight: 700;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      width: 100%;
      padding: 20px;
      text-align: center;
      border-radius: 10px; }

.search-btn__therapist {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  font-size: 15px;
  line-height: 25px;
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  background: linear-gradient(90deg, #f16655 0%, #fb3636 100%);
  border-radius: 14px;
  align-items: center;
  align-content: center;
  position: absolute;
  top: 9px;
  left: 0;
  color: #fff;
  height: 48px;
  white-space: nowrap; }
  .search-btn__therapist-wrapper {
    display: inline-block;
    position: relative;
    width: 243.3px;
    height: 48px; }
  .search-btn__therapist:hover {
    color: #fff; }
  .search-btn__therapist span {
    display: block;
    padding-top: 4px;
    position: relative;
    z-index: 3; }
  .search-btn__therapist img {
    margin-left: 20px;
    position: relative;
    z-index: 3; }

@media (min-width: 1440px) {
  .single-article .row--370 > .col-left {
    max-width: calc(100% - 430px);
    padding-right: 45px; } }

.calendary__top {
  padding-bottom: 171px; }

.calendary__list {
  margin-top: -150px; }
  .calendary__list-item {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    border-radius: 20px;
    padding: 28px 30px;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
    position: relative;
    margin-bottom: 40px; }
    .calendary__list-item-month {
      font-size: 22px;
      line-height: 21px;
      font-weight: 700;
      color: #fff;
      border-radius: 12px;
      margin-bottom: 27px;
      background: linear-gradient(to right, #274565 0%, #35b7c1 100%);
      display: inline-block;
      padding: 10px 26px;
      text-align: center;
      position: relative;
      z-index: 1; }
    .calendary__list-item-date {
      display: flex;
      position: absolute;
      left: 30px;
      top: 28px; }
      .calendary__list-item-date-day {
        font-size: 42px;
        line-height: 39px;
        font-weight: 700;
        margin-right: 8px; }
      .calendary__list-item-date-other-month {
        font-size: 15px;
        line-height: 18px;
        font-weight: 700; }
      .calendary__list-item-date-other-year {
        font-size: 11px;
        font-weight: 400; }
    .calendary__list-item-articles {
      max-width: 100%;
      width: 100%; }
  .calendary__list-article {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 750px) {
      .calendary__list-article {
        flex-wrap: wrap; } }
    .calendary__list-article-image {
      border-radius: 13px;
      margin-right: 38px;
      margin-top: 60px;
      max-width: 200px;
      width: 100%; }
      @media (max-width: 750px) {
        .calendary__list-article-image {
          max-width: 100%;
          margin-right: 0px;
          margin-bottom: 15px; } }
      .calendary__list-article-image img {
        display: block;
        border-radius: 13px; }
    @media (max-width: 750px) {
      .calendary__list-article-text {
        max-width: 100%;
        width: 100%; } }
    .calendary__list-article-text-title {
      font-size: 21px;
      line-height: 25px;
      font-weight: 700;
      margin-bottom: 15px; }
    .calendary__list-article-text-introtext {
      font-size: 15px;
      line-height: 23px; }

.calendary__category-title {
  font-size: 15px;
  line-height: 29px;
  font-weight: 700;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 27px;
  background: linear-gradient(to right, #274565 0%, #35b7c1 100%);
  display: inline-block;
  text-transform: uppercase;
  padding: 5px 10px 2px 10px; }

.kalendarium {
  background: #fff;
  padding: 20px 0;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  z-index: 1;
  position: relative; }
  .kalendarium .calendar-container {
    z-index: 0; }
    @media (max-width: 470px) {
      .kalendarium .calendar-container .gldp-kalendarium {
        max-width: 100% !important;
        width: 100% !important; } }
    .kalendarium .calendar-container .gldp-kalendarium .glCore {
      box-sizing: border-box;
      position: relative;
      float: left;
      padding: 0;
      margin: 0;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #000; }
      .kalendarium .calendar-container .gldp-kalendarium .glCore:nth-of-type(1) {
        background-color: #274565;
        border-top-left-radius: 14px;
        border-bottom-left-radius: 14px;
        margin-bottom: 13px;
        height: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 470px) {
          .kalendarium .calendar-container .gldp-kalendarium .glCore:nth-of-type(1) {
            max-width: 20% !important;
            width: 100% !important; } }
      .kalendarium .calendar-container .gldp-kalendarium .glCore:nth-of-type(2) {
        margin-bottom: 13px;
        font-size: 25px;
        line-height: 33px;
        font-weight: 700;
        height: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 470px) {
          .kalendarium .calendar-container .gldp-kalendarium .glCore:nth-of-type(2) {
            max-width: 60% !important;
            width: 100% !important; } }
      .kalendarium .calendar-container .gldp-kalendarium .glCore:nth-of-type(3) {
        background-color: #274565;
        border-top-right-radius: 14px;
        border-bottom-right-radius: 14px;
        margin-bottom: 13px;
        height: 60px !important;
        display: flex;
        justify-content: center;
        align-items: center; }
        @media (max-width: 470px) {
          .kalendarium .calendar-container .gldp-kalendarium .glCore:nth-of-type(3) {
            max-width: 20% !important;
            width: 100% !important; } }
      .kalendarium .calendar-container .gldp-kalendarium .glCore.dow {
        font-weight: 700;
        margin-bottom: 10px; }
    .kalendarium .calendar-container .gldp-kalendarium .title {
      font-size: 25px;
      color: #000;
      background-color: #274565;
      color: #fff; }
    .kalendarium .calendar-container .gldp-kalendarium .dow {
      color: #000;
      font-size: 23px;
      line-height: 31px; }
    .kalendarium .calendar-container .gldp-kalendarium .glCore.day.inday.event {
      cursor: pointer;
      font-weight: bold;
      color: #35B7C1;
      border: 2px solid #35B7C1;
      border-radius: 15px;
      transition: .5s all; }
      .kalendarium .calendar-container .gldp-kalendarium .glCore.day.inday.event:hover {
        background-color: #35B7C1;
        color: #fff; }
    .kalendarium .calendar-container .gldp-kalendarium .glCore.day, .kalendarium .calendar-container .gldp-kalendarium .glCore.glBorder.dow {
      width: 54px !important;
      height: 54px !important;
      margin: 3px !important;
      line-height: 54px !important; }
    .kalendarium .calendar-container .gldp-kalendarium .glCore.day.inday.selected {
      cursor: pointer;
      color: white;
      font-weight: bold;
      background-color: #35B7C1;
      border-radius: 15px; }
    .kalendarium .calendar-container .gldp-kalendarium .next-arrow, .kalendarium .calendar-container .gldp-kalendarium .prev-arrow {
      cursor: pointer;
      font-weight: 300;
      font-size: 20px;
      color: #fff;
      background-color: #274565; }
      .kalendarium .calendar-container .gldp-kalendarium .next-arrow img, .kalendarium .calendar-container .gldp-kalendarium .prev-arrow img {
        max-height: 20px; }

#search-form {
  color: #1A1B1C;
  padding-bottom: 92px; }
  #search-form .searched-expression {
    margin-bottom: 30px;
    font-size: 15px;
    line-height: 21px;
    text-transform: uppercase;
    color: #1A1B1C; }
  #search-form .tm-search-form__counter-value {
    font-size: 15px;
    line-height: 21px;
    margin-top: 30px; }
  #search-form .tm-search-form__form {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 1008px;
    width: 100%; }
    @media (max-width: 768px) {
      #search-form .tm-search-form__form {
        flex-wrap: wrap; } }
    #search-form .tm-search-form__form .search-input {
      width: 100%; }
      #search-form .tm-search-form__form .search-input input {
        background-color: transparent;
        font-size: 40px;
        line-height: 49px;
        font-weight: 700;
        color: #1A1B1C;
        padding: 43px 243px 37px 43px;
        border-radius: 49px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        width: 100%;
        outline: none; }
        @media (max-width: 768px) {
          #search-form .tm-search-form__form .search-input input {
            padding: 15px 30px;
            font-size: 30px;
            line-height: 39px;
            margin-bottom: 20px; } }
    #search-form .tm-search-form__form .tm-search-form__submit {
      position: absolute;
      right: 15px;
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      color: #fff;
      border-radius: 26px;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      border: none;
      padding: 28px 67px;
      text-transform: uppercase; }
      @media (max-width: 768px) {
        #search-form .tm-search-form__form .tm-search-form__submit {
          padding: 15px 47px;
          position: relative;
          margin: 0 auto; } }

#search-results {
  margin-top: 45px; }
  #search-results .search-result-empty {
    margin-bottom: 50px;
    text-align: center; }
    #search-results .search-result-empty__image img {
      margin: 0 auto;
      display: block;
      max-width: 600px;
      width: 100%; }
    #search-results .search-result-empty__title {
      font-size: 35px;
      font-weight: 700;
      margin-bottom: 20px; }
  #search-results .search-item {
    margin-bottom: 30px; }
    @media (max-width: 575px) {
      #search-results .search-item .image {
        margin-bottom: 20px; } }
    #search-results .search-item .image img {
      border-radius: 15px; }
    #search-results .search-item .image--category {
      display: flex;
      flex-wrap: wrap;
      font-size: 20px;
      font-weight: 700;
      padding-bottom: 65%;
      position: relative; }
      #search-results .search-item .image--category a {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        position: absolute;
        background-color: #fff;
        box-shadow: 0 0 90px rgba(0, 0, 0, 0.16);
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 15px; }
    #search-results .search-item .category {
      font-size: 11px;
      line-height: 13px;
      font-weight: 700;
      padding: 4px 9px;
      background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
      border-radius: 5px;
      display: block;
      margin-bottom: 10px;
      text-transform: uppercase;
      color: #fff; }
      #search-results .search-item .category--guide {
        background: #35B7C1; }
    #search-results .search-item .link {
      display: flex;
      align-items: center;
      align-content: center;
      flex-wrap: wrap;
      height: 100%; }
      #search-results .search-item .link .title {
        font-size: 22px;
        line-height: 25px;
        font-weight: 700;
        margin-bottom: 15px;
        width: 100%; }
      #search-results .search-item .link .introtext {
        font-size: 15px;
        line-height: 23px; }
      #search-results .search-item .link__text {
        font-size: 15px;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: underline;
        color: #FB3636;
        display: block;
        width: 100%;
        margin-top: 10px; }

.results__wrapper {
  background-color: #284666;
  border-radius: 20px;
  color: #fff;
  padding: 38px 28px;
  margin-bottom: 30px; }

.results__title {
  font-size: 15px;
  line-height: 13px;
  font-weight: 700;
  text-transform: uppercase;
  padding-bottom: 20px;
  border-bottom: 3px solid #FB3636;
  margin-bottom: 25px; }

.results__list {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 17px;
  line-height: 21px; }
  .results__list-item {
    transition: .3s all ease-in-out;
    padding: 11px 0; }
    .results__list-item.active {
      text-decoration: underline; }
      .results__list-item.active::before {
        opacity: 1;
        transform: translateX(0); }
    .results__list-item:hover {
      text-decoration: underline; }
      .results__list-item:hover::before {
        opacity: 1;
        transform: translateX(0); }
    .results__list-item::before {
      content: '';
      background-image: url("/templates/main/images/svg/search-arrow.svg");
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      width: 13px;
      height: 10px;
      display: inline-block;
      margin-right: 12px;
      opacity: 0;
      transform: translateX(-5px);
      transition: .3s all ease-in-out; }

.header__switcher {
  margin-left: 34px; }
  @media (max-width: 450px) {
    .header__switcher {
      margin-left: 15px; } }
  .header__switcher-wrapper {
    width: 40px;
    height: 24px;
    background-color: #EBEBEB;
    border-radius: 294px;
    position: relative;
    cursor: pointer;
    transition: .3s all ease-in-out; }
    .header__switcher-wrapper::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      right: 20px;
      left: 4px;
      border-radius: 50%;
      background-color: #1A1B1C;
      width: 16px;
      height: 16px;
      transition: .3s all ease-in-out; }

body[data-dark-mode="true"] {
  background-color: #0e222e;
  color: #fff; }
  body[data-dark-mode="true"] .header__wrap::before {
    display: none; }
  body[data-dark-mode="true"] .find-therapist {
    background-color: #284666; }
  body[data-dark-mode="true"] .problem-list-hp {
    background-color: #284666; }
  body[data-dark-mode="true"] .problem-list-hp__link {
    border: 2px solid #fff; }
  body[data-dark-mode="true"] .last-added {
    background-color: #284666; }
  body[data-dark-mode="true"] .last-added__list {
    background-color: #284666; }
  body[data-dark-mode="true"] .swiper-hp-top__link {
    background-color: #002B44;
    color: #fff; }
  body[data-dark-mode="true"] .day-bottom {
    color: #fff; }
  body[data-dark-mode="true"] .blue-slider {
    background-color: #284666; }
  body[data-dark-mode="true"] .opinions__item-author span {
    color: #fff; }
  body[data-dark-mode="true"] .newsletter-module__btn {
    color: #212529; }
  body[data-dark-mode="true"] .three-articles__big-description {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .exercises {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .exercises__top-more {
    border: 2px solid #fff; }
  body[data-dark-mode="true"] .exercises__el {
    background-color: rgba(255, 255, 255, 0.1); }
  body[data-dark-mode="true"] .our-guide--slider .our-guide__module-title .our-guide__module-title-btn {
    border: 2px solid #fff; }
  body[data-dark-mode="true"] .our-magazines {
    background-color: #284666; }
  body[data-dark-mode="true"] #sticky-menu .sticky__bottom {
    background-color: #0e222e; }
  body[data-dark-mode="true"] #sticky-menu .sticky__burger span {
    background-color: #fff; }
  body[data-dark-mode="true"] .sticky__search svg circle, body[data-dark-mode="true"] .sticky__search svg path {
    stroke: #fff; }
  body[data-dark-mode="true"] #sticky-menu .sticky__menu > ul > li > a {
    color: #fff; }
  body[data-dark-mode="true"] .sticky__logo #Group_135 path, body[data-dark-mode="true"] .burger-menu__logo #Group_135 path {
    fill: #fff; }
  body[data-dark-mode="true"] .sticky__logo #cross #Path_4 path, body[data-dark-mode="true"] .sticky__logo #cross #Path_3 path, body[data-dark-mode="true"] .burger-menu__logo #cross #Path_4 path, body[data-dark-mode="true"] .burger-menu__logo #cross #Path_3 path {
    fill: #fff; }
  body[data-dark-mode="true"] .reports__title {
    color: #fff; }
  body[data-dark-mode="true"] .magazines-list {
    color: #fff; }
  body[data-dark-mode="true"] .magazine-page__header h1 {
    color: #fff; }
  body[data-dark-mode="true"] .magazine-list__year-item {
    color: #fff; }
  body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-button-prev, body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-button-next {
    color: #fff; }
  body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-button-prev svg path, body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-button-next svg path {
    stroke: #fff; }
  body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-button-prev::after, body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-button-next::after {
    border-bottom: 1px dashed #fff; }
  body[data-dark-mode="true"] .magazine-single__background {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .magazine-single__top {
    background-color: #0e222e;
    color: #fff; }
  body[data-dark-mode="true"] .magazine-single__top {
    color: #fff; }
  body[data-dark-mode="true"] .header__new.header.header-article {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .swiper-container.swiper-magazine .swiper-nav-wrap::after {
    background-color: #fff; }
  body[data-dark-mode="true"] .article-list__header {
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__top {
    color: #fff; }
  body[data-dark-mode="true"] .single-article__top-text {
    color: #fff; }
  body[data-dark-mode="true"] .single-article__top-text-time-right-tag {
    color: #fff; }
  body[data-dark-mode="true"] .contact__top-title {
    color: #fff; }
  body[data-dark-mode="true"] .article-list__top .breadcrumb {
    color: #fff; }
  body[data-dark-mode="true"] .our-guide__menu ul {
    color: #fff; }
  body[data-dark-mode="true"] .pagination {
    color: #fff; }
  body[data-dark-mode="true"] .pagination > .active {
    color: #fff; }
  body[data-dark-mode="true"] .pagination > .inactive {
    background-color: transparent; }
  body[data-dark-mode="true"] .hp-info-doctor {
    background-color: #143855; }
  body[data-dark-mode="true"] .hp-info-doctor__image {
    background-color: #284666; }
    body[data-dark-mode="true"] .hp-info-doctor__image svg line, body[data-dark-mode="true"] .hp-info-doctor__image svg rect, body[data-dark-mode="true"] .hp-info-doctor__image svg path {
      stroke: #fff; }
  body[data-dark-mode="true"] .therapists-hp__title {
    border-color: rgba(255, 255, 255, 0.1); }
  body[data-dark-mode="true"] .firm-item {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-article__institution-el {
    background-color: #284666; }
  body[data-dark-mode="true"] .hp-info__item-introtext {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .btn-border__content {
    background-color: #002B44; }
    body[data-dark-mode="true"] .btn-border__content svg path {
      stroke: #fff; }
  body[data-dark-mode="true"] .main__top {
    background-color: #284666; }
  body[data-dark-mode="true"] .magazine-page__bottom-nav .swiper-magazine-button-prev {
    border: 3px solid rgba(201, 201, 201, 0.2); }
  body[data-dark-mode="true"] .magazine-page__bottom-nav .swiper-magazine-button-next {
    border: 3px solid rgba(201, 201, 201, 0.2); }
  body[data-dark-mode="true"] .category-description {
    color: #fff; }
  body[data-dark-mode="true"] .category-description__text {
    color: #fff; }
  body[data-dark-mode="true"] .category-description__image::after {
    background: linear-gradient(to right, #0e222e 0, rgba(255, 255, 255, 0) 100%); }
  body[data-dark-mode="true"] .category-menu {
    color: #fff; }
  body[data-dark-mode="true"] .calculator-single__top.nmc {
    background-image: linear-gradient(to right, #0e222e 50%, rgba(255, 255, 255, 0) 100%), url("/templates/main/images/nmc-background.jpg"); }
  body[data-dark-mode="true"] .treatment__title {
    color: #fff; }
  body[data-dark-mode="true"] .treatment__menu ul {
    color: #fff; }
  body[data-dark-mode="true"] .bg-blue .breadcrumb {
    color: #fff; }
  body[data-dark-mode="true"] .bg-blue__return {
    color: #fff; }
    body[data-dark-mode="true"] .bg-blue__return svg path {
      stroke: #fff; }
  body[data-dark-mode="true"] .alphabet-list__title-text {
    color: #fff; }
  body[data-dark-mode="true"] .health-menu--column {
    color: #fff; }
  body[data-dark-mode="true"] .header__new.header.header-subpages .header__menu > ul li.active.parent a, body[data-dark-mode="true"] .header__new.header.header-subpages .header__menu > ul li.current.parent a, body[data-dark-mode="true"] .header__new.header.header-subpages .header__menu > ul li.alias-parent-active.parent a {
    color: #fff; }
  body[data-dark-mode="true"] .magazine-single__article-introtext {
    color: rgba(255, 255, 255, 0.7); }
    body[data-dark-mode="true"] .magazine-single__article-introtext:hover {
      color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .burger-menu__wrapper {
    background-color: #284666; }
  body[data-dark-mode="true"] .calendar {
    background-color: #284666; }
  body[data-dark-mode="true"] .calendary__list-item {
    background-color: #284666; }
  body[data-dark-mode="true"] .kalendarium .calendar-container .gldp-kalendarium .dow, body[data-dark-mode="true"] .kalendarium .calendar-container .gldp-kalendarium .glCore {
    color: #fff; }
    body[data-dark-mode="true"] .kalendarium .calendar-container .gldp-kalendarium .dow.day.inday.event, body[data-dark-mode="true"] .kalendarium .calendar-container .gldp-kalendarium .glCore.day.inday.event {
      color: #fff; }
  body[data-dark-mode="true"] .magazine-single__introtext {
    color: rgba(255, 255, 255, 0.7); }
    body[data-dark-mode="true"] .magazine-single__introtext-title {
      color: #fff; }
  body[data-dark-mode="true"] .magazine-single__article:hover .magazine-single__article-introtext {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .single-therapist__person {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-therapist__place {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-therapist__info {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-therapist__experience {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-therapist__reviews {
    background-color: #284666; }
  body[data-dark-mode="true"] .therapist-region .swiper-slide .last-added__item {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-therapist__person-btns-el {
    color: rgba(255, 255, 255, 0.4); }
  body[data-dark-mode="true"] .single-therapist__person-btns-el.active {
    color: #fff; }
  body[data-dark-mode="true"] .single-therapist__info-text {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .single-therapist__place-top-map svg #Rectangle_1953 {
    stroke: #fff; }
  body[data-dark-mode="true"] .single-therapist__place-top-map svg #Path_1474 {
    fill: #fff; }
  body[data-dark-mode="true"] .single-therapist__place-top-map svg #map-pin path, body[data-dark-mode="true"] .single-therapist__place-top-map svg #map-pin circle {
    stroke: #fff; }
  body[data-dark-mode="true"] .calculator-single__bottom-fulltext .pattern {
    background-color: #284666; }
  body[data-dark-mode="true"] .calculator-single__bottom-fulltext p {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .bibliography {
    padding-top: 0px; }
  body[data-dark-mode="true"] .bibliography .bibliography__title {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-article__fulltext .static.bibliografia.p {
    background-color: #284666; }
  body[data-dark-mode="true"] .single-article__fulltext .bibliografia > p {
    background-color: #284666; }
  body[data-dark-mode="true"] .calculator-single__important {
    background-color: #284666; }
  body[data-dark-mode="true"] .calculator-single__grey {
    background-color: #284666; }
  body[data-dark-mode="true"] .calculator-single__important-top {
    color: #fff; }
  body[data-dark-mode="true"] .article-list__item {
    background-color: #284666; }
  body[data-dark-mode="true"] .article-list__item-title {
    color: #fff; }
  body[data-dark-mode="true"] .single-therapist__person-btns-el::before {
    background-color: #fff; }
  body[data-dark-mode="true"] .e-commerce--article-transparent .e-commerce__title span {
    background-color: #0e222e;
    color: #fff !important; }
  body[data-dark-mode="true"] .report-single .bg-blue__image::after {
    background: linear-gradient(to right, #0e222e 0, rgba(255, 255, 255, 0) 100%); }
  body[data-dark-mode="true"] .ramka1, body[data-dark-mode="true"] .ramka2, body[data-dark-mode="true"] .ramka3, body[data-dark-mode="true"] .ramka4, body[data-dark-mode="true"] .ramka5 {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .rules__text {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .firm-list__map-btn--right {
    color: #fff; }
  body[data-dark-mode="true"] .firm-list__map-btn--right::after {
    display: none; }
  body[data-dark-mode="true"] .firm-list__map-btn--right > img {
    opacity: 0.4; }
  body[data-dark-mode="true"] .single-therapist::before {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .single-therapist::after {
    display: none; }
  body[data-dark-mode="true"] .rules__top-title {
    color: #fff; }
  body[data-dark-mode="true"] .article-list__category {
    color: #fff; }
  body[data-dark-mode="true"] .city-list {
    color: #fff; }
  body[data-dark-mode="true"] .report-single__title {
    color: #fff; }
  body[data-dark-mode="true"] .single-article__top {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .single-therapist__breadcrumb {
    color: #fff; }
  body[data-dark-mode="true"] .report-single__introtext {
    color: #fff; }
  body[data-dark-mode="true"] .e-commerce__item-link {
    color: #fff !important; }
  body[data-dark-mode="true"] .single-article__fulltext {
    color: rgba(255, 255, 255, 0.7); }
    body[data-dark-mode="true"] .single-article__fulltext h2, body[data-dark-mode="true"] .single-article__fulltext h3 {
      color: #fff; }
    body[data-dark-mode="true"] .single-article__fulltext p, body[data-dark-mode="true"] .single-article__fulltext span {
      color: rgba(255, 255, 255, 0.7) !important; }
  body[data-dark-mode="true"] .more-in-section__link .more-in-section__title {
    color: #fff; }
  body[data-dark-mode="true"] .more-in-section__link .more-in-section__description {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .article-tags__list-el {
    color: #fff; }
  body[data-dark-mode="true"] .spis-tresci {
    background-color: #284666; }
    body[data-dark-mode="true"] .spis-tresci .spis-tresci__bottom {
      color: #fff !important; }
    body[data-dark-mode="true"] .spis-tresci .spis-tresci__title {
      color: #fff; }
  body[data-dark-mode="true"] .single-article__fulltext .spis-tresci__bottom-list-el a {
    color: #fff !important; }
  body[data-dark-mode="true"] .article-tags__list li a {
    color: #fff !important; }
  body[data-dark-mode="true"] .author-more__wrap {
    background-color: #284666; }
  body[data-dark-mode="true"] .author-more__btn {
    color: #fff !important; }
  body[data-dark-mode="true"] .single-article__holistic {
    background-color: #284666; }
  body[data-dark-mode="true"] .e-commerce--column {
    background-color: #284666; }
    body[data-dark-mode="true"] .e-commerce--column .e-commerce__title span {
      background-color: #284666; }
  body[data-dark-mode="true"] .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text {
    color: #fff; }
  body[data-dark-mode="true"] .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-description {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .recommended-col .articles-large-top-slider .swiper-hp-top .swiper-wrapper .swiper-slide .small-article__text-tags-el {
    border-color: rgba(255, 255, 255, 0.2); }
  body[data-dark-mode="true"] .header__switcher-wrapper {
    background-color: #284666; }
    body[data-dark-mode="true"] .header__switcher-wrapper::after {
      left: 20px;
      background-color: #fff; }
  body[data-dark-mode="true"] .header__new {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .header__new::after {
    background: linear-gradient(to bottom, #000000 0, transparent 80%);
    opacity: .09; }
  body[data-dark-mode="true"] .header__new.header.header-subpages .header__menu > ul > li > a {
    color: #fff; }
  body[data-dark-mode="true"] .header__new .header__search svg path, body[data-dark-mode="true"] .header__new .header__search svg circle {
    stroke: #fff; }
  body[data-dark-mode="true"] .header__new .header__burger span {
    background-color: #fff; }
  body[data-dark-mode="true"] .alphabet-list__top {
    background-color: #0e222e;
    color: #fff; }
  body[data-dark-mode="true"] #search-form .searched-expression {
    color: #fff; }
  body[data-dark-mode="true"] #search-form {
    color: #fff; }
  body[data-dark-mode="true"] #search-form .tm-search-form__form .search-input input {
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.2); }
  body[data-dark-mode="true"] .calculator-single__top {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .single-therapist__therapists {
    background-color: #284666; }
  body[data-dark-mode="true"] .articles-list__top {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .alphabet-list__letter {
    background-color: #0d202b;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.2); }
  body[data-dark-mode="true"] .e-commerce--article {
    background-color: #284666; }
  body[data-dark-mode="true"] .e-commerce--article .e-commerce__title span {
    background-color: #284666; }
  body[data-dark-mode="true"] #return-top {
    background-color: #284666; }
    body[data-dark-mode="true"] #return-top svg path {
      fill: #fff; }
  body[data-dark-mode="true"] .alphabet-list__item-title span {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .waitbox__wrapper {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .header__new.header.header-subpages .header__logo svg #Path_1479 {
    fill: #fff; }
  body[data-dark-mode="true"] .header__new.header.header-subpages .header__logo svg #Path_3 {
    stroke: #fff; }
  body[data-dark-mode="true"] .header__new.header.header-subpages .header__logo svg #Path_4 {
    stroke: #fff; }
  body[data-dark-mode="true"] .contact__form {
    background-color: #143855; }
  body[data-dark-mode="true"] .contact__info {
    background-color: #143855; }
  body[data-dark-mode="true"] .contact__small-title {
    color: #fff; }
  body[data-dark-mode="true"] .top-menu--new {
    background-color: #0e222e; }
  body[data-dark-mode="true"] .top-menu__link {
    color: #fff; }
  body[data-dark-mode="true"] .contact__name input, body[data-dark-mode="true"] .contact__email input, body[data-dark-mode="true"] .contact__form textarea {
    background-color: #284666;
    color: #fff;
    border: none; }
  body[data-dark-mode="true"] .questionnaire__wrap {
    background-color: #143855; }
  body[data-dark-mode="true"] .questionnaire__title {
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__description {
    color: rgba(255, 255, 255, 0.7); }
  body[data-dark-mode="true"] .questionnaire__input-wrap--file {
    background-color: #284666; }
  body[data-dark-mode="true"] .questionnaire__input-wrap--large .questionnaire__form-input--text {
    background-color: #284666;
    color: #fff;
    border: none; }
  body[data-dark-mode="true"] .questionnaire__input-wrap--large .questionnaire__input-placeholder {
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__input-wrap--large {
    background-color: #284666; }
  body[data-dark-mode="true"] .questionnaire #jform_degree {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__input-wrap--select::after {
    background-color: #284666;
    background-image: url("/templates/main/images/svg/down-white.svg"); }
  body[data-dark-mode="true"] .questionnaire__radio-item {
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__radio-item label::before {
    background-color: #284666; }
  body[data-dark-mode="true"] .questionnaire__radio-item label::after {
    width: 12px;
    height: 12px;
    right: calc(100% + 5px); }
  body[data-dark-mode="true"] .questionnaire .inputs-inside input {
    background-color: #284666;
    border-color: #fff; }
  body[data-dark-mode="true"] .questionnaire__radio-item .label {
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__form-textarea {
    background-color: #284666;
    color: #fff;
    border: none; }
  body[data-dark-mode="true"] .questionnaire__form-fieldset--location .questionnaire__form-left {
    background-color: #284666; }
  body[data-dark-mode="true"] .questionnaire__input-wrap--small .questionnaire__form-input--text {
    background-color: #284666;
    color: #fff;
    border: none; }
  body[data-dark-mode="true"] .questionnaire__input-wrap--small {
    background-color: #284666;
    border: 1px solid rgba(219, 219, 219, 0.3);
    border-radius: 0; }
  body[data-dark-mode="true"] .questionnaire__location-btn {
    background-color: #284666; }
    body[data-dark-mode="true"] .questionnaire__location-btn svg circle, body[data-dark-mode="true"] .questionnaire__location-btn svg path {
      stroke: #fff; }
  body[data-dark-mode="true"] .questionnaire__file-btn svg circle, body[data-dark-mode="true"] .questionnaire__file-btn svg path {
    stroke: #fff; }
  body[data-dark-mode="true"] .questionnaire__category {
    color: #fff; }
  body[data-dark-mode="true"] .questionnaire__form-select {
    background-color: #284666;
    color: #fff;
    border: none; }
  body[data-dark-mode="true"] .firm-list__tools--select .main__select-wrap .cs-title {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .main__select-wrap .cs-select::after {
    background-color: #284666; }
  body[data-dark-mode="true"] .main__select-wrap .cs-btn::after {
    background-image: url("/templates/main/images/svg/chevron-down.svg"); }
  body[data-dark-mode="true"] .firm-list__tools--select .main__select-wrap .cs-select .cs-title--finder span {
    color: #fff; }
  body[data-dark-mode="true"] .main__select-wrap .cs-list-wrap {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .cs-select .cs-list-wrap .cs-selected-list {
    color: #fff; }
  body[data-dark-mode="true"] .cs-select .cs-list-wrap .cs-selected-list a {
    color: #fff; }
  body[data-dark-mode="true"] .cs-select .cs-title--finder .cs-form::after {
    background-image: url("/templates/main/images/svg/search-icon.svg"); }
  body[data-dark-mode="true"] .cs-select .cs-title--finder .cs-form input {
    color: #fff;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  body[data-dark-mode="true"] .cs-select .cs-list-wrap .cs-result-list {
    color: #fff; }
  body[data-dark-mode="true"] .firm-list__select .cs-title {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .firm-list__select .cs-btn::after {
    background-image: url("/templates/main/images/svg/chevron-down.svg"); }
  body[data-dark-mode="true"] .firm-list__select .cs-list-wrap {
    background-color: #284666; }
  body[data-dark-mode="true"] .firm-list__select .cs-list {
    color: #fff; }
  body[data-dark-mode="true"] .firm-list__tools--select .main__select-wrap .cs-select.cs-select--finder.open .cs-title {
    background: transparent; }
  body[data-dark-mode="true"] .therapist-description {
    color: #fff; }
  body[data-dark-mode="true"] .firm-list__mobile-select {
    background-color: #284666;
    color: #fff; }
    body[data-dark-mode="true"] .firm-list__mobile-select svg path {
      stroke: #fff; }
  body[data-dark-mode="true"] .firm-list__tools--select .firm-list__mobile-wrapper {
    background-color: #284666; }
    @media (min-width: 1440px) {
      body[data-dark-mode="true"] .firm-list__tools--select .firm-list__mobile-wrapper {
        background-color: transparent; } }
  body[data-dark-mode="true"] .firm-list__tools--select .firm-list__select {
    border: 2px solid rgba(255, 255, 255, 0.4); }
    @media (min-width: 1440px) {
      body[data-dark-mode="true"] .firm-list__tools--select .firm-list__select {
        border: none; } }
  body[data-dark-mode="true"] .firm-list__tools--select #filter-type {
    border: 2px solid rgba(255, 255, 255, 0.4); }
    @media (min-width: 1440px) {
      body[data-dark-mode="true"] .firm-list__tools--select #filter-type {
        border: none; } }
  body[data-dark-mode="true"] .firm-list__map-btn {
    background-color: #284666;
    color: #fff; }
    body[data-dark-mode="true"] .firm-list__map-btn svg path, body[data-dark-mode="true"] .firm-list__map-btn svg circle {
      stroke: #fff; }
  body[data-dark-mode="true"] .article-list__article-select .cs-title {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .article-list__article-select .cs-btn::after {
    background-image: url("/templates/main/images/svg/chevron-down.svg"); }
  body[data-dark-mode="true"] .article-list__article-select .cs-select .cs-list-wrap {
    background-color: #284666; }
  body[data-dark-mode="true"] .article-list__article-select .cs-list {
    color: #fff; }
  body[data-dark-mode="true"].form-com_avtnewsletter .header__new {
    background-color: #0E222E; }
  body[data-dark-mode="true"].form-com_avtnewsletter .top-menu--new {
    background-color: #0E222E; }
  body[data-dark-mode="true"].form-com_avtnewsletter .header__new::after {
    background: linear-gradient(to bottom, rgba(14, 34, 46, 0.65) 0%, rgba(14, 34, 46, 0) 100%); }
  body[data-dark-mode="true"].form-com_avtnewsletter .top-menu::before {
    background: linear-gradient(to bottom, rgba(14, 34, 46, 0.65) 0%, rgba(14, 34, 46, 0) 100%); }
  body[data-dark-mode="true"].form-com_avtnewsletter .newsletter-main {
    background-color: #0E222E; }
  body[data-dark-mode="true"].form-com_avtnewsletter .newsletter-main::after {
    background: #0E222E; }
  body[data-dark-mode="true"].form-com_avtnewsletter .newsletter__gift-papers {
    background-color: #0E222E; }
  body[data-dark-mode="true"] .main__top::before {
    background-color: #284666; }
  body[data-dark-mode="true"] .header.header-subpages .header__menu > ul li.current a::after {
    background-color: #FFFFFF; }
  body[data-dark-mode="true"] .recommended-articles-right {
    background-color: #284666;
    color: #fff; }
  body[data-dark-mode="true"] .expert-list__list .row--370 .col-left {
    background-color: #284666;
    color: #fff; }

.questionnaire__file-btn svg {
  margin: 0 auto;
  display: block;
  margin-bottom: 12px; }

.questionnaire__location-btn svg {
  margin: 0 auto;
  display: block;
  margin-bottom: 12px; }
