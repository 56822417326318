@import 'vendors/bootstrap';
@import "vendors/swiper";
@import "vendors/simplebar";

@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
@import 'abstracts/placeholders';

@import 'base/reset';
@import 'base/typography';
@import "base/classes";

@import "layout/grid";

@import "pages/questionnaire";
@import "pages/error";

@import 'components/modules/OCL-connected';

@import 'components/breadcrumb';
@import 'components/articles';

@import 'layout/pagination';

@import 'pages/contact';
@import 'pages/rules';
@import 'pages/single-therapist';
@import 'pages/add-review';
@import "pages/shops";


@import "layout/buttons";
@import "layout/header";
@import "layout/footer";
@import "layout/burger-menu";

@import "components/fb-popup";
@import "components/main-top";
@import "components/firm-item";
@import "components/article-img-top";
@import "components/page-header";
@import "components/grid-article";
@import "components/gmap";
@import "components/return-top";
@import "components/animated-logo";
@import "components/author-more";
@import "components/article-select";

@import "components/modules/hp-info";
@import "components/modules/swiper-problems";
@import "components/modules/hp-info-doctor";
@import "components/modules/our-guide";
@import "components/modules/swiper-spec";
@import "components/modules/therapists-hp";
@import "components/modules/e-commerce";
@import "components/modules/reports";
@import "components/modules/city-list";
@import "components/modules/subscription-neboa";
@import "components/modules/job-offer";
@import "components/modules/select-menu";

@import "pages/article-list";
@import "pages/firm-list";
@import "pages/our-guide";


// AO


@import "components/modules/blue-slider";
@import "components/modules/our-guide-slider";
@import "components/modules/articles-list";
@import "components/modules/last-added";
@import "components/modules/our-magazines";
@import "components/modules/opinions";
@import "components/modules/recommended-right";
@import "components/modules/category-description";
@import "components/modules/category-menu";
@import "components/modules/therapist-list-articles";
@import "components/modules/archive-papers";
@import "components/modules/find-therapist";
@import "components/modules/problem-list-hp";
@import "components/modules/header-module";
@import "components/modules/therapist-region";
@import "components/modules/slider-read-more";
@import "components/modules/waitbox";
@import "components/modules/articles-large-top";
@import "components/modules/specialist-sticky";
@import "components/modules/auraherbals";
@import "components/modules/magazines-top";
@import "components/modules/important-today";

@import "components/bg-blue";
@import "components/article-white";
@import "components/finder-modal";
@import "components/custom-select";

@import "pages/problems";
@import "pages/treatment";
@import "pages/reports";
@import "pages/report-single";
@import "pages/magazines-list";
@import "pages/magazine";
@import "pages/magazine-single";
@import "pages/alphabet-list";
@import "pages/expert-form";
@import "pages/expert-list";
@import "pages/expert-single";
@import "pages/vaccine";
@import "pages/newsletter-new";

@import "layout/top-menu";
@import "layout/therapist-menu";
@import "layout/sticky-menu";

// MP

.col-md-4-5 {
    @media(min-width:768px) {
        flex: 0 0 34.9999%;
        max-width: 34.9999%;
    }
}
.col-xl-4-5 {
    @media(min-width:1200px) {
        flex: 0 0 34.9999%;
        max-width: 34.9999%;
    }
}
.col-md-3-5 {
    @media(min-width:768px) {
        flex: 0 0 29.9999%;
        max-width: 29.9999%;
    }
}

.col-xl-3-5 {
    @media(min-width:1200px) {
        flex: 0 0 29.9999%;
        max-width: 29.9999%;
    }
}

.last-added.single {
    border-radius: 15px;
}

#practice-year input#jform_practice {
    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    -moz-appearance:textfield;
}

@import 'components/modules/swiper-knowledge';
@import 'components/modules/health-menu';
@import 'components/modules/exercises';
@import 'components/modules/calculators';
@import 'components/modules/newsletter';
@import 'components/modules/important-topics';
@import 'components/modules/our-guide-articles';
@import 'components/modules/tags-article';
@import 'components/modules/more-in-section';
@import 'components/modules/recommended-articles';
@import 'components/modules/avt-popup';
@import 'components/modules/OCL-connected';
@import 'components/modules/calendar-slider';
@import 'components/modules/sticky-products';

@import 'pages/calculator';
@import 'pages/newsletter';
@import 'pages/single-article';
@import 'pages/calendary';

@import 'layout/search';

@import 'layout/dark-mode';
