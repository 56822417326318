.important-today {
    background-color: rgba(53,183,193,0.2);
    padding: 40px 0 55px;
    margin-bottom: 65px;
    margin-top: -30px;
    &__container {
        max-width: 1350px;
        margin: 0 auto;
        padding: 0 15px;
        @media(min-width: 440px) {
            padding: 0 45px;
        }
        @media(min-width: 576px) {
            padding: 0 90px;
        }
    }
    &__article {
        &--main {
            display: block;
            padding: 0 15px;
            width: 100%;
            margin-bottom: 45px;
            @media(min-width: 1025px) {
                width: 50%;
                margin-bottom: 0;
            }
            @media(min-width: 1200px) {
                width: 62%;
            }
            .important-today__article {
                &-img {
                    border-radius: 20px;
                    overflow: hidden;
                    margin-bottom: 25px;
                }
                &-title {
                    font-weight: 700;
                    margin-bottom: 15px;
                    font-size: 24px;
                    line-height: 30px;
                    @media(min-width: 1200px) {
                        font-size: 28px;
                        line-height: 35px;
                    }
                }
                &-text {
                    font-size: 15px;
                    line-height: 23px;
                }
            }
        }
        &--other {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            .important-today__article {
                &-img {
                    width: 31.5%;
                    @media(min-width: 440px) and (max-width: 1024px) {
                        width: 142px;
                    }
                    img {
                        border-radius: 20px;
                    }
                }
                &-wrap {
                    padding-left: 25px;
                    width: 68.5%;
                    @media(min-width: 440px) and (max-width: 1024px) {
                        width: calc(100% - 142px);
                    }
                }
                &-title {
                    font-weight: 700;
                    margin-bottom: 15px;
                    font-size: 17px;
                    line-height: 19px;
                    @media(min-width: 576px) {
                        font-size: 19px;
                        line-height: 21px;
                    }
                }
                &-text {
                    font-size: 15px;
                    line-height: 23px;
                }
            }
        }
    }
    &__articles {
        padding: 0 15px;
        width: 100%;
        @media(min-width: 1025px) {
            width: 50%;
        }
        @media(min-width: 1200px) {
            width: 38%;
        }
    }
    &__title {
        font-size: 20px;
        line-height: normal;
        font-weight: 400;
        letter-spacing: 0.04em;
        padding-bottom: 14px;
        border-bottom: 2px solid #FB3636;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
}
