.newsletter-module {
    background-color: #002B44;
    border-radius: 20px;
    padding: 45px 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    &--red {
        &.newsletter-module {
            background: linear-gradient(135deg,#f16655 0,#fb3636 100%);
            max-width: 370px;
            margin: 0 auto;
            width: 100%;
            position: relative;
            padding: 0;
            overflow: hidden;
            z-index: 9;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 0 30px 30px;
            height: auto;
            &:before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                background-image: url("/templates/main/images/static/newsletter-papers.png");
                background-position: center right;
                background-repeat: no-repeat;
                z-index: 3;
                pointer-events: none;
            }
            &:after {
                content: "";
                position: absolute;
                bottom: -6%;
                left: 0%;
                width: 200%;
                height: 200%;
                transform: rotate(45deg);
                background-color: rgba(255,255,255,0.3);
                transform-origin: 0 100%;
                pointer-events: none;
            }
        }
        .newsletter-module {
            &__top {
                text-align: center;
                margin-bottom: 20px;
                width: 100%;
                position: relative;
                z-index: 9;
            }
            &__text {
                max-width: 160px;
                width: 100%;
                color: $color-white;
                font-size: 15px;
                font-weight: 500;
                position: relative;
                z-index: 9;
                span {
                    text-decoration: underline;
                }
                strong {
                    display: block;
                    width: 100%;
                    text-transform: uppercase;
                    font-size: 23px;
                    font-weight: 700;
                    letter-spacing: 0.016em;
                    margin-top: 14px;
                    margin-bottom: 18px;
                }
            }
            &__btn {
                position: relative;
                z-index: 9;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                align-self: flex-end;
                justify-self: flex-end;
                margin-top: auto;
                width: 100%;
                min-height: 66px;
                background-color: $color-white;
                border-radius: 11px;
                box-shadow: 0 20px 30px rgba(0,0,0,0.16);
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 0.05em;
                font-weight: 700;
            }
        }
    }
}


.col-right {
    .newsletter-module--red {
        margin-bottom: 30px;
    }
}
