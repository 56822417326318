[id^='hp-list'].cs-select, .cs-select {
    &:after {
        max-height: 495px;
    }
    &.open {
        .cs-title--finder {
            .cs-form {
                z-index: 3;
                pointer-events: all;
                opacity: 1;
            }
            span {
                opacity: 0;
                pointer-events: none;
            }
        }
    }
    .cs-option {
        cursor: pointer;
    }
    &.finder-result {
        .cs-result-list {
            margin-top: 16px;
        }
        .cs-list-wrap {
            .cs-selected-list {
                display: none;
            }
        }
        .cs-see-all {
            display: none;
        }
        .cs-selected-list {
            display: none;
        }
        li {
            display: none;
        }
        .cs-result-list {
            display: block;
        }
    }
    .cs-result-list, .cs-selected-list {
        a {
            letter-spacing: normal;
            position: relative;
            z-index: 5;
        }
    }
    .cs-title--finder {
        position: relative;
        span {
            transition: opacity .2s ease-in-out;
            color: #fff;
        }
        .cs-form {
            position: absolute;
            top: 0;
            left: 30px;
            bottom: 0;
            right: 45px;
            opacity: 0;
            pointer-events: none;
            transition: opacity .2s ease-in-out;
            &:after {
                content: "";
                position: absolute;
                top: 27px;
                left: 0;
                background-image: url("/templates/main/images/svg/search-icon-black.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 18px;
                height: 18px;
                opacity: 0.2;
            }
            input {
                padding-left: 25px;
                top: 17px;
                left: 0;
                position: absolute;
                width: 100%;
                max-width: 100%;
                display: block;
                border: none;
                outline: none;
                height: 38px;
                background: transparent;
                color: $color-black;
                border-bottom: 1px solid rgba(0,0,0,0.2);
                font-size: 16px;
            }
        }
    }
    .cs-list-wrap {
        max-height: 380px;
        .cs-result-list, .cs-selected-list {
            display: block;
            width: 100%;
            opacity: 1;
            pointer-events: all;
            color: $color-black;
            a {
                display: block;
                width: 100%;
                padding-bottom: 10px;
                font-size: 16px;
                text-transform: none;
                pointer-events: all;
                opacity: 1;
                color: $color-black;
                font-weight: 400;
                span {
                    color: $color-teal;
                }
            }
        }
    }
    .cs-see-all {
        position: absolute;
        left: 30px;
        right: 30px;
        bottom: 26px;
        height: 43px;
        background-color: #3F5B77;
        font-size: 13px;
        letter-spacing: 0.1em;
        font-weight: 700;
        padding-top: 2px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-transform: uppercase;
        border-radius: 14px;
        cursor: pointer;
        color: $color-white;
    }
}
.main__select-wrap {
    [id^='hp-list'].cs-select, .cs-select {
        &--selected-list {
            .cs-selected-list {
                &, a {
                    display: block;
                }
            }
            .cs-result-list {
                &, a {
                    display: block;
                }
            }
            li {
                &, a {
                    display: none;
                }
            }
            &.open {
                .cs-list-wrap {
                    height: 360px;
                }
                &:after {
                    height: 440px;
                }
            }

            &.cs-select--see-all {
                &.open {
                    .cs-see-all {
                        display: none;
                    }
                    .cs-list-wrap {
                        height: 380px;
                    }
                    &:after {
                        height: 460px;
                    }
                    .cs-selected-list {
                        display: none;
                    }
                    li {
                        &, a {
                            display: block;
                        }
                    }
                    &.finder-result {
                        .cs-selected-list {
                            display: none;
                        }
                        li {
                            display: none;
                        }
                        .cs-result-list {
                            display: block;
                        }
                    }
                }
            }

        }
    }
}
