
.burger-open {
    .top-menu {
        @media(min-width: $lg) {
            padding-right: 17px;
        }
    }
}
.top-menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 12;
    text-align: right;
    background-color: $color-white;
    &__container {
        padding-top: 16px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        align-content: center;
    }
    &--new {
        position: relative;
        background-color: $color-white;
        padding: 16px 0 10px;
        display: none;
        @media(min-width: $lg) {
            display: block;
        }
        .top-menu {
            &__container {
                display: flex;
                flex-wrap: wrap;
                justify-content: unset;
                padding: 0 15px;
            }
            &__logo {
                justify-self: flex-start;
                svg {
                    position: relative;
                    z-index: 3;
                }
                @media(min-width: 1366px) {
                    display: none;
                }
            }
            &__link {
                justify-self: flex-end;
                margin-left: auto;
            }
        }
    }
    &__link {
        color: $color-black;
        border-bottom: 1px dotted $color-white;
        padding-bottom: 1px;
        position: relative;
        z-index: 3;
        font-size: 13px;
        transition: color .2s ease-in-out, border-color .2s ease-in-out;
        &:hover {
            color: $color-red;
            border-color: $color-red;
        }
    }
    ul {
        min-height: 50px;
        height: 100%;
        color: $color-white;
        max-width: 1440px;
        padding: 0 15px;
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
        margin: 0 auto;
        position: relative;
        z-index: 3;
        li {
            &.current,
            &.active,
            &.alias-parent-active {
                position: relative;
                a {
                    color: $color-white;
                    opacity: 1;
                }
                &:after {
                    content: "";
                    top: -6px;
                    bottom: -6px;
                    left: -12px;
                    right: -12px;
                    border-radius: 10px;
                    background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
                    position: absolute;
                }
            }
            a {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                align-content: center;
                opacity: 0.6;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: 0.075em;
                text-transform: uppercase;
                position: relative;
                z-index: 3;
            }
        }
        .item-1149 {
            &.current,
            &.active {
                &:after {
                    display: none;
                }
                a {
                    background: linear-gradient(135deg, #f16655 0%, #fb3636 100%);
                }
            }
            a {
                background-color: rgba(40,70,102, 0.5);
                display: block;
                border-radius: 10px;
                width: 42px;
                height: 34px;
                font-size: 0;
                position: relative;
                opacity: 1;
                &:after {
                    content: "";
                    width: 20px;
                    height: 20px;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    margin: auto;
                    position: absolute;
                    background-image: url("/templates/main/images/svg/home.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }
        }
        .item-1149, .item-1150, .item-1151 {
            margin-right: 35px;
        }
        .item-1151 {
            @media(max-width: 360px) {
                margin-right: 0;
            }
        }
        .item-1153, .item-1152, .item-1154 {
            justify-self: flex-end;
            margin-left: 35px;
            display: none;
            @media(min-width: $lg) {
                display: block;
            }
        }
        .item-1153 {
            margin-left: auto;
            a {
                &:after {
                    content: "";
                    height: 16px;
                    width: 21px;
                    display: inline-block;
                    background-image: url("/templates/main/images/svg/magazines.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-left: 12px;
                }
            }
        }
        .item-1152 {
            a {
                &:after {
                    content: "";
                    height: 21px;
                    width: 21px;
                    display: inline-block;
                    background-image: url("/templates/main/images/svg/newsletter.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-left: 12px;
                }
            }
        }
        .item-1154 {
            a {
                &:after {
                    content: "";
                    height: 13px;
                    width: 17px;
                    display: inline-block;
                    background-image: url("/templates/main/images/svg/contact.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    margin-left: 12px;
                }
            }
        }
    }
}
