.contact {
    margin-bottom: 101px;
    // &::before {
    //     content: '';
    //     height: 400px;
    //     width: 100%;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     background-color: #002A43;
    // }
    &__wrapper {
        display: flex;
        z-index: 1;
        position: relative;
        @media(max-width:$lg-max) {
            flex-wrap: wrap;
        }
    }
    &__top-title {
        font-size: 58px;
        font-weight: 700;
        text-align: center;
        color: $color-black;
        z-index: 1;
        position: relative;
        margin-top: 20px;
    }
    &__small-title {
        font-size: 18px;
        margin-bottom: 30px;
        border-bottom: 2px solid #DDDDDD;
        color: #1A1B1C;
        font-weight: 700;
        padding-bottom: 5px;
    }
    &__name {
        input {
            width: 100%;
            border: 1px solid #DDDDDD;
            border-radius: 10px;
            padding: 22px;
            margin-bottom: 30px;
        }
    }
    &__email {
        input {
            width: 100%;
            border: 1px solid #DDDDDD;
            border-radius: 10px;
            padding: 22px;
            margin-bottom: 30px;
        }
    }
    &__form {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 30px 99px rgba(0,0,0,.16);
        margin-right: 30px;
        max-width: 930px;
        width: 100%;
        padding: 28px 45px 45px 45px;
        @media(max-width:$xs-max) {
            padding: 15px;
        }
        @media(max-width:$lg-max) {
            max-width: 100%;
            width: 100%;
            margin-bottom: 30px;
            margin-right: 0;
        }
        .captcha-wrap {
            @media(max-width:768px) {
                margin-bottom: 30px;
            }
            @media(max-width:390px) {
                display: flex;
                justify-content: center;
                .captcha {
                    transform: scale(0.9);
                }
            }
        }
        textarea {
            width: 100%;
            border: 1px solid #DDDDDD;
            border-radius: 10px;
            padding: 22px;
            resize: none;
            min-height: 200px;
            margin-bottom: 30px;
        }
        .button-wrap {
            button {
                width: 100%;
                background: linear-gradient(to right, rgba(241,102,85,1) 0%,rgba(251,54,54,1) 100%);
                border: none;
                border-radius: 10px;
                color: #fff;
                font-size: 15px;
                line-height: 21px;
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;
                img {
                    margin-right: 20px;
                }
            }
            .submit-btn-inside {
                padding: 35px 0;
            }
        }
    }
    &__info {
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0 30px 99px rgba(0,0,0,.16);
        max-width: 450px;
        width: 100%;
        padding: 28px 45px 110px 45px;
        @media(max-width:$lg-max) {
            max-width: 100%;
            width: 100%;
            margin-bottom: 30px;
        }
        @media(max-width:$xs-max) {
            padding: 15px;
        }
    }
    &__text {
        text-align: center;
        font-size: 16px;
        line-height: 23px;
        width: 100%;
        &-first {
            padding-top: 57px;
            text-align: center;
            margin-bottom: 21px;
            .bold {
                font-weight: 700;
            }
        }
        &-second {
            text-align: center;
            margin-bottom: 21px;
        }
        &-third {
            text-align: center;
        }
    }
}