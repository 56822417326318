$color-blue-navy:           #002A43;
$color-blue-dark:           #002B44;
$color-blue:                #294868;
$color-blue-light:          #35B7C1;
$color-blue-bg:             #223D59;
$color-blue-header:         #000C2D;
$color-white:               #fff;
$color-red-light:           #F16655;
$color-red:                 #FB3636;
$color-black:               #1A1B1C;
$color-black-0:             #000;
$color-gray:                #707070;
$color-gray-border:         rgba(112,112,112,0.1);
$color-gray-dark:           #E9E9EC;
$color-gray-light:          #dddddd;
$color-teal:                #35B7C1;
$color-teal-dark:           #3FB693;
$color-green:               #3ACE58;
$color-green-light:         #3FB693;

$font-main: 'Objectivity', sans-serif;

$color-font-main:       $color-black;
$color-font-second:     $color-blue-dark;

$main-gradient: linear-gradient(to bottom right, rgba(241,102,85,1) 0%, rgba(251,54,54,1) 100%);
$teal-gradient: linear-gradient(to bottom right, #3FB693 0%, #35B7C1 100%);


$xs-max:    575.98px;
$sm:        576px;
$sm-max:    767.98px;
$md:        768px;
$md-max:    1024.98px;
$lg:        1025px;
$lg-max:    1199.98px;
$xl:        1200px;

:root,
:root[data-theme="light"] {
    --colorFont: #1a1b1c;
    --colorBg: #ffffff;
    --colorBgDarker: #f4f5f7;
}

:root[data-theme="dark"] {
    --colorFont: #FFFFFF;
    --colorBg: #284666;
    --colorBgDarker: #193654;
}
