.articles-list {
    &__top {
        background-color: $color-blue-navy;
        color: $color-white;
        padding-bottom: 115px;
        .breadcrumb {
            margin-bottom: 20px;
        }
        .tags-header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            .page-header {
                margin-bottom: 0;
                width: auto;
            }
        }
    }
    &__bottom {
        margin-top: -50px;
        &--tag {
            .article-list__item-wrap {
                &:hover {
                    .article-list__item-image {
                        img {
                            opacity: 0;
                        }
                    }
                }
            }
            .article-list__item-wrap {
                .article-list__item-image {
                    &:after {
                        background: linear-gradient(0deg, #000c2d 0%, rgba(0, 12, 45, 0) 100%);
                    }
                    img {
                        opacity: 0;
                    }
                }
            }
        }
    }
    &__title {
        font-size: 22px;
        line-height: 33px;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        margin-bottom: 25px;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        align-content: center;
        transition: color .5s ease-in-out;
        &-link {
            display: inline-flex;
            align-items: center;
            align-content: center;
            flex-grow: 0;
            padding-right: 15px;
        }
        &-line {
            font-size: 0;
            height: 2px;
            flex-grow: 2;
            background-color: $color-red;
            display: inline-flex;
            align-items: center;
            align-content: center;
            margin-bottom: 5px;
        }
    }
}
