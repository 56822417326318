.our-magazines {
    background-color: #284666;
    color: $color-white;
    padding: 50px 0 0;
    &__title {
        text-align: center;
        padding: 0 15px;
        text-transform: uppercase;
        margin-bottom: 50px;
        font-size: 26px;
        @media(min-width: 576px) {
            font-size: 35px;
        }
    }
    .swiper-container {
        padding-bottom: 50px;
        padding-left: 15px;
        @media(min-width: 1440px) {
            padding-left: calc((100% - 1410px) / 2);
        }
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
    }
    &__item {
        text-align: center;
        &-link {
            display: block;
        }
        &-image {
            margin-bottom: 50px;
            img {
                box-shadow: 0 140px 95px rgba(0,0,0,0.3);
            }
        }
        &-title {
            font-size: 19px;
            font-weight: 700;
            margin-bottom: 18px;
        }
        &-description {
            font-size: 15px;
            line-height: 21px;
            opacity: 0.5;
        }
    }
}
